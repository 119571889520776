<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left></f7-nav-left>
      <f7-nav-title sliding>Panel de control</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height dashboard">
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
.dashboard {
  background: #525659;
}
</style>
<script>
import Dashboard from "./../js/db/Dashboard.js";

export default {
  components: {
  },
  data() {
    return {
      Dashboard
    };
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>