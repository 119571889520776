import Municipios from './municipios.vue';
import MunicipioForm from './municipio-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/municipios/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('municipios', ['ADMIN'], Municipios),
        keepAlive: true
    },
    {
        path: '/municipios/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('municipios/new', ['ADMIN'], MunicipioForm)
    },
    {
        path: '/municipios/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('municipios/edit', ['ADMIN'], MunicipioForm)
    },
    {
        path: '/municipios/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('municipios/view', ['ADMIN'], MunicipioForm, { editable: false })
    },
];
export default routes;