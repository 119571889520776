<template>
  <f7-page :page-content="pageContent" @page:mounted="Utils.onPageMounted()" v-bind="{...$attrs,...$props}" v-on="$listeners">
    <slot :page="self"></slot>
  </f7-page>
</template>
<script>

import Utils from "./../js/Utils.js";

export default {
  data() {
    return {
      self: this,
      Utils
    };
  },
  props: {
    pageContent: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
  },
  methods: {
  }
};
</script>