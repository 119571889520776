import MediumEditor from 'medium-editor';

var Cabecera = MediumEditor.extensions.form.extend({
    
    name: 'cabecera',
    action: 'header',
    aria: 'select header',
    title: 'Seleccionar cabecera',
    contentDefault: '&#xB1;', // ±
    f7Icon: 'square_arrow_up',

    cabeceras: [],

    init: function () {
        this.button = this.document.createElement('button');
        this.button.classList.add('medium-editor-action');
        this.button.innerHTML = '<i class="icons f7-icons">' + this.f7Icon + '</i>';
        this.button.title = this.title;
        this.on(this.button, 'click', this.handleClick.bind(this));
    },

    // Called when the button the toolbar is clicked
    // Overrides ButtonExtension.handleClick
    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.showForm();

        return false;
    },

    // Called by medium-editor to append form to the toolbar
    getForm: function () {
        if (!this.form) {
            this.form = this.createForm();
        }
        return this.form;
    },

    // Used by medium-editor when the default toolbar is to be displayed
    isDisplayed: function () {
        return this.getForm().style.display === 'block';
    },

    hideForm: function () {
        this.getForm().style.display = 'none';
        this.getSelect().value = '';
    },

    showForm: function () {
        var select = this.getSelect();

        this.base.saveSelection();
        this.hideToolbarDefaultActions();
        this.getForm().style.display = 'block';
        this.setToolbarPosition();

        select.focus();
    },

    // Called by core when tearing down medium-editor (destroy)
    destroy: function () {
        if (!this.form) {
            return false;
        }

        if (this.form.parentNode) {
            this.form.parentNode.removeChild(this.form);
        }

        delete this.form;
    },

    // core methods

    doFormSave: function () {
        this.base.restoreSelection();
        this.base.checkSelection();
    },

    doFormCancel: function () {
        this.base.restoreSelection();
        this.base.checkSelection();
    },

    // form creation and event handling
    createForm: function () {
        var doc = this.document,
            form = doc.createElement('div'),
            select = doc.createElement('select'),
            close = doc.createElement('a'),
            save = doc.createElement('a'),
            option;

        // Font Name Form (div)
        form.className = 'medium-editor-toolbar-form';
        form.id = 'medium-editor-toolbar-form-fontname-' + this.getEditorId();

        // Handle clicks on the form itself
        this.on(form, 'click', this.handleFormClick.bind(this));

        // Add font names
        for (var i = 0; i<this.cabeceras.length; i++) {
            option = doc.createElement('option');
            option.innerHTML = this.cabeceras[i].name;
            option.value = this.cabeceras[i].content;
            select.appendChild(option);
        }

        select.className = 'medium-editor-toolbar-select';
        form.appendChild(select);

        // Handle typing in the textbox
        this.on(select, 'change', this.handleHeaderChange.bind(this));

        // Add save buton
        save.setAttribute('href', '#');
        save.className = 'medium-editor-toobar-save';
        save.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-check"></i>' :
                            '&#10003;';
        form.appendChild(save);

        // Handle save button clicks (capture)
        this.on(save, 'click', this.handleSaveClick.bind(this), true);

        // Add close button
        close.setAttribute('href', '#');
        close.className = 'medium-editor-toobar-close';
        close.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-times"></i>' :
                            '&times;';
        form.appendChild(close);

        // Handle close button clicks
        this.on(close, 'click', this.handleCloseClick.bind(this));

        return form;
    },

    getSelect: function () {
        return this.getForm().querySelector('select.medium-editor-toolbar-select');
    },

    handleHeaderChange: function () {
        var cabecera = this.base.elements[0].getElementsByClassName("header-container")[0];
        var contenedor = cabecera.getElementsByClassName("header-content")[0];

        var templateCabecera = this.getSelect().value;
        contenedor.innerHTML = templateCabecera;
        if (templateCabecera === '') {
            cabecera.classList.add("no-header");
        } else {
            cabecera.classList.remove("no-header");
        }
        this._gestionarMargen(contenedor);
    },

    handleFormClick: function (event) {
        // make sure not to hide form when clicking inside the form
        event.stopPropagation();
    },

    handleSaveClick: function (event) {
        // Clicking Save -> create the font size
        event.preventDefault();
        this.doFormSave();
    },

    handleCloseClick: function (event) {
        // Click Close -> close the form
        event.preventDefault();
        this.doFormCancel();
    },

    refresh: function() {
        setTimeout(()=>{
            var doc = this.document,
            headerContainer = doc.getElementById("header-container"),
            headerContent = headerContainer ? doc.getElementById("pageHeader") : null;
            if(headerContent && headerContent.innerHTML && headerContent.innerHTML.trim()){
                headerContainer.classList.remove("no-header");
            } else {
                headerContainer.classList.add("no-header");
            }
        },0);
    },
    cabeceraSize() {
        return this._mt2Cm(this.marginClass) || 1.5;
    },
    changeOptions: function(cabeceras){
        var self = this;
        var doc = self.document;
        cabeceras.unshift({   name: '', content: ''   });
        self.cabeceras = [{name:"", content:""},{name:'Sin cabecera', content:''}];
        var form = this.getForm();
        var select = form.getElementsByClassName("medium-editor-toolbar-select")[0];
        Array.from(select.childNodes).forEach(node => {node.remove();});
		cabeceras.forEach(cabecera => {
		if(!cabecera.valores) return;
            cabecera.valores.forEach(optionRes => {
                self.cabeceras.push({name:optionRes.valor, content:optionRes.nombre});
            })
        });
        self.cabeceras.forEach(optionRes => {
            var option = doc.createElement('option');
            option.innerHTML = optionRes.name;
            option.value = optionRes.content;
            select.appendChild(option);
        });
    },
    setInitialContent: function (content) {
        var cabecera = content.getElementsByClassName("header-container")[0];
        var contenedor = cabecera?cabecera.getElementsByClassName("header-content")[0]:null;
        this._gestionarMargen(contenedor);
    },


    _gestionarMargen: function(contenedor){
        var hijos = Array.from(contenedor.childNodes).filter(node => node.nodeName != "#text"),
            cabecera = hijos.length ? hijos[0] : null;
        if(!cabecera){
            this.marginClass = null;
            return;
        }
        var clases = cabecera.classList,
            marginClasses = Array.from(clases).filter(clase => clase.match(/mt-\d+/)),
            marginClass = marginClasses.length ? marginClasses[0] : null;
        if(!marginClass){
            this.marginClass = null;
        } else {
            this.marginClass = marginClass;
        }
    },
    _mt2Cm: function(mtClass){
        return mtClass ? mtClass.replace(/mt-(\d+)/, "$1")/10 : 1.5;
    }

});
export default Cabecera;