import DatabaseModel from './DatabaseModelOffline.js';
import Utils from '../Utils.js';
import Configuracion from './ConfiguracionOffline.js';

var Albaran = Object.create(DatabaseModel);

Albaran.model = 'albaranes';

Albaran.getCodigo = function () {

    return new Promise(function(resolve, _) {
        Configuracion.get({itemId: "CONFIG"})
        .then(config => {
            const year = new Date().getFullYear() - 2000;
            if (config.dispositivo.anyo != year) {
                Albaran.aumentarSecAlbaran()
                .then(config => {
                    if (config) {
                        var codigo = config.dispositivo.id + '-' + config.dispositivo.anyo + '-' + Utils.padZeros(config.dispositivo.secAlbaran, 5);
                        resolve(codigo);
                    } else {
                        resolve(null);
                    }
                })
                .catch(error => {
                    console.error(error);
                    resolve(null);
                })
            } else {
                if (config) {
                    var codigo = config.dispositivo.id + '-' + config.dispositivo.anyo + '-' + Utils.padZeros(config.dispositivo.secAlbaran, 5);
                    resolve(codigo);
                } else {
                    resolve(null);
                }
            }
        })
        .catch(error => {
            console.error(error);
            resolve(null);
        })
    });
};

Albaran.aumentarSecAlbaran = function() {

    return new Promise(function(resolve, _) {
        Configuracion.get({itemId: "CONFIG"})
        .then(config => {
            const year = new Date().getFullYear() - 2000;
            if (config) {
                if (year == config.dispositivo.anyo) {
                    config.dispositivo.secAlbaran = config.dispositivo.secAlbaran + 1;
                } else {
                    config.dispositivo.anyo = year;
                    config.dispositivo.secAlbaran = 1;
                }
                Configuracion.put({itemId: "CONFIG", data: config})
                .then(config => {
                    resolve(config);
                })
                .catch(error => {
                    console.error(error);
                    resolve(null);
                });
            } else {
                resolve(null);
            }
        })
        .catch(error => {
            console.error(error);
            resolve(null);
        });
    });
}

export default Albaran;