import TiposTareas from './tipostarea.vue';
import TiposTareasForm from './tipostarea-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/tipostarea/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipostarea', ['ADMIN', 'INSPECTOR'], TiposTareas),
        keepAlive: true
    },
    {
        path: '/tipostarea/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipostarea/new', ['ADMIN', 'INSPECTOR'], TiposTareasForm)
    },
    {
        path: '/tipostarea/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipostarea/edit', ['ADMIN', 'INSPECTOR'], TiposTareasForm)
    },
    {
        path: '/tipostarea/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipostarea/view', ['ADMIN', 'INSPECTOR'], TiposTareasForm, { editable: false })
    },
];
export default routes;