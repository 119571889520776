<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('empleados.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('empleados.empleado')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('empleados.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Empleado"
      :beforeSave="beforeSaveEmpleado"
      :documentoAdapter="Documento"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('empleados.username')"
                  :form="rform"
                  type="text"
                  name="username"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('empleados.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('empleados.email')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('empleados.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                ></r-input>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="Rol"
                  floating-label 
                  :label="$t('empleados.rol')"
                  :form="rform"
                  name="rol"
                  idProp="valor"
                  labelProp="nombre"
                ></r-autocomplete> 
                <r-input
                  class="col-25 medium-25"
                  :label="$t('empleados.crearuser')"
                  :form="rform"
                  type="check"
                  name="createdUser"
                ></r-input>
                <r-input
                  class="col-25 medium-25"
                  :label="$t('empleados.activo')"
                  :form="rform"
                  type="check"
                  name="activo"
                ></r-input>
                <r-files
                  class="col-100 medium-50 margin-top"
                  :form="rform"
                  name="firma"
                  :documentoAdapter="Documento"
                  label="Añadir Firma"
                  :maxFiles="1"
                ></r-files>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="Empresa"
                  floating-label
                  :label="$t('empleados.codEmpresa')"
                  :form="rform"
                  name="datos.codEmpresa"
                  idProp="codigo"
                  idFieldDetail="id"
                  prefixProp="codigo"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="LineaNegocio"
                  floating-label
                  :label="$t('empleados.codLineaNegocio')"
                  :form="rform"
                  name="datos.codLineaNegocio"
                  idProp="codigo"
                  prefixProp="codigo"
                  idFieldDetail="id"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="Delegacion"
                  floating-label
                  :label="$t('empleados.codDelegacion')"
                  :form="rform"
                  name="datos.codDelegacion"
                  idProp="codigo"
                  prefixProp="codigo"
                  idFieldDetail="id"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="CentroTrabajo"
                  floating-label
                  :label="$t('empleados.codCentroTrabajo')"
                  :form="rform"
                  name="datos.codCentroTrabajo"
                  idProp="codigo"
                  prefixProp="codigo"
                  idFieldDetail="id"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-autocomplete
                  class="col-100 medium-50"
                  :dbAdapter="Departamento"
                  floating-label
                  :label="$t('empleados.codDepartamento')"
                  :form="rform"
                  name="datos.codDepartamento"
                  idProp="codigo"
                  prefixProp="codigo"
                  idFieldDetail="id"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-input
                  class="col-25 medium-25"
                  floating-label
                  :label="$t('empleados.fecha_alta')"
                  :form="rform"
                  type="datepicker"
                  :name="'fechaAlta'"
                  :calendar-params="{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }"
                />
                <r-input
                  class="col-25 medium-25"
                  floating-label
                  :label="$t('empleados.fecha_baja')"
                  :form="rform"
                  type="datepicker"
                  :name="'fechaBaja'"
                  :calendar-params="{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }"
                />
                <r-input
                  class="col-25 medium-25"
                  floating-label
                  :label="$t('empleados.dni')"
                  :form="rform"
                  name="dni"
                />
                <!-- enlace para mostrar popup de colegas -->
                <f7-col class="col-100 medium-100">
                  <r-autocomplete-m2m
                    :dbAdapter="Empleado"
                    floating-label
                    outline
                    :label="$t('empleados.colegas')"
                    icon-f7="person_badge_plus"
                    :form="rform"
                    name="colegas"
                    idProp="username"
                    labelProp="nombre"
                    prefixProp="username"
                    type="textarea"
                    :lineBreak="true"
                  ></r-autocomplete-m2m>
                </f7-col>






              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2000" v-if="rform.formData.rol && rform.formData.rol == 'INSPECTOR'"> 
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <r-autocomplete
              outline
              :dbAdapter="Oficina"
              class="col-100 medium-25"
              floating-label
              :label="$t('empleados.oficina')"
              :form="rform"
              :shownFields="['direccion','telefono','email']"
              :idProp="null"
              name="oficina"
              labelProp="nombre"
            ></r-autocomplete>
            <r-input
              outline
              class="col-100 medium-25"
              floating-label
              :label="$t('empleados.auditoria_manual')"
              :form="rform"
              type="number"
              name="percentilAuditoria"
            ></r-input>
            <r-autocomplete-m2m
              outline
              :dbAdapter="Servicio"
              class="col-100 medium-50"
              floating-label
              :label="$t('empleados.servicios')"
              :form="rform"
              name="servicios"
              labelProp="nombre"
              type="textarea"
              prefixProp="codigo"
              :lineBreak="true"
              :viewTooltip="false"
            ></r-autocomplete-m2m>
            <r-input
              outline
              class="col-100 medium-33"
              floating-label
              label="Dirección inicio jornada"
              :form="rform"
              name="direccionInicio"
            ></r-input>
            <r-timepicker
              class="col-100 medium-33"
              outline
              floating-label
              label="Hora inicio jornada"
              :form="rform"
              name="horaInicio"
              :clearButton="false"
            ></r-timepicker>
            <r-input
              outline
              class="col-100 medium-33"
              floating-label
              label="Objetivo Diario"
              :form="rform"
              type="number"
              name="objetivoDiario"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2000" v-else-if="rform.formData.rol && rform.formData.rol == 'CLIENTE'"> 
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-block-title class="col-100">
              Clientes asociados
              <f7-link v-if="!readonly" @click="addToArray('clientes')">{{$t('ofertas.add')}}</f7-link>
            </f7-block-title>
            <div class="form-caja margin-horizontal col-100 medium-50">
              <div
                class="col-100"
                v-for="(cliente, index) in rform.formData.clientes"
                :key="'clienteasociado-' + index"
              >
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.cliente')"
                  :form="rform"
                  :name="'clientes.' + index"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigo', 'razonSocial', 'activo', 'codigoNombre']"
                  :readonly="readonly"
                  :async="true"
                  :clear-button="false"
                  :filter="{filter:{activo: true}}"
                >
                  <f7-link
                    v-if="!readonly"
                    v-tooltip="'Borrar'"
                    slot="inner-end"
                    @click="$delete(rform.formData.clientes, index);"
                    class="input-suffix-btn"
                    icon-f7="trash"
                    style="top: initial;right: 15px;"
                  ></f7-link>
                </r-autocomplete>
              </div>
            </div>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
  </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rDireccion from "./../../components/rDireccion.vue";
import Empleado from "./../../js/db/Empleado.js";
import Cliente from "./../../js/db/Cliente.js";
import Empresa from "./../../js/db/Empresa.js";
import LineaNegocio from "./../../js/db/LineaNegocio.js";
import Delegacion from "./../../js/db/Delegacion.js";
import CentroTrabajo from "./../../js/db/CentroTrabajo.js";
import Departamento from "./../../js/db/Departamento.js";
import Documento from "./../../js/db/Documento.js";
import Rol from "./../../js/db/Rol.js";
import Servicio from "./../../js/db/Servicio.js";
import Oficina from "./../../js/db/Oficina.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rDireccion,
    rAutocomplete,
    rAutocompleteM2m
  },
  data: function() {
    var self = this;
    return {
      Empleado,
      Cliente,
      Empresa,
      LineaNegocio,
      Delegacion,
      CentroTrabajo,
      Departamento,
      Rol,
      Servicio,
      Oficina,
      readonly: !this.editable,
      Documento
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    addToArray(namespace) {
      const self = this;
      var arr = self.$refs.rform.formData[namespace] || [];
      arr.push("");
      self.$set(self.$refs.rform.formData, namespace, arr);
    },
    beforeSaveEmpleado: function(data, resolve, reject) {
      const self = this;
      if (data.firma && typeof data.firma.length != "undefined") {
        data.firma = data.firma[0];
      }
      resolve(data);
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Empleado.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("empleados.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>