import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class SaltoPagina extends BlockEmbed {
  static create(value = '⏎') {
    let node = super.create(value);
    node.textContent = value;
    node.setAttribute('class', 'salto-pagina');
    return node;
  }
}

SaltoPagina.blotName = 'saltoPagina';
SaltoPagina.tagName = 'div';

export default SaltoPagina;
