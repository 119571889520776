import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Planificacion = Object.create(DatabaseModel);

Planificacion.model = 'planificacion';

Planificacion.clearCache = function() {
    var self = this;
    CacheService.clearCache(self.model);
};

Planificacion.borrarInspector = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/borrarInspector',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Planificacion.desplanificar = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/desplanificar',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Planificacion.calcularRuta = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/calcularRuta',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Planificacion.getDiario = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/getDiario',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Planificacion.getDiarios = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/getDiarios',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

export default Planificacion;