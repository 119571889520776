<template>
  <div class="div-badge" @click="$emit('click')">
    <label v-if="label"> {{label}} </label> 
    <span :class='"badge color-" + color + (borderColor ? " badge-border border-color-" + borderColor : "")' v-html="text"></span>
  </div>
</template>

<style scoped>
  span.badge {
    padding: 6px 10px;
  }
  .div-badge {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .badge-border {
    border: 2px solid;
  }
</style>
<script>
export default {
  data() {
    return {
      self: this
    };
  },
  props: {
    color: {
      required: false,
      type: String
    },
    borderColor: {
      required: false,
      type: String
    },
    text: {
      required: true,
      type: String
    },
    label: {
      required: false,
      type: String
    }
  }
};
</script>