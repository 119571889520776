import DatabaseModel from './DatabaseModel.js';

var Global = Object.create(DatabaseModel);

Global.model = 'global';

Global.search = function(busqueda){
    var self = this,
        params = {
            method: 'post',
            url: self.server_movilidad + self.prefix + self.model + '/search/' + busqueda,
        }
    return this.sendRequest(params, false);
}

export default Global;