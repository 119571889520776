
var FileUtils = {
    saveFile: function(data, path, nombre, extension, resolve, reject) {
        try {
            var baseDir = typeof eapp != "undefined" ? eapp.getPath("userData") : "";
            let dir = baseDir + path;
            if (typeof fs != "undefined") {
                data = new Buffer(data);
                var filePath = baseDir + path + nombre + '.' + extension;
                if (!fs.existsSync(dir)) fs.mkdirSync(dir);
                fs.writeFile(filePath, data, 'binary', function(err) {
                    if (err) reject({ error: 'Error almacenando imágenes' });
                    else resolve();
                });
            } else {
                data = new Blob([data]);
                var filePath = nombre + '.' + extension;
                var onError = function(e) {
                    reject({ error: 'Error almacenando imágenes: ' + e.toString() });
                };
                window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                    fs.root.getDirectory(dir, { create: true }, function (dirEntry) {
                
                        dirEntry.getFile(filePath, { create: true, exclusive: false }, function (fileEntry) {
                            fileEntry.createWriter(function (fileWriter) {
                                fileWriter.onwriteend = function() {
                                    resolve();
                                };
                                fileWriter.onerror = onError;
                                fileWriter.write(data);
                            });
                        }, onError);
                    }, onError);
                }, onError);
            }
        } catch (err) {
            reject({ error: 'Error almacenando imágenes: No se pudo crear el directorio local' });
            console.log(err);
            return;
        }
    },
    saveWithoutOverride (base64Data, path, nombreOrig, extension, resolve, reject) {
        var self = this;
        try {
            if (typeof fs != "undefined") {
                var n = 0;
                var baseDir = typeof eapp != "undefined" ? eapp.getPath("userData") : "";
                let dir = baseDir + path;
                var ruta = baseDir + path + nombre + '.' + extension;
                var nombre = nombreOrig;
                if (!fs.existsSync(dir)) fs.mkdirSync(dir);
                while (fs.existsSync(ruta)) {
                    n++;
                    var nombre = nombre + ' (' + n + ')';
                    var ruta = baseDir + path + nombre + '.' + extension;
                }
                fs.writeFile(baseDir + path + nombre + '.' + extension, base64Data, 'base64', function(err) {
                    if (err) reject({ error: 'Error almacenando documento' });
                    else resolve({
                        nombre: nombreOrig,
                        extension: extension
                    });
                });
            
            } else {
                var data = self.b64toBlob(base64Data);
                self.saveBlobFile(data, nombreOrig, extension, path, resolve, reject);
            }
        } catch (err) {
            reject({ error: 'Error almacenando documentos: No se pudo crear el directorio local' });
            console.log(err);
            return;
        }
    },
    saveBlobFile: function(blob, nombreOrig, extension, path, resolve, reject, override = false) {
        var nombre = nombreOrig;
        var baseDir = typeof eapp != "undefined" ? eapp.getPath("userData") : "";
        path = path || "/documentos/"
        let dir = baseDir + path;
        var n = 0;
        var onError = function(e) {
            reject({ error: 'Error almacenando imágenes: ' + e.toString() });
        };
        var getFile = function (dirEntry, nombreOrig, nombre) {
            var filePath = nombre + '.' + extension;
            dirEntry.getFile(filePath, { create: false }, function (fileEntry) {
                // Fichero Existe
                if (override) {
                    fileEntry.createWriter(function (fileWriter) {
                        fileWriter.onwriteend = function() {
                            resolve({
                                nombre: nombre,
                                extension: extension,
                                fileEntry: fileEntry,
                                alreadySaved: true
                            });
                        };
                        fileWriter.onerror = onError;
                        fileWriter.write(blob);
                    });
                } else {
                    n++;
                    var nombreNuevo = nombre + ' (' + n + ')';
                    getFile(dirEntry, nombreOrig, nombreNuevo);
                }
            }, function () {
                dirEntry.getFile(filePath, { create: true, exclusive: false }, function (fileEntry) {
                    fileEntry.createWriter(function (fileWriter) {
                        fileWriter.onwriteend = function() {
                            resolve({
                                nombre: nombre,
                                extension: extension,
                                fileEntry: fileEntry,
                                alreadySaved: true
                            });
                        };
                        fileWriter.onerror = onError;
                        fileWriter.write(blob);
                    });
                }, onError);
            });
        }
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
            fs.root.getDirectory(dir, { create: true }, function (dirEntry) {
                getFile(dirEntry, nombreOrig, nombre);
            }, onError);
        }, onError);
    },
    b64toBlob: function(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        b64Data = b64Data.replace(/^data:\w+\/\w+;base64,/, "");
        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    },
    b64toData: function(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return byteArrays;
    },
    openFile: function(data, name, resolve, reject) {
        if (typeof FileType != "undefined") {
            FileType.fromBuffer(data).then(function(t) {
                var blob = new Blob([data], { type: t.mime });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                } else {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name;
                    link.target = '_system';
                    link.click();
                    URL.revokeObjectURL(link.href);
                }
                resolve();
            });
        } else {
            
            var blob = new Blob([data]);
            var onError = function(e) {
                reject({ error: 'Error abriendo fichero: ' + e.toString() });
            };
            window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
                fs.root.getFile(name, { create: true}, function (fileEntry) {
                    fileEntry.createWriter(function (fileWriter) {
                        fileWriter.onwriteend = function() {
                            fileEntry.file(function (file) {
                                cordova.plugins.fileOpener2.open(
                                    file.localURL, 
                                    file.type, 
                                    {
                                    onError, 
                                    success : function(){ 
                                        resolve();
                                    } 
                                    } 
                                );
                            }, onError);
                        };
                        fileWriter.onerror = onError;
                        fileWriter.write(blob);
                    });
                }, onError);
            }, onError);
        }
    },
    deleteFile: function(name, resolve, reject) {
        var onError = function(e) {
            console.log(e);
            console.log("No se ha podido eliminar el archivo: " + name);
            reject({ error: 'Error borrando archivos: ' + e.toString()});
        };
        window.requestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, function (fs) {
            console.log("Dentro fileSystem");
            console.log(fs);
            fs.root.getFile(name, { create: false}, function (fileEntry) {
                console.log("Dentro getFile");
                console.log(fileEntry);
                fileEntry.remove(function(a){ 
                    console.log("Dentro remove");
                    console.log(a);
                    console.log("Eliminado archivo: " + name);
                    resolve(a);
                }, onError );
            }, onError);
        }, onError);
    }
}

export default FileUtils;