<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Oportunidades" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Oportunidad"
        :tableCode="'OPORTUNIDAD'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="200"
        :shownFields="['cliente.direcciones']"
      >
        <template v-slot:tableLinks>
            <a class="link" href="/oportunidades/new/">Nueva oportunidad</a>
        </template>
        <template v-slot:optionsColumn="{item}">
            <f7-link v-tooltip="$t('common.ver') + ' Oportunidad'" icon-f7="eye" color="green" :href="`/oportunidades/view/${item.id}/`"></f7-link>
            <f7-link icon-f7="square_pencil" color="blue" :href="`/oportunidades/edit/${item.id}/`"></f7-link>
            <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
        </template>
        <template v-slot:massiveActions="{ }">
          <f7-button
            fill
            icon-f7="envelope"
            text="Enviar email"
            class="display-flex"
            color="purple"
            @click="enviarEmail = true"
          ></f7-button>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>

  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "./components/envio-mail.vue";
import Oportunidad from "./../../js/db/Oportunidad.js";

export default {
  components: {
    rDynamicList,
    EnvioMail
  },
  data() {
    return {
      Oportunidad,
      enviarEmail: false
    };
  },
  computed: {},
  methods: {
    filasSeleccionadas: function() {
      let filas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return {id: selected.id};
      });
      return filas;
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>