import DatabaseModel from './DatabaseModel.js';

var PersonalCualificado = Object.create(DatabaseModel);

PersonalCualificado.model = 'personalcualificado';
PersonalCualificado.modelFront = 'personalcualificado';

PersonalCualificado.countInspecciones = function(data) {
    var self = this;
    let params = {
        url: self.server_movilidad + self.prefix + self.model + '/countinspecciones/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default PersonalCualificado;