import DatabaseModel from './DatabaseModel.js';

var DocumentoGestion = Object.create(DatabaseModel);
DocumentoGestion.model = "plantilla"

DocumentoGestion.getListaDocumentosPorTipo = function(tipo) {
    var self = this;
    var paramsRequest = {
        url: self.server_movilidad + self.prefix + 'plantilla/' + tipo,
        method: 'GET',
        data: {}
    };
    return self.sendRequest(paramsRequest, true);
}

export default DocumentoGestion;