<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="esEquipo ? $t('items.equipos') : $t('items.items')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Item"
        :tableCode="esEquipo ? 'EQUIPO' : 'ITEM'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :mandatoryFilter ="{esEquipo: esEquipo}" 
        :filtersNoCounters="['esEquipo']"
        :controlsWidth="esEquipo ? 120 : 70"
      >
            <template v-if="esEquipo" v-slot:tableLinks>
          <a class="link" href="/equipos/new/">{{$t('equipos.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="esEquipo ? `/equipos/view/${item.id}/` : `/items/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="esEquipo ? `/equipos/edit/${item.id}/` : `/items/edit/${item.id}/`"></f7-link>
          <f7-link v-if="esEquipo" v-tooltip="$t('common.duplicar')" icon-f7="rectangle_stack_badge_plus" color="green" @click="clonar(item.id)"></f7-link>
          <f7-link v-if="esEquipo" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
        </template> 
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Utils from "./../../js/Utils.js";
import Item from "./../../js/db/Item.js";
export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Utils,
      Item
    };
  },
  computed: {},
  props: {
    esEquipo: {
      type: Boolean
    }
  },
  methods: {
    clonar: function(id) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Item.clonar({
        itemId: id,
        data: {}
      }).then(function(res){
        app.views.current.router.navigate(
          "/equipos/edit/"+res.id+"/"
        );
      })
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>