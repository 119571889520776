import ViasPago from './viaspago.vue';
import ViaPagoForm from './viaspago-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/viaspago/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('viaspago', ['ADMIN', 'INSPECTOR'], ViasPago),
        keepAlive: true
    },
    {
        path: '/viaspago/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('viaspago/new', ['ADMIN', 'INSPECTOR'], ViaPagoForm)
    },
    {
        path: '/viaspago/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('viaspago/edit', ['ADMIN', 'INSPECTOR'], ViaPagoForm)
    },
    {
        path: '/viaspago/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('viaspago/view', ['ADMIN', 'INSPECTOR'], ViaPagoForm, { editable: false })
    },
];
export default routes;