import DatabaseModel from './DatabaseModel.js';
import FileUtils from '../FileUtils.js';
import moment from 'moment';

var Documento = Object.create(DatabaseModel);

Documento.model = 'core/documento';

Documento.upload = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        if (!params.nombre || !params.extension || !params.base64) {
            reject({ error: 'Parámetros no definidos' });
            return;
        }
        var base64Data = params.base64.split(',')[1];
        FileUtils.saveWithoutOverride(base64Data, '/documentos/', params.nombre, params.extension, resolve, reject);
    });
};

Documento.uploadData = function(params) {
    var self = this;
    return self.upload(params);
};

Documento.uploadRemote = function (doc) {
    const self = this;
    const send = function (blob, doc, resolve, reject) {
        var formData = new FormData();
        console.log("Enviando documento " + doc.nombre + '.' + doc.extension);
        formData.append("files", blob, doc.nombre + '.' + doc.extension);
        if (doc.comentario) formData.append("comentario", doc.comentario);
        if (doc.offlinePath) formData.append("offlinePath", doc.offlinePath);
        if (doc.selected) formData.append("selected", doc.selected);

        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/upload/',
            method: 'POST',
            dataType: 'json',
            crossDomain: true,
            data: formData
        }).then(function({ data, xhr, status }) {
            resolve(data.list[0]);
        }).catch(function(e) {
            console.log('Error al enviar el documento');
            // reject({ error: 'Error al enviar el documento' });
            resolve();
        });
    }
    return new Promise(function (resolve, reject) {
        const onError = function (e) {
            resolve();
            // reject({ error: 'UploadRemote: Error leyendo documentos: ' + JSON.stringify(e) });
        };
        if (!doc.offlinePath || doc.descargadoInicial) {
            resolve();
        } else {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                fs.root.getFile(doc.offlinePath, { create: false, exclusive: false }, function (fileEntry) {
                    fileEntry.file(function (file) {
                        var reader = new FileReader();
    
                        reader.onloadend = function () {
                            var blob = new Blob([new Uint8Array(this.result)]);
                            send(blob, doc, resolve, reject);
                        };
    
                        reader.readAsArrayBuffer(file);
    
                    }, onError);
                }, onError);
            }, onError);
        }
    });
}

Documento.getDocumento = function(documento) {

    return new Promise(function(resolve, reject) {
        if (!documento.nombre || !documento.extension) {
            reject({ error: 'Parámetros no definidos' });
            return;
        }
        var baseDir = typeof eapp != "undefined" ? eapp.getPath("userData") : "";
        var dir = baseDir + '/documentos/';
        var ruta = baseDir + '/documentos/' + documento.nombre + '.' + documento.extension;

        if (typeof fs != "undefined") {
            fs.readFile(ruta, (err, imageData) => {
                if (err) {
                    reject({ code: 'ERR_READ_DOC', error: 'Error al leer el documento' });
                    return;
                }
                FileType.fromBuffer(imageData).then(function(t) {
                    resolve(imageData);
                });

            });
        } else {
            var onError = function(e) {
                resolve();
                // reject({ error: 'GetDocumento: Error leyendo documentos: ' + JSON.stringify(e) });
            };
            if (documento.offlinePath) {
                window.resolveLocalFileSystemURL(documento.offlinePath, function (fileEntry) {
                    fileEntry.file(function (file) {
                        var reader = new FileReader();
                
                        reader.onloadend = function() {
                            resolve(new Uint8Array(this.result));
                        };
                
                        reader.readAsArrayBuffer(file);
                
                    }, onError);
                }, onError);
            } else {
                var filePath = documento.nombre + '.' + documento.extension;
                window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
                        fs.root.getDirectory(dir, { create: false }, function (dirEntry) {
                            dirEntry.getFile(filePath, { create: false }, function (fileEntry) {
                                fileEntry.file(function (file) {
                                    var reader = new FileReader();
                            
                                    reader.onloadend = function() {
                                        resolve(new Uint8Array(this.result));
                                    };
                            
                                    reader.readAsArrayBuffer(file);
                            
                                }, onError);
                            }, onError);
                        }, onError);
                }, onError);
            }
        }
    });
}

Documento.createFolders = async function (rootDir, folders, fs) {
    var folderToCreate = rootDir;
    for (var i = 0; i < folders.length - 1; i++) {
        folderToCreate += folders[i] + "/";
        try {
            await new Promise((resolve, reject) => {
                fs.root.getDirectory(folderToCreate, { create: true, exclusive: false }, resolve, reject);
            });
        } catch (e) {
            console.log("Error creando carpetas", e);
        }
    }
}

Documento.saveBase64Inspeccion = function (base64, inspeccion, path, filename) {
    const blob = FileUtils.b64toBlob(base64);
    return Documento.saveBlobInspeccion(blob, inspeccion, path, filename)
}
Documento.saveBase64 = function (base64, path) {
    const blob = FileUtils.b64toBlob(base64);
    return Documento.saveBlob(blob, "", path)
}

Documento.saveBlobInspeccion = function (blob, inspeccion, path, filename) {
    return new Promise(function (resolve, reject) {
        const timestamp = moment().format("YYYYMMDDHHmmss"),
            insp = inspeccion.id,
            provincia = inspeccion.direccionInspeccion && inspeccion.direccionInspeccion.provincia ? inspeccion.direccionInspeccion.provincia : "00",
            formato = inspeccion.formato.codigo.replaceAll(/\s/g, ""),
            completeFilename = 'SIMECAL/' + (insp ? `${formato}/${provincia}/${insp}/${path}/${timestamp}_${filename}` : `${formato}/${provincia}/${path}/${timestamp}_${filename}`);
        
        const rootDir = "Documents/";

        Documento.saveBlob(blob, rootDir, completeFilename).then(resolve).catch(reject);

    });
}

Documento.saveBlob = function (blob, rootDir, completeFilename) {
    if (!window.requestFileSystem) {
        return;
    }
    const filePath = rootDir + completeFilename;
    return new Promise(function (resolve, reject) {
        const onError = function (e) {
            reject({ error: 'Error guardando documento: ' + e.toString() });
        };

        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, async function (fs) {

            var folders = completeFilename.split("/");
            await Documento.createFolders(rootDir, folders, fs);

            fs.root.getFile(filePath, { create: true, exclusive: false }, function (fileEntry) {
                fileEntry.createWriter(function (fileWriter) {
                    fileWriter.onwriteend = function () {
                        resolve({
                            filePath: filePath,
                            localURL: fileEntry.nativeURL
                        });
                    };
                    fileWriter.onerror = onError;
                    fileWriter.write(blob);
                }, onError);
            }, onError);
        }, onError);
    });
}

export default Documento;