import MediumEditor from 'medium-editor';

var Checbox = MediumEditor.extensions.form.extend({

    name: 'checkbox',
    action: 'checkbox',
    aria: 'Añadir check',
    contentDefault: '<i class="icon f7-icons">checkmark_square</i>',
    // template: ```<caracteristica original-data="usoedificio" title="Valor de Característica usoedificio" data-type="list" data-type-value="caracteristicas"data-recursive-field="hijas" 
    //     data-condition="'\${caracteristica}'.equalsIgnoreCase('[nombre]')" class=""><input type="checkbox" 
    //     data-conditional-attribute="checked=checked:'[valor]'.contains('\${valor}')" class=""></caracteristica>```,

    init: function () {
        MediumEditor.extensions.form.prototype.init.apply(this, arguments);
    },

    // Called when the button the toolbar is clicked
    // Overrides ButtonExtension.handleClick
    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.showForm();

        return false;
    },

    // Called by medium-editor to append form to the toolbar
    getForm: function () {
        if (!this.form) {
            this.form = this.createForm();
        }
        return this.form;
    },

    // Used by medium-editor when the default toolbar is to be displayed
    isDisplayed: function () {
        return this.getForm().style.display === 'block';
    },

    hideForm: function () {
        this.getForm().style.display = 'none';
        this.getInput("caracteristica").value = '';
        this.getInput("valor").value = '';
    },

    showForm: function (fontSize) {
        var caracteristica = this.getInput("caracteristica");

        this.base.saveSelection();
        this.hideToolbarDefaultActions();
        this.getForm().style.display = 'block';
        this.setToolbarPosition();

        caracteristica.focus();
    },

    // Called by core when tearing down medium-editor (destroy)
    destroy: function () {
        if (!this.form) {
            return false;
        }

        if (this.form.parentNode) {
            this.form.parentNode.removeChild(this.form);
        }

        delete this.form;
    },

    // core methods

    doFormSave: function () {
        this.base.restoreSelection();
    },

    doFormCancel: function () {
        this.base.restoreSelection();
        this.base.checkSelection();
    },

    // form creation and event handling
    createForm: function () {
        var doc = this.document,
            form = doc.createElement('div'),
            close = doc.createElement('a'),
            save = doc.createElement('a');

        // Font Size Form (div)
        form.className = 'medium-editor-toolbar-form';
        form.id = 'medium-editor-toolbar-form-fontsize-' + this.getEditorId();

        // Handle clicks on the form itself
        this.on(form, 'click', this.handleFormClick.bind(this));

        var caracteristica = doc.createElement('input');
        caracteristica.classList = "caracteristica"
        caracteristica.name = "caracteristica";
        form.appendChild(caracteristica);

        var valor = doc.createElement('input');
        valor.classList = "valor"
        valor.name = "valor";
        form.appendChild(valor);

        // Add save buton
        save.setAttribute('href', '#');
        save.className = 'medium-editor-toobar-save';
        save.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-check"></i>' :
                            '&#10003;';
        form.appendChild(save);

        // Handle save button clicks (capture)
        this.on(save, 'click', this.handleSaveClick.bind(this), true);

        // Add close button
        close.setAttribute('href', '#');
        close.className = 'medium-editor-toobar-close';
        close.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-times"></i>' :
                            '&times;';
        form.appendChild(close);

        // Handle close button clicks
        this.on(close, 'click', this.handleCloseClick.bind(this));

        return form;
    },

    getInput: function(clase){
        return this.getForm().querySelector(`input.${clase}`);
    },

    handleFormClick: function (event) {
        // make sure not to hide form when clicking inside the form
        event.stopPropagation();
    },

    handleSaveClick: function (event) {
        // Clicking Save -> create the font size
        event.preventDefault();
        this.base.restoreSelection();
        var self = this;
        var sel = window.getSelection();
        var caracteristica = this.getInput("caracteristica").value,
            valor = this.getInput("valor").value;

        if (window.getSelection && (sel = window.getSelection()).rangeCount) {
            var range = sel.getRangeAt(0);
            range.collapse(true);
            var element = self._createCheck(caracteristica, valor);
            range.insertNode(element);
    
            // Move the caret immediately after the inserted span
            range.setStartAfter(element);

            // Ahora añadimos un espacio al final de range para que no se quede pegado al elemento
            const auxElem = document.createTextNode('\u00A0');
            range.insertNode(auxElem);
            range.setStartAfter(auxElem);

            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }

        this.base.checkContentChanged();
        // this.doFormSave();
    },

    handleCloseClick: function (event) {
        // Click Close -> close the form
        event.preventDefault();
        this.doFormCancel();
    },

    _createCheck: function(caracteristicaName, valor) {
    // template: ```<caracteristica original-data="usoedificio" title="Valor de Característica usoedificio" data-type="list" data-type-value="caracteristicas" data-recursive-field="hijas" 
    //     data-condition="'\${caracteristica}'.equalsIgnoreCase('[nombre]')" class=""><input type="checkbox" 
    //     data-conditional-attribute="checked=checked:'[valor]'.contains('\${valor}')" class=""></caracteristica>```,
        var doc = this.document,
            caracteristica = doc.createElement('caracteristica'),
            checkbox = doc.createElement('input');
        caracteristica.setAttribute("original-data", caracteristicaName);
        caracteristica.setAttribute("data-type", "list");
        caracteristica.setAttribute("data-type-value", "caracteristicas");
        caracteristica.setAttribute("data-recursive-field", "hijas");
        caracteristica.setAttribute("data-condition", `'${caracteristicaName}'.equalsIgnoreCase('[nombre]')`);

        checkbox.setAttribute("type", "checkbox");
        checkbox.setAttribute("data-conditional-attribute", `checked=checked:'[valor]'.contains('${valor}')`);

        caracteristica.appendChild(checkbox);

        return caracteristica;
    },

});

export default Checbox;