<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Fichaje" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list 
        ref="rlist"
        :dbAdapter="Fichaje"
        :tableCode="'FICHAJE'"
        :tableMode="true"
        :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true" :controlsWidth="140"
      >
        <!-- <template v-slot:tableLinks> -->
          <!-- <a class="link" href="/fichaje/new/">{{$t('fichaje.nuevo')}}</a> -->
        <!-- </template> -->
        <!-- <template v-slot:optionsColumn="{ item }"> -->
          <!-- <f7-link v-tooltip="$t('common.ver') + ' Fichaje'" icon-f7="eye" color="green" :href="`/fichaje/view/${item.id}/`"></f7-link> -->
          <!-- <f7-link icon-f7="square_pencil" color="blue" :href="`/fichaje/edit/${item.id}/`"></f7-link> -->
          <!-- <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link> -->
        <!-- </template> -->
        <!-- <template v-slot:massiveActions="{}"></template> -->
      </r-dynamic-list>
    </f7-page-content>

  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style>
.aurora .f7-icons,
.aurora .material-icons {
  font-size: 16px;
}
</style>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
import Fichaje from "../../js/db/Fichaje.js";
import Utils from "../../js/Utils.js";

export default {
  components: {
    rDynamicList,
  },
  data() {
    return {
      Fichaje,
      codigoFichaje: null,
      Utils,
    };
  },
  computed: {},
  methods: {
    filasSeleccionadas: function () {
      let filas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return { id: selected.id };
      });
      return filas;
    }
  },
  mounted: function () {
    var self = this;
    var app = self.$f7;
  }
};
</script>