<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('servicios.servicios')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Servicio"
        :tableCode="'SERVICIO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="120"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/servicios/new/">{{$t('servicios.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/servicios/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.duplicar')" icon-f7="rectangle_stack_badge_plus" color="green" @click="clonar(item.id)"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/servicios/edit/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
        </template>
        
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Servicio from "./../../js/db/Servicio.js";
import Utils from "./../../js/Utils.js";
export default {
  components: {
    rDynamicList,
  },
  data() {
    return {
      Utils,
      Servicio,
      itemSelected: null,
      rPanelOpenedByBreakpoint: false
    };
  },
  computed: {},
  methods: {
    clonar: function(id) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Servicio.clonar({
        itemId: id,
        data: {}
      }).then(function(res){
        app.views.current.router.navigate(
          "/servicios/edit/"+res.id+"/"
        );
      })
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>