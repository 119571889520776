// Import Vue
// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import VTooltip
import VTooltip from 'v-tooltip';
import Vue from 'vue';
/* VENDOR */
import VueI18n from 'vue-i18n';
import LoadScript from 'vue-plugin-load-script';
/******** */
import VueSignature from "vue-signature-pad";
import VueTheMask from 'vue-the-mask';
// Import App Component
import App from '../components/app.vue';
import rPage from '../components/rPage.vue';
import '../css/app.css';
// Import Icons and App Custom Styles
import '../css/icons.css';
import vuetify from '../plugins/vuetify';
import messages from './translations.js';
import Utils from "./Utils.js";
import VueFilterDateFormat from './vue-filter-date-format';
import VueFilterDateParse from './vue-filter-date-parse.js';
import VueFilterNumberFormat from './vue-filter-number-format.js';
import VueFilterTimeInHoursParse from './vue-filter-time-in-hours-parse.js';









// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);


Vue.use(VueI18n)
Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse)
Vue.use(VueFilterTimeInHoursParse)
Vue.use(VueFilterNumberFormat)
Vue.use(VueTheMask)
Vue.use(LoadScript);
Vue.component('r-page', rPage);

Vue.use(VTooltip);
var lang = localStorage.getItem("lang") || 'es';
const i18n = new VueI18n({
    locale: lang,
    messages,
});

Vue.use(VueSignature);


Vue.directive('roles', {
    bind: function(el, binding, vnode) {
        // el.setAttribute("data-initial-display", el.style.display == null ? "" : el.style.display);
        checkPermission(el, binding.value.roles || binding.value, vnode);
    },
    update: function(el, binding, vnode) {
        checkPermission(el, binding.value.roles || binding.value, vnode);
    }
});
Vue.directive('noroles', {
    bind: function(el, binding, vnode) {
        // el.setAttribute("data-initial-display", el.style.display == null ? "" : el.style.display);
        checkNoPermission(el, binding.value, vnode);
    },
    update: function(el, binding, vnode) {
        checkNoPermission(el, binding.value, vnode);
    }
});
Vue.directive('focusableNextElement', {
    bind: function(el, binding, vnode) {
        if (typeof binding.value == 'undefined' || binding.value) {
            el.addEventListener('keyup', function(ev) {
                if (ev.key == 'Enter') {
                    ev.preventDefault();
                    Utils.focusNextElement(ev, el);
                }
            });
        }
    }
});

function checkPermission(el, roles, vnode) {
    let user = getUser();
    if (!user || user == null || (user.roles.indexOf("ADMIN") < 0 && roles.filter(r => user.roles.indexOf(r) > -1).length <= 0)) {
        // el.style.display = "none";
        // el.classList.add("display-none");
        removeElement(el, vnode);
    } 
    // else {
    //     if (tipoCliente && user.clientes && user.clientes.filter(c => c.tipoCliente == tipoCliente).length <= 0) {
    //         el.style.display = "none";
    //         el.classList.add("display-none");
    //     } else {
    //         el.style.display = el.getAttribute("data-initial-display");
    //         el.classList.remove("display-none");
    //     }
    // }
}
function checkNoPermission(el, roles, vnode) {
    let user = getUser();
    if (!user || user == null || roles.filter(r => user.roles.indexOf(r) > -1).length) {
        // el.style.display = "none";
        // el.classList.add("display-none");
        removeElement(el, vnode);
    }
}

function removeElement(el, vnode) {
    // replace HTMLElement with comment node
    const comment = document.createComment(' ');
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    });
    vnode.elm = comment;
    vnode.text = ' ';
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;
  
    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }
  
    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }

function getUser() {
    var user = localStorage.getItem("user");
    if (typeof user != "undefined" && user) {
        var userObj = JSON.parse(user);
        return userObj;
    }
    return null;
}


// Init App
new Vue({
    el: '#app',
    render: (h) => h(App),
    i18n,
    vuetify,
    // Register App Component
    components: {
        app: App
    },
});