<template>
  <div class="rpanel-inner" :class="{'rpanel-right':side=='right', 'rpanel-active':active }">
    <slot :rpanel="self"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      self: this
    };
  },
  methods: {
    open: function() {
      if (!this.$el.offsetParent.classList.contains("enough-width")) {
        this.active = true;
        this.$emit("open");
      }
    },
    close: function() {
      this.active = false;
      this.$emit("close");
    },
    toggle: function() {
      if (this.active) {
        this.close();
      } else {
        this.open();
      }
    },
    handleWindowResize() {
      let parentWidth = this.$el.offsetParent.clientWidth;
      if (parentWidth > this.widthParentBreakpoint) {
        if (!this.$el.offsetParent.classList.contains("enough-width")) {
          this.$el.offsetParent.classList.add("enough-width");
          this.close();
          this.$emit("openedByBreakpoint");
        }
      } else {
        if (this.$el.offsetParent.classList.contains("enough-width")) {
          this.$el.offsetParent.classList.remove("enough-width");
          this.$emit("closedByBreakpoint");
        }
      }
    }
  },
  props: {
    side: {
      type: String,
      default: "left"
    },
    widthParentBreakpoint: {
      type: Number,
      default: 960
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.$el.offsetParent.classList.add("content-with-panel-inner");
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>
<style>
.rpanel-inner {
  display: block;
  /*transform: translate3d(0, 0, 0) !important;*/

  z-index: 1000;
  box-sizing: border-box;
  position: absolute;
  top: calc(0px + 0px);
  top: calc(var(--f7-statusbar-height) + var(--f7-appbar-app-offset, 0px));
  /*height: calc(100% - 0px - 0px);
  height: calc(
    100% - var(--f7-statusbar-height) - var(--f7-appbar-app-offset, 0px)
  );*/
  height: 100%;
  transform: translate3d(0, 0, 0);
  width: 260px;
  width: var(--f7-panel-width);
  background-color: #fff;
  background-color: var(--f7-panel-bg-color);
  overflow: visible;
  will-change: transform;
  transition-property: transform;
  border-left: 1px solid #ddd;
  overflow: auto;
}
.rpanel-inner,
.rpanel-inner::after {
  transition-duration: var(--f7-panel-transition-duration);
}

.rpanel-inner.rpanel-right {
  right: 0;
  left: auto;
  top: 0;
  width: var(--f7-panel-width);
  width: var(--f7-panel-right-width, var(--f7-panel-width));
}

.rpanel-inner.rpanel-right {
  transform: translate3d(100%, 0, 0);
}
.rpanel-inner.rpanel-right.rpanel-active {
  transform: translate3d(0, 0, 0);
  display: block;
  box-shadow: 0px 0px 10px #aaa;
}

.page-with-panel-inner > .page-content {
  overflow: hidden;
}
/*.panel-inner {
  position: absolute;
}
.content-with-panel-inner {
  margin-right: 0 !important;
}*/
.content-with-panel-inner {
  overflow-x: hidden !important;
}

.content-with-panel-inner.enough-width > *:not(.rpanel-inner) {
  margin-right: var(--f7-panel-right-width, var(--f7-panel-width)) !important;
}
.content-with-panel-inner.enough-width > .rpanel-inner {
  display: block;
  transform: translate3d(0, 0, 0) !important;
}

/*@media (min-width: 960px) {
  .content-with-panel-inner > *:not(.rpanel-inner) {
    margin-right: var(--f7-panel-right-width, var(--f7-panel-width)) !important;
  }
  .rpanel-inner {
    display: block;
    transform: translate3d(0, 0, 0) !important;
  }
}*/
</style>