import Items from './../items/items.vue';
import ItemForm from './../items/item-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/equipos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('equipos', ['ADMIN', 'INSPECTOR'], Items, { esEquipo: true }),
        keepAlive: true
    },
    {
        path: '/equipos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('equipos/new', ['ADMIN', 'INSPECTOR'], ItemForm, { esEquipo: true })
    },
    {
        path: '/equipos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('equipos/edit', ['ADMIN', 'INSPECTOR'], ItemForm, { esEquipo: true })
    },
    {
        path: '/equipos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('equipos/view', ['ADMIN', 'INSPECTOR'], ItemForm, { editable: false, esEquipo: true })
    },
];
export default routes;