<template>
  <f7-popup
    ref="popupEnviarEmail"
    class="popup-enviar-email"
    :opened="true"
    @popup:opened="onPopupOpened"
    @popup:closed="onPopupClosed"
    swipe-to-close
  >
    <f7-page>
        <f7-navbar>
            <f7-nav-left
                v-if="$refs.rformEnviarEmail && $refs.rformEnviarEmail.formData && $refs.rformEnviarEmail.formData.from">
                <f7-link @click="$refs.rformEnviarEmail.saveItem()">Enviar</f7-link>
            </f7-nav-left>
            <f7-nav-title>Enviar Email</f7-nav-title>
            <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <!-- <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        >
        <f7-card-header>
            * Si no se informan destinatarios, el envío de correo se realizará a la dirección informada en el campo "Email Comunicaciones" de la línea y si este no está informado, se enviará al "email de contacto" del pedido
        </f7-card-header>
        </f7-card> -->
        <r-form ref="rformEnviarEmail" @save="onSaveEnviarEmail" :dbAdapter="null" classContent="full-height"
            :readonly="false" :hidePreloaderOnSave="false" :defaultData="{ enlazarAdjuntos: true }">
            <template v-slot:default="{ rform }">
                <f7-popover style="width: 250px;" :closeByBackdropClick="false" :backdrop="true" closeOnEscape
                    ref="popover">
                    <f7-navbar :title="$t('Opciones disponibles')">
                        <f7-nav-right>
                            <f7-link popover-close icon-f7="multiply"></f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-list no-hairlines-md>
                        <ul>
                            <li v-for="(opcion, index) in opciones" :key="'select.opcion-' + index" class="popover_option"
                                @click="opcion.action ? ejecutar(opcion.action, opcion.params) : setOptionClicked(index)"
                                :value="opcion.value">{{ opcion.name }}</li>
                        </ul>
                    </f7-list>
                </f7-popover>
                <f7-card>
                    <f7-card-content>
                        <f7-list>
                            <ul>
                                <f7-row>
                                    <f7-col width="100" medium="100">
                                        <div style="padding: 4px 10px;" @contextmenu.prevent="showOptions(
                    {
                        opciones: [
                            { name: 'Datos del usuario', value: '{{$.empleadoActual.email}}', valueName: '{{$.empleadoActual.nombre}}' },
                            { name: 'Datos del Gestor', value: '{{$.empleadoGestor.email}}', valueName: '{{$.empleadoGestor.nombre}}' },
                            { name: 'Entrada manual', value: '', action: 'entradaManualCampo', params: [rform.formData, ['fromName', 'from']] },
                        ],
                        callback: (selected) => { $set(rform.formData, 'fromName', { name: selected.name, value: selected.valueName }); $set(rform.formData, 'from', { name: selected.name, value: selected.value }); }
                    },
                    $event,
                    true
                )">
                                            <f7-block-title>Remitente</f7-block-title>
                                            <div class="block-chips">
                                                <f7-chip v-if="rform.formData.from && rform.formData.fromName"
                                                    :text="rform.formData.fromName.name"
                                                    :tooltip="rform.formData.fromName.value + ' - ' + rform.formData.from.value"
                                                    deleteable
                                                    @delete="rform.formData.from = undefined; rform.formData.fromName = undefined"></f7-chip>
                                            </div>
                                        </div>
                                    </f7-col>
                                    <f7-col width="100" medium="100">
                                        <div style="padding: 4px 10px;" @contextmenu.prevent="showOptions(
                    {
                        opciones: [
                            { name: 'e-mail del contacto por defecto del cliente', value: '{{$.cliente.contactoDefectoDTO.canalesComunicacion[?(@.tipo == \'EMAIL\')].valor}}' },
                            { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'to'] },
                        ],
                        callback: (selected) => {
                            if (!rform.formData.to) {
                                $set(rform.formData, 'to', []);
                            }
                            rform.formData.to.push(selected);
                        }
                    },
                    $event,
                    true
                )">
                                            <f7-block-title>Destinatarios</f7-block-title>
                                            <div class="block-chips">
                                                <f7-chip v-for="(chip, index) in rform.formData.to"
                                                    :key="'chip-to-' + index" :text="chip.name" deleteable
                                                    @delete="deleteChip($event, rform.formData.to, index)"></f7-chip>
                                            </div>
                                        </div>
                                    </f7-col>
                                    <f7-col width="100" medium="100">
                                        <div style="padding: 4px 10px;" @contextmenu.prevent="showOptions(
                    {
                        opciones: [
                            { name: 'e-mail de tu usuario', value: '{{$.empleadoActual.email}}' },
                            { name: 'e-Mail del Gestor', value: '{{$.empleadoGestor.email}}' },
                            { name: 'e-mail del contacto por defecto del cliente', value: '{{$.cliente.contactoDefectoDTO.canalesComunicacion[?(@.tipo == \'EMAIL\')].valor}}' },
                            { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'cc'] },
                        ],
                        callback: (selected) => {
                            if (!rform.formData.cc) {
                                $set(rform.formData, 'cc', []);
                            }
                            rform.formData.cc.push(selected);
                        }
                    },
                    $event,
                    true
                )">
                                            <f7-block-title>En copia</f7-block-title>
                                            <div class="block-chips">
                                                <f7-chip v-for="(chip, index) in rform.formData.cc"
                                                    :key="'chip-cc-' + index" :text="chip.name" deleteable
                                                    @delete="deleteChip($event, rform.formData.cc, index)"></f7-chip>
                                            </div>
                                        </div>
                                    </f7-col>
                                    <f7-col width="100" medium="100">
                                        <div style="padding: 4px 10px;" @contextmenu.prevent="showOptions(
                    {
                        opciones: [
                            { name: 'e-mail del usuario', value: '{{$.empleadoActual.email}}' },
                            { name: 'e-Mail del Gestor', value: '{{$.empleadoGestor.email}}' },
                            { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'cco'] },
                        ],
                        callback: (selected) => {
                            if (!rform.formData.cco) {
                                $set(rform.formData, 'cco', []);
                            }
                            rform.formData.cco.push(selected);
                        }
                    },
                    $event,
                    true
                )">
                                            <f7-block-title>En copia oculta</f7-block-title>
                                            <div class="block-chips">
                                                <f7-chip v-for="(chip, index) in rform.formData.cco"
                                                    :key="'chip-cco-' + index" :text="chip.name" deleteable
                                                    @delete="deleteChip($event, rform.formData.cco, index)"></f7-chip>
                                            </div>
                                        </div>
                                    </f7-col>
                                </f7-row>
                            </ul>
                        </f7-list>
                    </f7-card-content>
                </f7-card>
                <f7-card class="elevation-3 padding-vertical-half padding-right">
                    <f7-card-content>
                        <f7-list class="list-form">
                            <ul>
                                <f7-row>
                                    <f7-col width="100" medium="100">
                                        <r-input class="col-100 medium-25" floating-label
                                            :label="$t('oportunidad.plantillaEmail')" :form="rform" type="select"
                                            name="plantillaEmail" :clear-button="false" @change="chargePlantillaEmail">
                                            <option value="SIN">Sin plantilla</option>
                                            <option v-for="plantilla in plantillas" :value="plantilla.path"
                                                :key="plantilla.path">{{ plantilla.nombre }}</option>
                                        </r-input>



                                        <div style="padding: 4px 10px;" @contextmenu.prevent="showOptions(
                    {
                        opciones: [
                            { name: 'Presentación de la empresa', value: 'Propuesta de prestación de servicios' },
                        ],
                        callback: (selected) => {
                            $set(rform.formData, 'asunto', selected.value);
                        }
                    },
                    $event,
                    true
                )">
                                            <r-input class="col-100" floating-label outline label="Asunto" :form="rform"
                                                name="asunto" />
                                        </div>
                                        <r-rich-text-input v-if="rform.formData.plantillaEmail"
                                            style="overflow: hidden;" ref="rRichTextInput" class="col-100"
                                            floating-label label="Cuerpo del email" :form="rform"
                                            :name="'cuerpo'"></r-rich-text-input>
                                    </f7-col>
                                </f7-row>
                            </ul>
                        </f7-list>
                    </f7-card-content>
                </f7-card>
                <f7-card>
                    <f7-card-content>
                        <f7-list>
                            <ul>
                                <f7-row>
                                    <f7-col width="100" medium="100">
                                        <r-files class="col-100" floating-label outline label="Adjuntar archivos"
                                            :form="rform" name="attachments" />
                                    </f7-col>
                                </f7-row>
                            </ul>
                            <ul>
                                <f7-row>
                                    <f7-col width="100" medium="100">
                                        <r-input floating-label outline :label="$t('Enviar como enlaces')" :form="rform"
                                            :simple="true" type="check" name="enlazarAdjuntos" :readonly="false"
                                            :override-readonly="true"></r-input>
                                    </f7-col>
                                </f7-row>
                            </ul>
                        </f7-list>
                    </f7-card-content>
                </f7-card>
                <f7-row>
                    <f7-col width="100" medium="100">
                        <f7-button fill icon-f7="disk" text="Enviar" class="display-flex margin-vertical margin-left"
                            color="green" @click="rform.saveItem()"></f7-button>
                    </f7-col>
                </f7-row>
            </template>
        </r-form>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .popover_option {
    border-top: 1px solid #e0e0e0;
    padding: 5px;
    cursor: pointer;
  }
  .popover_option:hover {
    background-color: #707594;
    color:white;
  }
  .block-chips {
    display: flex;
    flex-wrap: wrap;
    background-color: #edecee;
    padding: 4px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 4px;
    min-height: 34px;
  }
</style>
<script>
import rFiles from "../../../components/rFiles.vue";
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import rRichTextInput from "../../../components/rRichTextInput.vue";
import Utils from "../../../js/Utils.js";
import Documento from "../../../js/db/Documento.js";
import DocumentoGestion from "../../../js/db/DocumentoGestion.js";
import Oportunidad from "../../../js/db/Oportunidad.js";

export default {
  components: {
    rForm,
    rInput,
    rRichTextInput,
    rFiles,
    DocumentoGestion
  },
  data() {
    return {
      Oportunidad,
      Documento,
      Utils,
      plantillas: [],
      contexto: {},
      opciones: false,
      popupOpened: false,
      translator: {
        "from": "email del remitente",
        "fromName": "nombre del remitente",
        "to": "email del destinatario",
        "cc": "email en copia",
        "cco": "email en copia oculta",
      }
    };
  },
  props: {
    selected: {
      type: Array,
      default: function() {
        return [];
      }
    },
    refName: {
      type: String,
      default: "popupEnviarEmail"
    },
    className: {
      type: String,
      default: "popup-enviar-email"
    }
  },
  computed: {},
  methods: {
    ejecutar: function(action, params) {
      const self = this;
      self[action](...params);
    },
    entradaManualLista: function(formData, targetFieldName) {
      const self = this;
      const app = self.$f7;
      if(!formData[targetFieldName]) {
        self.$set(formData, targetFieldName, []);
      }
      app.dialog.prompt(self.translator[targetFieldName], 'Entrada manual', (valor) => {
        formData[targetFieldName].push({name: valor, value: valor});
      });
    },
    entradaManualCampo: function(formData, targetFieldName) {
      const self = this;
      const app = self.$f7;
      if(targetFieldName instanceof Array) {
        targetFieldName.forEach(field => {
          app.dialog.prompt(self.translator[field], 'Entrada manual', (valor) => {
            self.$set(formData, field, {name: valor,value: valor});
          });
        });
      } else {
        app.dialog.prompt(self.translator[targetFieldName], 'Entrada manual', (valor) => {
          self.$set(formData, targetFieldName, [{name: valor, value: valor}]);
        });
      }
    },
    deleteChip: function(e, array, index) {
      const self = this;
      const app = self.$f7;
      const target = e.target;
      app.dialog.confirm('¿Desea eliminar el valor de la lista?', () => {
        array.splice(index,1);
      });
    },
    showOptions: function(property, event, replace = true) {
      const self = this;
      const opciones = property.opciones;
      self.contexto.event = event;
      self.contexto.replace = replace;
      if(opciones && opciones.length) {
        // Crearemos un select en el aire y el valor seleccionado lo pegaremos en el innerHtml del elemento actual.
        self.opciones = opciones;
        self.contexto.property = property;
        self.$refs.popover.open(event.target);
      }
    },
    setOptionSelected: function(event) {
      const self = this;
      let optionSelected = self.opciones[event.target.options.selectedIndex];
      self.contexto.property.callback(optionSelected);
      self.$refs.popover.close(self.contexto.event.target);
      self.contexto = {};
    },
    setOptionClicked: function(index) {
      const self = this;
      let optionSelected = self.opciones[index];
      self.contexto.property.callback(optionSelected);
      self.$refs.popover.close(self.contexto.event.target);
      self.contexto = {};
    },
    recuperarPlantillas: function() {
      var self = this;
      var app = self.$f7;
      self.$refs.rformEnviarEmail.formData.plantillaEmail = "SIN";
      DocumentoGestion.getListaDocumentosPorTipo("EMAIL").then(data => {
        self.plantillas = data.list.filter(l=>l.nombre.endsWith("_oportunidad"));
      });
    },
    chargePlantillaEmail: function(e) {
      var self = this;
      var app = self.$f7;
      if(typeof e == 'object') {
        let formData = self.$refs.rformEnviarEmail.formData;
        if(self.$refs.rRichTextInput) self.$refs.rRichTextInput.readonly = false;
        if(!formData.plantillaEmail || formData.plantillaEmail == '') {
          self.$set(formData, "cuerpo", "");
        } else if(formData.plantillaEmail == 'SIN') {
          self.$set(formData, "cuerpo", "<html><body></body></html>");
        } else {
          Documento.getDocumento(formData.plantillaEmail).then(data => {
            const dec = new TextDecoder("utf-8");
            let html = dec.decode(data);
            console.log(self.$refs.rformEnviarEmail.formData.plantillaEmail);
            console.log(html);
            if(self.$refs.rRichTextInput) self.$refs.rRichTextInput.readonly = true;
            self.$set(formData, "cuerpo", html);
          });
        }
      }
    },
    onSaveEnviarEmail (dataEnviar) {
      const self = this;
      const app = self.$f7;

      let data = [];
      // Si tenemos attachments, los procesamos para que el contenido del campo _base64 pase al nuevo campo base64 que vamos a enviar al servidor
      if (dataEnviar.attachments) {
        dataEnviar.attachments.forEach(attachment => {
          if (attachment._base64) {
            attachment.base64 = attachment._base64;
          }
        });
      } 
      let oportunidades = self.selected;
      oportunidades.forEach(selected => {
        data.push({
          fromName: dataEnviar.fromName.value,
          from: dataEnviar.from.value,
          to: dataEnviar.to?dataEnviar.to.map(t => t.value.trim()):[],
          cc: dataEnviar.cc?dataEnviar.cc.map(t => t.value.trim()):[],
          cco: dataEnviar.cco?dataEnviar.cco.map(t => t.value.trim()):[],
          asunto: dataEnviar.asunto,
          plantillaEmail: dataEnviar.plantillaEmail,
          cuerpo: dataEnviar.cuerpo,
          idOportunidad: selected.id,
          documentosAAdjuntar: dataEnviar.documentosAAdjuntar,
          attachments: dataEnviar.attachments,
          enlazarAdjuntos: dataEnviar.enlazarAdjuntos
        });
      });
      app.preloader.show();
      Oportunidad.enviarEmail(data).then(res => {
        app.preloader.hide();
        app.dialog.alert("Emails enviados");
        self.$refs.rformEnviarEmail.clear();
        if (self.$refs.popupEnviarEmail) {
          self.$refs.popupEnviarEmail.close();
        }
      }).catch(e => {
        if (typeof e == "string") {
          e = {
            message: e
          };
        }
        app.dialog.alert("Error enviando emails" + e.message);
      }).then(_ => {
        app.preloader.hide();
      });
    },
    onPopupOpened: function(e) {
      var self = this;
      var app = self.$f7;
      self.popupOpened = true;
      self.recuperarPlantillas();
    },
    onPopupClosed: function(e) {
      var self = this;
      var app = self.$f7;
      this.$emit('email-enviado', true);

    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>