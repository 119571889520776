<template>
  <f7-page :page-content="true">
    <r-form
      ref="rform"
      class="full-height"
      classContent="page-content full-height"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Campania"
      :defaultData="Object.assign({}, defaultData, {gestor: Utils.getUser().username})"
      :documentoAdapter="Documento"
      @loadItem="() => loadData()"
    >
    <template v-slot:before="{ rform }">
      <f7-navbar>
        <f7-nav-left>
          <f7-link v-if="esPopup" @click.stop="$emit('close')">{{ $t('common.cerrar') }}</f7-link>
          <f7-link v-else @click.stop="$f7router.back()">&lt; {{ $t('atras') }}</f7-link>
        </f7-nav-left>
        <f7-nav-title v-if="id && !readonly" sliding>{{$t('campania.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="id" sliding>{{ $t('campania.campania')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{ $t('campania.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <template
              slot="right"
              v-else
            >
              <div class="menu-item menu-item-dropdown bg-color-primary" >
                <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
                <div class="menu-dropdown menu-dropdown-right bg-color-primary" style="z-index:10">
                  <div class="menu-dropdown-content bg-color-primary text-align-right">
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData && rform.formData.id && (!rform.formData.oportunidades || !rform.formData.oportunidades.length)"
                      @click="generarOportunidades">{{ $t('Generar oportunidades') }}
                    </div>
                    <div class="menu-dropdown-divider"></div>
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData"
                      @click="readonly = false">{{ $t('Editar') }}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer"
                      @click="deleteItem(false)">{{ $t('Eliminar') }}
                    </div>
                  </div>
                </div>
              </div>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <template v-slot:default="{ rform }">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        <f7-button
          class="col-20"
          v-noroles="['ENAC', 'PRODUCCION']"
          icon-f7="calendar_circle"
          @click="popupTareaEditable = true; popupTareasOpened = true"
          v-if="rform && rform.formData && rform.formData.id"
        >Crear tarea</f7-button>
        <div name="Datos Generales" style="display: flex; flex-direction: row; justify-content: end;">
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Datos Generales' : 'Mostrar Datos Generales'"
            :icon-f7="showDetalle ? 'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Datos Generales' : 'Mostrar Datos Generales'"
            style="padding-left: 7px;font-size: 10px;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" :medium="esPopup ? '100' : '50'">
              <ul>
                <div class="label-group col-100">Datos Generales</div>
                <r-input
                  outline
                  floating-label
                  :label="$t('campania.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  v-if="readonly"
                ></r-input>
                <r-input
                  outline
                  floating-label
                  :label="$t('campania.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                  :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* El nombre es obligatorio'}"
                ></r-input>
                <r-input
                  outline
                  floating-label
                  :label="$t('campania.estrategia')"
                  :form="rform"
                  type="text"
                  name="estrategia"
                  :validation="{ isValid: str => { return !validator.isEmpty(str, { ignore_whitespace: true }) }, errorMessage: '* La estrategia es obligatoria'}"
                ></r-input>
                <r-autocomplete
                  outline
                  :dbAdapter="Empleado"
                  key="responsable"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('campania.responsable')"
                  :form="rform"
                  name="responsable"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                  :showNavigationLink="false"
                  :validation="{ isValid: str => { return !validator.isEmpty(str, { ignore_whitespace: true }) }, errorMessage: '* El responsable es obligatorio'}"
                ></r-autocomplete>
                <r-autocomplete
                  outline
                  :dbAdapter="Empleado"
                  key="gestor"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('Gestor')"
                  :form="rform"
                  name="gestor"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                  :showNavigationLink="false"
                  :validation="{ isValid: str => { return !validator.isEmpty(str, { ignore_whitespace: true }) }, errorMessage: '* El gestor es obligatorio'}"
                ></r-autocomplete>
                <r-input
                  outline
                  floating-label
                  class="col-100 medium-50"
                  :label="$t('campania.fechaInicial')"
                  :form="rform"
                  type="datepicker"
                  name="fechaInicial"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  outline
                  floating-label
                  class="col-100 medium-50"
                  :label="$t('campania.fechaFinal')"
                  :form="rform"
                  type="datepicker"
                  name="fechaFinal"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-clientes" tab-link-active @click="tabActive = 'clientes'; loadData()">{{ $t('campania.clientes') }} ( {{ (rform.formData.clientes || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-ofertas" @click="tabActive = 'ofertas'; loadData()">{{ $t('campania.ofertas') }} ( {{ (rform.formData.ofertas || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-pedidos" @click="tabActive = 'pedidos'; loadData()">{{ $t('campania.pedidos') }} ( {{ (rform.formData.pedidos || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-lineas-pedidos" @click="tabActive = 'lineas-pedidos'; loadData()">{{ $t('campania.lineasPedidos') }} ( {{ (rform.formData.lineasPedidos || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-procesos" @click="tabActive = 'procesos'; loadData()">{{ $t('campania.procesos') }} ( {{ (rform.formData.procesos || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-comercial" @click="tabActive = 'comercial'; loadData()">{{ $t('campania.objetivosComercial') }} ( {{ (rform.formData.comerciales || []).length }} )</f7-button>
          <f7-button fill tab-link="#tab-oportunidades" @click="tabActive = 'oportunidades'; loadData()">{{ $t('campania.oportunidades') }} ( {{ (rform.formData.oportunidades || []).length }} )</f7-button>
          <f7-button v-noroles="['ENAC', 'PRODUCCION']"  @click="tabActive = 'tareas'; loadData()" fill tab-link="#tab-tareas">{{ $t("Tareas") }}</f7-button>
          <f7-button fill tab-link="#tab-documentos" @click="tabActive = 'documentos'; loadData()">{{ $t('campania.documentos') }} ( {{ (rform.formData.documentos || []).length }} )</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-clientes" tab-active>
          <template v-if="tabActive == 'clientes'">
            <f7-block-title>
                {{ $t('campania.clientes') }}
                <f7-button v-if="!readonly" fill @click="popuploadOpened = true" style="display: inline-block;">{{ $t('campania.cargarClientes') }}</f7-button>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(cliente) in clientes" :key="'cliente-' + cliente.id"
                >
                  <f7-card-content>
                    {{ cliente.codigo }} - {{ cliente.razonSocial }} - Potencial: {{ cliente.potencial ? 'SI' : 'NO' }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(cliente.id)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a cliente ' + cliente.codigo"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/clientes/view/' + cliente.id + '/'"
                    :key="'cliente-navigate-' + cliente.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-ofertas">
          <template v-if="tabActive == 'ofertas'">
            <f7-block-title>
              {{ $t('campania.ofertas') }}
              <f7-button v-if="!readonly" fill @click="popuploadOpened = true" style="display: inline-block;">{{ $t('campania.cargarOfertas') }}</f7-button>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(oferta) in ofertas" :key="'oferta-' + oferta.id"
                >
                  <f7-card-content>
                    {{ oferta.numero }} - {{ oferta.codigo }} - {{ oferta.cliente.razonSocial }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(oferta.id)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a oferta ' + (oferta.numero || oferta.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/ofertas/view/' + oferta.id + '/'"
                    :key="'oferta-navigate-' + oferta.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-pedidos">
          <template v-if="tabActive == 'pedidos'">
            <f7-block-title>
              {{ $t('campania.pedidos') }}
              <f7-button v-if="!readonly" fill @click="popuploadOpened = true" style="display: inline-block;">{{ $t('campania.cargarPedidos') }}</f7-button>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(pedido) in pedidos" :key="'pedido-' + pedido.id"
                >
                  <f7-card-content>
                    {{ pedido.numero }} - {{ pedido.codigo }} - {{ pedido.cliente.razonSocial }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(pedido.id)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a pedido ' + (pedido.numero || pedido.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/pedidos/view/' + pedido.id + '/'"
                    :key="'pedido-navigate-' + pedido.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-lineas-pedidos">
          <template v-if="tabActive == 'lineas-pedidos'">
            <f7-block-title>
              {{ $t('campania.lineasPedidos') }}
              <f7-button v-if="!readonly" fill @click="popuploadOpened = true" style="display: inline-block;">{{ $t('campania.cargarLineasPedidos') }}</f7-button>
              <input :multiple="false" type="file" ref="inputFileLineas" style="display:none;" @change="loadExcelLineas" />
              <f7-button v-if="!readonly" v-tooltip="'El formato del excel debe ser de 2 columnas, la primera el numero de pedido y la segunda el numero de linea'" @click="$refs.inputFileLineas.click()" fill style="display: inline-block;">{{ $t('Cargar desde excel') }}</f7-button>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(linea) in lineasPedidos" :key="'linea-pedido-' + linea.id + '-' + linea.lineas.numero"
                >
                  <f7-card-content>
                    {{ linea.numero }} - {{ linea.lineas.numero }} - {{ linea.cliente.razonSocial }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(linea.id + '-' + linea.lineas.numero)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a pedido ' + (linea.numero || linea.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/pedidos/view/' + linea.id + '/'"
                    :key="'linea-pedido-navigate-' + linea.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-procesos">
          <template v-if="tabActive == 'procesos'">
            <f7-block-title>
              {{ $t('campania.procesos') }}
              <f7-link v-if="!readonly" @click="addProceso" >{{ $t('campania.add') }}</f7-link>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100">
                <f7-card
                  v-for="(proceso, index) in rform.formData.procesos" :key="'proceso-' + index"
                >
                  <f7-card-content>
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-input
                        outline
                        floating-label
                        class="col-15"
                        :label="$t('campania.orden')"
                        :form="rform"
                        type="number"
                        step="1"
                        min="1"
                        :name="'procesos.' + index + '.orden'"
                      ></r-input>
                      <r-autocomplete
                        outline
                        :dbAdapter="TipoTarea"
                        class="col-50"
                        floating-label
                        :label="$t('campania.tipoTarea')"
                        :form="rform"
                        :name="'procesos.' + index + '.tipoTarea'"
                        labelProp="nombre"
                        :idProp="null"
                        prefixProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['id', 'codigo', 'nombre']"
                        :showNavigationLink="false"
                      ></r-autocomplete>
                      <r-input
                        outline
                        floating-label
                        class="col-15"
                        :label="$t('campania.numeroTareas')"
                        :form="rform"
                        type="number"
                        step="1"
                        min="1"
                        :name="'procesos.' + index + '.numeroTareas'"
                      ></r-input>
                      <r-input
                        outline
                        floating-label
                        class="col-15"
                        :label="$t('campania.ponderacion')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'procesos.' + index + '.ponderacion'"
                      ></r-input>
                      <span class="col-5"></span>
                    </f7-row>
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="$delete(rform.formData.procesos, index)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
            <f7-block-title>
              {{ $t('campania.procesos') }}
              <f7-link v-if="!readonly" @click="addProceso" >{{ $t('campania.add') }}</f7-link>
            </f7-block-title>
          </template>
        </f7-tab>
        <f7-tab id="tab-comercial">
          <template v-if="tabActive == 'comercial'">
            <f7-block-title>
              {{ $t('campania.objetivosComercial') }}
              <f7-link @click="loadComerciales(true)" >{{ $t('campania.updateData') }}</f7-link>
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines" style="z-index: 2;">
              <f7-col width="100">
                <f7-card
                  v-for="(comercial, index) in rform.formData.comerciales" :key="'comercial-' + index"
                >
                  <f7-card-content>
                    <f7-row no-gap class="list list-form no-hairlines">
                      <r-autocomplete
                        outline
                        :dbAdapter="Empleado"
                        class="col-25"
                        floating-label
                        :label="$t('campania.comercial')"
                        :form="rform"
                        :name="'comerciales.' + index + '.comercial'"
                        labelProp="nombre"
                        idProp="username"
                        prefixProp="username"
                        idFieldDetail="id"
                        :showNavigationLink="true"
                        :readonly="true"
                        :override-readonly="true"
                      ></r-autocomplete>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.numOportunidades')"
                        :form="rform"
                        type="number"
                        step="1"
                        :readonly="true"
                        :override-readonly="true"
                        :name="'comerciales.' + index + '.numOportunidades'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.vntc')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'vntc'"
                        :readonly="true"
                        :override-readonly="true"
                        :label-tooltip="'Volumen de negocio total de la campaña'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.vntoc')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'comerciales.' + index + '.vntoc'"
                        :label-tooltip="'Volumen de negocio total ofertado de la campaña objetivo del comercial'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.vntcc')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'comerciales.' + index + '.vntcc'"
                        :label-tooltip="'Volumen de negocio total contratado de la campaña objetivo del comercial'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.vntocr')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'comerciales.' + index + '.vntocr'"
                        :readonly="true"
                        :override-readonly="true"
                        :label-tooltip="'Volumen de negocio ofertado real del comercial'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.vntccr')"
                        :form="rform"
                        type="number"
                        step="0.01"
                        :name="'comerciales.' + index + '.vntccr'"
                        :readonly="true"
                        :override-readonly="true"
                        :label-tooltip="'Volumen de negocio contratado real del comercial'"
                      ></r-input>
                      <r-input
                        outline
                        style="padding: 0;"
                        floating-label
                        class="col-10"
                        :label="$t('campania.tareasRealizadas')"
                        :form="rform"
                        type="number"
                        step="1"
                        :name="'comerciales.' + index + '.tareasRealizadas'"
                        :readonly="true"
                        :override-readonly="true"
                      ></r-input>
                      <span class="col-5"></span>
                    </f7-row>
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="$delete(rform.formData.procesos, index)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-oportunidades">
          <template v-if="tabActive == 'oportunidades'">
            <f7-block-title>
                {{ $t('campania.oportunidades') }}
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(oportunidad) in oportunidades" :key="'oportunidades-' + oportunidad.id"
                >
                  <f7-card-content v-if="oportunidad.lineasPedido && oportunidad.lineasPedido.length">
                    {{ oportunidad.codigo }} - Lineas {{ oportunidad.lineasPedido.map(l => l.numero).join(', ') }} del Pedido {{ oportunidad.pedido.numero || oportunidad.pedido.codigo }} - Gestor {{ oportunidad.gestor }}
                  </f7-card-content>
                  <f7-card-content v-else-if="oportunidad.pedido">
                    {{ oportunidad.codigo }} - Pedido {{ oportunidad.pedido.numero || oportunidad.pedido.codigo }} - Gestor {{ oportunidad.gestor }}
                  </f7-card-content>
                  <f7-card-content v-else-if="oportunidad.oferta">
                    {{ oportunidad.codigo }} - Oferta {{ oportunidad.oferta.numero || oportunidad.oferta.codigo }} - Gestor {{ oportunidad.gestor }}
                  </f7-card-content>
                  <f7-card-content v-else-if="oportunidad.cliente">
                    {{ oportunidad.codigo }} - Cliente {{ oportunidad.cliente.codigo }} - {{ oportunidad.cliente.razonSocial }} - Gestor {{ oportunidad.gestor }}
                  </f7-card-content>
                  <f7-card-content v-else>
                    {{ oportunidad.codigo }} - Gestor {{ oportunidad.gestor }}
                  </f7-card-content>
                  <f7-link
                    external
                    v-tooltip="'Navegar a oportunidad ' + oportunidad.codigo"
                    class="margin-half link-inline link-inline-0 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/oportunidades/view/' + oportunidad.id + '/'"
                    :key="'oportunidad-navigate-' + oportunidad.id"
                  ></f7-link>
                  <f7-link
                    v-if="oportunidad.pedido"
                    external
                    v-tooltip="'Navegar a pedido ' + (oportunidad.pedido.numero || oportunidad.pedido.codigo)"
                    class="margin-half link-inline link-inline-1 color-blue"
                    icon-f7="doc_text_search"
                    :href="'#!/pedidos/view/' + oportunidad.pedido.id + '/'"
                    :key="'pedido-oport-navigate-' + oportunidad.pedido.id"
                  ></f7-link>
                  <f7-link
                    v-else-if="oportunidad.oferta"
                    external
                    v-tooltip="'Navegar a oferta ' + (oportunidad.oferta.numero || oportunidad.oferta.codigo)"
                    class="margin-half link-inline link-inline-1 color-blue"
                    icon-f7="doc_text_search"
                    :href="'#!/ofertas/view/' + oportunidad.oferta.id + '/'"
                    :key="'oferta-oport-navigate-' + oportunidad.oferta.id"
                  ></f7-link>
                  <f7-link
                    v-else-if="oportunidad.cliente"
                    external
                    v-tooltip="'Navegar a cliente ' + oportunidad.cliente.codigo"
                    class="margin-half link-inline link-inline-1 color-blue"
                    icon-f7="doc_text_search"
                    :href="'#!/clientes/view/' + oportunidad.cliente.id + '/'"
                    :key="'cliente-oport-navigate-' + oportunidad.cliente.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-tareas" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.id">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Tarea"
            :tableCode="'TAREASCAMPANIA'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['codigoCampania', 'estado']"
            :mandatoryFilter ="{ 'codigoCampania': $refs.rform.formData.codigo, 'estado': ['REALIZADA', 'PLANIFICADA'] }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-block-title>
            {{ $t('campania.documentos') }}
          </f7-block-title>
          <f7-card class="elevation-3 padding-vertical-half padding-right" v-if="tabActive == 'documentos'">
            <f7-card-content>
              <div v-if="!rform.formData.documentos || (rform.formData.documentos && !rform.formData.documentos.length > 0)">La campaña no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
      </f7-tabs>

    </template>
    </r-form>
    <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
      <tarea-form :id="tareaId" :editable="popupTareaEditable" @close="$refs.popupTarea.close();"  
        :defaultData="{ codigoCampania: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
      ></tarea-form>
    </f7-popup>
    <f7-popup ref="popupload" class="popupload" tablet-fullscreen v-if="popuploadOpened" :opened="true"  @popup:close="popuploadOpened = false">
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>{{ $t('campania.cerrar') }}</f7-link>
          </f7-nav-left>
          <f7-nav-title>
            {{ 'Carga de ' + tabActive }}
          </f7-nav-title>
        </f7-navbar>
        <f7-page-content class="full-height" v-if="tabActive == 'clientes'">
          <r-dynamic-list
            ref="rlist"
            :dbAdapter="Cliente"
            :tableCode="'CLIENTE'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :massiveActions="false"
            :massiveActionsSelectAll="true"
            :controlsWidth="70"
          >
            <template v-slot:massiveActions="{ selecteds }">
              <f7-button
                fill
                icon-f7="doc_chart_fill"
                text="Seleccionar"
                class="display-flex"
                color="blue"
                @click="seleccionar(selecteds)"
              ></f7-button>
            </template>
          </r-dynamic-list>
        </f7-page-content>
        <f7-page-content class="full-height" v-if="tabActive == 'ofertas'">
          <r-dynamic-list
            ref="rlist"
            :dbAdapter="Oferta"
            :tableCode="'OFERTA'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :shownFields="['estado.estado', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'lineas.comision', 'lineas.proveedor', 'lineas.item', 'lineas.administrador', 'lineas.instalador', 'lineas.mantenedor', 'lineas.asociacion']"
            :massiveActions="false"
            :massiveActionsSelectAll="true"
            :controlsWidth="90"
          >
            <template v-slot:massiveActions="{ selecteds }">
              <f7-button
                fill
                icon-f7="doc_chart_fill"
                text="Seleccionar"
                class="display-flex"
                color="blue"
                @click="seleccionar(selecteds)"
              ></f7-button>
            </template>
          </r-dynamic-list>
        </f7-page-content>
        <f7-page-content class="full-height" v-if="tabActive == 'pedidos'">
          <r-dynamic-list
            ref="rlist"
            :dbAdapter="Pedido"
            :tableCode="'PEDIDO'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :shownFields="['estado.estado', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'lineas.inspectores.username', 'lineas.camposUsuario.cpEj', 'lineas.comision', 'lineas.proveedor', 'lineas.item', 'lineas.administrador', 'lineas.instalador', 'lineas.mantenedor', 'lineas.asociacion']"
            :massiveActionsSelectAll="true"
            :controlsWidth="70"
          >
            <template v-slot:massiveActions="{ selecteds }">
              <f7-button
                fill
                icon-f7="doc_chart_fill"
                text="Seleccionar"
                class="display-flex"
                color="blue"
                @click="seleccionar(selecteds)"
              ></f7-button>
            </template>
          </r-dynamic-list>
        </f7-page-content>
        <f7-page-content class="full-height" v-if="tabActive == 'lineas-pedidos'">
          <r-dynamic-list
            ref="rlist"
            :dbAdapter="LineaPedido"
            :tableCode="'LINEAPEDIDO'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :shownFields="['numero', 'codigo', 'lineas.estado.estado', 'lineas.numero']"
            :massiveActions="false"
            :primary="['id', 'lineas.numero']"
            :massiveActionsSelectAll="true"
            :controlsWidth="70"
          >
            <template v-slot:massiveActions="{ selecteds }">
              <f7-button
                fill
                icon-f7="doc_chart_fill"
                text="Seleccionar"
                class="display-flex"
                color="blue"
                @click="seleccionar(selecteds)"
              ></f7-button>
            </template>
          </r-dynamic-list>
        </f7-page-content>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>

  .link-externo {
    margin-top: 20px;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .tab-link-active {
    background-color: var(--f7-theme-color-shade) !important;
  }
  .link-inline {
    position: absolute;
    top: 0;
  }

  .link-inline-0 {
    right: 0;
  }

  .link-inline-1 {
    right: 25px;
  }

  .link-inline-2 {
    right: 50px;
  }

</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Campania from "./../../js/db/Campania.js";
import Oportunidad from "./../../js/db/Oportunidad.js";
import Tarea from "./../../js/db/Tarea.js";
import Cliente from "./../../js/db/Cliente.js";
import Oferta from "./../../js/db/Oferta.js";
import Pedido from "./../../js/db/Pedido.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import Empleado from "./../../js/db/Empleado.js";
import TipoTarea from "./../../js/db/TipoTarea.js";
import rDireccion from "./../../components/rDireccion.vue";
import validator from "validator";
import rTimepicker from "./../../components/rTimepicker.vue";
import Documento from "./../../js/db/Documento.js";
import TareaForm from "./../tareas/tarea-form.vue";
import readXlsxFile from 'read-excel-file'
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    rAutocomplete,
    rDireccion,
    rTimepicker,
    rDynamicList,
    TareaForm
  },
  data: function() {
    var self = this;
    return {
      Utils,
      Campania,
      Oportunidad,
      Tarea,
      Empleado,
      Cliente,
      Oferta,
      Pedido,
      LineaPedido,
      TipoTarea,
      validator,
      readonly: !this.editable,
      popuploadOpened: false, 
      Documento,
      showDetalle: true,
      tabActive: 'clientes',
      clientes: [],
      ofertas: [],
      pedidos: [],
      lineasPedidos: [],
      numerosLineasPedidos: [],
      oportunidades: [],
      popupTareasOpened: false,
      tareaId: null,
      popupTareaEditable: false,
      cargadosComerciales: false,
      cargandoComerciales: 0,

    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    defaultData: {
      type: Object
    },
    esPopup: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  beforeDestroy() {},
  methods: {
    loadExcelLineas: function (event) {
      const self = this;
      let file = event.target.files[0];
      readXlsxFile(file).then((rows) => {
        console.log("rows", rows);
        self.numerosLineasPedidos = rows.filter(r => !isNaN(parseInt(r[0])) && !isNaN(parseInt(r[1]))).map(r => r[0] + '-' + r[1]);
        console.log("lineas", self.numerosLineasPedidos);
        self.loadLineasPedidos();
      });
      self.$refs.inputFileLineas.value = null;


    },
    generarOportunidades: function () {
      const self = this;
      app.preloader.show();
      Campania.generarOportunidades(self.$refs.rform.formData.id)
        .then(res => {
          self.$refs.rform.formData = res;
          app.dialog.alert("Oportunidades generadas");
        })
        .catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
        })
        .then(function () {
          app.preloader.hide();
        });
    },
    addProceso: function () {
      const self = this;
      if (!self.$refs.rform.formData.procesos) {
        self.$set(self.$refs.rform.formData, 'procesos', []);
      }
      let ordenes = self.$refs.rform.formData.procesos.map(p => p.orden);
      self.$refs.rform.formData.procesos.push({
        orden: (ordenes.length ? Math.max(...self.$refs.rform.formData.procesos.map(p => p.orden)) || 0 : 0) + 1,
        tipoTarea: {},
        numeroTareas: 1,
        ponderacion: 100
      });
    },  
    addComercial: function () {
      const self = this;
      if (!self.$refs.rform.formData.comerciales) {
        self.$set(self.$refs.rform.formData, 'comerciales', []);
      }
      self.$refs.rform.formData.comerciales.push({
        numOportunidades: 1,
        comercial: "",
        vntoc: 0,
        vntcc: 0,
        vntocr: 0,
        vntccr: 0,
        tareasRealizadas: 0
      });
    },  
    deleteCargado: function (id) {
      const self = this;
      if (self.tabActive == 'clientes') {
        self.$delete(self.clientes, self.clientes.map(c => c.id).indexOf(id));
        self.$delete(self.$refs.rform.formData.clientes, self.$refs.rform.formData.clientes.indexOf(id));
      } else if (self.tabActive == 'ofertas') {
        self.$delete(self.ofertas, self.ofertas.map(c => c.id).indexOf(id));
        self.$delete(self.$refs.rform.formData.ofertas, self.$refs.rform.formData.ofertas.indexOf(id));
      } else if (self.tabActive == 'pedidos') {
        self.$delete(self.pedidos, self.pedidos.map(c => c.id).indexOf(id));
        self.$delete(self.$refs.rform.formData.pedidos, self.$refs.rform.formData.pedidos.indexOf(id));
      } else if (self.tabActive == 'lineas-pedidos') {
        self.$delete(self.lineasPedidos, self.lineasPedidos.map(c => c.id + '-' + c.lineas.numero).indexOf(id));
        self.$delete(self.$refs.rform.formData.lineasPedidos, self.$refs.rform.formData.lineasPedidos.indexOf(id));
      }
    },
    seleccionar: function (selecteds) {
      const self = this;
      console.log("Seleccionados: ", selecteds);
      if (self.$refs.popupload) {
        self.$refs.popupload.close();
      }
      if (self.tabActive == 'clientes') {
        if (!self.$refs.rform.formData.clientes) {
          self.$refs.rform.formData.clientes = [];
        }
        self.$refs.rform.formData.clientes = [...new Set([...self.$refs.rform.formData.clientes, ...Object.keys(selecteds)])];
      } else if (self.tabActive == 'ofertas') {
        if (!self.$refs.rform.formData.ofertas) {
          self.$refs.rform.formData.ofertas = [];
        }
        self.$refs.rform.formData.ofertas = [...new Set([...self.$refs.rform.formData.ofertas, ...Object.keys(selecteds)])];
      } else if (self.tabActive == 'pedidos') {
        if (!self.$refs.rform.formData.pedidos) {
          self.$refs.rform.formData.pedidos = [];
        }
        self.$refs.rform.formData.pedidos = [...new Set([...self.$refs.rform.formData.pedidos, ...Object.keys(selecteds)])];
      } else if (self.tabActive == 'lineas-pedidos') {
        if (!self.$refs.rform.formData.lineasPedidos) {
          self.$refs.rform.formData.lineasPedidos = [];
        }
        self.$refs.rform.formData.lineasPedidos = [...new Set([...self.$refs.rform.formData.lineasPedidos, ...Object.keys(selecteds)])];
      }
      self.loadData();
    },
    loadData: function () {
      const self = this;
      if (self.tabActive == 'clientes') {
        self.loadClientes();
      } else if (self.tabActive == 'ofertas') {
        self.loadOfertas();
      } else if (self.tabActive == 'pedidos') {
        self.loadPedidos();
      } else if (self.tabActive == 'comercial') {
        self.loadComerciales();
      } else if (self.tabActive == 'oportunidades') {
        self.loadOportunidades();
      } else if (self.tabActive == 'lineas-pedidos') {
        self.loadLineasPedidos();
      }
    },
    loadComerciales: function (reload = false) {
      const self = this;
      if (self.$refs.rform.formData.id && !self.cargadosComerciales && !self.cargandoComerciales) {
        if (reload) {
          Oferta.resetCache();
          Pedido.resetCache();
          Oportunidad.resetCache();
          Tarea.resetCache();
        }
        if (!self.$refs.rform.formData.comerciales) {
          self.$set(self.$refs.rform.formData, 'comerciales', []);
        }
        self.$refs.rform.formData.comerciales.forEach(com => {
          com.tareasRealizadas = 0;
          com.numOportunidades = 0;
          com.vntocr = 0;
          com.vntccr = 0;
        });
        app.preloader.show();
        
        // Cargar total contratado de ofertas
        if (self.$refs.rform.formData.ofertas && self.$refs.rform.formData.ofertas.length) {
          self.cargandoComerciales++;
          Oferta.getList({
            filter: {
              ids: self.$refs.rform.formData.ofertas,
              onlyAggregations: true,
              withoutAggregations: false,
              funcionesAgregadas: [{
                activa: true,
                field: "totalSinIVASinTasasConDescuento",
                operacion: "SUM",
                as: "totalSinIVASinTerc",
                conCount: true, 
                conValor: true,
                disableProjection: false
              }]
            },
            projection: 'totalSinIVASinTerc'
          })
          .then(res => {
            let objValor = res.funcionesAgregadas.filter(fa => fa.nombre == "totalSinIVASinTerc")[0];
            self.$refs.rform.formData.valorOfertas = objValor.valor;
            self.$set(self.$refs.rform.formData, 'vntc', (self.$refs.rform.formData.valorOfertas || 0) + (self.$refs.rform.formData.valorPedidos || 0));
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            self.cargandoComerciales--;
            if (!self.cargandoComerciales) {
              app.preloader.hide();
            }
          });
        }
        // Cargar total contratado de pedidos
        if (self.$refs.rform.formData.pedidos && self.$refs.rform.formData.pedidos.length) {
          self.cargandoComerciales++;
          Pedido.getList({
            filter: {
              ids: self.$refs.rform.formData.pedidos,
              onlyAggregations: true,
              withoutAggregations: false,
              funcionesAgregadas: [{
                activa: true,
                field: "totalSinIVASinTasasConDescuento",
                operacion: "SUM",
                as: "totalSinIVASinTerc",
                conCount: true, 
                conValor: true,
                disableProjection: false
              }]
            },
            projection: 'totalSinIVASinTerc'
          })
          .then(res => {
            let objValor = res.funcionesAgregadas.filter(fa => fa.nombre == "totalSinIVASinTerc")[0];
            self.$refs.rform.formData.valorPedidos = objValor.valor;
            self.$set(self.$refs.rform.formData, 'vntc', (self.$refs.rform.formData.valorOfertas || 0) + (self.$refs.rform.formData.valorPedidos || 0));
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            self.cargandoComerciales--;
            if (!self.cargandoComerciales) {
              app.preloader.hide();
            }
          });
        }

        // Cargar oportunidades y recargar comerciales y los numeros de oportunidades que tienen.
        self.cargandoComerciales++;
        Oportunidad.getList({
            filter: {
              campaniaId: self.$refs.rform.formData.id, onlyAggregations: false, withoutAggregations: true
            },
            projection: 'codigo, gestor'
          })
          .then(res => {
            let oportunidades = res.list;
            console.log("oportunidades", oportunidades);

            let oportComercial = [];
            oportunidades.forEach(oportunidad => {
              if (!oportComercial[oportunidad.gestor]) {
                oportComercial[oportunidad.gestor] = [];
              }
              oportComercial[oportunidad.gestor].push(oportunidad);
            });
            console.log("oportComercial", oportComercial);

            if (!self.$refs.rform.formData.comerciales) {
              self.$set(self.$refs.rform.formData, 'comerciales', []);
            }
            Object.keys(oportComercial).forEach((gestor) => {
              let oports = oportComercial[gestor];
              let comercial = self.$refs.rform.formData.comerciales.filter(c => c.comercial == gestor)[0];
              if (!comercial) {
                self.$refs.rform.formData.comerciales.push({
                  numOportunidades: 1,
                  comercial: gestor,
                  vntoc: 0,
                  vntcc: 0,
                  vntocr: 0,
                  vntccr: 0,
                  tareasRealizadas: 0
                });
                comercial = self.$refs.rform.formData.comerciales.filter(c => c.comercial == gestor)[0];
              }
              comercial.numOportunidades = oports.length;
            });

            // Cargar tareas y ver cuales estan realizadas.
            self.loadTareasComerciales(oportunidades);
            self.loadOfertasComerciales(oportComercial);
            self.loadPedidosComerciales(oportComercial);
              

          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            self.cargandoComerciales--;
            if (!self.cargandoComerciales) {
              app.preloader.hide();
            }
          });

      }
    },
    loadOfertasComerciales: function (oportComercial) {
      const self = this;
      Object.keys(oportComercial).forEach((gestor) => {
        Oferta.getList({
          filter: {
            campaniaId: self.$refs.rform.formData.id,
            oportunidadGestor: gestor,
            onlyAggregations: true,
            withoutAggregations: false,
            funcionesAgregadas: [{
              activa: true,
              field: "totalSinIVASinTasasConDescuento",
              operacion: "SUM",
              as: "totalSinIVASinTerc",
              conCount: true,
              conValor: true,
              disableProjection: false
            }]
          },
          projection: 'totalSinIVASinTerc'
        })
          .then(res => {
            let objValor = res.funcionesAgregadas.filter(fa => fa.nombre == "totalSinIVASinTerc")[0];
            let comercial = self.$refs.rform.formData.comerciales.filter(c => c.comercial == gestor)[0];
            if (comercial) {
              comercial.vntocr = objValor ? objValor.valor || 0 : 0;
            }
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
        
      });
    },
    loadPedidosComerciales: function (oportComercial) {
      const self = this;
      Object.keys(oportComercial).forEach((gestor) => {
        Pedido.getList({
          filter: {
            campaniaId: self.$refs.rform.formData.id,
            oportunidadGestor: gestor,
            onlyAggregations: true,
            withoutAggregations: false,
            funcionesAgregadas: [{
              activa: true,
              field: "totalSinIVASinTasasConDescuento",
              operacion: "SUM",
              as: "totalSinIVASinTerc",
              conCount: true,
              conValor: true,
              disableProjection: false
            }]
          },
          projection: 'totalSinIVASinTerc'
        })
          .then(res => {
            let objValor = res.funcionesAgregadas.filter(fa => fa.nombre == "totalSinIVASinTerc")[0];
            let comercial = self.$refs.rform.formData.comerciales.filter(c => c.comercial == gestor)[0];
            if (comercial) {
              comercial.vntccr = objValor ? objValor.valor || 0 : 0;
            }
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
        
      });
    },
    loadTareasComerciales: function (oportunidades) {
      const self = this;
      Tarea.getList({
        filter: {
          idCampania: self.$refs.rform.formData.id, estado: 'REALIZADA', onlyAggregations: false, withoutAggregations: true
        },
        projection: 'codigo, idOportunidad, codigoOportunidad'
      })
      .then(res => {
        let tareas = res.list;
        console.log("Tareas", tareas);

        tareas.forEach(tarea => {
          let opor = oportunidades.filter(o => o.id == tarea.idOportunidad)[0];
          let comercial = self.$refs.rform.formData.comerciales.filter(c => c.comercial == opor.gestor)[0];
          if (comercial) {
            comercial.tareasRealizadas++;
          }
        })

      })
      .catch(function (e) {
        if (typeof e == "string") e = { error: e };
        let error = e.message || e.error || e.error_description;
        app.dialog.alert(error);
      });
    },  
    loadClientes: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.clientes && self.$refs.rform.formData.clientes.length) {
        app.preloader.show();
        Cliente.getList({
          filter: {
            ids: self.$refs.rform.formData.clientes, onlyAggregations: false, withoutAggregations: true }, projection: 'codigo,razonSocial,potencial' })
          .then(res => self.clientes = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    loadOfertas: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.ofertas && self.$refs.rform.formData.ofertas.length) {
        app.preloader.show();
        Oferta.getList({
          filter: {
            ids: self.$refs.rform.formData.ofertas, onlyAggregations: false, withoutAggregations: true }, projection: 'codigo,cliente,numero' })
          .then(res => self.ofertas = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    loadPedidos: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.pedidos && self.$refs.rform.formData.pedidos.length) {
        app.preloader.show();
        Pedido.getList({
          filter: {
            ids: self.$refs.rform.formData.pedidos, onlyAggregations: false, withoutAggregations: true }, projection: 'codigo,numero,cliente' })
          .then(res => self.pedidos = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    loadLineasPedidos: async function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.lineasPedidos && self.$refs.rform.formData.lineasPedidos.length) {
        app.preloader.show();
        self.lineasPedidos = [];
        let res = await LineaPedido.getList({
          filter: {
            idsPedidos: self.$refs.rform.formData.lineasPedidos.map(l => l.split('-')[0]), onlyAggregations: false, withoutAggregations: true
          }, projection: 'numero,codigo,lineas.estado.estado,lineas.numero,cliente'
        });
        app.preloader.hide();
        self.$refs.rform.formData.lineasPedidos.forEach(lp => {
          let elem = res.list.filter(l => l.id == lp.split('-')[0] && l.lineas.numero == lp.split('-')[1])[0];
          if (elem) {
            self.lineasPedidos.push(elem);
          }
        })
      }
      if (self.numerosLineasPedidos && self.numerosLineasPedidos.length) {
        let res = await LineaPedido.getList({
          filter: {
            numerosPedidos: self.numerosLineasPedidos.map(l => l.split('-')[0]), onlyAggregations: false, withoutAggregations: true
          }, projection: 'numero,codigo,lineas.estado.estado,lineas.numero,cliente'
        });
        if (!self.$refs.rform.formData.lineasPedidos) {
          self.$refs.rform.formData.lineasPedidos = [];
        }
        self.numerosLineasPedidos.forEach(lp => {
          let elem = res.list.filter(l => l.numero == lp.split('-')[0] && l.lineas.numero == lp.split('-')[1])[0];
          if (elem && !self.lineasPedidos.filter(lp => lp.id == elem.id && lp.lineas.numero == elem.lineas.numero).length) {
            self.lineasPedidos.push(elem);
            self.$refs.rform.formData.lineasPedidos.push(elem.id + '-' + elem.lineas.numero);
          }
        });
        self.numerosLineasPedidos = [];
        self.$refs.rform.formData.lineasPedidos = [...new Set([...self.$refs.rform.formData.lineasPedidos])];

      }
    },
    loadOportunidades: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.oportunidades && self.$refs.rform.formData.oportunidades.length) {
        app.preloader.show();
        Oportunidad.getList({
          filter: {
            ids: self.$refs.rform.formData.oportunidades, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,cliente,oferta,lineasPedido,pedido,gestor' })
          .then(res => self.oportunidades = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    deleteItem: function (confirmation = false) {
      const self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function () {
          Campania.delete({
            itemId: self.$refs.rform.formData.id, data: { confirmation : confirmation} }).then(function (res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function (error) {
            if (error && error.code == 'OPORT_CURSO') {
              var message = error.message;
              app.dialog.confirm(
                message,
                function () {
                  self.deleteItem(true);
                }
              );
              return;
            } else {
              app.dialog.alert(error.message);
            }

          });
        }
      );
    },
    getUrl(url) {
      const self = this;
      var currentUrl = new URL(window.location.href);
      if (self.target == 'cordova') {
        return '/' + url;
      }
      return currentUrl.pathname + ('#!/' + url);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;


      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("campania.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      self.$emit('saved', res);
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>