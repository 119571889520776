import EmpresasMantenedoras from './empresasmantenedoras.vue';
import EmpresaMantenedoraForm from './empresamantenedora-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/empresasmantenedoras/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresasmantenedoras', ['ADMIN', 'INSPECTOR'], EmpresasMantenedoras),
        keepAlive: true
    },
    {
        path: '/empresasmantenedoras/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresasmantenedoras/new', ['ADMIN', 'INSPECTOR'], EmpresaMantenedoraForm)
    },
    {
        path: '/empresasmantenedoras/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresasmantenedoras/edit', ['ADMIN', 'INSPECTOR'], EmpresaMantenedoraForm)
    },
    {
        path: '/empresasmantenedoras/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresasmantenedoras/view', ['ADMIN', 'INSPECTOR'], EmpresaMantenedoraForm, { editable: false })
    },
];
export default routes;