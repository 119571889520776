import Campanias from './campanias.vue';
import CampaniasForm from './campania-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/campanias/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('campanias', [], Campanias),
        keepAlive: true
    },
    {
        path: '/campanias/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('campanias/new', [], CampaniasForm)
    },
    {
        path: '/campanias/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('campanias/edit', [], CampaniasForm)
    },
    {
        path: '/campanias/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('campanias/view', [], CampaniasForm, { editable: false })
    },
];
export default routes;