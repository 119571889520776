import Ofertas from './ofertas.vue';
import OfertaForm from './oferta-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/ofertas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ofertas', ['ADMIN', 'INSPECTOR'], Ofertas),
        keepAlive: true
    },
    {
        path: '/ofertas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ofertas/new', ['ADMIN', 'INSPECTOR'], OfertaForm)
    },
    {
        path: '/ofertas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ofertas/edit', ['ADMIN', 'INSPECTOR'], OfertaForm)
    },
    {
        path: '/ofertas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('ofertas/view', ['ADMIN', 'INSPECTOR'], OfertaForm, { editable: false })
    },
];
export default routes;