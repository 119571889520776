import MediumEditor from 'medium-editor';

function extend(dest, source) {
    var prop;
    dest = dest || {};
    for (prop in source) {
        if (source.hasOwnProperty(prop) && !dest.hasOwnProperty(prop)) {
            dest[prop] = source[prop];
        }
    }
    return dest;
}

function getSelectionText(doc) {
    if (doc.getSelection) {
        return doc.getSelection().toString();
    }
    if (doc.selection && doc.selection.type !== 'Control') {
        return doc.selection.createRange().text;
    }
    return '';
}

function getSelectionStart(doc) {
    var node = doc.getSelection().anchorNode,
        startNode = (node && node.nodeType === 3 ? node.parentNode : node);

    return startNode;
}

function placeCaretAtNode(doc, node, before) {
    if (doc.getSelection !== undefined && node) {
        var range = doc.createRange(),
            selection = doc.getSelection();

        if (before) {
            range.setStartBefore(node);
        } else {
            range.setStartAfter(node);
        }

        range.collapse(true);

        selection.removeAllRanges();
        selection.addRange(range);
        node.click();
    }
}

function isInsideElementOfTag(node, tag) {
    if (!node) {
        return false;
    }

    var parentNode = node.parentNode,
        tagName = parentNode.tagName.toLowerCase();

    while (tagName !== 'body') {
        if (tagName === tag) {
            return true;
        }
        parentNode = parentNode.parentNode;

        if (parentNode && parentNode.tagName) {
            tagName = parentNode.tagName.toLowerCase();
        } else {
            return false;
        }
    }

    return false;
}

function getParentOf(el, tagTarget) {
    var tagName = el && el.tagName ? el.tagName.toLowerCase() : false;

    if (!tagName) {
        return false;
    }
    while (tagName && tagName !== 'body') {
        if (tagName === tagTarget) {
            return el;
        }
        el = el.parentNode;
        tagName = el && el.tagName ? el.tagName.toLowerCase() : false;
    }
}

const MediumEditorEnhacedTableTools = {
    hasClass: function(element, className){
        return Array.from(element.classList).includes(className);
    },
    removeClass: function(element, className){
        element.classList.remove(className);
    },
    addClass: function(element, className){
        element.classList.add(className);
    }
}

function Grid(el, callback, rows, columns) {
    return this.init(el, callback, rows, columns);
}

Grid.prototype = {
    init: function (el, callback, rows, columns) {
        this._root = el;
        this._callback = callback;
        this.rows = rows;
        this.columns = columns;
        return this._render();
    },

    setCurrentCell: function (cell) {
        this._currentCell = cell;
    },

    markCells: function () {
        [].forEach.call(this._cellsElements, function (el) {
            var cell = {
                    column: parseInt(el.dataset.column, 10),
                    row: parseInt(el.dataset.row, 10)
                },
                active = this._currentCell &&
                         cell.row <= this._currentCell.row &&
                         cell.column <= this._currentCell.column;

            if (active === true) {
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        }.bind(this));
    },

    _generateCells: function () {
        var row = -1;

        this._cells = [];

        for (var i = 0; i < this.rows * this.columns; i++) {
            var column = i % this.columns;

            if (column === 0) {
                row++;
            }

            this._cells.push({
                column: column,
                row: row,
                active: false
            });
        }
    },

    _html: function () {
        var width = this.columns * COLUMN_WIDTH + BORDER_WIDTH * 2,
            height = this.rows * COLUMN_WIDTH + BORDER_WIDTH * 2,
            html = '<div class="medium-editor-table-builder-grid clearfix" style="width:' + width + 'px;height:' + height + 'px;">';
        html += this._cellsHTML();
        html += '</div>';
        return html;
    },

    _cellsHTML: function () {
        var html = '';
        this._generateCells();
        this._cells.map(function (cell) {
            html += '<a href="#" class="medium-editor-table-builder-cell' +
                    (cell.active === true ? ' active' : '') +
                    '" ' + 'data-row="' + cell.row +
                    '" data-column="' + cell.column + '">';
            html += '</a>';
        });
        return html;
    },

    _render: function () {
        this._root.innerHTML = this._html();
        this._cellsElements = this._root.querySelectorAll('a');
        this._bindEvents();
    },

    _bindEvents: function () {
        [].forEach.call(this._cellsElements, function (el) {
            this._onMouseEnter(el);
            this._onClick(el);
        }.bind(this));
    },

    _onMouseEnter: function (el) {
        var self = this,
            timer;

        el.addEventListener('mouseenter', function () {
            clearTimeout(timer);

            var dataset = this.dataset;

            timer = setTimeout(function () {
                self._currentCell = {
                    column: parseInt(dataset.column, 10),
                    row: parseInt(dataset.row, 10)
                };
                self.markCells();
            }, 50);
        });
    },

    _onClick: function (el) {
        var self = this;
        el.addEventListener('click', function (e) {
            e.preventDefault();
            self._callback(this.dataset.row, this.dataset.column);
        });
    }
};

function Builder(options) {
    return this.init(options);
}

Builder.prototype = {
    init: function (options) {
        this.options = options;
        this._doc = options.ownerDocument || document;
        this._root = this._doc.createElement('div');
        this._root.className = 'medium-editor-table-builder';
        this.grid = new Grid(
          this._root,
          this.options.onClick,
          this.options.rows,
          this.options.columns
        );
        this._editor = options.editor;
        this._headerColorString = options.headerColor || "white";
        this._headerBGColorString = options.headerBGColor || "#4b6eb9";


        this._range = null;
        this._selectedTableOffset = 0;
        this._toolbar = this._doc.createElement('div');
        this._toolbar.className = 'medium-editor-table-builder-toolbar';

        var tables = this._doc.getElementsByClassName("medium-editor-table");
        Array.from(tables).forEach(table => {
            this.builder._adaptarCabeceraOculta(table);
        });

        // Controles de tabla
        this._tableControls = this._doc.createElement('div');
        var spanTable = this._doc.createElement('span');
        spanTable.innerHTML = 'Tabla:';
        this._tableControls.appendChild(spanTable);
        var showBorder = this._doc.createElement('button');
        showBorder.title = 'Mostrar bordes';
        showBorder.innerHTML = '<i class="fa fa-plus-square-o"></i>';
        showBorder.onclick = this.toggleBorders.bind(this, false);
        this._tableControls.appendChild(showBorder);
        var remTable = this._doc.createElement('button');
        remTable.title = 'Eliminar tabla';
        remTable.innerHTML = '<i class="fa fa-trash-o"></i>';
        remTable.onclick = this.removeTable.bind(this);
        this._tableControls.appendChild(remTable);
        this._toolbar.appendChild(this._tableControls);

        // Controles de color
        this._colorBGControls = this._doc.createElement('div');
        this._colorBGControls.className = "header-color-controls-container";
        var spanHeader = this._doc.createElement('span');
        spanHeader.innerHTML = 'Color fondo:';
        spanHeader.style = 'margin-top: 3px;';
        this._colorBGControls.appendChild(spanHeader);
        var headerBGColor = this._doc.createElement('input');
        headerBGColor.classList = "header-color-input"
        headerBGColor.name = "header-color";
        headerBGColor.value = this._headerBGColorString;
        headerBGColor.addEventListener("change",(e => {
            this._headerBGColorString = e.target.value;
            this.refreshPreviewColors();
        }));
        this._headerBGColorInput = headerBGColor;
        this._colorBGControls.appendChild(this._headerBGColorInput);
        this._toolbar.appendChild(this._colorBGControls);

        this._colorControls = this._doc.createElement('div');
        this._colorControls.className = "header-color-controls-container";
        var spanHeader = this._doc.createElement('span');
        spanHeader.innerHTML = 'Color letra:';
        spanHeader.style = 'margin-top: 3px;';
        this._colorControls.appendChild(spanHeader);
        var headerColor = this._doc.createElement('input');
        headerColor.classList = "header-color-input"
        headerColor.name = "header-color";
        headerColor.value = this._headerColorString;
        headerColor.addEventListener("change",(e => {
            this._headerColorString = e.target.value;
            this.refreshPreviewColors();
        }));
        this._headerColorInput = headerColor;
        this._colorControls.appendChild(this._headerColorInput);
        this._toolbar.appendChild(this._colorControls);
        var headerColorPreviewWrapper = this._doc.createElement('div');
        headerColorPreviewWrapper.className = "header-color-preview-wrapper";
        var headerColorPreview = this._doc.createElement('div');
        headerColorPreview.className = "header-color-preview";
        headerColorPreview.innerHTML = "Previsualización";
        this._headerColorPreview = headerColorPreview;
        headerColorPreviewWrapper.appendChild(this._headerColorPreview);
        var copyColors = this._doc.createElement('button');
        copyColors.title = 'Recuperar colores de celda';
        copyColors.innerHTML = '<i class="fa fa-paint-brush"></i>';
        copyColors.onclick = this.recoverColors.bind(this);
        this._headerColorPreview.appendChild(copyColors);
        this._toolbar.appendChild(headerColorPreviewWrapper);

        // Controles de fila
        this._rowControls = this._doc.createElement('div');
        var spanRow = this._doc.createElement('span');
        spanRow.innerHTML = 'Fila:';
        this._rowControls.appendChild(spanRow);
        var addRowBefore = this._doc.createElement('button');
        addRowBefore.title = 'Añadir fila antes';
        addRowBefore.innerHTML = '<i class="fa fa-long-arrow-up"></i>';
        addRowBefore.onclick = this.addRow.bind(this, true);
        this._rowControls.appendChild(addRowBefore);

        var addRowAfter = this._doc.createElement('button');
        addRowAfter.title = 'Añadir fila después';
        addRowAfter.innerHTML = '<i class="fa fa-long-arrow-down"></i>';
        addRowAfter.onclick = this.addRow.bind(this, false);
        this._rowControls.appendChild(addRowAfter);

        var remRow = this._doc.createElement('button');
        remRow.title = 'Eliminar fila';
        remRow.innerHTML = '<i class="fa fa-close"></i>';
        remRow.onclick = this.removeRow.bind(this);
        this._rowControls.appendChild(remRow);

        var headRow = this._doc.createElement('button');
        headRow.title = 'Aplicar colores';
        headRow.innerHTML = '<i class="fa fa-paint-brush"></i>';
        headRow.onclick = this.headerRow.bind(this);
        this._rowControls.appendChild(headRow);
        this._toolbar.appendChild(this._rowControls);

        // Controles de columna
        this._columnControls = this._doc.createElement('div');
        var spanCol = this._doc.createElement('span');
        spanCol.innerHTML = 'Columna:';
        this._columnControls.appendChild(spanCol);
        var addColumnBefore = this._doc.createElement('button');
        addColumnBefore.title = 'Añadir columna antes';
        addColumnBefore.innerHTML = '<i class="fa fa-long-arrow-left"></i>';
        addColumnBefore.onclick = this.addColumn.bind(this, true);
        this._columnControls.appendChild(addColumnBefore);

        var addColumnAfter = this._doc.createElement('button');
        addColumnAfter.title = 'Añadir columna después';
        addColumnAfter.innerHTML = '<i class="fa fa-long-arrow-right"></i>';
        addColumnAfter.onclick = this.addColumn.bind(this, false);
        this._columnControls.appendChild(addColumnAfter);

        var remColumn = this._doc.createElement('button');
        remColumn.title = 'Eliminar columna';
        remColumn.innerHTML = '<i class="fa fa-close"></i>';
        remColumn.onclick = this.removeColumn.bind(this);
        this._columnControls.appendChild(remColumn);

        var headCol = this._doc.createElement('button');
        headCol.title = 'Aplicar colores';
        headCol.innerHTML = '<i class="fa fa-paint-brush"></i>';
        headCol.onclick = this.headerCol.bind(this);
        this._columnControls.appendChild(headCol);
        this._toolbar.appendChild(this._columnControls);

        // Controles de celda
        this._cellControls = this._doc.createElement('div');
        var spanCell = this._doc.createElement('span');
        spanCell.innerHTML = 'Celda:';
        this._cellControls.appendChild(spanCell);
        var joinRight = this._doc.createElement('button');
        joinRight.title = 'Combinar con celda a la derecha';
        joinRight.innerHTML = '<i class="fa fa-long-arrow-right"></i>';
        joinRight.onclick = this.joinRightCell.bind(this);
        this._cellControls.appendChild(joinRight);
        
        var joinRight = this._doc.createElement('button');
        joinRight.title = 'Combinar con celda inferior';
        joinRight.innerHTML = '<i class="fa fa-long-arrow-down"></i>';
        joinRight.onclick = this.joinBottomCell.bind(this);
        this._cellControls.appendChild(joinRight);

        var remColumn = this._doc.createElement('button');
        remColumn.title = 'Separar celda combinada';
        remColumn.innerHTML = '<i class="fa fa-plus-square-o"></i>';
        remColumn.onclick = this.splitCell.bind(this);
        this._cellControls.appendChild(remColumn);

        var headCol = this._doc.createElement('button');
        headCol.title = 'Aplicar colores';
        headCol.innerHTML = '<i class="fa fa-paint-brush"></i>';
        headCol.onclick = this.headerCell.bind(this);
        this._cellControls.appendChild(headCol);
        this._toolbar.appendChild(this._cellControls);

        var grid = this._root.childNodes[0];
        this._root.insertBefore(this._toolbar, grid);

        this.refreshPreviewColors();
    },

    getElement: function () {
        return this._root;
    },

    hide: function () {
        this._root.style.display = '';
        this.grid.setCurrentCell({ column: -1, row: -1 });
        this.grid.markCells();
    },

    show: function (left) {
        this._root.style.display = 'block';
        this._root.style.left = left + 'px';
    },

    setEditor: function (range, restrictNestedTable) {
        this._range = range;
        this._selectedTableOffset = 0;
        this._toolbar.style.display = 'block';
        if (restrictNestedTable) {
            var elements = this._doc.getElementsByClassName('medium-editor-table-builder-grid');
            elements[0].style.display = 'none';
        }
    },

    setBuilder: function () {
        this._range = null;
        this._toolbar.style.display = 'none';
        var elements = this._doc.getElementsByClassName('medium-editor-table-builder-grid');
        elements[0].style.display = 'block';
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.height = (COLUMN_WIDTH * this.rows + BORDER_WIDTH * 2) + 'px';
            elements[i].style.width = (COLUMN_WIDTH * this.columns + BORDER_WIDTH * 2) + 'px';
        }
    },

    setRange: function(element){
        this._range = element;
        this._selectedTableOffset = 0;
        this._manageSelectedTable();
        this._tbody = this.getParentType(this._range, 'tbody');
    },

    _resetSelectedTable: function(){
        let tables = this._doc.getElementsByClassName('medium-editor-table') || [];
        for(let table of tables) {
            MediumEditorEnhacedTableTools.removeClass(table, 'medium-editor-table-selected');
        };
    },

    _manageSelectedTable: function(){
        let offset = this._selectedTableOffset,
            currentTable = this._getElement('table', offset),
            tables = this._doc.getElementsByClassName('medium-editor-table') || [];
        
        for(let table of tables) {
            MediumEditorEnhacedTableTools.removeClass(table, 'medium-editor-table-selected');
        };
        MediumEditorEnhacedTableTools.addClass(currentTable, 'medium-editor-table-selected');
    },

    _getElement: function(type, offset){
        if(offset == undefined){
            offset = 0;
        }
        let element = this.getParentType(this._range, type);
        while(offset > 0){
            let possibleParent = this.getParentType(element.parentNode, type);
            if(possibleParent) {
                element = possibleParent;
            } else {
                break;
            }
            offset--;
        }
        return element;
    },

    getParentType: function (el, targetNode) {
        var nodeName = el && el.nodeName ? el.nodeName.toLowerCase() : false;
        if (!nodeName) {
            return false;
        }
        while (nodeName && nodeName !== 'body') {
            if (nodeName === targetNode) {
                return el;
            }
            el = el.parentNode;
            nodeName = el && el.nodeName ? el.nodeName.toLowerCase() : false;
        }
    },

    addRow: function (before, e) {
        e.preventDefault();
        e.stopPropagation();
        var table = this.getParentType(this._range, 'table'),
            tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTR = this.getParentType(this._range, 'tr'),
            tr = this._doc.createElement('tr'),
            td,
            cols = Array.from(selectedTR.childNodes).reduce((i,td) => i + td.colSpan, 0);
        for (var i = 0; i < cols; i++) {
            td = this._doc.createElement('td');
            td.appendChild(this._doc.createElement('br'));
            tr.appendChild(td);
        }
        if (before !== true && selectedTR.nextSibling) {
            tbody.insertBefore(tr, selectedTR.nextSibling);
        } else if (before === true) {
            tbody.insertBefore(tr, selectedTR);
        } else {
            tbody.appendChild(tr);
        }
        this._forzarBordes(table);
    },

    removeRow: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTR = this.getParentType(this._range, 'tr'),
            destinationTR = selectedTR.nextSibling,
            selectedTD = this.getParentType(this._range, 'td'),
            selectedPosition = this.cellPosition(selectedTD),
            rowCells = this.cellsAtRow(selectedPosition.iRow),
            cellsToMove = rowCells.filter(cell => {
                var cellPos = this.cellPosition(cell);
                return cellPos.iRow == selectedPosition.iRow && cell.rowSpan > 1;
            }),
            cellsToReduce = rowCells.filter(cell => cell.rowSpan > 1),
            nextRowCells = this.cellsAtRow(selectedPosition.iRow + 1),
            movementsNeeded = cellsToMove.map(cell => {
                var nextCell = null;
                var cellToMovePosition = this.cellPosition(cell);
                var nextCol = cellToMovePosition.iCol + cell.colSpan;
                for (var i=0; !nextCell && i < nextRowCells.length; i++){
                    var nextPossibleCell = nextRowCells[i];
                    var nextPossibleCellPosition = this.cellPosition(nextPossibleCell);
                    if(nextPossibleCell != cell && nextPossibleCellPosition.iRow == selectedPosition.iRow + 1 && nextPossibleCellPosition.iCol >= nextCol){
                        nextCell = nextPossibleCell;
                    }
                }
                return {
                    cell: cell,
                    tr: destinationTR,
                    nextCell: nextCell
                };
            }),
            nextCellToFocus = (this.cellAt(selectedPosition.iRow + 1, selectedPosition.iCol)) 
                ? this.cellAt(selectedPosition.iRow + 1, selectedPosition.iCol) 
                : this.cellAt(selectedPosition.iRow - 1, selectedPosition.iCol);

        // Se reduce el tamaño de las celdas combinadas afectadas
        cellsToReduce.forEach(cell => {
            cell.rowSpan--;
        });
        // Se mueven las celdas que necesiten ser movidas
        movementsNeeded.forEach(movement => {
            if(!movement.nextCell){
                movement.tr.appendChild(movement.cell);
            } else {
                movement.tr.insertBefore(movement.cell, movement.nextCell);
            }
        });
        selectedTR.remove();
        this.setRange(nextCellToFocus);
    },
    
    headerRow: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        if(tbody)
        this._normalizarTabla(tbody);
        var selectedTR = this.getParentType(this._range, 'tr'),
            rowTDs = Array.from(selectedTR.childNodes),
            isHeader = rowTDs.every(td => td.classList.length && Array.from(td.classList).includes('header-cell'));
        rowTDs.forEach(td => {
            this.refreshCellColors(td, !isHeader);
        });
    },

    addColumn: function (before, e) {
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var table = this.getParentType(this._range, 'table'),
            selectedTD = this.getParentType(this._range, 'td'),
            cellPosition = this.cellPosition(selectedTD),
            newCellPosition = (before) ? cellPosition.iCol : cellPosition.iCol + selectedTD.colSpan,
            rows = Array.from(tbody.childNodes);
        
        for (var i = 0; i < rows.length; i++) {
            var td = this._doc.createElement('td');
            td.appendChild(this._doc.createElement('br'));
            var row = rows[i];
            var cell1 = this.cellAt(i, newCellPosition);
            if(!cell1){
                row.appendChild(td);
                continue;
            }
            var cell2 = this.cellAt(i, newCellPosition - 1);
            if(cell1 == cell2) {
                cell1.colSpan++;
                continue;
            }
            if(!cell2){
                row.insertBefore(td, cell1);
            }
            var targetCell = null;
            row.childNodes.forEach(cell => {
                if(targetCell) return;
                var possibleCellPosition = this.cellPosition(cell);
                if(possibleCellPosition.iCol >= newCellPosition) {
                    targetCell = cell;
                }
            });
            if(targetCell){
                row.insertBefore(td, targetCell);
            }
        }
        this._adaptarCabeceraOculta(table);
        this._forzarBordes(table);
    },

    removeColumn: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody,
            table = this.getParentType(this._range, 'table');
        this._normalizarTabla(tbody);
        var selectedTD = this.getParentType(this._range, 'td'),
            selectedTR = this.getParentType(this._range, 'tr'),
            cellPosition = this.cellPosition(selectedTD),
            colToDelete = cellPosition.iCol,
            rows = tbody.childNodes.length,
            nextCellToFocus = (selectedTD.nextSibling) ? selectedTD.nextSibling : selectedTR.nextSibling.childNodes[0];

        for (var i = 0; i < rows; i++) {
            var cellsToAdvance = 0;
            var cellToDelete = this.cellAt(i, colToDelete);
            if(cellToDelete.rowSpan > 1){
                cellsToAdvance = cellToDelete.rowSpan - 1;
            }
            if(cellToDelete.colSpan == 1){
                cellToDelete.remove();
            } else {
                var cellToDeletePosition = this.cellPosition(cellToDelete);
                if(i == cellToDeletePosition.iRow){
                    cellToDelete.colSpan--;
                }
            }
            i += cellsToAdvance;
        }
        this.setRange(nextCellToFocus);
        this._adaptarCabeceraOculta(table);
    },
    
    headerCol: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTR = this.getParentType(this._range, 'tr'),
            selectedTD = this.getParentType(this._range, 'td'),
            iCol = Array.from(selectedTR.childNodes).indexOf(selectedTD),
            selectedColTDs = Array.from(tbody.childNodes).map(tr => tr.childNodes[iCol]),
            isHeader = selectedColTDs.every(td => td.classList.length && Array.from(td.classList).includes('header-cell'));
        selectedColTDs.forEach(td => {
            this.refreshCellColors(td, !isHeader);
        });
    },

    joinRightCell: function (e){
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTD = this.getParentType(this._range, 'td'),
            cellPosition = this.cellPosition(selectedTD),
            nextCells = [],
            nextCol = cellPosition.iCol + selectedTD.colSpan;
        for(var i=0; i<selectedTD.rowSpan; i++){
            if(cellPosition){
                nextCells.push(this.cellAt(cellPosition.iRow + i, nextCol));
            }
        }
        if (nextCells && nextCells.length) {
            if(!nextCells[0]) {
                return console.error("No hay siguiente columna");
            }
            var nextCellColSpan = nextCells[0].colSpan;
            if(nextCells.some(nextCell => nextCellColSpan != nextCell.colSpan)) {
                return console.error("En la siguiente columnas hay celdas con diferentes tamaños");
            }
            var cellPositions = nextCells.map(nextCell => this.cellPosition(nextCell));
            var lastRowCellPosition = cellPositions.reduce((maxRow, position) => (maxRow > position.iRow) ? maxRow : position.iRow, 0);
            var firstRowCellPosition = cellPositions.reduce((minRow, position) => (minRow < position.iRow) ? minRow : position.iRow, 99999);
            var lastCell = this.cellAt(lastRowCellPosition, nextCol);
            if(cellPosition.iRow != firstRowCellPosition){
                return console.error("La primera celda no empieza en la misma línea que la celda origen");
            }
            if(cellPosition.iRow + selectedTD.rowSpan != lastRowCellPosition + lastCell.rowSpan){
                return console.error("La última celda no termina en la misma línea que la celda origen");
            }
            
            selectedTD.colSpan += nextCellColSpan;
            nextCells.forEach(nextCell => {
                nextCell.remove()
            });
        }
    },

    joinBottomCell: function (e){
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTD = this.getParentType(this._range, 'td'),
            cellPosition = this.cellPosition(selectedTD),
            nextCells = [],
            nextRow = cellPosition.iRow + selectedTD.rowSpan;
        for(var i=0; i<selectedTD.colSpan; i++){
            if(cellPosition){
                nextCells.push(this.cellAt(nextRow, cellPosition.iCol + i));
            }
        }
        if (nextCells && nextCells.length) {
            if(!nextCells[0]) {
                return console.error("No hay siguiente fila");
            }
            var nextCellRowSpan = nextCells[0].rowSpan;
            if(nextCells.some(nextCell => nextCellRowSpan != nextCell.rowSpan)) {
                return console.error("En la siguiente fila hay celdas con diferentes tamaños");
            }
            var cellPositions = nextCells.map(nextCell => this.cellPosition(nextCell));
            var lastColCellPosition = cellPositions.reduce((maxCol, position) => (maxCol > position.iCol) ? maxCol : position.iCol, 0);
            var firstColCellPosition = cellPositions.reduce((minCol, position) => (minCol < position.iCol) ? minCol : position.iCol, 99999);
            var lastCell = this.cellAt(nextRow, lastColCellPosition);
            if(cellPosition.iCol != firstColCellPosition){
                return console.error("La primera celda no empieza en la misma columna que la celda origen");
            }
            if(cellPosition.iCol + selectedTD.colSpan != lastColCellPosition + lastCell.colSpan){
                return console.error("La última celda no termina en la misma columna que la celda origen");
            }
            
            selectedTD.rowSpan += nextCellRowSpan;
            nextCells.forEach(nextCell => {
                nextCell.remove()
            });
        }
    },

    splitCell: function (e){
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTD = this.getParentType(this._range, 'td'),
            tr = selectedTD.parentNode,
            cellPosition = this.cellPosition(selectedTD),
            rowCells = this.cellsAtRow(cellPosition.iRow),
            rows = selectedTD.rowSpan,
            cols = selectedTD.colSpan;
        for(var i=1; i<cols; i++){
            const newCell = this._doc.createElement('td');
            var nextCell = selectedTD.nextSibling;
            if(nextCell){
                tr.insertBefore(newCell, nextCell);
            } else {
                tr.appendChild(newCell);
            }
        }
        for(var i=1; i<rows; i++){
            var nextCell = null,
                rowCells = this.cellsAtRow(cellPosition.iRow + i),
                row = (row) ? row.nextSibling : tr.nextSibling;
            for(var j=0; !nextCell && j < rowCells.length; j++){
                var possibleCell = rowCells[j];
                var possibleCellPosition = this.cellPosition(possibleCell);
                if(selectedTD != possibleCell && possibleCellPosition.iRow == cellPosition.iRow + i && possibleCellPosition.iCol >= cellPosition.iCol){
                    nextCell = possibleCell;
                }
            }
            
            for(var j=0; j<cols; j++){
                const newCell = this._doc.createElement('td');
                if(nextCell){
                    row.insertBefore(newCell, nextCell);
                } else {
                    row.appendChild(newCell);
                }
            }
        }
        selectedTD.colSpan = 1;
        selectedTD.rowSpan = 1;
    },

    headerCell: function (e){
        e.preventDefault();
        e.stopPropagation();
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var selectedTD = this.getParentType(this._range, 'td'),
            isHeader = Array.from(selectedTD.classList).includes('header-cell');
        this.refreshCellColors(selectedTD, !isHeader);
    },

    cellsGrid: function(){
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var rows = tbody.childNodes ? Array.from(tbody.childNodes) : [],
            grid = rows.map(_=>[]);
        rows.forEach((row, iRow) => {
            var columns = Array.from(row.childNodes);
            columns.forEach((cell, iCol) => {
                for(var i = 0; i <= iCol; i++){
                    while(grid[iRow][i]){
                        i++;
                    }
                    grid[iRow][i] = cell;
                    for(var zC = 0; zC < cell.colSpan; zC++) {
                        for(var zR = 0; zR < cell.rowSpan; zR++) {
                            grid[iRow + zR][i + zC] = cell;
                        }
                    }
                }
            });
        });
        return grid;
    },

    cellPosition: function(cell){
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var grid = this.cellsGrid(),
            position = null;
        grid.forEach((row, iRow) => {
            row.forEach((col, iCol) => {
                if(!position && col == cell){
                    position = {
                        iRow: iRow,
                        iCol: iCol
                    };
                }
            })
        });
        return position;
    },

    cellAt: function(row,col){
        var tbody = this._tbody;
        this._normalizarTabla(tbody);
        var grid = this.cellsGrid();
        if(grid[row]){
            return grid[row][col];
        }
        return null;
    },

    cellsAtRow: function(row){
        var cells = [];
        for(var i=0, cell = this.cellAt(row,i); cell; i++, cell = this.cellAt(row,i)){
            if(cell){
                cells.push(cell);
            }
        }
        return [...new Set(cells)];
    },

    removeTable: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var table = this.getParentType(this._range, 'table'),
            parent = table.parentNode;
        parent.removeChild(table);
        if(parent.nodeName == "TD" && parent.innerHTML.trim() == ""){
            parent.innerHTML = '<br/>';
        }
        this.options.onClick(0, 0);
    },

    refreshPreviewColors: function() {
        var headerBGColor = this._headerBGColorString,
            headerColor = this._headerColorString;
            
        this._headerColorPreview.style["background"] = headerBGColor;
        this._headerColorPreview.style["color"] = headerColor;
    },

    refreshCellColors: function(cell, isHeader) {
        var headerBGColor = this._headerBGColorString,
            headerColor = this._headerColorString,
            table = this.getParentType(this._range, 'table');
            
        if(table){
            if(!isHeader){
                cell.classList.remove('header-cell');
                cell.style.background = "";
                cell.style.color = "";
            } else {
                cell.classList.add('header-cell');
                cell.style.background = headerBGColor;
                cell.style.color = headerColor;
            }
        }
    },

    recoverColors: function() {
        var selectedTD = this.getParentType(this._range, 'td'),
            isHeader = Array.from(selectedTD.classList).includes('header-cell');
        this._headerBGColorString = this._rgb2hex(selectedTD.style.background);
        this._headerColorString = this._rgb2hex(selectedTD.style.color);
        this._headerBGColorInput.value = this._headerBGColorString;
        this._headerColorInput.value = this._headerColorString;
        this.refreshPreviewColors();
    },

    _normalizarTabla(tabla){
        Array.from(tabla.childNodes).filter(node => node.nodeName == "#text").forEach(node => node.remove());
        Array.from(tabla.childNodes).forEach(row =>{
            Array.from(row.childNodes).filter(node => node.nodeName == "#text").forEach(node => node.remove());
        });
        this._forzarBordes(tabla);
    },

    _rgb2hex: function(color){
        var matches = color.match(/rgb\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\)/);
        if(matches){
            var colorComponents = [matches[1],matches[2],matches[3]];
            var hexColor = colorComponents.reduce((prev, dec) => prev + parseInt(dec).toString(16), "#");
            return hexColor;
        }
        return color;
    },
    _adaptarCabeceraOculta: function(table){
        var thead = (table.getElementsByTagName("thead").length) ? table.getElementsByTagName("thead")[0] : null,
            tbody = (table.getElementsByTagName("tbody").length) ? table.getElementsByTagName("tbody")[0] : null;
        this._normalizarTabla(tbody);
        var tr = tbody.firstChild,
            cellsPerRow = tr && tr.childNodes ? Array.from(tr.childNodes).reduce((result, current) => result + current.colSpan,0) : 0,
            headTr = thead && thead.getElementsByTagName("tr").length ? thead.getElementsByTagName("tr")[0] : null;
        
        if(!thead){
            var newThead = document.createElement("thead");
            newThead.classList = "workaround-cell-size";
            table.insertBefore(newThead, tbody);
            thead = newThead;
        }
        if(!headTr){
            var newTr = document.createElement("tr");
            thead.appendChild(newTr);
            headTr = newTr;
        }
        while (headTr.firstChild) {
            headTr.removeChild(headTr.firstChild);
        }
        for(var i=0; i < cellsPerRow; i++){
            var td = document.createElement("td");
            td.classList.add("without-border");
            td.setAttribute("width", 100/cellsPerRow + "%");
            headTr.appendChild(td);
        }
    },
    _forzarBordes: function(table){
        var conBordes = Array.from(table.classList).includes("show-borders"),
            tbody = (table.getElementsByTagName("tbody").length) ? table.getElementsByTagName("tbody")[0] : null;
        var trs = (tbody && tbody.childNodes.length) ? Array.from(tbody.childNodes) : [],
            tds = trs.length ? trs.reduce((result, tr) => {
                    var lineTds = tr.childNodes.length ? Array.from(tr.childNodes) : [];
                    return result.concat(lineTds);
                },[]) : [];
        tds.forEach(td => {
            if(!td.classList) {
                td.classList = [];
            }
            if(conBordes) {
                td.classList.add("with-border");
                td.classList.remove("without-border");
            } else {
                td.classList.add("without-border");
                td.classList.remove("with-border");
            }
        });
    },

    toggleBorders: function() {
        var table = this._getElement('table'),
            alreadyHasBorders = MediumEditorEnhacedTableTools.hasClass(table, "show-borders"),
            tbody = (table.getElementsByTagName("tbody").length) ? table.getElementsByTagName("tbody")[0] : null;
        if(!alreadyHasBorders) {
            table.classList.add("show-borders");
        } else {
            table.classList.remove("show-borders");
        }
        this._normalizarTabla(tbody);
    }
};

function Table(editor) {
    return this.init(editor);
}

var TAB_KEY_CODE = 9;

Table.prototype = {
    init: function (editor) {
        this._editor = editor;
        this._doc = this._editor.options.ownerDocument;
        this._bindTabBehavior();
    },

    insert: function (rows, cols) {
        var html = this._html(rows, cols);

        this._editor.pasteHTML(
            '<table class="medium-editor-table" id="medium-editor-table"' +
            ' width="100%">' +
            '<tbody id="medium-editor-table-tbody">' +
            html +
            '</tbody>' +
            '</table><br/>', {
                cleanAttrs: [],
                cleanTags: []
            }
        );

        var table = this._doc.getElementById('medium-editor-table'),
            tbody = this._doc.getElementById('medium-editor-table-tbody');
        // if (0 === $(table).find('#medium-editor-table-tbody').length) {
        //     //Edge case, where tbody gets appended outside table tag
        //     $(tbody).detach().appendTo(table);
        // }
        if(table.nextSibling.nodeName=="BR"){
            table.nextSibling.remove();
        }
        tbody.removeAttribute('id');
        table.removeAttribute('id');
        placeCaretAtNode(this._doc, table.querySelector('td'), true);

        this._editor.checkSelection();
        return table;
    },

    _html: function (rows, cols) {
        var html = '',
            x, y,
            text = getSelectionText(this._doc);

        for (x = 0; x <= rows; x++) {
            html += '<tr>';
            for (y = 0; y <= cols; y++) {
                html += '<td><br/></td>';
            }
            html += '</tr>';
        }
        return html;
    },

    _bindTabBehavior: function () {
        var self = this;
        [].forEach.call(this._editor.elements, function (el) {
            el.addEventListener('keydown', function (e) {
                self._onKeyDown(e);
            });
        });
    },

    _onKeyDown: function (e) {
        var el = getSelectionStart(this._doc),
            table;

        if (e.which === TAB_KEY_CODE && isInsideElementOfTag(el, 'table')) {
            e.preventDefault();
            e.stopPropagation();
            table = this._getTableElements(el);
            if (e.shiftKey) {
                this._tabBackwards(el.previousSibling, table.row);
            } else {
                if (this._isLastCell(el, table.row, table.root)) {
                    this._insertRow(getParentOf(el, 'tbody'), table.row.cells.length);
                }
                placeCaretAtNode(this._doc, el);
            }
        }
    },

    _getTableElements: function (el) {
        return {
            cell: getParentOf(el, 'td'),
            row: getParentOf(el, 'tr'),
            root: getParentOf(el, 'table')
        };
    },

    _tabBackwards: function (el, row) {
        el = el || this._getPreviousRowLastCell(row);
        placeCaretAtNode(this._doc, el, true);
    },

    _insertRow: function (tbody, cols) {
        var tr = document.createElement('tr'),
            html = '',
            i;

        for (i = 0; i < cols; i += 1) {
            html += '<td><br /></td>';
        }
        tr.innerHTML = html;
        tbody.appendChild(tr);
    },

    _isLastCell: function (el, row, table) {
        return (
          (row.cells.length - 1) === el.cellIndex &&
          (table.rows.length - 1) === row.rowIndex
        );
    },

    _getPreviousRowLastCell: function (row) {
        row = row.previousSibling;
        if (row) {
            return row.cells[row.cells.length - 1];
        }
    }
};

var COLUMN_WIDTH = 16,
    BORDER_WIDTH = 1,
    MediumEditorEnhacedTable;

MediumEditorEnhacedTable = MediumEditor.extensions.form.extend({
    name: 'mediumEditorEnhacedTable',

    aria: 'create table',
    action: 'table',
    contentDefault: 'TBL',
    contentFA: '<i class="fa fa-table"></i>',

    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();

        this[this.isActive() === true ? 'hide' : 'show']();
    },

    hide: function () {
        this.setInactive();
        this.builder.hide();
    },

    show: function () {
        this.setActive();

        var range = MediumEditor.selection.getSelectionRange(this.document);
        if (range.startContainer.nodeName.toLowerCase() === 'td' ||
          range.endContainer.nodeName.toLowerCase() === 'td' ||
          MediumEditor.util.getClosestTag(MediumEditor.selection.getSelectedParentElement(range), 'td')) {
            this.builder.setEditor(MediumEditor.selection.getSelectedParentElement(range), this.restrictNestedTable);
        } else {
            this.builder.setBuilder();
        }
        this.builder.show(this.button.offsetLeft);
    },

    getForm: function () {
        if (!this.builder) {
            this.builder = new Builder({
                onClick: function (rows, columns) {
                    if (rows > 0 || columns > 0) {
                        let table = this.table.insert(rows, columns),
                            firstTd = table.querySelector('td');
                        this.builder.setRange(firstTd);
                    }
                    this.hide();
                }.bind(this),
                ownerDocument: this.document,
                rows: this.rows || 10,
                columns: this.columns || 10,
                editor: this.base
            });

            this.table = new Table(this.base);
        }

        return this.builder.getElement();
    },

    repositionCursor: function(element){
        let table = getParentOf(element, 'table');
        if(element.tagName == "TD"){
            this.builder.setRange(element);
        } else if(!MediumEditorEnhacedTableTools.hasClass(element, "header-color-input")) {
            this.builder.hide();
        }
        if(!table && !(element.nodeName == "A" && MediumEditorEnhacedTableTools.hasClass(element, "medium-editor-table-builder-cell"))){
            this.builder._resetSelectedTable();
        }
    },

    elementClicked: function(element){
        let table = getParentOf(element, 'table');
        if(!table){
            this.builder._adaptarCabeceraOculta(table);
        }
    },

    refresh: function() {
        setTimeout(()=>{
            var doc = this.document,
                tables = doc.getElementsByClassName("medium-editor-table");
            Array.from(tables).forEach(table => {
                this.builder._adaptarCabeceraOculta(table);
                this.builder._forzarBordes(table);
            });
        },10);
    },
});

export default MediumEditorEnhacedTable;
