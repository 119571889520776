<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{
        $t("inspecciones.editar")
      }}</f7-nav-title>
      <f7-nav-title v-else sliding>{{ $t("inspecciones.inspecciones.nuevo") }}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="guardarExpedienteManual()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>

    <r-form
      ref="rform"
      v-slot="{ rform }"
      :itemId="id"
      :readonly="readonly"
      :dbAdapter="null"
      @save="crearExpedienteManual"
      :documentoAdapter="Documento"
      :defaultData="{inspectores: [{rol: 'INSPECTOR'}]}"
      :hidePreloaderOnSave="false"
    >
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <r-input
              floating-label
              :label="$t('inspecciones.inspeccion_form.codigoPedido')"
              :form="rform"
              type="text"
              name="codigoPedido"
              :readonly="true"
            ></r-input>
            <r-input
              floating-label
              :label="$t('inspecciones.inspeccion_form.numeroPedido')"
              :form="rform"
              type="text"
              name="numPedido"
              :readonly="true"
            ></r-input>
            <r-input
              floating-label
              :label="$t('inspecciones.inspeccion_form.numeroLinea')"
              :form="rform"
              type="text"
              name="numeroLineaPedido"
              :readonly="true"
            ></r-input>
            <r-input
              class='col-33'
              floating-label
              :label="$t('inspecciones.inspeccion_form.servicio')"
              :form="rform"
              type="text"
              prefi
              name="servicioCodNombre"
              :readonly="true"
            ></r-input>
            <r-input
              floating-label
              :label="$t('inspecciones.inspeccion_form.materia')"
              :form="rform"
              type="text"
              name="materia"
              :readonly="true"
            ></r-input>
            <r-input
              floating-label
              :label="$t('inspecciones.inspeccion_form.materiaCodExp')"
              :form="rform"
              type="text"
              name="materiaExp"
              :readonly="true"
            ></r-input>
          </f7-row>
        </f7-card-content>
        </f7-card>
      <f7-card
        class="padding-vertical-half padding-right"
        style="z-index: 3000"
      >
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('inspecciones.inspeccion_form.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                ></r-input>
                <f7-row>
                  <r-input
                    floating-label
                    class='col-50'
                    :label="$t('inspecciones.inspeccion_form.fecha_inspeccion')"
                    :form="rform"
                    type="datepicker"
                    :name="'fechaInsp'"
                    :calendar-params="{
                      closeOnSelect: true,
                      routableModals: false,
                      dateFormat: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                    }"
                  />
                  <r-timepicker
                    class="col-25"
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.hora_inicio')"
                    :form="rform"
                    name="hc1"
                  ></r-timepicker>
                  <r-timepicker
                    class="col-25"
                    floating-label
                    :label="$t('inspecciones.inspeccion_form.hora_final')"
                    :form="rform"
                    name="hf1"
                  ></r-timepicker>
                </f7-row>
                <r-input
                  floating-label
                  class="col-25"
                  :label="
                    $t('inspecciones.inspeccion_form.fecha_limite_correccion')
                  "
                  :form="rform"
                  type="datepicker"
                  :name="'fechaLimiteCorrecion'"
                  :calendar-params="{
                    closeOnSelect: true,
                    routableModals: false,
                    dateFormat: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  }"
                />
              </ul>
              <div class="list" style="z-index: 10">
                <f7-row>
                  <ul style="width: 80%">
                    <r-autocomplete
                      outline
                      ref="cliente"
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.cliente')"
                      :form="rform"
                      name="cliente"
                      labelProp="codigoNombre"
                      :minLength="3"
                      :readonly="true"
                      :shownFields="['nif', 'razonSocial', 'codigo']"
                      :async="true"
                    >
                    </r-autocomplete>
                  </ul>
                  <ul style="width: 20%">
                    <r-input
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.nif')"
                      :form="rform"
                      name="cliente.nif"
                      :readonly="true"
                    >
                    </r-input>
                  </ul>
                </f7-row>
              </div>
            </f7-col>
            <f7-block-title  class="col-100">Dirección Inspección</f7-block-title>
            <div class="list">
              <ul>
                <r-direccion
                  class="outlineAzul"
                  :form="rform"
                  name="direccionInspeccion"
                  :guardarCodigos="true"
                  :readonlyFields="['provincia']"
                ></r-direccion>
              </ul>
            </div>
            <f7-block-title class="col-100" v-if="rform && rform.formData" style="z-index: 30">
            {{$t('inspecciones.inspeccion_form.inspectores')}}
              <f7-link
                @click="rform.formData.inspectores.push({rol: 'INSPECTOR'})"
              >{{$t('inspecciones.inspeccion_form.add')}}</f7-link>
            </f7-block-title>
            <div v-if="rform && rform.formData" style="z-index: 50" class="col-100">
                    <f7-card
                      class="elevation-3 padding-vertical-half padding-right"
                      v-for="(item,index) in rform.formData.inspectores"
                      :key="'inspectores-'+index"
                      :style="'z-index:'+(rform.formData.inspectores.length - index)"
                    >
                      <f7-card-content>
                        <f7-list class="list-form">
                          <ul class="no-margin no-padding">
                            <f7-row no-gap>
                              <r-autocomplete
                                :dbAdapter="Inspector"
                                class="col-100 medium-50"
                                floating-label
                                :label="$t('inspecciones.inspeccion_form.inspector')"
                                :form="rform"
                                :name="'inspectores.' + index"
                                labelProp="nombre"
                                :filter="{filter:{rol:'INSPECTOR'}}"
                                :readonly="readonly"
                                :shownFields="['oficina', 'nombre', 'email', 'firma']"
                                :idProp="null"
                                idFieldDetail="id"
                                prefixProp="username"
                                @change="onChangeInspector(index)"
                                
                              ></r-autocomplete>
                              <r-input
                                class="col-100 medium-50"
                                floating-label
                                :label="$t('inspecciones.inspeccion_form.rol')"
                                :form="rform"
                                type="select"
                                :readonly="readonly"
                                :name="'inspectores.' + index + '.rol'"
                              >
                                <option value="INSPECTOR">{{$t('pedidos.inspector')}}</option>
                                <option value="SUPERVISADO">{{$t('pedidos.inspectorSupervisado')}}</option>
                                <option value="OBSERVADOR">{{$t('pedidos.observador')}}</option>
                                <option value="SUPERVISOR">{{$t('pedidos.supervisor')}}</option>
                                <option value="SUPERVISADO_FORMACION">{{$t('pedidos.supervisadoFormacion')}}</option>
                              </r-input>
                            </f7-row>
                          </ul>
                        </f7-list>
                      </f7-card-content>
                      <f7-link
                        v-if="rform.formData"
                        @click="$delete(rform.formData.inspectores, index);"
                        class="margin-half"
                        style="position:absolute;right:0;top:0;"
                        icon-f7="trash"
                      ></f7-link>
                    </f7-card>
                  </div>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-card class="elevation-3 padding-vertical-half padding-right">
        <f7-card-content>
          <!-- <div
            v-if="
              !rform.formData.informes ||
                (rform.formData.informes &&
                  !rform.formData.informes.length > 0)
            "
          >
            Adjuntar certificado del expediente
          </div> -->
          <f7-list class="list-form">
            <ul class="">
              <r-files
                class="col-75 medium-75 display-flex"
                :form="rform"
                name="informes"
                :label="'Adjuntar certificado del expediente'"
                :documentoAdapter="Documento"
                :maxFiles="1"
              ></r-files>
              <r-input
                class="col-100 medium-25"
                :form="rform"
                type="check"
                :name="'adjuntadoSinFirma'"
                label="Sin firma"
              ></r-input>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <f7-toolbar
        :position="'bottom'"
        :class="{
          'bg-color-gray': !rform.formData.calificacion,
          'bg-color-green': rform.formData.calificacion == 'FAVORABLE',
          'bg-color-lime':
            rform.formData.calificacion == 'FAVORABLE_CON_DEFECTOS' ||
            rform.formData.calificacion == 'FAVORABLE_CON_REPARO',
          'bg-color-orange': rform.formData.calificacion == 'DESFAVORABLE',
          'bg-color-red': rform.formData.calificacion == 'NEGATIVO',
        }"
      >
        <div class="list" style="z-index: 10; width: 100%;">
          <ul style="background: transparent;">
            <r-autocomplete-m2m
              class="calificacion"
              inline-label
              outline
              :label="$t('inspecciones.inspeccion_form.calificacion') + ':'"
              :form="rform"
              name="calificacion"
              idProp="id"
              labelProp="nombre"
              :multiple="false"
              :disabled="readonly"
              :dbItems="[
                { id: 'FAVORABLE', nombre: 'Favorable' },
                {
                  id: 'FAVORABLE_CON_DEFECTOS',
                  nombre: 'Favorable con Defectos',
                },
                {
                  id: 'FAVORABLE_CON_REPARO',
                  nombre: 'Favorable con Reparo (reiteración defecto leve)',
                },
                { id: 'DESFAVORABLE', nombre: 'Condicionada' },
                { id: 'NEGATIVO', nombre: 'Negativa' },
              ]"
            >
            </r-autocomplete-m2m>
          </ul>
        </div>
      </f7-toolbar>
    </r-form>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Documento from "./../../js/db/Documento.js";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Cliente from "./../../js/db/Cliente.js";
import rTimepicker from "./../../components/rTimepicker.vue";
import Inspector from "./../../js/db/Inspector.js";
import Utils from "./../../js/Utils.js";
import rDireccion from "./../../components/rDireccion.vue";
import Pedido from "./../../js/db/Pedido.js";

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    rAutocompleteM2m,
    rAutocomplete,
    rTimepicker,
    rDireccion,
  },
  data: function() {
    var self = this;
    return {
      Inspeccion,
      Documento,
      Cliente,
      readonly: !this.editable,
      Inspector,
      Utils,
      Pedido,
    };
  },
  props: {
    id: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  beforeDestroy() {},
 mounted() {
    var self = this;
    var app = self.$f7;
    if(self.$f7route.query.jsonData) {
      var jsonData = JSON.parse(self.$f7route.query.jsonData);
      app.preloader.show();
      Pedido.getLineaCreateExpManual({ data: {id: jsonData[0], lineas: [{numero: jsonData[1]}]} })
        .then(function(res) {
          if (res) {
            self.$set(self.$refs.rform.formData, 'idPedido', res.id);
            self.$set(self.$refs.rform.formData, 'codigoPedido', res.codigo);
            self.$set(self.$refs.rform.formData, 'numPedido', res.numero);
            self.$set(self.$refs.rform.formData, 'numeroLineaPedido', res.lineas[0].numero);
            self.$set(self.$refs.rform.formData, 'cliente', res.cliente);
            self.$set(self.$refs.rform.formData, 'direccionInspeccion', res.lineas[0].direccionInspeccion);
            self.$set(self.$refs.rform.formData, 'fechaInsp', res.lineas[0].camposUsuario.fechaEjecucion);
            self.$set(self.$refs.rform.formData, 'materia', res.lineas[0].servicio.materia.codigo);
            self.$set(self.$refs.rform.formData, 'materiaExp', res.lineas[0].servicio.materia.codigoExpediente);
            self.$set(self.$refs.rform.formData, 'servicioCodNombre', res.lineas[0].servicio.codigo + " - "+ res.lineas[0].servicio.nombre);
            if (res.lineas[0].inspectores) {
              res.lineas[0].inspectores.forEach( insp => {
                if (!insp.rol) {
                  insp.rol = "INSPECTOR";
                }
              });
            }
            self.$set(self.$refs.rform.formData, 'inspectores', res.lineas[0].inspectores);
          }
        })
        .catch(function(error) {
          app.dialog.alert(error.message);
        }).finally(function() {
          app.preloader.hide();
        });
    }
  },
  methods: {


    guardarExpedienteManual() {

      const self = this;
      var app = self.$f7;
      var exp = self.$refs.rform.formData;
      var cod = exp.codigo;
      var prov = exp.direccionInspeccion.provincia;
      var materia = exp.materiaExp;
      var partesCod = cod != null ? cod.split("/"): null;

      if (partesCod && partesCod[0] && partesCod[0] != prov ) {

        app.dialog.confirm(
        self.$t("La provincia de la dirección de inspección no coincide con la del código del expediente, ¿desea continuar?"),
        "Código provincia",
        function() {

          if (partesCod && partesCod[1] && partesCod[1] != materia ) {
            app.dialog.confirm(
            self.$t("La materia del servicio no coincide con la del código del expediente, ¿desea continuar?"),
            "Código materia",
            function() {
              self.$refs.rform.saveItem();
            });
          } else {
            self.$refs.rform.saveItem();
          }
        });
      } else if (partesCod && partesCod[1] && partesCod[1] != materia) {
        app.dialog.confirm(
          self.$t("La materia del servicio no coincide con la del código del expediente, ¿desea continuar?"),
          "Código materia",
          function() {
            self.$refs.rform.saveItem();
          });
      } else {
        self.$refs.rform.saveItem();
      }
    },

    onChangeInspector(index) {
      const self = this;
      self.$set(self.$refs.rform.formData.inspectores[index],'rol','INSPECTOR');
    },

    crearExpedienteManual(data){
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Inspeccion.crearExpedienteManual(data)
        .then(function(res) {
          app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: "Expediente guardado correctamente",
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                app.preloader.hide();
                app.views.current.router.navigate(
            "/inspecciones/view/" + res.id + "/"
          );  
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  },
};
</script>
