import Provincias from './provincias.vue';
import ProvinciaForm from './provincia-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/provincias/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias', ['ADMIN', 'INSPECTOR'], Provincias),
        keepAlive: true
    },
    {
        path: '/provincias/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/new', ['ADMIN', 'INSPECTOR'], ProvinciaForm)
    },
    {
        path: '/provincias/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/edit', ['ADMIN', 'INSPECTOR'], ProvinciaForm)
    },
    {
        path: '/provincias/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/view', ['ADMIN', 'INSPECTOR'], ProvinciaForm, { editable: false })
    },
];
export default routes;