<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('inventario.inventario')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Inventario"
        :tableCode="'INVENTARIO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="200"
        :funcShowCheck="_ => true"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/inventario/new/">{{$t('inventario.nuevo')}}</a>
      </template>
      <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/inventario/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/inventario/edit/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
      </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Inventario from "./../../js/db/Inventario.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Inventario,
      Utils
    };
  },
  computed: {},
  methods: {

   },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>