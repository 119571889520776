import Materias from './materias.vue';
import MateriaForm from './materia-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/materias/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias', ['ADMIN', 'INSPECTOR'], Materias),
        keepAlive: true
    },
    {
        path: '/materias/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/new', ['ADMIN', 'INSPECTOR'], MateriaForm)
    },
    {
        path: '/materias/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/edit', ['ADMIN', 'INSPECTOR'], MateriaForm)
    },
    {
        path: '/materias/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/view', ['ADMIN', 'INSPECTOR'], MateriaForm, { editable: false })
    },
];
export default routes;