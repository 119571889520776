<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Formatos" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Formato"
        :tableCode="'FORMATO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['versionSiguiente','fechaDesde','fechaHasta']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="150"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/formatos/new/">{{$t('formatos.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item, rlist}">
          <f7-link 
            icon-f7="eye" color="green" 
            :href="`/formatos/view/${item.id}/`"
            v-tooltip="'Ver'"
          ></f7-link>
          <f7-link 
            v-if="!item.versionSiguiente && new Date(item.fechaDesde) < Date.now() && (!item.fechaHasta || new Date(item.fechaHasta) > Date.now())"
            icon-f7="square_stack_3d_up" color="blue" 
            :href="`/formatos/version/${item.id}/`"
            v-tooltip="'Versionar'"
          ></f7-link>
          <f7-link 
            class="display_inline_block"
            v-tooltip="$t('formatos.duplicar')"
            icon-f7="rectangle_stack_badge_plus" color="green"
            @click="formatoSeleccionado=item"
            popup-open=".popupduplicar"
          ></f7-link>
          <f7-link 
            v-if="!item.versionSiguiente && (item.fechaDesde == null || new Date(item.fechaDesde) >= Date.now())"
            icon-f7="square_pencil" color="blue" 
            :href="`/formatos/edit/${item.id}/`"
            v-tooltip="'Editar'"
          ></f7-link>
          <f7-link 
            v-if="item.fechaHasta == null || new Date(item.fechaHasta) > Date.now()"
            icon-f7="text_badge_checkmark" color="orange" 
            :href="`/formatos/auditoria/${item.id}/`"
            v-tooltip="$t('formatos.auditoria')"
          ></f7-link>
          <f7-link 
            v-if="item.fechaDesde == null || new Date(item.fechaDesde) > Date.now()"
            icon-f7="trash" color="red" 
            @click="rlist.deleteItem(item.id);"
            v-tooltip="'Borrar'"
          ></f7-link>
        </template>
        
      </r-dynamic-list>
      
    </f7-page-content>
     <f7-popup class="popupduplicar" ref="popupduplicar" @popup:close="formatoSeleccionado = null" swipeToClose>
            <r-form
              ref="formDuplicar"
              :itemId="formatoIdSeleccionado"
              @save="duplicarFormato"
            >
              <template v-slot="{rform}">
                <f7-page v-if="formatoSeleccionado" >
                  <f7-navbar>
                    <f7-nav-left>
                      <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-left>
                    <f7-nav-title>
                      {{$t('formatos.duplicar')}}
                    </f7-nav-title>
                    <f7-nav-right>
                      
                    </f7-nav-right>
                  </f7-navbar>
                  <f7-list no-hairlines-md>
                    <f7-row class="list list-form no-hairlines">
                      <f7-col width="66" medium="66">
                        <r-input
                          floating-label
                          :label="$t('formatos.nuevoCodigo')"
                          :form="rform"
                          type="text"
                          name="newCode"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                  </f7-list>
                  <f7-button
                    fill
                    color="primary"
                    class="margin-horizontal"
                    @click="$refs.formDuplicar.saveItem()"
                  >{{$t('formatos.duplicar')}}</f7-button>
                </f7-page>
              </template>
            </r-form>
          </f7-popup>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Formato from "./../../js/db/Formato.js";
import Utils from "./../../js/Utils.js";
import rInput from "./../../components/rInput.vue";
import rForm from "./../../components/rForm.vue";

export default {
  components: {
    rDynamicList,
    rInput,
    rForm
  },
  data() {
    return {
      Formato,
      Utils,
      formatoSeleccionado: null
    };
  },
  computed: {
    formatoIdSeleccionado: function() {
      var self = this;
      return self.formatoSeleccionado ? self.formatoSeleccionado.id : null;
    }
  },
  methods: {
    showCheckInList: function(item) {
      return !item.versionSiguiente && (item.fechaDesde == null || new Date(item.fechaDesde) >= Date.now()); 
    },

    duplicarFormato: function() {
      var self = this;
      var app = self.$f7;
      var codigo = self.$refs.formDuplicar.formData.newCode;
      var idCopiado = self.formatoIdSeleccionado;
      app.preloader.show();
      Formato.clonar({
        itemId: idCopiado,
        newCode: codigo,
        data: {}
      }).then(function(res){
        if (self.$refs.popupduplicar) {
          self.$refs.popupduplicar.close();
        }
        app.views.current.router.navigate(
          "/formatos/edit/" + res.id + "/"
        );
      })
    }
    
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    
  }
};
</script>