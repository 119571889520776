import Informes from './informes.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/informes/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('informes', ['ADMIN'], Informes),
        keepAlive: true
    }
];
export default routes;