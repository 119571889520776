import LineasPedidos from './lineaspedidos.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/lineaspedidos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineaspedidos', ['ADMIN', 'INSPECTOR'], LineasPedidos),
        keepAlive: true
    },
    {
        path: '/lineaspedidos/filter',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineaspedidos', ['ADMIN', 'INSPECTOR'], LineasPedidos, { searchOnInit: true }),
        keepAlive: false
    }
];
export default routes;