<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="esEquipo ? $t('tipositem.tiposequipo'): $t('tipositem.tipositem')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="TipoItem"
        sort="creationDate"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :filter ="{esEquipo: esEquipo}" 
        :filtersName="esEquipo ? 'tiposequipo' : 'tipositem'"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <div class="data-table-links">
                <a class="link" :href="esEquipo ? '/tiposequipo/new/' : '/tipositem/new/'">Nuevo</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.reload()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('TiposItem.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('codigo')"
                :class="rlist.sortableClasses('codigo')"
              >{{$t('tipositem.codigo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('nombre')"
                :class="rlist.sortableClasses('nombre')"
              >{{$t('tipositem.nombre')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('plazoCalibrado')"
                :class="rlist.sortableClasses('plazoCalibrado')"
              >{{$t('tipositem.plazoCalibrado')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('plazoVerificacion')"
                :class="rlist.sortableClasses('plazoVerificacion')"
              >{{$t('tipositem.plazoVerificacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('plazoRevision')"
                :class="rlist.sortableClasses('plazoRevision')"
              >{{$t('tipositem.plazoRevision')}}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item) in vlData.items" :key="'tipositem-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td>{{item.codigo}}</td>
            <td>{{item.nombre}}</td>
            <td>{{item.plazoCalibrado}}</td>
            <td>{{item.plazoVerificacion}}</td>
            <td>{{item.plazoRevision}}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="eye" color="green" :href="esEquipo ? `/tiposequipo/view/${item.id}/` : `/tipositem/view/${item.id}/`"></f7-link>
              <f7-link icon-f7="square_pencil" color="blue" :href="esEquipo ? `/tiposequipo/edit/${item.id}/` : `/tipositem/edit/${item.id}/`"></f7-link>
              <f7-link icon-f7="trash" color="red" @click="rlist.deleteItem(item.id)"></f7-link>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form 
              @submit="rlist.setFilter($event); rpanel.close();"
              :defaultData="{esEquipo:esEquipo}" 
              :filtersName="esEquipo ? 'tiposequipo' : 'tipositem'" 
            >
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('tipositem.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('tipositem.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import TipoItem from "./../../js/db/TipoItem.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      TipoItem,
      rPanelOpenedByBreakpoint: false
    };
  },
  computed: {},
  props: {
    esEquipo: {
      type: Boolean
    }
  },
  methods: {
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>