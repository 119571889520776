<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('centrostrabajo.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('centrostrabajo.centrotrabajo')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('centrostrabajo.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="CentroTrabajo"
      :defaultData="{canalesComunicacion:[]}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <ul>
                <r-input
                  floating-label
                  :label="$t('centrostrabajo.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('centrostrabajo.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Delegacion"
                  floating-label
                  :label="$t('centrostrabajo.delegacion')"
                  :form="rform"
                  name="idDelegacion"
                  idProp="id"
                  labelProp="nombre"
                ></r-autocomplete>
                <r-direccion
                  :form="rform"
                  name="direccion">
                </r-direccion>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>

    <!-- Canales Comunicacion -->
      <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
        {{$t('centrostrabajo.canalescomunicacion')}}
        <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.canalesComunicacion.push({tipo: '', nombre: '', valor:''})">{{$t('centrostrabajo.add')}}</f7-link>
      </f7-block-title>

      <div v-if="rform && rform.formData" style="z-index: 30">
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
          v-for="(item,index) in rform.formData.canalesComunicacion"
          :key="'canalcomunicacion-'+index"
          :style="'z-index:'+(rform.formData.canalesComunicacion.length - index)"
        >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <r-input
                    class="col-33"
                    floating-label
                    :label="$t('centrostrabajo.tipo')"
                    :form="rform"
                    type="select"
                    :name="'canalesComunicacion.' + index + '.tipo'"
                  >
                    <option selected></option>
                    <option value="TELEFONO">{{$t('centrostrabajo.telefono')}}</option>
                    <option value="TELEFONO_MOVIL">{{$t('centrostrabajo.telefono_movil')}}</option>
                    <option value="FAX">{{$t('centrostrabajo.fax')}}</option>
                    <option value="EMAIL">{{$t('centrostrabajo.email')}}</option>
                    <option value="WEB">{{$t('centrostrabajo.web')}}</option>
                  </r-input>
                  <r-input
                    class="col-33"
                    floating-label
                    :label="$t('centrostrabajo.nombre')"
                    :form="rform"
                    :name="'canalesComunicacion.' + index + '.nombre'"
                  ></r-input>
                  <r-input
                    class="col-33"
                    floating-label
                    :label="$t('centrostrabajo.valor')"
                    :form="rform"
                    :name="'canalesComunicacion.' + index + '.valor'"
                  ></r-input>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-link
            v-if="!rform.readonly && rform.formData"
            @click="$delete(rform.formData.canalesComunicacion,index);"
            class="margin-half"
            style="position:absolute;right:0;top:0;"
            icon-f7="trash"
          ></f7-link>
        </f7-card>
      </div>
  </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rDireccion from "./../../components/rDireccion.vue";
import CentroTrabajo from "./../../js/db/CentroTrabajo.js";
import Delegacion from "./../../js/db/Delegacion.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rDireccion,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      CentroTrabajo,
      Delegacion,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          CentroTrabajo.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("centrostrabajo.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>