<template>
<f7-page :page-content="true">
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    :readonly="true"
    :dbAdapter="Pedido"
    :documentoAdapter="Documento"
  >
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title >Documentación pedido {{rform.formData.numero}}</f7-nav-title>
      </f7-navbar>
    </template>
    <template v-slot:default="{rform}">
 
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="rform.formData.documentos && !rform.formData.documentos.length > 0">El pedido no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                    :ignorarOffline="true"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
    </template>
  </r-form>
</f7-page>
</template>

<script>
import rForm from "./../../components/rForm.vue";
import rFiles from "./../../components/rFiles.vue";
import Pedido from "./../../js/db/Pedido.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rFiles,
  },
  data: function() {
    var self = this;
    return {
      Pedido,
      Documento,
      idData: null,
      rform: null,
      target: process.env.TARGET,
    };
  },
  props: {
    id: {
      type: String
    }
  },
  computed: {
    
  },
  beforeDestroy() {},
  methods: {
    
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
 
  }
};
</script>