<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title sliding>{{$t('perfil.perfil')}}</f7-nav-title>
      <f7-nav-right>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>
      Contraseña
    </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        <f7-card-content>
          <f7-row>
            <f7-button
              fill
              icon-f7="lock"
              text="Cambiar Contraseña"
              class="display-flex"
              popup-open=".popup-cambio-pass"
              width="100"
              medium="30"
            ></f7-button>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-popup ref="popupCambioPass" class="popup-cambio-pass" swipe-to-close>
        <f7-page>
          <f7-navbar title="Cambiar Contraseña">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
        <f7-list form>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.actual_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="oldPassword" placeholder />
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.new_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="newPassword" placeholder />
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.repeat_new_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="repeatPassword" placeholder />
              </div>
            </div>
          </li>
        </f7-list>
        <f7-button
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="saveCambioPass"
          ></f7-button>
        </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.btn-cliente {
  width: 200px;
  float: right;
}
</style>
<script>
import Vue from "vue";
import Usuario from "./../../js/db/Usuario.js";
import rForm from "./../../components/rForm.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";


export default {
  components: {
    rForm,
    rAutocomplete,
    rInput,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Usuario,
    };
  },
  props: {
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      data.clienteContactos.forEach((cc,i) => self.$set(data.clienteContactos[i], 'firma', Array.isArray(cc.firma) ? cc.firma[0] : cc.firma));
      resolve(data);
    },
    getUser: function() {
      var user = localStorage.getItem("user");
      if (typeof user != "undefined" && user) {
        var userObj = JSON.parse(user);
        return userObj;
      }
      return null;
    },
    saveCambioPass: function(e) {
      var self = this;
      var app = self.$f7;
      var $el = self.$$(self.$refs.popupCambioPass.$el);
      var data = app.form.convertToData($el.find("form"));
      if (!data.oldPassword || data.oldPassword == '') {
        app.dialog.alert("Introduzca la contraseña actual.");
        return;
      }
      if (!data.newPassword || data.newPassword == '') {
        app.dialog.alert("Introduzca la nueva contraseña.");
        return;
      }
      if (!data.repeatPassword || data.repeatPassword == '') {
        app.dialog.alert("Introduzca la repetición de la nueva contraseña.");
        return;
      }
      if (data.newPassword != data.repeatPassword) {
        app.dialog.alert("Las nuevas contraseñas no coinciden");
        return;
      }
      app.preloader.show();
      Usuario.cambiarPass(data)
        .then(function(res) {
          app.preloader.hide();
          app.toast
            .create({
              text: 'Contraseña Cambiada',
              position: "center",
              closeTimeout: 2000
            })
            .open();
          self.$refs.popupCambioPass.close(true);
        })
        .catch(function(e) {
          app.preloader.hide();
          app.toast
            .create({
              text: e.error_description,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        });
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    var user = self.getUser();
  }
};
</script>