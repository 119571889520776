import Tiposdocumentos from './tiposdocumentos.vue';
import TipodocumentoForm from './tipodocumento-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/tiposdocumentos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposdocumentos', ['ADMIN', 'INSPECTOR'], Tiposdocumentos),
        keepAlive: true
    },
    {
        path: '/tiposdocumentos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposdocumentos/new', ['ADMIN', 'INSPECTOR'], TipodocumentoForm)
    },
    {
        path: '/tiposdocumentos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposdocumentos/edit', ['ADMIN', 'INSPECTOR'], TipodocumentoForm)
    },
    {
        path: '/tiposdocumentos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposdocumentos/view', ['ADMIN', 'INSPECTOR'], TipodocumentoForm, { editable: false })
    },
];
export default routes;
