<template>
  <f7-page :page-content="true">
    <r-form
      ref="rform"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="RegistroEmail"
    >
      <template v-slot:before="{ rform }">
        <f7-navbar>

          <f7-nav-left>
            <f7-link v-if="esPopup" @click.stop="$emit('close')">{{ $t('common.cerrar') }}</f7-link>
            <f7-link v-else @click.stop="$f7router.back()">&lt; {{ $t('atras') }}</f7-link>
          </f7-nav-left>
          <f7-nav-title v-if="id && !readonly" sliding>{{$t('registroemail.editar')}}</f7-nav-title>
          <f7-nav-title v-else-if="id" sliding>{{$t('registroemail.registroemail')}}</f7-nav-title>
          <f7-nav-title v-else sliding>{{$t('registroemail.nuevo')}}</f7-nav-title>
          <f7-nav-right>
            <f7-button
              v-if="!readonly"
              fill
              icon-f7="floppy_disk"
              text="Guardar"
              class="display-flex"
              @click="rform.saveItem()"
            ></f7-button>
            <template slot="right" v-else>
              <!-- <f7-button color="blue" @click="readonly=false">Editar</f7-button>
              <f7-button color="red" @click="deleteItem">Eliminar</f7-button> -->
            </template>
          </f7-nav-right>
        </f7-navbar>
      </template>
      <template v-slot:default="{ rform }">
        <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
          <f7-card-content>
            <f7-row no-gap class="list list-form no-hairlines">
              <r-input
                class="col-25 medium-25"
                floating-label
                :label="$t('registroemail.from')"
                :form="rform"
                type="text"
                name="from"
              ></r-input>
              <r-input
                class="col-25 medium-25"
                floating-label
                :label="$t('registroemail.to')"
                :form="rform"
                type="text"
                name="to"
              ></r-input>
              <r-input
                class="col-25 medium-25"
                floating-label
                :label="$t('registroemail.cc')"
                :form="rform"
                type="text"
                name="cc"
              ></r-input>
              <r-input
                class="col-25 medium-25"
                floating-label
                :label="$t('registroemail.cco')"
                :form="rform"
                type="text"
                name="cco"
              ></r-input>
              <r-input
                class="col-25 medium-25"
                floating-label
                :label="$t('registroemail.fechaEnvio')"
                :form="rform"
                type="datepicker"
                name="fechaEnvio"
                key="fechaEnvio"
                :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' } }"
              ></r-input>
              <r-input
                class="col-75 medium-75"
                floating-label
                :label="$t('registroemail.subject')"
                :form="rform"
                type="text"
                name="subject"
              ></r-input>
            </f7-row>
            <f7-row no-gap class="list list-form no-hairlines">
              <r-rich-text-input
                class="col-100"
                floating-label
                :label="$t('registroemail.body')"
                :form="rform"
                name="body"
              ></r-rich-text-input>
            </f7-row>
            <br/>
            <br/>
            <f7-row no-gap class="list list-form no-hairlines">
              <r-files
                floating-label
                :label="$t('registroemail.attachments')"
                :form="rform"
                name="attachments"
                :documentoAdapter="Documento"
              ></r-files>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </template>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rFiles from "../../components/rFiles.vue";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import rAutocomplete from "../../components/rAutocomplete.vue";
import RegistroEmail from "../../js/db/RegistroEmail.js";

export default {
  components: {
    rForm,
    rInput,
    rRichTextInput,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      RegistroEmail,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    defaultData: {
      type: Object
    },
    esPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          RegistroEmail.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("registroemail.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>