import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Item = Object.create(DatabaseModel);

Item.model = 'item';
Item.modelFront = 'items';

Item.clonar = function(params){
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/copiar/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

export default Item;