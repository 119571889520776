<template>
  <f7-page :page-content="true">
    <r-form
      ref="rform"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Tarea"
      :defaultData="defaultData"
      :documentoAdapter="Documento"
      :beforeSave="beforeSave"
      @loadItem="loadItem"
    >
    <template v-slot:before="{ rform }">
      <f7-navbar>
        <f7-nav-left>
          <f7-link v-if="esPopup" @click.stop="$emit('close')">{{ $t('common.cerrar') }}</f7-link>
          <f7-link v-else @click.stop="$f7router.back()">&lt; {{ $t('atras') }}</f7-link>
        </f7-nav-left>
        <f7-nav-title v-if="id && !readonly" sliding>{{$t('tarea.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="id" sliding>{{$t('tarea.tarea')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('tarea.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <f7-button
            v-if="readonly"
            fill
            icon-f7="arrow_right_square"
            text="Tarea siguiente"
            class="display-flex"
            @click="tareaSiguiente()"
          ></f7-button>
          <template
              slot="right"
              v-if="rform && rform.formData && rform.formData.estado" 
            >
              <div class="menu-item menu-item-dropdown bg-color-primary" >
                <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
                <div class="menu-dropdown menu-dropdown-right bg-color-primary" style="z-index:10">
                  <div class="menu-dropdown-content bg-color-primary text-align-right">
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData"
                      @click="readonly=false">{{$t('Editar')}}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer"
                      v-if="rform && rform.formData && rform.formData.estado == 'PENDIENTE'" 
                      @click="popupPlanificarOpened = true">{{$t('Planificar')}}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer"
                      v-if="rform && rform.formData && rform.formData.estado == 'PLANIFICADA'" 
                      @click="desplanificarTarea">{{$t('Desplanificar')}}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer" v-if="readonly" @click.prevent="enviarEmail = true">{{$t('Enviar e-mail')}}</div>
                    <div class="menu-dropdown-item menu-close pointer" v-if="rform && rform.formData && rform.formData.estado == 'PENDIENTE'" @click="deleteItem">{{$t('Eliminar')}}</div>
                  </div>
                </div>
              </div>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <template v-slot:default="{ rform }">
      <f7-card class="padding-vertical-half padding-right" >
        <div name="datos_generales" style="display: flex;justify-content: center;">
          <div style="height: 12px;"></div>
          <f7-button
            v-if="readonly && !rform.formData.idOportunidad && !rform.formData.idCampania"
            class="col-20"
            icon-f7="rectangle_stack_person_crop_fill"
            @click="crearOportunidad"
          >Crear oportunidad</f7-button>
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            :icon-f7="showDetalle ?'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            style="padding-left: 7px;font-size: 10px;float: right;position: absolute;right: 10px;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" :medium="esPopup ? '100' : '50'">
              <ul>
                <r-input
                  outline
                  floating-label
                  :label="$t('tarea.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  v-if="readonly"
                ></r-input>
                <r-input
                  v-if="readonly && rform && rform.formData && rform.formData.estado"
                  floating-label
                  :label="$t('tarea.estado')"
                  :form="rform"
                  type="badge"
                  name="estado"
                  :readonly="true"
                  :color="getColorBadgeEstado(rform.formData.estado)"
                  :text="rform.formData.estado"
                ></r-input>
                <r-input
                  v-else
                  floating-label
                  :label="$t('tarea.estado')"
                  :form="rform"
                  type="select"
                  name="estado"
                  :color="getColorBadgeEstado(rform.formData.estado)"
                  :text="getTextBadgeEstado(rform.formData.estado)"
                  @change="changeEstado"
                >
                  <option selected disabled></option>
                  <option value="PENDIENTE">PENDIENTE</option>
                  <option value="PLANIFICADA">PLANIFICADA</option>
                  <option value="REALIZADA">REALIZADA</option>
                  <option value="CANCELADA">CANCELADA</option>
                </r-input>
                <r-autocomplete
                  outline
                  :dbAdapter="TipoTarea"
                  key="tipoTarea"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('tarea.tipoTarea')"
                  :form="rform"
                  name="tipo"
                  labelProp="nombre"
                  :idProp="null"
                  prefixProp="codigo"
                  idFieldDetail="id"
                  :shownFields="['id', 'codigo', 'nombre']"
                  :showNavigationLink="false"
                  :validation="{isValid: obj => {return !validator.isEmpty(obj.id, {ignore_whitespace:true})}, errorMessage: '* El tipo de tarea es obligatorio'}"
                ></r-autocomplete>
                <r-input
                  outline
                  floating-label
                  :label="$t('tarea.descripcion')"
                  :form="rform"
                  type="text"
                  name="descripcion"
                  :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* La descripción es obligatoria'}"
                ></r-input>
                <r-input
                  outline
                  floating-label
                  :label="$t('tarea.comentarios')"
                  :form="rform"
                  type="textarea"
                  name="comentarios"
                ></r-input>
                <r-autocomplete
                  outline
                  :dbAdapter="Empleado"
                  key="empleado"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('tarea.empleado')"
                  :form="rform"
                  name="username"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                  :showNavigationLink="false"
                  :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* El empleado es obligatorio'}"
                ></r-autocomplete>
                <div v-if="rform && rform.formData && rform.formData.estado && rform.formData.estado != 'PENDIENTE'&& rform.formData.estado && rform.formData.estado != 'CANCELADA'">
                  <r-input
                    outline
                    floating-label
                    class="col-100 medium-50"
                    :label="$t('tarea.fecha')"
                    :form="rform"
                    type="datepicker"
                    name="fechaFecha"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-timepicker
                    outline
                    floating-label
                    class="col-100 medium-50"
                    :label="$t('tarea.hora_inicio')"
                    :form="rform"
                    name="fechaHora"
                    :clearButton="true"
                  ></r-timepicker>
                  <r-timepicker
                    v-if="rform && rform.formData && rform.formData.id"
                    outline
                    floating-label
                    class="col-100 medium-50"
                    :label="$t('tarea.hora_final')"
                    :form="rform"
                    name="horaFinal"
                    :clearButton="true"
                    :readonly="true"
                  ></r-timepicker>
                </div>
                <r-input
                  outline
                  floating-label
                  :label="$t('tarea.tiempoEstimado')"
                  :form="rform"
                  name="tiempoEstimado"
                  type="number"
                ></r-input>
                <r-input
                  outline
                  floating-label
                  :label="$t('tarea.importe')"
                  :form="rform"
                  name="importe"
                  type="number"
                  step=".01"
                ></r-input>
                <f7-row>
                  <r-input
                    outline
                    class="col-40"
                    floating-label
                    :label="$t('tarea.numeroPedido')"
                    :form="rform"
                    type="text"
                    name="numeroPedido"
                  ></r-input>
                  <r-input
                    outline
                    class="col-40"
                    floating-label
                    :label="$t('tarea.numeroLineaPedido')"
                    :form="rform"
                    type="text"
                    name="numeroLineaPedido"
                  ></r-input>
                  <f7-link
                    v-if="rform.formData.idPedido"
                    class="col-20 link-externo"
                    :href="getUrl('pedidos/view/' + rform.formData.idPedido + '/')"
                    icon-f7="arrow_up_right_square"
                    color="green"
                    :external="true"
                    v-tooltip="'Ver Pedido'"
                  > Ver Pedido</f7-link>
                  <div v-else class="col-20"></div>
                </f7-row>
                <f7-row>
                  <r-input
                    class="col-80"
                    outline
                    floating-label
                    :label="$t('tarea.numeroOferta')"
                    :form="rform"
                    type="text"
                    name="numeroOferta"
                  ></r-input>
                  <f7-link
                    v-if="rform.formData.idOferta"
                    class="col-20 link-externo"
                    :href="getUrl('ofertas/view/' + rform.formData.idOferta + '/')"
                    icon-f7="arrow_up_right_square"
                    color="green"
                    :external="true"
                    v-tooltip="'Ver Oferta'"
                  > </f7-link>
                </f7-row>
                <f7-row>
                  <r-input
                    outline
                    class="col-80"
                    floating-label
                    :label="$t('tarea.codigoCliente')"
                    :form="rform"
                    type="text"
                    name="codigoCliente"
                  ></r-input>
                  <f7-link
                    v-if="rform.formData.idCliente"
                    class="col-20 link-externo"
                    :href="getUrl('clientes/view/' + rform.formData.idCliente + '/')"
                    icon-f7="arrow_up_right_square"
                    color="green"
                    :external="true"
                    v-tooltip="'Ver Cliente'"
                  > </f7-link>
                  <r-input
                    outline
                    class="col-80"
                    floating-label
                    :label="$t('tarea.codigoOportunidad')"
                    :form="rform"
                    type="text"
                    name="codigoOportunidad"
                  ></r-input>
                  <f7-link
                    v-if="rform.formData.idOportunidad"
                    class="col-20 link-externo"
                    :href="getUrl('oportunidades/view/' + rform.formData.idOportunidad + '/')"
                    icon-f7="arrow_up_right_square"
                    color="green"
                    :external="true"
                    v-tooltip="'Ver Oportunidad'"
                  > </f7-link>
                  <r-input
                    outline
                    class="col-80"
                    floating-label
                    :label="$t('tarea.codigoCampania')"
                    :form="rform"
                    type="text"
                    name="codigoCampania"
                  ></r-input>
                  <f7-link
                    v-if="rform.formData.idCampania"
                    class="col-20 link-externo"
                    :href="getUrl('campanias/view/' + rform.formData.idCampania + '/')"
                    icon-f7="arrow_up_right_square"
                    color="green"
                    :external="true"
                    v-tooltip="'Ver Campaña'"
                  > </f7-link>
                </f7-row>
                <r-direccion
                  :form="rform"
                  :name="'direccion'"
                  :guardarCodigos="true"
                  autocomplete="off"
                  :showNavigationLink="false"
                >
                </r-direccion>
                <r-input
                  outline
                  v-if="rform && rform.formData && rform.formData.comentariosPlanificacion"
                  floating-label
                  :label="$t('tarea.comentariosPlanificacion')"
                  :form="rform"
                  type="text"
                  name="comentariosPlanificacion"
                  :readonly="true"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-documentos">Documentación</f7-button>
          <f7-button fill tab-link="#tab-emails">e-Mails</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-documentos" tab-active>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="!rform.formData.documentos || (rform.formData.documentos && !rform.formData.documentos.length > 0)">La tarea no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-emails" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILTABS'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['to','datos']"
            :mandatoryFilter ="{ 'datos##codigoTarea': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupEmailEditable = false; emailId = item.id; popupEmailOpened = true"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
      </f7-tabs>
      <pre style="display:none;">{{rform.formData}}</pre>

      <f7-popup ref="popupPlanificar"  swipe-to-close v-if="popupPlanificarOpened" :opened="true"  @popup:close="popupPlanificarOpened = false" >
      <f7-page v-if="rform && rform.formData">
        <f7-navbar title="Planificar Tarea">
          <f7-nav-right>
            <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form 
          ref="rformPlanificar" 
          @save="planificar()"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
        >
          <template v-slot:default="{ rform }">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
            >
              <f7-card-content>
                <f7-list class="list-form">
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                            floating-label
                            class="col-100 medium-50"
                            :label="$t('tarea.fecha')"
                            :form="rform"
                            type="datepicker"
                            name="fechaFecha"
                            :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                        ></r-input>
                        <r-timepicker
                            floating-label
                            class="col-100 medium-50"
                            :label="$t('tarea.hora_inicio')"
                            :form="rform"
                            name="fechaHora"
                            :clearButton="true"
                        ></r-timepicker>
                        <r-input
                          floating-label
                          :label="$t('tarea.comentariosPlanificacion')"
                          :form="rform"
                          type="text"
                          name="comentariosPlanificacion"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                      fill
                      icon-f7="disk"
                      :text="$t('common.guardar')"
                      class="display-flex margin-vertical"
                      color="green"
                      @click="rform.saveItem()"
                    ></f7-button>
                </f7-col>
              </f7-row>
 
            </f7-card>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>
    </template>
    </r-form>
    <f7-popup ref="popupEmail" swipe-to-close :opened="true" v-if="popupEmailOpened" @popup:closed="popupEmailOpened = false; emailId = null">
      <registro-email-form :id="emailId" :editable="popupEmailEditable" @close="$refs.popupEmail.close();"  
        :defaultData="{ codigoTarea: $refs.rform.formData.codigo}" :esPopup="true"
      ></registro-email-form>
    </f7-popup>
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>
    
  </f7-page>
</template>
<style scoped>

  .link-externo {
    margin-top: 20px;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rDynamicList from "./../../components/rDynamicList.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import EnvioMail from "./components/envio-mail.vue";
import Tarea from "./../../js/db/Tarea.js";
import Empleado from "./../../js/db/Empleado.js";
import TipoTarea from "./../../js/db/TipoTarea.js";
import rDireccion from "./../../components/rDireccion.vue";
import validator from "validator";
import rTimepicker from "./../../components/rTimepicker.vue";
import Documento from "./../../js/db/Documento.js";
import Utils from "./../../js/Utils.js";
import Oportunidad from "./../../js/db/Oportunidad.js";
import RegistroEmail from "../../js/db/RegistroEmail.js";
import RegistroEmailForm from "./../registroemail/registroemail-form.vue";

export default {
  components: {
    rForm,
    rDynamicList,
    rInput,
    rFiles,
    rAutocomplete,
    EnvioMail,
    rDireccion,
    rTimepicker,
    RegistroEmailForm
  },
  data: function() {
    var self = this;
    return {
      Tarea,
      Empleado,
      TipoTarea,
      validator,
      readonly: !this.editable,
      popupPlanificarOpened: false, 
      Documento,
      RegistroEmail,
      popupTareaEditable: false,
      popupEmailOpened: false,
      emailId: null,
      popupEmailEditable: false,
      enviarEmail: false,
      showDetalle: true
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    defaultData: {
      type: Object
    },
    esPopup: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  beforeDestroy() {},
  methods: {
    crearOportunidad() {
      const self = this;
      self.$f7.dialog.confirm('¿Está seguro de que desea crear una oportunidad?', 'Crear oportunidad', () => {
        self.$f7.preloader.show();
        Oportunidad.save({
          data: { tarea: { id: self.$refs.rform.formData.id }, cliente: { id: self.$refs.rform.formData.idCliente }, oferta: { id: self.$refs.rform.formData.idOferta }, pedido: { id: self.$refs.rform.formData.idPedido }, lineaPedido: { numero: self.$refs.rform.formData.numeroLineaPedido }, gestor: Utils.getUser().username }
        }).then(res => {
          self.$set(self.$refs.rform.formData, 'codigoOportunidad', res.codigo);
          self.$set(self.$refs.rform.formData, 'idOportunidad', res.id);
          self.$f7.dialog.alert("Creada oportunidad " + res.codigo);
          window.open('#!/oportunidades/view/' + res.id + '/', "_blank");
        }).catch(function (error) {
          app.dialog.alert(error.message);
        }).then(_ => {
          self.$f7.preloader.hide();
        });
      });
    },
    changeEstado(e) {
      if (typeof e == 'string' && e == 'REALIZADA') {
        const self = this;
        if (!self.$refs.rform.formData.fechaFecha) {
          self.$set(self.$refs.rform.formData, 'fechaFecha', new Date());
          self.$set(self.$refs.rform.formData, 'fechaHora', new Date());
        }
      }
    },  
    tareaSiguiente() {
      const self = this;
      const tarea = self.$refs.rform.formData;
      delete tarea.id;
      delete tarea.comentariosPlanificacion;
      delete tarea.horaInicio;
      tarea.estado = "PENDIENTE";
      Tarea.post({ data: tarea }).then((res) => {
        window.open(self.getUrl('tarea/view/' + res.id + '/'));
      }).catch(e => app.dialog.alert(error.message))
    },
    getUrl(url) {
      const self = this;
      var currentUrl = new URL(window.location.href);
      if (self.target == 'cordova') {
        return '/' + url;
      }
      return currentUrl.pathname + ('#!/' + url);
    },
    getColorBadgeEstado: function(estado) {
      let color = "gray";
        switch (estado) {
          case "PENDIENTE":
            color = "orange";
            break;
          case "PLANIFICADA":
            color = "blue";
            break;
          case "REALIZADA":
            color = "teal";
            break;
          case "CANCELADA":
            color = "red";
            break;
        }
      return color;
    },
    getTextBadgeEstado: function(estado) {
      let texto = "gray";
        switch (estado) {
          case "PENDIENTE":
            texto = "Tarea Pendiente de Gestionar";
            break;
          case "PLANIFICADA":
            texto = "Tarea Planificada";
            break;
          case "REALIZADA":
            texto = "Tarea Realizada";
            break;
          case "CANCELADA":
            texto = "Tarea Cancelada";
            break;
        }
      return texto;
    },

    desplanificarTarea: function() {
      var self = this;

      app.dialog.confirm(
        "¿Seguro que desea desplanificar esta tarea?",
        "Desplanificar",
        function() {
          
          Tarea.desplanificar({itemId: self.id, data: self.$refs.rform.formData }).then(function(res) {
            if (res) {
                self.$refs.rform.formData = res.dto;
                self.readonly = true;
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: "Tarea desplanificada correctamente",
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally(function(e) {
            app.preloader.hide();
            self.loadItem(); 
          })
        }
      );

      

    },

    planificar: function() {
      var self = this;
      self.$refs.popupPlanificar.close();
      var app = self.$f7;

      var formDataPlanificar = self.$refs.rformPlanificar.formData;

      var dateInicio = new Date (formDataPlanificar.fechaFecha);
      var horaInicio = new Date (formDataPlanificar.fechaHora);

      
      dateInicio.setHours(horaInicio.getHours());
      dateInicio.setMinutes(horaInicio.getMinutes());
      dateInicio.setSeconds(horaInicio.getSeconds());
      dateInicio.setMilliseconds(horaInicio.getMilliseconds());

      app.preloader.show();

      if (!self.$refs.rform.formData.username) {
        app.dialog.alert("Debe asignar la tarea a un empleado");
        return;
      }

      app.dialog.confirm(
        "¿Seguro que desea planificar esta tarea?",
        "Planificar",
        function() {
          
         var dataSend = JSON.parse(JSON.stringify( Object.assign({}, self.$refs.rform.formData, {horaInicio: dateInicio, comentariosPlanificacion: formDataPlanificar.comentariosPlanificacion })));
          
          Tarea.planificar({itemId: dataSend.id, data: dataSend}).then(function(res) {
            if (res) {
                self.$refs.rform.formData = res.dto;
                self.readonly = true;
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: "Tarea planificada correctamente",
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally(function(e) {
            app.preloader.hide();
            self.loadItem(); 
          })
        }
      );

    },
    loadItem: function() {
      var self = this;
      var formData = self.$refs.rform.formData;
      var horaFinal = new Date (new Date (formData.horaInicio).getTime() + formData.tiempoEstimado * 60000);
      self.$set(formData, "fechaFecha", formData.horaInicio);
      self.$set(formData, "fechaHora", formData.horaInicio);
      self.$set(formData, "horaFinal", horaFinal);

    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Tarea.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    beforeSave: function (data, resolve, reject) {
      var self = this;      
      var formData = data;
      var dateInicio = new Date (formData.fechaFecha);
      var horaInicio = new Date (formData.fechaHora);
      // var horaFinal = new Date (formData.horaFinal);

      
      dateInicio.setHours(horaInicio.getHours());
      dateInicio.setMinutes(horaInicio.getMinutes());
      dateInicio.setSeconds(horaInicio.getSeconds());
      dateInicio.setMilliseconds(horaInicio.getMilliseconds());

      // horaFinal.setDate(dateInicio.getDate());

      // var tiempo = (horaFinal.getTime() - dateInicio.getTime())/60000;


      self.$set(formData, "horaInicio", dateInicio);
      // self.$set(formData, "tiempoEstimado", tiempo);

      resolve(formData);

    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;


      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("tarea.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      self.loadItem();  
      self.$emit('saved', res);
    },
    filasSeleccionadas: function() {
      const self = this;
      let filas = [self.$refs.rform.formData].map(selected => {
        return {id: selected.id};
      });
      return filas;
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>