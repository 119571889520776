<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('gruposcliente.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('gruposcliente.gruposcliente')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('gruposcliente.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="GrupoCliente"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('gruposcliente.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  v-if="readonly"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('gruposcliente.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import GrupoCliente from "./../../js/db/GrupoCliente.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      GrupoCliente,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          GrupoCliente.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("gruposcliente.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>