import DatabaseModel from './DatabaseModel.js';

var Cliente = Object.create(DatabaseModel);

Cliente.model = 'cliente';
Cliente.modelFront = 'clientes';

Cliente.importar = function(documento) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/importar/',
        method: 'POST',
        data: documento
    };
    return self.sendRequest(params, false).then((res) => {
        CacheService.clearCache(self.model);
        return res;
    }).catch((error) => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
    });
}

export default Cliente;