import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Oferta = Object.create(DatabaseModel);

Oferta.model = 'public/oferta';

Oferta.rechazar = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/rechazar',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

export default Oferta;