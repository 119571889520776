import TiposItem from './../tipositem/tipositem.vue';
import TiposItemForm from './../tipositem/tipositem-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/tiposequipo/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposequipo', ['ADMIN', 'INSPECTOR'], TiposItem, { esEquipo: true }),
        keepAlive: true
    },
    {
        path: '/tiposequipo/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposequipo/new', ['ADMIN', 'INSPECTOR'], TiposItemForm, { esEquipo: true })
    },
    {
        path: '/tiposequipo/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposequipo/edit', ['ADMIN', 'INSPECTOR'], TiposItemForm, { esEquipo: true })
    },
    {
        path: '/tiposequipo/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tiposequipo/view', ['ADMIN', 'INSPECTOR'], TiposItemForm, { editable: false, esEquipo: true })
    },
];
export default routes;