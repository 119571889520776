import DatabaseModel from './DatabaseModel.js';

var Tarea = Object.create(DatabaseModel);

Tarea.model = 'tarea';
Tarea.modelFront = 'tareas';

Tarea.planificar = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/planificar',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Tarea.desplanificar = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/desplanificar',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};


Tarea.enviarEmail = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/enviarEmail/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};
Tarea.probarPlantillaCorreo = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/probarPlantillaCorreo/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default Tarea;