import DatabaseModel from './DatabaseModel.js';

var CampaniaComercial = Object.create(DatabaseModel);

CampaniaComercial.model = 'campaniacomerciales';
CampaniaComercial.modelFront = 'campaniacomerciales';

// Campania.generarOportunidades = function (id) {
//     var self = this;
//     if (!id) reject({ error: 'id not defined' });
//     var params = {
//         url: self.server_movilidad + self.prefix + self.model + '/' + id + '/generarOportunidades',
//         method: 'PUT'
//     };
//     return self.sendRequest(params, false);
// };

export default CampaniaComercial;