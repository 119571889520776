<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('condicionespago.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('condicionespago.condicionespago')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('condicionespago.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="CondicionPago"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <r-input
              class="col-100 medium-25"
              floating-label
              :label="$t('condicionespago.codigo')"
              :form="rform"
              type="text"
              name="codigo"
              :readonly="true"
            ></r-input>
            <r-input
              class="col-100 medium-75"
              floating-label
              :label="$t('condicionespago.nombre')"
              :form="rform"
              type="text"
              name="nombre"
              outline
            ></r-input>
            <r-input
              class="col-100 medium-25"
              floating-label
              :label="$t('condicionespago.activa')"
              :form="rform"
              type="check"
              name="activa"
            ></r-input>
            <r-autocomplete
              ref="viasPago"
              :dbAdapter="ViaPago"
              class="col-100 medium-25"
              floating-label
              :label="$t('condicionespago.viaPago')"
              :form="rform"
              name="viaPago.codigo"
              labelProp="nombre"
              idProp="codigo"
              idFieldDetail="id"
              :shownFields="['nombre']"
              @change="recalcularNombre"
              outline
              ></r-autocomplete>
            <r-input
              class="col-100 medium-25"
              floating-label
              :label="$t('condicionespago.cuenta')"
              :form="rform"
              :validation="{isValid: str => { return validator.isEmpty(str) || validator.isIBAN(str) }, errorMessage: '* Introduzca un IBAN válido'}"
              type="text"
              name="cuenta"
              outline
              @change="recalcularNombre"
            ></r-input>
            <r-input
              class="col-100 medium-25"
              floating-label
              :label="$t('condicionespago.plazoDias')"
              :form="rform"
              type="number"
              name="plazoDias"
              outline
              @change="recalcularNombre"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import CondicionPago from "./../../js/db/CondicionPago.js";
import ViaPago from "./../../js/db/ViaPago.js";
import Empresa from "./../../js/db/Empresa.js";
import validator from "validator";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      CondicionPago,
      ViaPago,
      Empresa,
      readonly: !this.editable,
      validator
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          CondicionPago.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("condicionespago.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    recalcularNombre: function(){
      const self = this,
        formData = self.$refs.rform.formData,
        cuenta = formData.cuenta,
        plazoDias = formData.plazoDias,
        viaPagoCodigo = (formData.viaPago) ? formData.viaPago.codigo : null,
        viasPagoNombreArr = viaPagoCodigo ? self.$refs.viasPago.items.filter(via => via.codigo == viaPagoCodigo).map(via => via.nombre) : [],
        viaPagoNombre = viasPagoNombreArr.length ? viasPagoNombreArr[0] : null;
      var nombre = (viaPagoNombre) ? viaPagoNombre : "";
      if (cuenta) {
        nombre = `${nombre} en ${cuenta}`;
      }
      if (plazoDias) {
        nombre = `${nombre} a ${plazoDias} días`;
      }
      self.$set(formData, "nombre", nombre);
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>