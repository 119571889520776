<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Notificaciones" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Notificacion"
        :tableCode="'NOTIFICACION'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :controlsWidth="50"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/notificaciones/new/">{{$t('notificaciones.nuevo')}}</a>
      </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Notificacion from "./../../js/db/Notificacion.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Notificacion,
      Utils
    };
  },
  computed: {},
  methods: {
    
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>