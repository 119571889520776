<template>
  <div v-if="filtro.tipo == 'FECHA'" class="label-group">
    <li><label>{{$t(filtro.nombre)}}</label></li>
    <r-input
      ref="mesActual"
      floating-label
      :readonly="!!form && (!!form.getValue(getPathName() + 'FechaVacia') || !!form.getValue(getPathName() + 'FechaRellena'))"
      outline
      :label="$t('common.mesActual')"
      :form="form"
      :simple="true"
      type="check"
      :name="getPathName() + 'MesActual'"
      @change="mesActual"
    ></r-input>
    <r-input
      ref="fechaVacia"
      floating-label
      outline
      :label="$t('common.fechaVacia')"
      :form="form"
      :simple="true"
      type="check"
      :name="getPathName() + 'FechaVacia'"
      @change="fechaVacia"
    ></r-input>
    <r-input
      ref="fechaRellena"
      floating-label
      outline
      :label="$t('common.fechaRellena')"
      :form="form"
      :simple="true"
      type="check"
      :name="getPathName() + 'FechaRellena'"
      @change="fechaRellena"
    ></r-input>
    <r-input
      ref="fechaDesde"
      v-if="!!form && !form.getValue(getPathName() + 'FechaVacia') && !form.getValue(getPathName() + 'FechaRellena')"
      :readonly="!!form && (!!form.getValue(getPathName() + 'MesActual') || !!form.getValue(getPathName() + 'FechaVacia') || !!form.getValue(getPathName() + 'FechaRellena'))"
      :overrideReadonly="true"
      floating-label
      outline
      :label="$t('common.desde')"
      :form="form"
      type="datepicker"
      :name="getPathName() + 'Desde'"
      :clearButton="true"
      :viewTooltip="viewTooltip"
      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
    ></r-input>
    <r-input
      v-if="!!form && !form.getValue(getPathName() + 'FechaVacia') && !form.getValue(getPathName() + 'FechaRellena')"
      ref="fechaHasta"
      :readonly="!!form && (!!form.getValue(getPathName() + 'MesActual') || !!form.getValue(getPathName() + 'FechaVacia') || !!form.getValue(getPathName() + 'FechaRellena'))"
      outline
      floating-label
      :label="$t('common.hasta')"
      :form="form"
      type="datepicker"
      :name="getPathName() + 'Hasta'"
      :clearButton="true"
      :viewTooltip="viewTooltip"
      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
    ></r-input>
  </div>
  <div v-else-if="filtro.tipo == 'BOOLEAN'" class="label-group">
    <li><label>{{$t(filtro.nombre)}}</label></li>
    <r-input
      ref="booleanSI"
      :label="$t('common.yes')"
      :form="form"
      :simple="true"
      type="check"
      :name="getPathName() + 'BOOLEANSI'"
    ></r-input>
    <r-input
      ref="booleanNO"
      :label="$t('common.no')"
      :form="form"
      :simple="true"
      type="check"
      :name="getPathName() + 'BOOLEANNO'"
    ></r-input>
  </div>
  <div v-else-if="filtro.tipo == 'IMPORTE'" class="label-group">
    <li><label>{{$t(filtro.nombre)}}</label></li>
    <r-input
      floating-label
      outline
      :label="$t('common.desde')"
      :form="form"
      type="number"
      step=".01"
      :name="getPathName() + 'Desde'"
      :clearButton="true"
      :viewTooltip="viewTooltip"
    ></r-input>
    <r-input
      outline
      floating-label
      :label="$t('common.hasta')"
      :form="form"
      type="number"
      step=".01"
      :name="getPathName() + 'Hasta'"
      :clearButton="true"
      :viewTooltip="viewTooltip"
    ></r-input>
  </div>
  <r-input
    v-else-if="filtro.tipo == 'NUMERO'"
    floating-label
    outline
    :label="$t(filtro.nombre)"
    :form="form"
    type="number"
    :clearButton="true"
    :viewTooltip="viewTooltip"
    :name="getPathName()"
  ></r-input>
  <r-autocomplete
    v-else-if="filtro.tipo == 'SELECT'"
    floating-label
    outline
    :model="getValue('model')"
    :label="$t(filtro.nombre)"
    :dbItems="getValue('items')"
    :form="form"
    :name="getPathName()"
    :idProp="getValue('idProp')"
    :labelProp="getValue('labelProp')"
    :prefixProp="getValueOpcion('prefixProp')"
    :async="getValue('async')"
    :minLength="getValue('minLength')"
    :viewTooltip="viewTooltip"
    :clearButton="true"
    :shownFields="getValue('shownFields')"
  ></r-autocomplete>
  <r-autocomplete-m2m
    v-else-if="filtro.tipo == 'MULTISELECT'"
    class="col-100"
    floating-label
    outline
    :model="getValue('model')"
    :label="$t(filtro.nombre)"
    :dbItems="getValue('items')"
    :form="form"
    :name="getPathName()"
    type="textarea"
    :lineBreak="true"
    :idProp="getValue('idProp')"
    :labelProp="getValue('labelProp')"
    :prefixProp="getValueOpcion('prefixProp')"
    :viewTooltip="viewTooltip"
    :shownFields="getValue('shownFields')"
  ></r-autocomplete-m2m>
  <r-autocomplete-m2m
    v-else-if="filtro.tipo == 'MES'"
    class="col-100"
    floating-label
    outline
    :model="getValue('model')"
    :label="$t(filtro.nombre)"
    :dbItems="getMeses()"
    :form="form"
    :name="getPathName()"
    type="textarea"
    :lineBreak="true"
    idProp="id"
    labelProp="nombre"
    :viewTooltip="viewTooltip"
  ></r-autocomplete-m2m>
  <r-autocomplete-m2m
    v-else-if="filtro.tipo == 'ANYO'"
    class="col-100"
    floating-label
    outline
    :model="getValue('model')"
    :label="$t(filtro.nombre)"
    :dbItems="getAnyos()"
    :form="form"
    :name="getPathName()"
    type="textarea"
    :lineBreak="true"
    idProp="id"
    labelProp="nombre"
    :viewTooltip="viewTooltip"
  ></r-autocomplete-m2m>
  <r-input
    v-else
    floating-label
    outline
    :label="$t(filtro.nombre)"
    :form="form"
    type="text"
    :clearButton="true"
    :viewTooltip="viewTooltip"
    :name="getPathName()"
  ></r-input>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>

import rInput from "./rInput.vue";
import rAutocompleteM2m from "./rAutocompleteM2m.vue";
import rAutocomplete from "./rAutocomplete.vue";

export default {
  components: {
    rInput,
    rAutocompleteM2m,
    rAutocomplete
  },
  data() {
    return {
      self: this,
      booleanCambioMasivoActivar: false
    };
  },
  props: {
    filtro: {
      required: true,
      type: Object
    },
    form: {
      type: Object
    },
    viewTooltip: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const self = this;
    if (self.filtro.tipo == 'FECHA') {
      self.setFechasMesActual(!!self.form.getValue(self.getPathName() + 'MesActual'));
      self.setFechasVacias(!!self.form.getValue(self.getPathName() + 'FechaVacia'));
      self.setFechasRellenas(!!self.form.getValue(self.getPathName() + 'FechaRellena'));
    }

  },
  methods: {
    cleanData() {
      const self = this;
      self.cleanDataChildren(self.$children);
    },
    cleanDataChildren(children) {
      const self = this;
      if (children && Array.isArray(children) && children.length) {
        children.forEach(child => {
          if (child.setValue) {
            child.setValue(null);
          }
          self.cleanDataChildren(child.$children);
        });
      }
    },
    changeBooleanActivo (event) {
      const self = this;
      self.booleanCambioMasivoActivar = event.target.checked;
      if (!self.booleanCambioMasivoActivar) {
        self.$refs.booleanChange.setValue(null);
      }
    },
    getMeses () {
      const self = this;
      return [
        {id: 1, nombre: "01 - " + self.$t('Enero')},
        {id: 2, nombre: "02 - " + self.$t('Febrero')},
        {id: 3, nombre: "03 - " + self.$t('Marzo')},
        {id: 4, nombre: "04 - " + self.$t('Abril')},
        {id: 5, nombre: "05 - " + self.$t('Mayo')},
        {id: 6, nombre: "06 - " + self.$t('Junio')},
        {id: 7, nombre: "07 - " + self.$t('Julio')},
        {id: 8, nombre: "08 - " + self.$t('Agosto')},
        {id: 9, nombre: "09 - " + self.$t('Septiembre')},
        {id: 10, nombre: "10 - " + self.$t('Octubre')},
        {id: 11, nombre: "11 - " + self.$t('Noviembre')},
        {id: 12, nombre: "12 - " + self.$t('Diciembre')}
      ];
    },
    getAnyos () {
      const self = this;
      let anyosAtras = self.filtro.opciones && self.filtro.opciones["anyosAtras"] ? self.filtro.opciones["anyosAtras"] : 5;
      let currentYear = new Date().getFullYear(); 
      var arr = [];
      for (let i = 0; i <= anyosAtras; i++) {
        arr.push({id: currentYear, nombre: "" + currentYear});
        currentYear--;
      }

      return arr;

    },
    mesActual (event) {
      const self = this;
      if (event.target) {
        self.setFechasMesActual(event.target.checked);
      }
    },
    fechaVacia (event) {
      const self = this;
      if (event.target) {
        self.setFechasVacias(event.target.checked);
      }
    },
    fechaRellena (event) {
      const self = this;
      if (event.target) {
        self.setFechasRellenas(event.target.checked);
      }
    },
    setFechasMesActual(isChecked) {
      const self = this;
      if (isChecked) {
        var date = new Date();
        var y = date.getFullYear(); 
        var m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        self.$refs.fechaDesde.setValue([firstDay], "calendar");
        self.$refs.fechaHasta.setValue([lastDay], "calendar");
      } else {
        self.$refs.fechaDesde.setValue(null, "calendar");
        self.$refs.fechaHasta.setValue(null, "calendar");
          
      }
    },
    setFechasVacias(isChecked) {
      const self = this;
      if (isChecked) {
        if (self.$refs.fechaDesde) {
          self.$refs.fechaDesde.setValue(null, "calendar");
        }
        if (self.$refs.fechaHasta) {
          self.$refs.fechaHasta.setValue(null, "calendar");
        }
        self.$refs.mesActual.setValue(false);
        self.$refs.fechaRellena.setValue(false);
      } 
    },
    setFechasRellenas(isChecked) {
      const self = this;
      if (isChecked) {
        if (self.$refs.fechaDesde) {
          self.$refs.fechaDesde.setValue(null, "calendar");
        }
        if (self.$refs.fechaHasta) {
          self.$refs.fechaHasta.setValue(null, "calendar");
        }
        self.$refs.mesActual.setValue(false);
        self.$refs.fechaVacia.setValue(false);
      } 
    },
    getValue: function(field) {
      const self = this;
      var value = self.filtro[field];
      return value;
    },
    getValueOpcion: function(opcion) {
      const self = this;
      var value = self.filtro.opciones && self.filtro.opciones[opcion] ? self.filtro.opciones[opcion] : undefined;
      return value;
    },
    getPathName: function() {
      const self = this;
      return self.filtro.pathName.replaceAll(".", "##");
    }
  }
};
</script>