import Items from './items.vue';
import ItemForm from './item-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/items/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('items', ['ADMIN', 'INSPECTOR'], Items, { esEquipo: false }),
        keepAlive: true
    },
    {
        path: '/items/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('items/new', ['ADMIN', 'INSPECTOR'], ItemForm, { esEquipo: false })
    },
    {
        path: '/items/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('items/edit', ['ADMIN', 'INSPECTOR'], ItemForm, { esEquipo: false })
    },
    {
        path: '/items/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('items/view', ['ADMIN', 'INSPECTOR'], ItemForm, { editable: false, esEquipo: false })
    },
];
export default routes;