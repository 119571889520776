import Quill from 'quill';
import SaltoPagina from "../formats/saltoPagina"
// let BlockEmbed = Quill.import('blots/block/embed');
const Module = Quill.import('core/module')

class TPM extends Module {
  static register() {
    Quill.register(SaltoPagina)
  }

  constructor(...args) {
    super(...args);
  }

}

export default TPM;
