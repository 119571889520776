<template>
  <f7-popup :opened="true" tablet-fullscreen >
    <f7-page no-toolbar no-swipeback>
      <f7-navbar>
        <f7-nav-left class="left">
          <img src="static/img/logo.png" style="max-height: 1.6rem;" />
        </f7-nav-left>
        <f7-nav-title sliding>{{$t('ofertas.aceptacionOferta')}}</f7-nav-title>
      </f7-navbar>
      <r-form
        v-if="token"
        ref="rform"
        class="page"
        classContent="page-content full-height"
        :itemId="token"
        @save="onSave"
        @errorLoading="errorLoading = $event"
        @loadItem="ofertaLoaded = true"
        :readonly="readonly"
        :dbAdapter="OfertaPublic"
        :beforeSave="beforeSave"
        :documentoAdapter="DocumentoPublic"
      >
        <template v-slot:default="{rform}">
          <div class="text-align-center" v-if="ofertaLoaded && !errorLoading">
            <div class="display-inline-block margin-vertical">
              <f7-button
                :disabled="currentPage <= 1"
                fill
                color="primary"
                class="display-inline-block margin-horizontal"
                @click="currentPage--"
              >{{$t('ofertas.anterior')}}</f7-button>
              <div class="display-inline-block margin-horizontal page-div border-color-primary">
                Pág: {{currentPage}} / {{pageCount}}
              </div>
              <f7-button
                :disabled="currentPage >= pageCount"
                fill
                color="primary"
                class="display-inline-block margin-horizontal"
                @click="currentPage++"
              >{{$t('ofertas.siguiente')}}</f7-button>
            </div>
            <pdf 
              v-show="loadedRatio && loadedRatio >= 1"
              ref="pdfViewer"
              class="pdf-viewer"
              :src="OfertaPublic.server_movilidad + OfertaPublic.prefix + OfertaPublic.model + '/' + token + '/pdf'"
              @num-pages="pageCount = $event"
              @page-loaded="currentPage = $event"
              @progress="loadedRatio = $event"
              :page="currentPage"
            ></pdf>
            <div v-if="!loadedRatio || loadedRatio < 1" class="text-align-center color-primary">
              <div class="preloader color-primary">
                <span class="preloader-inner">
                  <span class="preloader-inner-circle"></span>
                </span>
              </div>
              <span>{{$t('ofertas.loading')}}</span>
            </div>
            <div class="display-inline-block margin-vertical">
              <f7-button
                :disabled="currentPage <= 1"
                fill
                color="primary"
                class="display-inline-block margin-horizontal"
                @click="currentPage--"
              >{{$t('ofertas.anterior')}}</f7-button>
              <div class="display-inline-block margin-horizontal page-div border-color-primary">
                Pág: {{currentPage}} / {{pageCount}}
              </div>
              <f7-button
                :disabled="currentPage >= pageCount"
                fill
                color="primary"
                class="display-inline-block margin-horizontal"
                @click="currentPage++"
              >{{$t('ofertas.siguiente')}}</f7-button>
            </div>
            <div class="display-block margin-vertical">
              <f7-button
                fill
                color="primary"
                class="display-inline-block margin-horizontal"
                @click="$refs.pdfViewer.print()"
              >{{$t('ofertas.imprimir')}}</f7-button>
            </div>
          </div>
          <f7-card v-if="ofertaLoaded && !errorLoading" class="padding-vertical-half padding-right form-cliente" style="z-index: 3000">
            <div class="text-align-center">
              <f7-block-title class="form-cliente-element form-title padding-horizontal">
                {{$t('ofertas.formTitle')}}
              </f7-block-title>
            </div>
            <f7-card-content>
              <div class="list list-form no-hairlines">
                  <div
                    class="form-cliente-element"
                    v-for="(item, index) in rform.formData.fields"
                    :key="'field-' + index"
                  >
                    <r-input
                      v-if="item.tipo == 'TEXTO'"
                      class="col-100"
                      outline
                      floating-label
                      :label="item.nombre"
                      :form="rform"
                      type="text"
                      :placeholder="'Introduzca ' + item.nombre"
                      :name="'fields.'+index+'.valor'"
                      :validation="!item.obligatorio ? null : {isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                    ></r-input>
                    <r-input
                      v-if="item.tipo == 'FECHA'"
                      class="col-100"
                      outline
                      floating-label
                      :label="item.nombre"
                      :form="rform"
                      type="datepicker"
                      :placeholder="'Introduzca ' + item.nombre"
                      :name="'fields.'+index+'.valor'"
                      :validation="!item.obligatorio ? null : {isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      v-else-if="item.tipo == 'DNI'"
                      class="col-100"
                      outline
                      floating-label
                      :label="item.nombre"
                      :form="rform"
                      type="text"
                      :placeholder="'Introduzca un DNI válido. Ej: 12345678A'"
                      :name="'fields.'+index+'.valor'"
                      :validation="{isValid: str => { return validator.isIdentityCard(str, 'ES')}, errorMessage: '* Introduzca un DNI válido. Ej: 12345678A'}"
                    ></r-input>
                    <r-input
                      v-else-if="item.tipo == 'IBAN'"
                      class="col-100"
                      outline
                      floating-label
                      :label="item.nombre"
                      :form="rform"
                      type="text"
                      :placeholder="'Introduzca un IBAN válido'"
                      :name="'fields.'+index+'.valor'"
                      :validation="{isValid: validator.isIBAN, errorMessage: '* Introduzca un IBAN válido'}"
                    ></r-input>
                    <template
                      class="col-100"
                      v-else-if="item.tipo == 'LIST_EMAIL'"
                    >
                      <f7-block-title>
                        {{item.nombre}}
                        <f7-link v-if="!readonly" @click="addEmail(index)">{{$t('ofertas.add')}}</f7-link>
                      </f7-block-title>
                      <div class="form-caja margin-horizontal">
                        <div class="margin text-color-red" v-if="!rform.formData.fields[index].valor || !rform.formData.fields[index].valor.length">* Ningún correo electrónico introducido</div>
                        <div
                          class="col-100"
                          v-for="(email, index2) in rform.formData.fields[index].valor"
                          :key="'field-' + index + '-email-' + index2"
                        >
                          <r-input
                            class="col-100"
                            outline
                            floating-label
                            :label="'Correo electrónico'"
                            :form="rform"
                            type="text"
                            :clearButton="false"
                            :placeholder="'Introduzca un correo electrónico válido'"
                            :name="'fields.'+index+'.valor.'+index2"
                            :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                          >
                            <f7-link
                              v-if="!readonly"
                              v-tooltip="'Borrar'"
                              slot="inner-end"
                              @click="$delete(rform.formData.fields[index].valor, index2);"
                              class="input-suffix-btn"
                              icon-f7="trash"
                              style="top: initial;right: 15px;"
                            ></f7-link>
                          </r-input>
                        </div>
                      </div>
                    </template>

                    <template
                      class="col-100"
                      v-else-if="item.tipo == 'DIRECCION'"
                    >
                      <f7-block-title>
                        {{item.nombre}}
                      </f7-block-title>
                      <div class="form-caja margin-horizontal">
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :readonly="true"
                          :disabled="true"
                          :label="'Razón Social'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca la Razón Social'"
                          :name="'fields.'+index+'.valor.alias'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'NIF'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca el NIF de la empresa'"
                          :name="'fields.'+index+'.valor.nif'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'Dirección'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca una dirección'"
                          :name="'fields.'+index+'.valor.direccion'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'Código postal'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca un código postal'"
                          :name="'fields.'+index+'.valor.cp'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'Población'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca una poblacion'"
                          :name="'fields.'+index+'.valor.poblacion'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'Provincia'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca una provincia'"
                          :name="'fields.'+index+'.valor.provincia'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                        <r-input
                          class="col-100"
                          outline
                          floating-label
                          :label="'País'"
                          :form="rform"
                          type="text"
                          :placeholder="'Introduzca un país'"
                          :name="'fields.'+index+'.valor.pais'"
                          :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                        >
                        </r-input>
                      </div>
                    </template>
                    <r-files 
                      v-else-if="item.tipo == 'DOCUMENTOS'"
                      :form="rform" 
                      :name="'fields.'+index+'.valor'"
                      :documentoAdapter="DocumentoPublic"
                      :readonly="readonly"
                    ></r-files>
  
                    
                  </div>
                  <div class="form-buttons">
                    <f7-button
                      v-if="!readonly"
                      fill
                      icon-f7="multiply_circle"
                      text="Rechazar"
                      class="form-button-item"
                      color="red"
                      popup-open=".rechazar-oferta"
                    ></f7-button>
                    <f7-button
                      v-if="!readonly"
                      fill
                      icon-f7="checkmark_circle"
                      text="Firmar"
                      class="form-button-item"
                      @click="rform.saveItem()"
                    ></f7-button>
                  </div>
              </div>
            </f7-card-content>
          </f7-card>
          <f7-card v-if="errorLoading" class="padding-vertical-half padding-right form-cliente form-cliente-error border-color-red" style="z-index: 3000">
            <f7-card-content class="text-align-center">
              {{errorLoading}}
            </f7-card-content>
          </f7-card>
          <pre style="display:none;">{{rform.formData}}</pre>
        </template>
      </r-form>
    </f7-page>
    <f7-popup ref="rechazarOferta" class="rechazar-oferta">
      <f7-page>
        <f7-navbar title="Rechazar Oferta">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <r-form
          @save="onRechazar"
          :readonly="readonly"
        >
          <template v-slot:default="{rform}">
            <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
              <f7-card-content>
                <div class="list list-form no-hairlines">
                  <r-input 
                    class="col-100"
                    outline
                    floating-label
                    :label="'Comentarios del rechazo'"
                    :name="'comentario'"
                    :form="rform"
                    type="textarea"
                    :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                  >
                  </r-input>
                </div>
              </f7-card-content>
            </f7-card>
            <div class="form-buttons">
              <f7-button
                v-if="!readonly"
                fill
                icon-f7="multiply_circle"
                text="Volver"
                class="form-button-item"
                popup-close
              ></f7-button>
              <f7-button
                v-if="!readonly"
                fill
                icon-f7="arrow_up_right_circle"
                text="Rechazar"
                class="form-button-item"
                color="red"
                @click="rform.saveItem()"
              ></f7-button>
            </div>
          </template>
        </r-form>
      </f7-page>
    </f7-popup>
  </f7-popup>
</template>
<style>
  .annotationLayer section.linkAnnotation {
    display: none;
  }
  @media (max-width: 768px) {
    .list .form-cliente-element input, .list .form-cliente-element .item-input-outline .item-floating-label {
      font-size: 10px;
    }
  }
</style>
<style scoped>
  .linkAnnotation {
    display: none;
  }
  .page-div {
    border: solid 2px;
    padding: 2px;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .pdf-viewer {
    width: 50%;
    margin: auto;
    min-width: 750px;
  }
  .form-cliente {
    width: 50%;
    margin: auto;
  }
  .form-cliente-element {
    width: 50%;
    margin: auto;
  }
  .form-cliente-error {
    border: 2px solid;
  }
  .form-title {
    font-size: 20px;
  }
  .form-caja {
    min-height: 30px;
    border: 1px solid gray;
    border-radius: var(--f7-input-outline-border-radius);
    padding-bottom: 7px;
  }
  .form-caja div {
    font-size: 12px;
    color: gray;
  }
  .form-buttons {
    display: flex;
    margin: auto;
    flex-direction:row;
    text-align: center;
    max-width: 500px;
  }
  .form-button-item {
    flex:1 1 auto;
    margin: 20px;
    max-width: 200px;
  }

  @media (max-width: 768px) {
    .form-cliente-element.form-title {
      font-size: 15px;
    }
    .form-cliente-element {
      font-size: 10px;
    }
    .form-cliente-element input, .form-cliente-element label {
      font-size: 10px;
    }
    .form-caja div {
      font-size: 10px;
    }
    .pdf-viewer {
      width: 90%;
      margin: auto;
      min-width: 0;
    }
    .form-cliente {
      width: 90%;
      margin: auto;
    }
    .form-cliente-element {
      width: 95%;
      margin: auto;
    }
  }

</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import OfertaPublic from "./../../js/db/OfertaPublic.js";
import DocumentoPublic from "./../../js/db/DocumentoPublic.js";
import validator from "validator";
import pdf from 'vue-pdf';

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    pdf
  },
  data: function() {
    var self = this;
    return {
      OfertaPublic,
      DocumentoPublic,
      validator,
      readonly: !this.editable,
      token: null,
      pageCount: 0,
      currentPage: 1,
      loadedRatio: 0,
      errorLoading: undefined,
      ofertaLoaded: false
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      var fields = formData.fields;
      resolve(fields);
    },
    onRechazar: function(res) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      OfertaPublic.rechazar({itemId: self.token, data: res})
        .then(function(res) {
          if (self.$refs.rechazarOferta) {
            self.$refs.rechazarOferta.close();
          }
          app.toast
          .create({
            icon: '<i class="f7-icons">checkmark</i>',
            text: self.$t("ofertas.guardado_public"),
            position: "center",
            destroyOnClose: true,
            closeTimeout: 2000
          })
          .open();
          self.errorLoading = self.$t("ofertas.guardado_public");
        }).catch(function(e) {
          if (typeof e == "string") {
            e = {
              message: e
            };
          }
          app.dialog.alert(e.message);
        }).finally(function(e) {
          app.preloader.hide();
        });
    },
    addEmail: function(index) {
      var self = this;
      var arr = self.$refs.rform.formData.fields[index].valor || [];
      arr.push("");
      self.$set(self.$refs.rform.formData.fields[index], 'valor', arr);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      // app.toast
      //   .create({
      //     text: self.$t("ofertas.guardado_ok"),
      //     position: "center",
      //     closeTimeout: 2000,
      //     icon: '<i class="f7-icons">checkmark_alt</i>'
      //   })
      //   .open();
      app.dialog.alert("A continuación se le abrirá la aplicación de firma de la oferta.",
        () => window.location.href = res.urlFirma
      );
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    if(self.$f7route.query.token) {
      self.token = self.$f7route.query.token;
    }
  }
};
</script>