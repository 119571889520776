<template>
    <div class="item-content item-input item-label-up">
      <div class="item-inner">
        <div class="item-title item-label item-floating-label">{{label}}</div>
        <div class="item-input-wrap">
          <prism-editor 
            :code="getValue()" 
            :language="language" 
            :lineNumbers="lineNumbers"
            :readonly="overrideReadonly ? readonly : readonly || form.readonly"
            :emitEvents="true"
            v-on="$listeners"
            @change="setValue($event)"
          ></prism-editor>
        </div>
      </div>
    </div>
</template>
<style>
</style>
<script>
import "prismjs";
import "prismjs/themes/prism.css";
import "vue-prism-editor/dist/VuePrismEditor.css";
import PrismEditor from 'vue-prism-editor'

export default {
  components: {
    PrismEditor
  },
  data() {
    return {
      self: this
    };
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      return context;
    },
    setValue: function(val) {
      if (val != undefined && val === '') {
        val = undefined;
      }
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self);
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: "js"
    },
    lineNumbers: {
      type: Boolean,
      default: true
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.$emit("mounted", self);
  }
};
</script>