import Oportunidades from './oportunidades.vue';
import OportunidadesForm from './oportunidad-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/oportunidades/',
        beforeEnter: RoutesUtils.checkAuthPage,
    async: RoutesUtils.checkPermission('oportunidades', [], Oportunidades),
        keepAlive: true
    },
    {
        path: '/oportunidades/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('oportunidades/new', [], OportunidadesForm)
    },
    {
        path: '/oportunidades/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('oportunidades/edit', [], OportunidadesForm)
    },
    {
        path: '/oportunidades/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('oportunidades/view', [], OportunidadesForm, { editable: false })
    },
];
export default routes;