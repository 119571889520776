<template>
    <f7-page :page-content="false">
      <!-- Top Navbar -->
      <!-- <f7-navbar :title="$t('clientes.clientes')" :back-link="$t('atras')"></f7-navbar> -->
      <!-- Page content-->
      <f7-page-content class="full-height">
        <r-dynamic-list
          ref="rlist"
          :dbAdapter="Cliente"
          :tableCode="'CLIENTESDUPLICADOS'"
          :tableMode="true"
          :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
          class="card full-height data-table"
          classContent="card-content full-height"
          :massiveActions="true"
          :controlsWidth="45"
          useView="clientes_nif_count_activos"
          :funcShowCheck="_ => {return true;}"
          :searchOnInit="true"
          :customLineStyle="colorearFilasMismoNif"
        >
          <template v-slot:tableLinks>
          </template>
          <template v-slot:massiveActions>
            <f7-button icon-f7="wand_stars" @click="seleccionEuriastica()" v-tooltip="'Deselección heurística (Dejamos el modificado más recientemente)'"></f7-button>
          </template>
          <template v-slot:optionsColumn="{item}">
            <!-- <f7-link icon-f7="eye" color="green" :href="`#!/clientes/view/${item.id}/`" v-tooltip="'Ver Cliente'"></f7-link> -->
            <f7-link external icon-f7="square_pencil" color="blue" :href="`#!/clientes/edit/${item.id}/`" v-tooltip="'Editar Cliente'"></f7-link>
          </template>
        </r-dynamic-list>
      </f7-page-content>
  
    </f7-page>
  </template>
  <style scoped>
  </style>
  <script>
  import rDynamicList from "../../../components/rDynamicList.vue";
  import Utils from "../../../js/Utils.js";
  import Cliente from "../../../js/db/Cliente.js";
  import rForm from "../../../components/rForm.vue";
  export default {
    components: {
      rDynamicList,
      rForm,
    },
    data() {
      return {
        Cliente,
        Utils,
      };
    },
    props: {
      esPopup: {
        type: Boolean,
        default: false
      },
      popupObject: {
        type: Object,
        default: () => {}
      },
    },
    computed: {},
    methods: {
      colorearFilasMismoNif: function(item) {
        const self = this;
        return "background-color:" + Utils.stringToColor(item.nif.replace('ES','').replace('B','')) + "40;";
      },
      seleccionEuriastica: function() {
        const self = this;
        let seleccionados = {};
        let nifsVisitados = new Set();
        // quiero ordenar los clientes por nif y modificationDate, ambos descendente, para quedarme con el más reciente de cada nif
        let clientesOrdenadosNifModif = Object.values(self.$refs.rlist.selected).sort((a, b) => {
          if (a.nif < b.nif) {
            return -1;
          } else if (a.nif > b.nif) {
            return 1;
          } else {
            if (a.modificationDate > b.modificationDate) {
              return -1;
            } else if (a.modificationDate < b.modificationDate) {
              return 1;
            } else {
              return 0;
            }
          }
        });

        // Iterar sobre cada tupla [key, valor] en self.$refs.rlist.selected
        for (const cliente of clientesOrdenadosNifModif) {
          // Verificar si el nif ya ha sido visitado
          if (nifsVisitados.has(cliente.nif)) {
            // Añadir a la lista de seleccionados
            seleccionados[cliente.id] = cliente;
          } else {
            // Añadir el nif al conjunto de nifs visitados
            nifsVisitados.add(cliente.nif);
          }
        }

        // Actualizar la lista de seleccionados
        self.$refs.rlist.selected = seleccionados;
      }
    },
    mounted: function() {
      var self = this;
      var app = self.$f7;
      /*app.dataTable.create({
        el: self.$refs.datatable
      });*/
    }
  };
  </script>