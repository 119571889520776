import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';
import Pedido from './Pedido.js';

var Inspeccion = Object.create(DatabaseModel);

Inspeccion.model = 'expediente';
Inspeccion.modelFront = 'inspecciones';

Inspeccion.getByCodigo = function(codigo) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/codigo/',
        method: 'POST',
        data: { codigo: codigo }
    };
    return self.sendRequest(params, false);
},

Inspeccion.downloadCertificado = function(item) {
    var self = this;
    return self.download(
        self.server_inspecciones + self.prefix + self.model + '/' + item.id + '/certificado',
        'Certificado-' + item.lineaPedido.coche.matricula + '.pdf');
}

Inspeccion.aceptarJustificantes = function(item) {
    return Inspeccion.confirmarJustificantes(item.id, true);
}

Inspeccion.rechazarJustificantes = function(id, mensaje) {
    return Inspeccion.confirmarJustificantes(id, false, mensaje.getValue());
}

Inspeccion.confirmarJustificantes = function(id, confirmado, mensaje) {
    var self = this;
    var data = {
        "confirmacion": confirmado ? 'OK' : 'KO',
    }
    if (mensaje) {
        data.mensaje = mensaje;
    }
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + id + '/confirmarJustificantes',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.asociarPedido = function(asociacion) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/asociarPedido',
        method: 'PUT',
        data: asociacion
    };
    return self.sendRequest(params, false).then(function(res) {
        self.resetCache();
        Pedido.resetCache();
        return res;
    });
}

Inspeccion.desasociar = function(expediente) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/desasociar',
        method: 'PUT',
        data: expediente
    };
    return self.sendRequest(params, false).then(function(res) {
        self.resetCache();
        Pedido.resetCache();
        return res;
    });
}

Inspeccion.descargarCertificado = function(inspeccion, beta = false) {
    var self = this;

    if(!!beta){
        return self.download(
            self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/informeBeta/CI',
            inspeccion.codigo.replaceAll('/','') + ' ' + (inspeccion.cliente && inspeccion.cliente.razonSocial ? inspeccion.cliente.razonSocial : "" ) + '_CI.pdf');
    } else {
        return self.download(
            self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/informe/CI',
            inspeccion.codigo.replaceAll('/','') + ' ' + (inspeccion.cliente && inspeccion.cliente.razonSocial ? inspeccion.cliente.razonSocial : "" ) + '_CI.pdf');
    }
}

Inspeccion.descargarHojaDatos = function(inspeccion, beta = false) {
    var self = this;
    let codigo = inspeccion.codigo?inspeccion.codigo.replaceAll('/',''):'';
    if(!!beta){
        return self.download(
            self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/informeBeta/HD',
            codigo + ' ' + (inspeccion.cliente && inspeccion.cliente.razonSocial ? inspeccion.cliente.razonSocial : "" ) + '_HD.pdf');
    } else {
        return self.download(
            self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/informe/HD',
            codigo + ' ' + (inspeccion.cliente && inspeccion.cliente.razonSocial ? inspeccion.cliente.razonSocial : "" ) + '_HD.pdf');
    }
}

Inspeccion.obtenerBase64 = function(id) {
    var self = this;

    return self.getBase64(
        self.server_movilidad + self.prefix + self.model + '/' + id + '/informe/CI');
}

Inspeccion.refreshEnvio = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/refreshEnvio/',
        method: 'GET'
    };
    return self.sendRequest(params, false);
};

Inspeccion.crearExpedienteSegundas = function(inspeccion) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/crearExpedienteSegundas',
        method: 'POST'
    };
    return self.sendRequest(params, false);

}
Inspeccion.arreglarDocumentos = function(id) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + id + '/arreglarDocumentos',
        method: 'PUT'
    };
    return self.sendRequest(params, false);

}

Inspeccion.versionar = function(inspeccion) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/versionar',
        method: 'POST'
    };
    return self.sendRequest(params, false);

}

Inspeccion.estadoEnviado = function(inspeccion) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/estadoEnviado',
        method: 'POST'
    };
    return self.sendRequest(params, false);

}

Inspeccion.crearExpedienteManual = function(inspeccion) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/crearExpedienteManual/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: inspeccion,
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.finalizar = function(id, data) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/' + id + '/finalizar',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.cambiaEstado = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/cambiaEstado',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false);
};

Inspeccion.firmar = function(inspeccion) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/firmar',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}


Inspeccion.adjuntarCertificado = function(params) {
    var self = this,
        params = params || {},
        firmado = params.firmado || false;
    if(params.data && params.data.length) {params.data[0].tipo = "CI";}
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/adjuntarCertificado/' + firmado,
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false);
};

Inspeccion.auditoriaManual = function(inspeccion, erroresAuditoria) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/' + inspeccion.id + '/auditoriaManual',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: erroresAuditoria,
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.probarInforme = function(codigoInspeccion, documentoInforme) {
    var self = this;
    var name = `${documentoInforme.nombre} - ${codigoInspeccion}.pdf`;
    var data = {
        codigoExpediente: codigoInspeccion,
        informePrueba: documentoInforme
    };
    return new Promise((resolve, reject) => {
        self.request({
            url: self.server_movilidad + self.prefix + self.model + '/probarInforme',
            method: 'POST',
            contentType: 'application/json',
            crossDomain: true,
            xhrFields: { 'responseType': 'arraybuffer' },
            data: data,
            success: function(data) {
                var blob = new Blob([data]);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                } else {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name;
                    link.target = '_blank';
                    link.click();
                    URL.revokeObjectURL(link.href);
                }
                resolve();
            },
            error: function(error) {
                reject(error);
            }
        });
    });
}

// Este servicio nos devolverá un objeto clave-valor con valor true o false, dependiendo si el expediente tiene o no servicios excluidos. El objeto que se le pasa como parámetro tiene la siguiente estructura:
// Le tenemos que pasar estos datos:
// data = {
//     private String codigoCliente;
//     private String codigoItem;
//     private String idServicio;
//     private Date fechaInsp;
//     private DireccionDTO direccionInspeccion;
// }
Inspeccion.hayServiciosExcluidos = function(data) {
    var self = this;
    return new Promise((resolve, reject) => {
        self.request({
            url: self.server_movilidad + self.prefix + self.model + '/hayServiciosExcluidos',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data,
            success: function(res) {
                let data = {};
                if(res.dto.valorObj == true) {
                    data.hayServiciosExcluidos = true;
                    data.textoAlerta = "Es necesario revisar si es posible realizar esta inspección, porque se ha detectado una inspección voluntaria aún en vigor. Póngase en contacto con Dirección Técnica."
                    resolve(data);
                } else {
                    data.hayServiciosExcluidos = false;
                    resolve(data);
                }
            },
            error: function(error) {
                reject(error);
            }
        });
    });
}

Inspeccion.syncOnline = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/sync/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Inspeccion.duplica = function(params){
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/duplica/',
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}


export default Inspeccion;