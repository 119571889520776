<template>
    <div class="item-content item-input item-label-up">
      <div class="item-inner">
        <div v-if="label" class="item-title item-label item-floating-label">{{label}}</div>
        <div class="sign-container" :class="classes">
          <img v-if="viewImage && isEmpty" class="sign" :class="classes" src="static/rSign/sign.svg"/>
          <VueSignaturePad
            height="200px"
            ref="signaturePad"
          ></VueSignaturePad>
          <f7-button
            small
            color="red"
            class="display-flex"
            icon-f7="arrow_counterclockwise"
            @click="reset()"></f7-button>
        </div>
      </div>
    </div>
</template>
<style>
.sign-container {
  min-width: 300px;
  min-height: 200px;
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-container.readonly {
  min-width: 302px;
  min-height: 202px;
  border: 0px;
}
img.sign {
  max-width: 100%;
  max-height: 100%;
}
img.sign.empty-sign {
  width: 100px;
  position: absolute;
  z-index: -1;
}
span.test {
  position: absolute;
  z-index: -1;
}
</style>
<script>

export default {
  components: {
  },
  data() {
    return {
      self: this,
      isMounted: false,
      updateCheck: null
    };
  },
  computed: {
    classes: function(){
      var self = this;
      if(!self.getValue()){
        return "empty-sign";
      }
    },
    editable: function(){
      var self = this;
      return self.overrideReadonly ? self.readonly : self.readonly || self.form.readonly;
    },
    isEmpty: function(){
      var self = this;
      return !self.isMounted || !self.$refs.signaturePad || self.$refs.signaturePad.isEmpty();
    },
    datos: function(){
      var self = this;
      return self.isEmpty ? "" : self.$refs.signaturePad.toData();
    }
  },
  watch:{
    datos: function(){
      var self = this;
      if(!self.updateCheck){
        self.updateCheck = setTimeout(function(){
          self.updateValue();
        },500);
      }
    }
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      return context;
    },
    setValue: function(val) {
      if (val != undefined && val === '') {
        val = undefined;
      }
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self);
          }
        }
        context = context[namespaces[i]];
      }
    },
    reset: function(){
      var self = this;
      self.setValue(null);
      self.$refs.signaturePad.clearSignature();
      self.$emit("reset");
    },
    updateValue: function(){
      var self = this;
      delete self.updateCheck;
      self.setValue(self.$refs.signaturePad.saveSignature().data);
      self.$emit("drawn");
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    viewImage: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.isMounted = true;
    self.$emit("mounted", self);
  }
};
</script>