import Formatos from './formatos.vue';
import FormatoForm from './formato-form.vue';
import FormatoAuditoria from './formato-auditoria.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/formatos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos', ['ADMIN', 'INSPECTOR'], Formatos),
        keepAlive: true
    },
    {
        path: '/formatos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/new', ['ADMIN', 'INSPECTOR'], FormatoForm)
    },
    {
        path: '/formatos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/edit', ['ADMIN', 'INSPECTOR'], FormatoForm),
        // keepAlive: true
    },
    {
        path: '/formatos/version/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/edit', ['ADMIN', 'INSPECTOR'], FormatoForm, {version: true})
    },
    {
        path: '/formatos/auditoria/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/auditoria', ['ADMIN', 'INSPECTOR'], FormatoAuditoria)
    },
    {
        path: '/formatos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('formatos/view', ['ADMIN', 'INSPECTOR'], FormatoForm, { editable: false })
    }
];
export default routes;