import RoutesUtils from '../../js/RoutesUtils.js';
import RegistroEmailsForm from './registroemail-form.vue';
import RegistroEmails from './registroemail.vue';

var routes = [{
        path: '/registroemail/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('registroemail', ['ADMIN', 'INSPECTOR'], RegistroEmails),
        keepAlive: true
    },
    {
        path: '/registroemail/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('registroemail/new', ['ADMIN', 'INSPECTOR'], RegistroEmailsForm)
    },
    {
        path: '/registroemail/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('registroemail/edit', ['ADMIN', 'INSPECTOR'], RegistroEmailsForm)
    },
    {
        path: '/registroemail/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('registroemail/view', ['ADMIN', 'INSPECTOR'], RegistroEmailsForm, { editable: false })
    },
    {
        path: '/registroemail/filter',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('registroemail/', ['ADMIN', 'INSPECTOR'], RegistroEmails, { searchOnInit: true }),
        keepAlive: false
    }
];
export default routes;