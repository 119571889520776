<template>
  <f7-row>
    <r-autocomplete
      class="col-25"
      ref="pais"
      :dbAdapter="Pais"
      floating-label
      :label="$t('componentes.cuenta.pais')"
      :form="form"
      :name="name + '.pais'"
      labelProp="nombre"
      idProp="codigo"
    ></r-autocomplete>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.nombreBanco')"
      :form="form"
      type="text"
      :name="name + '.nombreBanco'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.bic')"
      :form="form"
      type="text"
      :name="name + '.bic'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.nombreCuenta')"
      :form="form"
      type="text"
      :name="name + '.nombreCuenta'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.codigoBanco')"
      :form="form"
      type="text"
      :name="name + '.codigoBanco'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.sucursal')"
      :form="form"
      type="text"
      :name="name + '.sucursal'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.claveControl')"
      :form="form"
      type="text"
      :name="name + '.claveControl'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.cuenta')"
      :form="form"
      type="text"
      :name="name + '.cuenta'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.iban')"
      :form="form"
      type="text"
      :name="name + '.iban'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.idMandato')"
      :form="form"
      type="text"
      :name="name + '.idMandato'"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.cuenta.fechaFirma')"
      :form="form"
      type="datepicker"
      :name="name + '.fechaFirma'"
      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
    ></r-input>
    <r-input
      class="col-25"
      floating-label
      :form="form"
      type="hidden"
      :name="name + '.hidden'"
    ></r-input>
  </f7-row>

</template>
<style>
  div.list{
    list-style: none;
  }
  select[multiple] {
    height: 100px;
    margin-top: 10px; 
  }
</style>
<script>
import rInput from "./rInput.vue";
import rAutocomplete from "./rAutocomplete.vue";
import Pais from "./../js/db/Pais.js";
export default {
  name: "r-cuenta",
  components: {
    rInput,
    rAutocomplete
  },
  data() {
    return {
      self: this,
      Pais
    };
  },
  methods: {},
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>