var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-popup',{ref:"popupEnviarEmail",staticClass:"popup-enviar-email",attrs:{"opened":true,"swipe-to-close":""},on:{"popup:opened":_vm.onPopupOpened,"popup:closed":_vm.onPopupClosed}},[_c('f7-page',[_c('f7-navbar',[(_vm.$refs.rformEnviarEmail && _vm.$refs.rformEnviarEmail.formData && _vm.$refs.rformEnviarEmail.formData.from)?_c('f7-nav-left',[_c('f7-link',{on:{"click":function($event){return _vm.$refs.rformEnviarEmail.saveItem()}}},[_vm._v("Enviar")])],1):_vm._e(),_vm._v(" "),_c('f7-nav-title',[_vm._v("Enviar Email")]),_vm._v(" "),_c('f7-nav-right',[_c('f7-link',{attrs:{"popup-close":""}},[_vm._v("Cerrar")])],1)],1),_vm._v(" "),_c('r-form',{ref:"rformEnviarEmail",attrs:{"dbAdapter":null,"classContent":"full-height","readonly":false,"hidePreloaderOnSave":false,"defaultData":{ enlazarAdjuntos: true }},on:{"save":_vm.onSaveEnviarEmail},scopedSlots:_vm._u([{key:"default",fn:function({ rform }){return [_c('f7-popover',{ref:"popover",staticStyle:{"width":"250px"},attrs:{"closeByBackdropClick":false,"backdrop":true,"closeOnEscape":""}},[_c('f7-navbar',{attrs:{"title":_vm.$t('Opciones disponibles')}},[_c('f7-nav-right',[_c('f7-link',{attrs:{"popover-close":"","icon-f7":"multiply"}})],1)],1),_vm._v(" "),_c('f7-list',{attrs:{"no-hairlines-md":""}},[_c('ul',_vm._l((_vm.opciones),function(opcion,index){return _c('li',{key:'select.opcion-' + index,staticClass:"popover_option",attrs:{"value":opcion.value},on:{"click":function($event){opcion.action ? _vm.ejecutar(opcion.action, opcion.params) : _vm.setOptionClicked(index)}}},[_vm._v(_vm._s(opcion.name))])}),0)])],1),_vm._v(" "),_c('f7-card',[_c('f7-card-content',[_c('f7-list',[_c('ul',[_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('div',{staticStyle:{"padding":"4px 10px"},on:{"contextmenu":function($event){$event.preventDefault();_vm.showOptions(
                  {
                      opciones: [
                          { name: 'Datos del usuario', value: '{{$.empleadoActual.email}}', valueName: '{{$.empleadoActual.nombre}}' },
                          { name: 'Datos del Gestor', value: '{{$.empleadoGestor.email}}', valueName: '{{$.empleadoGestor.nombre}}' },
                          { name: 'Entrada manual', value: '', action: 'entradaManualCampo', params: [rform.formData, ['fromName', 'from']] },
                      ],
                      callback: (selected) => { _vm.$set(rform.formData, 'fromName', { name: selected.name, value: selected.valueName }); _vm.$set(rform.formData, 'from', { name: selected.name, value: selected.value }); }
                  },
                  $event,
                  true
              )}}},[_c('f7-block-title',[_vm._v("Remitente")]),_vm._v(" "),_c('div',{staticClass:"block-chips"},[(rform.formData.from && rform.formData.fromName)?_c('f7-chip',{attrs:{"text":rform.formData.fromName.name,"tooltip":rform.formData.fromName.value + ' - ' + rform.formData.from.value,"deleteable":""},on:{"delete":function($event){rform.formData.from = undefined; rform.formData.fromName = undefined}}}):_vm._e()],1)],1)]),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('div',{staticStyle:{"padding":"4px 10px"},on:{"contextmenu":function($event){$event.preventDefault();_vm.showOptions(
                  {
                      opciones: [
                          { name: 'e-mail del contacto por defecto del cliente', value: '{{$.cliente.contactoDefectoDTO.canalesComunicacion[?(@.tipo == \'EMAIL\')].valor}}' },
                          { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'to'] },
                      ],
                      callback: (selected) => {
                          if (!rform.formData.to) {
                              _vm.$set(rform.formData, 'to', []);
                          }
                          rform.formData.to.push(selected);
                      }
                  },
                  $event,
                  true
              )}}},[_c('f7-block-title',[_vm._v("Destinatarios")]),_vm._v(" "),_c('div',{staticClass:"block-chips"},_vm._l((rform.formData.to),function(chip,index){return _c('f7-chip',{key:'chip-to-' + index,attrs:{"text":chip.name,"deleteable":""},on:{"delete":function($event){return _vm.deleteChip($event, rform.formData.to, index)}}})}),1)],1)]),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('div',{staticStyle:{"padding":"4px 10px"},on:{"contextmenu":function($event){$event.preventDefault();_vm.showOptions(
                  {
                      opciones: [
                          { name: 'e-mail de tu usuario', value: '{{$.empleadoActual.email}}' },
                          { name: 'e-Mail del Gestor', value: '{{$.empleadoGestor.email}}' },
                          { name: 'e-mail del contacto por defecto del cliente', value: '{{$.cliente.contactoDefectoDTO.canalesComunicacion[?(@.tipo == \'EMAIL\')].valor}}' },
                          { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'cc'] },
                      ],
                      callback: (selected) => {
                          if (!rform.formData.cc) {
                              _vm.$set(rform.formData, 'cc', []);
                          }
                          rform.formData.cc.push(selected);
                      }
                  },
                  $event,
                  true
              )}}},[_c('f7-block-title',[_vm._v("En copia")]),_vm._v(" "),_c('div',{staticClass:"block-chips"},_vm._l((rform.formData.cc),function(chip,index){return _c('f7-chip',{key:'chip-cc-' + index,attrs:{"text":chip.name,"deleteable":""},on:{"delete":function($event){return _vm.deleteChip($event, rform.formData.cc, index)}}})}),1)],1)]),_vm._v(" "),_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('div',{staticStyle:{"padding":"4px 10px"},on:{"contextmenu":function($event){$event.preventDefault();_vm.showOptions(
                  {
                      opciones: [
                          { name: 'e-mail del usuario', value: '{{$.empleadoActual.email}}' },
                          { name: 'e-Mail del Gestor', value: '{{$.empleadoGestor.email}}' },
                          { name: 'Entrada manual', value: '', action: 'entradaManualLista', params: [rform.formData, 'cco'] },
                      ],
                      callback: (selected) => {
                          if (!rform.formData.cco) {
                              _vm.$set(rform.formData, 'cco', []);
                          }
                          rform.formData.cco.push(selected);
                      }
                  },
                  $event,
                  true
              )}}},[_c('f7-block-title',[_vm._v("En copia oculta")]),_vm._v(" "),_c('div',{staticClass:"block-chips"},_vm._l((rform.formData.cco),function(chip,index){return _c('f7-chip',{key:'chip-cco-' + index,attrs:{"text":chip.name,"deleteable":""},on:{"delete":function($event){return _vm.deleteChip($event, rform.formData.cco, index)}}})}),1)],1)])],1)],1)])],1)],1),_vm._v(" "),_c('f7-card',{staticClass:"elevation-3 padding-vertical-half padding-right"},[_c('f7-card-content',[_c('f7-list',{staticClass:"list-form"},[_c('ul',[_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('r-input',{staticClass:"col-100 medium-25",attrs:{"floating-label":"","label":_vm.$t('oportunidad.plantillaEmail'),"form":rform,"type":"select","name":"plantillaEmail","clear-button":false},on:{"change":_vm.chargePlantillaEmail}},[_c('option',{attrs:{"value":"SIN"}},[_vm._v("Sin plantilla")]),_vm._v(" "),_vm._l((_vm.plantillas),function(plantilla){return _c('option',{key:plantilla.path,domProps:{"value":plantilla.path}},[_vm._v(_vm._s(plantilla.nombre))])})],2),_vm._v(" "),_c('div',{staticStyle:{"padding":"4px 10px"},on:{"contextmenu":function($event){$event.preventDefault();_vm.showOptions(
                  {
                      opciones: [
                          { name: 'Presentación de la empresa', value: 'Propuesta de prestación de servicios' },
                      ],
                      callback: (selected) => {
                          _vm.$set(rform.formData, 'asunto', selected.value);
                      }
                  },
                  $event,
                  true
              )}}},[_c('r-input',{staticClass:"col-100",attrs:{"floating-label":"","outline":"","label":"Asunto","form":rform,"name":"asunto"}})],1),_vm._v(" "),(rform.formData.plantillaEmail)?_c('r-rich-text-input',{ref:"rRichTextInput",staticClass:"col-100",staticStyle:{"overflow":"hidden"},attrs:{"floating-label":"","label":"Cuerpo del email","form":rform,"name":'cuerpo'}}):_vm._e()],1)],1)],1)])],1)],1),_vm._v(" "),_c('f7-card',[_c('f7-card-content',[_c('f7-list',[_c('ul',[_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('r-files',{staticClass:"col-100",attrs:{"floating-label":"","outline":"","label":"Adjuntar archivos","form":rform,"name":"attachments"}})],1)],1)],1),_vm._v(" "),_c('ul',[_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('r-input',{attrs:{"floating-label":"","outline":"","label":_vm.$t('Enviar como enlaces'),"form":rform,"simple":true,"type":"check","name":"enlazarAdjuntos","readonly":false,"override-readonly":true}})],1)],1)],1)])],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('f7-button',{staticClass:"display-flex margin-vertical margin-left",attrs:{"fill":"","icon-f7":"disk","text":"Enviar","color":"green"},on:{"click":function($event){return rform.saveItem()}}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }