import Componentes from './componentesproteccion.vue';
import ComponenteForm from './componenteproteccion-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/componentesproteccion/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias', ['ADMIN', 'INSPECTOR'], Componentes),
        keepAlive: true
    },
    {
        path: '/componentesproteccion/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/new', ['ADMIN', 'INSPECTOR'], ComponenteForm)
    },
    {
        path: '/componentesproteccion/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/edit', ['ADMIN', 'INSPECTOR'], ComponenteForm)
    },
    {
        path: '/componentesproteccion/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('materias/view', ['ADMIN', 'INSPECTOR'], ComponenteForm, { editable: false })
    },
];
export default routes;