import Combos from './combos.vue';
import ComboForm from './combo-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/combos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('combos', ['ADMIN', 'INSPECTOR'], Combos),
        keepAlive: true
    },
    {
        path: '/combos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('combos/new', ['ADMIN', 'INSPECTOR'], ComboForm)
    },
    {
        path: '/combos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('combos/edit', ['ADMIN', 'INSPECTOR'], ComboForm)
    },
    {
        path: '/combos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('combos/view', ['ADMIN', 'INSPECTOR'], ComboForm, { editable: false })
    },
];
export default routes;