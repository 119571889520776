import Calendario from './calendario.vue';
import RoutesUtils from './../../js/RoutesUtils.js';


var routes = [

    {
        path: '/calendario/:username/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('calendario', ['ADMIN', 'INSPECTOR'], Calendario),
        keepAlive: false
    },
    {
        path: '/calendario/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('calendario', ['ADMIN', 'INSPECTOR'], Calendario),
        keepAlive: false
    }

];
export default routes;
