<template>
  <f7-page :page-content="true">
    <r-form
      ref="rform"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Oportunidad"
      :defaultData="defaultData"
      :documentoAdapter="Documento"
    >
    <template v-slot:before="{ rform }">
      <f7-navbar>
        <f7-nav-left>
          <f7-link v-if="esPopup" @click.stop="$emit('close')">{{ $t('common.cerrar') }}</f7-link>
          <f7-link v-else @click.stop="$f7router.back()">&lt; {{ $t('atras') }}</f7-link>
        </f7-nav-left>
        <f7-nav-title v-if="id && !readonly" sliding>{{ $t('oportunidad.editar') + ' ' + $t('oportunidad.oportunidad') }}</f7-nav-title>
        <f7-nav-title v-else-if="id" sliding>{{ $t('oportunidad.oportunidad')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{ $t('oportunidad.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <template
              slot="right"
              v-else
            >
              <div class="menu-item menu-item-dropdown bg-color-primary" >
                <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
                <div class="menu-dropdown menu-dropdown-right bg-color-primary" style="z-index:10">
                  <div class="menu-dropdown-content bg-color-primary text-align-right">
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData && rform.formData.id && (!rform.formData.estado || !rform.formData.estado.estado || rform.formData.estado.estado != 'PERDIDA')"
                      @click="generarOferta">{{ $t('Generar oferta') }}
                    </div>
                    <div class="menu-dropdown-divider"
                      v-if="readonly && rform && rform.formData && rform.formData.id && (!rform.formData.estado || !rform.formData.estado.estado || rform.formData.estado.estado != 'PERDIDA')"
                    ></div>
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData && rform.formData.id && (!rform.formData.estado || !rform.formData.estado.estado || rform.formData.estado.estado != 'PERDIDA')"
                      @click="marcarPerdida">{{ $t('Marcar como Perdida') }}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData && rform.formData.id"
                      @click="abrirPopupEnviarEmail()">{{ $t('Enviar e-mail') }}
                    </div>
                    <div class="menu-dropdown-divider"
                      v-if="readonly && rform && rform.formData && rform.formData.id && (!rform.formData.estado || !rform.formData.estado.estado || rform.formData.estado.estado != 'PERDIDA')"
                    ></div>
                    <div class="menu-dropdown-item menu-close pointer" 
                      v-if="readonly && rform && rform.formData"
                      @click="readonly = false">{{ $t('Editar') }}
                    </div>
                    <div class="menu-dropdown-item menu-close pointer"
                      v-if="readonly && rform && rform.formData && rform.formData.estado && rform.formData.estado.estado == 'CREADA'"
                      @click="deleteItem">{{ $t('Eliminar') }}
                    </div>
                  </div>
                </div>
              </div>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <template v-slot:default="{ rform }">
      <f7-card style="z-index: 10;" class="padding-vertical-half padding-right" >
        <f7-button
          class="col-20"
          v-noroles="['ENAC', 'PRODUCCION']"
          icon-f7="calendar_circle"
          @click="popupTareaEditable = true; popupTareasOpened = true"
          v-if="rform && rform.formData && rform.formData.id"
        >Crear tarea</f7-button>
        <div name="Datos Generales" style="display: flex; flex-direction: row; justify-content: end;">
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Datos Generales' : 'Mostrar Datos Generales'"
            :icon-f7="showDetalle ? 'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Datos Generales' : 'Mostrar Datos Generales'"
            style="padding-left: 7px;font-size: 10px;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <div class="label-group margin-bottom col-100">{{ $t('Datos oportunidad') }}</div>
          <f7-row no-gap class="list list-form no-hairlines margin-bottom" style="z-index: 10;">
            <!-- <f7-col width="100" :medium="esPopup ? '100' : '50'">
              <ul> -->
                <r-input
                  class="col-100 medium-33"
                  outline
                  floating-label
                  :label="$t('oportunidad.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  v-if="readonly"
                ></r-input>
                <r-autocomplete
                  outline
                  :dbAdapter="Empleado"
                  key="gestor"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('oportunidad.gestor')"
                  :form="rform"
                  name="gestor"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                  :showNavigationLink="false"
                  :validation="{ isValid: str => { return !validator.isEmpty(str, { ignore_whitespace: true }) }, errorMessage: '* El responsable es obligatorio'}"
                ></r-autocomplete>
                <r-input
                  class="col-100 medium-33"
                  v-if="readonly && rform && rform.formData && rform.formData.estado && rform.formData.estado.estado"
                  floating-label
                  :label="$t('oportunidad.estado')"
                  :form="rform"
                  type="badge"
                  name="estado.estado"
                  :readonly="true"
                  :color="getColorBadgeEstado(rform.formData.estado.estado)"
                  :text="getTextBadgeEstado(rform.formData.estado.estado)"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Campania"
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('campania.campania')"
                  :form="rform"
                  name="campania"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['codigoNombre', 'codigo', 'nombre', 'estrategia', 'fechaInicial', 'fechaFinal', 'responsable']"
                  :async="true"
                ></r-autocomplete>
                <r-input
                  v-if="readonly"
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('campania.estrategia')"
                  :form="rform"
                  name="campania.estrategia"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
              <!-- </ul>
            </f7-col> -->
          </f7-row>
          <div v-if="rform.formData.id && rform.formData.cliente && rform.formData.cliente.id" class="label-group margin-bottom margin-top col-100">
            {{ $t('Datos cliente') }}
            <f7-link
              external
              v-tooltip="'Navegar a cliente ' + (rform.formData.cliente.codigo)"
              class="color-green"
              icon-f7="arrow_up_right_square"
              :href="'#!/clientes/view/' + rform.formData.cliente.id + '/'"
              :key="'cliente-navigate-' + rform.formData.cliente.id"
            ></f7-link>
          </div>
          <f7-row v-if="rform.formData.id && rform.formData.cliente && rform.formData.cliente.id" no-gap class="list list-form no-hairlines">
            <!-- <f7-col width="100" :medium="esPopup ? '100' : '50'">
              <ul> -->
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.codigo')"
                  :form="rform"
                  type="text"
                  name="cliente.codigo"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.razonSocial')"
                  :form="rform"
                  type="text"
                  name="cliente.razonSocial"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.tipoCliente')"
                  :form="rform"
                  type="select"
                  name="cliente.tipoCliente"
                  :readonly="true"
                  :overrideReadonly="true"
                >
                  <option value="D">Directo</option>
                  <option value="I">Indirecto</option>
                </r-input>
                <r-autocomplete
                  :dbAdapter="GrupoCliente"
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.grupocliente')"
                  :form="rform"
                  name="cliente.grupoCliente"
                  labelProp="nombre"
                  idProp="codigo"
                  idFieldDetail="id"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-autocomplete>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.responsable')"
                  :form="rform"
                  type="text"
                  name="cliente.responsable"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.empleadoVentas')"
                  :form="rform"
                  type="text"
                  name="cliente.empleadoVentas"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.poblacion')"
                  :form="rform"
                  type="text"
                  name="cliente.direccionDefectoFacturasDTO.poblacion"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  outline
                  floating-label
                  :label="$t('clientes.provincia')"
                  :form="rform"
                  type="text"
                  name="cliente.direccionDefectoFacturasDTO.provincia"
                  :readonly="true"
                  :overrideReadonly="true"
                ></r-input>
              <!-- </ul>
            </f7-col> -->
          </f7-row>
        </f7-card-content>
      </f7-card>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-documentos-origen" tab-link-active @click="tabActive = 'documentos-origen'; loadData()">{{ $t('oportunidad.documentosOrigen') }}</f7-button>
          <f7-button fill tab-link="#tab-documentos" @click="tabActive = 'documentos'; loadData()">{{ $t('oportunidad.documentos') }}</f7-button>
          <f7-button v-noroles="['ENAC', 'PRODUCCION']"  @click="tabActive = 'tareas'; loadData()" fill tab-link="#tab-tareas">{{ $t("Tareas") }}</f7-button>
          <f7-button @click="tabActive = 'emails'; loadData()" fill tab-link="#tab-emails">{{ $t("e-Mails") }}</f7-button>
          <f7-button @click="tabActive = 'ofertas'; loadData()" fill tab-link="#tab-ofertas">{{ $t("Ofertas") }}</f7-button>
          <f7-button @click="tabActive = 'pedidos'; loadData()" fill tab-link="#tab-pedidos">{{ $t("Pedidos") }}</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-documentos-origen" tab-active>
          <template v-if="tabActive == 'documentos-origen'">
            <f7-block-title>
              {{ $t('oportunidad.documentosOrigen') }}
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col v-if="(rform.formData.cliente && rform.formData.cliente.id) || (rform.formData.oferta && rform.formData.oferta.id) || (rform.formData.lineasPedido && rform.formData.lineasPedido.length) || (rform.formData.pedido && rform.formData.pedido.id)" width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-if="rform.formData.cliente"
                >
                  <f7-block-title>
                    {{ $t('oportunidad.cliente') }}
                  </f7-block-title>
                  <f7-card-content>
                    {{ rform.formData.cliente.codigo }} - {{ rform.formData.cliente.razonSocial }} - Potencial: {{ rform.formData.cliente.potencial ? 'SI' : 'NO' }}
                  </f7-card-content>
                  <f7-link
                    external
                    v-tooltip="'Navegar a cliente ' + (rform.formData.cliente.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/clientes/view/' + rform.formData.cliente.id + '/'"
                    :key="'cliente-navigate-' + rform.formData.cliente.id"
                  ></f7-link>
                </f7-card>
                <f7-card
                  v-if="rform.formData.oferta"
                >
                  <f7-block-title>
                    {{ $t('oportunidad.oferta') }}
                  </f7-block-title>
                  <f7-card-content>
                    {{ (rform.formData.oferta.numero) }} - {{ rform.formData.oferta.codigo }}
                  </f7-card-content>
                  <f7-link
                    external
                    v-tooltip="'Navegar a oferta ' + (rform.formData.oferta.numero || rform.formData.oferta.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/ofertas/view/' + rform.formData.oferta.id + '/'"
                    :key="'oferta-navigate-' + rform.formData.oferta.id"
                  ></f7-link>
                </f7-card>
                <f7-card
                  v-if="rform.formData.lineasPedido && rform.formData.lineasPedido.length && rform.formData.pedido"
                >
                  <f7-block-title>
                    {{ $t('Líneas de pedido') }}
                  </f7-block-title>
                  <f7-card-content>
                    Líneas {{ (rform.formData.lineasPedido.map(l => l.numero).join(', ')) }} del pedido {{ rform.formData.pedido.numero }}
                  </f7-card-content>
                  <f7-link
                    external
                    v-tooltip="'Navegar a pedido ' + (rform.formData.pedido.numero || rform.formData.pedido.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/pedidos/view/' + rform.formData.pedido.id + '/'"
                    :key="'linea-pedido-navigate-' + rform.formData.pedido.id"
                  ></f7-link>
                </f7-card>
                <f7-card
                  v-else-if="rform.formData.pedido"
                >
                  <f7-block-title>
                    {{ $t('oportunidad.pedido') }}
                  </f7-block-title>
                  <f7-card-content>
                    {{ (rform.formData.pedido.numero) }} - {{ rform.formData.pedido.codigo }}
                  </f7-card-content>
                  <f7-link
                    external
                    v-tooltip="'Navegar a pedido ' + (rform.formData.pedido.numero || rform.formData.pedido.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/pedidos/view/' + rform.formData.pedido.id + '/'"
                    :key="'pedido-navigate-' + rform.formData.pedido.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
              <f7-col v-else width="100" :medium="esPopup ? '100' : '50'">
                <f7-card>
                  <f7-row>
                    <r-autocomplete
                      :dbAdapter="Cliente"
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('oportunidad.cliente')"
                      :form="rform"
                      name="clienteAux"
                      labelProp="codigoNombre"
                      :minLength="3"
                      :shownFields="['razonSocial', 'condicionesPago', 'direcciones', 'direccionDefectoFacturas', 'direccionDefectoEnvio', 'condPago', 'viaPagoDefecto', 'listaPrecios', 'contactos', 'grupoCliente', 'empleadoVentas', 'responsable', 'emailsFacturas', 'emailsCertificados', 'cuenta', 'activo', 'codigo']"
                      :async="true"
                      :filter="{ filter: { activo: true } }"
                    >
                    </r-autocomplete>
                    <f7-button :disabled="!rform.formData.clienteAux" fill @click="cargarCliente" style="margin-top: 10px; margin-right: 10px">{{ $t('oportunidad.cargarCliente') }}</f7-button>
                  </f7-row>
                </f7-card>
                <f7-card>
                  <f7-row>
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('oportunidad.numOferta')"
                      :form="rform"
                      name="ofertaAux"
                    >
                    </r-input>
                    <f7-button :disabled="!rform.formData.ofertaAux" fill @click="cargarOferta" style="margin-top: 10px; margin-right: 10px">{{ $t('oportunidad.cargarOferta') }}</f7-button>
                  </f7-row>
                </f7-card>
                <f7-card>
                  <f7-row>
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('oportunidad.numPedido')"
                      :form="rform"
                      name="pedidoAux"
                    >
                    </r-input>
                    <f7-button :disabled="!rform.formData.pedidoAux" fill @click="cargarPedido" style="margin-top: 10px; margin-right: 10px">{{ $t('oportunidad.cargarPedido') }}</f7-button>
                  </f7-row>
                </f7-card>
                <f7-card>
                  <f7-row>
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('oportunidad.numPedido')"
                      :form="rform"
                      name="pedidoAux"
                    >
                    </r-input>
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :label="$t('oportunidad.numLinea')"
                      :form="rform"
                      name="lineaAux"
                    >
                    </r-input>
                    <f7-button :disabled="!rform.formData.pedidoAux || rform.formData.lineaAux == undefined" fill @click="cargarLinea" style="margin-top: 10px; margin-right: 10px">{{ $t('oportunidad.cargarLineaPedido') }}</f7-button>
                  </f7-row>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="!rform.formData.documentos || (rform.formData.documentos && !rform.formData.documentos.length > 0)">La oportunidad no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-tareas" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.id">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Tarea"
            :tableCode="'TAREASOPORTUNIDAD'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['codigoOportunidad']"
            :mandatoryFilter ="{ 'codigoOportunidad': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-emails" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILTABS'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['to','datos']"
            :mandatoryFilter ="{ 'datos##codigoOportunidad': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupEmailEditable = false; emailId = item.id; popupEmailOpened = true"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
        <f7-tab id="tab-ofertas">
          <template v-if="tabActive == 'ofertas'">
            <f7-block-title>
              {{ $t('campania.ofertas') }}
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(oferta) in ofertas" :key="'oferta-' + oferta.id"
                >
                  <f7-card-content>
                    <r-input
                      class="col-100 medium-20"
                      style="display: inline-block;"
                      type="badge"
                      name="estado"
                      :readonly="true"
                      :overrideReadonly="true"
                      :text="getTextoBadgeEstadoOferta(oferta.estado.estado)"
                      :color="getColorBadgeEstadoOferta(oferta.estado.estado)"
                    ></r-input>
                    {{ oferta.numero }} - {{ oferta.codigo }} - {{ oferta.cliente.razonSocial }} {{ oferta.contratacion && oferta.contratacion.codEmpleado ? ' - ' + oferta.contratacion.codEmpleado : ''}} - {{ DateUtils.dateFormat( oferta.fechaOferta, 'DD/MM/YYYY') }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(oferta.id)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a oferta ' + (oferta.numero || oferta.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/ofertas/view/' + oferta.id + '/'"
                    :key="'oferta-navigate-' + oferta.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
        <f7-tab id="tab-pedidos">
          <template v-if="tabActive == 'pedidos'">
            <f7-block-title>
              {{ $t('campania.pedidos') }}
            </f7-block-title>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" :medium="esPopup ? '100' : '50'">
                <f7-card
                  v-for="(pedido) in pedidos" :key="'pedido-' + pedido.id"
                >
                  <f7-card-content>
                    <r-input
                      class="col-100 medium-20"
                      style="display: inline-block;"
                      type="badge"
                      name="activo"
                      :readonly="true"
                      :overrideReadonly="true"
                      :text="pedido.activo ? 'ACTIVO': 'NO ACTIVO'"
                      :color="pedido.activo ? 'green': 'red'"
                    ></r-input>
                    {{ pedido.numero }} - {{ pedido.codigo }} - {{ pedido.cliente.razonSocial }}
                  </f7-card-content>
                  <f7-link
                    v-tooltip="'Eliminar'"
                    v-if="!readonly"
                    @click="deleteCargado(pedido.id)"
                    class="margin-half link-inline link-inline-0"
                    icon-f7="trash"
                    color="red"
                  ></f7-link>
                  <f7-link
                    external
                    v-tooltip="'Navegar a pedido ' + (pedido.numero || pedido.codigo)"
                    class="margin-half link-inline link-inline-1 color-green"
                    icon-f7="arrow_up_right_square"
                    :href="'#!/pedidos/view/' + pedido.id + '/'"
                    :key="'pedido-navigate-' + pedido.id"
                  ></f7-link>
                </f7-card>
              </f7-col>
            </f7-row>
          </template>
        </f7-tab>
      </f7-tabs>
    </template>
    </r-form>
    <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
      <tarea-form :id="tareaId" :editable="popupTareaEditable" @close="$refs.popupTarea.close();"  
        :defaultData="{ codigoOportunidad: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
      ></tarea-form>
    </f7-popup>
    <f7-popup ref="popupEmail" swipe-to-close :opened="true" v-if="popupEmailOpened" @popup:closed="popupEmailOpened = false; emailId = null">
      <registro-email-form :id="emailId" :editable="popupEmailEditable" @close="$refs.popupEmail.close();"  
        :defaultData="{ codigoOportunidad: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
      ></registro-email-form>
    </f7-popup>

    <envio-mail
      v-if="enviarEmail && $refs.rform && $refs.rform.formData && $refs.rform.formData.id"
      ref="enviarEmail"
      :selected="oportunidadesSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>

  </f7-page>
</template>
<style scoped>

  .link-externo {
    margin-top: 20px;
  }
  .label-group {
    /* margin-bottom: 12px; */
    /* margin-top: 12px; */
    margin-left: 20px;
    font-weight: bold;
  }
  .margin-bottom {
    margin-bottom: 12px;
  }
  .margin-top {
    margin-top: 12px;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .tab-link-active {
    background-color: var(--f7-theme-color-shade) !important;
  }

  .link-inline {
    position: absolute;
    top: 0;
  }

  .link-inline-0 {
    right: 0;
  }

  .link-inline-1 {
    right: 25px;
  }

  .link-inline-2 {
    right: 50px;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "./components/envio-mail.vue";
import Oportunidad from "./../../js/db/Oportunidad.js";
import Campania from "./../../js/db/Campania.js";
import Empleado from "./../../js/db/Empleado.js";
import GrupoCliente from "./../../js/db/GrupoCliente.js";
import rDireccion from "./../../components/rDireccion.vue";
import validator from "validator";
import rTimepicker from "./../../components/rTimepicker.vue";
import Documento from "./../../js/db/Documento.js";
import Tarea from "./../../js/db/Tarea.js";
import TareaForm from "./../tareas/tarea-form.vue";
import RegistroEmail from "../../js/db/RegistroEmail.js";
import RegistroEmailForm from "./../registroemail/registroemail-form.vue";
import Oferta from "./../../js/db/Oferta.js";
import Pedido from "./../../js/db/Pedido.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import Cliente from "./../../js/db/Cliente.js";
import DateUtils from "../../js/DateUtils";

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    rDynamicList,
    EnvioMail,
    rAutocomplete,
    rDireccion,
    rTimepicker,
    TareaForm,
    RegistroEmailForm
  },
  data: function() {
    var self = this;
    return {
      Oportunidad,
      Tarea,
      RegistroEmail,
      Campania,
      Empleado,
      Cliente,
      LineaPedido,
      GrupoCliente,
      validator,
      readonly: !this.editable,
      popupPlanificarOpened: false, 
      Documento,
      DateUtils,
      popupTareasOpened: false,
      tareaId: null,
      popupTareaEditable: false,
      popupEmailOpened: false,
      emailId: null,
      popupEmailEditable: false,
      enviarEmail: false,
      tabActive: 'documentos-origen',
      ofertas: [],
      pedidos: [],
      showDetalle: true,
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    defaultData: {
      type: Object
    },
    esPopup: {
      type: Boolean,
      default: false
    }
  },

  computed: {},
  beforeDestroy() {},
  methods: {
    cargarLinea: function () {
      const self = this;
      if (self.$refs.rform.formData.pedidoAux && self.$refs.rform.formData.lineaAux != undefined) {
        app.preloader.show();
        LineaPedido.getList({
          filter: {
            numeroPedido: self.$refs.rform.formData.pedidoAux, numeroLinea: self.$refs.rform.formData.lineaAux, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,cliente,numero,estado,idOferta,codOferta,numeroOferta,lineas.estado.estado,lineas.numero'
        })
          .then(res => {
            if (!res.list[0]) {
              app.dialog.alert("Línea de pedido no encontrada");
            } else {
              let lineaPedido = res.list[0];
              if (!self.$refs.rform.formData.lineasPedido || !self.$refs.rform.formData.lineasPedido.length) {
                self.$set(self.$refs.rform.formData, 'lineasPedido', []);
              }
              self.$refs.rform.formData.lineasPedido.push(lineaPedido.lineas);
              // self.$set(self.$refs.rform.formData, 'lineaPedido', lineaPedido.lineas);
              self.$set(self.$refs.rform.formData, 'pedido', { id: lineaPedido.id, codigo: lineaPedido.codigo, numero: lineaPedido.numero });
              self.$set(self.$refs.rform.formData, 'oferta', { id: lineaPedido.idOferta, codigo: lineaPedido.codOferta, numero: lineaPedido.numeroOferta });
              self.$set(self.$refs.rform.formData, 'cliente', lineaPedido.cliente);
            }
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      } 
    },  
    cargarPedido: function () {
      const self = this;
      if (self.$refs.rform.formData.pedidoAux) {
        app.preloader.show();
        Pedido.getList({
          filter: {
            numero: self.$refs.rform.formData.pedidoAux, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,cliente,numero,estado,idOferta,codOferta,numeroOferta'
        })
          .then(res => {
            if (!res.list[0]) {
              app.dialog.alert("Pedido no encontrada");
            } else {
              let pedido = res.list[0];
              self.$set(self.$refs.rform.formData, 'pedido', pedido);
              self.$set(self.$refs.rform.formData, 'oferta', { id: pedido.idOferta, codigo: pedido.codOferta, numero: pedido.numeroOferta });
              self.$set(self.$refs.rform.formData, 'cliente', pedido.cliente);
            }
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      } 
    },  
    cargarOferta: function () {
      const self = this;
      if (self.$refs.rform.formData.ofertaAux) {
        app.preloader.show();
        Oferta.getList({
          filter: {
            numeroSAP: self.$refs.rform.formData.ofertaAux, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,cliente,numero,contratacion,fechaOferta,estado'
        })
          .then(res => {
            if (!res.list[0]) {
              app.dialog.alert("Oferta no encontrada");
            } else {
              self.$set(self.$refs.rform.formData, 'oferta', res.list[0]);
              self.$set(self.$refs.rform.formData, 'cliente', res.list[0].cliente);
            }
          })
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      } 
    },  
    cargarCliente: function () {
      const self = this;
      if (self.$refs.rform.formData.clienteAux && self.$refs.rform.formData.clienteAux.id) {
        app.preloader.show();
        Cliente.get({ itemId: self.$refs.rform.formData.clienteAux.id }).then(res => {
          console.log("Carga cliente", res);
          self.$set(self.$refs.rform.formData, 'cliente', res);
        }).catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
        }).then(function () {
          app.preloader.hide();
        });
      } 
    },
    getColorBadgeEstadoOferta: function (estado) {

      if (!estado) {
        return "gray";
      }

      if (estado == 'ABIERTA') {
        return "blue";
      }
      else if (estado == 'TERMINADA') {
        return "purple";
      }
      else if (estado == 'APROBADA') {
        return "lime";
      }
      else if (estado == 'ENVIADA') {
        return "green";
      }
      else if (estado == 'ACEPTADA') {
        return "teal";
      }
      else if (estado == 'RECHAZADA') {
        return "red";
      }
      else if (estado == 'CADUCADA') {
        return "yellow";
      }
      else if (estado == 'EN_PROCESO') {
        return "deeporange";
      }
      else if (estado == 'CERRADA') {
        return "gray";
      }
      else if (estado == 'CANCELADA') {
        return "pink";
      }
      console.log("estado", estado);
    },
    getTextoBadgeEstadoOferta: function (estado) {

      if (!estado) {
        return "Sin Estado";
      }

      if (estado == 'ABIERTA') {
        return "Abierta";
      }
      else if (estado == 'TERMINADA') {
        return "Terminada";
      }
      else if (estado == 'APROBADA') {
        return "Aprobada";
      }
      else if (estado == 'ENVIADA') {
        return "Enviada";
      }
      else if (estado == 'ACEPTADA') {
        return "Aceptada";
      }
      else if (estado == 'RECHAZADA') {
        return "Rechazada";
      }
      else if (estado == 'CADUCADA') {
        return "Caducada";
      }
      else if (estado == 'EN_PROCESO') {
        return "En proceso";
      }
      else if (estado == 'CERRADA') {
        return "Cerrada";
      }
      else if (estado == 'CANCELADA') {
        return "Cancelada";
      }

    },
    loadData: function () {
      const self = this;
      if (self.tabActive == 'ofertas') {
        self.loadOfertas();
      } else if (self.tabActive == 'pedidos') {
        self.loadPedidos();
      } 
    },
    loadOfertas: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.id) {
        app.preloader.show();
        Oferta.getList({
          filter: {
            oportunidadId: self.$refs.rform.formData.id, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,cliente,numero,contratacion,fechaOferta,estado'
        })
          .then(res => self.ofertas = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    loadPedidos: function () {
      const self = this;
      const app = self.$f7;
      if (self.$refs.rform.formData.id) {
        app.preloader.show();
        Pedido.getList({
          filter: {
            oportunidadId: self.$refs.rform.formData.id, onlyAggregations: false, withoutAggregations: true
          }, projection: 'codigo,numero,cliente,activo'
        })
          .then(res => self.pedidos = res.list)
          .catch(function (e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function () {
            app.preloader.hide();
          });
      }
    },
    generarOferta: function () {
      const self = this;
      app.preloader.show();
      Oportunidad.generarOferta(self.$refs.rform.formData.id)
        .then(res => {
          // self.$refs.rform.formData = res;
          app.dialog.alert("Oferta generada");
        })
        .catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
        })
        .then(function () {
          app.preloader.hide();
        });
    },
    marcarPerdida: function () {
      const self = this;
      app.preloader.show();
      Oportunidad.marcarPerdida(self.$refs.rform.formData.id)
        .then(res => {
          self.$refs.rform.formData = res;
          app.dialog.alert("Marcada como perdida");
        })
        .catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
        })
        .then(function () {
          app.preloader.hide();
        });
    },
    deleteItem: function () {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function () {
          Campania.delete({ itemId: self.id }).then(function (res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function (error) {
            app.dialog.alert(error.message);
          });
        }
      );
    },
    getColorBadgeEstado: function (estado) {
      let color = "gray";
      switch (estado) {
        case "CREADA":
          color = "blue";
          break;
        case "EN_CURSO":
          color = "lime";
          break;
        case "GANADA":
          color = "green";
          break;
        case "PERDIDA":
          color = "red";
          break;
      }
      return color;
    },
    getTextBadgeEstado: function (estado) {
      let texto = "gray";
      switch (estado) {
        case "CREADA":
          texto = "Creada";
          break;
        case "EN_CURSO":
          texto = "En curso";
          break;
        case "GANADA":
          texto = "Ganada";
          break;
        case "PERDIDA":
          texto = "Perdida";
          break;
      }
      return texto;
    },
    getUrl(url) {
      const self = this;
      var currentUrl = new URL(window.location.href);
      if (self.target == 'cordova') {
        return '/' + url;
      }
      return currentUrl.pathname + ('#!/' + url);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;


      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("oportunidad.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      self.$emit('saved', res);
    },
    abrirPopupEnviarEmail: function() {
      var self = this;
      this.enviarEmail = true;
    },
    oportunidadesSeleccionadas: function() {
      const self = this;
      let oportunidades = [self.$refs.rform.formData].map(selected => {
        return {id: selected.id};
      });
      return oportunidades;
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>