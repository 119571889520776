import Pedidos from './pedidos.vue';
import PedidoForm from './pedido-form.vue';
import DocumentosPedidoForm from './documentos-pedido-form.vue';
import PedidoPlanificar from './pedido-planificar.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/pedidos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos', ['ADMIN', 'INSPECTOR'], Pedidos),
        keepAlive: true
    },
    {
        path: '/pedidos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/new', ['ADMIN', 'INSPECTOR'], PedidoForm)
    },
    {
        path: '/pedidos/planificar/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/planificar', ['ADMIN', 'INSPECTOR'], PedidoPlanificar)
    },
    {
        path: '/pedidos/planificar-tpi/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/planificar-tpi', ['ADMIN', 'INSPECTOR'], PedidoPlanificar, { esTpi: true })
    },
    {
        path: '/pedidos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/edit', ['ADMIN', 'INSPECTOR'], PedidoForm)
    },
    {
        path: '/pedidos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/view', ['ADMIN', 'INSPECTOR'], PedidoForm, { editable: false })
    },
    {
        path: '/pedidos/documentos/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('pedidos/documentos', ['ADMIN', 'INSPECTOR'], DocumentosPedidoForm, { editable: false })
    },
];
export default routes;