<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Campañas" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Campania"
        :tableCode="'CAMPANIA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="100"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/campanias/new/">Nueva campaña</a>
      </template>
      <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver') + ' Campaña'" icon-f7="eye" color="green" :href="`/campanias/view/${item.id}/`"></f7-link>
          <f7-link icon-f7="square_pencil" color="blue" :href="`/campanias/edit/${item.id}/`"></f7-link>
          <f7-link icon-f7="trash" color="red" @click="deleteItem(item.id, false)"></f7-link>
      </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Campania from "./../../js/db/Campania.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Campania,
      Utils
    };
  },
  computed: {},
  methods: {
    deleteItem: function (id, confirmation = false) {
      const self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function () {
          Campania.delete({ itemId: id, data: { confirmation: confirmation } }).then(function (res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function (error) {
            if (error && error.code == 'OPORT_CURSO') {
              var message = error.message;
              app.dialog.confirm(
                message,
                function () {
                  self.deleteItem(id, true);
                }
              );
              return;
            } else {
              app.dialog.alert(error.message);
            }

          });
        }
      );
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>