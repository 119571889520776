<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('municipios.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('municipios.municipio')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('municipios.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :beforeSave="beforeSave"
      :readonly="readonly"
      :dbAdapter="Municipio"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <f7-block-title class="col-100">
                    Códigos Postales
                    <f7-link v-if="!readonly" @click="addCP('cps')">{{$t('municipios.añadir')}}</f7-link>
                  </f7-block-title>
                <div class="form-caja margin-horizontal">
                    <div
                      class="col-100"
                      v-for="(cp, index) in rform.formData.cps"
                      :key="'codigoPostal' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Código Postal'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un código postal válido'"
                        :name="'cps.' + index"
                        :validation="{isValid: str => {return validator.isEmpty(str, {ignore_whitespace:true}) || ValidationUtils.validCodigoPostal(str)}, errorMessage: '* Introduzca un código postal válido'}"
                        :key="'codigoPostal' + index"
                        
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cps, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div> 
                <r-input
                  floating-label
                  :label="$t('municipios.poblacion')"
                  :form="rform"
                  type="text"
                  name="poblacion"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Provincia"
                  floating-label
                  :label="$t('municipios.provincia')"
                  :form="rform"
                  name="codProvincia"
                  labelProp="nombre"
                  idProp="codigo"
                  idFieldDetail="id"
                ></r-autocomplete>
                <r-autocomplete 
                  v-if="rform.formData.codComunidad"
                  :dbAdapter="Comunidad"
                  floating-label
                  :label="$t('municipios.comunidad')"
                  :form="rform"
                  name="codComunidad"
                  labelProp="nombre"
                  idProp="codigo"
                  idFieldDetail="id"
                ></r-autocomplete>
            
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Municipio from "./../../js/db/Municipio.js";
import Provincia from "./../../js/db/Provincia.js";
import Comunidad from "./../../js/db/Comunidad.js";
import ValidationUtils from "./../../js/ValidationUtils.js";
import validator from "validator";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Municipio,
      Provincia,
      Comunidad,
      readonly: !this.editable,
      ValidationUtils, 
      validator
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      const self = this;
      delete data.extrasList;
      delete data.extras;
      resolve(data);
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Municipio.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    addCP: function(listName) {
      var self = this;
      var arr = self.$refs.rform.formData[listName] || [];
      arr.push("");
      self.$set(self.$refs.rform.formData, listName, arr);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      if (!self.idData) {
      app.views.current.router.navigate(
                "/municipios/view/" + res.id + "/"
              );
      }
      self.idData = res.id;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("municipios.municipio_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>