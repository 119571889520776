<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id" sliding>{{$t('formatos.auditoria')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="saveAuditoria()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Formato"
      @loadItem="loadItem"
      class="full-height"
      classContent="full-height flex-direction-row"
      :defaultData="{auditoria:{comprobaciones: []}}"
    >
      <template v-slot="{rform}">
        <div class="page-content" style="flex:1;--f7-navbar-height: 0px;">
          <f7-block-title >
            {{$t('formatos.comprobaciones')}}
            <f7-link @click="rform.formData.auditoria.comprobaciones.push({cuando: [], entonces: []});indexComprobacion=rform.formData.auditoria.comprobaciones.length-1; comprobacionSelected=rform.formData.auditoria.comprobaciones[indexComprobacion]; $refs.popupComprobacion.open()">{{$t('formatos.add')}}</f7-link>
          </f7-block-title>
          <f7-list class="comprobacion-list">
            <div
              style="display: flex;"
              v-for="(comprobacion, index) in rform.formData.auditoria.comprobaciones" :key="'btn-comprobacion-'+ index"
            >
              <f7-list-item 
                link 
                class="comprobacion-item margin-half border-2"
                @click="onClickItem(comprobacion, index)"
                popup-open=".popup-comprobacion"
                :class="{'border-color-orange': !comprobacion.activa, 'border-color-green': comprobacion.activa}"
              >
                <div 
                  @click="onClickItem(comprobacion, index)"
                  slot="media" class="display-flex align-self-center"
                >
                  <f7-icon v-if="comprobacion.tipo == 'BLOQUEANTE'" color="red" f7="lock_fill" v-tooltip="'Bloqueante'"/>
                  <f7-icon v-if="comprobacion.tipo == 'INFORMATIVO'" color="primary" f7="info_circle_fill" v-tooltip="'Informativa'" />
                  <input
                    :value="index + 1"
                    style="width:3em; text-align:center;"
                    :readonly="true"
                  />
                </div>
                <input
                  @click="onClickItem(comprobacion, index)"
                  slot="before-title"
                  :value="comprobacion.nombre"
                  :readonly="true"
                />
              </f7-list-item>
              <f7-link
                @click="$delete(rform.formData.auditoria.comprobaciones,index);"
                class="margin-half"
                icon-f7="trash"
              ></f7-link>
            </div>
          </f7-list>
        </div>
        <f7-popup tablet-fullscreen ref="popupComprobacion" class="popup-comprobacion">
          <f7-page :page-content="true">
            <f7-navbar title="Comprobación">
              <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
              </f7-nav-right>
            </f7-navbar>
            <div v-if="comprobacionSelected" class="full-height flex-direction-row">
              <div class="page-content" style="flex:1;--f7-navbar-height: 0px;">
                <f7-row class="list margin-horizontal-half">
                  <r-input 
                    class="col-25"
                    floating-label
                    :label="$t('formatos.codigoExpediente')"
                    :name="'auditoria.comprobaciones.' + indexComprobacion + '.codigoExpedienteProbar'"
                    :form="rform"
                  />
                  <f7-button class="col-75 margin-vertical" color="blue" fill @click="probar(comprobacionSelected)"> Probar</f7-button>
                </f7-row>
                <f7-block-title >
                  {{$t('formatos.datosGenerales')}}
                </f7-block-title>
                <f7-card>
                  <f7-card-content>
                    <f7-row class="list">
                      <f7-col width="100" medium="50">
                        <r-input 
                          floating-label
                          :label="$t('formatos.nombre')"
                          :name="'auditoria.comprobaciones.' + indexComprobacion + '.nombre'"
                          :form="rform"
                        />
                        <r-input 
                          floating-label
                          :label="$t('formatos.descripcion')"
                          :name="'auditoria.comprobaciones.' + indexComprobacion + '.descripcion'"
                          :form="rform"
                          type="textarea"
                        />
                      </f7-col>
                      <f7-col width="100" medium="50">
                        <r-autocomplete 
                          floating-label
                          :label="$t('formatos.tipo')"
                          :name="'auditoria.comprobaciones.' + indexComprobacion + '.severidad'"
                          :form="rform"
                          idProp="codigo"
                          labelProp="nombre"
                          :dbItems="[
                            {codigo: 'BLOQUEANTE', nombre:'Bloqueante'},
                            {codigo: 'INFORMATIVO', nombre:'Informativo'}
                          ]"
                        >
                        </r-autocomplete>
                        <r-autocomplete-m2m 
                          floating-label
                          :label="$t('formatos.aplicaEn')"
                          :name="'auditoria.comprobaciones.' + indexComprobacion + '.aplicaEn'"
                          :form="rform"
                          labelProp="nombre"
                          idProp="codigo"
                          type="textarea"
                          :lineBreak="true"
                          :dbItems="[
                            {id: '1', codigo: 'APP', nombre:'Aplicación Offline'},
                            {id: '2', codigo: 'SINCRONIZACION', nombre:'Sincronización'},
                            {id: '3', codigo: 'CERTIFICADO', nombre:'Generar Certificado'},
                            {id: '4', codigo: 'AUDITORIA', nombre:'Pestaña de Auditorías'}
                          ]"
                        >
                        </r-autocomplete-m2m>
                      </f7-col>
                      <r-input 
                        class="col-100 medium-50"
                        floating-label
                        :label="$t('formatos.mensajeAMostrar')"
                        :name="'auditoria.comprobaciones.' + indexComprobacion + '.mensaje'"
                        :form="rform"
                      />
                      <r-input 
                        class="col-100 medium-50"
                        floating-label
                        :label="$t('formatos.activa')"
                        :name="'auditoria.comprobaciones.' + indexComprobacion + '.activa'"
                        :form="rform"
                        type="check"
                      />
                    </f7-row>
                  </f7-card-content>
                </f7-card>
                <f7-icon class="icon-trash" color="red" f7="trash" />
                <Container
                  class="container-trash"
                  orientation="horizontal"
                  :should-accept-drop="(src, payload) => getShouldAcceptDropDelete(src, payload)"
                >
                </Container>
                <f7-block-title class="block-margin">
                  {{$t('formatos.cuando')}}
                  <f7-link @click="comprobacionSelected.cuando.push({operaciones: []})">{{$t('formatos.add')}}</f7-link>
                </f7-block-title>
                <f7-card v-for="(cuando, indexCuando) in comprobacionSelected.cuando" :key="'btn-cuando-'+ indexCuando">
                  <f7-card-content 
                    class="border-2"
                    :class="{'border-color-red': !operacionesCorrectas(cuando.operaciones), 'border-color-green': operacionesCorrectas(cuando.operaciones)}"
                  >
                    <Container
                      group-name="group-cuando"
                      orientation="horizontal"
                      class="container-horizontal"
                      :animation-duration="1000"
                      @drop="onDrop(cuando.operaciones, $event)"
                      :get-child-payload="getPayload(cuando.operaciones)"
                      :should-accept-drop="(src, payload) => getShouldAcceptDropCuando(src, payload)"
                    >
                      <Draggable v-for="(operacion, index) in cuando.operaciones" :key="'btn-cuando-'+ indexCuando + '-operacion-'+index">
                        <operacion-auditoria :onDrop="onDrop" :operacion="operacion" :rform="rform" :name="'auditoria.comprobaciones.' + indexComprobacion + '.cuando.' + indexCuando + '.operaciones.' + index"></operacion-auditoria>
                      </Draggable>
                    </Container>
                  </f7-card-content>
                  <f7-link
                    @click="$delete(comprobacionSelected.cuando,indexCuando);"
                    class="margin-half"
                    style="position:absolute;right:0;top:0;"
                    icon-f7="trash"
                  ></f7-link>
                </f7-card>
                <f7-icon class="icon-trash" color="red" f7="trash" />
                <Container
                  class="container-trash"
                  orientation="horizontal"
                  :should-accept-drop="(src, payload) => getShouldAcceptDropDelete(src, payload)"
                >
                </Container>
                <f7-block-title  class="block-margin">
                  {{$t('formatos.entonces')}}
                  <f7-link @click="comprobacionSelected.entonces.push({operaciones: []})">{{$t('formatos.add')}}</f7-link>
                </f7-block-title>
                <f7-card v-for="(entonces, indexEntonces) in comprobacionSelected.entonces" :key="'btn-entonces-'+ indexEntonces">
                  <f7-card-content
                    class="border-2"
                    :class="{'border-color-red': !operacionesCorrectas(entonces.operaciones), 'border-color-green': operacionesCorrectas(entonces.operaciones)}"
                  >
                    <Container
                      group-name="group-entonces"
                      orientation="horizontal"
                      class="container-horizontal"
                      :animation-duration="1000"
                      @drop="onDrop(entonces.operaciones, $event)"
                      :get-child-payload="getPayload(entonces.operaciones)"
                      :should-accept-drop="(src, payload) => getShouldAcceptDropEntonces(src, payload)"
                    >
                      <Draggable v-for="(operacion, index) in entonces.operaciones" :key="'btn-entonces-'+ indexEntonces + '-operacion-'+index">
                        <operacion-auditoria :onDrop="onDrop" :operacion="operacion" :rform="rform" :name="'auditoria.comprobaciones.' + indexComprobacion + '.entonces.' + indexEntonces + '.operaciones.' + index"></operacion-auditoria>
                      </Draggable>
                    </Container>
                  </f7-card-content>
                  <f7-link
                    @click="$delete(comprobacionSelected.entonces,indexEntonces);"
                    class="margin-half"
                    style="position:absolute;right:0;top:0;"
                    icon-f7="trash"
                  ></f7-link>
                </f7-card>
              </div>

              <!-- Panel derecho -->
              <div style="width: 250px;" class="bg-color-white full-height page-content">
                <div>
                  <f7-block-title>{{$t('formatos.valores')}}</f7-block-title>
                  <f7-block>
                    <Container
                      group-name="group-panel"
                      behaviour="copy"
                      :get-child-payload="getPayloadValor(null)"
                    >
                      <Draggable v-for="(valor, idx) in valores" :key="'btn-valor-'+idx">
                        <f7-button large :href="false" raised class="btn-drag bg-color-white margin-vertical-half">
                          <f7-icon v-if="valor.icono" class="icon-placeholder" :f7="valor.icono" />
                          <span v-else class="icon-placeholder"> {{valor.visual}} </span>
                          {{valor.nombre}}
                        </f7-button>
                        <div class="div-drag larger" style="cursor: pointer;">
                          <f7-icon v-if="valor.icono" class="larger icon-drag" :f7="valor.icono" />
                          <span v-else> {{valor.visual}} </span>
                        </div>
                      </Draggable>
                    </Container>
                  </f7-block>
                </div>
                <div>
                  <f7-block-title>{{$t('formatos.funciones')}}</f7-block-title>
                  <f7-block>
                    <Container
                      group-name="group-panel"
                      behaviour="copy"
                      :get-child-payload="getPayloadFuncion(null)"
                    >
                      <Draggable v-for="(funcion, idx) in funciones" :key="'btn-funcion-'+idx">
                        <f7-button large :href="false" raised class="btn-drag bg-color-white margin-vertical-half">
                          <f7-icon class="icon-placeholder" :f7="funcion.icono" />
                          {{funcion.nombre}}
                        </f7-button>
                        <div class="div-drag larger" style="cursor: pointer;">
                          <f7-icon class="larger icon-drag" :f7="funcion.icono" />
                        </div>
                      </Draggable>
                    </Container>
                  </f7-block>
                </div>
                <div v-for="(oper, idx) in operaciones" :key="'btn-operacion-'+idx">
                  <f7-block-title v-if="idx == 0 || operaciones[idx - 1].tipo != oper.tipo">{{$t('formatos.' + oper.tipo)}}</f7-block-title>
                  <f7-block>
                    <Container
                      group-name="group-panel"
                      behaviour="copy"
                      :get-child-payload="getPayloadOperacion(null, oper.tipo)"
                    >
                      <Draggable v-for="(operacion, index) in oper.operaciones" :key="'btn-operacion-' + oper.tipo + '-' + index">
                        <f7-button large :href="false" raised class="btn-drag bg-color-white margin-vertical-half">
                          <f7-icon v-if="operacion.icono" class="icon-placeholder" :f7="operacion.icono" />
                          <span v-else class="icon-placeholder"> {{operacion.visual}} </span>
                          {{operacion.nombre}}
                        </f7-button>
                        <div class="div-drag larger" style="cursor: pointer;"> {{operacion.visual}} </div>
                      </Draggable>
                    </Container>
                  </f7-block>
                </div>
              </div>
            </div>
          </f7-page>
        </f7-popup>
      </template>
    </r-form>
  </f7-page>
</template>
<style>
.container-horizontal input{
  width: 100%;
}
.comprobacion-list > ul{
  background-color: transparent;
}
.comprobacion-list > ul:before, .comprobacion-list > ul:after {
  display: none;
}
</style>
<style scoped>
.block-margin {
  margin-right: 250px;
}
.border-2 {
  border: 2px solid;
}
.block-title + .card {
  margin-top: 20px;
}
.div-drag {
  display: none;
  text-align: center;
}
.icon-drag {
  transform: rotate(-15deg);
}
.smooth-dnd-ghost .div-drag {
  display: block;
}
.smooth-dnd-ghost .btn-drag {
  display: none;
}
.larger {
  font-size: large;
  font-weight: bold;
}
.container-horizontal {
  display: flex;
  width: 100%;
  list-style: none;
  flex-flow: wrap;
}
.container-trash {
  position: absolute;
  width: 150px;
  height: 60px;
  /* top: 20px; */
  right: 0;
}
.container-trash:hover {
  background-color: rgba(255, 0, 0, 0.226);
}
.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}
.icon-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.2;
  font-size: 2.8em;
  transform: rotate(-15deg);
}
.icon-trash {
  position: absolute;
  right: 10px;
  /* top: 30px; */
  opacity: 0.2;
  font-size: 2.8em;
  transform: rotate(-15deg);
  width: 150px;
}
.comprobacion-item {
  background-color: #f3f3f3;
  width: 100%;
}
.block-title > input {
  background: transparent;
  border: none;
  font-weight: bold;
  width: 100%;
}
.comprobacion-item > .item-link input {
  border: none;
  background: transparent;
  width: auto;
  width: 100%;
  cursor: pointer;
}
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Formato from "./../../js/db/Formato.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
import EvaluadorJS from "./../../js/db/EvaluadorJS.js";
import operacionAuditoria from "./operacion-auditoria";
import { Container, Draggable } from "vue-smooth-dnd";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
  }

};

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rAutocompleteM2m,
    operacionAuditoria,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Formato,
      Inspeccion,
      EvaluadorJS,
      readonly: !this.editable,
      rPanelOpenedByBreakpoint: false,
      dragType: undefined,
      indexComprobacion: -1,
      comprobacionSelected: undefined,
      ambito: 'AUDITORIA_EXPEDIENTE',
      nombreObjeto: 'expediente',
      operaciones : [
        {tipo: "ARITMETICO", operaciones: [
          {tipoPadre:"ARITMETICO", tipo: "OPERACION", codigo: "SUMAR", nombre: "Sumar", visual: "+", valor: "+", icono: "plus"},
          {tipoPadre:"ARITMETICO", tipo: "OPERACION", codigo: "RESTAR", nombre: "Restar", visual: "-", valor: "-", icono: "minus"},
          {tipoPadre:"ARITMETICO", tipo: "OPERACION", codigo: "DIVIDIR", nombre: "Dividir", visual: "/", valor: "/", icono: "divide"},
          {tipoPadre:"ARITMETICO", tipo: "OPERACION", codigo: "MULTIPLICAR", nombre: "Multiplicar", visual: "x", valor: "*", icono: "multiply"}, 
        ]},
        {tipo: "COMPARACION", operaciones: [
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "IGUAL", nombre: "Igual", visual: "==", valor: "==", icono: "equals"},
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "NOIGUAL", nombre: "No Igual", visual: "!=", valor: "!="},
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "MAYOR", nombre: "Mayor", visual: ">", valor: ">", icono: "greaterthan"},
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "MENOR", nombre: "Menor", visual: "<", valor: "<", icono: "lessthan"},
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "MAYORIGUAL", nombre: "Mayor o Igual", visual: ">=", valor: ">="},
          {tipoPadre:"COMPARACION", tipo: "OPERACION", codigo: "MENORIGUAL", nombre: "Menor o Igual", visual: "<=", valor: "<="},
        ]},
        {tipo: "LOGICO", operaciones: [
          {tipoPadre:"LOGICO", tipo: "OPERACION", codigo: "AND", nombre: "And", visual: "&&", valor: "&&"},
          {tipoPadre:"LOGICO", tipo: "OPERACION", codigo: "OR", nombre: "Or", visual: "||", valor: "||"},
        ]},
        {tipo: "PARENTESIS", operaciones: [
          {tipoPadre: "PARENTESIS", tipo: "OPERACION", codigo: "(", nombre: "(", visual: "(", valor: "("},
          {tipoPadre: "PARENTESIS", tipo: "OPERACION", codigo: ")", nombre: ")", visual: ")", valor: ")"},
        ]},
      ],
      valores: [
        {tipo: "VALOR", codigo: "TEXTO", nombre: "Texto", icono: "textbox"},
        {tipo: "VALOR", codigo: "NUMERO", nombre: "Número", icono: "number"},
        {tipo: "VALOR", codigo: "TRUE", nombre: "True", visual: "True", valor: "true"},
        {tipo: "VALOR", codigo: "FALSE", nombre: "False", visual: "False", valor: "false"},
        {tipo: "VALOR", codigo: "FECHA", nombre: "Fecha", icono: "calendar"},
        {tipo: "VALOR", codigo: "CARACTERISTICA", nombre: "Característica", icono: "folder", valor: "objFunctions.getOneByFieldRecursive(expediente.caracteristicas, 'nombre', '##valorAux##', 'hijas')"},
        {tipo: "VALOR", codigo: "COMPROBACION", nombre: "Comprobación", icono: "checkmark_rectangle", valor: "objFunctions.getOneByFieldRecursive(expediente.comprobaciones, 'codigo', '##valorAux##', 'hijas')"},
        {tipo: "VALOR", codigo: "RESULTADO", nombre: "Resultado", icono: "checkmark_seal_fill"},
        {tipo: "VALOR", codigo: "CAMPO", nombre: "Campo Expediente", icono: "rectangle_dock"},
      ],
      funciones: [
        {tipo: "FUNCION", codigo: "VALOR", nombre: "Tiene valor", icono: "checkmark_circle_fill", valor:"!!(##operaciones##)", operaciones: []},
        {tipo: "FUNCION", codigo: "NO_VALOR", nombre: "No tiene valor", icono: "multiply_circle_fill", valor:"!!!(##operaciones##)", operaciones: []},
        {tipo: "FUNCION", codigo: "COUNT", nombre: "Count", icono: "list_number", valor:"(##operaciones##).length", operaciones: []},
        {tipo: "FUNCION", codigo: "HIJAS", nombre: "Hijas", icono: "square_grid_3x2", valor:"objFunctions.getListByFieldValue(##operaciones##)", operaciones: []},
      ],
      nuid: 1
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    probar (comprobacion) {
      var self = this;
      var app = self.$f7;
      if (!comprobacion.codigoExpedienteProbar) {
        app.dialog.alert("Debe introducir un codigo expediente con el que probar.");
        return;
      }
      app.preloader.show();
      EvaluadorJS.getList({filter: {ambitos: [self.ambito]}}).then(res => {
        var jsEval = res.list;
        Inspeccion.getByCodigo(comprobacion.codigoExpedienteProbar).then(expediente => {
          if (!expediente) {
            app.dialog.alert("Expediente no encontrado.");
            return;
          }

          self.auditarExpediente(expediente, jsEval, comprobacion);
        }).catch(_ => app.dialog.alert("Expediente no encontrado.")).finally(_ => app.preloader.hide());
      }).catch(_ => app.preloader.hide());
    },
    auditarExpediente(exp, jsEval, comprobacion) {
      var self = this;
      var app = self.$f7;
      var expediente = exp;
      var objFunctions = {};
      jsEval.forEach(element => {
        eval("objFunctions." + element.nombre + " = " + element.js);
      });

      var expresiones = [];

      comprobacion.cuando.forEach((cuando, idx) => {
        expresiones.push({expresion: self.getValueExpresion(cuando.operaciones, cuando), tipo: "Cuando", numero: idx});
      });
      comprobacion.entonces.forEach((entonces, idx) => {
        expresiones.push({expresion: self.getValueExpresion(entonces.operaciones, entonces), tipo: "Entonces", numero: idx});
      });

      var strAviso = "";

      if (expediente.formato && expediente.formato.codigo != self.$refs.rform.formData.codigo) {
        strAviso = strAviso + "<hr><span class='text-color-red'><b>ATENCION: </b></span>Formato de expediente no coincidente.<hr>";
      }

      expresiones.forEach(expresion => {
        var resultEval = null;
        try {
          resultEval = eval(expresion.expresion);
        } catch (e) {
          resultEval = e.message;
        }
        strAviso = strAviso + "<span class='text-color-" + (resultEval == true ? "green" : "red") +  "'><b>" + expresion.tipo + " " + (expresion.numero + 1) + "</b> : " + resultEval + "</span><br>";
      });
      app.dialog.alert(strAviso);
    },
    getValueExpresion(operaciones, parentOper) {
      var self = this;
      if (!operaciones || !operaciones.length) {
        return "";
      }
      var result = operaciones.map((oper, idx) => {
        var nextOper = operaciones[idx + 1];
        if (oper.tipo == 'VALOR' || oper.tipo == 'FUNCION') {
          return self.getValorOperacion(oper, nextOper, parentOper);
        } else {
          return oper.valor
        }

      }).join(" ");
      return result;
    },
    getValorOperacion(operacion, nextOper, parentOper) {
      var self = this;
      switch (operacion.codigo) {
        case "NUMERO":
        case "TRUE":
        case "FALSE":
          return operacion.valor;
        case "CARACTERISTICA":
          var valor = operacion.valor;
          if ((nextOper && nextOper.tipoPadre && nextOper.tipoPadre != "PARENTESIS") || (parentOper && parentOper.codigo && (parentOper.codigo == 'NO_VALOR' || parentOper.codigo == 'VALOR')) ) {
            valor = operacion.valor + '.valor';
          }
          return valor.replace("##valorAux##", operacion.valorAux);
        case "COMPROBACION":
          var valor = operacion.valor;
          if ((nextOper && nextOper.tipoPadre && nextOper.tipoPadre != "PARENTESIS") || (parentOper && parentOper.codigo && (parentOper.codigo == 'NO_VALOR' || parentOper.codigo == 'VALOR')) ) {
            valor = operacion.valor + '.valor';
          }
          return valor.replace("##valorAux##", operacion.valorAux);
        case "VALOR":
        case "NO_VALOR":
        case "COUNT":
          var operacionesStr = "";
          var valor = operacion.valor;
          if (operacion.operaciones && operacion.operaciones.length) {
            var operacionesStr = self.getValueExpresion(operacion.operaciones, operacion);
          }
          return valor.replace("##operaciones##", operacionesStr);
        case "HIJAS":
          var operacionesStr = "";
          var valor = operacion.valor;
          var caractComproStr = self.getValorOperacion(operacion.operaciones[0]);
          operacionesStr = operacionesStr + caractComproStr + ", 'hijas', ";
          if (operacion.operaciones[0].codigo == "CARACTERISTICA") {
            operacionesStr = operacionesStr + "'valor', ";
          }else if (operacion.operaciones[0].codigo == "COMPROBACION") {
            operacionesStr = operacionesStr + "'resultado', ";
          }
          if (operacion.operaciones[1] && operacion.operaciones[1].valor && operacion.operaciones[1].valor.trim()) {
            operacionesStr = operacionesStr + "'" + operacion.operaciones[1].valor + "'";
          } else {
            operacionesStr = operacionesStr + "null";
          }
          return valor.replace("##operaciones##", operacionesStr);
        default:
          return "'" + operacion.valor + "'";
      }
    },
    saveAuditoria() {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      var auditoria = self.$refs.rform.formData.auditoria;
      auditoria.comprobaciones.forEach(comprobacion => {
        comprobacion.cuando.forEach(cuando => {
          cuando.expresion = self.getValueExpresion(cuando.operaciones, cuando);
        });
        comprobacion.entonces.forEach(entonces => {
          entonces.expresion = self.getValueExpresion(entonces.operaciones, entonces);
        });
      });
      auditoria.ambito = self.ambito;
      auditoria.nombreObjeto = self.nombreObjeto;
      Formato.auditoria({itemId: self.id, data: auditoria})
        .then(function(res) {
          self.$refs.rform.formData = res;
          app.dialog.alert("Auditoría guardada correctamente.");
        })
        .catch(function(e) {
          app.dialog.alert(e.message);
        })
        .then(_ => app.preloader.hide());
    },
    onClickItem(comprobacion, index) {
      var self = this;
      self.comprobacionSelected = comprobacion; 
      self.indexComprobacion = index;
    },
    operacionCorrecta (operacion) {
      var self = this;
      if (operacion.codigo == "CARACTERISTICA" || operacion.codigo == "COMPROBACION") {
        if (!operacion.valorAux) {
          return false;
        }
      }
      if (operacion.tipo == "FUNCION") {
        if (!operacion.operaciones || !operacion.operaciones.length) {
          return false;
        } else {
          var allCorrect = true;
          operacion.operaciones.forEach(oper => {
            if (!self.operacionCorrecta(oper)) {
              allCorrect = false;
            }
          });
          if (!allCorrect) {
            return false;
          }
        }
      }
      if (!operacion.valor) {
        return false;
      }
      return true;
    },
    operacionesCorrectas (operaciones) {
      var self = this;
      var allCorrect = true;
      var expresion = operaciones.map(operacion => {
        if (!self.operacionCorrecta(operacion)) {
          allCorrect = false;
        }
        if (operacion.tipo == "VALOR" || operacion.tipo == "FUNCION") {
          switch (operacion.codigo) {
            case "TEXTO":
              return '"foo"';
            case "NUMERO":
              return 5;
            case "TRUE":
              return true;
            case "FALSE":
              return false;
            case "CARACTERISTICA":
              return '"caracteristica"';
            case "COMPROBACION":
              return '"comprobacion"';
            case "RESULTADO":
              return '"F"';
            case "CAMPO":
              return '"campo"';
            case "FECHA":
              return '"06/08/2020"';
            case "COUNT":
              return 5;
            case "VALOR":
              return true;
            case "NO_VALOR":
              return false;
            case "HIJAS":
              return [
                {codigo: '1', valor: 'val1'},
                {codigo: '2', valor: 'val2'},
              ];
            default:
              return '"foo"';
          }
        } else {
          return operacion.valor;
        }
      }).join(' ');
      if (!allCorrect) {
        return false;
      }
      var resultEval = undefined;
      try {
        resultEval = eval(expresion);
      } catch (e) {
      }
      if (typeof resultEval == 'boolean') {
        return true;
      }
      return false;
    },
    getShouldAcceptDropCuando (src, payload) {
      var self = this;
      return src.groupName == 'group-cuando' || src.groupName == 'group-panel';
    },
    getShouldAcceptDropDelete (src, payload) {
      var self = this;
      return src.groupName != 'group-panel';
    },
    getShouldAcceptDropEntonces (src, payload) {
      var self = this;
      return src.groupName == 'group-entonces' || src.groupName == 'group-panel';
    },
    loadItem (res) {
      var self = this;
      if (!res.auditoria) {
        self.$set(self.$refs.rform.formData, "auditoria", {comprobaciones: []});
      }
    },
    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult);
      self.$forceUpdate();
    },
    getPayloadOperacion(arr, tipo) { 
      var self = this;
      if (!arr) {
        if (tipo) {
          return index => Object.assign({}, self.operaciones.filter(o => o.tipo == tipo)[0].operaciones[index]);
        } else return index => Object.assign({}, self.operaciones[index]);
      } else return index => Object.assign({}, arr[index]);
    },
    getPayload(arr) { 
      var self = this;
      return index => Object.assign({}, arr[index]);
    },
    getPayloadValor(arr) { 
      var self = this;
      if (!arr) {
        return index => Object.assign({}, self.valores[index]);
      } else return index => Object.assign({}, arr[index]);
    },
    getPayloadFuncion(arr) { 
      var self = this;
      if (!arr) {
        return index => { 
          var obj = Object.assign({}, self.funciones[index])
          obj.operaciones = [];
          return obj;
        };
      } else return index => Object.assign({}, arr[index]);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("formatos.formato_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>