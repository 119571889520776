<template>
  <f7-tab id="elemento-reproduccion">
    <div class="full-height reproduccion">
      <f7-navbar>
        <f7-button
          :disabled="rform.readonly"
          class="display-flex"
          icon-f7="plus"
          @click="anadirProteccion"
          v-tooltip="'Añadir protección'"
        >P</f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="plus"
          @click="anadirSubproteccion"
          v-tooltip="'Añadir subprotección'"
        >S</f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="plus"
          @click="anadirCircuito"
          v-tooltip="'Añadir circuito'"
        >C</f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="doc_on_doc"
          @click="copiarProteccion"
          v-tooltip="'Copiar protección'"
        ></f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="doc_on_doc_fill"
          @click="copiarProteccionJerarquia"
          v-tooltip="'Copiar protección con jerarquía'"
        ></f7-button>
        <f7-button
          :disabled="(!copied_proteccion && !copied_protecciones_jerarquia) || rform.readonly"
          class="display-flex"
          icon-f7="doc_on_clipboard"
          @click="pegarProteccion"
          v-tooltip="'Pegar protección'"
        ></f7-button>
        <f7-button
          :disabled="!copied_proteccion || rform.readonly"
          class="display-flex"
          icon-f7="arrow_down_doc"
          @click="pegarComoSubproteccion"
          v-tooltip="'Pegar como descendiente'"
        ></f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="trash"
          @click="eliminarProteccion"
          v-tooltip="'Eliminar protección'"
        ></f7-button>
        <f7-button
          :disabled="selected==null || rform.readonly"
          class="display-flex"
          icon-f7="checkmark"
          @click="validarProteccion"
          v-tooltip="'Validar protección'"
        ></f7-button>
        <f7-button
          :disabled="selected!=null || rform.readonly"
          class="display-flex"
          icon-f7="text_badge_plus"
          @click="generarMedidas"
          v-tooltip="'Generar medidas'"
        ></f7-button>
      </f7-navbar>
      <div class="page-content no-padding">
        <f7-list style="min-width:600px;">
          <div
            v-for="(proteccion, ri) in cuadro.protecciones || []"
            :key="'cuadro-proteccion-'+ri"
            class="card margin elevation-4"
            :class="{'cuadro-selected':selected==ri, 'cuadro-last-selected':last_selected==ri}"
            @click="onClickProteccion($event, ri)"
            @change="onChangeProteccion($event, ri)"
          >
            <div class="resumen">
              <div class="card-header list display-block">
                <div class="item-row">
                  <div class="item-cell">{{proteccion.codigo}}</div>
                  <div class="item-cell">{{proteccion.tipo}}</div>
                  <div class="item-cell">{{proteccion.polos}}</div>
                  <div class="item-cell">{{proteccion.sensibilidad}}</div>
                  <div class="item-cell">{{proteccion.intensidadNominal}}</div>
                  <div class="item-cell">{{proteccion.poderCorte}}</div>
                  <div class="item-cell">
                    <span v-if="proteccion.modulo">{{proteccion.modulo}}</span>
                    <span v-if="proteccion.fila">{{proteccion.fila}}</span>
                    <span v-if="proteccion.posicion">{{proteccion.tipo}}{{proteccion.posicion}}</span>
                  </div>
                </div>
              </div>
              <div class="card-content list">
                <div
                  class="item-row"
                  v-for="(circuito, ci) in proteccion.circuitos || []"
                  :key="'cuadro-proteccion-circuito-'+ci"
                >
                  <div class="item-cell">{{circuito.aislamiento}}</div>
                  <div class="item-cell">{{circuito.material}}</div>
                  <div class="item-cell">{{circuito.tipo}}</div>
                  <div class="item-cell">{{circuito.sistemaMontaje}}</div>
                  <div class="item-cell">{{circuito.polos}}</div>
                  <div class="item-cell">{{circuito.seccion}}</div>
                  <div class="item-cell">{{circuito.identificacion}}</div>
                  <div class="item-cell">{{circuito.referencia}}</div>
                </div>
              </div>
            </div>
            <div class="extendido">
              <div class="card-header list display-block">
                <div class="item-row text-small">
                  <div class="item-cell">Código</div>
                  <div class="item-cell">Tipo</div>
                  <div class="item-cell">Polos</div>
                  <div class="item-cell">Sens.</div>
                  <div class="item-cell">I. Nominal</div>
                  <div class="item-cell">Pod. corte</div>
                  <div class="item-cell">Mod-Fila-Pos</div>
                </div>
              </div>
              <div class="card-content list text-align-center">
                <div class="item-row">
                  <input :disabled="rform.readonly"
                    class="item-cell align-self-stretch padding bg-color-primary text-color-white"
                    :value="proteccion.codigo"
                    type="text"
                    :name="'codigo-'+proteccion.codigo"
                    style="border:none;"
                  />
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="MGT"
                        :checked="proteccion.tipo=='MGT'"
                      />
                      <div class="bg-radio">MGT</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="ID"
                        :checked="proteccion.tipo=='ID'"
                      />
                      <div class="bg-radio">ID</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="SEC"
                        :checked="proteccion.tipo=='SEC'"
                      />
                      <div class="bg-radio">SEC</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="FU"
                        :checked="proteccion.tipo=='FU'"
                      />
                      <div class="bg-radio">FU</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="GM"
                        :checked="proteccion.tipo=='GM'"
                      />
                      <div class="bg-radio">GM</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="VIGI"
                        :checked="proteccion.tipo=='VIGI'"
                      />
                      <div class="bg-radio">VIGI</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo"
                        value="ST"
                        :checked="proteccion.tipo=='ST'"
                      />
                      <div class="bg-radio">ST</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-proteccion-'+proteccion.codigo"
                        value="1"
                        :checked="proteccion.polos=='1'"
                      />
                      <div class="bg-radio">1</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-proteccion-'+proteccion.codigo"
                        value="2"
                        :checked="proteccion.polos=='2'"
                      />
                      <div class="bg-radio">2</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-proteccion-'+proteccion.codigo"
                        value="3"
                        :checked="proteccion.polos=='3'"
                      />
                      <div class="bg-radio">3</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-proteccion-'+proteccion.codigo"
                        value="4"
                        :checked="proteccion.polos=='4'"
                      />
                      <div class="bg-radio">4</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'sensibilidad-'+proteccion.codigo"
                        :value="proteccion.sensibilidad"
                        placeholder="Sens."
                      />
                    </div>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'intensidadNominal-'+proteccion.codigo"
                        :value="proteccion.intensidadNominal"
                        placeholder="I. Nominal"
                      />
                    </div>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'poderCorte-'+proteccion.codigo"
                        :value="proteccion.poderCorte"
                        placeholder="Pod. corte"
                      />
                    </div>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'modulo-'+proteccion.codigo"
                        :value="proteccion.modulo"
                        placeholder="Módulo"
                      />
                    </div>
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'fila-'+proteccion.codigo"
                        :value="proteccion.fila"
                        placeholder="Fila"
                      />
                    </div>
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'posicion-'+proteccion.codigo"
                        :value="proteccion.posicion"
                        placeholder="Posición"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-header list display-block">
                <div class="item-row text-small">
                  <div class="item-cell">Aislam.</div>
                  <div class="item-cell">Material</div>
                  <div class="item-cell">Tipo</div>
                  <div class="item-cell">Montaje</div>
                  <div class="item-cell">Polos</div>
                  <div class="item-cell">Sección</div>
                  <div class="item-cell">Identif.</div>
                  <div class="item-cell">Ref.</div>
                </div>
              </div>
              <div class="card-content list circuitos">
                <div
                  class="item-row"
                  v-for="(circuito, ic2) in proteccion.circuitos || []"
                  :key="'cuadro-proteccion-circuito2-'+ic2"
                >
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'aislamiento-'+proteccion.codigo+'-'+ic2"
                        value="PVC"
                        :checked="circuito.aislamiento=='PVC'"
                      />
                      <div class="bg-radio">PVC</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'aislamiento-'+proteccion.codigo+'-'+ic2"
                        value="XLPE"
                        :checked="circuito.aislamiento=='XLPE'"
                      />
                      <div class="bg-radio">XLPE</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'aislamiento-'+proteccion.codigo+'-'+ic2"
                        value="EPR"
                        :checked="circuito.aislamiento=='EPR'"
                      />
                      <div class="bg-radio">EPR</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'material-'+proteccion.codigo+'-'+ic2"
                        value="Cu"
                        :checked="circuito.material=='Cu'"
                      />
                      <div class="bg-radio">Cu</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'material-'+proteccion.codigo+'-'+ic2"
                        value="Al"
                        :checked="circuito.material=='Al'"
                      />
                      <div class="bg-radio">Al</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo+'-'+ic2"
                        value="F"
                        :checked="circuito.tipo=='F'"
                      />
                      <div class="bg-radio">F</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'tipo-'+proteccion.codigo+'-'+ic2"
                        value="R"
                        :checked="circuito.tipo=='R'"
                      />
                      <div class="bg-radio">R</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="A"
                        :checked="circuito.sistemaMontaje=='A'"
                      />
                      <div class="bg-radio">A</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="A2"
                        :checked="circuito.sistemaMontaje=='A2'"
                      />
                      <div class="bg-radio">A2</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="B"
                        :checked="circuito.sistemaMontaje=='B'"
                      />
                      <div class="bg-radio">B</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="B2"
                        :checked="circuito.sistemaMontaje=='B2'"
                      />
                      <div class="bg-radio">B2</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="C"
                        :checked="circuito.sistemaMontaje=='C'"
                      />
                      <div class="bg-radio">C</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="E"
                        :checked="circuito.sistemaMontaje=='E'"
                      />
                      <div class="bg-radio">E</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="F"
                        :checked="circuito.sistemaMontaje=='F'"
                      />
                      <div class="bg-radio">F</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="G"
                        :checked="circuito.sistemaMontaje=='G'"
                      />
                      <div class="bg-radio">G</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'sistemaMontaje-'+proteccion.codigo+'-'+ic2"
                        value="Pte."
                        :checked="circuito.sistemaMontaje=='Pte.'"
                      />
                      <div class="bg-radio">Pte.</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-circuito-'+proteccion.codigo+'-'+ic2"
                        value="1"
                        :checked="circuito.polos=='1'"
                      />
                      <div class="bg-radio">1</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-circuito-'+proteccion.codigo+'-'+ic2"
                        value="2"
                        :checked="circuito.polos=='2'"
                      />
                      <div class="bg-radio">2</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-circuito-'+proteccion.codigo+'-'+ic2"
                        value="3"
                        :checked="circuito.polos=='3'"
                      />
                      <div class="bg-radio">3</div>
                    </label>
                    <label class="radio button button-outline">
                      <input :disabled="rform.readonly"
                        type="radio"
                        :name="'polos-circuito-'+proteccion.codigo+'-'+ic2"
                        value="4"
                        :checked="circuito.polos=='4'"
                      />
                      <div class="bg-radio">4</div>
                    </label>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="number"
                        class="width-100 display-flex"
                        :name="'seccion-'+proteccion.codigo+'-'+ic2"
                        :value="circuito.seccion"
                        placeholder="Sección"
                      />
                    </div>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="text"
                        class="width-100 display-flex"
                        :name="'identificacion-'+proteccion.codigo+'-'+ic2"
                        :value="circuito.identificacion"
                        placeholder="Identificación"
                      />
                    </div>
                  </div>
                  <div class="item-cell align-self-stretch">
                    <div class="input">
                      <input :disabled="rform.readonly"
                        type="text"
                        class="width-100 display-flex"
                        :name="'referencia-'+proteccion.codigo+'-'+ic2"
                        :value="circuito.referencia"
                        placeholder="Referencia"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </f7-list>
      </div>
    </div>
  </f7-tab>
</template>
<script>
import rInput from "./../../components/rInput.vue";
export default {
  components: {
    rInput
  },
  props: {
    rform: { type: Object, required: true },
    cuadroIdx: { required: true },
    indexTab: { required: true },
    cuadro: { required: true }
  },
  data() {
    return {
      selected: null,
      last_selected: null,
      copied_proteccion: null,
      copied_protecciones_jerarquia: null,
      tabEvent: null,
    };
  },
  methods: {
    onClickProteccion(e, i) {
      var self = this;
      var app = self.$f7;
      if (self.selected == i) return;
      if (self.selected != null && !self.rform.readonly)  {
        app.dialog.confirm(
          "¿Validar los cambios?",
          "Editar Protección",
          function() {
            self.validarProteccion();
            self.selected = i;
            self.last_selected = i;
            //self.$forceUpdate();
          },
          function() {}
        );
      } else {
        self.selected = i;
        self.last_selected = i;
        //self.$forceUpdate();
      }
    },
    onChangeProteccion(e, i) {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (e.target.name.startsWith("codigo")) {
        var input = e.target;
        var codigo_old = protecciones[i].codigo;
        var codigo_new = input.value;
        var error = "";
        if (codigo_new == codigo_old) return;
        if (!/^(\d+)(\.\d+)*$/.test(codigo_new)) {
          error = "Formato inválido";
        } else if (
          protecciones.some(function(el, j) {
            return i != j && el.codigo == input.value;
          })
        ) {
          error = "Ya existe una protección con ese código";
        } else if (codigo_new.startsWith(codigo_old)) {
          error = "No puedes asignar un código descendiente";
        }
        if (error) {
          app.toast
            .create({
              text: error,
              position: "center",
              closeTimeout: 2000
            })
            .open();
          input.value = codigo_old;
          return;
        }

        var protecciones_a_mover = [];
        var idx_a_eliminar = [];
        protecciones.forEach(function(el, j) {
          if (el.codigo.startsWith(codigo_old)) {
            protecciones_a_mover.push(app.utils.extend({}, el));
            idx_a_eliminar.push(j);
          }
        });
        for (var idxae = idx_a_eliminar.length - 1; idxae >= 0; idxae--)
          protecciones.splice(idx_a_eliminar[idxae], 1);

        var newPos = self.sortedIndex(
          protecciones,
          {
            codigo: codigo_new
          },
          self.sortFn
        );
        var j = newPos;
        protecciones_a_mover.forEach(function(el, i) {
          el.codigo = el.codigo.replace(codigo_old, codigo_new);
          protecciones.splice(j++, 0, el);
        });
        self.selected = newPos;
        self.last_selected = newPos;
        self.recalcularCodigos();
        //TODO: reproduccionVirtualList.scrollToItem(newPos);
        self.$forceUpdate();
      } else {
        var $card_selected = self.$$(e.target).closest(".card");
        var proteccionData = {
          codigo: $card_selected.find('input[name^="codigo"]').val(),
          tipo: $card_selected
            .find('input[name^="tipo"]:checked')
            .eq(0)
            .val(),
          polos: $card_selected
            .find('input[name^="polos-proteccion"]:checked')
            .val(),
          sensibilidad: $card_selected
            .find('input[name^="sensibilidad"]')
            .val(),
          intensidadNominal: $card_selected
            .find('input[name^="intensidadNominal"]')
            .val(),
          poderCorte: $card_selected.find('input[name^="poderCorte"]').val(),
          modulo: $card_selected.find('input[name^="modulo"]').val(),
          fila: $card_selected.find('input[name^="fila"]').val(),
          posicion: $card_selected.find('input[name^="posicion"]').val(),
          circuitos: []
        };
        $card_selected.find(".list.circuitos>.item-row").each(function(j, el) {
          var $row = self.$$(this);
          proteccionData.circuitos.push({
            aislamiento: $row.find('input[name^="aislamiento"]:checked').val(),
            material: $row.find('input[name^="material"]:checked').val(),
            tipo: $row.find('input[name^="tipo"]:checked').val(),
            sistemaMontaje: $row
              .find('input[name^="sistemaMontaje"]:checked')
              .val(),
            polos: $row.find('input[name^="polos-circuito"]:checked').val(),
            seccion: $row.find('input[name^="seccion"]').val(),
            identificacion: $row.find('input[name^="identificacion"]').val(),
            referencia: $row.find('input[name^="referencia"]').val()
          });
        });
        protecciones[i] = proteccionData;
        self.$forceUpdate();
      }
    },
    anadirProteccion() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;
      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        self.selected = 0;
        self.last_selected = 0;
      } 
      let codigo_nuevo = "1";
      if (protecciones.length) {
        var item = protecciones[self.selected];
        var codigo = item.codigo;
        var codigo_arr = codigo.split(".");
        var parte2 = parseInt(codigo_arr.pop());
        var parte1 = codigo_arr.join(".");
        var cod_max = 0;
        protecciones.forEach(function(el, i) {
          var codigo_arr2 = el.codigo.split(".");
          var parte22 = parseInt(codigo_arr2.pop());
          var parte12 = codigo_arr2.join(".");
          if (parte1 == parte12 && parte22 > cod_max) {
            cod_max = parte22;
          }
        });
        codigo_nuevo = (parte1 ? parte1 + "." : "") + (cod_max + 1);
      }
      var newItem = {
        codigo: codigo_nuevo,
        circuitos: [
          {
            aislamiento: "PVC",
            material: "Cu",
            tipo: "F"
          }
        ]
      };
      var newPos = self.sortedIndex(protecciones, newItem, self.sortFn);
      self.selected = newPos;
      self.last_selected = newPos;
      protecciones.splice(newPos, 0, newItem);
      self.$forceUpdate();
      //reproduccionVirtualList.scrollToItem(newPos); TODO
    },
    anadirSubproteccion(fromCopy) {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        app.toast
          .create({
            text: "Debe seleccionar una protección",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      } else {
        var item = protecciones[self.selected || 0];
        var codigo = item.codigo;
        var cod_max = 0;
        protecciones.forEach(function(el, i) {
          var codigo_arr2 = el.codigo.split(".");
          var parte22 = parseInt(codigo_arr2.pop());
          var parte12 = codigo_arr2.join(".");
          if (codigo == parte12 && parte22 > cod_max) {
            cod_max = parte22;
          }
        });
        var codigo_nuevo = (codigo ? codigo + "." : "") + (cod_max + 1);
      }
      var newItem = null;
      if (fromCopy === true) {
        newItem = app.utils.extend({}, self.copied_proteccion);
      } else {
        newItem = {
          circuitos: [
            {
              aislamiento: "PVC",
              material: "Cu",
              tipo: "F"
            }
          ]
        };
      }
      newItem.codigo = codigo_nuevo;
      var newPos = self.sortedIndex(protecciones, newItem, self.sortFn);
      self.selected = newPos;
      self.last_selected = newPos;
      if (fromCopy === true) {
        self.copied_proteccion = null;
        self.copied_protecciones_jerarquia = null;
      }
      protecciones.splice(newPos, 0, newItem);
      self.$forceUpdate();
    },
    anadirCircuito() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        app.toast
          .create({
            text: "Debe seleccionar una protección",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      } else {
        protecciones[self.selected].circuitos.push({
          aislamiento: "PVC",
          material: "Cu",
          tipo: "F"
        });
      }
      self.$forceUpdate();
    },
    copiarProteccion() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        app.toast
          .create({
            text: "Debe seleccionar una protección",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      } else {
        var item = protecciones[self.selected];
        var codigo = item.codigo;
        self.copied_protecciones_jerarquia = null;
        self.copied_proteccion = app.utils.extend({}, item);
        app.toast
          .create({
            text: "Protección " + codigo + " copiada",
            position: "center",
            closeTimeout: 2000
          })
          .open();
      }
    },
    copiarProteccionJerarquia() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        app.toast
          .create({
            text: "Debe seleccionar una protección",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      } else {
        var item = protecciones[self.selected];
        var codigo = item.codigo;
        self.copied_proteccion = null;
        self.copied_protecciones_jerarquia = [];
        protecciones.forEach(function(el, i) {
          if (el.codigo.startsWith(codigo)) {
            self.copied_protecciones_jerarquia.push(app.utils.extend({}, el));
          }
        });
        app.toast
          .create({
            text: "Protección " + codigo + " copiada con toda su jerarquía",
            position: "center",
            closeTimeout: 2000
          })
          .open();
      }
    },
    pegarComoSubproteccion() {
      var self = this;
      self.anadirSubproteccion(true);
    },
    pegarProteccion() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

        var item = protecciones[self.selected || 0];
        var codigo = item.codigo;
        var codigo_arr = codigo.split(".");
        var parte2 = parseInt(codigo_arr.pop());
        var parte1 = codigo_arr.join(".");
        var cod_max = 0;
        protecciones.forEach(function(el, i) {
          var codigo_arr2 = el.codigo.split(".");
          var parte22 = parseInt(codigo_arr2.pop());
          var parte12 = codigo_arr2.join(".");
          if (parte1 == parte12 && parte22 > cod_max) {
            cod_max = parte22;
          }
        });
        var codigo_nuevo = (parte1 ? parte1 + "." : "") + (cod_max + 1);
        if (self.copied_proteccion) {
          var newItem = app.utils.extend({}, self.copied_proteccion);
          newItem.codigo = codigo_nuevo;
          var newPos = self.sortedIndex(protecciones, newItem, self.sortFn);
          self.selected = newPos;
          self.last_selected = newPos;
          protecciones.splice(newPos, 0, newItem);
        } else if (self.copied_protecciones_jerarquia) {
          var codigo_old = self.copied_protecciones_jerarquia[0].codigo;
          var newPos = self.sortedIndex(
            protecciones,
            {
              codigo: codigo_nuevo
            },
            self.sortFn
          );
          var j = newPos;
          self.copied_protecciones_jerarquia.forEach(function(elo, i) {
            var el = app.utils.extend({}, elo);
            el.codigo = el.codigo.replace(codigo_old, codigo_nuevo);
            protecciones.splice(j++, 0, el);
          });
        }
        self.selected = newPos;
        self.last_selected = newPos;
        self.copied_proteccion = null;
        self.copied_protecciones_jerarquia = null;
        //TODO: reproduccionVirtualList.scrollToItem(newPos);
      // }
    },
    eliminarProteccion() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      if (self.selected == null) self.selected = self.last_selected;
      if (self.selected == null) {
        app.toast
          .create({
            text: "Debe seleccionar una protección",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      } else {
        var item = protecciones[self.selected];
        var codigo = item.codigo;
        app.dialog.confirm(
          "¿Está seguro? Se eliminarán todos sus descendientes",
          "Eliminar protección",
          function() {
            var idxDelete = [];
            protecciones.forEach(function(el, i) {
              if (el.codigo.startsWith(codigo)) idxDelete.push(i);
            });
            for (var idxae = idxDelete.length - 1; idxae >= 0; idxae--)
              protecciones.splice(idxDelete[idxae], 1);
            self.recalcularCodigos();
            self.selected = null;
            self.last_selected = null;
          }
        );
      }
    },
    validarProteccion() {
      var self = this;
      self.selected = null;
    },
    generarMedidas() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;

      var items_protecciones_a_medidas = [];
      protecciones.forEach(function(el, i) {
        if (el.tipo == "VIGI" || el.tipo == "ID") {
          items_protecciones_a_medidas.push({
            codigo: el.circuitos[0].identificacion || "",
            modulo: el.modulo || "",
            fila: el.fila || "",
            posicion: el.posicion || "",
            vi: "",
            vt: "",
            vc: ""
          });
        }
      });
      if (!items_protecciones_a_medidas.length) {
        app.toast
          .create({
            text: "No existen medidas a importar",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        return;
      }

      if (!self.cuadro.medidas) {
        self.cuadro.medidas = [];
      }
      var medidas = self.cuadro.medidas;

      var idx_medidas_a_eliminar = [];
      medidas.forEach(function(el, i) {
        if (
          !el.manual &&
          !items_protecciones_a_medidas.find(
            m => {
              el.modulo == m.modulo &&
              el.fila == m.fila &&
              el.posicion == m.posicion
            }
          )
        ) {
          idx_medidas_a_eliminar.push(i);
        }
      });
      if (idx_medidas_a_eliminar.length) {
        app.dialog.confirm(
          "Se van a reemplazar medidas que contienen datos. ¿Desea continuar?",
          "Importar medidas",
          function() {
            //Eliminar medidas generadas automáticamente
            for (
              let idxae = idx_medidas_a_eliminar.length - 1;
              idxae >= 0;
              idxae--
            )
              medidas.splice(idx_medidas_a_eliminar[idxae], 1);
            //Añadir las nuevas medidas
            for (
              let idxai = 0;
              idxai < items_protecciones_a_medidas.length;
              idxai++
            ) {
              let el = items_protecciones_a_medidas[idxai];
              if (
                !medidas.find(
                  m => {
                    el.modulo == m.modulo &&
                    el.fila == m.fila &&
                    el.posicion == m.posicion
                  }
                )
              ) {
                medidas.push(items_protecciones_a_medidas[idxai]);
              }
            }
            medidas.sort(function(a, b) {
              let mod = (parseInt(a.modulo) || 0) - (parseInt(b.modulo) || 0);
              let fil = (parseInt(a.fila) || 0) - (parseInt(b.fila) || 0);
              let pos =
                (parseInt(a.posicion) || 0) - (parseInt(b.posicion) || 0);
              return mod != 0 ? mod : fil != 0 ? fil : pos != 0 ? pos : 0;
            });
            app.toast
              .create({
                text: "Medidas importadas",
                position: "center",
                closeTimeout: 2000
              })
              .open();
            self.$forceUpdate();
          }
        );
      } else {
        //Añadir las nuevas medidas
        for (
          let idxai = 0;
          idxai < items_protecciones_a_medidas.length;
          idxai++
        ) {
          let el = items_protecciones_a_medidas[idxai];
          if (
            !medidas.find(
              m => {
                el.modulo == m.modulo &&
                el.fila == m.fila &&
                el.posicion == m.posicion
              }
            )
          ) {
            medidas.push(items_protecciones_a_medidas[idxai]);
          }
        }
        medidas.sort(function(a, b) {
          let mod = (parseInt(a.modulo) || 0) - (parseInt(b.modulo) || 0);
          let fil = (parseInt(a.fila) || 0) - (parseInt(b.fila) || 0);
          let pos = (parseInt(a.posicion) || 0) - (parseInt(b.posicion) || 0);
          return mod != 0 ? mod : fil != 0 ? fil : pos != 0 ? pos : 0;
        });
        app.toast
          .create({
            text: "Medidas importadas",
            position: "center",
            closeTimeout: 2000
          })
          .open();
        self.$forceUpdate();
      }
    },

    recalcularCodigos() {
      var self = this;
      var app = self.$f7;
      if (!self.cuadro.protecciones) self.cuadro.protecciones = [];
      var protecciones = self.cuadro.protecciones;
      var j = [0];
      for (let i in protecciones) {
        let el = protecciones[i];
        var depth = el.codigo.split(".").length;
        if (depth == j.length) {
          j[depth - 1]++;
        } else if (depth > j.length) {
          var aux = depth - j.length;
          while (aux--) {
            j.push(1);
          }
        } else if (depth < j.length) {
          j.splice(depth);
          j[depth - 1]++;
        }
        el.codigo = j.join(".");
      }
      self.$forceUpdate();
    },

    sortFn: function(a, b) {
      var codA = a.codigo.split(".");
      var codB = b.codigo.split(".");
      var minLength = Math.min(codA.length, codB.length);
      var res = 0;
      for (var i = 0; i < minLength; i++) {
        if (parseInt(codA[i]) < parseInt(codB[i])) {
          res = 1;
          break;
        } else if (parseInt(codA[i]) > parseInt(codB[i])) {
          res = 0;
          break;
        } else {
          if (i == minLength - 1 && codB.length > codA.length) {
            res = 1;
            break;
          }
          continue;
        }
      }
      return res;
    },
    sortedIndex: function(array, value, fun) {
      if (typeof fun != "function")
        fun = function(a, b) {
          return a < b;
        };
      var low = 0,
        high = array.length;

      while (low < high) {
        var mid = (low + high) >>> 1;
        if (fun.call(undefined, array[mid], value)) low = mid + 1;
        else high = mid;
      }
      return low;
    }
  }
};
</script>
<style>
.reproduccion .item-row > .item-cell {
  padding: 5px 10px;
}

.reproduccion .item-row > .item-cell:not(:last-child) {
  border-right: 1px solid #ddd;
}

.reproduccion .card-header {
  background: #dbe58a;
  padding: 0;
  min-height: auto;
}

.reproduccion .card.selected-to-paste .card-header {
  background: #c6cb9b;
}

.reproduccion .cuadro-selected > .resumen {
  display: none;
}

.reproduccion :not(.cuadro-selected) > .extendido {
  display: none;
}

.reproduccion .radio.button {
  height: 28px;
  line-height: 28px;
  width: 100%;
}

.reproduccion .radio input[type="radio"]:checked ~ .bg-radio {
  background: #23355b;
  color: white;
}

.reproduccion .radio input[type="radio"] ~ .bg-radio {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: -1;
}

.reproduccion .list .item-cell {
  margin: 0;
}

.reproduccion .card-content .item-row:not(:last-child) {
  position: relative;
}

.reproduccion .card-content .item-row:after {
  content: "";
  position: absolute;
  background-color: #e1e1e1;
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
</style>