<template>
  <r-form ref="rform" class="page" classContent="page-content full-height" :itemId="id" @save="onSave"
    :readonly="readonly" :dbAdapter="Servicio"
    :defaultData="{precios:[], tasas:[], subservicios:[], formatos:[], exclusiones:[], textosDocumentacion: []}">
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="id && !readonly" sliding>{{$t('servicios.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="id" sliding>{{$t('servicios.servicio')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('servicios.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button v-if="!readonly" fill icon-f7="floppy_disk" text="Guardar" class="display-flex"
            @click="rform.saveItem()"></f7-button>
          <template slot="right" v-else>
            <f7-button color="blue" @click="readonly=false">Editar</f7-button>
            <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
          </template>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000;">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <r-input class="col-100 medium-33" :form="rform" type="check" :name="'activo'" label="Activo"></r-input>
            <r-input class="col-100 medium-33" :form="rform" type="check" :name="'activoSAP'"
              label="Activo SAP"></r-input>
            <r-input class="col-100 medium-33" floating-label :label="$t('servicios.codigo')" :form="rform" type="text"
              name="codigo"></r-input>
            <r-input class="col-100 medium-33" floating-label :label="$t( 'servicios.userText' )" :form="rform"
              type="text" name="userText"></r-input>
            <r-input class="col-100 medium-33" floating-label :label="$t('servicios.nombre')" :form="rform" type="text"
              name="nombre"></r-input>
            <r-autocomplete class="col-100 medium-33" :dbAdapter="Materia" floating-label
              :label="$t('servicios.materia')" :form="rform" name="materia"
              :shownFields="['codigo', 'codigoExpediente']" labelProp="nombre" prefixProp="codigo"></r-autocomplete>
            <r-input class="col-100 medium-33" floating-label :label="$t('servicios.tiempoCampo')" :form="rform"
              type="number" step=".1" name="tiempoEstimadoCampo"></r-input>
            <r-input class="col-100 medium-33" floating-label :label="$t('servicios.tiempoOficina')" :form="rform"
              type="number" step=".1" name="tiempoEstimadoOficina"></r-input>
            <r-input class="col-100 medium-33" floating-label :label="$t('servicios.codCuentaMayor')" :form="rform"
              name="codCuentaMayor"></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>



      <pre style="display:none;">{{rform.formData}}</pre>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 margin-horizontal">
          <f7-button fill tab-link="#tab-precios" tab-link-active>Precios</f7-button>
          <f7-button fill tab-link="#tab-tasas">Tasas</f7-button>
          <f7-button fill tab-link="#tab-subservicios">Subservicios</f7-button>
          <f7-button fill tab-link="#tab-formatos">Formatos</f7-button>
          <f7-button fill tab-link="#tab-exclusiones">Exclusiones</f7-button>
          <f7-button fill tab-link="#tab-textos" @click="initTextos">Textos</f7-button>
          <f7-button fill tab-link="#tab-textosDocumentacion">Documentación</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-precios" tab-active>
          <!-- Precios -->
          <f7-block-title v-if="rform && rform.formData" style="z-index: 20">
            {{$t('servicios.precios')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.precios.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.precios" :key="'precio-'+index"
              :style="'z-index:'+(rform.formData.precios.length - index)">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-autocomplete class="col-33" :dbAdapter="ListaPrecio" floating-label
                        :label="$t('servicios.listaPrecios')" :form="rform" :name="'precios.' + index + '.listaPrecios'"
                        idProp="codigo" idFieldDetail="id" labelProp="nombre" :source="function(self) {
                          return function(query, render) {
                            var results = [];
                            for (var i = 0; i < self.items.length; i++) {
                              if (( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && rform.formData.precios.filter(t => t.listaPrecios == self.items[i][self.idProp]).length <= 0)
                        self.items[i]['txtValue'] = self.items[i][self.searchProp];
                                results.push(self.items[i]);
                            }
                            render(results);
                          };
                        }"></r-autocomplete>
                      <r-input class="col-33" floating-label :label="$t('servicios.precio')" :form="rform"
                        :name="'precios.' + index + '.precioUnidad'" type="number" step=".01"></r-input>
                      <r-input class="col-33" floating-label :label="$t('servicios.moneda')" :form="rform"
                        :name="'precios.' + index + '.moneda'" type="select">
                        <option selected value="EUR">Euro</option>
                      </r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link v-if="!rform.readonly && rform.formData" @click="$delete(rform.formData.precios,index);"
                class="margin-half" style="position:absolute;right:0;top:0;" icon-f7="trash"></f7-link>
            </f7-card>
          </div>
          <f7-block-title v-if="rform && rform.formData" style="z-index: 20">
            {{$t('servicios.precios')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.precios.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>
        <f7-tab id="tab-formatos">
          <!-- Formatos -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form" style="z-index: 40">
                <ul>
                  <f7-row>
                    <r-autocomplete-m2m :dbAdapter="Formato" class="col-100" floating-label
                      :label="$t('servicios.formato')" :form="rform" name="formatos" labelProp="nombre" type="textarea"
                      prefixProp="codigo" :lineBreak="true" idProp="codigo" :disabled="readonly"></r-autocomplete-m2m>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>

        <f7-tab id="tab-exclusiones">
          <!-- Exclusiones -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form" style="z-index: 40">
                <ul>
                  <f7-row>
                    <r-autocomplete-m2m :dbAdapter="Servicio" outline class="col-100" floating-label
                      :label="$t('servicios.exclusiones')" :form="rform" name="serviciosExcluidos" labelProp="nombre"
                      type="textarea" prefixProp="codigo" :lineBreak="true" idProp="codigo"
                      :disabled="readonly"></r-autocomplete-m2m>
                  </f7-row>
                  <f7-row>
                    <r-input outline class="col-25" floating-label :label="$t('servicios.vigencia')" :form="rform"
                      name="vigencia" type="number" step="1"></r-input>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>


        <f7-tab id="tab-tasas">
          <!-- Tasas -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <f7-row class="justify-content-start">
                  <r-input floating-label class="col-25" :label="$t('servicios.tasaDefecto')" :form="rform"
                    type="number" step=".01" name="tasaDefecto"></r-input>
                  <r-input floating-label class="col-25" :label="$t('servicios.tasaDefecto2')" :form="rform"
                    type="number" step=".01" name="tasaDefecto2as"></r-input>
                </f7-row>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.tasas')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.tasas.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.tasas" :key="'tasa-'+index"
              :style="'z-index:'+(rform.formData.tasas.length - index)">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input class="col-25" floating-label :label="$t('servicios.nombre')" :form="rform"
                        :name="'tasas.' + index + '.nombre'"></r-input>
                      <r-autocomplete class="col-25" :dbAdapter="Comunidad" floating-label
                        :label="$t('servicios.comunidad')" :form="rform" :name="'tasas.' + index + '.comunidad'"
                        idProp="codigo" idFieldDetail="id" labelProp="nombre"></r-autocomplete>
                      <r-input class="col-25" floating-label :label="$t('servicios.valor')" :form="rform"
                        :name="'tasas.' + index + '.valor'" type="number" step=".01"></r-input>
                      <r-input class="col-25" floating-label :label="$t('servicios.valor2as')" :form="rform"
                        :name="'tasas.' + index + '.valor2as'" type="number" step=".01"></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link v-if="!rform.readonly && rform.formData" @click="$delete(rform.formData.tasas,index);"
                class="margin-half" style="position:absolute;right:0;top:0;" icon-f7="trash"></f7-link>
            </f7-card>
          </div>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.tasas')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.tasas.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>

        <f7-tab id="tab-subservicios">
          <!-- Subservicios -->
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.subservicios')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.subservicios.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.subservicios" :key="'subservicios-'+index"
              :style="'z-index:'+(rform.formData.subservicios.length - index)">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input class="col-25" floating-label :label="$t('servicios.nombre')" :form="rform"
                        :name="'subservicios.' + index + '.nombre'"></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link v-if="!rform.readonly && rform.formData" @click="$delete(rform.formData.subservicios,index);"
                class="margin-half" style="position:absolute;right:0;top:0;" icon-f7="trash"></f7-link>
            </f7-card>
          </div>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.subservicios')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.subservicios.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>




        <f7-tab id="tab-textos">
          <!-- Textos -->
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="anadirTexto({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
          <div v-if="rform && rform.formData" style="z-index: 30">

            <Container @drop="onDrop(rform.formData.textos, $event)" drag-handle-selector=".column-drag-handle"
              lock-axis="y">
              <Draggable v-for="(item,index) in rform.formData.textos" :key="'texto-'+index">

                <f7-card class="elevation-3 padding-vertical-half padding-right">
                  <f7-card-content>
                    <f7-list class="list-form">
                      <ul>
                        <f7-row no-gap>
                          <span v-if="!readonly" class="column-drag-handle margin-left cursor-move col-5"
                            v-tooltip="$t('common.move')">&uarr;&darr;</span>
                          <r-input class="col-15" :label="$t('textos.forzarPaginaNueva')" :form="rform" type="check" 
                            :name="'textos.' + index + '.propiedades.forzarPaginaNueva'"></r-input>
                          <r-input class="col-15" :label="$t('textos.alInicio')" :form="rform" type="check" 
                            :name="'textos.' + index + '.propiedades.alInicio'"></r-input>
                          <r-input class="col-10" floating-label :label="$t('servicios.orden')" :form="rform" type="number" step="1"
                            :name="'textos.' + index + '.orden'"></r-input>
                          <r-input class="col-10" :label="$t('servicios.obligatorio')" :form="rform" type="check"
                            :name="'textos.' + index + '.obligatorio'"></r-input>
                          <r-input class="col-10" :label="$t('servicios.sololectura')" :form="rform" type="check"
                            :name="'textos.' + index + '.soloLectura'"></r-input>
                          <r-input class="col-100" floating-label :label="$t('servicios.titulo')" :form="rform"
                              :name="'textos.' + index + '.nombre'"></r-input>
                        </f7-row>
                        <f7-row>
                          <r-rich-text-input class="col-100" floating-label :label="$t('servicios.html')" :form="rform"
                            :name="'textos.' + index + '.html'"></r-rich-text-input>
                        </f7-row>
                      </ul>
                    </f7-list>
                  </f7-card-content>
                  <f7-link v-if="rform.readonly && rform.formData" @click="popupExportarTextoOpened = true; texto = item;" class="margin-half" style="position:absolute;right:0px;top:0;" icon-f7="scope" tooltip="Exportar texto"></f7-link>
                  <f7-link v-if="!rform.readonly && rform.formData" @click="$delete(rform.formData.textos,index);" class="margin-half" style="position:absolute;right:0;top:0;" icon-f7="trash"></f7-link>
                </f7-card>


              </Draggable>
            </Container>



          </div>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.textos')}}
            <f7-link v-if="!rform.readonly && rform.formData" @click="anadirTexto({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>

        <f7-tab id="tab-textosDocumentacion">
          <!-- Textos Documentación -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <f7-row>
                  <r-rich-text-input class="col-100" floating-label :label="$t('servicios.textoDocumentacionDefecto')"
                    :form="rform" name="textoDocumentacionDefecto"></r-rich-text-input>
                </f7-row>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.textosDocumentacion')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.textosDocumentacion.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.textosDocumentacion" :key="'textosDocumentacion-'+index"
              :style="'z-index:'+(rform.formData.textosDocumentacion.length - index)">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input class="col-33" floating-label :label="$t('servicios.nombre')" :form="rform"
                        :name="'textosDocumentacion.' + index + '.nombre'"></r-input>
                      <r-autocomplete class="col-33" :dbAdapter="Comunidad" :showNavigationLink="false" floating-label
                        :label="$t('servicios.comunidad')" :form="rform"
                        :name="'textosDocumentacion.' + index + '.comunidad'" idProp="codigo" idFieldDetail="id"
                        labelProp="nombre" :source="function(self) {
                          return function(query, render) {
                            var results = [];
                            for (var i = 0; i < self.items.length; i++) {
                              if (( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && rform.formData.tasas.filter(t => t.comunidad == self.items[i][self.idProp]).length <= 0) {
                                self.items[i]['txtValue'] = self.items[i][self.searchProp];
                                results.push(self.items[i]);
                              }
                            }
                            render(results);
                          };
                        }"></r-autocomplete>
                      <r-rich-text-input class="col-100" floating-label :label="$t('servicios.textoDocumentacion')"
                        :form="rform" :name="'textosDocumentacion.' + index + '.texto'"></r-rich-text-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.textosDocumentacion,index);" class="margin-half"
                style="position:absolute;right:0;top:0;" icon-f7="trash"></f7-link>
            </f7-card>
          </div>
          <f7-block-title v-if="rform && rform.formData">
            {{$t('servicios.textosDocumentacion')}}
            <f7-link v-if="!rform.readonly && rform.formData"
              @click="rform.formData.textosDocumentacion.push({})">{{$t('servicios.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>





      </f7-tabs>
      <!-- Popup de exportación de textos -->
      <f7-popup ref="popupExportarTexto" class="popup-exportacion-textos" swipe-to-close :opened="true" v-if="popupExportarTextoOpened" @popup:closed="popupExportarTextoOpened = false; texto = null;" >
        <f7-page>
          <f7-navbar>
            <f7-nav-left>
              <f7-link @click="exportarTexto" style="color: greenyellow;">{{ $t('servicios.exportarTexto.exportar') }}</f7-link>
              <f7-link @click="exportarTextoEliminar" style="color: #ffabbd;">{{ $t('servicios.exportarTexto.eliminar') }}</f7-link>
            </f7-nav-left>
            <f7-nav-title>{{ $t('servicios.exportarTexto.title') }}</f7-nav-title>
            <f7-nav-right>
              <f7-link popup-close>{{ $t('common.cerrar') }}</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <r-form ref="rformExportacionTexto" :dbAdapter="null" classContent="full-height" :readonly="false" :defaultData="texto">
            <template v-slot:default="{ rform }">
              <f7-card class="elevation-3 padding-vertical-half padding-right">
                <f7-card-content>
                  <f7-list class="list-form">
                    <ul>
                      <f7-row>
                        <f7-col width="100" medium="100">
                          <r-autocomplete-m2m
                            outline
                            @loaded="prepararPopupExportacion"
                            :dbAdapter="Servicio"
                            class="col-100 medium-50"
                            floating-label
                            :label="$t('servicios.exportarTexto.servicios')"
                            :form="rform"
                            name="servicios"
                            labelProp="nombre"
                            type="textarea"
                            prefixProp="codigo"
                            :filter ="filtro"
                            :lineBreak="true"
                            :viewTooltip="false"
                            @change="setServiciosSeleccionados"
                          ></r-autocomplete-m2m>
                        </f7-col>
                      </f7-row>
                    </ul>
                  </f7-list>
                </f7-card-content>
              </f7-card>
            </template>
          </r-form>
        </f7-page>
      </f7-popup>
      <!-- FIN Popup de exportación de textos -->
    </template>
  </r-form>




</template>
<style scoped>
  .justify-content-start {
    justify-content: start !important;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rDireccion from "./../../components/rDireccion.vue";
import Servicio from "./../../js/db/Servicio.js";
import Delegacion from "./../../js/db/Delegacion.js";
import ListaPrecio from "./../../js/db/ListaPrecio.js";
import Comunidad from "./../../js/db/Comunidad.js";
import Materia from "./../../js/db/Materia.js";
import Formato from "./../../js/db/Formato.js";
import { Container, Draggable } from "vue-smooth-dnd";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  //return result;
};

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rDireccion,
    rAutocomplete,
    rAutocompleteM2m,
    rRichTextInput,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Servicio,
      Delegacion,
      ListaPrecio,
      Comunidad,
      Materia,
      Formato,
      readonly: !this.editable,
      texto: null,
      popupExportarTextoOpened: false,
      filtro: {activo: true},
      serviciosSeleccionados: {}
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Servicio.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("servicios.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    initTextos() {
      var self = this;
      if (!self.$refs.rform.formData.textos || self.$refs.rform.formData.textos == null) {
        self.$set(self.$refs.rform.formData, "textos", []);
        self.$refs.rform.formData.textos.push({nombre: "OBJETO",obligatorio: true,soloLectura: false,html: "DEFINIR"});
        self.$refs.rform.formData.textos.push({nombre: "ALCANCE",obligatorio: true,soloLectura: true,html: "DEFINIR"});
        self.$refs.rform.formData.textos.push({nombre: "Preparación del Item",obligatorio: false,soloLectura: false,html: "DEFINIR"});
        self.$refs.rform.formData.textos.push({nombre: "PRESUPUESTO",obligatorio: true,soloLectura: true,html: ""});
      }
    },
    anadirTexto(texto) {
      var self = this;
      if (!self.$refs.rform.formData.textos || self.$refs.rform.formData.textos == null) {
        self.$set(self.$refs.rform.formData, "textos", []);
      }
      self.$refs.rform.formData.textos.push(texto);
    },
    setServiciosSeleccionados() {
      var self = this;
      self.serviciosSeleccionados = self.$refs.rformExportacionTexto.formData.servicios.map(s => s.codigo);
      self.texto.propiedades=(self.texto.propiedades||{});
      self.texto.propiedades.serviciosSeleccionados = self.serviciosSeleccionados;
    },
    prepararPopupExportacion(listaServicios) {
      var self = this;
      // crear array de servicios seleccionados conteniendo el campo código de los servicios seleccionados que haya en el campo propiedades.serviciosSeleccionados del texto
      self.serviciosSeleccionados = [];
      if (self.texto && self.texto.propiedades && self.texto.propiedades.serviciosSeleccionados) {
        self.serviciosSeleccionados = self.texto.propiedades.serviciosSeleccionados;
        if(self.serviciosSeleccionados.length > 0) {
          // dejamos en el campo self.$refs.rformExportacionTexto.formData.servicios solo los que tengan los códigos de self.texto.propiedades.serviciosSeleccionados
          self.$set(self.$refs.rformExportacionTexto.formData,'servicios',listaServicios.filter(s => self.serviciosSeleccionados.includes(s.codigo)));
        }
      }
    },
    exportarTexto() {
      var self = this;
      var app = self.$f7;
      // abriremos el popup de búsqueda y selección de  servicios a los que exportar el texto
      let servicios = self.$refs.rformExportacionTexto.formData.servicios;
      if (!servicios || servicios.length == 0) {
        app.dialog.alert(self.$t("servicios.exportarTexto.errorServicios"));
        self.$refs.popupExportarTexto.close();
        return;
      }
      // Añadimos el servicio actual a la lista de servicios a exportar
      let servicioActual = self.$refs.rform.formData;
      servicios.push({id: servicioActual.id, codigo: servicioActual.codigo, nombre: servicioActual.nombre});
      // Vamos a mostrar un diálogo para preguntar si queremos exportar el texto con nombre "nombre" a los servicios seleccionados
      app.dialog.confirm(
        self.$t("servicios.exportarTexto.confirmacion"),
        self.$t("servicios.exportarTexto.confirmar"),
        function() {
          let serviciosNoActualizados = [];
          const textoExportar = self.texto;
          // Si confirmamos, vamos a exportar el texto a los servicios seleccionados
          servicios.forEach(s => {
            // Buscamos el servicio
            Servicio.get({itemId: s.id,data: s}).then(function(res) {
              // Vamos a añadir el texto al servicio
              if (!res.textos) res.textos = [];
              console.log("Servicio TEXTOS: " +s.id);
              // si ya hay un texto con el mismo nombre en el servicio, lo actualizamos
              let idx = res.textos.findIndex(t => t.nombre == textoExportar.nombre);
              if (idx >= 0) {
                res.textos[idx] = textoExportar;
              } else {
                res.textos.push(textoExportar);
              }
              // Ordenamos por el campo orden 
              res.textos = res.textos.sort((a, b) => ((a.orden || 0) >= (b.orden || 0) ) ? 1 : -1);
              
              // Vamos a guardar el servicio
              Servicio.save({itemId: res.id,data: res}).then(function(saved) {
                if (saved) {
                  app.toast
                    .create({
                      icon: '<i class="f7-icons">checkmark</i>',
                      text: self.$t("servicios.exportarTexto.exportado"),
                      position: "center",
                      destroyOnClose: true,
                      closeTimeout: 2000
                    }).open();
                }
              }).catch(function(error){
                console.log("SERVICIO: " + res.codigo + " - ERROR: " + error.message);
                serviciosNoActualizados.push(res.codigo);
                // Mostramos el error en un toast rojo
                app.dialog.alert("SERVICIO: " + res.codigo + " - ERROR: " + error.message);
              }).then(function(){
                if(self.$refs.popupExportarTexto) { self.$refs.popupExportarTexto.close(); }
              });
            }).catch(function(error){
              console.log("ERROR2: " + error.message);
              app.dialog.alert(error.message);
            });
          });
          if(serviciosNoActualizados.length > 0) {
            app.dialog.alert("NO ACTUALIZADOS: " + serviciosNoActualizados.join(", "));
          }
        }
      );
    },
    exportarTextoEliminar() {
      var self = this;
      var app = self.$f7;
      // abriremos el popup de búsqueda y selección de  servicios a los que exportar el texto
      let servicios = self.$refs.rformExportacionTexto.formData.servicios;
      if (!servicios || servicios.length == 0) {
        app.dialog.alert(self.$t("servicios.exportarTexto.errorServicios"));
        self.$refs.popupExportarTexto.close();
        return;
      }
      // Vamos a mostrar un diálogo para preguntar si queremos exportar el texto con nombre "nombre" a los servicios seleccionados
      app.dialog.confirm(
        self.$t("servicios.exportarTexto.confirmacionEliminar"),
        self.$t("servicios.exportarTexto.confirmarEliminar"),
        function() {
          let serviciosNoActualizados = [];
          const textoExportar = self.texto;
          // Si confirmamos, vamos a eliminar el texto a los servicios seleccionados
          servicios.forEach(s => {
            // Buscamos el servicio
            Servicio.get({itemId: s.id,data: s}).then(function(res) {
              // Vamos a eliminar el texto al servicio
              if (!res.textos) return;
              // si ya hay un texto con el mismo nombre en el servicio, lo actualizamos
              let idx = res.textos.findIndex(t => t.nombre == textoExportar.nombre);
              if (idx >= 0) {
                res.textos.splice(idx, 1);
              } else {
                return;
              }
              // Vamos a guardar el servicio
              Servicio.save({itemId: res.id,data: res}).then(function(saved) {
                if (saved) {
                  app.toast
                    .create({
                      icon: '<i class="f7-icons">cursor_rays</i>',
                      text: self.$t("servicios.exportarTexto.eliminado"),
                      position: "center",
                      destroyOnClose: true,
                      closeTimeout: 2000
                    }).open();
                }
              }).catch(function(error){
                console.log("SERVICIO: " + res.codigo + " - ERROR: " + error.message);
                serviciosNoActualizados.push(res.codigo);
                // Mostramos el error en un toast rojo
                app.dialog.alert("SERVICIO: " + res.codigo + " - ERROR: " + error.message);
              }).then(function(){
                if(self.$refs.popupExportarTexto) { self.$refs.popupExportarTexto.close(); }
              });
            }).catch(function(error){
              console.log("ERROR2: " + error.message);
              app.dialog.alert(error.message);
            });
          });
        }
      );
    },

    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult);
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>