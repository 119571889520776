import Cualificaciones from './cualificaciones.vue';
import CualificacionesForm from './cualificaciones-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/cualificaciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('cualificaciones', [], Cualificaciones),
        keepAlive: true
    },
    {
        path: '/cualificaciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('cualificaciones/new', [], CualificacionesForm)
    },
    {
        path: '/cualificaciones/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('cualificaciones/edit', [], CualificacionesForm)
    },
    {
        path: '/cualificaciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('cualificaciones/view', [], CualificacionesForm, { editable: false })
    },
];
export default routes;