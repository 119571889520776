import GrupoClientes from './gruposcliente.vue';
import GrupoClienteForm from './gruposcliente-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/gruposcliente/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposcliente', ['ADMIN', 'INSPECTOR'], GrupoClientes),
        keepAlive: true
    },
    {
        path: '/gruposcliente/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposcliente/new', ['ADMIN', 'INSPECTOR'], GrupoClienteForm)
    },
    {
        path: '/gruposcliente/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposcliente/edit', ['ADMIN', 'INSPECTOR'], GrupoClienteForm)
    },
    {
        path: '/gruposcliente/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposcliente/view', ['ADMIN', 'INSPECTOR'], GrupoClienteForm, { editable: false })
    },
];
export default routes;