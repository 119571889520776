<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title>{{$t('notificaciones.notificaciones')}}</f7-nav-title>
    
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="wind_snow"
          text="Enviar"
          class="display-flex"
          @click="sendNotificacion()"
        ></f7-button>
        
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      :readonly="readonly"
      :dbAdapter="Notificacion"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
                <r-input
                    class="col-25"
                    :label="$t('notificaciones.enviarATodos')"
                    :form="rform"
                    type="check"
                    name="enviarATodos"
                    @change="onChangeTopic()"
                ></r-input>
                <r-autocomplete
                 class="col-20" 
                 :dbAdapter="UserNotificacion" 
                 :label="$t('notificaciones.usuario')"
                 name="username" 
                 :form="rform" 
                 labelProp="username" 
                 idProp="username" 
                 idFieldDetail="username" 
                 :showNavigationLink="false"
                 :readonly="rform.formData.enviarATodos">
                 </r-autocomplete>

                <r-input
                  floating-label
                  :label="$t('notificaciones.titulo')"
                  :form="rform"
                  type="text"
                  name="title"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('notificaciones.mensaje')"
                  :form="rform"
                  type="text"
                  name="message"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('notificaciones.urlInterna')"
                  :form="rform"
                  type="text"
                  name="data.redirectUrl"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('notificaciones.urlExterna')"
                  :form="rform"
                  type="text"
                  name="data.redirectExternalUrl"
                ></r-input>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Notificacion from "./../../js/db/Notificacion.js";
import UserNotificacion from "./../../js/db/UserNotificacion.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Notificacion,
      UserNotificacion,
      readonly: !this.editable
      
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {

    onChangeTopic: function() {

        var self = this;
        var notificacion = self.$refs.rform.formData;
        if (notificacion.enviarATodos) {
          notificacion.username = null;
          notificacion.topic = 'all';
        }

    },

    sendNotificacion: function() {
      var self = this;
      var app = self.$f7;
      var notificacion = self.$refs.rform.formData;
      if (!notificacion.title || !notificacion.message) {
          app.dialog.alert("La notificación debe tener título y mensaje");
          return;
      }
      Notificacion.enviar({data: notificacion})
      .then((noti) => {
          app.toast
          .create({
            text: "Notificación enviada",
            position: "center",
            closeTimeout: 2000
          }).open();
      })
      .catch(function(e){
              if (typeof e == "string") e = { error: e };
              let error = e.message || e.error || e.error_description;
              app.dialog.alert(error);
              console.log(error);
            });
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>