import { EmbedBlot } from 'parchment';
import Quill from 'quill';

const Image = Quill.import('formats/image');

class CustomImage extends Image {
  static create(value, quill) {
    console.log("Custom image", quill);
    const node = super.create(value);
    node.setAttribute('style', 'max-width: 100%;')
    let isTd = false;
    if (window.getSelection() && window.getSelection().baseNode) {
      let node = window.getSelection().baseNode;
      while (node) {
        if (node.tagName && node.tagName.toUpperCase() == 'TD') {
          isTd = true;
        }
        node = node.parentElement;
      }
    }
    if (isTd) {
      node.setAttribute('style', 'width: 100%;')
    }
    window.getSelection().baseNode.parentElement
    return node;
  }

}

export default CustomImage;
