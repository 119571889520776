<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('usuarios.usuarios.usuarios')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Usuario"
        sort="username"
        sortDir="ASC"
        :tableMode="true"
        :virtualListParams="{height:32}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :infiniteScroll="true"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <div class="data-table-links">
                <a class="link" href="/usuarios/new/">Nuevo</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.reload()" icon-f7="arrow_counterclockwise"></f7-link>
                <!-- <f7-link small @click="rlist.exportExcel('Usuarios.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link> -->
                <!-- <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link> -->
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th>{{$t('usuarios.usuario_form.username')}}</th>
              <th>{{$t('usuarios.usuario_form.nombre')}}</th>
              <th>{{$t('usuarios.usuario_form.apellidos')}}</th>
              <th>{{$t('usuarios.usuario_form.telefono')}}</th>
              <th>{{$t('usuarios.usuario_form.email')}}</th>
              <th>{{$t('usuarios.usuario_form.iniciales')}}</th>
              <th>{{$t('usuarios.usuario_form.roles')}}</th>
              <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
                <f7-icon f7="gear_alt_fill"></f7-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item) in vlData.items" :key="'usuario-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td class="label-cell">{{item.username}}</td>
            <td class="label-cell">{{item.nombre}}</td>
            <td class="label-cell">{{item.apellidos}}</td>
            <td class>{{item.telefono}}</td>
            <td class>{{item.email}}</td>
            <td class>{{item.iniciales}}</td>
            <td class>{{item.application && item.application.roles ? item.application.roles.join(',') : ''}}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="eye" color="green" :href="`/usuarios/view/${item.id}/`" title="Ver usuario"></f7-link>
              <f7-link 
                icon-f7="square_pencil" 
                color="blue" 
                :href="`/usuarios/edit/${item.id}/`"
                title="Editar usuario"
              ></f7-link>
              <f7-link icon-f7="trash" color="red" @click="rlist.deleteItem(item.id)" title="Borrar usuario"></f7-link>
              <f7-link icon-f7="lock" color="orange" @click="resetPass(item.id)" title="Resetear contraseña"></f7-link>
              <!-- <f7-link v-if="item.calificacion == 'BUENO' || item.calificacion == 'EXCELENTE'" icon-f7="cloud_download_fill" color="green" title="Descargar certificado"
                @click.prevent="Inspeccion.downloadCertificado(item)"
              ></f7-link> -->
            </td>
            <pre style="display:none;">{{item}}</pre>
          </tr>
        </template>
        <!-- <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form @submit="rlist.setFilter($event); rpanel.close();">
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.pedido')"
                      :form="rform"
                      type="text"
                      name="codPedido"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.matricula')"
                      :form="rform"
                      type="text"
                      name="matricula"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.bastidor')"
                      :form="rform"
                      type="text"
                      name="bastidor"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.tipo_inspeccion')"
                      :form="rform"
                      type="select"
                      name="tipoInspeccion"
                    >
                      <option selected></option>
                      <option value="CERTIFICACION_1">1ª Certificación</option>
                      <option value="CERTIFICACION_2">2ª Certificación</option>
                    </r-input>
                    <r-autocomplete
                      :dbAdapter="Cliente"
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.cliente')"
                      :form="rform"
                      name="clienteId"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-input
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.calificacion')"
                      :form="rform"
                      type="select"
                      name="calificacion"
                    >
                      <option selected></option>
                      <option value="EXCELENTE">Excelente</option>
                      <option value="BUENO">Buena</option>
                      <option value="DESFAVORABLE">Desfavorable</option>
                      <option value="EN_CONFLICTO">En Conflicto</option>
                    </r-input>
                    <r-input
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.estado')"
                      :form="rform"
                      type="select"
                      name="estado"
                    >
                      <option selected></option>
                      <option value="CREADA">Creada</option>
                      <option value="PENDIENTE_FACTURACION">Realizada</option>
                      <option value="FACTURADA">Facturada</option>
                      <option value="CANCELADA">Cancelada</option>
                    </r-input>
                    <r-input
                      floating-label
                      :label="$t('inspecciones.inspeccion_form.con_conflictos')"
                      :form="rform"
                      type="select"
                      name="tieneConflictos"
                    >
                      <option selected></option>
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </r-input>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template> -->
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Usuario from "./../../js/db/Usuario.js";
import Inspector from "./../../js/db/Inspector.js";
import Cliente from "./../../js/db/Cliente.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rTimepicker,
    rAutocomplete
  },
  data() {
    return {
      Usuario,
      Inspector,
      Cliente,
      rPanelOpenedByBreakpoint: false,
      inspeccion: {}
    };
  },
  computed: {},
  methods: {
    resetPass: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea resetear la contraseña del usuario seleccionado?",
        "Resetear Contraseña",
        function() {
          app.preloader.show();
          Usuario.resetPass({itemId: itemId})
            .then(function(res) {
              app.preloader.hide();
              app.toast
                .create({
                  text: 'Contraseña Reseteada',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>