import DatabaseModel from './DatabaseModel.js';

var Campania = Object.create(DatabaseModel);

Campania.model = 'campanias';
Campania.modelFront = 'campanias';

Campania.generarOportunidades = function (id) {
    var self = this;
    if (!id) reject({ error: 'id not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + id + '/generarOportunidades',
        method: 'PUT'
    };
    return self.sendRequest(params, false);
};

export default Campania;