import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';
import Utils from '../Utils.js';

var Documentacion = Object.create(DatabaseModel);

Documentacion.model = 'documentacion';
Documentacion.modelFront = 'documentacion';

Documentacion.delete = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/delete',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}
Documentacion.put = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}
Documentacion.upload = function(file, fileData) {
    var self = this;
    var data = new FormData();
    data.append("files", file);
    if(fileData){
        if(fileData.path){ data.append("path", fileData.path); }
    }
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/upload',
            method: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Documentacion.getDownloadUrl = function(item) {
    var self = this;
    let docStr = window.btoa(unescape(encodeURIComponent( JSON.stringify({path: item.path}))));
    let url = self.server_movilidad + self.prefix + 'core/public/download?token=' + Utils.getToken() + "&documento=" + docStr + "&ignoreBasePath=true";
    return url;
}


export default Documentacion;