<template>
    <f7-page>
        <f7-navbar>
        <f7-nav-left></f7-nav-left>
        <f7-nav-title>{{(rform.readonly?'Detalle':'Edición')+' de la regla de auditoría'}}</f7-nav-title>
        <f7-nav-right><f7-link popup-close>Cerrar</f7-link></f7-nav-right>
        </f7-navbar>
        <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
            <f7-card-content>
                <f7-row no-gap class="list list-form no-hairlines bg-">
                    <f7-col width="10" style="padding:0;">
                        <r-input
                            floating-label
                            :label="'Tipo de regla'"
                            :form="rform"
                            type="select"
                            name="reglaSeleccionada.tipoRegla"
                            :clearButton="false"
                        >
                            <!-- <option value="" selected></option> -->
                            <option value="bloqueante">Bloqueante</option>
                            <option value="informativa">Informativa</option>
                        </r-input>
                    </f7-col>
                    <f7-col width="20" style="padding:0;display: inline-flex;">
                        Evaluar:
                        <r-input
                            floating-label
                            :label="'al sincronizar'"
                            :form="rform"
                            type="check"
                            :simple="true"
                            name="reglaSeleccionada.evalOnSync"
                        ></r-input>
                        <r-input
                            floating-label
                            :label="'al finalizar'"
                            :form="rform"
                            type="check"
                            :simple="true"
                            name="reglaSeleccionada.evalOnFinish"
                        ></r-input>
                    </f7-col>
                    <f7-col width="10">
                        <r-input
                            floating-label
                            :label="'Activa'"
                            :form="rform"
                            type="check"
                            :simple="true"
                            name="reglaSeleccionada.activa"
                        ></r-input>
                    </f7-col>
                    <f7-col width="50"></f7-col>
                </f7-row>
            </f7-card-content>
        </f7-card>
        <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
            <f7-card-content>
                <f7-row no-gap class="list list-form no-hairlines">
                <f7-col width="100" medium="100">
                    <ul>
                    <r-input
                        floating-label
                        label="Regla"
                        :form="rform"
                        type="text"
                        name="reglaSeleccionada.textoCondicion"
                    ></r-input>
                    <r-code
                        floating-label
                        class="row-auto"
                        label="Codificación de la regla"
                        :form="rform"
                        name="reglaSeleccionada.regla.regla"
                    ></r-code>
                    <f7-nav-title style="margin-left: 12px;">Características afectadas:</f7-nav-title>
                    <r-autocomplete-m2m
                        class="col-100"
                        floating-label
                        outline
                        :form="rform"
                        :label="'Características afectadas'"
                        :dbItems="getCaracteristicas(rform.formData.caracteristicas)"
                        name="reglaSeleccionada.regla.caracteristicasAfectadas"
                        labelProp="nombre"
                        idProp="nombre"
                    ></r-autocomplete-m2m>
                    <div class="separador-horizontal"></div>
                    <f7-nav-title style="margin-left: 12px;">Comprobaciones afectadas:</f7-nav-title>
                    <r-autocomplete-m2m
                        class="col-100"
                        floating-label
                        outline
                        :form="rform"
                        :label="'Comprobaciones afectadas'"
                        :dbItems="getComprobaciones(rform.formData.comprobaciones)"
                        name="reglaSeleccionada.regla.comprobacionesAfectadas"
                        labelProp="codigo"
                        idProp="codigo"
                    ></r-autocomplete-m2m>
                    <div class="separador-horizontal"></div>
                    <f7-nav-title style="margin-left: 12px;">Valores afectados:</f7-nav-title>
                    <div
                        v-for="(valor, indexValores) in rform.formData.reglaSeleccionada.regla.valoresAfectados"
                        :key="'valores-'+indexValores"
                        class="valores-afectados"
                    >
                        <r-input
                        floating-label
                        outline
                        label="Valores"
                        :form="rform"
                        type="text"
                        :name="'reglaSeleccionada.regla.valoresAfectados.'+indexValores"
                        ></r-input>
                        <f7-link v-if="!rform.readonly" @click="eliminaValorAfectado(indexValores)" icon-f7="minus_square_fill" color="red" v-tooltip="'Eliminar valor'"></f7-link>
                    </div>
                    <f7-link v-if="!rform.readonly" @click="nuevoValorAfectado()" icon-f7="plus_square_fill" v-tooltip="'Crear nueva regla'"></f7-link>
                    </ul>
                </f7-col>
                </f7-row>
            </f7-card-content>
        </f7-card>
        <div v-if="rform.formData.reglaSeleccionada.regla.error" class="texto-error" v-html="rform.formData.reglaSeleccionada.regla.error.stack"></div>
        <div v-if="rform.formData.reglaSeleccionada.regla.fechaGPT" class="pie-popup">
        {{ 'Fecha generación: '  +  moment(rform.formData.reglaSeleccionada.regla.fechaGPT).format('DD/MM/YYYY hh:mm:ss.SSS') }}
        </div>
    </f7-page>
</template>

<style scoped>


/* estilo de div para que el contenido quede en una sola línea ocupando lo que necesite */
.valores-afectados {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<script>
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rCode from "./../../components/rCode.vue"
import rInput from "../../components/rInput.vue";
import moment from 'moment';

export default {
    components: {
        rAutocompleteM2m,
        rInput,
        rCode
    },
    data: function () {
        var self = this;
        return {
            moment,
            reglaSeleccionada: self.rform.formData.reglaSeleccionada,
        };
    },
    props: {
        rform: {
            type: Object,
        },
    },
    computed: {},
    mounted() {
    },
    beforeDestroy() { },
    methods: {
        getCaracteristicas (caracteristicas) {
            const self = this;
            if (!caracteristicas || !caracteristicas.length) {
                return [];
            }
            var result = Object.assign([], caracteristicas);
            var hijas = caracteristicas.flatMap(c => {
                return self.getCaracteristicas(c.hijas);
            });
            return result.concat(hijas);
        },
        getComprobaciones (comprobaciones) {
            var self = this;
            if (!comprobaciones || !comprobaciones.length) {
                return [];
            }
            var result = Object.assign([], comprobaciones);
            var hijas = comprobaciones.flatMap(c => {
                c.codigoNombre = c.codigo + ' - ' + c.nombre;
                return self.getComprobaciones(c.hijas);
            });
            return result.concat(hijas);
        },
        nuevaCaracteristicaAfectada: function () {
            var self = this;
            if(self.rform.formData.reglaSeleccionada.regla.caracteristicasAfectadas) {
                self.rform.formData.reglaSeleccionada.regla.caracteristicasAfectadas.push('');
            } else {
                self.rform.formData.reglaSeleccionada.regla.caracteristicasAfectadas = [''];
            }
        },
        nuevaComprobacionAfectada: function () {
            var self = this;
            if(self.rform.formData.reglaSeleccionada.regla.comprobacionesAfectadas) {
                self.rform.formData.reglaSeleccionada.regla.comprobacionesAfectadas.push('');
            } else {
                self.rform.formData.reglaSeleccionada.regla.comprobacionesAfectadas = [''];
            }
        },
        nuevoValorAfectado: function () {
            var self = this;
            if(self.rform.formData.reglaSeleccionada.regla.valoresAfectados) {
                self.rform.formData.reglaSeleccionada.regla.valoresAfectados.push('');
            } else {
                self.rform.formData.reglaSeleccionada.regla.valoresAfectados = [''];
            }
            },
            eliminaValorAfectado: function (index) {
            var self = this;
            self.rform.formData.reglaSeleccionada.regla.valoresAfectados.splice(index, 1);
        },
    }
}
</script>
