<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('albaranes.albaranes')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Albaran"
        sort="fecha"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :rowsPerPage="30"
        filtersName="albaranes"
        @load="loadList"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <!-- <div class="data-table-links">
                <a class="link" href="/inspecciones/new/">Nuevo</a>
              </div> -->
              <div class="data-table-actions">
                <f7-link small @click="rlist.reload()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Albaranes.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('codigo')"
                :class="rlist.sortableClasses('codigo')"
              >{{$t('albaranes.codigo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('cliente')"
                :class="rlist.sortableClasses('cliente')"
              >{{$t('albaranes.cliente')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('expedientes')"
                :class="rlist.sortableClasses('expedientes')"
              >{{$t('albaranes.expedientes')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('fecha')"
                :class="rlist.sortableClasses('fecha')"
              >{{$t('albaranes.fecha')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('firmado')"
                :class="rlist.sortableClasses('firmado')"
              >{{$t('albaranes.firmado')}}</th>
              <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
                <f7-icon f7="gear_alt_fill"></f7-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item, i) in vlData.items" :key="'albaran-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <!-- <td class="label-cell">{{item.codigo}}</td> -->
            <td>{{item.codigo ? item.codigo : ''}}</td>
            <td>{{item.cliente ? item.cliente.razonSocial : ''}}</td>
            <td>{{item.expedientes ? item.expedientes.length : 0}}</td>
            <td>{{item.fecha | dateParse | dateFormat('DD/MM/YYYY')}}</td>
            <td><r-badge 
              :text="$t(firmado[i] ? 'common.yes' : 'common.no')"
              :color="(firmado[i]) ? 'green' : 'red'"></r-badge></td>
            <td
              class="display-flex right-column-sticky align-items-center padding-half text-color-gray text-align-center"
            >
              <f7-button
                v-if="item.albaranFirmado"
                v-tooltip="$t('albaranes.resumen')"
                icon-f7="cloud_download_fill"
                icon-size="18"
                color="green"
                @click="downloadAlbaran(item)"
              ></f7-button>
              <f7-button
                v-if="!firmado[i]"
                v-tooltip="$t('albaranes.cargar_albaran_firmado')"
                icon-f7="cloud_upload_fill"
                icon-size="18"
                color="orange"
                @click="albaranSeleccionado=item"
                popup-open=".popupcarga"
              ></f7-button>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
            class="page"
          >
            <r-form 
              @submit="submitFilter(rlist, $event, rpanel)" 
              class="full-height" 
              classContent="page-content"
              filtersName="albaranes"
            >
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('albaranes.codigo')"
                      :form="rform"
                      type="text"
                      name="codigo"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('albaranes.cliente')"
                      :form="rform"
                      name="cliente"
                    ></r-input>
                    <r-autocomplete
                      :dbAdapter="Provincia"
                      :idProp="'codigo'"
                      floating-label
                      :label="$t('albaranes.provincia')"
                      :form="rform"
                      name="provincia"
                      labelProp="nombre"
                      :idFieldDetail="'id'"
                      nameId="idProvincia"
                    ></r-autocomplete>
                    <r-input
                      floating-label
                      :label="$t('albaranes.firmado')"
                      :form="rform"
                      type="select"
                      name="firmado"
                    >
                      <option selected></option>
                      <option value="true">{{$t('common.yes')}}</option>
                      <option value="false">{{$t('common.no')}}</option>
                    </r-input>
                    <li class="label-group"><label>{{$t('albaranes.fecha')}}</label></li>
                    <r-input
                      floating-label
                      :label="$t('common.desde')"
                      :form="rform"
                      type="datepicker"
                      name="fechaDesde"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                    <r-input
                      floating-label
                      :label="$t('common.hasta')"
                      :form="rform"
                      type="datepicker"
                      name="fechaHasta"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                    ></r-input>
                  </template>
                </f7-list>
              </template>
              <template v-slot:after="{}">
                <f7-toolbar bottom>
                  <f7-button class="display-flex" type="submit" icon-f7="search">{{$t("common.buscar")}}</f7-button>
                </f7-toolbar>
              </template>
            </r-form>
            <f7-popup class="popupcarga" ref="popupcarga" @popup:close="albaranSeleccionado = null" swipeToClose>
              <r-form
                ref="cargaAlbaran"
                :itemId="itemIdSeleccionado"
                :dbAdapter="Albaran"
                :defaultData="{firma:{}}"
                @save="cargarAlbaran"
                :beforeSave="beforeCargarAlbaran"
                :documentoAdapter="Documento"
              >
                <template v-slot="{rform}">
                  <f7-page v-if="albaranSeleccionado" >
                    <f7-navbar>
                      <f7-nav-left>
                        <f7-link popup-close>Cerrar</f7-link>
                      </f7-nav-left>
                      <f7-nav-title>
                        {{$t('albaranes.cargar_albaran_firmado')}}
                      </f7-nav-title>
                      <f7-nav-right>
                        
                      </f7-nav-right>
                    </f7-navbar>
                    <f7-list no-hairlines-md>
                      <f7-row class="list list-form no-hairlines">
                        <f7-col width="66" medium="66">
                          <r-input
                            floating-label
                            :label="$t('albaranes.nombre')"
                            :form="rform"
                            type="text"
                            name="firma.nombre"
                          ></r-input>
                        </f7-col>
                        <f7-col width="33" medium="33">
                          <r-input
                            floating-label
                            :label="$t('albaranes.nif')"
                            :form="rform"
                            type="text"
                            name="firma.nif"
                          ></r-input>
                        </f7-col>
                        <f7-col width="100" medium="100">
                          <r-files
                            :form="rform"
                            name="albaranFirmado"
                            :documentoAdapter="Documento"
                            :maxFiles="1"
                          ></r-files>
                        </f7-col>
                      </f7-row>
                    </f7-list>
                    <f7-button
                      fill
                      color="primary"
                      class="margin-horizontal"
                      @click="$refs.cargaAlbaran.saveItem()"
                    >{{$t('common.cargar')}}</f7-button>
                  </f7-page>
                </template>
              </r-form>
            </f7-popup>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rBadge from "./../../components/rBadge.vue";
import VueHtml2pdf from 'vue-html2pdf'
import Documento from "./../../js/db/Documento.js";
import Albaran from "./../../js/db/Albaran.js";
import Provincia from "./../../js/db/Provincia.js";
import Cliente from "./../../js/db/Cliente.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rFiles,
    rTimepicker,
    rAutocomplete,
    rBadge,
    VueHtml2pdf
  },
  data() {
    return {
      Documento,
      Albaran,
      Provincia,
      Cliente,
      rPanelOpenedByBreakpoint: false,
      albaranSeleccionado: {},
      listData: []
    };
  },
  computed: {
    firmado: function(){
      var self = this;
      if(!self.listData){
        return [];
      }
      return self.listData.map(item => {
        return item.firma && item.firma.nif;
      });
    },
    itemIdSeleccionado: function(){
      var self = this;
      if(!self.albaranSeleccionado || ! self.albaranSeleccionado.id){
        return null;
      }
      return self.albaranSeleccionado.id;
    }
  },
  methods: {
    submitFilter: function(rlist, event, rpanel) {
      var self = this;
      rlist.setFilter(event); 
      rpanel.close();
    },
    downloadAlbaran: function (albaran){
      var self = this;
      var app = self.$f7;
      Documento.download(albaran.albaranFirmado);
    },
    beforeCargarAlbaran: function(data, resolve, reject) {
      const self = this;
      const d = new Date();
      if (data.albaranFirmado.length) {
        data.albaranFirmado = data.albaranFirmado[0];
      }
      resolve(data);
    },
    cargarAlbaran: function(){
      var self = this;
      var router = self.$f7router;
      self.$refs.cargaAlbaran.clear();
      if (self.$refs.popupcarga) {
        self.$refs.popupcarga.close();
      }
      self.$refs.rlist.reload();
      app.toast
        .create({
          text: self.$t("albaranes.albaran_cargado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadList: function(){
      var self = this;
      self.listData = self.$refs.rlist.vlData.items;
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>