<template>
  <f7-popup ref="popup" :opened="false" class="popup-facturar"
      @popup:closed="pedido = false;$refs.popup.close();">
    <f7-page>
      <r-form
        ref="rform"
        v-slot="{rform}"
        :readonly="false"
        @save="facturar();"
      >
        <f7-navbar >
          <f7-nav-left>
            <f7-link @click="noFacturar()" icon-f7="minus_circle" v-tooltip="'Marcar todo como No facturar'"></f7-link>
            <f7-link @click="facturarTodo()" icon-f7="arrow_left_right_circle" v-tooltip="'Marcar todo a facturar'"></f7-link>
            <f7-link @click="facturarImportes()" icon-f7="ant_circle" v-tooltip="'Marcar facturar solo importes'"></f7-link>
            <f7-link @click="facturarTasas()" icon-f7="asterisk_circle" v-tooltip="'Marcar facturar solo tasas'"></f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>Que facturar</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="rform.saveItem()">Generar Factura</f7-link>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul v-if="pedido" style="padding-left:10px;">
                  <f7-row class="" style="padding-left:5px;border-bottom: 1px solid #e3dcdc;">
                    <f7-col class="text-align-center" width="25">
                      Que facturar
                    </f7-col>
                    <f7-col class="text-align-center" width="70">
                      Nº linea - Servicio
                    </f7-col>
                  </f7-row>
                  <f7-row 
                    v-for="(lineafac,index) in rform.formData.lineasFacturables" 
                    :key="'lineasFacturables.'+index"
                    class="list list-form no-hairlines"
                    style="padding-left:5px;border-bottom: 1px solid #e3dcdc;"
                  >
                    <f7-col width="25">
                      <r-input
                        :form="rform"
                        type="select"
                        :clearButton="false"
                        :name="'lineasFacturables.' + index + '.tipoLineaFacturacion'"
                        :readonly="false"
                        :overrideReadonly="true"
                      >
                        <option 
                          v-for="(option) in opcionesFacturacion(lineafac)" 
                          :key="'lineasFacturables.' + index + '.tipoLineaFacturacion.' + option.value" 
                          :value="option.value"
                        >{{ option.name }}</option>
                      </r-input>
                    </f7-col>
                    <f7-col width="70">
                      {{ lineafac.numero }} - {{ lineafac.servicio.nombre }}
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </div>
      </r-form>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rInput from "../../../components/rInput.vue";
import rAutocomplete from "../../../components/rAutocomplete.vue";
import Utils from "../../../js/Utils.js";
import DateUtils from "../../../js/DateUtils.js";
import Property from "../../../js/db/Property.js";
import Factura from "../../../js/db/Factura.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Utils,
      Property,
      configuraciones: [],
      DateUtils,
      pedido: false
    };
  },
  props:{
  },
  computed: {
    popupTitle: function(){
      const self = this;
      return self.$t("pedidos.pedido_form.facturar");
    },
    // lineasFacturables: function() {
    //   var self = this;
    //   var app = self.$f7;
    //   let lineasFacturar = [];
    //   let lineas = self.pedido.lineas;
    //   lineas.forEach((linea) => {
    //     let totalFacturadoConIVA = linea.totalFacturadoConIVA?linea.totalFacturadoConIVA:0;
    //     let totalConIVAConTasasConDescuentoConTerceros = linea.totalConIVAConTasasConDescuentoConTerceros?linea.totalConIVAConTasasConDescuentoConTerceros:0;
    //     if ((totalFacturadoConIVA + 1) < totalConIVAConTasasConDescuentoConTerceros) {
    //       lineasFacturar.push({
    //           "pedido": {"id": self.pedido.id},
    //           "numero": linea.numero
    //       });
    //     } 
    //   });
    //   return lineasFacturar;
    // }
  },
  methods: {
    openPopup: function(pedido) {
      const self = this;
      self.pedido = pedido;
      let lineas = self.pedido.lineas;
      let lineasFacturables = [];
      lineas.forEach((linea) => {
        let totalFacturadoConIVA = linea.totalFacturadoConIVA?linea.totalFacturadoConIVA:0;
        let totalConIVAConTasasConDescuentoConTerceros = linea.totalConIVAConTasasConDescuentoConTerceros?linea.totalConIVAConTasasConDescuentoConTerceros:0;
        if ((totalFacturadoConIVA + 1) < totalConIVAConTasasConDescuentoConTerceros) {
          if(linea && linea.tipoLineaFacturacion) {
            lineasFacturables.push(linea);
          } else if(linea.tasas && linea.tasas > 0) {
            lineasFacturables.push(Object.assign(linea));
          } else {
            lineasFacturables.push(Object.assign(linea));
          }
        } 
      });
      lineasFacturables = lineasFacturables.sort((a,b)=>a.numero-b.numero);
      self.$set(self.$refs.rform.formData, 'lineasFacturables', lineasFacturables);
      self.noFacturar();
      self.$refs.popup.open();
    },
    
    noFacturar: function() {
      const self = this;
      const app = self.$f7;
      // Vamos a marcar todas las líneas facturables como NO_FACTURAR
      self.$refs.rform.formData.lineasFacturables.forEach((linea,index) => {
        // linea.tipoLineaFacturacion = 'NO_FACTURAR';
        self.$set(linea, 'tipoLineaFacturacion', 'NO_FACTURAR');
      });
    },
    facturarTodo: function() {
      const self = this;
      const app = self.$f7;
      // Vamos a marcar todas las líneas facturables como TODO
      self.$refs.rform.formData.lineasFacturables.forEach((linea) => {
        if(linea.tasas && linea.tasas > 0) {
          self.$set(linea, 'tipoLineaFacturacion', 'TODO');
        } else {
          self.$set(linea, 'tipoLineaFacturacion', 'LINEA');
        }
      });
    },
    facturarImportes: function() {
      const self = this;
      const app = self.$f7;
      // Vamos a marcar todas las líneas facturables como LINEA
      self.$refs.rform.formData.lineasFacturables.forEach((linea) => {
        self.$set(linea, 'tipoLineaFacturacion', 'LINEA');
      });
    },
    facturarTasas: function() {
      const self = this;
      const app = self.$f7;
      // Vamos a marcar todas las líneas facturables como TASAS
      self.$refs.rform.formData.lineasFacturables.forEach((linea) => {
        if(linea.tasas && linea.tasas > 0) {
          self.$set(linea, 'tipoLineaFacturacion', 'TASAS');
        } else {
          self.$set(linea, 'tipoLineaFacturacion', 'NO_FACTURAR');
        }
      });
    },
    facturar: function() {
      const self = this;
      const app = self.$f7;
      let params = {};
      params.data = self.preparaLineas();
      Factura.crearFacturas(params).then((response) => {
        self.$refs.popup.close();
        app.dialog.alert("Se ha generado " + response.list.length  + " facturas", "OK");
      }).catch((error) => {
        app.dialog.alert("no se ha podido facturar el pedido ", "ERROR");
      });
    },
    preparaLineas: function() {
      const self = this;
      const app = self.$f7;
      let lineasFacturar = [];
      self.$refs.rform.formData.lineasFacturables.forEach((linea) => {
        let totalFacturadoConIVA = linea.totalFacturadoConIVA?linea.totalFacturadoConIVA:0;
        let totalConIVAConTasasConDescuentoConTerceros = linea.totalConIVAConTasasConDescuentoConTerceros?linea.totalConIVAConTasasConDescuentoConTerceros:0;
        let seleccionada = linea.tipoLineaFacturacion == 'TODO'?'':linea.tipoLineaFacturacion;
        let datosLinea;
        if (seleccionada !== 'NO_FACTURAR' && (totalFacturadoConIVA + 1) < totalConIVAConTasasConDescuentoConTerceros) {
          datosLinea = {
            "pedido": {"id": self.pedido.id},
            "numero": linea.numero
          };
          if(seleccionada) {
            datosLinea.tipoLinea = seleccionada;
          }

          lineasFacturar.push(datosLinea);
        } 
      });
      return lineasFacturar;
    },
    opcionesFacturacion: function(linea) {
      const self = this;
      const app = self.$f7;
      let opciones = [{'name':'No facturar', 'value':'NO_FACTURAR'}];
      if(linea.tasas && linea.tasas > 0) {
        opciones.push({'name':'Todo', 'value':'TODO'});
        opciones.push({'name':'Solo importe', 'value':'LINEA'});
        opciones.push({'name':'Solo tasas', 'value':'TASAS'});
      } else {
        opciones.push({'name':'Solo importe', 'value':'LINEA'});
      }
      return opciones;
    },
    verFacturar: function (event) {
      const self = this;
      const app = self.$f7;

      // Solo saldrá el botón de facturar si hemos seleccionado alguna línea de pedido a facturar
      return self.preparaLineas().length > 0;      
    },
  },
  mounted: function(){
    const self = this;
    console.log ("pedido", self.pedido);
  }
};
</script>