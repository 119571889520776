<template>
  <r-page>
    <!-- Top Navbar -->
    <f7-navbar :title="$t('informes.title')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="{}"
        tipoConsultasEstaticas="INFORME"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['abierta']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="90"
        :searchOnInit="true"
        :primary="['fechaPrevision', 'referencia']"
      >
      </r-dynamic-list>
    </f7-page-content>
  </r-page>
</template>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
export default {
  components: {
    rDynamicList
  },
  data: function() {
    return {
    };
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>