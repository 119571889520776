<template>
<f7-page :page-content="true">
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    @save="onSave"
    @loadItem="loadItem"
    :readonly="readonly"
    :dbAdapter="Factura"
    :documentoAdapter="Documento"
    :defaultData="{inspectores:[], logistica: {}, lineas:[], fechaPedido: new Date(), anyoEjecucion: new Date().getFullYear(), activo: true, adelantoCobro: false}"
    :beforeSave="beforeSave"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-roles="['ADMIN','ADMINISTRACION', 'DIRECTOR']" v-if="idData && !readonly" sliding>{{$t('facturas.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="idData" sliding>{{rform.formData.tipo == 'FACTURA' ? $t('facturas.factura') : 'Abono'}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('facturas.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
          <div slot="right" v-roles="['ADMIN','ADMINISTRACION']" v-if="idData && readonly">
            <div class="menu-item menu-item-dropdown bg-color-primary">
              <div class="menu-item-content"><i class="f7-icons">ellipsis_vertical</i></div>
              <div class="menu-dropdown menu-dropdown-right bg-color-primary">
                <div class="menu-dropdown-content bg-color-primary text-align-right">
                  <!-- <div class="menu-dropdown-item menu-close pointer" @click="readonly=false">{{$t('facturas.editar')}}</div> -->
                  <!-- <div class="menu-dropdown-divider"></div> -->
                  <!-- <div class="menu-dropdown-item menu-close pointer text-color-red" @click="cancelarFactura">{{$t('facturas.cancelar')}}</div> -->
                </div>
              </div>
            </div>
          </div>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <f7-row>
                <div class="label-group col-100">Datos Generales</div>
                <r-input
                  v-noroles="['ENAC', 'PRODUCCION']"
                  class="col-100 medium-25"
                  :form="rform"
                  type="check"
                  :name="'activo'"
                  label="Activo"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.numero')"
                  :form="rform"
                  type="text"
                  name="numero"
                  v-if="idData"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  label="DocEntry"
                  :form="rform"
                  type="text"
                  name="docEntry"
                  v-if="idData"
                  v-roles="['ADMIN']"
                  :readonly="true"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  :readonly="true"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.cliente')"
                  :form="rform"
                  name="cliente"
                  labelProp="codigoNombre"
                  :minLength="3"
                  :shownFields="['razonSocial', 'direcciones', 'direccionDefectoFacturas', 'direccionDefectoEnvio', 'condPago', 'viaPagoDefecto', 'listaPrecios', 'contactos']"
                  :async="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="clienteSelected"
                  key="contactoCliente"
                  :ref="'contactoCliente'"
                  :dbAdapter="ContactoCliente"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.contactocliente')"
                  :form="rform"
                  :name="'contactoCliente'"
                  labelProp="alias"
                  infoText="Cree un contacto nuevo para este cliente"
                  @change="changeContacto"
                  :filter="clienteSelected ? {clienteId: rform.formData.cliente.id} : undefined"
                >
                  <f7-link
                    v-if="!rform.formData.contactoCliente"
                    slot="inner-end"
                    popup-open=".popup-contacto"
                    class="input-suffix-btn"
                    icon-f7="plus_app_fill"
                  ></f7-link>
                </r-autocomplete>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.emailcontacto')"
                  :form="rform"
                  type="text"
                  name="emailContacto"
                  key="emailContacto"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                  key="telefono"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('ofertas.telefonoMovil')"
                  :form="rform"
                  type="text"
                  name="telefonoMovil"
                  key="telefonoMovil"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.estado')" 
                  :form="rform"
                  type="select"
                  name="estado.estado"
                >
                  <option value="ABIERTA">{{$t('facturas.abierta')}}</option>
                  <option value="CERRADA">{{$t('facturas.cerrada')}}</option>
                  <option value="CANCELADA">{{$t('facturas.cancelada')}}</option>
                </r-input>
                <r-autocomplete
                  v-if="idData"
                  :dbAdapter="Empleado"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.empleadoContratacion')"
                  :form="rform"
                  name="contratacion.codEmpleado"
                  labelProp="nombre"
                  idProp="username"
                  idFieldDetail="id"
                  prefixProp="username"
                  :hidden="true"
                ></r-autocomplete>
                <r-autocomplete
                  v-if="idData"
                  :dbAdapter="Empleado"
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('pedidos.usuarioEjecucion')"
                  :form="rform"
                  name="camposUsuario.cpEj"
                  labelProp="nombre"
                  idProp="username"
                  prefixProp="username"
                  idFieldDetail="id"
                  :hidden="true"
                ></r-autocomplete>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('facturas.fechaFactura')"
                  :form="rform"
                  type="datepicker"
                  name="fechaFactura"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('facturas.fechaEmision')"
                  :form="rform"
                  type="datepicker"
                  name="fechaEmision"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>

                <r-input
                  class="col-100 medium-25"
                  floating-label
                  :label="$t('facturas.fechaVencimiento')"
                  :form="rform"
                  type="datepicker"
                  name="fechaVencimiento"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>

                <r-input
                  v-noroles="['ENAC', 'PRODUCCION']"
                  class="col-100 medium-50"
                  floating-label 
                  :label="$t('pedidos.comentarios')"
                  :form="rform"
                  type="text"
                  name="comentarios"
                ></r-input>
              </f7-row>
            </f7-col>
            <f7-col width="100" medium="100">
              <f7-row no-gap>
                <!-- <div class="label-group col-100 text-color-red">{{$t('pedidos.totales')}}</div> -->
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.subtotal')"
                  type="number"
                  key="facturas.subtotal.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['subtotalConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-10 text-color-red "
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.totalIVA')"
                  type="number"
                  key="facturas.totalIVA.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['ivaConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('pedidos.total')"
                  type="number"
                  key="facturas.total.con.proveedor"
                  step=".01"
                  :value="getTotalesPedido(rform.formData ? rform.formData.lineas : undefined)['totalConTasasConTerceros']"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('facturas.importeaplicado')"
                  type="number"
                  key="facturas.total.importeaplicado"
                  step=".01"
                  :value="getFormatted(rform.formData.importeAplicado)"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="$t('facturas.saldovencido')"
                  type="number"
                  key="facturas.total.saldovencido"
                  step=".01"
                  :value="getFormatted(rform.formData.saldoVencido)"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="'Cobrado'"
                  type="number"
                  key="facturas.total.cobrado"
                  step=".01"
                  :value="getFormatted(rform.formData.totalCobradoConIVA)"
                ></f7-list-input>
                <f7-list-input
                  class="col-100 medium-15 text-color-red"
                  :readonly="true"
                  floating-label
                  :label="'Pendiente'"
                  type="number"
                  key="facturas.total.pendiente"
                  step=".01"
                  :value="getFormatted(rform.formData.totalPendienteConIVA)"
                ></f7-list-input>
              </f7-row>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 margin-horizontal width-100">
          <f7-button fill tab-link="#tab-lineas" tab-link-active>Líneas</f7-button>
          <f7-button fill tab-link="#tab-direcciones">Direcciones</f7-button>
          <f7-button fill tab-link="#tab-finanzas">Pago y Finanzas</f7-button>
          <f7-button fill tab-link="#tab-documentos">Documentación</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-lineas" tab-active>
          <!-- Líneas -->
          <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('pedidos.lineas')}}
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30" class="accordion-list">
            <ul class="padding-horizontal">
              <li
                class="accordion-item row margin-vertical border-3"
                :class="{ 
                  'border-color-blue' : item.tipoLinea == 'TASAS', 
                  'border-color-orange' : item.tipoLinea == 'SUBCONTRATACION',
                  'border-color-red' : item.tipoLinea == 'COMISION',
                  'border-color-green' : item.tipoLinea != 'TASAS' && item.tipoLinea != 'SUBCONTRATACION' && item.tipoLinea != 'COMISION',
                  }"
                v-for="(item,index) in rform.formData.lineas"
                :key="'linea-'+index"
              >
              <!-- <a class="col-100 item-content item-link" href="#"> -->
                <div class="item-inner col-100">
                  <f7-card class="no-margin data-linea">
                    <div 
                      class="col-100 medium-25 item-content item-input item-input-with-value over-card"
                      >
                        <r-badge :color="getColorBadge(item, lineasEjecutable[index])" :text="getTextoBadge(item, lineasEjecutable[index])"></r-badge>
                        <r-badge v-if="item.baseRef" :color="'teal'" :text="'Pedido: ' + item.baseRef"></r-badge>
                        <r-badge v-if="item.lineStatus && item.lineStatus=='C'" color="gray" text="Línea cerrada"></r-badge>
                    </div>
                    <f7-list class="list-form">
                      <ul>
                        <f7-row no-gap class="padding-vertical-half first-col">
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('pedidos.num')"
                            :value="item.numero"
                            v-tooltip="$t('pedidos.num')+'<br/>'+item.numero"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <!-- <f7-list-input
                            class="col-100 medium-20"
                            floating-label
                            :label="$t('pedidos.item')"
                            :value="item.item ? item.item.nombre : ''"
                            v-tooltip="$t('pedidos.item')+'<br/>'+ (item.item ? item.item.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input> -->
                          <!-- <f7-list-input
                            class="col-100 medium-40"
                            floating-label
                            :label="$t('pedidos.servicio')"
                            :value="item.servicio ? (item.servicio.userText || item.servicio.nombre) : ''"
                            v-tooltip="$t('pedidos.servicio')+'<br/>'+ (item.servicio ? item.servicio.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input> -->
                          <f7-list-input
                            class="col-100 medium-40"
                            floating-label
                            label="Concepto"
                            :value="item.text || (item.servicio ? item.servicio.nombre : '')"
                            v-tooltip="item.text || (item.servicio ? item.servicio.nombre : '')"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-5"
                            floating-label
                            :label="$t('pedidos.cantidad')"
                            :value="item.cantidad"
                            v-tooltip="$t('pedidos.cantidad')+'<br/>'+item.cantidad"
                            autocomplete="off"
                            :readonly="true"
                          >
                          </f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.contratacion')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.contratacion')+'<br/>'+totalesLineas[index]['subtotal']"
                            :value="totalesLineas[index]['subtotal']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            v-noroles="['ENAC', 'PRODUCCION']"
                            floating-label
                            :label="$t('pedidos.terceros')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.terceros')+'<br/>'+totalesLineas[index]['subtotalTerceros']"
                            :value="totalesLineas[index]['subtotalTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.totalTasas')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.totalTasas')+'<br/>'+totalesLineas[index]['totalTasas']"
                            :value="totalesLineas[index]['totalTasas']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.totalIVA')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.totalIVA')+'<br/>'+totalesLineas[index]['ivaConTerceros']"
                            :value="totalesLineas[index]['ivaConTerceros']"
                          ></f7-list-input>
                          <f7-list-input
                            class="col-100 medium-10 text-color-red"
                            :readonly="true"
                            floating-label
                            :label="$t('pedidos.subtotal')"
                            type="number"
                            step=".01"
                            v-tooltip="$t('pedidos.subtotal')+'<br/>'+totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                            :value="totalesLineas[index]['totalSinTasasConTercerosSinIVa']"
                          ></f7-list-input>
                        </f7-row>
                      </ul>
                    </f7-list>
                    <f7-link
                      v-tooltip="'Editar Línea'"
                      v-if="!readonly"
                      @click="$set($refs.rformNew, 'formData', item);llamarOnBlur2a($refs.rformNew.formData)"
                      class="margin-half link-inline"
                      :class="{'link-inline-1': item.baseEntry, 'link-inline-0': !item.baseEntry}"
                      icon-f7="pencil_circle_fill"
                      popup-open=".popupnew"
                    ></f7-link>
                    <f7-link
                      v-if="readonly"
                      v-tooltip="'Ver Línea'"
                      @click="$set($refs.rformNew, 'formData', item);llamarOnBlur2a($refs.rformNew.formData)"
                      class="margin-half link-inline"
                      :class="{'link-inline-1': item.baseEntry, 'link-inline-0': !item.baseEntry}"
                      icon-f7="info_circle_fill"
                      popup-open=".popupnew"
                    ></f7-link>
                    <f7-link
                      external
                      v-tooltip="'Ver ' + (item.baseType == '13' ? 'Factura' : 'Pedido') + ' ' + item.baseRef "
                      v-if="item.baseEntry"
                      class="margin-half link-inline link-inline-0"
                      icon-f7="arrow_up_right_square"
                      color="green"
                      :href="'#!/' + (item.baseType == '13' ? 'facturas' : 'pedidos') + '/view/' + item.baseEntry + '/'"
                    ></f7-link>
                  </f7-card>
              </div>
              <template v-if="item.comentarios">
                <div v-noroles="['ENAC', 'PRODUCCION']" class="item-inner col-100" 
                  v-for="(texto,index2) in getLineasTexto(item)"
                  :key="'linea-'+index+'texto'+index2">
                  <f7-card class="no-margin linea-texto">
                      {{texto}}
                  </f7-card>
                </div>
              </template>
              <!-- </a> -->
              </li>
            </ul>
          </div>
          <!-- Líneas -->
          <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('pedidos.lineas')}}
          </f7-block-title>
        </f7-tab>
        <f7-tab id="tab-direcciones">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <f7-row>
                  <div class="label-group col-100">Dirección Envío</div>
                  <r-direccion :form="rform" :name="'logistica.direccionEnvio'"></r-direccion>
                  <div class="label-group col-100">Dirección Facturación</div>
                  <r-direccion :form="rform" :name="'logistica.direccionFacturacion'"></r-direccion>
                  <f7-block-title class="col-100">
                    Emails Envío Facturas
                    <f7-link v-if="!readonly" @click="addEmail('emailsFacturas')">{{$t('pedidos.add')}}</f7-link>
                    <f7-link
                      v-if="readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.emailsFacturas && rform.formData.cliente.emailsFacturas.length"
                      external
                      v-tooltip="'Enviar Email'"
                      icon-f7="envelope_badge_fill"
                      :href="'mailto:' + rform.formData.cliente.emailsFacturas.join(';')"
                    ></f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsFacturas"
                      :key="'emailFactura-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsFacturas.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsFacturas, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                  <f7-block-title class="col-100">
                    Emails Envío Informes
                    <f7-link v-if="!readonly" @click="addEmail('emailsCertificados')">{{$t('pedidos.add')}}</f7-link>
                    <f7-link
                      v-if="readonly && rform.formData && rform.formData.cliente && rform.formData.cliente.emailsCertificados && rform.formData.cliente.emailsCertificados.length"
                      external
                      v-tooltip="'Enviar Email'"
                      icon-f7="envelope_badge_fill"
                      :href="'mailto:' + rform.formData.cliente.emailsCertificados.join(';')"
                    ></f7-link>
                  </f7-block-title>
                  <div class="form-caja margin-horizontal" v-if="rform.formData.cliente">
                    <div
                      class="col-100"
                      v-for="(email, index) in rform.formData.cliente.emailsCertificados"
                      :key="'emailCertificado-' + index"
                    >
                      <r-input
                        class="col-100"
                        outline
                        floating-label
                        :label="'Correo electrónico'"
                        :form="rform"
                        type="text"
                        :clearButton="false"
                        :placeholder="'Introduzca un correo electrónico válido'"
                        :name="'cliente.emailsCertificados.' + index"
                        :validation="{isValid: validator.isEmail, errorMessage: '* Introduzca un correo electrónico válido'}"
                      >
                        <f7-link
                          v-if="!readonly"
                          v-tooltip="'Borrar'"
                          slot="inner-end"
                          @click="$delete(rform.formData.cliente.emailsCertificados, index);"
                          class="input-suffix-btn"
                          icon-f7="trash"
                          style="top: initial;right: 15px;"
                        ></f7-link>
                      </r-input>
                    </div>
                  </div>
                </f7-row>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-finanzas" v-if="rform.formData && rform.formData.finanzas">
          <tab-finanzas
            ref="tabFinanzas"
            :readonly="readonly"
            :rform="rform" 
          ></tab-finanzas>
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="!rform.formData.documentos || !rform.formData.documentos.length">La factura no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
      </f7-tabs>
    </template>
  </r-form>
  <f7-popup ref="popupnew" class="popupnew" @popup:close="indexLinea = -1; step = 1; if ($refs.rformNew) {$refs.rformNew.clear();} ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{$t('pedidos.cerrar')}}</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{$t('pedidos.linea')}} - {{step}}/{{maxStep}}
        </f7-nav-title>
      </f7-navbar>
      <r-form 
        v-if="clienteSelected"
        ref="rformNew" 
        classContent="full-height"
        @save="onNewLine"
        :readonly="readonly"
      >
        <template v-slot:default="{rform}">
          <div class="display-inline-block text-align-center margin-top">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
            >{{$t('pedidos.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('pedidos.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="rform.saveItem()"
            >{{$t('pedidos.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('pedidos.cerrar')}}</f7-button>
          </div>
          <template v-if="step == 1">
            <div class="list" style="z-index: 4;">
              <ul>
                <r-input
                  v-if="id"
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.fechaEjecucion')"
                  :form="rform"
                  type="datepicker"
                  name="camposUsuario.fechaEjecucion"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>
              {{$t('pedidos.instalacion')}}
              <span
                v-if="!rform.formData.codigoInstalacion"
              >
               - 
              </span>
            </f7-block-title>
            <div class="list" style="z-index: 3;">
              <ul>
                <r-autocomplete
                  key="instalacion"
                  :ref="'instalacion'"
                  :dbAdapter="Instalacion"
                  class="col-100"
                  :form="rform"
                  :name="'codigoInstalacion'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  idProp="codigo"
                  :readonly="true"
                  :overrideReadonly="true"
                  :filter="{clienteId: $refs.rform.formData.cliente ? $refs.rform.formData.cliente.id: null}"
                  @change="$set(rform.formData, 'item', null)"
                >
                </r-autocomplete>
              </ul>
            </div>
            <f7-block-title>
              {{$t('pedidos.item')}}
              <span
                v-if="!readonly && rform.formData.codigoInstalacion && (!rform.formData.item || !rform.formData.item.id)"
              >
               - 
              </span>
            </f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                 <r-autocomplete
                  key="item"
                  :ref="'item'"
                  :dbAdapter="Item"
                  class="col-100"
                  :form="rform"
                  :name="'item'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'codigoInstalacion', 'direccion', 'administrador', 'instalador', 'mantenedor', 'asociacion']"
                  :readonly="true"
                  :overrideReadonly="true"
                  :filter="{filter: {idCliente: $refs.rform.formData.cliente.id}}"
                  :source="function(self) {
                    return function(query, render) {
                      var results = [];
                      for (var i = 0; i < self.items.length; i++) {
                        if (( self.items[i] && self.items[i][self.searchProp] && self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && (!rform.formData.codigoInstalacion || rform.formData.codigoInstalacion == self.items[i].codigoInstalacion)) {
                          self.items[i]['txtValue'] = self.items[i]['codigo'] + ' - ' + self.items[i]['nombre'];
                          results.push(self.items[i]);
                        }
                      }
                      render(results);
                    };
                  }"
                  @change="changeItem(rform.formData)"
                  :beforeChange="beforeChangeItem"
                >
                </r-autocomplete>
              </ul>
            </div>
          </template>
          <template v-if="step == 2">
            <f7-block-title>{{$t('pedidos.direccionInspeccion')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-direccion
                  :form="rform"
                  :name="'direccionInspeccion'"
                  :guardarCodigos="true"
                  :readonly="!lineaEditable(rform.formData)"
                  autocomplete="off"
                >
                </r-direccion>
              </ul>
            </div>
            <f7-block-title>{{$t('ofertas.emailComunicaciones')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-100"
                  floating-label
                  :label="$t('ofertas.emailComunicaciones')"
                  :form="rform"
                  :name="'emailComunicaciones'"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 3">
            <f7-block-title>Concepto</f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="'Concepto'"
                  :form="rform"
                  :name="'text'"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.servicio')}}</f7-block-title>
            <div class="list" style="z-index: 2;">
              <ul>
                <r-autocomplete
                  key="servicio"
                  :dbAdapter="Servicio"
                  class="col-100"
                  :form="rform"
                  :name="'servicio'"
                  labelProp="nombre"
                  prefixProp="codigo"
                  :shownFields="['codigo', 'precios', 'tasas', 'tasaDefecto', 'codCuentaMayor', 'tiempoEstimadoCampo', 'tiempoEstimadoOficina', 'materia', 'userText']"
                  @change="changeServicio(rform.formData)"
                  :readonly="true"
                ></r-autocomplete>
              </ul>
            </div>
            <!-- <f7-block-title>{{$t('pedidos.tiempos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tiempoEstimadoCampo')"
                  :form="rform"
                  :name="'tiempoEstimadoCampo'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tiempoEstimadoOficina')"
                  :form="rform"
                  :name="'tiempoEstimadoOficina'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
              </ul>
              <ul class="row" v-noroles="['ENAC', 'PRODUCCION']" v-if="rform.formData.tiempoRealCampo || rform.formData.tiempoRealOficina">
                <r-input
                  v-if="rform.formData.tiempoRealCampo"
                  class="col-100 medium-50"
                  floating-label
                  label="Tiempo Real Campo"
                  :form="rform"
                  :name="'tiempoRealCampo'"
                  :readonly="true"
                  :overrideReadonly="true"
                  type="number"
                ></r-input>
                <r-input
                  v-if="rform.formData.tiempoRealOficina"
                  class="col-100 medium-50"
                  floating-label
                  label="Tiempo Real Oficina"
                  :form="rform"
                  :name="'tiempoRealOficina'"
                  :readonly="true"
                  :overrideReadonly="true"
                  type="number"
                ></r-input>
              </ul>
            </div> -->
          </template>
          <template v-if="step == 4">
            <f7-block-title>{{$t('pedidos.importes')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.precio')"
                  :form="rform"
                  :name="'precioUnitario'"
                  type="number"
                  step=".01"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurPrecioUnitario(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.cantidad')"
                  :form="rform"
                  :name="'cantidad'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('pedidos.porcDescuento')"
                  :form="rform"
                  :name="'porcDescuento'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.porcSegunda')" 
                  :form="rform"
                  :name="'porcSegunda'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurPorc2a(rform.formData)"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('ofertas.importeSegunda')" 
                  :form="rform"
                  :name="'importeSegunda'"
                  type="number"
                  step=".01"
                  :readonly="!lineaEditable(rform.formData)"
                  @change="onBlurImporte2a(rform.formData)"
                ></r-input>
              </ul>
            </div>
            <f7-block-title>{{$t('pedidos.impuestos')}}</f7-block-title>
            <div class="list">
              <ul class="row">
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.tasas')"
                  :form="rform"
                  :name="'tasas'"
                  type="number"
                  :readonly="!lineaEditable(rform.formData)"
                  step=".01"
                ></r-input>
                <r-input
                  class="col-100 medium-50"
                  floating-label
                  :label="$t('pedidos.porcIVA')"
                  :form="rform"
                  :name="'porcIVA'"
                  :readonly="!lineaEditable(rform.formData)"
                  type="number"
                ></r-input>
              </ul>
            </div>
            <f7-block-title class="disabled">{{$t('pedidos.importe')}}</f7-block-title>
            <f7-list>
              <f7-list-input
                class="col-100 medium-25 text-color-red disabled"
                :readonly="true"
                type="number"
                step=".01"
                :value="getTotalesLinea(rform.formData)['total']"
              ></f7-list-input>
            </f7-list>
          </template>
          <!-- <template v-if="step == 5"> -->
            <!-- <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.colaboradores')}}</f7-block-title>
            <div v-noroles="['ENAC', 'PRODUCCION']" class="list">
              <ul class="row">
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.administrador')"
                  :form="rform"
                  :name="'item.administrador'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.instalador')"
                  :form="rform"
                  :name="'item.instalador'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.mantenedor')"
                  :form="rform"
                  :name="'item.mantenedor'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100"
                  floating-label
                  :label="$t('pedidos.asociacion')"
                  :form="rform"
                  :name="'item.asociacion'"
                  labelProp="codigoNombre"
                  :idProp="null"
                  idFieldDetail="id"
                  :shownFields="['codigo', 'razonSocial']"
                  :minLength="3"
                  :async="true"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-autocomplete>
              </ul>
            </div> -->
          <!-- </template> -->
          <template v-if="step == 5">
           <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
           <div v-noroles="['ENAC', 'PRODUCCION']">
            <f7-block-title>{{$t('pedidos.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.comision')"
                    :form="rform"
                    :name="'comision.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                    :readonly="!lineaEditable(rform.formData)"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.codigoOferta')"
                    :form="rform"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'comision.codigoOferta'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.precio')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    step=".01"
                    :name="'comision.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.porcIVA')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'comision.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.totalComision')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalComision']"
                ></f7-list-input>
              </f7-list>
              <f7-block-title>{{$t('pedidos.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <r-autocomplete
                    :dbAdapter="Cliente"
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.proveedor')"
                    :form="rform"
                    :name="'proveedor.codigo'"
                    labelProp="codigoNombre"
                    idProp="codigo"
                    idFieldDetail="id"
                    :shownFields="['codigo', 'razonSocial']"
                    :minLength="3"
                    :async="true"
                    :readonly="!lineaEditable(rform.formData)"
                  ></r-autocomplete>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.codigoOferta')"
                    :form="rform"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.codigoOferta'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.precio')"
                    :form="rform"
                    type="number"
                    step=".01"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.precio'"
                  ></r-input>
                  <r-input
                    class="col-100 medium-50"
                    floating-label
                    :label="$t('pedidos.porcIVA')"
                    :form="rform"
                    type="number"
                    :readonly="!lineaEditable(rform.formData)"
                    :name="'proveedor.porcIVA'"
                  ></r-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.totalSubcontratacion')}}</f7-block-title>
              <f7-list>
                <f7-list-input
                  class="col-100 medium-25 text-color-red disabled"
                  :readonly="true"
                  type="number"
                  step=".01"
                  :value="getTotalesLinea(rform.formData)['totalProveedor']"
                ></f7-list-input>
              </f7-list>
            </div>
          </template> 
          <template v-if="step == 6">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">{{$t('pedidos.comentarios')}}</f7-block-title>
            <f7-block-title v-noroles="['ENAC', 'PRODUCCION']">Para que salgan lineas de texto separadas en sap, separar por ##</f7-block-title>
            <div class="list" v-noroles="['ENAC', 'PRODUCCION']">
              <ul class="row">
                <r-input
                  class="col-100"
                  :form="rform"
                  :name="'comentarios'"
                  type="textarea"
                  :readonly="!lineaEditable(rform.formData)"
                ></r-input>
              </ul>
            </div>
          </template>
          <template v-if="step == 7">
            <p v-roles="['ENAC', 'PRODUCCION']" class="margin-half">Sección en desarrollo</p>
            <div v-noroles="['ENAC', 'PRODUCCION']">
              <f7-block-title>{{$t('pedidos.resumen')}}</f7-block-title>
              <f7-block-title class="disabled">{{$t('pedidos.contratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotal']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['iva']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['total']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled"></f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.porcSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.porcSegunda"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.importeSegunda')"
                    type="number"
                    step=".01"
                    :value="rform.formData.importeSegunda"
                  ></f7-list-input>
                  
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.subcontratacion')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaProveedor']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalProveedor']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('pedidos.comision')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaComision']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-33 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalComision']"
                  ></f7-list-input>
                </ul>
              </div>
              <f7-block-title class="disabled">{{$t('ofertas.totales')}}</f7-block-title>
              <div class="list">
                <ul class="row">
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.subtotal')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['subtotalConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalTasas')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalTasas']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.totalIVA')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['ivaConTerceros']"
                  ></f7-list-input>
                  <f7-list-input
                    class="col-100 medium-25 disabled"
                    :readonly="true"
                    floating-label
                    :label="$t('ofertas.total')"
                    type="number"
                    step=".01"
                    :value="getTotalesLinea(rform.formData)['totalConTasasConTerceros']"
                  ></f7-list-input>
                </ul>
              </div>
            </div>
          </template>
          <div class="display-inline-block text-align-center">
            <f7-button
              v-if="verBotonAnterior(rform)"
              fill
              color="red"
              class="display-inline-block margin-horizontal"
              @click="step--"
            >{{$t('pedidos.anterior')}}</f7-button>
            <f7-button
              v-if="verBotonSiguiente(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="step++"
            >{{$t('pedidos.siguiente')}}</f7-button>
            <f7-button
              v-if="verBotonGuardar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              @click="rform.saveItem()"
            >{{$t('pedidos.guardar')}}</f7-button>
            <f7-button
              v-if="verBotonCerrar(rform)"
              fill
              color="primary"
              class="display-inline-block margin-horizontal"
              popup-close=".popupnew"
            >{{$t('pedidos.cerrar')}}</f7-button>
          </div>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>

</f7-page>
</template>
<style>

#block_container
{
    display: flex;
}
#block1
{
    margin-left: 20px;
    font-weight: bold
}
#block2
{
    margin-left: 20px;
}

#block3
{
    margin-left: 20px;
}

.linKO
{
 border-style: solid;
 border-color: red;  
 border-radius: 20px;
 margin: 2px;
}
.linOK
{
 border-style: solid;
 border-color: green;  
 border-radius: 20px;
 margin: 2px;
}

.over-card label.disabled {
  opacity: 1 !important;
}
.sub-card label.disabled {
  opacity: 1 !important;
}
.form-caja .item-input-wrap {
  margin-right: 20px;
}
</style>
<style scoped>
.data-linea {
  padding-bottom: 20px;
}
.linea-texto {
  border: 2px solid gray;
  padding-left: 10px;
}
.form-caja {
  min-height: 30px;
  border: 1px solid gray;
  border-radius: var(--f7-input-outline-border-radius);
  padding-bottom: 7px;
}
.form-caja div {
  font-size: 12px;
  color: gray;
}
.menu-item-dropdown .menu-item-content:after {
  content: inherit !important;
}
.over-card {
  position: absolute;
  top: -13px;
  z-index: 3000;
  left: 12px;
  max-width: 90%;
}
.sub-card {
  position: absolute;
  z-index: 3000;
  left: 12px;
  max-width: 90%;
  bottom: 3px;
}
ul.no-padding ul {
  padding: 0 !important;
}
li.accordion-item .card, li.accordion-item .card ul, .border-3, li.accordion-item .accordion-item-content {
  border-radius: 20px;
}
.border-3 {
  border: 3px solid;
}
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.link-inline {
  position: absolute;
  top:0;
}
.link-inline-row-2 {
  position: absolute;
  top:25px;
}
.link-inline-0 {
  right:0;
}
.link-inline-1 {
  right:25px;
}
.link-inline-2 {
  right:50px;
}
.link-inline-3 {
  right:75px;
}
/* .first-col {
  padding-left: 20px;
}
.first-col:before {
    font-family: 'framework7-core-icons';
    position: absolute;
    top: 50%;
    font-size: 20px;
    color: var(--f7-list-chevron-icon-color);
    pointer-events: none;
    content: var(--f7-accordion-chevron-icon-down);
    margin-top: -4px;
    line-height: 8px;
    transform: translateX(-50%);
    left: calc((var(--f7-list-chevron-icon-area) + var(--f7-list-item-padding-horizontal)) / 2 + var(--f7-safe-area-left));
}
.accordion-item-opened .first-col:before {
    content: var(--f7-accordion-chevron-icon-up);
    width: 14px;
    height: 8px;
    margin-top: -4px;
    line-height: 8px;
} */
</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

</style>
<script>
import Vue from "vue";
import rForm from "../../components/rForm.vue";
import rInput from "../../components/rInput.vue";
import rBadge from './../../components/rBadge';
import rFiles from "../../components/rFiles.vue";
import rAutocomplete from "../../components/rAutocomplete.vue";
import rAutocompleteM2m from "../../components/rAutocompleteM2m.vue";
import rDireccion from "../../components/rDireccion.vue";
import rCuenta from "../../components/rCuenta.vue";
import rTimepicker from "../../components/rTimepicker.vue";
import tabFinanzas from "../../components/tabFinanzas.vue";
import Factura from "../../js/db/Factura.js";
import Item from "../../js/db/Item.js";
import Formato from "../../js/db/Formato.js";
import Empleado from "../../js/db/Empleado.js";
import Empresa from "../../js/db/Empresa.js";
import Delegacion from "../../js/db/Delegacion.js";
import CentroTrabajo from "../../js/db/CentroTrabajo.js";
import Departamento from "../../js/db/Departamento.js";
import LineaNegocio from "../../js/db/LineaNegocio.js";
import ListaPrecio from "../../js/db/ListaPrecio.js";
import CondicionPago from "./../../js/db/CondicionPago.js";
import Instalacion from "../../js/db/Instalacion.js";
import Comunidad from "../../js/db/Comunidad.js";
import Servicio from "../../js/db/Servicio.js";
import Cliente from "../../js/db/Cliente.js";
import Inspeccion from "../../js/db/Inspeccion.js";
import validator from "validator";
import ContactoCliente from "../../js/db/ContactoCliente.js";
import Documento from "../../js/db/Documento.js";
import DateUtils from "../../js/DateUtils.js";


export default {
  components: {
    rForm,
    rInput,
    rBadge,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rDireccion,
    rCuenta,
    tabFinanzas,
  },
  data: function() {
    var self = this;
    return {
      DateUtils,
      validator,
      Factura,
      Item,
      Formato,
      Empleado,
      Empresa,
      Delegacion,
      CentroTrabajo,
      Departamento,
      LineaNegocio,
      ListaPrecio,
      CondicionPago,
      Instalacion,
      Comunidad,
      Servicio,
      Cliente,
      ContactoCliente,
      Documento,
      Inspeccion,
      readonly: !this.editable,
      idData: null,
      indexLinea: -1,
      step: 1,
      maxStep: 7,
      clienteSelected: false,
      rform: null,
      tipoReparto: null,
      initLineas: [],
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    totalesLineas: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var totales = self.getTotalesLinea(linea);
        return totales;
      });
    },
    lineasEjecutable: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var res = true;
        // Debe tener Item
        // if (!linea.item || !linea.item.id) {
        //   res = false;
        // }
        // Debe tener Servicio
        if (!linea.servicio || (!linea.servicio.id && !linea.servicio.codigo)) {
          res = false;
        }
        if (linea.tipoLinea != 'LINEA') {
          res = false;
        }
          return res;
      });
    },

  },

  beforeDestroy() {},

  methods: {
    onOpenUrl(url) {
      const self = this;
      window.open(url);
    },
    lineaEditable(linea) {
      const self = this;
      if (self.readonly) {
        return false;
      }
      if (linea.lineStatus && linea.lineStatus == 'O') {
        return true;
      }
      return false;
    },
    getLineaSegundas: function(linea) {
      const self = this;
      if (!linea || !linea.idExpediente) {
        return false;
      }
      return self.$refs.rform.formData.lineas.filter(l => l.idExpedienteRelacionado == linea.idExpediente)[0];
    },
    getNumLineaPrimeras: function (linea) {
      const self = this;
      if (linea.idPedido1as) {
        
        return "Línea de primeras: Pedido: " + linea.numeroPedido1as + " nº " + linea.numeroLinea1as; 
      }
      var codLinea = linea.codigoLinea;

      var parteIzda = codLinea && codLinea.split(".") != null ? codLinea.split(".")[0] : null; 
      var parte2 = parteIzda != null ?  parteIzda.split("-") : null;
      var numLineaPrimeras = parte2 != null ? parte2[1] : null;

      return "Línea de primeras: nº " + ( numLineaPrimeras != null ? numLineaPrimeras : ""); 

    },
    borrarSegundas: function (numeroLinea) {
      var self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Está seguro que quiere eliminar la línea seleccionada?",
        "Advertencia",
        function() {
          app.preloader.show();
          var data = {
            idPedido: self.$refs.rform.formData.id,
            numeroLinea: numeroLinea,
          };
          Factura.borrarSegundas({data: data})
            .then(function(res) {
              self.$refs.rform.formData = res;
              app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: 'Línea borrada correctamente',
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            }).catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            }).finally(function(e) {
              app.preloader.hide();
            });
        }
      );

    },

    getLineasTexto: function(linea) {
      return linea.comentarios.split("##").map(c => c.trim());
    },
    addEmail: function(listName) {
      var self = this;
      var arr = self.$refs.rform.formData.cliente[listName] || [];
      arr.push("");
      self.$set(self.$refs.rform.formData.cliente, listName, arr);
    },
    getMaxNumeroLineas: function(lineas) {
      var maxNumero = -1;
      if (lineas && lineas.length) {
        lineas.forEach(l => {
          if (l.numero > maxNumero) {
            maxNumero = l.numero;
          }
        });
      }
      return maxNumero + 1;
    },
    onBlurPrecioUnitario: function(formData) {
      var self = this;
      self.onBlurPorc2a(formData);
    },
    llamarOnBlur2a: function (formData) {
      var self = this;
      if (formData.porcSegunda) {
        self.onBlurPorc2a(formData);
      }
      else if (formData.importeSegunda) {
        self.onBlurImporte2a(formData);
      }
    }, 
    onBlurPorc2a: function(formData) {
      var self = this;
      var importeUnitario = formData.precioUnitario;
      var porc2a = formData.porcSegunda;
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      if (!!importeUnitario && importeUnitario > 0 && !!porc2a && porc2a > 0) {
        self.$set(formData, "importeSegunda", formatter.format((importeUnitario / 100) * porc2a));
      } else {
        self.$set(formData, "importeSegunda", 0);
      }
    },
    onBlurImporte2a: function(formData) {
      var self = this;
      var importeUnitario = formData.precioUnitario;
      var importe2a = formData.importeSegunda;
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      if (!!importe2a && importe2a > 0 && !!importeUnitario && importeUnitario > 0) {
        self.$set(formData, "porcSegunda", formatter.format((importe2a * 100) / importeUnitario));
      } else {
        self.$set(formData, "porcSegunda", 0);
      }
    },
    onNewLine: function(nuevaLinea) {
      const self = this;
      if (self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero).length) {
          var linea = self.$refs.rform.formData.lineas.filter(l => l.numero == nuevaLinea.numero)[0];
          self.$set(self.$refs.rform.formData.lineas, self.$refs.rform.formData.lineas.indexOf(linea), nuevaLinea);
      } else {
        self.$refs.rform.formData.lineas.push(nuevaLinea);
      }
      if (self.$refs.popupnew) {
        self.$refs.popupnew.close();
      }
    },
    verBotonSiguiente: function(rform) {
      const self = this;

      if (self.step >= self.maxStep || !rform || !rform.formData) {
        return false;
      }
      if (self.readonly) {
        return true;
      }
      if (self.step == 1) {
        return true;
      }
      if (self.step == 2) {
        return true;
      }
      if (self.step == 3) {
        return rform.formData.servicio && rform.formData.servicio.id;
      }

      return true;
    },
    verBotonAnterior: function(rform) {
      const self = this;

      if (self.step <= 1 || !rform || !rform.formData) {
        return false;
      }
      if (self.step == 3) {
        return !rform.formData.firma;
      }

      return true;
    },
    verBotonGuardar: function(rform) {
      const self = this;

      return self.lineaEditable(rform.formData);
    },
    verBotonCerrar: function(rform) {
      const self = this;

      return self.step == self.maxStep && rform && !self.lineaEditable(rform.formData);
    },
    planificar: function(lineas, idPedido) {
      var self = this;
      var app = self.$f7;
      var jsonData = [{
        idPedido: idPedido,
        numeroLineas: lineas.filter((linea, idx) => linea.tipoLinea && linea.tipoLinea != 'TASAS' && linea.tipoLinea != 'SUBCONTRATACION' && linea.tipoLinea != 'COMISION' && linea.estado && (linea.estado.estado == 'CREADO_PEDIDO' || linea.estado.estado == 'PLANIFICADA') && self.lineasEjecutable[idx]).map(linea => linea.numero)
      }]
      app.views.current.router.navigate(
        "/pedidos/planificar/?jsonData=" + JSON.stringify(jsonData)
      );
    },
    beforeSaveItem: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      formData.codigoInstalacion = self.$refs.rformNew.formData.codigoInstalacion;
      resolve(formData);
    },
    onSaveItem: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformItem.clear();
      if (self.$refs.popupItem) {
        self.$refs.popupItem.close();
      }
      if (Array.isArray(self.$refs.item)) {
        self.$refs.item.forEach(i => i.reload());
      } else if (self.$refs.item) {
        self.$refs.item.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'item', res);
      }
      app.toast
        .create({
          text: self.$t("ofertas.item_creado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    beforeSaveInstalacion: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.$refs.rform.formData.cliente.id;
      resolve(formData);
    },
    onSaveInstalacion: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformInstalacion.clear();
      if (self.$refs.popupInstalacion) {
        self.$refs.popupInstalacion.close();
      }
      if (Array.isArray(self.$refs.instalacion)) {
        self.$refs.instalacion.forEach(i => i.reload());
      } else if (self.$refs.instalacion) {
        self.$refs.instalacion.reload();
      }
      if (self.$refs.rformNew && self.$refs.rformNew.formData) {
        self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', res.codigo);
      }
      app.toast
        .create({
          text: self.$t("ofertas.instalacion_creada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    getFormatted(importe) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      return formatter.format(importe);
    },
    getTotalesPedido: function(lineas) {
      var self = this;
      var totales = {
        descuento: 0,
        totalSinDto: 0,
        subtotal: 0,
        iva: 0,
        totalTasas: 0,
        total: 0,
        subtotalProveedor: 0,
        ivaProveedor: 0,
        totalProveedor: 0,

        subtotalComision: 0,
        ivaComision: 0,
        totalComision: 0,

        subtotalTerceros: 0,
        ivaTerceros: 0,
        totalTerceros: 0,

        subtotalConTerceros: 0,
        ivaConTerceros: 0,
        totalConTerceros: 0,
        totalConTasasConTerceros: 0,
        totalSinTasasConTercerosSinIVa: 0,
        // importeAplicado: 0,
        // saldoVencido: 0
      };
      if(!lineas || lineas.length <= 0) {
        return totales;
      }
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      lineas.forEach(linea => {
        var totalesLinea = self.getTotalesLinea(linea);
        for (const t in totales) {
          totales[t] = formatter.format(parseFloat(totales[t]) + parseFloat(totalesLinea[t]));
        }
      });
      return totales;
    },
    getTotalesLinea: function(linea) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
        useGrouping: false
      });
      var cantidad = linea.cantidad;
      var dtoUnitario = linea.precioUnitario / 100 * linea.porcDescuento;
      var descuento = dtoUnitario * cantidad;
      var totalSinDto = linea.precioUnitario * cantidad;
      var totalTasas = linea.tasas * cantidad;
      var subtotal = totalSinDto - descuento;
      var iva = subtotal / 100 * linea.porcIVA;
      var total = subtotal + iva;
      
      // Proveedor
      var proveedor = linea.proveedor || {precio: 0, porcIVA: 21};
      var subtotalProveedor = proveedor.precio * cantidad;
      var ivaProveedor = subtotalProveedor / 100 * proveedor.porcIVA;
      var totalProveedor = subtotalProveedor + ivaProveedor;
      // Comision
      var comision = linea.comision || {precio: 0, porcIVA: 21};
      var subtotalComision = comision.precio * cantidad;
      var ivaComision = subtotalComision / 100 * comision.porcIVA;
      var totalComision = subtotalComision + ivaComision;

      var subtotalTerceros = subtotalProveedor + subtotalComision;
      var ivaTerceros = ivaProveedor + ivaComision;
      var totalTerceros = totalProveedor + totalComision;


      var totales = {
        descuento: formatter.format(descuento),
        totalSinDto: formatter.format(totalSinDto),
        subtotal: formatter.format(subtotal),
        iva: formatter.format(iva),
        totalTasas: formatter.format(totalTasas),
        total: formatter.format(total),
        subtotalProveedor: formatter.format(subtotalProveedor),
        ivaProveedor: formatter.format(ivaProveedor),
        totalProveedor: formatter.format(totalProveedor),

        subtotalComision: formatter.format(subtotalComision),
        ivaComision: formatter.format(ivaComision),
        totalComision: formatter.format(totalComision),

        subtotalTerceros: formatter.format(subtotalTerceros),
        ivaTerceros: formatter.format(ivaTerceros),
        totalTerceros: formatter.format(totalTerceros),

        subtotalConTerceros: formatter.format(subtotal + subtotalProveedor + subtotalComision  ),
        ivaConTerceros: formatter.format(iva + ivaProveedor + ivaComision),
        totalConTerceros: formatter.format(total + totalProveedor + totalComision),
        totalConTasasConTerceros: formatter.format(total + totalProveedor + totalComision + totalTasas),
        totalSinTasasConTercerosSinIVa: formatter.format(total + totalProveedor + totalComision - iva - ivaProveedor - ivaComision),

        importeAplicado: formatter.format(linea.importeAplicado),
        // saldoVencido: formatter.format(linea.saldoVencido)
      };
      
      return totales;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var factura = self.$refs.rform.formData;
      var app = self.$f7;
      for (const idx in data.lineas) {
        var linea = data.lineas[idx];
        linea.importe = self.totalesLineas[idx]["subtotal"];
      }
      resolve(data);
    },
    changeContacto: function() {
      var self = this;
      var app = self.$f7;
      var contactocliente = self.$refs.contactoCliente.selected;
      if (contactocliente && contactocliente.canalesComunicacion) {
        contactocliente.canalesComunicacion.filter(canal=>canal.tipo == "EMAIL").forEach(canal => {
          self.$set(self.$refs.rform.formData, 'emailContacto', canal.valor);
        });
      }
    },
    changeServicio: function(linea) {
      var self = this;
      if (!linea.precioUnitario && linea.servicio) {
        var precio = undefined;
        if (linea.servicio.precios && linea.servicio.precios.length > 0) {
          var listaPrecios = self.$refs.cliente.selected
            ? self.$refs.cliente.selected.listaPrecios
            : undefined;
          if (listaPrecios) {
            precio = linea.servicio.precios.filter(
              s => s.listaPrecios == listaPrecios
            )[0];
          }
          if (!precio) {
            precio = linea.servicio.precios[0];
          }
        }
        if (precio) {
          self.$set(
            linea,
            "precioUnitario",
            precio.precioUnidad
          );
        }
      }
      if (!linea.tasas && linea.servicio) {
        var tasa = undefined;
        if (linea.servicio.tasas && linea.servicio.tasas.length > 0) {
          
          var provincia = linea.direccionInspeccion ? linea.direccionInspeccion.provincia : undefined;
          if (provincia) {
            var tasaDTO = linea.servicio.tasas.filter(s => s.provincia == provincia)[0];
            tasa = tasaDTO ? tasaDTO.valor : undefined;
          }
          if (!tasa) {
            tasa = linea.servicio.tasaDefecto;
          }
        } else {
          tasa = linea.servicio.tasaDefecto;
        }
        if (tasa) {
          self.$set(linea, 'tasas', tasa);
        }
      }
      if (
        !linea.tiempoEstimadoCampo &&
        linea.servicio &&
        linea.servicio.tiempoEstimadoCampo
      ) {
        self.$set(
          linea,
          "tiempoEstimadoCampo",
          linea.servicio.tiempoEstimadoCampo
        );
      }
      if (
        !linea.tiempoEstimadoOficina &&
        linea.servicio &&
        linea.servicio.tiempoEstimadoOficina
      ) {
        self.$set(
          linea,
          "tiempoEstimadoOficina",
          linea.servicio.tiempoEstimadoOficina
        );
      }
      if (
        !linea.codCuentaMayor &&
        linea.servicio &&
        linea.servicio.codCuentaMayor
      ) {
        self.$set(
          linea,
          "codCuentaMayor",
          linea.servicio.codCuentaMayor
        );
      }
    },
    beforeChangeItem: function (itemNuevo) {
      var self = this;
      var itemViejo = self.$refs.rformNew.formData.item;
      if (typeof itemViejo != 'undefined' && itemViejo != null){
        itemViejo.codigo = null;
        itemViejo.nombre = null;
        itemViejo.codInstalacion = null;
        itemViejo.id = null;
        itemNuevo = Object.assign({}, itemViejo, itemNuevo);
      }
      return itemNuevo;

    },
    changeItem: function(linea) {
      var self = this;
      if (!linea.direccionInspeccion && linea.item && linea.item.direccion) {
          self.$set(self.$refs.rformNew.formData, 'direccionInspeccion', linea.item.direccion);
      }
      if (!linea.codigoInstalacion && linea.item && linea.item.codigoInstalacion) {
          self.$set(self.$refs.rformNew.formData, 'codigoInstalacion', linea.item.codigoInstalacion);
      }
    },
    loadItem: function(res) {
      const self = this;
      self.$set(self.$refs.rform, "formData", res);
      if (!res.lineas || res.lineas == null) {
        self.$set(self.$refs.rform.formData, "lineas", []);
      } else {
        self.$set(self.$refs.rform.formData, "lineas", self.getSortedLineas(res.lineas));
      }

      // cargamos las finanzas, aunque si metemos todo el res al formData no lo veo necesario.
      if(!res.finanzas) {
        self.$set(self.$refs.rform.formData, "finanzas", {});
      } 

      self.initLineas = JSON.parse(JSON.stringify(res.lineas));

      self.clienteSelected = res.cliente && res.cliente.id;
      //self.initDatosFinanzas(res.finanzas.codCondicionesPago);
      if (!res.inspectores || res.inspectores == null) {
        self.$set(self.$refs.rform.formData, "inspectores", []);
      }
      // if (res.cliente) {
      //   self.changeCliente();
      // }
    },
    getSortedLineas: function(lineas) {
      var compare = function (a, b) {
        if (!a.estado || !a.estado.estado) {
          return -1;
        }
        if (!b.estado || !b.estado.estado) {
          return 1;
        }
        if (a.estado.estado == b.estado.estado) {
          return a.numero < b.numero ? -1 : 1; 
        }
        if (a.estado.estado == "CREADA_OFERTA") {
          return -1;
        }
        return 1;
      }
      return lineas.sort(compare);
    },
    cancelarFactura: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("facturas.pregunta_cancelar"),
        "Eliminar",
        function() {
          Factura.cancelar({ itemId: self.id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$f7router.back();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Factura.delete({ itemId: self.id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$f7router.back();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$set(self.$refs.rform, "formData", res);
      self.$refs.tabFinanzas.initDatosFinanzas(res.finanzas.codCondicionesPago);     
      self.idData = res.id;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    filterViasPago: function(autocomplete){
      var self = this;
      return function(query, render) {
        var results = autocomplete.items.filter(item => self.condicionesPagoActivas.some(condicion => condicion.viaPago.codigo == item.codigo))
          .map(condicion => {
            condicion['txtValue'] = condicion[autocomplete.searchProp];
            return condicion;
          });
        render(results);
      };
    },
    filterCondicionesCuentas: function(autocomplete){
      var self = this;
      return function(query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.cuenta))
          .map(condicion => {
            if(condicion["cuenta"] != undefined){
              condicion['txtValue'] = ""+condicion["cuenta"].trim();
            }
            return condicion;
          });
        options.sort((a,b) => a.cuenta > b.cuenta ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });

        render(results);
      };
    },
    filterCondicionesPlazos: function(autocomplete){
      var self = this;
      return function(query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.plazoDias != undefined ))
          .map(condicion => {
            if(condicion["plazoDias"] != undefined){
              condicion['txtValue'] = ""+condicion["plazoDias"];
            }
            return condicion;
          });
        options.sort((a,b) => a.plazoDias > b.plazoDias ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });

        render(results);
      };
    },
    getTextoBadge: function(linea, lineaEjecutable) {

      if (linea.tipoLinea == 'TASAS') {
        return "Línea de tasas";
      }
      else if (linea.tipoLinea == 'SUBCONTRATACION') {
        return "Línea de subcontratación";
      }
      else if (linea.tipoLinea == 'COMISION') {
        return "Línea de comisión";
      }
      else if (linea.servicio && linea.servicio.codigo){
        return "Línea de Servicio";
      } else {
        return "Línea sin servicio";
      }
      
    },
    getColorBadge: function(linea, lineaEjecutable) {

      if (linea.tipoLinea == 'TASAS') {
        return "blue";
      } else if (linea.tipoLinea == 'SUBCONTRATACION') {
        return "orange";
      } else if (linea.tipoLinea == 'COMISION') {
        return "red";
      } else {
        return "green";
      }
      
    },

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
    CondicionPago.getList({filter:{activa:true}}).then(function(res){
      self.condicionesPagoActivas = res.list;
    });
    // if(self.$refs.rform && self.$refs.rform.formData && !self.$refs.rform.formData.finanzas) {
    //   self.$set(self.$refs.rform.formData, "finanzas", {});
    // }
  }
};
</script>
