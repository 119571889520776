
import MediumEditor from 'medium-editor';

var CaracteristicaListado = MediumEditor.Extension.extend({
    name: 'caracteristicaListado',
    title: 'Característica tipo lista',

    init: function () {
        this.button = this.document.createElement('button');
        this.button.classList.add('medium-editor-action');
        this.button.innerHTML = 'LI';
        this.button.title = this.title;
        this.on(this.button, 'click', this.handleClick.bind(this));
    },

    getButton: function () {
        return this.button;
    },

    handleClick: function (event) {
        var inputHtml = this.getCurrentSelection();
        const tooltip = this.title + ' ' + inputHtml;
        var sel = window.getSelection();
        var selected = sel.anchorNode;
        var parent = selected.parentElement;

        if (this.isActive()) {
            if (selected.nodeName.toUpperCase() == this.name.toUpperCase()) {
                const originalData = selected.firstElementChild.getAttribute("original-data");
                parent.removeChild(selected);
                parent.innerHTML = originalData;
            }
            return;
        }

        if (window.getSelection && (sel = window.getSelection()).rangeCount) {
            var range = sel.getRangeAt(0);
            range.deleteContents();
            var element = document.createElement(this.name);
            // element.setAttribute("contenteditable", "false");
            element.setAttribute("original-data", inputHtml);
            element.setAttribute("title", tooltip);
            element.setAttribute("data-type", "list");
            element.setAttribute("data-type-value", "caracteristicas");
            element.setAttribute("data-recursive-field", "hijas");
            element.setAttribute("data-condition", `"${inputHtml}".equalsIgnoreCase("[nombre]")`);
            element.innerHTML = "[valor:list]";
            range.insertNode(element);
            // Move the caret immediately after the inserted span
            range.setStartAfter(element);

            // Ahora añadimos un espacio al final de range para que no se quede pegado al elemento
            const auxElem = document.createTextNode('\u00A0');
            range.insertNode(auxElem);
            range.setStartAfter(auxElem);
            
            sel.removeAllRanges();
            sel.addRange(range);
        }

        this.base.checkContentChanged();
    },

    isAlreadyApplied: function (node) {
        return node.nodeName.toUpperCase() === this.name.toUpperCase();
    },

    isActive: function () {
        return this.button.classList.contains('medium-editor-button-active');
    },

    setInactive: function () {
        this.button.classList.remove('medium-editor-button-active');
    },

    setActive: function () {
        this.button.classList.add('medium-editor-button-active');
    },

    getSelect: function () {
        return this.getForm().querySelector('select.medium-editor-toolbar-select');
    },

    getCurrentSelection: function() {
        var html = ''
        var sel

        if (typeof window.getSelection != 'undefined') {
        sel = window.getSelection()
        if (sel.rangeCount) {
            var container = document.createElement('div')
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents())
            }
            html = container.innerHTML
        }
        } else if (typeof document.selection != 'undefined') {
            if (document.selection.type == 'Text') {
                html = document.selection.createRange().htmlText
            }
        }
        return html
    }
})

export default CaracteristicaListado;