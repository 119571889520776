import DatabaseModel from './DatabaseModelOffline.js';

var Municipio = Object.create(DatabaseModel);

Municipio.model = 'municipios';

Municipio.getCombo = function(params, shownFields = []){
    var self = this;
    var comboParams = Object.assign({}, params);
    // Si vamos por mongo, hacer que en los params se hagan como un like insensitivo
    if (comboParams.filter) {
        for (var f in comboParams.filter) {
            if (f == undefined) {
                continue;
            }
            if (f == 'cp') {
                if (process.env.TARGET == 'web') {
                    comboParams.filter['cps'] = { "$contains": comboParams.filter[f]};
                } else {
                    comboParams.filter['cps'] = { "$in": [comboParams.filter[f]]};
                }
                delete comboParams.filter[f];
                continue;
            }
            comboParams.filter[f] = { "$regex": comboParams.filter[f], "$options": "i" };
        }
    }
    if(shownFields.length){
        comboParams.projection = shownFields.join(",");
    }
    return self.getList(comboParams);
};

export default Municipio;