<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('municipios.municipio')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Municipio"
        :tableCode="'MUNICIPIO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :controlsWidth="100"
        :funcShowCheck=" _=> false"
      >

        <template v-slot:tableLinks>
          <a class="link" href="/municipios/new/">Nuevo</a>
        </template>  
        <template v-slot:optionsColumn="{item, rlist}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/municipios/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/municipios/edit/${item.id}/`"></f7-link>
          <f7-link v-tooltip="'Borrar'" icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Municipio from "./../../js/db/Municipio.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Municipio,
      Utils
    };
  },
  computed: {},
  methods: {

  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>