<template>
  <!-- <f7-popup tablet-fullscreen ref="popupVerEmails" class="popup-ver-emails" v-if="popupOpened" :opened="true"  @popup:close="onPopupClosed" swipe-to-close> -->
  <f7-popup 
    tablet-fullscreen
    ref="popupListadoEmail"
    class="popup-listado-email"
    :opened="true"
    @popup:opened="onPopupOpened"
    @popup:closed="onPopupClosed"
    swipe-to-close
  >
    <f7-page>
      <f7-navbar :title="'Emails de la tarea ' + filterValue">
        <f7-nav-right>
          <f7-link popup-close>Cerrar</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <!-- rList Table CARD-->
        <r-dynamic-list
          ref="rlist"
          :dbAdapter="RegistroEmail"
          :tableCode="'REGISTROEMAILTABS'"
          :primary="['id']"
          :tableMode="true"
          :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
          class="card full-height data-table"
          classContent="card-content full-height"
          :shownFields="['datos']"
          :massiveActions="false"
          :mandatoryFilter ="getFilter()"
          :funcShowCheck="_ => {return false;}"
          :controlsWidth="45"
          :searchOnInit="true"
          useView="vista_RegistroEmails"
        >
          <template v-slot:optionsColumn="{item}">
            <f7-link icon-f7="eye" color="green" external :href="getEmailUrl(item)"></f7-link>
          </template>
        </r-dynamic-list>
      </f7-card>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
</style>
<script>
import rDynamicList from "./rDynamicList.vue";
import RegistroEmail from "./../js/db/RegistroEmail.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      RegistroEmail,
      popupOpened: false,
    };
  },
  props: {
    filterName: {
      type: String,
      default: ""
    },
    filterValue: {
      type: String,
      default: null
    }
  },
  computed: {},
  methods: {
    getFilter() {
      const self = this;
      return {
        [self.filterName]: self.filterValue
      };
    },
    getEmailUrl(item) {
      const self = this;
      var currentUrl = new URL(window.location.href);
      return currentUrl.pathname + ('#!/' + `registroemail/view/${item.id}/`);
    },
    onPopupOpened: function(e) {
      var self = this;
      var app = self.$f7;
      self.popupOpened = true;
      this.$emit('listado-abierto', true);
    },
    onPopupClosed: function(e) {
      var self = this;
      var app = self.$f7;
      this.$emit('listado-cerrado', true);

    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>