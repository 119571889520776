
var ValidationUtils = {
    DNI_REGEX: /^(\d{8})([A-Z])$/,
    CIF_REGEX: /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/,
    NIE_REGEX: /^[XYZ]\d{7,8}[A-Z]$/,
    validateSpanishID: function( str ) {
        // Ensure upcase and remove whitespace
        str = str.toUpperCase().replace(/\s/, '');
        if (str.startsWith('ES')) {
            str = str.substring(2);
        }
        const self = ValidationUtils;

        var valid = true;
        var type = self.spainIdType( str );

        switch (type) {
            case 'dni':
                valid = self.validDNI( str );
                break;
            case 'nie':
                valid = self.validNIE( str );
                break;
            case 'cif':
                valid = self.validCIF( str );
                break;
        }

        return valid;

    },
    spainIdType: function( str ) {
        const self = ValidationUtils;
        if ( str.match( self.DNI_REGEX ) ) {
            return 'dni';
        }
        if ( str.match( self.CIF_REGEX ) ) {
            return 'cif';
        }
        if ( str.match( self.NIE_REGEX ) ) {
            return 'nie';
        }
    },
    validDNI: function( dni ) {
        const self = ValidationUtils;
        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter = dni_letters.charAt( parseInt( dni, 10 ) % 23 );
        
        return letter == dni.charAt(8);
    },
    validNIE: function( nie ) {
        const self = ValidationUtils;
        // Change the initial letter for the corresponding number and validate as DNI
        var nie_prefix = nie.charAt( 0 );

        switch (nie_prefix) {
            case 'X': nie_prefix = 0; break;
            case 'Y': nie_prefix = 1; break;
            case 'Z': nie_prefix = 2; break;
        }

        return self.validDNI( nie_prefix + nie.substr(1) );

    },
    validCodigoPostal: function( cp ) {
        const self = ValidationUtils;
        //Que todos los caracteres sean números
        var stringNumericoLength5 = /^[0-9]{5}$/;
        if (!cp.match(stringNumericoLength5)){
            return false;
        } 
        var cifrasProv = cp.substring(0,2);
        var cifrasProvNum = parseInt(cifrasProv);
        if (cifrasProvNum <= 0 || cifrasProvNum > 53) {
            return false;
        } else {
            return true
        }
            
    },
    validCIF: function( cif ) {
        const self = ValidationUtils;
        var match = cif.match( self.CIF_REGEX );
        var letter  = match[1],
            number  = match[2],
            control = match[3];

        var even_sum = 0;
        var odd_sum = 0;
        var n;

        for ( var i = 0; i < number.length; i++) {
            n = parseInt( number[i], 10 );

            // Odd positions (Even index equals to odd position. i=0 equals first position)
            if ( i % 2 === 0 ) {
                // Odd positions are multiplied first.
                n *= 2;

                // If the multiplication is bigger than 10 we need to adjust
                odd_sum += n < 10 ? n : n - 9;

                // Even positions
                // Just sum them
            } else {
                even_sum += n;
            }

        }

        var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1) );
        if (control_digit == 10) {
            control_digit = 0;
        }
        var control_letter = 'JABCDEFGHI'.substr( control_digit, 1 );

        // Control must be a digit
        if ( letter.match( /[ABEH]/ ) ) {
            return control == control_digit;

        // Control must be a letter
        } else if ( letter.match( /[KPQS]/ ) ) {
            return control == control_letter;

        // Can be either
        } else {
            return control == control_digit || control == control_letter;
        }

    }
}

export default ValidationUtils;