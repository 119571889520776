import LineaNegocios from './lineasnegocio.vue';
import LineaNegocioForm from './lineasnegocio-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/lineasnegocio/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasnegocio', ['ADMIN', 'INSPECTOR'], LineaNegocios),
        keepAlive: true
    },
    {
        path: '/lineasnegocio/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasnegocio/new', ['ADMIN', 'INSPECTOR'], LineaNegocioForm)
    },
    {
        path: '/lineasnegocio/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasnegocio/edit', ['ADMIN', 'INSPECTOR'], LineaNegocioForm)
    },
    {
        path: '/lineasnegocio/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('lineasnegocio/view', ['ADMIN', 'INSPECTOR'], LineaNegocioForm, { editable: false })
    },
];
export default routes;