import MediumEditor from 'medium-editor';
import Picker from 'vanilla-picker';

var ColorPicker = MediumEditor.extensions.form.extend({
    name: "colorpicker",
    action: "applyForeColor",
    aria: "color picker",
    contentDefault: "Col.",
    contentFA: '<i class="fa fa-paint-brush"></i>',
    editor: null,
    pickerExtension: null,
    currentTextSelection: null,

    init: function () {
        MediumEditor.extensions.form.prototype.init.apply(this, arguments);
    },

    // Called when the button the toolbar is clicked
    // Overrides ButtonExtension.handleClick
    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();

        this._showForm();

        return false;
    },

    _showForm(){
        if (!this.isDisplayed()) {
            // Get fontsize of current selection (convert to string since IE returns this as number)
            // var fontSize = this.document.queryCommandValue('fontSize') + '';
            this.showForm();
        }
    },

    // Called by medium-editor to append form to the toolbar
    getForm: function () {
        if (!this.form) {
            this.form = this.createForm();
        }
        return this.form;
    },

    // Used by medium-editor when the default toolbar is to be displayed
    isDisplayed: function () {
        return this.getForm().style.display === 'block' && Array.from(this.getForm().parentElement.classList).indexOf("medium-editor-toolbar-active") >= 0;
    },

    hideForm: function () {
        this.getForm().style.display = 'none';
    },

    showForm: function () {
        this.base.saveSelection();
        this.hideToolbarDefaultActions();
        this.getForm().style.display = 'block';
        this.creating = true;
        this.initPicker(this.colorpicker);
        this.creating = false;
        this.setToolbarPosition();
    },

    // Called by core when tearing down medium-editor (destroy)
    destroy: function () {
        if (!this.form) {
            return false;
        }

        if (this.form.parentNode) {
            this.form.parentNode.removeChild(this.form);
        }

        delete this.form;
    },

    // core methods

    doFormSave: function () {
        this.base.restoreSelection();
        this._execColor();
    },

    // form creation and event handling
    createForm: function () {
        var doc = this.document,
            form = doc.createElement('div'),
            colorpicker = doc.createElement('div');

        this.colorpicker = colorpicker;

        // Font Size Form (div)
        form.className = 'medium-editor-toolbar-form';
        form.id = 'medium-editor-toolbar-form-colorpicker-' + this.getEditorId();

        // Handle clicks on the form itself
        this.on(form, 'click', this.handleFormClick.bind(this));

        // Add font size slider
        colorpicker.className = 'medium-editor-toolbar-colorpicker';
        form.appendChild(colorpicker);

        return form;
    },

    initPicker(element) {
        let self = this;
        if(!self.picker){
            self.picker = new Picker({
                parent: element,
                popup: false,
                onChange: function(color) { self.handleColorChange(color); },
                onDone: function() { self.doFormSave(); }
            });
            if(self.picker.domElement.getElementsByClassName("picker_done").length
                    && self.picker.domElement.getElementsByClassName("picker_done")[0].getElementsByTagName("button").length){
                self.picker.domElement.getElementsByClassName("picker_done")[0].getElementsByTagName("button")[0].innerText = "Aplicar";
            }
        }
    },

    clearFontSize: function () {
        MediumEditor.selection.getSelectedElements(this.document).forEach(function (el) {
            if (el.nodeName.toLowerCase() === 'font' && el.hasAttribute('size')) {
                el.removeAttribute('size');
            }
        });
    },

    handleColorChange: function (color) {
        this.color = color;
    },

    handleFormClick: function (event) {
        // make sure not to hide form when clicking inside the form
        event.stopPropagation();
    },

    _execColor: function(){
        if(this.creating){return;}
        let color = this.color;
        this.base.saveSelection();
        this.execAction('foreColor', { value: color.hex });
        // En este punto, el navegador debería haber gestionado el cambio de letra incluyendo elementos <font> donde sea necesario.
        // Hay que reemplazar estos elementos por elementos con nuestras clases personalizadas
        this._cleanFonts();
        this.base.restoreSelection();
        this._showForm();
    },

    _cleanFonts: function() {
        var fontsArr = Array.from(this.document.getElementsByTagName("font"));
        fontsArr.filter(font => font.getAttribute("color")).forEach(font => this._cleanFont(font));
    },
    _cleanFont: function(font){
        var parent = font.parentNode,
            color = font.getAttribute("color"),
            newElement = this.document.createElement("span");
        newElement.classList.add("custom-fc");
        newElement.style.color = color;
        while (font.firstChild) {
            newElement.appendChild(font.firstChild); // *Moves* the child
        }
        
        parent.replaceChild(newElement, font);
    },
});

export default ColorPicker;