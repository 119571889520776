<template>
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    @save="onSave"
    @loadItem="loadItem"
    :readonly="readonly"
    :dbAdapter="Proveedor"
    :defaultData="{instalaciones:[], canalesComunicacion:[], contactos:[], direcciones:[]}"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="idData && !readonly" sliding>{{$t('proveedores.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="idData" sliding>{{$t('proveedores.proveedor')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('proveedores.nuevo')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <div class="label-group col-100">Datos Generales</div>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.codigo')"
              :form="rform"
              type="text"
              name="codigo"
              v-if="id"
              :readonly="true"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.acronimo')"
              :form="rform"
              type="text"
              name="acronimo"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.nombre')"
              :form="rform"
              type="text"
              name="nombre"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.nif')"
              :form="rform"
              type="text"
              name="nif"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.razonSocial')"
              :form="rform"
              type="text"
              name="razonSocial"
            ></r-input>
            <r-autocomplete
              :dbAdapter="EmpresaMantenedora"
              class="col-25"
              floating-label
              :label="$t('proveedores.empresaMantenedora')"
              :form="rform"
              name="empresaMantenedora"
              labelProp="nombre"
              idProp="codigo"
              idFieldDetail="id"
            ></r-autocomplete>
            <r-autocomplete
              :dbAdapter="GrupoProveedor"
              class="col-25"
              floating-label
              :label="$t('proveedores.grupoproveedor')"
              :form="rform"
              name="grupoProveedor"
              labelProp="nombre"
              idProp="codigo"
              idFieldDetail="id"
            ></r-autocomplete>
            <r-input
              class="col-25"
              :label="$t('proveedores.potencial')"
              :form="rform"
              type="check"
              name="potencial"
            ></r-input>
            <r-input
              class="col-25"
              :label="$t('proveedores.tipoProveedor')"
              :form="rform"
              type="select"
              name="tipoProveedor"
            >
              <option value="D">Directo</option>
              <option value="I">Indirecto</option>
            </r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.fechaCaptacion')"
              :form="rform"
              type="datepicker"
              name="fechaCaptacion"
              :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
            ></r-input>
            <r-autocomplete
              class="col-25"
              ref="comunidad"
              :dbAdapter="Comunidad"
              floating-label
              :label="$t('proveedores.comunidadAutonoma')"
              :form="rform"
              name="comunidadAutonoma"
              labelProp="nombre"
              idProp="codigo"
              idFieldDetail="id"
            ></r-autocomplete>
            <r-input
              class="col-25"
              :label="$t('proveedores.moneda')"
              :form="rform"
              type="select"
              name="moneda"
            >
              <option selected value="EUR">Euro</option>
            </r-input>
            <div class="label-group col-100">Impuestos</div>
            <r-input
              class="col-25"
              :label="$t('proveedores.recargoEquivalencia')"
              :form="rform"
              type="check"
              name="recargoEquivalencia"
            ></r-input>
            <r-input
              class="col-25"
              :label="$t('proveedores.impuestoDiferido')"
              :form="rform"
              type="check"
              name="impuestoDiferido"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.codigoOperacion347')"
              :form="rform"
              type="text"
              name="codigoOperacion347"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('proveedores.operacionAseguradora347')"
              :form="rform"
              type="check"
              name="operacionAseguradora347"
            ></r-input>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 margin-horizontal">
          <f7-button fill tab-link="#tab-canalesComunicacion" tab-link-active>Canales Comunicación</f7-button>
          <f7-button fill tab-link="#tab-direcciones">Direcciones</f7-button>
          <f7-button fill tab-link="#tab-contactos">Contactos</f7-button>
          <f7-button fill tab-link="#tab-finanzas">Pago y Finanzas</f7-button>
          <f7-button fill tab-link="#tab-servicios">Servicios</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-canalesComunicacion" tab-active>
          <!-- Canales Comunicacion -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('proveedores.canalescomunicacion')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.canalesComunicacion.push({tipo: '', nombre: '', valor:''})"
            >{{$t('centrostrabajo.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.canalesComunicacion"
              :key="'canalcomunicacion-'+index"
              :style="'z-index:'+(rform.formData.canalesComunicacion.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.tipo')"
                        :form="rform"
                        type="select"
                        :name="'canalesComunicacion.' + index + '.tipo'"
                      >
                        <option selected></option>
                        <option value="TELEFONO">{{$t('proveedores.telefono')}}</option>
                        <option value="TELEFONO_MOVIL">{{$t('proveedores.telefono_movil')}}</option>
                        <option value="FAX">{{$t('proveedores.fax')}}</option>
                        <option value="EMAIL">{{$t('proveedores.email')}}</option>
                        <option value="WEB">{{$t('proveedores.web')}}</option>
                      </r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.nombre')"
                        :form="rform"
                        :name="'canalesComunicacion.' + index + '.nombre'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.valor')"
                        :form="rform"
                        :name="'canalesComunicacion.' + index + '.valor'"
                      ></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.canalesComunicacion,index);"
                class="margin-half"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
            </f7-card>
          </div>
        </f7-tab>
        <f7-tab id="tab-direcciones">
          <f7-list class="list-form">
            <f7-row>
              <r-input
                class="col-50"
                :label="$t('proveedores.direccionDefectoFacturas')"
                :form="rform"
                type="select"
                name="direccionDefectoFacturas"
              >
                <option
                  v-for="(item,index) in rform.formData.direcciones.filter(d => d.tipo == 'DESTINATARIO_FACTURA')"
                  :key="'direccionDefectoFacturas-'+index"
                  :value="item.alias"
                >{{item.alias}}</option>
              </r-input>
              <r-input
                class="col-50"
                :label="$t('proveedores.direccionDefectoEnvio')"
                :form="rform"
                type="select"
                name="direccionDefectoEnvio"
              >
                <option
                  v-for="(item,index) in rform.formData.direcciones.filter(d => d.tipo == 'ENVIAR_A')"
                  :key="'direccionDefectoEnvio-'+index"
                  :value="item.alias"
                >{{item.alias}}</option>
              </r-input>
            </f7-row>
          </f7-list>
          <!-- Direcciones -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('proveedores.direcciones')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.direcciones.push({})"
            >{{$t('proveedores.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.direcciones"
              :key="'direcciones-'+index"
              :style="'z-index:'+(rform.formData.direcciones.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.alias')"
                        :form="rform"
                        :name="'direcciones.' + index + '.alias'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.nif')"
                        :form="rform"
                        :name="'direcciones.' + index + '.nif'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.tipo')"
                        :form="rform"
                        type="select"
                        :name="'direcciones.' + index + '.tipo'"
                      >
                        <option selected></option>
                        <option value="ENVIAR_A">{{$t('proveedores.enviar_a')}}</option>
                        <option
                          value="DESTINATARIO_FACTURA"
                        >{{$t('proveedores.destinatario_factura')}}</option>
                      </r-input>
                      <r-direccion :form="rform" :name="'direcciones.' + index"></r-direccion>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.direcciones,index);"
                class="margin-half"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
            </f7-card>
          </div>
        </f7-tab>
        <f7-tab id="tab-contactos">
          <f7-list class="list-form">
            <f7-row>
              <r-input
                class="col-50"
                :label="$t('proveedores.contactoDefecto')"
                :form="rform"
                type="select"
                name="contactoDefecto"
              >
                <option
                  v-for="(item,index) in rform.formData.contactos"
                  :key="'contactosDefecto-'+index"
                  :value="item.alias"
                >{{item.alias}}</option>
              </r-input>
            </f7-row>
          </f7-list>
          <!-- Contactos -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('proveedores.contactos')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.contactos.push({activo:true})"
            >{{$t('proveedores.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.contactos"
              :key="'contactos-'+index"
              :style="'z-index:'+(rform.formData.contactos.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.alias')"
                        :form="rform"
                        :name="'contactos.' + index + '.alias'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.nombre')"
                        :form="rform"
                        :name="'contactos.' + index + '.nombre'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.apellidos')"
                        :form="rform"
                        :name="'contactos.' + index + '.apellidos'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('proveedores.posicion')"
                        :form="rform"
                        :name="'contactos.' + index + '.posicion'"
                      ></r-input>
                      <r-input
                        class="col-66"
                        floating-label
                        :label="$t('proveedores.direccion')"
                        :form="rform"
                        :name="'contactos.' + index + '.direccion'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        :label="$t('proveedores.activo')"
                        :form="rform"
                        type="check"
                        :name="'contactos.' + index + '.activo'"
                      ></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.contactos,index);"
                class="margin-half"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
            </f7-card>
          </div>
        </f7-tab>
        <f7-tab id="tab-finanzas">
          <!-- Pago y Finanzas -->
          <!-- <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
              {{$t('proveedores.f')}}
              <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.contactos.push({activo:true})">{{$t('proveedores.add')}}</f7-link>
          </f7-block-title>-->

          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete
                      :dbAdapter="CondicionPago"
                      class="col-50"
                      floating-label
                      :label="$t('proveedores.condicionPago')"
                      :form="rform"
                      name="condPago"
                      labelProp="nombre"
                      idProp="codigo"
                      idFieldDetail="id"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="ListaPrecio"
                      class="col-50"
                      floating-label
                      :label="$t('proveedores.listaPrecios')"
                      :form="rform"
                      name="listaPrecios"
                      labelProp="nombre"
                      idProp="codigo"
                      idFieldDetail="id"
                    ></r-autocomplete>
                    <div class="label-group">Cuenta Proveedor</div>
                    <r-cuenta :form="rform" name="cuenta"></r-cuenta>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form" style="z-index: 40">
                <ul>
                  <f7-row>
                    <r-autocomplete
                      :dbAdapter="ViaPago"
                      class="col-100"
                      floating-label
                      :label="$t('clientes.viaPagoDefecto')"
                      :form="rform"
                      name="viaPagoDefecto"
                      labelProp="nombre"
                      idProp="codigo"
                      idFieldDetail="id"
                    ></r-autocomplete>
                    <r-autocomplete-m2m
                      :dbAdapter="ViaPago"
                      class="col-100"
                      floating-label
                      :label="$t('clientes.viasPago')"
                      :form="rform"
                      name="viasPago"
                      labelProp="nombre"
                      type="textarea"
                      :lineBreak="true"
                      idProp="codigo"
                    ></r-autocomplete-m2m>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-servicios">
          <!-- Servicios -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete-m2m
                      :dbAdapter="Servicio"
                      class="col-100"
                      floating-label
                      :label="$t('clientes.servicio')"
                      :form="rform"
                      name="servicios"
                      labelProp="nombre"
                      idProp="codigo"
                      type="textarea"
                      :lineBreak="true"
                    ></r-autocomplete-m2m>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
      </f7-tabs>
    </template>
    <r-form
      ref="rformInstalacion"
      v-slot="{rform}"
      :itemId="null"
      @save="onSaveInstalacion"
      :readonly="readonly"
      :dbAdapter="Instalacion"
      :beforeSave="beforeSaveInstalacion"
    >
      <f7-popup ref="popupInstalacion" class="popup-instalacion" swipe-to-close>
        <f7-page>
          <f7-navbar title="Instalación">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        class="col-50"
                        floating-label
                        :label="$t('proveedores.codigo')"
                        :form="rform"
                        type="text"
                        name="codigo"
                      ></r-input>
                      <r-input
                        class="col-50"
                        floating-label
                        :label="$t('proveedores.nombre')"
                        :form="rform"
                        type="text"
                        name="nombre"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-row>
              <f7-col width="100" medium="100">
                <f7-button
                  fill
                  icon-f7="save"
                  text="Guardar Instalación"
                  class="display-flex margin-left"
                  color="green"
                  @click="$refs.rformInstalacion.saveItem()"
                ></f7-button>
              </f7-col>
            </f7-row>
          </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </r-form>
    <r-form
      ref="rformItem"
      v-slot="{rform}"
      :itemId="null"
      @save="onSaveItem"
      :readonly="readonly"
      :dbAdapter="Item"
      :beforeSave="beforeSaveItem"
    >
      <f7-popup ref="popupItem" class="popup-item" swipe-to-close>
        <f7-page>
          <f7-navbar title="Item">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <r-input
                        floating-label
                        :label="$t('proveedores.codigo')"
                        :form="rform"
                        type="text"
                        name="codigo"
                      ></r-input>
                      <r-input
                        floating-label
                        :label="$t('proveedores.nombre')"
                        :form="rform"
                        type="text"
                        name="nombre"
                      ></r-input>
                      <r-autocomplete
                        :dbAdapter="Formato"
                        floating-label
                        :label="$t('proveedores.formato')"
                        :form="rform"
                        name="formato"
                        nameId="formatoId"
                        labelProp="nombre"
                        idProp="id"
                      ></r-autocomplete>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-row>
              <f7-col width="100" medium="100">
                <f7-button
                  fill
                  icon-f7="save"
                  text="Guardar Item"
                  class="display-flex margin-left"
                  color="green"
                  @click="$refs.rformItem.saveItem()"
                ></f7-button>
              </f7-col>
            </f7-row>
          </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
    </r-form>
  </r-form>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
</style>
<style scoped>
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rCuenta from "./../../components/rCuenta.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Proveedor from "./../../js/db/Proveedor.js";
import Item from "./../../js/db/Item.js";
import Formato from "./../../js/db/Formato.js";
import GrupoProveedor from "./../../js/db/GrupoProveedor.js";
import EmpresaMantenedora from "./../../js/db/EmpresaMantenedora.js";
import ListaPrecio from "./../../js/db/ListaPrecio.js";
import CondicionPago from "./../../js/db/CondicionPago.js";
import ViaPago from "./../../js/db/ViaPago.js";
import Instalacion from "./../../js/db/Instalacion.js";
import Comunidad from "./../../js/db/Comunidad.js";
import Servicio from "./../../js/db/Servicio.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rDireccion,
    rCuenta
  },
  data: function() {
    var self = this;
    return {
      Proveedor,
      Item,
      Formato,
      GrupoProveedor,
      EmpresaMantenedora,
      ListaPrecio,
      CondicionPago,
      ViaPago,
      Instalacion,
      Comunidad,
      Servicio,
      readonly: !this.editable,
      itemsInstalacion: [],
      isTitularOpen: false,
      openedInstalacion: {},
      idData: null
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    borrarItem: function(id) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Item.delete({ itemId: id })
            .then(function(res) {
              if (res) {
                self.itemsInstalacion = self.itemsInstalacion.filter(
                  i => i.id != id
                );
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    borrarTitular: function(instalacion, titular) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          instalacion.titulares = instalacion.titulares.filter(
            t => t.nif != titular.nif
          );
        }
      );
    },
    borrarInstalacion: function(codigo) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Instalacion.delete({ proveedorId: self.idData, codigo: codigo })
            .then(function(res) {
              if (res) {
                self.$refs.rform.formData = res;
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    openTitular: function(event) {
      var self = this;
      self.isTitularOpen = true;
    },
    openInstalacion: function(instalacion) {
      var self = this;
      var app = self.$f7;
      if (self.isTitularOpen) {
        self.isTitularOpen = false;
        return;
      }
      self.itemsInstalacion = [];
      app.preloader.show();
      self.openedInstalacion = instalacion;
      var params = {
        filter: {
          idProveedor: self.idData,
          codigoInstalacionEq: instalacion.codigo
        }
      };
      Item.getList(params)
        .then(function(res) {
          self.itemsInstalacion = res.list;
        })
        .catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.error || e.error_description;
          app.toast
            .create({
              text: error,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        })
        .then(function() {
          app.preloader.hide();
        });
    },
    loadItem: function(res) {
      var self = this;
      if (!res.instalaciones || res.instalaciones == null) {
        self.$set(self.$refs.rform.formData, "instalaciones", []);
      }
      if (!res.canalesComunicacion || res.canalesComunicacion == null) {
        self.$set(self.$refs.rform.formData, "canalesComunicacion", []);
      }
      if (!res.direcciones || res.direcciones == null) {
        self.$set(self.$refs.rform.formData, "direcciones", []);
      }
      if (!res.contactos || res.contactos == null) {
        self.$set(self.$refs.rform.formData, "contactos", []);
      }
      if (!res.viasPago || res.viasPago == null) {
        self.$set(self.$refs.rform.formData, "viasPago", []);
      }
      if (!res.servicios || res.servicios == null) {
        self.$set(self.$refs.rform.formData, "servicios", []);
      }
    },
    beforeSaveInstalacion: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.proveedorId = self.idData;
      resolve(formData);
    },
    beforeSaveItem: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.proveedorId = self.idData;
      formData.codigoInstalacion = self.openedInstalacion.codigo;
      resolve(formData);
    },
    onSaveItem: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      if (!self.itemsInstalacion) {
        self.itemsInstalacion = [];
      }
      self.itemsInstalacion.push(res);
      self.$refs.rformItem.clear();
      if (self.$refs.popupItem) {
        self.$refs.popupItem.close();
      }
      app.toast
        .create({
          text: self.$t("items.item_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onSaveInstalacion: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformInstalacion.clear();
      if (self.$refs.popupInstalacion) {
        self.$refs.popupInstalacion.close();
      }
      if (self.$refs.rform && self.$refs.rform.formData) {
        var formData = self.$refs.rform.formData;
        formData.instalaciones.push(res);
      }
      app.toast
        .create({
          text: self.$t("proveedores.proveedor_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.idData = res.id;
      app.toast
        .create({
          text: self.$t("proveedores.proveedor_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
  }
};
</script>