<template>
    <div class="item-content item-input item-label-up">
      <div class="item-inner">
        <div class="item-title item-label item-floating-label">{{label}}</div>
        <div class="item-input-wrap">
          <vue-editor v-if="!disabled && !(overrideReadonly ? readonly : readonly || form.readonly)"
            v-on="$listeners"
            v-model="innerText"
            @text-change="updateValue()"
            :editor-toolbar="customToolbar"
            :placeholder="placeholder"
            :editorOptions="editorSettings"
          ></vue-editor>
          <div v-else class="vue-editor-html" v-html="innerText"></div>
        </div>
      </div>
    </div>
</template>
<style>
  .ql-saltoPagina {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
  .ql-saltoPagina::after {
    font-size: 14px;
    font-weight: bold;
    content: '(⏎)';
  }
  .salto-pagina {
    border: 1px dashed #e69a0c;
    color: #e69a0c;
    display: inline;
  }
  .salto-pagina::before {
    color: #e69a0c;
		content: '~';
  }
  .salto-pagina::after {
    color: #e69a0c;
		content: '~';
  }
  @media print {
    .salto-pagina{visibility: hidden;clear: both;page-break-after: always;}
    .nueva-pagina{visibility: hidden;clear: both;page-break-before: always;}
    .bloque {page-break-inside: avoid;}
  }
</style>
<script>
import VueEditor from "./vue2-editor/VueEditor.vue";

const maxRows = 10;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push('newtable_' + r + '_' + c);
  }
}

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      self: this,
      innerText: '',
      editorSettings: {
      },
    };
  },
  computed: {
    text: function(){
      var self = this;
      return self.getValue();
    },
    customToolbar: function() {
      // return [
      //   [{ 'font': [] }],
      //   [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
      //   [{ 'size': ['small', false, 'large', 'huge'] }],
      //   ['bold', 'italic', 'underline', 'strike'],
      //   [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      //   [{ 'header': 1 }, { 'header': 2 }],
      //   ['blockquote', 'code-block'],
      //   [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      //   [{ 'script': 'sub'}, { 'script': 'super' }],
      //   [{ 'indent': '-1'}, { 'indent': '+1' }],
      //   [{ 'color': [] }, { 'background': [] }],
      //   ['link', 'image', 'video', 'formula'],
      //   [{ 'direction': 'rtl' }],
      //   ['clean'],
      // ];
      var self = this;
      var toolbar = [
        // [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['10px', false, '18px', '32px'] }],
        [{'font': []}],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        // [{ 'direction': 'rtl' }],
        ['clean'],
        // [{ tabletpm: tableOptions }, { tabletpm: 'append-row' }, { tabletpm: 'append-col' }, { tabletpm: 'delete-row' }, { tabletpm: 'delete-col' }, { tabletpm: 'delete-table' }],
        [{ tabletpm: tableOptions }],
        [{'saltoPagina': '⏎'}],
      ];
      if(self.showVideoButton){
        toolbar[10].push('video');
      }
      return {
        container: toolbar,
        handlers: {
          'tabletpm': function (value) {
            let table = this.quill.getModule('better-table');
            if (!table) {
              table = this.quill.getModule('table');
            }
            if (value.includes('newtable_')) {
              let sizes = value.split('_');
              let row_count = Number.parseInt(sizes[1]);
              let col_count = Number.parseInt(sizes[2]);
              return table.insertTable(row_count, col_count);
            } else if (value === 'delete-table') {
              table.deleteTable();
            } else if (value === 'append-col') {
              table.insertColumnRight();
            } else if (value === 'delete-col') {
              table.deleteColumn();
            } else if (value === 'append-row') {
              table.insertRowBelow();
            } else if (value === 'delete-row') {
              table.deleteRow();
            } else {
              return table.insertTable(2, 2);
            }
          },
          // saltoPagina: function(value = '⏎') {
          //   const range = this.$refs.myVueEditor.quill.getSelection(true);
          //   if(range == null) return;
          //   this.$refs.myVueEditor.quill.insertEmbed(range.index, 'saltoPagina', value, 'user');
          //   this.$refs.myVueEditor.quill.setSelection(range.index + 1, Quill.sources.SILENT);
          // },
        }
};
    }
  },
  watch: {
    text: function(){
      var self = this;
      if(self.innerText != self.text){
        self.innerText = self.text;
      }
    }
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      return context;
    },
    updateValue: function() {
      var self = this;
      var val = self.innerText.replace(/<img([^>]+)>/g, '<img$1/>')
                     .replace(/<br([^>]+)>/g, '<br$1/>')
                     .replace(/<hr([^>]+)>/g, '<hr$1/>')
                     .replace(/<input([^>]+)>/g, '<input$1/>')
                     .replace(/<col( [^>]+)>/g, '<col$1/>')
                     .replace(/<link([^>]+)>/g, '<link$1/>')
                     .replace(/<meta([^>]+)>/g, '<meta$1/>');
      self.setValue(val);
    },
    setValue: function(val) {
      if (val != undefined && val === '') {
        val = undefined;
      }
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
          self.$emit("change", val, self);
        } else if (context[namespaces[i]] == null) {
          self.$set(context, namespaces[i], {});
          self.$emit("change", {}, self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
            self.$emit("change", [], self);
          } else {
            self.$set(context, namespaces[i], {});
            self.$emit("change", {}, self);
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    showVideoButton: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean
    }
  },
  created() {
    var self = this;
    self.$emit("created", self);
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    self.innerText = self.getValue();
    self.$emit("mounted", self);
  }
};
</script>