<template v-on:submit="onSubmitActionsInComponent">
  <f7-card class="elevation-3 padding-vertical-half padding-right" v-if="rform.formData.inicializado">
    <f7-card-content>
      <f7-list class="list-form">
        <ul>
          <f7-row>
            <r-autocomplete 
              :dbAdapter="ViaPago" 
              class="col-100 medium-33" 
              floating-label 
              :label="$t('finanzas.viaPago')"
              :form="rform" 
              :source="filterViasPago"
              @change="gestionarCambioViaPago(rform.formData, $event)"
              name="viaPago.codigo" 
              labelProp="nombre" 
              idProp="codigo" 
              idFieldDetail="id"
              :readonly="readonly"
            ></r-autocomplete>
            <r-autocomplete 
              :dbAdapter="CondicionPago" class="col-100 medium-33" 
              floating-label
              :label="$t('finanzas.cuenta')" 
              :form="rform" 
              :source="filterCondicionesCuentas"
              @change="gestionarCambioDatosPago(rform.formData, $event)" 
              :readonly="readonly || viaPagoSeleccionadaReadonly"
              :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']" 
              name="cuenta" 
              labelProp="cuenta"
              idProp="cuenta" 
              idFieldDetail="id"
            ></r-autocomplete>
            <r-autocomplete 
              :dbAdapter="CondicionPago" 
              class="col-100 medium-33" 
              floating-label 
              :label="$t('finanzas.plazoDias')"
              :form="rform" 
              :source="filterCondicionesPlazos" 
              @change="gestionarCambioDatosPago(rform.formData, $event)"
              :readonly="readonly || viaPagoSeleccionadaReadonly" 
              :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']"
              name="plazoDias" 
              labelProp="plazoDias" 
              idProp="plazoDias" 
              idFieldDetail="id"
            ></r-autocomplete>
            <r-input 
              class="col-100 medium-33" 
              :form="rform" 
              type="check" 
              :name="'adelantoCobro'"
              :label="$t('finanzas.adelantoCobro')"
              :readonly="readonly"
            ></r-input>
          </f7-row>
        </ul>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>

<script>
import Vue from "vue";
import rForm from "./rForm.vue";
import rInput from "./rInput.vue";
import rAutocomplete from "./rAutocomplete.vue";
import rAutocompleteM2m from "./rAutocompleteM2m.vue";
import CondicionPago from "../js/db/CondicionPago.js";
import ViaPago from "../js/db/ViaPago.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rAutocompleteM2m,
  },
  data: function () {
    return {
      CondicionPago,
      ViaPago,
      viaPagoSeleccionada: null,
      condicionesPagoActivas: [],
      inicializado: false,
      yaBuscadasCondicionesPago: false
    };
  },
  props: {
    rform: {},
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    initDatosFinanzas: function(codCondicionesPago) {
      const self = this;
      self.$set(self.rform.formData, "inicializado", false);
      if((!self.condicionesPagoActivas || !self.condicionesPagoActivas.length) && !self.yaBuscadasCondicionesPago) {
        self.yaBuscadasCondicionesPago = true;
        CondicionPago.getList({filter:{activa:true}}).then(function(res){
          self.condicionesPagoActivas = res.list;
          self.initDatosFinanzasCore(codCondicionesPago);
        });
      } else {
        self.initDatosFinanzasCore(codCondicionesPago);
      }
    },
    initDatosFinanzasCore: function(codCondicionesPago) {
      const self = this;
      var condiciones = self.condicionesPagoActivas.filter(condicion => !codCondicionesPago || condicion.codigo == codCondicionesPago);
      if(!self.rform.formData.viaPago) {
        self.$set(self.rform.formData, "viaPago", {});
      }
      if(!condiciones.length) {
        return null;
      }
      var condicion = condiciones[0];
      self.$set(self.rform.formData.viaPago, "codigo", condicion.viaPago.codigo);
      self.$set(self.rform.formData, "cuenta", condicion.cuenta);
      self.$set(self.rform.formData, "plazoDias", condicion.plazoDias);
      self.viaPagoSeleccionada = condicion;
      self.$set(self.rform.formData, "inicializado", true);
    },
    filterViasPago: function (autocomplete) {
      const self = this;
      return function (query, render) {
        var results = autocomplete.items.filter(item => self.condicionesPagoActivas.some(condicion => condicion.viaPago.codigo == item.codigo))
          .map(condicion => {
            condicion['txtValue'] = condicion[autocomplete.searchProp];
            return condicion;
          });
        render(results);
      };
    },
    filterCondicionesCuentas: function (autocomplete) {
      const self = this;
      return function (query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.cuenta))
          .map(condicion => {
            if (condicion["cuenta"] != undefined) {
              condicion['txtValue'] = "" + condicion["cuenta"].trim();
            }
            return condicion;
          });
        options.sort((a, b) => a.cuenta > b.cuenta ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if (!results.some(result => result.txtValue == option.txtValue) && option.txtValue) {
            results.push(option);
          }
        });

        render(results);
      };
    },
    filterCondicionesPlazos: function (autocomplete) {
      const self = this;
      return function (query, render) {
        var options = self.condicionesPagoActivas.filter(condicion => self.getCondicionesPagoPosibles().some(condicionPosible => condicionPosible.id == condicion.id && condicion.plazoDias != undefined))
          .map(condicion => {
            if (condicion["plazoDias"] != undefined) {
              condicion['txtValue'] = "" + condicion["plazoDias"];
            }
            return condicion;
          });
        options.sort((a, b) => a.plazoDias > b.plazoDias ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if (!results.some(result => result.txtValue == option.txtValue) && option.txtValue) {
            results.push(option);
          }
        });

        render(results);
      };
    },
    getCondicionesPagoPosibles: function (coincidetodo = false) {
      const self = this;
      var condiciones = self.condicionesPagoActivas;

      if (self.rform.formData.viaPago && self.rform.formData.viaPago.codigo != undefined) {
        condiciones = condiciones.filter(condicion => condicion.viaPago.codigo == self.rform.formData.viaPago.codigo);
      }
      if (!coincidetodo && self.rform.formData.cuenta != undefined) {
        condiciones = condiciones.filter(condicion => condicion.cuenta == self.rform.formData.cuenta);
      }
      if (coincidetodo) {
        condiciones = condiciones.filter(condicion => condicion.cuenta == self.rform.formData.cuenta);
      }
      if (!coincidetodo && self.rform.formData.plazoDias != undefined) {
        condiciones = condiciones.filter(condicion => condicion.plazoDias == self.rform.formData.plazoDias);
      }
      if (coincidetodo) {
        condiciones = condiciones.filter(condicion => condicion.plazoDias == self.rform.formData.plazoDias);
      }
      return condiciones;
    },
    gestionarCambioViaPago: function (formData, val) {
      const self = this;
      self.viaPagoSeleccionada = val;
      if (!val.id) {
        self.$set(self.rform.formData, 'cuenta', undefined);
        self.$set(self.rform.formData, 'plazoDias', undefined);
        self.$set(self.rform.formData.finanzas, 'nombre', undefined);
        self.$set(self.rform.formData.finanzas, 'codCondicionesPago', undefined);
        self.$set(self.rform.formData.finanzas, 'codFormaPago', undefined);
      }
      self.completeCondicionPago();
    },
    gestionarCambioDatosPago: function (formData, val) {
      const self = this;
      if (val.id) {
        self.validateCondicionesPago();
        self.completeCondicionPago();
      } else {
        self.rform.formData.finanzas.nombre = undefined;
        self.rform.formData.finanzas.codCondicionesPago = undefined;
        self.rform.formData.finanzas.codFormaPago = undefined;
      }
    },
    completeCondicionPago: function () {
      const self = this;
      var condicionesPagoPosibles = self.getCondicionesPagoPosibles();
      if (condicionesPagoPosibles.length == 1) {
        var condicion = condicionesPagoPosibles[0];
        if (!self.rform.formData.cuenta) {
          self.rform.formData.cuenta = condicion.cuenta;
        }
        if (!self.rform.formData.plazoDias) {
          self.rform.formData.plazoDias = condicion.plazoDias;
        }
        self.rform.formData.finanzas.nombre = condicion.nombre;
        self.rform.formData.finanzas.codCondicionesPago = condicion.codigo;
        self.rform.formData.finanzas.codFormaPago = condicion.viaPago.codigo;
      }
      else if (!condicionesPagoPosibles.length) {
        self.rform.formData.cuenta = null;
        self.rform.formData.plazoDias = null;
      }
    },
    validateCondicionesPago: function() {
      const self = this;
      var app = self.$f7;
      var correcto = false;
      // Calculamos condiciones de pago
      if (self.rform.formData.codCondicionesPago === undefined) {
        let condiciones = self.getCondicionesPagoPosibles(self.rform.formData.viaPago && self.rform.formData.viaPago.codigo != undefined && self.rform.formData.plazoDias != undefined);
        if (condiciones.length == 1) {
          self.rform.formData.finanzas.nombre = condiciones[0].nombre;
          self.rform.formData.finanzas.codCondicionesPago = condiciones[0].codigo;
          self.rform.formData.finanzas.codFormaPago = condiciones[0].viaPago.codigo;
          correcto = true;
        } else { 
          // Necesaria vía de pago
          if (!self.rform.formData.viaPago || !self.rform.formData.viaPago.codigo) {
            let mensaje = "Imposible asignar condiciones de pago sin seleccionar una vía de pago.";
            app.dialog.alert(mensaje);
          }
          // Necesario plazo de pago
          if(self.rform.formData.plazoDias == undefined) {
            let mensaje = "Imposible asignar condiciones de pago sin seleccionar un plazo de pago.";
            app.dialog.alert(mensaje);
          }
          // no encontradas condiciones de pago posibles
          if(condiciones.length == 0) {
            let mensaje = "No se encuentra ninguna condición de pago, con vía de pago " + self.rform.formData.viaPago.codigo;
            mensaje+= self.rform.formData.cuenta?" ,cuenta bancaria " + self.rform.formData.cuenta:" sin cuenta bancaria";
            mensaje+= " y plazo de pago " + self.rform.formData.plazoDias;
            app.dialog.alert(mensaje);
          } else {
            let mensaje = "Imposible asignar condiciones de pago, con vía de pago " + self.rform.formData.viaPago.codigo;
            mensaje+= self.rform.formData.cuenta?" ,cuenta bancaria "+self.rform.formData.cuenta:" sin cuenta bancaria";
            mensaje+= " y plazo de pago " + self.rform.formData.plazoDias + " días. Con estas características solo debe haber una condición de pago y encontramos " + condiciones.length + " ";
            // Concatenamos separados por comas los nombres de las condiciones de pago
            let nombres = condiciones.map(condicion => condicion.nombre).join(", ");
            mensaje+= " condiciones de pago posibles, (" + nombres + ")";
            app.dialog.alert(mensaje);
          }
        }
      }
      return correcto;
    },
    onSave: function (formData) {
      const self = this;
      var app = self.$f7;
      self.initDatosFinanzas(self.rform.formData.finanzas.codCondicionesPago);
    },
  },
  computed: {
    viaPagoSeleccionadaReadonly: function () {
      const self = this;
      return !(self.viaPagoSeleccionada && self.viaPagoSeleccionada.codigo);
    },
  },
  created: function() {
    const self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
    CondicionPago.getList({filter:{activa:true}}).then(function(res){
      self.condicionesPagoActivas = res.list;
      self.initDatosFinanzas(self.rform.formData.finanzas.codCondicionesPago);
    });
  }
};
</script>