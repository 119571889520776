import Delegaciones from './delegaciones.vue';
import DelegacionForm from './delegacion-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/delegaciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('delegaciones', ['ADMIN', 'INSPECTOR'], Delegaciones),
        keepAlive: true
    },
    {
        path: '/delegaciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('delegaciones/new', ['ADMIN', 'INSPECTOR'], DelegacionForm)
    },
    {
        path: '/delegaciones/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('delegaciones/edit', ['ADMIN', 'INSPECTOR'], DelegacionForm)
    },
    {
        path: '/delegaciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('delegaciones/view', ['ADMIN', 'INSPECTOR'], DelegacionForm, { editable: false })
    },
];
export default routes;