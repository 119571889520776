<template>
  <div>
    <f7-popover class="history-popover" :closeByBackdropClick="false" :backdrop="true" closeOnEscape ref="popoverHistory" style="width: auto; max-width: 90%;">
      <div style="min-width: 250px;">
        <f7-navbar :title="$t('Historico de cambios')">
          <f7-nav-right>
            <f7-link popover-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div class="scrollable-content">
            <table>
              <thead>
                <tr style="background-color: #22355b;color: #FFF;">
                  <th>Valor</th>
                  <th>Fecha</th>
                  <th>Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(itm, idx) in history" :key="'history-' + idx" :class="(idx % 2 ? 'fila-impar' : 'fila-par')">
                  <td class="datos-valor" :title="itm && itm.msgHistory ? itm.msgHistory : ''">{{ itm.valor }}</td>
                  <td class="datos-auditoria">{{ itm.date }}</td>
                  <td class="datos-auditoria">{{ itm.username || ' - ' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </f7-block>
      </div>
    </f7-popover>
    <f7-list-input
      :value="getValue()"
      type="text"
      autocomplete="off"
      @input="updateIfEmpty"
      @blur="onBlur"
      v-bind="{...$attrs,...$props}"
      @keyup.enter.native="onPressEnter"
      v-on="$listeners"
      :error-message-force="!!form.errors[name]"
      :error-message="form.errors[name]"
      :readonly="overrideReadonly ? readonly : readonly || form.readonly"
      :class="{readonly:overrideReadonly ? readonly : readonly || form.readonly, 'cordova-workaround': target == 'cordova', 'navigation-link': getShowNavigationLink()}"
      v-tooltip="viewTooltip ? (label ? label + ':<br>' : '') + (getValue() ? getValue() : '') : ''"
      :clear-button="!(overrideReadonly ? readonly : readonly || form.readonly) && clearButton && !!getValue()"
      @input:clear="setValue({})"
      @focus="focused = true"
      class="r-autocomplete"
    >
      <slot name="inner-end" slot="inner-end">
        <f7-link
          v-if="hasHistory && Utils.posterior2Dic2024(form.formData.fechaInsp)"
          class="input-suffix-btn r-input-little r-input-top history-link"
          :icon-f7="'archivebox'"
          v-tooltip="'Historico de valores'"
          @click="showHistory"
        ></f7-link>

        <f7-link
          v-if="getShowNavigationLink()"
          :href="getDetailUrl()"
          class="input-suffix-btn"
          icon-f7="arrow_up_right_square"
          color="green"
          external
          v-tooltip="'Navegar a seleccionado'"
        ></f7-link>
      </slot>
    </f7-list-input>
      
  </div>
</template>
<style scoped>
</style>
<script>

import DatabaseModel from '../js/db/DatabaseModel';
import DateUtils from "./../js/DateUtils";
import Utils from "./../js/Utils";
import JSONPath from 'jsonpath';

export default {
  data() {
    return {
      _jqxhr: null,
      isLoading: false,
      items: [],
      initialItems: [],
      selected: null,
      autocomplete: null,
      DatabaseModel,
      dbAdapterData: null,
      target: process.env.TARGET,
      preloaded: false,
      timeoutGetData: null,
      focused: false,
      history: [],
      Utils
    };
  },
  computed: {
    hasHistory: function () {
      return this.form?.history && this.form?.history[this.name];
    },
  },
  methods: {
    onPressEnter: function() {
      const self = this;
      if (self.async && self.items && self.items.length) {
        self.setValue(self.items[0]);
        self.autocomplete.close();
      }
    },
    setFilter: function(filter, loadIfOne = true) {
      const self = this;
      if (filter) {
        self.setValue({}, false);
        self.autocomplete.preloaderShow();
        var {params, shownFields} = self.getRemoteData(filter);
        params.filter = params.filter || {};
        self.dbAdapterData
          .getCombo(params, [...shownFields])
          .then(function(res) {
            self.preloaded = true;
            self.items = res.list;
            self.$emit("loaded", res.list);
            if (loadIfOne && self.items && self.items.length == 1) {
              self.setValue(self.items[0]);
            }
            self.autocomplete.preloaderHide();
          })
          .catch(function(e) {
            self.autocomplete.preloaderHide();
          });
      } else {
        self.setValue({});
        self.preloaded = false;
      }
    },
    getDetailUrl: function() {
      const self = this;
      var currentUrl = new URL(window.location.href);
      
      if (self.detailUrl) {
        return currentUrl.pathname + self.detailUrl.replace(':id', self.getIdValue());
      }
      return currentUrl.pathname + ('#!/' + self.dbAdapterData.modelFront + '/view/' + self.getIdValue() + '/');
    },
    getShowNavigationLink: function() {
      const self = this;
      const idValue = self.getIdValue();
      if (!idValue || !self.showNavigationLink) {
        return false;
      }
      if (self.detailUrl) {
        return true;
      }

      return self.dbAdapterData && self.dbAdapterData.modelFront;
    },
    updateIfEmpty: function(e) {
      if (!e.target.value && e.inputType == "deleteContentBackward") {
        this.setValue({});
        this.form.$emit("change", this.name, {});
      }
    },
    onBlur: function(e) {
      const self = this;
      self.focused = false;
      if (!!!self.getValue() && e.target.value) {
        e.target.value = "";
        this.setValue({});
        this.form.$emit("change", this.name, {});
      }
    },
    reload(items) {
      var self = this;
      if (!self.async) {
        if (self.dbAdapterData) {
          const {params, shownFields} = self.getRemoteData();
          self.dbAdapterData
            .getCombo(params, [...shownFields])
            .then(function(res) {
              self.items = res.list;
              self.initialItems = res.list;
              if (self.autoSelectValue) {
                self.setValue(self.autoSelectValue);
              } else if (self.getValue()) {
                self.source(self)(self.getValue(), res => {
                  if (res && res.length == 1 && res[0] != self.getValue()) {
                    self.setValue(res[0]);
                  }
                })
              }
              if (self.focused) {
                self.autocomplete.renderDropdown();
                self.autocomplete.open();
                self.autocomplete.openDropdown();
              }
              self.$emit("loaded", res.list);
            })
            .catch(function(e) {
              console.log(e);
            });
        } else {
          items = items || self.dbItems;
          self.items = items;
          self.initialItems = items;
          self.$emit("loaded", items);
        }
      }
    },
    getRemoteData: function(filterInput) {
      const self = this;
      var filters = filterInput || self.filter;
      var params = Object.assign({}, filters);
      var shownFields = new Set(self.shownFields);
      shownFields.add('id');
      if(self.labelProp) shownFields.add(self.labelProp);
      if(self.prefixProp) shownFields.add(self.prefixProp);
      if(self.idProp) shownFields.add(self.idProp);
      if(self.searchProp) shownFields.add(self.searchProp);
      return {params: params, shownFields: shownFields};
    },
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (typeof context == "undefined" || context == null) context = {};
      var selected = self.items.filter(function(i){
        return i[self.idProp] == context;
      })
      if (selected && selected.length > 0) {
        self.selected = selected[0];
      } else {
        self.selected = context;
      }
      if (selected && selected.length > 0) {
        return self.prefixProp && selected[0][self.prefixProp] ? (selected[0][self.prefixProp] + ' - ' + selected[0][self.labelProp]) : selected[0][self.labelProp];
      }

      if (typeof context == "string") {
        return context[self.labelProp] || context;
      } else {
        return self.prefixProp && context[self.prefixProp] ? (context[self.prefixProp] + ' - ' + context[self.labelProp]) : context[self.labelProp];
      }

    },
    showHistory: function(event) {
      const self = this;
      self.history = self.form.history[self.name];
      // vamos a formatear los campos de fecha
      for (var i = 0; i < self.history.length; i++) {
        if(self.history[i].valor === undefined) {
          self.history[i].valor = '';
        }
        if(self.history[i].valorAnterior === undefined) {
          self.history[i].valorAnterior = '';
        }
        self.history[i].date = DateUtils.dateFormat(self.history[i].date, 'DD/MM/YYYY HH:mm:ss');
        self.history[i].username = self.history[i].username || '-';
      }
      if(event.target) self.$refs.popoverHistory.open(event.target);
    },
    getIdValue: function() {
      var self = this;
      const idField = self.idFieldDetail || self.idProp || 'id';
      const namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      if (typeof context == "undefined" || context == null) return "";
      var selected = self.items.filter(function(i){
        return i[self.idProp] == context;
      })
      if (selected && selected.length > 0) {
        return selected[0][idField];
      }

      if (typeof context == "string") {
        return context[idField] || context;
      } else {
        return context[idField];
      }

    },
    setValue: function(val, sendEvent = true) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.selected = val;
          var value = val;
          if (self.idProp) {
            value = val[self.idProp];
          }
          value = self.beforeChange(value);
          self.$set(context, namespaces[i], value);
          if (self.nameId) {
            var namespace = namespaces[i];
            if (self.nameId) {
              namespace = self.nameId;
            }
            self.$set(context, namespace, value);
          } 
          if (sendEvent) {
            self.$emit("change", val, self);
            self.form.$emit("change", namespace, val);
          }
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    dbAdapter: {
      type: Object
    },
    name: {
      required: true,
      type: String
    },
    nameId: {
      type: String
    },
    form: {
      required: false,
      type: Object,
      default: function() {
        return {};
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    overrideReadonly: {
      type: Boolean,
      default: false
    },
    labelProp: {
      type: String,
      default: "name"
    },
    prefixProp: {
      type: String
    },
    idProp: {
      type: String,
      default: ""
    },
    minLength: {
      type: Number,
      default: 0
    },
    searchProp: {
      type: String,
      default: function() {
        return this.labelProp;
      }
    },
    beforeChange: {
      type: Function,
      default: function(item) {
        return item;
      }
    },
    filter: {
      type: Object,
      default: function() {
        return {};
      }
    },
    autoSelectValue: {
      type: Object
    },
    viewTooltip: {
      type: Boolean,
      default: true
    },
    shownFields: {
      type: Array,
      default: function (){
        return [];
      }
    },
    defaultFilterSourceAsync: {
      type: Function,
      default: function () {
        return function() {
          return {};
        };
      }
    },
    showItem: {
      type: Function,
      default: function () {
        return function(item) {
          return true;
        };
      }
    },
    source: {
      type: Function,
      default: function(self) {
        return function(query, render) {
          var results = [];
          if (self.preloaded) {
            for (var i = 0; i < self.items.length; i++) {
              var txtValue = self.items[i][self.searchProp];
              if (self.prefixProp && self.items[i][self.prefixProp]) {
                txtValue = self.items[i][self.prefixProp] + ' - ' + self.items[i][self.searchProp];
              }
              if (
                txtValue &&
                txtValue
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) >= 0 &&
                self.showItem(self.items[i])
              ) {
                self.items[i]['txtValue'] = txtValue;
                results.push(self.items[i]);
              }
            }
            render(results);
          } else if (self.async && self.dbAdapterData && query.length >= self.minLength) {
            self.autocomplete.preloaderShow();
            var {params, shownFields} = self.getRemoteData();
            params.filter = Object.assign({} , params.filter, {withoutAggregations: true}, self.defaultFilterSourceAsync());
            params.filter[self.searchProp] = query;
            if (self.timeoutGetData) {
              clearTimeout(self.timeoutGetData);
            }
            self.timeoutGetData = setTimeout(() => {
                self.dbAdapterData
                  .getCombo(params, [...shownFields])
                  .then(function(res) {
                    self.items = res.list;
                    self.$emit("loaded", res.list);
                    //self.autocomplete.preloaderHide();
                    render(res.list);
                  })
                  .catch(function(e) {
                    self.autocomplete.preloaderHide();
                    render(results);
                  });
            }, 1000);
            
          } else {
            self.items = self.initialItems;
            if (query.length >= self.minLength) {
              for (var i = 0; i < self.items.length; i++) {
                var txtValue = self.items[i][self.searchProp];
                if (self.prefixProp && self.items[i][self.prefixProp]) {
                  txtValue = self.items[i][self.prefixProp] + ' - ' + self.items[i][self.searchProp];
                }
                if (
                  txtValue &&
                  txtValue.toLowerCase().indexOf(query.toLowerCase()) >= 0 &&
                  self.showItem(self.items[i])
                ) {
                  self.items[i]['txtValue'] = txtValue;
                  results.push(self.items[i]);
                }
              }
            }
            render(results);
          }
        };
      }
    },
    onchange: {
      type: Function,
      default: function(self) {
        return function(value) {
          self.setValue(value[0]);
          // self.$emit("change", value[0]);
          // self.form.$emit("change", self.name, value[0]);
        };
      }
    },
    autocompleteProps: {
      type: Object,
      default: function() {
        var $ = this.$$;
        var $el = $(this.$el);
        var self = this;
        return {};
      }
    },
    label: {
      type: String,
      default: ""
    },
    dbItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    openIn: {
      type: String,
      default: "dropdown"
    },
    infoText: {
      type: String
    },
    async: {
      type: Boolean,
      default: false
    },
    model: {
      type: String
    },
    clearButton: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Object
    },
    showNavigationLink: {
      type: Boolean,
      default: true
    },
    detailUrl: {
      type: String
    },
    idFieldDetail: {
      type: String
    }
  },
  created: function() {
    var self = this;
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    if (self.validation) {
      self.form.validateFields[self.name] = self.validation;
    }
    if (self.model) {
      var obj = Object.create(DatabaseModel);
      obj.model = self.model;
      self.dbAdapterData = obj;
    } else {
      self.dbAdapterData = self.dbAdapter;
    }
    self.autocomplete = self.$f7.autocomplete.create({
      ...{
        openIn: self.openIn,
        openerEl: $el,
        closeOnSelect: true,
        inputEl: $el.find("input"),
        preloader: true,
        highlightMatches: true,
        dropdownPlaceholderText: self.infoText ? self.infoText : (self.minLength > 0 ? 'Introduzca al menos ' + self.minLength + ' caracteres' : null),
        requestSourceOnOpen: true,
        dropdownContainerEl: $el,
        valueProperty: self.idProp || self.searchProp,
        textProperty: self.async ? self.searchProp : 'txtValue',
        source: self.source(self),
        on: {
          change: self.onchange(self)
        }
      },
      ...self.autocompleteProps
    });
    self.reload();
  },
  destroyed() {
    var self = this;
    if (self.validation) {
      delete self.form.validateFields[self.name];
    }
  }
};
</script>
<style>
.readonly input,
.readonly select {
  pointer-events: none;
}
.autocomplete-dropdown-inner {
  max-height: 170px;
}
.autocomplete-dropdown {
  min-width: 400px;
}
</style>