import Proveedores from './proveedores.vue';
import ProveedorForm from './proveedor-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/proveedores/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('proveedores', ['ADMIN', 'INSPECTOR'], Proveedores),
        keepAlive: true
    },
    {
        path: '/proveedores/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('proveedores/new', ['ADMIN', 'INSPECTOR'], ProveedorForm)
    },
    {
        path: '/proveedores/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('proveedores/edit', ['ADMIN', 'INSPECTOR'], ProveedorForm)
    },
    {
        path: '/proveedores/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('proveedores/view', ['ADMIN', 'INSPECTOR'], ProveedorForm, { editable: false })
    },
];
export default routes;