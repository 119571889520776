import DatabaseModel from './DatabaseModel.js';

var Oportunidad = Object.create(DatabaseModel);

Oportunidad.model = 'oportunidades';
Oportunidad.modelFront = 'oportunidades';

Oportunidad.generarOferta = function (id) {
    var self = this;
    if (!id) reject({ error: 'id not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + id + '/generarOferta',
        method: 'PUT'
    };
    return self.sendRequest(params, false);
};
Oportunidad.marcarPerdida = function (id) {
    var self = this;
    if (!id) reject({ error: 'id not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + id + '/marcarPerdida',
        method: 'PUT'
    };
    return self.sendRequest(params, false);
};

Oportunidad.enviarEmail = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/enviarEmail/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};
Oportunidad.probarPlantillaCorreo = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/probarPlantillaCorreo/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default Oportunidad;