<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('ofertas.ofertas')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Oferta"
        :tableCode="'OFERTA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['estado.estado', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'lineas.comision', 'lineas.proveedor', 'lineas.item', 'lineas.administrador', 'lineas.instalador', 'lineas.mantenedor', 'lineas.asociacion']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="90"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/ofertas/new/">{{$t('ofertas.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/ofertas/view/${item.id}/`"></f7-link>
          <f7-link v-tooltip="$t('common.duplicar')" icon-f7="rectangle_stack_badge_plus" color="green" @click="clonar(item.id)"></f7-link>
          <f7-link v-if="!item.estado || item.estado.estado == 'ABIERTA'" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/ofertas/edit/${item.id}/`"></f7-link>
        </template>
        <template v-slot:massiveActions="{ }">
          <f7-button
            fill
            icon-f7="envelope"
            text="Enviar email"
            class="display-flex"
            color="purple"
            @click="enviarEmail = true"
          ></f7-button>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>

  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
  .popover_option {
    border-top: 1px solid #e0e0e0;
    padding: 5px;
    cursor: pointer;
  }
  .popover_option:hover {
    background-color: #707594;
    color:white;
  }
  .block-chips {
    display: flex;
    flex-wrap: wrap;
    background-color: #edecee;
    padding: 4px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 4px;
    min-height: 34px;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "./components/envio-mail.vue";
import rInput from "./../../components/rInput.vue";
import rForm from "./../../components/rForm.vue";
import Oferta from "./../../js/db/Oferta.js";
export default {
  components: {
    rDynamicList,
    EnvioMail,
    rForm,
    rInput,
  },
  data() {
    return {
      Oferta,
      enviarEmail: false
    };
  },
  computed: {},
  methods: {
    filasSeleccionadas: function() {
      let oportunidades = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return {id: selected.id};
      });
      return oportunidades;
    },
    showCheckInList: function(item) {
      return item.estado && item.estado.estado && item.estado.estado != "CERRADA" && item.estado.estado != "CANCELADA" && item.estado.estado != "RECHAZADA" && item.estado.estado != "CADUCADA"; 
    },
    clonar: function(id) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Oferta.clonar({
        itemId: id,
        data: {}
      }).then(function(res){
        app.views.current.router.navigate(
          "/ofertas/edit/"+res.id+"/"
        );
      })
    },

  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>