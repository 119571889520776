import MediumEditor from 'medium-editor';

var SaltoPagina = MediumEditor.Extension.extend({
    name: 'saltoPagina',
    title: 'Salto de página',
    init: function () {
        this.button = this.document.createElement('button');
        this.button.classList.add('medium-editor-action');
        this.button.innerHTML = '<i class="icon f7-icons">arrow_turn_down_left</i>';
        this.button.title = this.title;
        this.on(this.button, 'click', this.handleClick.bind(this));
    },

    getButton: function () {
        return this.button;
    },

    handleClick: function (event) {
        var inputHtml = this.getCurrentSelection();
        var sel = window.getSelection();
        var selected = sel.anchorNode;
        var parent = selected.parentElement;

        if (this.isActive()) {
            if (selected.nodeName.toUpperCase() == this.name.toUpperCase()) {
                const originalData = selected.firstElementChild.getAttribute("original-data");
                parent.removeChild(selected);
                parent.innerHTML = originalData;
            }
            return;
        }

        if (window.getSelection && (sel = window.getSelection()).rangeCount) {
            var range = sel.getRangeAt(0);
            range.deleteContents();
            var element = document.createElement("hr");
            element.classList.add("salto-pagina");
            range.insertNode(element);

    
            // Move the caret immediately after the inserted span
            range.setStartAfter(element);
            sel.removeAllRanges();
            sel.addRange(range);
        }

        this.base.checkContentChanged(); 
    },

    isAlreadyApplied: function (node) {
        return node.nodeName.toUpperCase() === this.name.toUpperCase();
    },

    isActive: function () {
        return this.button.classList.contains('medium-editor-button-active');
    },

    setInactive: function () {
        this.button.classList.remove('medium-editor-button-active');
    },

    setActive: function () {
        this.button.classList.add('medium-editor-button-active');
    },

    getSelect: function () {
        return this.getForm().querySelector('select.medium-editor-toolbar-select');
    },

    getCurrentSelection: function() {
        var html = ''
        var sel

        if (typeof window.getSelection != 'undefined') {
        sel = window.getSelection()
        if (sel.rangeCount) {
            var container = document.createElement('div')
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents())
            }
            html = container.innerHTML
        }
        } else if (typeof document.selection != 'undefined') {
            if (document.selection.type == 'Text') {
                html = document.selection.createRange().htmlText
            }
        }
        return html
    }
});
export default SaltoPagina;