 <template>
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="idData"
    @save="onSave"
    @loadItem="loadItem"
    :readonly="readonly"
    :dbAdapter="Cliente"
    :documentoAdapter="Documento"
    :defaultData="{instalaciones:[], canalesComunicacion:[], contactos:[], direcciones:[], condicionesPago: [], listaPrecios: 1, fechasPago: [], activo: true}"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{rform}">
      <f7-popover ref="popo"
            @popover:open="removeEmptyOptions(rform.formData)"
            @popover:closed="removeEmptyOptions(rform.formData)">
        <f7-row class="cabecera-fechaPago">
          <f7-button
            icon-f7="plus"
            class="nueva-fecha"
            @click="nuevaOpcion(rform.formData.fechasPago)"
          ></f7-button>
        </f7-row>
        <f7-list class="limit-height" ref="fechaPagoList">
          <f7-list-input
            v-for="(fecha,ico) in rform.formData.fechasPago"
            :key="'fecha-' + fecha + '-fecha2-' + ico"
            type="number"
            step="1"
            min="1"
            max="31"
            placeholder="Día del mes"
            clear-button
            :value="fecha"
            @change="!$event.target.value? rform.formData.fechasPago.splice(ico, 1) : $set(rform.formData.fechasPago, ico, $event.target.value)"
          ></f7-list-input>
        </f7-list>
      </f7-popover>
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="idData && !readonly" sliding>{{$t('clientes.editar_cliente')}}</f7-nav-title>
        <f7-nav-title v-else-if="idData" sliding>{{$t('clientes.cliente')}}</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('clientes.nuevo_cliente')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
          v-if="readonly"
          fill
          :href="'/clientes/edit/' + idData + '/'"
          text="Editar"
          class="display-flex"

          ></f7-button>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem()"
          ></f7-button>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <!-- Tabs -->
    <template v-slot:default="{rform}">
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <div name="Datos Generales">
          <f7-link
            @click="showDetalle = !showDetalle"
            :tooltip="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            :icon-f7="showDetalle ?'chevron_compact_up' : 'chevron_compact_down'"
            :title="showDetalle ? 'Ocultar Detalle' : 'Mostrar Detalle'"
            style="padding-left: 7px;font-size: 10px;float: right;"
          ></f7-link>
        </div>
        <f7-card-content v-show="showDetalle">
          <f7-row no-gap class="list list-form no-hairlines">
            <div class="label-group" :class="{'col-50': readonly, 'col-100': !readonly}">Datos Generales</div>
            <f7-button
              v-if="readonly"
              class="col-20"
              v-noroles="['ENAC', 'PRODUCCION']"
              icon-f7="rectangle_stack_person_crop_fill"
              @click="crearOportunidad"
            >Crear oportunidad</f7-button>
            <f7-button
              class="col-20"
              v-noroles="['ENAC', 'PRODUCCION']"
              icon-f7="calendar_circle"
              @click="popupTareaEditable = true; popupTareasOpened = true"
              v-if="readonly && id && $refs.rform && $refs.rform.formData && $refs.rform.formData.codigo"
            >Crear tarea</f7-button>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.codigo')"
              :form="rform"
              type="text"
              name="codigo"
              v-if="id"
              :readonly="true"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.nif')"
              :form="rform"
              type="text"
              name="nif"
              :validation="{isValid: str => {return validator.isEmpty(str, {ignore_whitespace:true}) || ValidationUtils.validateSpanishID(str)}, errorMessage: '* Introduzca un NIF válido'}"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.razonSocial')"
              :form="rform"
              type="text"
              name="razonSocial"
              :allMayus="true"
              :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Introduzca una Razón Social'}"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.empresaMantenedora')"
              :form="rform"
              name="empresaMantenedora"
            ></r-input>
            <r-autocomplete
              :dbAdapter="GrupoCliente"
              class="col-25"
              floating-label
              :label="$t('clientes.grupocliente')"
              :form="rform"
              name="grupoCliente"
              labelProp="nombre"
              idProp="codigo"
              idFieldDetail="id"
            ></r-autocomplete>
            <r-input
              class="col-25"
              :label="$t('clientes.potencial')"
              :form="rform"
              type="check"
              name="potencial"
            ></r-input>
            <r-input
              class="col-25"
              :label="$t('clientes.tipoCliente')"
              :form="rform"
              type="select"
              name="tipoCliente"
            >
              <option value="D">Directo</option>
              <option value="I">Indirecto</option>
            </r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.fechaCaptacion')"
              :form="rform"
              type="datepicker"
              name="fechaCaptacion"
              :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
            ></r-input>
            <r-input
              class="col-25"
              :label="$t('clientes.moneda')"
              :form="rform"
              type="select"
              name="moneda"
            >
              <option selected value="EUR">Euro</option>
            </r-input>
            <r-autocomplete
              :dbAdapter="Cliente"
              class="col-50"
              floating-label
              :label="$t('clientes.clienteIndirecto')"
              :form="rform"
              :name="'clienteIndirecto'"
              labelProp="codigoNombre"
              idProp="codigoNombre"
              idFieldDetail="id"
              :detailUrl="!rform.formData.clienteIndirecto ? undefined : ('#!/' + 'clientes' + '/view/' + (rform.formData.clienteIndirecto.split('-')[0].trim()) + '/')"
              :shownFields="['codigo', 'razonSocial']"
              :minLength="3"
              :async="true"
            ></r-autocomplete>
            <r-input
              class="col-100 medium-25"
              :form="rform"
              type="check"
              :name="'activo'"
              label="Activo"
            ></r-input>
            <f7-row no-gap class="col-100 display-flex" style="background-color: bisque;border-radius: 5px;">
              <div class="label-group col-100" style="margin-top: 0;margin-bottom: 0;">{{$t('clientes.titular.title')}}</div>
              <r-input
                class="col-25"
                floating-label
                :label="$t('clientes.titular.nombre')"
                :form="rform"
                type="text"
                name="titular.nombre"
              ></r-input>
              <r-input
                class="col-50"
                floating-label
                :label="$t('clientes.titular.apellidos')"
                :form="rform"
                type="text"
                name="titular.apellidos"
              ></r-input>
              <r-input
                class="col-25"
                floating-label
                :label="$t('clientes.titular.nif')"
                :form="rform"
                type="text"
                name="titular.nif"
              ></r-input>
            </f7-row>
            <div class="label-group col-100">Impuestos</div>
            <r-input
              class="col-25"
              :label="$t('clientes.recargoEquivalencia')"
              :form="rform"
              type="check"
              name="recargoEquivalencia"
            ></r-input>
            <r-input
              class="col-25"
              :label="$t('clientes.impuestoDiferido')"
              :form="rform"
              type="check"
              name="impuestoDiferido"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.codigoOperacion347')"
              :form="rform"
              type="text"
              name="codigoOperacion347"
            ></r-input>
            <r-input
              class="col-25"
              floating-label
              :label="$t('clientes.operacionAseguradora347')"
              :form="rform"
              type="check"
              name="operacionAseguradora347"
            ></r-input>
            <r-autocomplete
              :dbAdapter="Empleado"
              class="col-25"
              floating-label
              label="Responsable"
              :form="rform"
              name="responsable"
              labelProp="nombre"
              prefixProp="username"
              idProp="username"
              idFieldDetail="id"
            ></r-autocomplete>
            <r-autocomplete
              :dbAdapter="Empleado"
              class="col-25"
              floating-label
              label="Empleado Ventas"
              :form="rform"
              name="empleadoVentas"
              labelProp="nombre"
              prefixProp="username"
              idProp="username"
              idFieldDetail="id"
              :shownFields="['slpCode']"
              @change="changeEmpleado($event)"
            ></r-autocomplete>
            <r-input
              class="col-25"
              floating-label
              label="Cod. Carga"
              :form="rform"
              type="text"
              name="codCarga"
            ></r-input>
            <div v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo" class="label-group col-75" style="margin-top: 10px;">Datos de pedidos</div>
            <r-dynamic-list
              v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo"
              ref="rlist"
              :primary="['id','lineas.numero']"
              :dbAdapter="LineaPedido"
              :tableCode="'LINEAPEDIDOCLIENTE'"
              :tableMode="true"
              :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
              class="card full-height data-table width-100"
              classContent="card-content full-height"
              :controlsWidth="100"
              :searchOnInit="true"
              :shownFields="['cliente.codigo','lineas.numero']"
              :mandatoryFilter ="{ 'cliente##codigo': $refs.rform.formData.codigo }"
              :funcShowCheck="_ => { return false; }"
              :massiveActions="false"
              :mostrarBadgeFilters="false"
              style="margin-bottom: 2px;"
            >
              <template v-slot:optionsColumn="{ item }">
                  <f7-link v-tooltip="$t('common.ver') + ' Tarea'" icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                  <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                  <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
              </template>
            </r-dynamic-list>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-canalesComunicacion" tab-link-active>Canales Comunicación</f7-button>
          <f7-button fill tab-link="#tab-instalaciones">Instalaciones</f7-button>
          <f7-button fill tab-link="#tab-direcciones">Direcciones</f7-button>
          <f7-button fill tab-link="#tab-contactos">Contactos</f7-button>
          <f7-button fill tab-link="#tab-finanzas">Pago y Finanzas</f7-button>
          <f7-button fill tab-link="#tab-comentarios">Comentarios</f7-button>
          <f7-button fill tab-link="#tab-servicios">Servicios</f7-button>
          <f7-button fill tab-link="#tab-documentos">Documentación</f7-button>
          <f7-button v-noroles="['ENAC', 'PRODUCCION']" fill tab-link="#tab-tareas">Tareas</f7-button>
          <f7-button fill tab-link="#tab-oportunidades">Oportunidades</f7-button>
          <f7-button fill tab-link="#tab-emails">e-Mails</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs class="full-height" style="overflow: auto;">
        <f7-tab id="tab-canalesComunicacion" tab-active>
          <!-- Canales Comunicacion -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('clientes.canalescomunicacion')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.canalesComunicacion.push({tipo: '', nombre: '', valor:''})"
            >{{$t('centrostrabajo.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.canalesComunicacion"
              :key="'canalcomunicacion-'+index"
              :style="'z-index:'+(rform.formData.canalesComunicacion.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.tipo')"
                        :form="rform"
                        type="select"
                        :name="'canalesComunicacion.' + index + '.tipo'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      >
                        <option selected></option>
                        <option value="TELEFONO">{{$t('clientes.telefono')}}</option>
                        <option value="TELEFONO_MOVIL">{{$t('clientes.telefono_movil')}}</option>
                        <option value="FAX">{{$t('clientes.fax')}}</option>
                        <option value="EMAIL">{{$t('clientes.email')}}</option>
                        <option value="WEB">{{$t('clientes.web')}}</option>
                      </r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.nombre')"
                        :form="rform"
                        :name="'canalesComunicacion.' + index + '.nombre'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.valor')"
                        :form="rform"
                        :name="'canalesComunicacion.' + index + '.valor'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      ></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.canalesComunicacion,index);"
                class="margin-half"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
            </f7-card>
          </div>
        </f7-tab>
        <f7-tab id="tab-instalaciones">
          <div class="block-title" v-if="idData">
            Instalaciones
            <f7-link v-if="!rform.readonly" popup-open=".popup-instalacion">{{$t('clientes.add')}}</f7-link>
          </div>
          <div
            v-if="idData && rform.formData && rform.formData.instalaciones"
            class="list accordion-list accordion-opposite margin-left margin-right"
          >
            <ul>
              <li
                class="accordion-item row"
                v-for="(item,index) in rform.formData.instalaciones"
                :key="'instalacion-'+index"
                @accordion:open="openInstalacion(item)"
              >
                <a class="col-95 item-content item-link" href="#">
                  <div class="item-inner">
                    <div class="item-title">{{item.codigo}} - {{item.nombre}}</div>
                  </div>
                </a>
                <f7-link
                  class="col-5"
                  style="margin-top:5px; margin-right:5px;"
                  icon-f7="minus_circle_fill"
                  color="red"
                  @click="borrarInstalacion(item.codigo)"
                ></f7-link>
                <div class="accordion-item-content">
                  <f7-row>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.codigo')"
                      :form="rform"
                      type="text"
                      :name="'instalaciones.' + index + '.codigo'"
                    ></r-input>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.nombre')"
                      :form="rform"
                      type="text"
                      :name="'instalaciones.' + index + '.nombre'"
                    ></r-input>
                  </f7-row>
                  <div class="block-title">Datos Instalación</div>
                  <f7-row>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.nif')"
                      :form="rform"
                      type="text"
                      :name="'instalaciones.' + index + '.datosInstalacion.nif'"
                    ></r-input>
                    <r-input
                      class="col-50"
                      floating-label
                      :label="$t('clientes.razonSocial')"
                      :form="rform"
                      type="text"
                      :name="'instalaciones.' + index + '.datosInstalacion.razonSocial'"
                    ></r-input>
                    <r-direccion
                      :form="rform"
                      :name="'instalaciones.' + index + '.datosInstalacion.direccion'"
                    ></r-direccion>
                  </f7-row>
                  <div class="block-title">
                    Titulares
                    <f7-link
                      v-if="!rform.readonly"
                      @click="if (!item.titulares) item.titulares = []; item.titulares.push({})"
                    >{{$t('clientes.add')}}</f7-link>
                  </div>
                  <div
                    v-if="item && (!item.titulares || item.titulares.length == 0)"
                    class="margin-left text-color-gray"
                  >No hay Titulares asociados a la instalación</div>
                  <div
                    v-if="item && item.titulares"
                    class="list accordion-list accordion-opposite margin-left"
                  >
                    <ul>
                      <li
                        class="accordion-item row"
                        v-for="(titular,indexTitulares) in item.titulares"
                        :key="'instalacion-' + index + '-titular-' + indexTitulares"
                        @accordion:beforeopen="openTitular"
                      >
                        <f7-link
                          class="col-5"
                          style="margin-top:5px; margin-right:5px;"
                          icon-f7="minus_circle_fill"
                          color="red"
                          @click="borrarTitular(item, titular)"
                        ></f7-link>
                        <a class="item-content item-link col-95" href="#">
                          <div class="item-inner">
                            <div class="item-title">{{titular.nif}} - {{titular.nombre}}</div>
                          </div>
                        </a>
                        <div class="accordion-item-content">
                          <f7-row>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.nif')"
                              :form="rform"
                              type="text"
                              :name="'instalaciones.' + index + '.titulares.' + indexTitulares + '.nif'"
                            ></r-input>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.nombre')"
                              :form="rform"
                              type="text"
                              :name="'instalaciones.' + index + '.titulares.' + indexTitulares + '.nombre'"
                            ></r-input>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.razonSocial')"
                              :form="rform"
                              type="text"
                              :name="'instalaciones.' + index + '.titulares.' + indexTitulares + '.razonSocial'"
                            ></r-input>
                            <r-direccion
                              :form="rform"
                              :name="'instalaciones.' + index + '.titulares.' + indexTitulares + '.direccion'"
                            ></r-direccion>
                          </f7-row>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="block-title">
                    Items
                    <f7-link v-if="!rform.readonly" popup-open=".popup-item">{{$t('clientes.add')}}</f7-link>
                  </div>
                  <div
                    v-if="!itemsInstalacion || itemsInstalacion.length == 0"
                    class="margin-left text-color-gray margin-bottom"
                  >No hay Items asociados a la Instalación</div>
                  <div class="list margin-bottom margin-left">
                    <ul>
                      <li
                        class="row"
                        v-for="(itemInstalacion, indexItems) in itemsInstalacion"
                        :key="'instalacion-' + index + '-item-' + indexItems"
                      >
                        <f7-link
                          class="col-5"
                          style="margin-top:5px; margin-right:5px;"
                          icon-f7="minus_circle_fill"
                          color="red"
                          @click="borrarItem(itemInstalacion.id)"
                        ></f7-link>
                        <a
                          :href="(readonly ? '/items/view/' : '/items/edit/') + itemInstalacion.id + '/'"
                          class="item-link item-content col-95"
                        >
                          <div class="item-inner">
                            <div
                              class="item-title"
                            >{{itemInstalacion.codigo}} - {{itemInstalacion.nombre}}</div>
                            <div class="item-after">Ver</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-tab>
        <f7-tab id="tab-direcciones">
          <f7-list class="list-form">
            <ul class="margin-horizontal">
            <f7-row>
              <r-input
                class="col-50"
                :label="$t('clientes.direccionDefectoFacturas')"
                :form="rform"
                type="select"
                name="direccionDefectoFacturas"
              >
                <option
                  :key="'direccionDefectoFacturas-empty'"
                  :value="null"
                >{{''}}</option>
                <option
                  v-for="(item,index) in rform.formData.direcciones.filter(d => d.tipo == 'DESTINATARIO_FACTURA')"
                  :key="'direccionDefectoFacturas-'+index"
                  :value="item.alias"
                  :selected="item.alias == rform.formData.direccionDefectoFacturas"
                >{{item.alias}}</option>
              </r-input>
              <r-input
                class="col-50"
                :label="$t('clientes.direccionDefectoEnvio')"
                :form="rform"
                type="select"
                name="direccionDefectoEnvio"
              >
                <option
                  :key="'direccionDefectoEnvio-empty'"
                  :value="null"
                >{{''}}</option>
                <option
                  v-for="(item,index) in rform.formData.direcciones.filter(d => d.tipo == 'ENVIAR_A')"
                  :key="'direccionDefectoEnvio-'+index"
                  :value="item.alias"
                  :selected="item.alias == rform.formData.direccionDefectoEnvio"
                >{{item.alias}}</option>
              </r-input>
            </f7-row>
            </ul>
          </f7-list>
          <!-- Direcciones -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('clientes.direcciones')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.direcciones.push({})"
            >{{$t('clientes.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.direcciones"
              :key="'direcciones-'+index"
              :style="'z-index:'+(rform.formData.direcciones.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.alias')"
                        :form="rform"
                        :name="'direcciones.' + index + '.alias'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      ></r-input>
                      <!-- <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.nif')"
                        :form="rform"
                        :name="'direcciones.' + index + '.nif'"
                      ></r-input> -->
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.tipo')"
                        :form="rform"
                        type="select"
                        :name="'direcciones.' + index + '.tipo'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      >
                        <option selected></option>
                        <option value="ENVIAR_A">{{$t('clientes.enviar_a')}}</option>
                        <option value="DESTINATARIO_FACTURA">{{$t('clientes.destinatario_factura')}}</option>
                      </r-input>
                      <r-direccion :form="rform" :name="'direcciones.' + index"></r-direccion>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-link
                v-tooltip="'Borrar Dirección'"
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.direcciones,index);"
                class="margin-half link-inline link-inline-0"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
              <f7-link
                v-tooltip="'Duplicar Dirección'"
                v-if="!rform.readonly"
                @click.prevent="rform.formData.direcciones.push(
                  JSON.parse(JSON.stringify( Object.assign({}, rform.formData.direcciones[index], {alias: ''}))))"
                class="margin-half link-inline link-inline-1"
                icon-f7="plus_square_fill_on_square_fill"
              ></f7-link>
            </f7-card>
          </div>
        </f7-tab>
        <f7-tab id="tab-contactos">
          <f7-list class="list-form">
            <f7-row>
              <r-input
                class="col-50"
                :label="$t('clientes.contactoDefecto')"
                :form="rform"
                type="select"
                name="contactoDefecto"
              >
                <option
                  :key="'contactosDefecto-empty'"
                  :value="null"
                >{{''}}</option>
                <option
                  v-for="(item,index) in rform.formData.contactos"
                  :key="'contactosDefecto-'+index"
                  :value="item.alias"
                  :selected="item.alias == rform.formData.contactoDefecto"
                >{{item.alias}}</option>
              </r-input>
            </f7-row>
          </f7-list>
          <!-- Contactos -->
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('clientes.contactos')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.contactos.push({activo:true, canalesComunicacion:[]})"
            >{{$t('clientes.add')}}</f7-link>
          </f7-block-title>

          <div v-if="rform && rform.formData" style="z-index: 30">
            <f7-card
              class="elevation-3 padding-vertical-half padding-right"
              v-for="(item,index) in rform.formData.contactos"
              :key="'contactos-'+index"
              :style="'z-index:'+(rform.formData.contactos.length - index)"
            >
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.alias')"
                        :form="rform"
                        :name="'contactos.' + index + '.alias'"
                        :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* Este dato es obligatorio'}"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.nombre')"
                        :form="rform"
                        :name="'contactos.' + index + '.nombre'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.apellidos')"
                        :form="rform"
                        :name="'contactos.' + index + '.apellidos'"
                      ></r-input>
                      <r-input
                        class="col-33"
                        floating-label
                        :label="$t('clientes.posicion')"
                        :form="rform"
                        :name="'contactos.' + index + '.posicion'"
                      ></r-input>
                      <r-direccion
                        :form="rform"
                        :name="'contactos.' + index + '.direccion'">
                      </r-direccion>
                      <r-input
                        class="col-33"
                        :label="$t('clientes.activo')"
                        :form="rform"
                        type="check"
                        :name="'contactos.' + index + '.activo'"
                      ></r-input>
                    </f7-row>
                  </ul>
                </f7-list>
                <!-- Canales Comunicacion -->
                <f7-block-title style="z-index: 20">
                  {{$t('clientes.canalescomunicacion')}}
                  <f7-link
                    v-if="!rform.readonly && rform.formData"
                    @click="addCanalComunicacionContacto(index)"
                  >{{$t('centrostrabajo.add')}}</f7-link>
                </f7-block-title>

                <div style="z-index: 20">
                  <f7-card
                    class="elevation-3 padding-vertical-half padding-right"
                    v-for="(item2,index2) in rform.formData.contactos[index].canalesComunicacion"
                    :key="'contacto-'+index+'canalcomunicacion-'+index2"
                    :style="'z-index:'+(rform.formData.contactos[index].canalesComunicacion.length - index2)"
                  >
                    <f7-card-content>
                      <f7-list class="list-form">
                        <ul>
                          <f7-row>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.tipo')"
                              :form="rform"
                              type="select"
                              :name="'contactos.' + index + '.canalesComunicacion.' + index2 + '.tipo'"
                            >
                              <option selected></option>
                              <option value="TELEFONO">{{$t('clientes.telefono')}}</option>
                              <option value="TELEFONO_MOVIL">{{$t('clientes.telefono_movil')}}</option>
                              <option value="FAX">{{$t('clientes.fax')}}</option>
                              <option value="EMAIL">{{$t('clientes.email')}}</option>
                              <option value="WEB">{{$t('clientes.web')}}</option>
                            </r-input>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.nombre')"
                              :form="rform"
                              :name="'contactos.' + index + '.canalesComunicacion.' + index2 + '.nombre'"
                            ></r-input>
                            <r-input
                              class="col-33"
                              floating-label
                              :label="$t('clientes.valor')"
                              :form="rform"
                              :name="'contactos.' + index + '.canalesComunicacion.' + index2 + '.valor'"
                            ></r-input>
                          </f7-row>
                        </ul>
                      </f7-list>
                    </f7-card-content>
                    <f7-link
                      v-if="!rform.readonly && rform.formData"
                      @click="$delete(rform.formData.contactos[index].canalesComunicacion,index2);"
                      class="margin-half"
                      style="position:absolute;right:0;top:0;"
                      icon-f7="trash"
                    ></f7-link>
                  </f7-card>
                </div>
              </f7-card-content>
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="$delete(rform.formData.contactos,index);"
                class="margin-half link-inline link-inline-0"
                style="position:absolute;right:0;top:0;"
                icon-f7="trash"
              ></f7-link>
              <f7-link
                v-tooltip="'Duplicar Contacto'"
                v-if="!rform.readonly"
                @click.prevent="rform.formData.contactos.push(
                  JSON.parse(JSON.stringify( Object.assign({}, rform.formData.contactos[index], {alias: ''}))))"
                class="margin-half link-inline link-inline-1"
                icon-f7="plus_square_fill_on_square_fill"
              ></f7-link>
            </f7-card>
          </div>
          <f7-block-title v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
            {{$t('clientes.contactos')}}
            <f7-link
              v-if="!rform.readonly && rform.formData"
              @click="rform.formData.contactos.push({activo:true, canalesComunicacion:[]})"
            >{{$t('clientes.add')}}</f7-link>
          </f7-block-title>
        </f7-tab>
        <f7-tab id="tab-finanzas">
          <!-- Pago y Finanzas -->
          <!-- <f7-block-title  v-if="$refs.rform && $refs.rform.formData" style="z-index: 30">
              {{$t('clientes.f')}}
              <f7-link v-if="!rform.readonly && rform.formData" @click="rform.formData.contactos.push({activo:true})">{{$t('clientes.add')}}</f7-link>
          </f7-block-title>-->

          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row> 
                    <f7-col col="50" >
                      <r-autocomplete
                        :dbAdapter="ListaPrecio"
                        floating-label
                        :label="$t('clientes.listaPrecios')"
                        :form="rform"
                        name="listaPrecios"
                        labelProp="nombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        outline
                      ></r-autocomplete>
                      <div class="label-group" style="width: 100%;">Cuenta Cliente</div>
                      <r-input
                        floating-label
                        outline
                        :label="$t('clientes.iban')"
                        :form="rform"
                        type="text"
                        :name="'cuenta.iban'"
                        :validation="{isValid: str => {return validator.isEmpty(str, {ignore_whitespace:true}) || validator.isIBAN(str)}, errorMessage: '* Introduzca un IBAN válido'}"
                      ></r-input>
                    </f7-col>
                   
                    <div class="col-50 medium-50  cursor-pointer" @click="clickFechasPago($event.target)">
                      <r-input
                        outline
                        :label="$t('clientes.fechasPago')"
                        :form="rform"
                        :name="'fechasPago'"
                        type="textarea"
                        :readonly="true"
                      ></r-input>
                    </div>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-block-title style="z-index: 20">
              {{$t('clientes.condicionesPago')}}
              <f7-link
                v-if="!rform.readonly && rform.formData"
                @click="addCondicionDePago()"
              >{{$t('common.anadir')}}</f7-link>
            </f7-block-title>
            <f7-card-content>
              <f7-list class="list-form accordion-list" style="z-index: 40">
                <ul class="padding-horizontal">
                  <li
                      class="accordion-item row margin-vertical-half border-3"
                      :class="{'inactive':condicionPagoInactiva(condicionPago)}"
                      v-for="(condicionPago,index) in rform.formData.condicionesPago"
                      :key="index"
                      :style="'z-index:'+(40 + rform.formData.condicionesPago.length - index)"
                  >
                    <div class="item-inner col-100">
                      <f7-card class="no-margin">
                        <f7-list class="list-form">
                          <ul>
                            <f7-row no-gap class="padding-vertical-half first-col">
                              <r-autocomplete
                                :dbAdapter="ViaPago"
                                class="col-100 medium-25"
                                floating-label
                                :label="$t('ofertas.viaPago')"
                                :form="rform"
                                :source="filterViasPago"
                                @change="gestionarCambioViaPago(rform.formData, index, $event)"
                                :name='"condicionesPago."+index+".viaPago.codigo"'
                                :readonly="readonly || condicionPagoInactiva(condicionPago)"
                                labelProp="nombre"
                                idProp="codigo"
                                idFieldDetail="id"
                              ></r-autocomplete>
                              <r-autocomplete
                                ref="cuentas"
                                :dbAdapter="CondicionPago"
                                class="col-100 medium-25"
                                floating-label
                                :label="$t('ofertas.cuenta')"
                                :form="rform"
                                :source="filterCondicionesCuentas"
                                @change="gestionarCambioDatosPago(rform.formData, index, $event)"
                                :readonly="viasPagoSeleccionadasReadonly[index]"
                                :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']"
                                :autocompleteProps="{index: index}"
                                :name='"condicionesPago."+index+".cuenta"'
                                labelProp="cuenta"
                                idProp="cuenta"
                                idFieldDetail="id"
                                :showNavigationLink="false"
                              ></r-autocomplete>
                              <r-autocomplete
                                ref="plazosDias"
                                :dbAdapter="CondicionPago"
                                class="col-100 medium-25"
                                floating-label
                                :label="$t('ofertas.plazoDias')"
                                :form="rform"
                                :source="filterCondicionesPlazos"
                                @change="gestionarCambioDatosPago(rform.formData, index, $event)"
                                :readonly="viasPagoSeleccionadasReadonly[index]"
                                :shown-fields="['codigo', 'cuenta', 'plazoDias', 'viaPago.codigo']"
                                :autocompleteProps="{index: index}"
                                :name='"condicionesPago."+index+".plazoDias"'
                                labelProp="plazoDias"
                                idProp="plazoDias"
                                idFieldDetail="id"
                                :showNavigationLink="false"
                              ></r-autocomplete>
                            </f7-row>
                          </ul>
                        </f7-list>
                        <f7-link
                          external
                          class="margin-half link-inline link-inline-2"
                          v-tooltip="'Navegar a Condición de Pago'"
                          icon-f7="arrow_up_right_square"
                          color="green"
                          :href="`#!/condicionespago/view/${condicionPago.id}/`"
                        ></f7-link>
                        
                        <f7-link
                          v-tooltip="'Condición de pago principal'"
                          v-if="!condicionPagoInactiva(condicionPago) && condicionPago.principal"
                          class="margin-half link-inline link-inline-0"
                          color="green"
                          icon-f7="checkmark_seal"
                        ></f7-link>
                        <f7-link
                          v-if="!condicionPagoInactiva(condicionPago) && !condicionPago.principal"
                          v-tooltip="'Establecer como condición de pago principal'"
                          @click="condicionPagoPrincipal(index)"
                          class="margin-half link-inline link-inline-0"
                          icon-f7="checkmark_seal"
                        ></f7-link>
                        <f7-link
                          v-if="condicionPagoInactiva(condicionPago)"
                          v-tooltip="'Condición de pago inactiva'"
                          class="margin-half link-inline link-inline-0"
                          icon-f7="exclamationmark_triangle"
                          color="red"
                        ></f7-link>
                        <f7-link
                          v-tooltip="'Eliminar condición de pago'"
                          @click="borrarCondicionPago(index)"
                          class="margin-half link-inline link-inline-1"
                          icon-f7="trash"
                        ></f7-link>
                      </f7-card>
                    </div>
                  </li>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
                <f7-tab id="tab-comentarios">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-input
                      class="col-100"
                      :form="rform"
                      :name="'pestanaComentarios'"
                      type="textarea"
                  ></r-input>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-servicios">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete-m2m
                      :dbAdapter="Servicio"
                      class="col-100"
                      floating-label
                      :label="$t('clientes.servicio')"
                      :form="rform"
                      name="servicios"
                      labelProp="nombre"
                      prefixProp="codigo"
                      idProp="codigo"
                      idFieldDetail="id"
                      type="textarea"
                      :lineBreak="true"
                    ></r-autocomplete-m2m>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-documentos">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <div v-if="(rform.formData.documentos && !rform.formData.documentos.length > 0) || !rform.formData.documentos" >El cliente no tiene documentos</div>
              <f7-list class="list-form">
                <ul>
                  <r-files 
                    :form="rform" 
                    name="documentos"
                    :documentoAdapter="Documento"
                  ></r-files>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-tareas" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Tarea"
            :tableCode="'TAREASCLIENTE'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="100"
            :searchOnInit="true"
            :shownFields="['codigoCliente']"
            :mandatoryFilter ="{ 'codigoCliente': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupTareaEditable = false; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="square_pencil" color="blue" @click="popupTareaEditable = true; tareaId = item.id; popupTareasOpened = true"></f7-link>
                <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>

        <f7-tab id="tab-oportunidades" v-noroles="['ENAC', 'PRODUCCION']" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="Oportunidad"
            :tableCode="'OPORTUNIDADTAB'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['cliente.codigo']"
            :mandatoryFilter ="{ 'cliente##codigo': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link external v-tooltip="$t('common.ver') + ' Oportunidad'" icon-f7="eye" color="green" :href="`#!/oportunidades/view/${item.id}/`"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>

        <f7-tab id="tab-emails" v-if="$refs.rform && $refs.rform.formData && $refs.rform.formData.codigo">
          <r-dynamic-list
            ref="rlist"
            :primary="['id']"
            :dbAdapter="RegistroEmail"
            :tableCode="'REGISTROEMAILTABS'"
            :tableMode="true"
            :virtualListParams="{ height: 32, rowsBefore: 60, rowsAfter: 60 }"
            class="card full-height data-table"
            classContent="card-content full-height"
            :controlsWidth="50"
            :searchOnInit="true"
            :shownFields="['to','datos']"
            :mandatoryFilter ="{ 'datos##codigoCliente': $refs.rform.formData.codigo }"
            :funcShowCheck="_ => { return false; }"
            :massiveActions="false"
            useView="vista_RegistroEmails"
          >
            <template v-slot:optionsColumn="{ item }">
                <f7-link icon-f7="eye" color="green" @click="popupEmailEditable = false; emailId = item.id; popupEmailOpened = true"></f7-link>
            </template>
          </r-dynamic-list>
        </f7-tab>
      </f7-tabs>
      <r-form
        ref="rformInstalacion"
        v-slot="{rform}"
        :itemId="null"
        @save="onSaveInstalacion"
        :readonly="readonly"
        :dbAdapter="Instalacion"
        :beforeSave="beforeSaveInstalacion"
      >
        <f7-popup ref="popupInstalacion" class="popup-instalacion" swipe-to-close>
          <f7-page>
            <f7-navbar title="Instalación">
              <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
              </f7-nav-right>
            </f7-navbar>

            <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
            <f7-card class="elevation-3 padding-vertical-half padding-right">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                          class="col-50"
                          floating-label
                          :label="$t('clientes.codigo')"
                          :form="rform"
                          type="text"
                          name="codigo"
                        ></r-input>
                        <r-input
                          class="col-50"
                          floating-label
                          :label="$t('clientes.nombre')"
                          :form="rform"
                          type="text"
                          name="nombre"
                        ></r-input>
                      </f7-col>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                    fill
                    icon-f7="save"
                    text="Guardar Instalación"
                    class="display-flex margin-left"
                    color="green"
                    @click="$refs.rformInstalacion.saveItem()"
                  ></f7-button>
                </f7-col>
              </f7-row>
            </f7-card>
            <!-- </div> -->
          </f7-page>
        </f7-popup>
      </r-form>
      <r-form
        ref="rformItem"
        v-slot="{rform}"
        :itemId="null"
        @save="onSaveItem"
        :readonly="readonly"
        :dbAdapter="Item"
        :beforeSave="beforeSaveItem"
      >
        <f7-popup ref="popupItem" class="popup-item" swipe-to-close>
          <f7-page>
            <f7-navbar title="Item">
              <f7-nav-right>
                <f7-link popup-close>Cerrar</f7-link>
              </f7-nav-right>
            </f7-navbar>

            <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
            <f7-card class="elevation-3 padding-vertical-half padding-right">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                          floating-label
                          :label="$t('clientes.codigo')"
                          :form="rform"
                          type="text"
                          name="codigo"
                        ></r-input>
                        <r-input
                          floating-label
                          :label="$t('clientes.nombre')"
                          :form="rform"
                          type="text"
                          name="nombre"
                        ></r-input>
                        <r-autocomplete
                          :dbAdapter="Formato"
                          floating-label
                          :label="$t('clientes.formato')"
                          :form="rform"
                          name="formato"
                          nameId="formatoId"
                          labelProp="nombre"
                          idProp="id"
                        ></r-autocomplete>
                      </f7-col>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
              <f7-row>
                <f7-col width="100" medium="100">
                  <f7-button
                    fill
                    icon-f7="save"
                    text="Guardar Item"
                    class="display-flex margin-left"
                    color="green"
                    @click="$refs.rformItem.saveItem()"
                  ></f7-button>
                </f7-col>
              </f7-row>
            </f7-card>
            <!-- </div> -->
          </f7-page>
        </f7-popup>
      </r-form>
      <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
        <tarea-form :id="tareaId" :editable="popupTareaEditable" @close="$refs.popupTarea.close();"  
          :defaultData="{ codigoCliente: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
        ></tarea-form>
      </f7-popup>
      <f7-popup ref="popupEmail" swipe-to-close :opened="true" v-if="popupEmailOpened" @popup:closed="popupEmailOpened = false; emailId = null">
        <registro-email-form :id="emailId" :editable="popupEmailEditable" @close="$refs.popupEmail.close();"  
          :defaultData="{ codigoCliente: $refs.rform.formData.codigo, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date() }" :esPopup="true"
        ></registro-email-form>
      </f7-popup>
    </template>
  </r-form>
</template>
<style scoped>
.limit-height {
  overflow: auto;
  margin-top: 28px;
  max-height: 408px;
}
.cabecera-fechaPago {
  position:absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  background: white;
  border-radius: 4px 4px 0 0;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ddd;
  text-align: center;
}
.nueva-fecha {
  width:100%
}
ul.no-padding ul {
  padding: 0 !important;
}
li.accordion-item .card, li.accordion-item .card ul, .border-3, li.accordion-item .accordion-item-content {
  border-radius: 20px;
}
.border-3 {
  border: 3px solid;
}
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
  /* width:100%; */
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.link-inline {
  position: absolute;
  top:0;
}
.link-inline-0 {
  right:0;
}
.link-inline-1 {
  right:25px;
}
.link-inline-2 {
  right:50px;
}
.over-card {
  position: absolute;
  top: -10px;
  z-index: 3000;
  left: 12px;
}
.item-inner.col-100{
  width: 100%;
  display: block;
  padding: 0;
}

.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item.inactive,
.accordion-item.inactive ul {
  background-color: #fad0d0;
}
.accordion-item-content {
  background-color: white;
}
</style>
<style>

.popover-inner {
  position:relative;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer textarea{
  cursor: pointer;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rCuenta from "./../../components/rCuenta.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Cliente from "./../../js/db/Cliente.js";
import Item from "./../../js/db/Item.js";
import Formato from "./../../js/db/Formato.js";
import GrupoCliente from "./../../js/db/GrupoCliente.js";
import EmpresaMantenedora from "./../../js/db/EmpresaMantenedora.js";
import ListaPrecio from "./../../js/db/ListaPrecio.js";
import CondicionPago from "./../../js/db/CondicionPago.js";
import ViaPago from "./../../js/db/ViaPago.js";
import Instalacion from "./../../js/db/Instalacion.js";
import Comunidad from "./../../js/db/Comunidad.js";
import Servicio from "./../../js/db/Servicio.js";
import validator from "validator";
import Empleado from "./../../js/db/Empleado.js";
import ValidationUtils from "./../../js/ValidationUtils.js";
import Utils from "./../../js/Utils.js";
import Documento from "./../../js/db/Documento.js";
import Tarea from "./../../js/db/Tarea.js";
import TareaForm from "./../tareas/tarea-form.vue";
import RegistroEmail from "../../js/db/RegistroEmail.js";
import RegistroEmailForm from "./../registroemail/registroemail-form.vue";
import Oportunidad from "./../../js/db/Oportunidad.js";
import rDynamicList from "./../../components/rDynamicList.vue";
import LineaPedido from "./../../js/db/LineaPedido.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rDireccion,
    rCuenta,
    TareaForm,
    RegistroEmailForm,
    rDynamicList
  },
  data: function() {
    var self = this;
    return {
      Cliente,
      Tarea,
      RegistroEmail,
      Oportunidad,
      Item,
      Documento,
      Formato,
      GrupoCliente,
      EmpresaMantenedora,
      ListaPrecio,
      CondicionPago,
      ViaPago,
      Instalacion,
      Comunidad,
      Servicio,
      validator,
      LineaPedido,
      readonly: !this.editable,
      itemsInstalacion: [],
      isTitularOpen: false,
      openedInstalacion: {},
      condicionesPagoActivas: [],
      idData: null,
      Empleado,
      ValidationUtils,
      Utils,
      viasPagoSeleccionadas: [],
      popupTareasOpened: false,
      tareaId: null,
      popupTareaEditable: false,
      popupEmailOpened: false,
      emailId: null,
      popupEmailEditable: false,
      showDetalle: true
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    viasPagoSeleccionadasReadonly: function() {
      const self = this;
      if(!self.viasPagoSeleccionadas || !self.viasPagoSeleccionadas.length){
        return [];
      }
      let viasPagoSeleccionadas = self.viasPagoSeleccionadas.map(viaPagoSeleccionada => !viaPagoSeleccionada.codigo || self.condicionPagoInactiva(viaPagoSeleccionada));
      return viasPagoSeleccionadas;
    }
  },
  beforeDestroy() {},
  methods: {
    crearOportunidad() {
      const self = this;
      self.$f7.dialog.confirm('¿Está seguro de que desea crear una oportunidad?', 'Crear oportunidad', () => {
        Oportunidad.save({
          data: {cliente: {id: self.$refs.rform.formData.id}, gestor: Utils.getUser().username}
        }).then(res => {
          self.$f7.dialog.alert("Creada oportunidad " + res.codigo);
          window.open('#!/oportunidades/view/' + res.id + '/', "_blank");
        });
      });
    },
    clickFechasPago(target) {
      const self = this;
      self.$refs.popo.open(target);
    },
    nuevaOpcion(opciones){
      var self = this;
      opciones.push('');
      var lista = self.$refs.fechaPagoList.$el;
      setTimeout(_=>{lista.scrollTop = lista.scrollHeight;},100); // Ñapa necesaria porque esto no es buena praxis. 
    },
    removeEmptyOptions(cliente){
      var self = this;
      self.$set(cliente, "fechasPago", cliente.fechasPago.filter(fecha => !!fecha));
    },
    condicionPagoInactiva(condicionPago){
      var self = this;
        var condicionesPagoActivas = self.condicionesPagoActivas;
      return !condicionPago.nueva && !condicionesPagoActivas.some(condicion => condicion.codigo == condicionPago.codigo);
    },
    changeEmpleado: function(empleado) {
      var self = this;
      var app = self.$f7;
      if (empleado && empleado.idSap) {
        self.$set(self.$refs.rform.formData, 'empleadoVentasSlpCode', empleado.slpCode)
      } 
    },
    addCanalComunicacionContacto: function(index) {
      const self = this;
      var formData = self.$refs.rform.formData;
      var canales = formData.contactos[index].canalesComunicacion || [];
      canales.push({tipo: '', nombre: '', valor:''});
      self.$set(self.$refs.rform.formData.contactos[index], 'canalesComunicacion', canales);
    },
    addCondicionDePago: function() {
      const self = this;
      var formData = self.$refs.rform.formData,
          nuevaCondicion = (formData.condicionesPago && formData.condicionesPago.length) ? {} : {principal: true};
      nuevaCondicion.nueva = true;
      formData.condicionesPago.push(nuevaCondicion);
      self.viasPagoSeleccionadas.push(nuevaCondicion);
    },
    filterViasPago: function(autocomplete){
      var self = this;
      return function(query, render) {
        var results = autocomplete.items.filter(item => self.condicionesPagoActivas.some(condicion => condicion.viaPago.codigo == item.codigo)).map(el => {el['txtValue'] = el["nombre"]; return el});
        render(results);
      };
    },
    gestionarCambioViaPago: function(formData, index, val) {
      var self = this,
          condicionPago = self.$refs.rform.formData.condicionesPago[index];
      condicionPago.viaPago = {
        codigo: val.codigo
      };
      self.$set(self.viasPagoSeleccionadas, index, val);

      if(!val.id){
        self.$set(condicionPago, 'cuenta', undefined);
        self.$set(condicionPago, 'plazoDias', undefined);
      }
      self.completeCondicionPago(index, condicionPago);
    },
    filterCondicionesCuentas: function(autocomplete){
      const self = this,
          autocompleteIndex = autocomplete.autocompleteProps.index,
          condicionPago = self.$refs.rform.formData.condicionesPago[autocompleteIndex];
      if(!autocomplete){
        return;
      }
      return function(query, render) {
        var options = autocomplete.items.filter(condicion => self.getCondicionesPagoPosibles(condicionPago).some(condicionPosible => condicionPosible.id == condicion.id && condicion.cuenta))
          .map(condicion => {
            if(condicion["cuenta"] != undefined){
              condicion['txtValue'] = ""+condicion["cuenta"];
            }
            return condicion;
          });
        options.sort((a,b) => a.cuenta > b.cuenta ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });
        render(results);
      };
    },
    filterCondicionesPlazos: function(autocomplete){
      const self = this,
          autocompleteIndex = autocomplete.autocompleteProps.index,
          condicionPago = self.$refs.rform.formData.condicionesPago[autocompleteIndex];
      if(!autocomplete){
        return;
      }
      return function(query, render) {
        var options = autocomplete.items.filter(condicion => self.getCondicionesPagoPosibles(condicionPago).some(condicionPosible => condicionPosible.id == condicion.id && condicion.plazoDias != undefined))
          .map(condicion => {
            if(condicion["plazoDias"] != undefined){
              condicion['txtValue'] = ""+condicion["plazoDias"];
            }
            return condicion;
          });
        options.sort((a,b) => a.plazoDias > b.plazoDias ? 1 : -1);
        var results = [];
        options.forEach(option => {
          if(!results.some(result => result.txtValue == option.txtValue) && option.txtValue){
            results.push(option);
          }
        });
        render(results);
      };
    },
    gestionarCambioDatosPago: function(formData, index, val) {
      var self = this,
        formData = self.$refs.rform.formData,
        condicionPago = formData.condicionesPago[index];
      if(val.id){
        self.completeCondicionPago(index, condicionPago);
      } else {
        condicionPago.codCondicionesPago = null;
        condicionPago.codFormaPago = null;
      }
    },
    completeCondicionPago: function(index, condicionPago){
      var self = this;
      var condicionesPagoPosibles = self.getCondicionesPagoPosibles(condicionPago);
      condicionPago = self.$refs.rform.formData.condicionesPago[index];
      if(condicionesPagoPosibles.length == 1){
        var condicion = condicionesPagoPosibles[0];
        if(!condicionPago.cuenta) {
          self.$set(self.$refs.rform.formData.condicionesPago[index], 'cuenta', condicion.cuenta);
          condicionPago.cuenta = condicion.cuenta;
        }
        if(!condicionPago.plazoDias) {
          self.$set(self.$refs.rform.formData.condicionesPago[index], 'plazoDias', condicion.plazoDias);
          condicionPago.plazoDias = condicion.plazoDias;
        }
        self.$set(self.$refs.rform.formData.condicionesPago[index], 'codigo', condicion.codigo);
        self.$set(self.$refs.rform.formData.condicionesPago[index], 'id', condicion.id);
        self.$set(self.$refs.rform.formData.condicionesPago[index], 'nombre', condicion.nombre);
      }
      else if(!condicionesPagoPosibles.length){
        self.$set(condicionPago, 'cuenta', undefined);
        self.$set(condicionPago, 'plazoDias', undefined);
      }
    },
    getCondicionesPagoPosibles: function(condicionPago){
      var self = this;
      var condiciones = self.condicionesPagoActivas;
      if(!condicionPago){
        return condiciones;
      }

      if(condicionPago.viaPago && condicionPago.viaPago.codigo){
        condiciones = condiciones.filter(condicion => condicion.viaPago.codigo == condicionPago.viaPago.codigo);
      }
      if(condicionPago.cuenta){
        condiciones = condiciones.filter(condicion => condicion.cuenta == condicionPago.cuenta);
      }
      if(condicionPago.plazoDias != undefined){
        condiciones = condiciones.filter(condicion => condicion.plazoDias == condicionPago.plazoDias);
      }
      return condiciones;
    },
    condicionPagoPrincipal: function(index){
      var self = this,
        condicionesPago = self.$refs.rform.formData.condicionesPago;
      condicionesPago.forEach(condicion => {
        self.$set(condicion, "principal", false);
      });
      self.$set(condicionesPago[index], "principal", true);
    },
    borrarCondicionPago: function(index){
      var self = this,
        condicionesPago = self.$refs.rform.formData.condicionesPago;
      condicionesPago.splice(index,1);
      var condicionesPagoPrincipales = condicionesPago.filter(condicion => condicion.principal);
      if(!condicionesPagoPrincipales.length && condicionesPago.length){
        condicionesPago[0].principal = true;
      }
    },
    borrarItem: function(id) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Item.delete({ itemId: id })
            .then(function(res) {
              if (res) {
                self.itemsInstalacion = self.itemsInstalacion.filter(
                  i => i.id != id
                );
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    borrarTitular: function(instalacion, titular) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          instalacion.titulares = instalacion.titulares.filter(
            t => t.nif != titular.nif
          );
        }
      );
    },
    borrarInstalacion: function(codigo) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Instalacion.delete({ clienteId: self.idData, codigo: codigo })
            .then(function(res) {
              if (res) {
                self.$refs.rform.formData = res;
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            });
        }
      );
    },
    openTitular: function(event) {
      var self = this;
      self.isTitularOpen = true;
    },
    openInstalacion: function(instalacion) {
      var self = this;
      var app = self.$f7;
      if (self.isTitularOpen) {
        self.isTitularOpen = false;
        return;
      }
      self.itemsInstalacion = [];
      app.preloader.show();
      self.openedInstalacion = instalacion;
      var params = {
        filter: {
          idCliente: self.idData,
          codigoInstalacionEq: instalacion.codigo
        }
      };
      Item.getList(params)
        .then(function(res) {
          self.itemsInstalacion = res.list;
        })
        .catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.error || e.error_description;
          app.toast
            .create({
              text: error,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        })
        .then(function() {
          app.preloader.hide();
        });
    },
    loadItem: function(res) {
      var self = this;
      if (!res.instalaciones || res.instalaciones == null) {
        self.$set(self.$refs.rform.formData, "instalaciones", []);
      }
      if (!res.canalesComunicacion || res.canalesComunicacion == null) {
        self.$set(self.$refs.rform.formData, "canalesComunicacion", []);
      }
      if (!res.direcciones || res.direcciones == null) {
        self.$set(self.$refs.rform.formData, "direcciones", []);
      }
      if (!res.contactos || res.contactos == null) {
        self.$set(self.$refs.rform.formData, "contactos", []);
      }
      console.log("loadItem", res.condicionesPago, res.viasPago);
      if (!res.condicionesPago || res.condicionesPago == null) {
        self.$set(self.$refs.rform.formData, "condicionesPago", []);
      }
      if (!res.viasPago || res.viasPago == null) {
        self.$set(self.$refs.rform.formData, "viasPago", []);
      }
      if (!res.servicios || res.servicios == null) {
        self.$set(self.$refs.rform.formData, "servicios", []);
      }
    },
    beforeSaveInstalacion: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.idData;
      resolve(formData);
    },
    beforeSaveItem: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var formData = data;
      formData.clienteId = self.idData;
      formData.codigoInstalacion = self.openedInstalacion.codigo;
      resolve(formData);
    },
    onSaveItem: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      if (!self.itemsInstalacion) {
        self.itemsInstalacion = [];
      }
      self.itemsInstalacion.push(res);
      self.$refs.rformItem.clear();
      if (self.$refs.popupItem) {
        self.$refs.popupItem.close();
      }
      app.toast
        .create({
          text: self.$t("items.item_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onSaveInstalacion: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rformInstalacion.clear();
      if (self.$refs.popupInstalacion) {
        self.$refs.popupInstalacion.close();
      }
      if (self.$refs.rform && self.$refs.rform.formData) {
        var formData = self.$refs.rform.formData;
        formData.instalaciones.push(res);
      }
      app.toast
        .create({
          text: self.$t("clientes.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.idData = res.id;
      app.toast
        .create({
          text: self.$t("clientes.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    CondicionPago.getList({filter:{activa:true}}).then(function(res){
      self.condicionesPagoActivas = res.list;
    });
    self.idData = self.id;
  }
};
</script>