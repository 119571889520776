var FlashUtils = {
    store: {},
    get: function(variable){
        var self = this;
        var value = self.store[variable];
        if(typeof value === "object"){
            value = Object.assign({}, value);
        } else if(typeof value === "array") {
            value = Object.assign([], value);
        }
        self._clear(variable);
        return value;
    },
    set: function(variable, value){
        var self = this;
        self.store[variable] = value;
    },
    _clear: function(variable){
        var self = this;
        delete self.store[variable];
    },
}

export default FlashUtils;