<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('inspecciones.inspecciones.inspecciones')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Inspeccion"
        :tableCode="'EXPEDIENTE'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['codigo','estado','idPedido','cliente._id','bloqueado','numeroLineaPedido', 'numeroPedido' ]"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="130"
        :searchOnInit="searchOnInit"
      >
        <template v-slot:optionsColumn="{item, rlist}">
          <f7-link icon-f7="eye" color="green" :href="`/inspecciones/view/${item.id}/`" v-tooltip="'Ver inspección'"></f7-link>
          <f7-link v-if="!item.bloqueado && (item.estado && (
                                                              item.estado.estado == 'GENERADO' 
                                                              || item.estado.estado == 'PLANIFICADO' 
                                                              || item.estado.estado == 'INSPECCIONADO' 
                                                              || item.estado.estado == 'CORREGIR_ERRORES_AUDITORIA_CALIDAD'
                                                              )
                                            )"
            v-roles="{route: $f7route.url, roles: ['DTYCAL','ADMIN','INSPECTOR']}"
            icon-f7="square_pencil" 
            color="blue" 
            :href="`/inspecciones/edit/${item.id}/`" 
            v-tooltip="'Editar inspección'"></f7-link>
          <f7-link v-tooltip="'Asociar Pedido'" v-if="!item.idPedido" icon-f7="doc_text_viewfinder" color="yellow" @click="abrirPopupPedido(item)"></f7-link>
          <f7-link v-tooltip="'Desasociar Pedido'" v-if="item.idPedido && (item.estado.estado == 'INSPECCIONADO' || item.estado.estado == 'AUDITADO' || item.estado.estado == 'PLANIFICADO' || item.estado.estado == 'GENERADO')" icon-f7="viewfinder" color="red" @click="desasociar(item)"></f7-link>
          <f7-link v-tooltip="$t('common.eliminar')"  v-if="!item.idPedido && (item.estado.estado == 'INSPECCIONADO' || item.estado.estado == 'AUDITADO' || item.estado.estado == 'PLANIFICADO')" v-roles="{route: $f7route.url, roles: ['ADMIN']}" icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
          <f7-link v-tooltip="$t('common.duplicar')" icon-f7="rectangle_stack_badge_plus" color="green" @click="duplica(item.id)"></f7-link>
          <f7-link v-tooltip="$t('common.borrar')" v-if="!item.idPedido"  icon-f7="trash" color="red" @click="rlist.deleteItem(item.id);"></f7-link>
        </template>
      </r-dynamic-list>
      <!-- rList Table CARD-->
    </f7-page-content>
    <f7-popup ref="popupAsociarPedido" class="popup-asociar-pedido" swipe-to-close @popup:closed="inspeccion = null;">
      <f7-page>
        <f7-navbar title="Asociar Pedido">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
        <r-form 
          ref="rformAsociar" 
          @save="onSaveAsociar"
          :dbAdapter="null"
          classContent="full-height"
          :readonly="false"
        >
          <template v-slot:default="{ rform }">
            <f7-card class="elevation-3 padding-vertical-half padding-right">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <f7-row>
                      <f7-col width="100" medium="100">
                        <r-input
                          :clearButton="false"
                          floating-label
                          outline
                          label="Número o código de pedido"
                          :form="rform"
                          name="numeroOCodigoPedido"
                        />
                        <r-input
                          :clearButton="false"
                          outline
                          floating-label
                          label="Número de línea"
                          :form="rform"
                          type="number"
                          name="numeroLinea"
                        />
                      </f7-col>
                    </f7-row>
                  </ul>
                </f7-list>
              </f7-card-content>
            </f7-card>
            <f7-row>
              <f7-col width="100" medium="100">
                <f7-button
                    fill
                    icon-f7="disk"
                    text="Asociar"
                    class="display-flex margin-vertical margin-left"
                    color="green"
                    @click="rform.saveItem()"
                  ></f7-button>
              </f7-col>
            </f7-row>
          </template>
        </r-form>
      </f7-card>
        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
        <r-list
          ref="rlistLineas"
          slot="default"
          :dbAdapter="LineaPedido"
          :tableMode="true"
          class="card full-height data-table"
          classContent="card-content full-height"
          :classList="['full-height']"
          :searchOnInit="false"
          :mostrarBadgeFilters="false"
          :shownFields="['id', 'numero', 'codigo', 'lineas.numero', 'lineas.servicio', 
            'lineas.idExpediente', 'lineas.estadoExpediente', 'cliente._id', 'lineas.tipoLinea', 'cliente.razonSocial',
            'cliente.codigo', 'contratacion.codEmpleado', 'cliente.empleadoVentas', 'cliente.responsable', 'lineas.inspectores.username',
            'lineas.camposUsuario.cpEj', 'invitados.username']"
          
        >
          <template v-slot:header="{rlist}">
            <thead class="thead-sticky">
              <tr>
                <th class="checkbox-cell" style="width: 2rem;">
                  <label class="checkbox">
                    <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                    <i class="icon-checkbox"></i>
                  </label>
                </th>
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('numero')"
                  :class="rlist.sortableClasses('numero')"
                >{{$t('pedidos.numero')}}</th>
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('codigo')"
                  :class="rlist.sortableClasses('codigo')"
                >{{$t('pedidos.pedido')}}</th>
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('lineas.numero')"
                  :class="rlist.sortableClasses('lineas.numero')"
                >{{$t('pedidos.linea')}}</th>
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('lineas.servicio.nombre')"
                  :class="rlist.sortableClasses('lineas.servicio.nombre')"
                >{{$t('pedidos.servicio')}}</th>
                <!-- <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
                  <f7-icon f7="gear_alt_fill"></f7-icon>
                </th> -->
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('cliente.codigo')"
                  :class="rlist.sortableClasses('cliente.codigo')"
                >{{$t('clientes.codCliente')}}</th>
                <th
                  style="min-width:1em;"
                  @click="rlist.setOrder('cliente.razonSocial')"
                  :class="rlist.sortableClasses('cliente.razonSocial')"
                >{{$t('clientes.cliente')}}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:default="{vlData,rlist}">
            <tr v-for="(item) in vlData.items" :key="'pedido-' + item.id + '-linea-' + item.lineas.numero"
              @click="asociarPedido(item)">
              <th class="checkbox-cell">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :checked="item.id in rlist.selected"
                    @change="rlist.toggleSelect(item, $event.target.checked, item.id + '-linea-' + item.lineas.numero)"
                  />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <td>{{item.numero}}</td>
              <td>{{item.codigo}}</td>
              <td>{{item.lineas.numero}}</td>
              <td>{{item.lineas.servicio ? item.lineas.servicio.nombre : '-'}}</td>
              <td>{{item.cliente ? item.cliente.codigo : '-'}}</td>
              <td>{{item.cliente ? item.cliente.razonSocial : '-'}}</td>
              <!-- <td class="text-align-right right-column-sticky">
                <f7-link icon-f7="square_pencil" color="blue" :href="`/pedidos/edit/${item.id}/`" v-tooltip="'Editar Pedido'"></f7-link>
              </td> -->
            </tr>
          </template>
          <template v-slot:after="{rlist}">
            <f7-card-footer>
              <span>
                <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
              </span>
            </f7-card-footer>
          </template>
        </r-list>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Inspeccion from "./../../js/db/Inspeccion.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import rDynamicList from "./../../components/rDynamicList.vue";
import Utils from "./../../js/Utils.js";
import CacheService from './../../js/db/Cache.js';

export default {
  components: {
    rList,
    rForm,
    rInput,
    rTimepicker,
    rAutocomplete,
    rDynamicList
  },
  data() {
    return {
      Inspeccion,
      LineaPedido,
      Utils,
      rPanelOpenedByBreakpoint: false,
      inspeccion: {},
      filtroLineas: {}
    };
  },
  props: {
    searchOnInit: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "INSPECCIONADO"; 
    },
    submitFilter: function(rlist, event, rpanel) {
      var self = this;
      rlist.setFilter(event);
      rpanel.close();
    },
    submitFilterLineas: function(rlist, event) {
      var self = this;
      rlist.setFilter(event);
    },
    abrirPopupPedido: function(inspeccion) {
      var self = this;
      var app = self.$f7;
      self.inspeccion = inspeccion;
      if (!self.inspeccion.cliente) {
          app.dialog.alert("La inspección debe tener un <b>cliente</b> para poder ser asociada a un pedido.");
          return;
      }
      var filtros = {
        esParaAsociarExpediente: true,
        andList : [
          {
            nombre: 'cliente._id',
            operacion: 'EQUALS_OBJECT_ID',
            valor: self.inspeccion.cliente.id
          }, 
          {
            nombre: 'lineas.tipoLinea',
            operacion: 'EQUALS',
            valor: 'LINEA'
          },
          {
            operacion: "OR",
            valor: [
              {
                nombre: "lineas.idExpediente",
                valor: false,
                operacion: "EXISTS"
              },
              {
                nombre: "lineas.estadoExpediente",
                valor: "PLANIFICADO",
                operacion: "EQUALS"
              }
            ]
          }
        ]
      };
      self.$refs.rlistLineas.setFilter(filtros);
      self.$refs.rlistLineas.reload();
      app.popup.open(".popup-asociar-pedido");
    },
    onSaveAsociar () {
      const self = this;
      var asociarPlani = self.$refs.rformAsociar.formData;
      var asociacion = {
        idExpediente: self.inspeccion.id,
        numeroOCodigoPedido: asociarPlani.numeroOCodigoPedido,
        numeroLineaPedido: asociarPlani.numeroLinea
      };
      self.asociarPedido(null, asociacion);
    },
    asociarPedido: function(pedido, asociacionInput) {
      var self = this;
      var app = self.$f7;

      app.dialog.confirm(
        "¿Está seguro que quiere asociar el expediente al pedido " + (asociacionInput ? asociacionInput.numeroOCodigoPedido : pedido.numero) + " y línea " + (asociacionInput ? asociacionInput.numeroLineaPedido : pedido.lineas.numero) + "?",
        "Advertencia",
        function() {
          let asociacion = null;
          if (asociacionInput) {
            asociacion = asociacionInput;
          } else {
            asociacion = {
              idExpediente: self.inspeccion.id,
              idPedido: pedido.id,
              numeroLineaPedido: pedido.lineas.numero
            };
          }
          app.preloader.show();
          Inspeccion.asociarPedido(asociacion).then(function(res){
            console.log("cerrando");
            CacheService.clearCache('pedido/lineas');
            self.$refs.popupAsociarPedido.close();
            self.$refs.rlist.reload();
            // app.$f7.views.current.router.navigate('/ofertas/view/' + rform.formData.idOferta + '/')
            return res;
          },function(res){
            console.log("error", res);
            return res;
          }).then(function(){
            console.log("quitando preloader");
            app.preloader.hide();
          });
        }
      );
    },
    desasociar: function(item) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        "¿Está seguro de que quiere desasociar el expediente " + item.codigo + " de la línea " + item.numeroLineaPedido  + " del pedido " + ( item.numeroPedido ? item.numeroPedido : item.codPedido ) + " ?",
        "Advertencia",
        function() {
          app.preloader.show();
          Inspeccion.desasociar(item).then(function(res){
            CacheService.clearCache('pedido/lineas');
            self.$refs.rlist.reload();
            app.preloader.hide();
            app.toast
            .create({
              text: "Expediente desasociado con éxito",
              position: "center",
              closeTimeout: 2000
            })
            .open();
          }).catch(e => {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          }).then(_ => app.preloader.hide());
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.inspeccion = null;
      if (self.$refs.popupEditar) {
        self.$refs.popupEditar.close(true);
      }
      if (self.$refs.rform) {
        self.$refs.rform.clear();
      }
      app.toast
      .create({
        text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
        position: "center",
        closeTimeout: 2000,
        icon: '<i class="f7-icons">checkmark_alt</i>'
      })
      .open();
      if (self.$refs.rlist) {
        self.$refs.rlist.reload()
      }
    },
    duplica: function(id) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.duplica({
        itemId: id,
        data: {}
      }).then(function(res){
        if (self.$refs.rlist) {
          self.$refs.rlist.reload()
        }
        app.views.current.router.navigate(
          "/inspecciones/edit/"+res.id+"/"
        );
      }).catch(e => {
        if (typeof e == "string") e = { error: e };
        let error = e.message || e.error || e.error_description;
        app.dialog.alert(error);
      }).then(_ => app.preloader.hide());
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>