import Documentos from './documentos.vue';
import DocumentoForm from './documento-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/documentos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('documentos', ['ADMIN', 'INSPECTOR'], Documentos),
        keepAlive: true
    },
    {
        path: '/documentos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('documentos/new', ['ADMIN', 'INSPECTOR'], DocumentoForm)
    },
    {
        path: '/documentos/edit/:path/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('documentos/edit', ['ADMIN', 'INSPECTOR'], DocumentoForm)
    },
    {
        path: '/documentos/view/:path/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('documentos/view', ['ADMIN', 'INSPECTOR'], DocumentoForm, { editable: false })
    },
];
export default routes;