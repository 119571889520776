<template>
  <r-form
    ref="rform"
    :itemId="id"
    @save="onSave"
    :beforeSave="beforeSave"
    class="page"
    classContent="full-height"
    :readonly="readonly"
    :dbAdapter="PersonalCualificado"
    :documentoAdapter="Documento"
    @loadItem="loadedItem"
  >
    <template v-slot:before="{rform}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title v-if="id && !readonly" sliding>{{$t('common.editar')}}</f7-nav-title>
        <f7-nav-title v-else-if="id" sliding>Personal cualificado</f7-nav-title>
        <f7-nav-title v-else sliding>{{$t('common.nueva')}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!readonly"
            fill
            icon-f7="floppy_disk"
            :text="$t('common.guardar')"
            class="display-flex"
            @click="$refs.rform.saveItem()"
          ></f7-button>
          <template slot="right" v-else>
            <f7-button color="blue" @click="readonly=false">{{$t('common.editar')}}</f7-button>
            <f7-button color="red" @click="deleteItem">{{$t('common.eliminar')}}</f7-button>
          </template>
        </f7-nav-right>
        <f7-subnavbar>
          <f7-segmented raised class="elevation-6">
            <f7-button fill tab-link="#tab-datos" tab-link-active>Datos</f7-button>
            <f7-button fill tab-link="#tab-documentos">Documentos</f7-button>
          </f7-segmented>
        </f7-subnavbar>
      </f7-navbar>
    </template>
    <template v-slot:default="{rform}">
      <div class="full-height">
        <f7-tabs animated>
          <f7-tab id="tab-datos" class="page-content" tab-active>
            <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
              <f7-card-content>
                <f7-row no-gap class="list list-form no-hairlines">
                  <f7-col width="100" medium="50">
                    <ul>
                      <f7-block-title v-if="countInspecciones >= 0" small>Inspecciones en el último año: {{countInspecciones}}</f7-block-title>
                      <r-autocomplete
                        :dbAdapter="Empleado"
                        key="empleados"
                        floating-label
                        label="Empleado"
                        :form="rform"
                        name="empleado"
                        labelProp="nombre"
                        :idProp="null"
                        prefixProp="username"
                        idFieldDetail="id"
                        :shownFields="['id', 'username', 'nombre', 'email']"
                        :showNavigationLink="false"
                        @change="() => loadCountInspecciones()"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cualificaciones"
                        key="cualificaciones"
                        floating-label
                        label="Cualificación"
                        :form="rform"
                        name="cualificacion"
                        labelProp="nombre"
                        :idProp="null"
                        prefixProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['id', 'codigo', 'nombre']"
                        :showNavigationLink="false"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Materia"
                        key="materias"
                        floating-label
                        label="Materia"
                        :form="rform"
                        name="materia"
                        labelProp="nombre"
                        :idProp="null"
                        prefixProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['id', 'codigo', 'nombre', 'codigoExpediente']"
                        :showNavigationLink="false"
                        @change="() => loadCountInspecciones()"
                      ></r-autocomplete>
                      <r-input
                        floating-label
                        label="En Vigor"
                        :form="rform"
                        name="enVigor"
                        type="check"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Fecha cualificacion"
                        :form="rform"
                        type="datepicker"
                        name="fechaCualificacion"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Fecha baja"
                        :form="rform"
                        type="datepicker"
                        name="fechaBaja"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Causas baja"
                        :form="rform"
                        type="text"
                        name="causasBaja"
                      ></r-input>
                      <f7-block-title medium>Supervisión técnica</f7-block-title>
                      <r-input
                        floating-label
                        label="Fecha última supervisión técnica"
                        :form="rform"
                        type="datepicker"
                        name="fechaUltimaSupervTecnica"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                        @change="sumarFecha('fechaUltimaSupervTecnica', 4, 'years', 'fechaProxSupervTecnica')"
                        ></r-input>
                      <r-input
                        floating-label
                        label="Código Última supervisión técnica"
                        :form="rform"
                        type="text"
                        name="codigoUltimaSupervTecnica"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Código Expediente Última supervisión técnica"
                        :form="rform"
                        type="text"
                        name="codigoExpUltimaSupervTecnica"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Fecha próxima supervisión técnica"
                        :form="rform"
                        type="datepicker"
                        name="fechaProxSupervTecnica"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                      <f7-block-title medium>Supervisión documental</f7-block-title>
                      <r-input
                        floating-label
                        label="Fecha última supervisión documental"
                        :form="rform"
                        type="datepicker"
                        name="fechaUltimaSupervDocumental"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Código Última supervisión documental"
                        :form="rform"
                        type="text"
                        name="codigoUltimaSupervDocumental"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Código Expediente Última supervisión documental"
                        :form="rform"
                        type="text"
                        name="codigoExpUltimaSupervDocumental"
                      ></r-input>
                      <r-input
                        floating-label
                        label="Fecha próxima supervisión documental"
                        :form="rform"
                        type="datepicker"
                        name="fechaProxSupervDocumental"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ></r-input>
                      <f7-block-title medium>Observaciones</f7-block-title>
                      <r-input
                        floating-label
                        :form="rform"
                        type="textarea"
                        name="observaciones"
                        resizable
                      ></r-input>
                    </ul>
                  </f7-col>
                </f7-row>
              </f7-card-content>
            </f7-card>
          </f7-tab>
          <f7-tab id="tab-documentos" class="page-content" tab-active>
            <f7-card class="elevation-3 padding-vertical-half padding-right">
              <f7-card-content>
                <f7-list class="list-form">
                  <ul>
                    <r-files 
                      :form="rform" 
                      name="documentos"
                      :documentoAdapter="Documento"
                    ></r-files>
                  </ul>
                </f7-list>
              </f7-card-content>
            </f7-card>
          </f7-tab>
        </f7-tabs>
      </div>
    </template>
  </r-form>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import PersonalCualificado from "./../../js/db/PersonalCualificado.js";
import Cualificaciones from "./../../js/db/Cualificaciones.js";
import Empleado from "./../../js/db/Empleado.js";
import Materia from "./../../js/db/Materia.js";
import Documento from "./../../js/db/Documento.js";
import moment from 'moment';

export default {
  components: {
    rForm,
    rAutocomplete,
    rInput,
    rFiles
  },
  data: function() {
    return {
      PersonalCualificado,
      Cualificaciones,
      Empleado,
      Materia,
      Documento,
      readonly: !this.editable,
      moment,
      countInspecciones: undefined
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    loadedItem: function(item) {
      const self = this;
      self.loadCountInspecciones(item);
    },  
    beforeSave: function(data, resolve, reject) {
      var o = Object.assign({}, data);
      if (o.documentos && o.documentos.length) {
        o.documentos.forEach(d => {
          d.path = 'PersonalCualificado';
        });
      }
      resolve(o);
    },
    loadCountInspecciones(item) {
      const self = this;
      if (!item && self.$refs.rform) {
        item = self.$refs.rform.formData;
      }
      if (item && item.empleado && item.empleado.username && item.materia && item.materia.codigo) {
        PersonalCualificado.countInspecciones({username: item.empleado.username, codMateria: item.materia.codigo})
        .then(res => {
          console.log("RESULT COUNT", res);
          self.countInspecciones = res.valorLong;
        })
      }
    },
    sumarFecha: function(origen, n, tipo, destino) {
      const self = this;
      if (self.$refs.rform.formData[origen]) {
        let sumada = self.moment(self.$refs.rform.formData[origen]).add(n, tipo);
        self.$set(self.$refs.rform.formData, destino, sumada.toDate());
      } else {
        self.$set(self.$refs.rform.formData, destino, "");
      }
    },
    deleteItem: function() {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Cualificaciones.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      const self = this;
      const app = self.$f7;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: 'Cualificación guardada correctamente',
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
};
</script>