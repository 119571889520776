<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('documentos.documentos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="DocumentoGestion"
        sort="creationDate"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        filtersName="documentos"
        @load="loadList"
        :searchOnInit="false"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <div class="data-table-links">
                <a class="link" href="/documentos/new/">{{$t('documentos.nuevo')}}</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.reload()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Documentos.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('nombre')"
                :class="rlist.sortableClasses('nombre')"
              >{{$t('documentos.nombre')}}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item, i) in vlData.items" :key="'combo-'+i">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td>{{item.path}}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="eye" color="green" :href="`/documentos/view/${getPathId(item)}/`"></f7-link>
              <f7-link icon-f7="square_pencil" color="blue" :href="`/documentos/edit/${getPathId(item)}/`"></f7-link>
              <f7-link icon-f7="trash" color="red" @click="deleteItem(item)"></f7-link>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form 
              @submit="rlist.setFilter($event); rpanel.close();"
              filtersName="documentos"  
            >
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      class="col-25"
                      floating-label
                      :label="$t('documentos.nombre')"
                      :form="rform"
                      name="nombre"
                    ></r-input>
                    <r-autocomplete
                      ref="comboTiposDocumento"
                      class="col-25"
                      floating-label
                      :label="$t('documentos.tiposDocumento')"
                      :form="rform"
                      :dbItems="tiposDocumento"
                      name="tipoDocumento"
                      labelProp="valor"
                      idProp="valor"
                    ></r-autocomplete>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">{{$t('common.buscar')}}</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Combos from "./../../js/db/Combos.js";
import Documento from "./../../js/db/Documento.js";
import DocumentoGestion from "./../../js/db/DocumentoGestion.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Documento,
      DocumentoGestion,
      rPanelOpenedByBreakpoint: false,
      listado: [],
      tiposDocumento: []
    };
  },
  methods: {
    loadList: function(){
      var self = this;
      self.listado = self.$refs.rlist.vlData.items;
    },
    getPathId: function(item){
      return encodeURIComponent(item.path);
    },
    deleteItem: function(documento) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        self.$t("common.eliminar"),
        function() {
          Documento.delete(documento).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              // self.$f7router.back()
              self.refresh();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    }
  },
  created: function() {
    var self = this;
    Combos.getComboById("tiposPlantilla").then(res => {
      self.tiposDocumento = res.list;
      self.$refs.comboTiposDocumento.reload(self.tiposDocumento);
    })
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>