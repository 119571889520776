<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-title sliding>Panel de control</f7-nav-title>
      <f7-nav-right>
        <f7-link 
          icon-f7="wand_stars" class="pointer" 
          v-tooltip="'Limpiar datos sincronizados'" 
          @click="limpiarDatos()"
        ></f7-link>
        <f7-link 
          icon-f7="arrow_down_doc" class="pointer" 
          v-tooltip="'Descargar Expediente'" 
          @click="descargarExpediente()"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <f7-row class="width-100" style="max-width:800px;margin:auto;">
        <template v-if="isSync()">
          <a class="col-100 medium-50" href="/inspecciones-offline/">
            <f7-card class="card-button">
              <f7-card-content>
                <f7-icon f7="doc_fill"></f7-icon>
                <f7-icon f7="plus" style="color:white; font-size:42px; bottom:0px; right: 70px;"></f7-icon>
              </f7-card-content>
              <f7-card-footer class="bg-color-primary text-color-white">Nuevo expediente</f7-card-footer>
            </f7-card>
          </a>
          <a class="col-100 medium-50" href="/inspecciones-offline-anteriores/">
            <f7-card class="card-button">
              <f7-card-content>
                <f7-icon f7="doc_text_search_fill"></f7-icon>
              </f7-card-content>
              <f7-card-footer class="bg-color-primary text-color-white">Expedientes anteriores</f7-card-footer>
            </f7-card>
          </a>
          <!-- <a class="col-100 medium-50" href="/albaranes-offline/">
            <f7-card class="card-button">
              <f7-card-content>
                <f7-icon f7="doc"></f7-icon>
                <f7-icon f7="signature" style="font-size:50px; bottom:10px; right: 50px;"></f7-icon>
              </f7-card-content>
              <f7-card-footer class="bg-color-primary text-color-white">Albaranes</f7-card-footer>
            </f7-card>
          </a> -->
        </template>
        <a class="col-100 medium-50" @click="sync()">
          <f7-card class="card-button">
            <f7-card-content>
              <f7-icon f7="cloud_download"></f7-icon>
            </f7-card-content>
            <f7-card-footer class="bg-color-primary text-color-white">Sincronizar</f7-card-footer>
          </f7-card>
        </a>
        <a class="col-100 medium-50" href="/calendario/">
          <f7-card class="card-button">
            <f7-card-content>
              <f7-icon f7="calendar"></f7-icon>
            </f7-card-content>
            <f7-card-footer class="bg-color-primary text-color-white">Calendario</f7-card-footer>
          </f7-card>
        </a>
      </f7-row>
      <f7-card v-for="(item,index) in list" :key="'lista-'+index">
        <f7-card-content>
          <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
          <i class="f7-icons" :class="'text-color-' + item.color">{{item.imagen}}</i>
          {{item.nombre}}:
          <span :class="'badge color-' + item.color ">{{item.valor}}</span>
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
.badge {
  top: -2px;
}
.card-button .card-content {
  padding-top: 100px;
  overflow: hidden;
}
.card-button .icon {
  position: absolute;
  right: 10px;
  bottom: -10px;
  font-size: 120px;
  transform: rotate(7deg);
}
</style>
<script>
import rList from "./../components/rList.vue";
import InspeccionOffline from "./../js/db/InspeccionOffline.js";
import DatabaseModelOffline from "./../js/db/DatabaseModelOffline.js";
import Inspeccion from "./../js/db/Inspeccion.js";
import Usuario from "./../js/db/Usuario.js";
import Configuracion from '../js/db/ConfiguracionOffline';
import moment from 'moment';
import Utils from './../js/Utils';

export default {
  components: {},
  data() {
    return {
      list: [],
      config: {},
      Configuracion,
      moment,
      Inspeccion,
      InspeccionOffline,
      DatabaseModelOffline,
      Utils
    };
  },
  mounted: function() {
    const self = this;
    const app = self.$f7;
    self._updateConfig();
  },
  methods: {
    _updateConfig() {
      const self = this;
      Configuracion.get({itemId: "CONFIG"})
        .then(result => {
          self.config = result;
        }).catch(_ => self.config = null);
    },
    isSync: function() {
      var self = this;
      return (self.config && self.config.syncDate && self.config.dispositivo && self.moment().diff(self.config.syncDate,'days') < 1);
    },
    limpiarDatos: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "Se va a realizar la limpieza de datos sincronizados, esto no debería afectar a sus inspecciones editadas o creadas. ¿Desea continuar?",
        "Limpieza de datos sincronizados",
        function() {
          app.preloader.show();
          DatabaseModelOffline.cleanData()
            .then(function() {
              self._updateConfig();
              app.preloader.hide();
              app.dialog.alert("Datos limpiados correctamente", "Confirmación").open();
            })
            .catch(function(e) {
              app.dialog.alert(e.error && e.error.message ? e.error.message : e.error, "Error").open();
            })
            .then(function() {
              app.preloader.hide();
            });
        }
      );
      
    },
    sync: function() {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      app.methods.onTriggerLocalNotification();
      DatabaseModelOffline.sync()
        .then(function() {
          app.preloader.hide();
          self._updateConfig();
          app.toast
            .create({
              text: "Datos sincronizados con éxito",
              position: "center",
              closeTimeout: 2000
            })
            .open();
        })
        .catch(function(e) {
          console.log(e);
          if (e.error == "invalid_token") {
            app.dialog
              .login(
                "Inicia sesión para sincronizar",
                "Token inválido",
                function(username, password) {
                  app.preloader.show();
                  Usuario.auth({ username, password })
                    .then(function(res) {
                      app.preloader.hide();
                      app.methods.saveSession({
                        token: res.access_token
                      });
                      app.methods.startSession();
                    })
                    .catch(function(e) {
                      app.preloader.hide();
                      app.toast
                        .create({
                          text: e.error_description,
                          position: "center",
                          closeTimeout: 2000
                        })
                        .open();
                    });
                }
              )
              .open();
          } else {
            app.dialog.alert(e.error && e.error.message ? e.error.message : e.error, "Error").open();
          }
        })
        .then(function() {
          app.preloader.hide();
        });
    },
    descargarExpediente: function () {
      var self = this;
      var app = self.$f7;
      app.dialog.prompt(
        "Introduzca el código del expediente que desea descargar",
        "Descargar expediente",
        function(codigo) {
          Inspeccion.getByCodigo(codigo).then((exp) => {
            console.log(exp)
            var expediente = exp;
            const self = this;
            var params = {};
            params.data = expediente;
            InspeccionOffline.save(params);
            app.dialog.confirm(
                "¿Desea abrir el expediente "+ expediente.codigo + "?",
                function() {
                  app.views.main.router.navigate("/inspecciones/edit-offline/" + expediente.id+"/");
                }
              );
            }).catch(function(e){
              if (typeof e == "string") e = { error: e };
              let error = e.message || e.error || e.error_description;
              app.dialog.alert(error);
              console.log(error);
            });
        }
      );

    }
  }
};
</script>