import Empresas from './empresas.vue';
import EmpresaForm from './empresa-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/empresas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresas', ['ADMIN', 'INSPECTOR'], Empresas),
        keepAlive: true
    },
    {
        path: '/empresas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresas/new', ['ADMIN', 'INSPECTOR'], EmpresaForm)
    },
    {
        path: '/empresas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresas/edit', ['ADMIN', 'INSPECTOR'], EmpresaForm)
    },
    {
        path: '/empresas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empresas/view', ['ADMIN', 'INSPECTOR'], EmpresaForm, { editable: false })
    },
];
export default routes;