<template>
  <div>
    <v-medium-editor ref="mediumeditor" class="" @change="changed()" :prefill="processedContent" :options="options" />
	<vue-simple-context-menu
	  :elementId="'vueSimpleContextMenu'"
	  :options="contextMenu_options"
	  :ref="'vueSimpleContextMenu'"
	  @option-clicked="optionClicked"
	/>


	<!-- popup de edición de propiedades del elemento seleccionado -->
	<f7-popup ref="popupEdicion" class="popupedicion" v-if="popupEdicionShow" @popup:close="popupEdicionShow = false" :opened="true" swipeToClose>
		<f7-page>
			<f7-navbar>
			<f7-nav-left>
				<f7-link @click="applyChanges">Guardar</f7-link>
			</f7-nav-left>
			<f7-nav-title>Editar</f7-nav-title>
			<f7-nav-right>
				<f7-link popup-close>Cerrar</f7-link>
			</f7-nav-right>
			</f7-navbar>
			<!-- vamos a poner aquí el formulario de edición de propiedades del objeto seleccionado sacado de objetoActual -->
			<f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
				<f7-card-content>
					<f7-list strong-ios dividers-ios inset-ios>
						<div class="popup-title">Estilos</div>
						<f7-row>
							<f7-col width="50" v-for="(style, index) in objetoActual.properties.style" :key="'style-' + index">
								<div @contextmenu.prevent="showOptions(style, $event)">
									<f7-list-input 
										outline
										:label="index" 
										floating-label 
										type="text" 
										:placeholder="index" 
										clear-button
										@change="style.valor = $event.target.value"
										:value="style.valor"
									></f7-list-input>
								</div>
							</f7-col>
						</f7-row>
					</f7-list>
				</f7-card-content>
			</f7-card>
			<!-- vamos a poner aquí el formulario de edición de atributos del objeto seleccionado sacado de objetoActual -->
			<f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
				<f7-card-content>
					<f7-list strong-ios dividers-ios inset-ios>
						<div class="popup-title">Propiedades</div>
						<f7-row>
							<f7-col width="50" v-for="(attribute, index) in objetoActual.properties.attributes" :key="'attribute-' + index">
								<div @contextmenu.prevent="showOptions(attribute, $event)">
									<f7-list-input 
										outline
										:label="index" 
										floating-label 
										type="text" 
										:placeholder="index" 
										clear-button
										@change="attribute.valor = $event.target.value"
										@contextmenu.prevent="showOptions(attribute, $event)"
										:value="attribute.valor"
									></f7-list-input>
								</div>
							</f7-col>
						</f7-row>
					</f7-list>
				</f7-card-content>
		  	</f7-card>
			<!-- vamos a poner aquí el formulario de edición de atributos del objeto seleccionado sacado de objetoActual -->
			<f7-card v-if="objetoActual.properties.innerHTML" class="padding-vertical-half padding-right" style="z-index: 3000">
				<f7-card-content>
					<f7-list strong-ios dividers-ios inset-ios>
						<div class="popup-title">Contenido dinámico</div>
						<div style="margin-top: 5px; margin-left: 15px;" @contextmenu.prevent="showOptions(objetoActual.properties.innerHTML, $event)">
							<vue-editor
								ref="textareaContenidoDinamico"
								:placeholder="'Contenido dinámico'" 
								v-model="objetoActual.properties.innerHTML.valor"
								:editor-toolbar="customToolbar"
							></vue-editor>
							
						</div>
					</f7-list>
				</f7-card-content>
			</f7-card>
			<f7-popover 
				:closeByBackdropClick="false" 
				:backdrop="true" 
				closeOnEscape
				ref="popover"
			>
				<f7-navbar :title="$t('Selección de campos del elemento')">
					<f7-nav-right>
						<f7-link popover-close icon-f7="multiply"></f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-list no-hairlines-md>
					<select @change="setOptionSelected">
						<option>Seleccione un campo...</option>
						<option v-for="(opcion,index) in opciones" :key="'select.opcion-'+index" :value="opcion.content">{{ opcion.name }}</option>
					</select>
				</f7-list>
			</f7-popover>
		</f7-page>
		<!-- <div v-if="opciones">
			<select>
				<option></option>
				<option v-for="(opcion,index) in opciones" :key="'select.opcion-'+index" value="opcion.content">{{ opcion.name }}</option>
			</select>
		</div> -->
	</f7-popup>

  </div>
</template>
<style scoped>
	@import "~medium-editor/dist/css/medium-editor.css";
	@import "~vuejs-medium-editor/src/themes/default.css";
	@import "~font-awesome/css/font-awesome.css";
	/* @import "/path/to/node_modules/vue-simple-context-menu.css"; */
	/* @import "~medium-editor/dist/css/themes/bootstrap.css"; */
	@import '~highlight.js/styles/github.css';

	.vue-simple-context-menu__item {
		padding: 2px 0 2px 5px;
		color: #fff;
		cursor: pointer;
	}
</style>
<style>
	#vueSimpleContextMenu {
		position: fixed;
		background-color: #d4ceba !important;
		/* background-color: #23355b !important; */
		border: 1px solid #000000;
		color:#ffffff;
		border-radius: 0;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	}
	.medium-editor-toolbar {
	z-index: 50000;
	}
	.medium-editor-toolbar-form select{
	background-color: black;
	border: solid 2px white;
	}
	.medium-editor-toolbar-form .medium-editor-toolbar-input:focus, .medium-editor-toolbar-form label:focus {
		-webkit-appearance: auto !important; 
		-moz-appearance: auto !important;
		appearance: auto !important;
	}
	.medium-editor-toolbar-form input {
		border: 1px solid white;
		color: white;
		margin-left: 2px;
		border-radius: 5px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.valor,valor,caracteristica,caracteristicalistado,table.medium-editor-table {
		border: 1px dashed yellowgreen;
	}

	.valor::before,valor::before,caracteristica::before,caracteristicalistado::before {
		color: #bcbebc;
		content: '~';
	}
	table.medium-editor-table::before {
		color: #bcbebc;
		content: '~';
		position: absolute;
		left: 1.25cm;
		cursor: pointer;
		border: 1px solid #bcbebc;
	}
	.valor::after,valor::after,caracteristica::after,caracteristicalistado::after {
		color: #bcbebc;
		content: '~';
	}

	.popup-title {
		margin-left: 20px;
		font-weight: bold;
	}
</style>
<script>

import VMediumEditor from 'vuejs-medium-editor'
import MediumEditor from 'medium-editor';
import Caracteristica from './extensions/Caracteristica'
import CaracteristicaListado from './extensions/CaracteristicaListado'
import Checkbox from './extensions/Checkbox'
import Watermark from './extensions/Watermark'
import Provisional from './extensions/Provisional'
import Cabecera from './extensions/Cabecera'
import Pie from './extensions/Pie'
import MediumEditorEnhacedTable from './extensions/MediumEditorEnhacedTable'
import ColorPicker from './extensions/Colorpicker'
import FontSize2 from './extensions/FontSize2'
import Bloque from './extensions/Bloque'
import ValorInspeccion from './extensions/ValorInspeccion'
import ValorEntidad from './extensions/ValorEntidad'
import SaltoPagina from './extensions/SaltoPagina'
import Combos from '../../js/db/Combos'
import Inspeccion from '../../js/db/Inspeccion'
import LineaPedido from '../../js/db/LineaPedido.js'
import Oferta from '../../js/db/Oferta.js'
import Oportunidad from '../../js/db/Oportunidad.js'
import VueEditor from "../vue2-editor/VueEditor.vue"

import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import Tarea from '../../js/db/Tarea';

export default {
  components: {
    VMediumEditor,
	VueSimpleContextMenu,
	VueEditor
  },
  data() {
    return {
	  self: this,
	  item: null,
	  nPaginas: 1,
	  bloques: {b1:[],b2:[]},
	  htmlData: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
\${head}
\${body}
</html>`,
	  headMail: `<head>
<meta content="text/html;charset=utf-8" http-equiv="content-type" />
<style type="text/css">
body {
	margin: 0;
	font-size: 80%;
}
#page-container {
	width: auto;
	height: auto;
	overflow: visible;
 	background-color: rgb(82, 86, 89);
	position: relative;
	margin: 0;
	border: 0;	
	font-family: sans-serif;
	letter-spacing: -0.6px;
	padding: 0;
}
.pf {
	position: relative;
	background-image: linear-gradient(0deg, white 49.95%, grey 49.95%, grey 50%, white 50%, white 99.95%, grey 99.95%, grey 100%);
	background-size: 1790px 1790px;
	background-repeat: repeat;
	border: 0;
	margin: 0 auto;
	box-shadow: 1px 1px 3px 1px #333;
	border-collapse: collapse;
	width:21.1cm;
	min-height:23.7cm;
	padding: 1cm 1.5cm;
	z-index: 10000;
	background-color: white;
}
</style>
</head>`,
	  head: `<head>
<meta content="text/html;charset=utf-8" http-equiv="content-type" />
<title>FICHERO</title>
<style type="text/css">
@charset "UTF-8";
@page {
	/* portrait|landscape */
	size: A4 portrait;
	/* set page margins superior derecho inferior izquierdo*/
	/*PAGE-MARGIN-START*/margin: 4.5cm 1.5cm 1.5cm 1.5cm;/*PAGE-MARGIN-END*/
	/* Default header */
	@top-center{ content: element(pageHeader); }
	/* Default footers */
	@bottom-center{ content: element(pageFooter); }
}
@page watermarked{
	background: url('${process.env.SERVER_UPLOADS_DIR}/static/img/watermark.png') no-repeat center;
	background-size: 21.5cm 30cm;
}
@page provisional{
	background: url('${process.env.SERVER_UPLOADS_DIR}/static/img/provisional.png') no-repeat center;
	background-size: 21.5cm 30cm;
}
@page provisionalWatermarked{
	background: url('${process.env.SERVER_UPLOADS_DIR}/static/img/provisional-watermark.png') no-repeat center;
	background-size: 21.5cm 30cm;
}
@page borrador{
	background: url('${process.env.SERVER_UPLOADS_DIR}/static/img/borrador.png') no-repeat center;
	background-size: 21.5cm 30cm;
}
@page borradorWatermarked{
	background: url('${process.env.SERVER_UPLOADS_DIR}/static/img/borrador-watermark.png') no-repeat center;
	background-size: 21.5cm 30cm;
}

body {
	margin: 0;
	font-size: 80%;
	-webkit-print-color-adjust: exact;
}
body.watermarked{
	page: watermarked;
}
body.provisional{
	page: provisional;
}
body.watermarked.provisional{
	page: provisionalWatermarked;
}
body.borrador{
	page: borrador;
}
body.watermarked.borrador{
	page: borradorWatermarked;
}
.medium-editor-container {
	background: rgb(82, 86, 89);
	padding-top: 50px !important;
}
#editor-container {
	padding-top: 1.5cm;
}
#pageHeader{ padding-top: 1.5cm; width:100%; position:relative; }
#pageFooter:before {
	content: "Pag. " counter(page) " de " counter(pages);
	position: absolute;
	bottom: 0.5cm;
	left: 0;
	font-size: 8px;
}
#pageFooter:after {
	content: /*INICIO-NOMBRE-FICHERO*//*FIN-NOMBRE-FICHERO*/;
	position: absolute;
	bottom: 0.5cm;
	right: 0;
	font-size: 8px;
}
#pageFooter:before, #pageFooter:after{ display:none;}
#pageFooter.no-footer{
	display:none;
}

#header-container {
	width: auto;
	height: auto;
	overflow: visible;
	position: relative;
	margin: 0;
	padding: 1.5cm 0 0;
	border: 0;
	font-family: sans-serif;
	letter-spacing: -0.6px;
}
#header-container.no-header {
  display:none;
}

.hf {
	position: relative;
    background-size: 43.2px;
	background-color: white;
	overflow: hidden;
	border: 0;
	margin: 0 auto;
	padding: 0 1.5cm; 
	box-shadow: 1px 1px 3px 1px #333;
	border-collapse: collapse;
	width:21.1cm;
}

#page-container {
	width: auto;
	height: auto;
	overflow: visible;
	position: relative;
	margin: 0;
	border: 0;	
	font-family: sans-serif;
	letter-spacing: -0.6px;
	padding: 0;
}
#header-container.no-header + #page-container {
	top: 0;
	padding-top: 1.5cm;
}


.pf {
	position: relative;
	background-image: linear-gradient(0deg, white 49.95%, grey 49.95%, grey 50%, white 50%, white 99.95%, grey 99.95%, grey 100%);
	background-size: 1790px 1790px;
	background-repeat: repeat;
	border: 0;
	margin: 0 auto;
	box-shadow: 1px 1px 3px 1px #333;
	border-collapse: collapse;
	width:21.1cm;
	min-height:23.7cm;
	padding: 1cm 1.5cm;
	z-index: 10000;
}
.pf.watermarked {
	background: url('static/img/watermark.png') no-repeat center 5cm / 47em, linear-gradient(0deg, white 49.95%, grey 49.95%, grey 50%, white 50%, white 99.95%, grey 99.95%, grey 100%) top /1790px 1790px;
}
#pageFooter {
	position: relative;
	background: white;
	border: 0;
	margin: 0 auto;
	box-shadow: 1px 1px 3px 1px #333;
	width:21.1cm;
	padding: 0.5cm 1.5cm 1cm;
}


.encabezado {
	margin: 0; 
 	padding: 0; 
	text-align: center;
	position: relative;
}
.encabezado.encabezado-2 {
    margin: 1em 0;
}
.encabezado-ENAC .logo-ENAC {
	position: absolute;
	right: 0;
	top: 0;
}
.encabezado-ENAC .logo-ENAC.logo-ENAC-2 {
    top: -2em;
}
.encabezado-ENAC .fs7.num-cenac {
	position: relative;
	bottom: 1.6em;
	text-align: center;
	font-size: 11px !important;
}

.cod-expediente {
    text-align: right;
}
.cod-expediente span.code {
    display: inline-block;
    width: 180px;
    border: 1px solid black;
    padding: 0.1em 0.5em;
    margin-left: 1em
}

div.cuerpo {
}

p {margin-left: 0; text-align: justify;}
table {border-collapse: collapse;}

tbody tr td {
	border: 0;
	margin: 3px;
}

thead tr th.cabecera1, thead tr th.cabecera1_left {
	vertical-align: middle;
	text-align: center;
	padding: 10px;
}

thead tr th.cabecera1_left {
	text-align: left;
}

thead.cabecera2 tr {
	padding: 10px;
}

thead.cabecera2 tr th {
	vertical-align: middle;
	text-align: left;
	border: 0;
}

.titulo {margin-bottom: 1em;}

.tab-1 {display:inline-block; width: 2.5em;}
.tab-2 {display:inline-block; width: 5em;}
.medium-editor-toolbar-colorpicker {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid white;
	margin: -2px;
}
.medium-editor-container * {
	font-size: 0.8rem;
}
.medium-editor-table.medium-editor-table-selected >*>*> td{
	border-color: goldenrod !important;
}
.medium-editor-table {
	page-break-inside: avoid;
}
.medium-editor-table, .medium-editor-table td p{
	margin: 0;
	padding: 0;
}
.medium-editor-table.show-borders >*>*>td {
  border: 1px solid black;
}
.medium-editor-table:not(.show-borders) >*>*>td {
  border: 1px dashed #e3e3e3;
}
.medium-editor-table:not(.show-borders), .medium-editor-table:not(.show-borders)&gt;tbody&gt;tr&gt;td, .medium-editor-table:not(.show-borders)&gt;thead&gt;tr&gt;td {
  /* border: 1px dashed #e3e3e3; */
}
img {
    max-width: 100%;
}
table thead.workaround-cell-size tr, table thead.workaround-cell-size tr td {
    height: 0px !important;
    line-height: 0;
    padding: 0;
    margin: 0;
	border: 0;
	visibility: hidden;
}
table.medium-editor-table tr {height:1px;}
table.medium-editor-table tr td {height:inherit;}
table tbody td .cell-content {
	background: rgba(255,0,0,0.5) !important;
	height: 100%;
}

.custom-fc { font-size: inherit; }
.custom-fs.fs-xxs{ font-size: 8px !important; }
.custom-fs.fs-xs{ font-size: 10px !important; }
.custom-fs.fs-s{ font-size: 12px !important; }
.custom-fs.fs-n{ font-size: 14px !important; }
.custom-fs.fs-l{ font-size: 16px !important; }
.custom-fs.fs-xl{ font-size: 18px !important; }
.custom-fs.fs-xxl{ font-size: 20px !important; }

.firma{
    display: block;
    width: 225px;
    height: 90px;
    font-size: 1pt !important;
    color: white;
    border: 1px solid grey;
}
.salto-pagina {
	display: block;
}

img[src*="cid:"]{
  max-width: 100%;
  max-height: 100%;
  display: block;
}

@media print {
	#pageHeader{ position: running(pageHeader); height: auto; padding-top: 0.5cm;}
	#pageFooter{ position: running(pageFooter); width:auto; padding:0;}
	#pageFooter.no-footer{display:block;}
	.noPDF{display:none;}
	#pageFooter:before, #pageFooter:after{ display:block;}
	#header-container {background: none; position: absolute; top:0;}
	#page-container {top: 0;background: none !important; padding:0 !important; position: absolute;}
	.encabezado-ENAC .fs7.num-cenac {bottom:11pt;}
	.cod-expediente span.code {width: 120pt;}
	.pf{background:none !important;width:18cm; min-height:0; height:auto; margin: 0 auto;top:0;padding: 0;}
	.hf{background:none !important;}
    div.cuerpo {margin: 0;}
	.titulo {margin-bottom: 1em;}
	p{padding-left:0;margin-left:0}
	.medium-editor-table td.without-border {border: 0 !important;}
	.medium-editor-table td.with-border {border: 1px solid black !important;}
	.firma{border:0}
	.salto-pagina{
		visibility: hidden;
        clear: both;
        page-break-after: always;
    }
	caracteristica span {
        page-break-inside: auto;
    }
    caracteristica div.linea {
        page-break-inside: avoid;
		top: 0 !important;
    }
}
.col2tac .medium-editor-table tr td:nth-child(2){
    text-align:center;
}


</style>
</head>`,

headOferta: `<head>
    <meta content="text/html;charset=utf-8" http-equiv="content-type" />
    <title>MODELO DE OFERTA CORTA V3</title>
    <meta content="nombre largo" name="Oferta modelo resumido V3" />
    <meta content="estado" name="PRELIMINAR" />
    <meta content="autor" name="JAC" />

    <style type="text/css">
        @charset "UTF-8";
        @page {
            /* portrait|landscape */
            size: A4 portrait;
            /* set page margins superior derecho inferior izquierdo*/
            margin: 3.5cm 0cm 1.3cm 0cm;
            @top-center {
                content: element( pageHeader);
            }
            /* @footnote {
                float: bottom;
            } */
            @bottom-left {
                content: counter(page);
                /* " de " counter(pages);*/
                font-size: 20pt;
                padding-left: 10pt;
                /*font-family: sans-serif;*/
                margin-left: 0.8cm;
                color: rgb(35, 53, 91);
            }
            @bottom-center {
                content: "De Conformidad con el Art. 7 del Reglamento (UE) 2016/679  de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos. Vd. Puede ejercer sus derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal, enviando una comunicación a la dirección de correo electrónico simecal@simecal.com o bien dirigiendo una solicitud escrita a C/ Manuel Azaña, 39 bajo 47014 Valladolid, acompañando fotocopia de D.N.I.";
                font-size: 6pt;
                font-family: sans-serif;
                color: gray;
            }
            @left-middle {
                content: "......";
                color: white;
                background: white url('templates/OFERTAS/static/CIFVertical.png') no-repeat center right;
                background-size: 0.4cm auto;
            }
        }
        
        .ql-indent-1 {
            padding-left: 3em;
        }
        
        .ql-indent-2 {
            padding-left: 6em;
        }
        
        .ql-indent-3 {
            padding-left: 9em;
        }
        
        .ql-indent-4 {
            padding-left: 12em;
        }
        
        .ql-indent-5 {
            padding-left: 15em;
        }
        
        .ql-indent-6 {
            padding-left: 18em;
        }
        
        .ql-indent-7 {
            padding-left: 21em;
        }
        
        .ql-indent-8 {
            padding-left: 24em;
        }
        
        .ql-size-small {
            font-size: 0.75em;
        }
        
        .ql-size-large {
            font-size: 1.5em;
        }
        
        .ql-size-huge {
            font-size: 2.5em;
        }
        
        body {
            margin: 0;
            font-size: 100%;
            font-family: sans-serif;
            letter-spacing: -0.3pt;
            -webkit-print-color-adjust: exact;
        }
        
        p {
            margin: 0;
        }
        
        .medium-editor-container {
            font-size: 0.8rem;
        }
        #editor-container {
            padding-top: 1.5cm;
        }
        /* #pageHeader {
            height: 3.4cm;
            padding: 0cm 0cm 0 0cm;
        } */
        #pageHeader{ padding-top: 1.5cm; width:100%; position:relative; }

        #pageFooter:before {
            content: "Pag. " counter(page) " de " counter(pages);
            position: absolute;
            bottom: 0.5cm;
            left: 0;
            font-size: 8px;
        }
        #pageFooter:after {
            content: /*INICIO-NOMBRE-FICHERO*/"F.CYL.02.07.PI-AE.10"/*FIN-NOMBRE-FICHERO*/;
            position: absolute;
            bottom: 0.5cm;
            right: 0;
            font-size: 8px;
        }
        #pageFooter:before, #pageFooter:after{ display:none;}
        #pageFooter.no-footer{
            display:none;
        }
        
        #header-container {
            width: auto;
            height: auto;
            overflow: visible;
            background-color: #525659;
            position: relative;
            margin: 0;
	        padding: 1.5cm 0 0;
            border: 0;
            font-family: sans-serif;
            letter-spacing: -0.6px;
        }
        #header-container.no-header {
        display:none;
        }
        
        /* .hf {
            position: relative;
            overflow: hidden;
            border: 0;
            page-break-after: always;
            page-break-inside: avoid;
            margin: 1.5cm auto 0 auto;
            height: 3.4cm;
            background-color: white;
            box-shadow: 1pt 1pt 3pt 0pt #333;
            border-collapse: collapse;
            width: 21cm;
        } */
        .hf {
            position: relative;
            background-color: white;
            overflow: hidden;
            border: 0;
            margin: 0 auto;
            padding: 0 auto; 
            box-shadow: 1px 1px 3px 1px #333;
            border-collapse: collapse;
            width:21.1cm;
        }
        
        #page-container {
            width: auto;
            height: auto;
            top: 4.55cm;
            right: 0;
            left: 0;
            overflow: visible;
            margin: 1mm 0;
            padding: 0;
            border: 0;
            font-family: sans-serif;
            letter-spacing: -0.6px;
        }
        
        .pf {
            position: relative;
            border: 0;
            page-break-after: always;
            page-break-inside: avoid;
            margin: 0 auto;
            box-shadow: 1px 1px 3px 1px #333;
            border-collapse: collapse;
            width: 21cm;
            height: 26.6cm;
        }
        
        p.texto-cabecera {
            color: black;
            font-weight: bold;
            text-align: center;
            font-size: 10pt;
        }
        
        div.page-oferta {
            margin: 0 7mm 8mm 7mm;
        }
        
        div.page2 {
            margin: 0 8mm 15mm 8mm;
        }
        
        div.bloque, div.bloque-servicios {
            border-left: solid #387026 1.5pt;
            background-repeat: repeat-y;
            margin-top: 5pt;
            padding-left: 10pt;
        }
        
        div.bloque.bloque1, div.bloque-servicios.bloque1 {
            margin-top: -2pt;
        }
        
        div.bloque.otrosBloques, div.bloque-servicios.otrosBloques {
            margin-top: 5pt;
        }
		
        
        span.nota_al_pie {
            float: footnote;
        }
        
        table.cell-border-05 {
            border-collapse: collapse;
            margin: 3pt;
        }
        
        table.borer-gray {
            border-color: gray;
        }
        
        table.cell-border-05 td,
        table.cell-border-05 th,
        td.cell-border-05,
        th.cell-border-05 {
            border: 0.5pt solid black;
        }
        
        td.cell-border-right-15,
        th.cell-border-right-15 {
            border-right: 1.5pt;
        }
        
        th.cell-border-05 {
            text-align: center;
            font-weight: bold;
        }
        
        .right {
            text-align: right;
        }
        
        .left {
            text-align: left;
        }
        
        .border-0 {
            border: 0;
        }
        
        .titulo1 {
            color: #1f3864;
            font-family: TimesNewRoman;
            font-size: 10pt;
            font-style: italic;
            font-weight: bold;
            text-transform: uppercase;
        }
        
        .timesNewRoman {
            font-family: TimesNewRoman;
        }
        
        div.titulo1.subtitulo {
            margin-top: 5pt;
        }
        
        .titulo2 {
            color: #385f0b;
            font-family: Calibri;
            font-size: 9pt;
            font-weight: bold;
        }
        
        div.lineaverde {
            color: #387035;
            font-family: TimesNewRoman;
            font-size: 9.5pt;
            font-style: italic;
            font-weight: bold;
            border-bottom: solid #A5C249 0.5pt;
            display: inline;
            left: -10pt;
            padding-left: 11pt;
            position: relative;
            letter-spacing: 0.1pt;
        }
        
        img.lineaverde {
            position: relative;
            top: 16pt;
            left: -25pt;
        }
        
        div.doscolumnas {
            width: 49.6%;
            display: table-cell;
            text-align: justify;
        }
        
        .justify {
            text-align: justify;
        }
        
        div.doscolumnas p,
        div.doscolumnas ol,
        div.doscolumnas ol li {
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0pt;
        }
        
        div.sinespacios p {
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0pt;
            padding-inline-end: 0pt;
            margin: 0;
            padding: 0;
        }
        
        div.sinespacios {
            margin: 0;
            padding: 0;
        }
        
        .calibri {
            font-family: Calibri;
        }
        
        .bold {
            font-weight: bold;
        }
        
        .pd1 {
            padding: 1pt;
        }
        
        .pd2 {
            padding: 2pt;
        }
        
        .pd3 {
            padding: 3pt;
        }
        
        .pd4 {
            padding: 4pt;
        }
        
        .pd5 {
            padding: 5pt;
        }
        
        .pd6 {
            padding: 6pt;
        }
        
        .pd7 {
            padding: 7pt;
        }
        
        .pd8 {
            padding: 8pt;
        }
        
        .pd9 {
            padding: 9pt;
        }
        
        .letsp-02 {
            letter-spacing: -0.2pt;
        }
        
        .letsp-01 {
            letter-spacing: -0.1pt;
        }
        
        .center {
            text-align: center;
        }
        
        .fs12pt {
            font-size: 12pt;
        }
        
        .fs11pt {
            font-size: 11pt;
        }
        
        .fs10pt {
            font-size: 10pt;
        }
        
        .fs9pt {
            font-size: 9pt;
        }
        
        .fs8pt {
            font-size: 8pt;
        }
        
        .fs85pt {
            font-size: 8.5pt;
        }
        
        .fs7pt {
            font-size: 7pt;
        }
        
        .fs6pt {
            font-size: 6pt;
        }
        
        .fs5pt {
            font-size: 4.6pt;
        }
        /* 		ol {list-style: none; } */
        
        ol {
            list-style: none;
            padding-inline-start: 0px;
            list-style: none;
        }
        
        p.html ol,
        p.html p {
            margin-block-start: 0;
            margin-block-end: 0;
        }
        
        .firmacliente {
            /* border: solid 1pt; */
        }
        
        ol.dl {
            list-style-type: lower-latin;
        }
        
        ol.dn {
            list-style-type: decimal;
        }
        
        ol.dr {
            list-style-type: lower-roman;
        }
        
        ol.il {
            list-style-position: inside;
            list-style-type: lower-latin;
        }
        
        ol.in {
            list-style-position: inside;
            list-style-type: decimal;
        }
        
        ol.ir {
            list-style-position: inside;
            list-style-type: lower-roman;
        }
        
        .nota_al_pie {
            float: footnote;
        }
        
        span.resaltado {
            margin-left: -5pt;
            position: relative;
            float: left;
        }
        
        div.campo-iban {
            border: 0.5pt solid black;
            width: 50%;
            vertical-align: middle;
        }
        
        div.etiqueta {
            background-color: gray;
            color: white;
            display: inline-block;
            width: 30pt;
            padding: 4px;
            text-align: center;
        }
        
        div.valor {
            font-stretch: ultra-expanded;
            display: inline-block;
            padding: 0 3px;
        }
        
        .iban {
            letter-spacing: 4px;
            font-family: monospace;
            font-size: 9pt;
        }
        
        span.black-diamond {
            content: "\\2756";
        }
        
        @media print {
            #pageHeader {position: running(pageHeader);height: auto;padding: 0;}
            #header-container {background: none !important;position: absolute;top:0;}
            #page-container   {background: none !important;position: absolute;top:0; padding:0 !important; }
			.noPDF{display:none;}
            .pf,.hf {background:none !important;width:21cm; min-height:0; height:auto; margin: 0 auto;top:0;padding: 0;}
            div.cuerpo {margin: 0;}
            .titulo {margin-bottom: 1em;}
            p{padding-left:0;margin-left:0}
            .medium-editor-table td.without-border {border: 0 !important;}
            .medium-editor-table td.with-border {border: 1px solid black !important;}
            .firma{border:0}
            .salto-pagina{visibility: hidden;clear: both;page-break-after: always;}
            .bloque {page-break-inside: avoid;}
			.bloque-servicios {page-break-inside: auto;orphans : 5;widows : 10;}
        }

        span.black-diamond {
            content: "\\2756";
        }
    </style>
</head>`,
headOtros: `<head>
    <meta content="text/html;charset=utf-8" http-equiv="content-type" />
    <title>plantilla OTROS</title>

    <style type="text/css">
        @charset "UTF-8";
		body { margin:0; font-size:100%; -webkit-print-color-adjust:exact; }
		.header-container {display:none;}
		.editor .pf {border:2px dashed #000; margin:0 auto; width:21cm; min-height:29.7cm; padding:0;}
    </style>
</head>`,
header: '',
footer: '',
name: '',
body: `<body>
	<div id="header-container" class="header-container no-header">
		<div class="hf"><header id="pageHeader" class="header-content">\${header}</header></div>
	</div>
	<div id="page-container">
		<div class="pf">\${content}</div>
	</div>
	<div id="pageFooter" class="footer-content">\${footer}</div>
</body>`,
	  actualContent: '<p>placeholder</p>',
      options: {
		spellcheck: false,
        placeholder: {
            /* This example includes the default options for placeholder,
              if nothing is passed this is what it used */
            text: 'Comience a escribir aquí',
            hideOnClick: true
        },
		disableReturn: true,
        buttonLabels: 'fontawesome',
        toolbar: {
          static: true,
          align: 'center',
          sticky: true,
          updateOnEmptySelection: true,
          buttons: [
            "image","bold", "italic", "underline", "strikethrough", "subscript", "superscript", 
            "anchor", "quote", "pre", "orderedlist", "unorderedlist", 
            "justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "saltoPagina", "mediumEditorEnhacedTable",
			"html", "removeFormat", "fontsize2", "fontname", "colorpicker",
			"watermark", "provisional", "caracteristica", "caracteristicaListado", "valor", "checkbox", "cabecera", "pie", "bloque1"
		  ],
		  onChange: function() { console.log('Cambió: ' + this.content) },
		  onShowToolbar: function() { console.log('handleShowToolbar') }
        }
      },
	  contextMenu_options: [
		{ name: 'Duplicar', slug: 'duplicate', icon: 'fa fa-copy' },
        // { type: 'divider', },
        { name: 'Editar', slug: 'edit',  icon: 'fa-pen-to-square'},
        { name: '<span style="color:red;">Borrar</span>', slug: 'delete', icon: 'fa fa-trash', },
		{ type: 'divider', },
        { name: '<span style="color:blue;">Copiar</span>', slug: 'copy', icon: 'fa fa-trash', },
        { name: '<span style="color:blue;">Pegar</span>', slug: 'paste', icon: 'fa fa-trash', },
		{ type: 'divider', },
		{ name: 'Cancelar', slug: 'cancel', icon: 'fa fa-times', },
	  ],
	  objetoActual: {},
	  opciones: false,
	  popupEdicionShow: false,
	  customToolbar: [
        // [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['10px', false, '18px', '32px'] }],
		[{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image'],
        [{ 'direction': 'rtl' }],
        ['clean'],
		['video']
      ],
    };
  },
  computed: {
	processedContent: function(){
		var self = this;
		if(self.tipo == "templates/EXPEDIENTES/"){
			var body = self.body.replace("${header}",self.header).replace("${content}", self.actualContent).replace("${footer}", self.footer);
			var result = self.htmlData.replace("${head}", self.head).replace("${body}", body)
					.replace(/\/\*INICIO-NOMBRE-FICHERO\*\/.*\/\*FIN-NOMBRE-FICHERO\*\//, `/*INICIO-NOMBRE-FICHERO*/"${self.name}"/*FIN-NOMBRE-FICHERO*/`)
					.replace(/<title>.*<\/title>/, `<title>${self.name}</title>`);
			self.options.paste = {
				/* This example includes the default options for paste,
				if nothing is passed this is what it used */
				forcePlainText: false,
				cleanPastedHTML: false,
				cleanReplacements: [],
				cleanAttrs: ['class', 'style', 'dir'],
				cleanTags: ['meta'],
				unwrapTags: []
			}
			return result;
		} else if(self.tipo == "templates/OFERTAS/"){
			var body = self.body.replace("${header}",self.header).replace("${content}", self.actualContent).replace("${footer}", self.footer);
			var result = self.htmlData.replace("${head}", self.headOferta).replace("${body}", body)
					.replace(/\/\*INICIO-NOMBRE-FICHERO\*\/.*\/\*FIN-NOMBRE-FICHERO\*\//, `/*INICIO-NOMBRE-FICHERO*/"${self.name}"/*FIN-NOMBRE-FICHERO*/`)
					.replace(/<title>.*<\/title>/, `<title>${self.name}</title>`);
			self.options.paste = {
				/* This example includes the default options for paste,
				if nothing is passed this is what it used */
				forcePlainText: false,
				cleanPastedHTML: false,
				cleanReplacements: [],
				cleanAttrs: ['class', 'style', 'dir'],
				cleanTags: ['meta'],
				unwrapTags: []
			}
			return result;
		} else if(self.tipo == "templates/mail/"){
			var body = self.body.replace("${header}",self.header).replace("${content}", self.actualContent).replace("${footer}", self.footer);
			var result = self.htmlData.replace("${head}", self.headMail).replace("${body}", body)
					.replace(/\/\*INICIO-NOMBRE-FICHERO\*\/.*\/\*FIN-NOMBRE-FICHERO\*\//, `/*INICIO-NOMBRE-FICHERO*/"${self.name}"/*FIN-NOMBRE-FICHERO*/`)
					.replace(/<title>.*<\/title>/, `<title>${self.name}</title>`);
			self.options.paste = {
				/* This example includes the default options for paste,
				if nothing is passed this is what it used */
				forcePlainText: false,
				cleanPastedHTML: false,
				cleanReplacements: [],
				cleanAttrs: ['class', 'style', 'dir'],
				cleanTags: ['meta'],
				unwrapTags: []
			}
			return result;
		} else {
			var body = self.body.replace("${header}",self.header).replace("${content}", self.actualContent).replace("${footer}", self.footer);
			var result = self.htmlData.replace("${head}", self.headOtros).replace("${body}", body)
					.replace(/\/\*INICIO-NOMBRE-FICHERO\*\/.*\/\*FIN-NOMBRE-FICHERO\*\//, `/*INICIO-NOMBRE-FICHERO*/"${self.name}"/*FIN-NOMBRE-FICHERO*/`)
					.replace(/<title>.*<\/title>/, `<title>${self.name}</title>`);
			self.options.paste = {
				/* This example includes the default options for paste,
				if nothing is passed this is what it used */
				forcePlainText: false,
				cleanPastedHTML: false,
				cleanReplacements: [],
				cleanAttrs: ['class', 'style', 'dir'],
				cleanTags: ['meta'],
				unwrapTags: []
			}
			return result;
		}
	}
  },
  props: {
    defaultData: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: {}
    },
    tipo: {
      type: String
    }
  },
  methods: {
	handleClick (event) {
		// detenemos la primera acción del evento
		event.preventDefault();
		// ponemos como item el html sobre el que hemos hecho click
		this.item = event.target;
		this.actualSelection = this.getCurrentSelection();
		console.log('handleClick', event, this.item);
		this.$refs.vueSimpleContextMenu.showMenu(event, this.item)
	},


	optionClicked (event) {
		// window.alert(JSON.stringify(event));
		// pintamos el html del elemento sobre el que hemos hecho click
		let objeto = {
			html:this.item.innerHTML,
			tag:this.item.tagName,
			selection: this.actualSelection
		};
		// aplicamos la acción correspondiente, según el evento y el tag del elemento;
		switch (event.option.slug) {
			case 'duplicate':
				// Afecta al tag completo
				this.duplicate(objeto);
				break;
			case 'edit':
				// afecta a la selección, a no ser que no se haya seleccionado nada, que afectará al tag completo
				this.edit(objeto);
				break;
			case 'delete':
				// afecta al tag completo
				this.delete(objeto);
				break;
			case 'copy':
				// afecta al tag completo
				this.copy(objeto);
				break;
			case 'paste':
				// afecta al tag completo
				this.paste(objeto);
				break;
			case 'cancel':
				this.cancel(objeto);
				break;
			default:
				break;
		}

	},

	duplicate(objeto){
		// duplicamos el elemento this.item
		let nuevo = this.item.cloneNode(true);
		// lo insertamos después del elemento this.item
		this.item.parentNode.insertBefore(nuevo, this.item.nextSibling);
	},
	delete(objeto) {
		const self = this;
		const app = self.$f7;
		// borramos el elemento this.item
		// - lanzamos un diálogo de confirmación
		self.$f7.dialog.confirm('¿Está seguro de que desea borrar el elemento seleccionado?', 'Borrar', () => {
			// - si confirma, borramos el elemento
			this.item.parentNode.removeChild(this.item);
		});
	},
	copy(objeto) {
		const self = this;
		const app = self.$f7;
		// copiamos al portapapeles el elemento seleccionado 
		var data = [new ClipboardItem({ "text/html": Promise.resolve(new Blob([objeto.selection], { type: "text/html" })) })];
		navigator.clipboard.write(data).then(function() {
			app.dialog.alert('Copiado al portapapeles');
		}).catch(function(error) {
			app.dialog.alert('Error al copiar al portapapeles: ' + error);
		});
	},
	async paste(objeto) {
		const self = this;
		const app = self.$f7;
		const items = await navigator.clipboard.read();
		let type = items[0].types[0];
		// si items[0].types contiene 'text/html' es que es un elemento html y pondremos type = 'text/html'
		if(items[0].types.includes('text/html')){
			type = 'text/html';
		}
		const div = document.createElement('div');
		switch (type) {
			case 'text/html':
			case 'text/plain':
				const textBlob = await items[0].getType(type);
				console.log("textBlob", textBlob);
				const text = await (new Response(textBlob)).text();
				div.innerHTML = text;
				break;
			case 'image/png':
			case 'image/jpeg':
				const imageBlob = await items[0].getType(type);
				const img = document.createElement('img');
				img.src = URL.createObjectURL(imageBlob);
				div.appendChild(img);
				break;
			default:
				app.dialog.alert('No se puede pegar este tipo de contenido');
				break;
		}
		this.item.parentNode.insertBefore(div, this.item.nextSibling);
	},
	edit(objeto){
		// si hay selección, la rodeamos de un span con un id único
		if(objeto.selection){
			let id = 'id' + Math.random().toString(36).substr(2, 9);
			let span = document.createElement('span');
			span.id = id;
			span.innerHTML = objeto.selection;
			// reemplazamos la selección por el span
			let range = window.getSelection().getRangeAt(0);
			range.deleteContents();
			range.insertNode(span);
			// Asignamos el nuevo span como this.item
			this.item = span;
		}
		// abrimos un modal con las propiedades que queremos modificar del objeto según el tag
		console.log(JSON.stringify(objeto));
		objeto.properties = {};
		objeto.properties.style = {}; 
		//this.item.style?this.item.style.filter(st=>st).map(st=>{opciones:null,valor:st}):{};
		Object.keys(this.item.style).forEach(element => {
			if(this.item.style[element]){
				try{
					this.item.style[element] = this.item.style[element];
					objeto.properties.style[element] = {opciones:null,valor:this.item.style[element],tipo: 'style'};
				}catch(e){console.log(e.message);}
			}
		});
		// vamos a añadir los atributos que tiene el elemento objeto, para poderlos modificar si es necesario
		objeto.properties.attributes = {};
		let attrs = this.item.getAttributeNames();
		attrs.forEach(attr => {
			if(attr != 'style') {
				objeto.properties.attributes[attr] = {opciones:null,valor:this.item.getAttribute(attr),tipo: 'attribute'};
			}
		});

		switch (objeto.tag) {
			case 'IMG':
				// en las imágenes poolríamos cambiar la posición (left, right, text), el ancho, el alto.
				// objeto.properties.style.float = {opciones:['left','right','none'],valor:this.item.style.float,tipo: 'style'};
				// objeto.properties.style.width = {opciones:null,valor:this.item.style.width,tipo: 'style'};
				// objeto.properties.style.height = {opciones:null,valor:this.item.style.height,tipo: 'style'};
				
				objeto.properties.attributes['width'] =  {opciones:null,valor:this.item.getAttribute("width"),tipo: 'attribute'};
				objeto.properties.attributes['height'] =  {opciones:null,valor:this.item.getAttribute("height"),tipo: 'attribute'};
				objeto.properties.attributes['align'] =  {opciones:[{content:'left',name:'Izquierda'},{content:'right',name:'Derecha'}],valor:this.item.getAttribute("align"),tipo: 'attribute'};
				break;
			case 'P':
				// en los párrafos podríamos cambiar el tamaño de la fuente, el color, el tipo de letra, el interlineado, la alineación, el espaciado, el margen, el padding
				objeto.properties.style.fontSize = {opciones:null,valor:this.item.style.fontSize,tipo: 'style'};
				objeto.properties.style.color = {opciones:null,valor:this.item.style.color,tipo: 'style'};
				objeto.properties.style.fontFamily = {opciones:null,valor:this.item.style.fontFamily,tipo: 'style'};
				objeto.properties.style.lineHeight = {opciones:null,valor:this.item.style.lineHeight,tipo: 'style'};
				objeto.properties.style.textAlign = {opciones:[
						{content:'left',name:'Izquierda'},
						{content:'center',name:'Centrado'},
						{content:'right',name:'Derecha'},
						{content:'justify',name:'Justificado'}
					],valor:this.item.style.textAlign,tipo: 'style'};
				objeto.properties.style.letterSpacing = {opciones:null,valor:this.item.style.letterSpacing,tipo: 'style'};
				objeto.properties.style.margin = {opciones:null,valor:this.item.style.margin,tipo: 'style'};
				objeto.properties.style.padding = {opciones:null,valor:this.item.style.padding,tipo: 'style'};
				break;
			case 'SPAN':
				// en los spans podríamos cambiar el tamaño de la fuente, el color, el tipo de letra
				objeto.properties.style.fontSize = {opciones:null,valor:this.item.style.fontSize,tipo: 'style'};
				objeto.properties.style.color = {opciones:null,valor:this.item.style.color,tipo: 'style'};
				objeto.properties.style.fontFamily = {opciones:null,valor:this.item.style.fontFamily,tipo: 'style'};
				break;
			case 'VALOR','DIV':
				// en los spans podríamos cambiar el tamaño de la fuente, el color, el tipo de letra
				objeto.properties.style.fontSize = {opciones:null,valor:this.item.style.fontSize,tipo: 'style'};
				objeto.properties.style.color = {opciones:null,valor:this.item.style.color,tipo: 'style'};
				objeto.properties.style.fontFamily = {opciones:null,valor:this.item.style.fontFamily,tipo: 'style'};
				// si es una lista (this.item.getAttribute("data-type")=='list'), le vamos a permitir que defina las propiedades data-condition y el texto a mostrar con los campos de los objetos de la lista o simplemente [valor] si es una lista de strings
				if(this.item.getAttribute("data-type") == 'list') {
					objeto.properties.attributes['data-type'] =  {opciones:null,valor:this.item.getAttribute("data-type"),tipo: 'attribute'};
					objeto.properties.attributes['data-type-value'] = {opciones:null,valor:this.item.getAttribute("data-type-value"),tipo: 'attribute'};
					objeto.properties.attributes['data-condition'] = {opciones:null,valor:this.item.getAttribute("data-condition"),tipo: 'attribute'};
					objeto.properties.innerHTML = {opciones:[],valor:this.item.innerHTML?this.item.innerHTML:"[value]",tipo: "innerHTML"};
					// si alguno de los campos de la lista son listas, le vamos a permitir que defina las propiedades data-recursive-field
					// esto lo veremos mirando si algún campo dentro de valores de los hijos del data-type-value es una lista
					let valor = this.getValor(this.item.getAttribute("data-type-value"));
					if(valor && valor.children && valor.children.length){
						objeto.properties.attributes['data-recursive-field'] = {
							opciones:valor.children.filter(child => child.tipo == 'list').sort((a,b)=>a.name.localeCompare(b.name)),
							valor:this.item.getAttribute("data-recursive-field"),
							tipo: 'attribute'
						};
						objeto.properties.innerHTML = {
							opciones:valor.children.sort((a,b)=>a.name.localeCompare(b.name)),
							valor:this.item.innerHTML?this.item.innerHTML:"--texto--",
							tipo: "innerHTML"
						};
					}
				} else {
					objeto.properties.innerHTML = {
						opciones:this.opciones?this.opciones.sort((a,b)=>a.name.localeCompare(b.name)):[],
						valor:this.item.innerHTML?this.item.innerHTML:"--texto--",
						tipo: "innerHTML"
					};
				}
				break;
		
			default:
				break;
		}
		this.objetoActual = objeto;
		this.popupEdicionShow = true;
	},
	cancel(objeto) {
	},
	getValor(valor){
		var self = this;
		let valorEntidad = self.$refs.mediumeditor.editor.options.extensions.valor;
		// buscamos el primer valor que tenga el content == valor
		return valorEntidad.valores.find(v => v.content == valor);
	},
	applyChanges: function() {
		// aplicaremos los estilos de objetoActual al item seleccionado
		var self = this;
		var objeto = self.objetoActual;
		var item = self.item;
		if(objeto.properties.style){
			// Iteramos por todos los atributos de objeto.properties.style
			Object.keys(objeto.properties.style).forEach(key => {
				// si el valor del atributo es diferente al valor del atributo del item, lo cambiamos
				if(objeto.properties.style[key] && objeto.properties.style[key].valor != item.style[key]){
					try{item.style[key] = objeto.properties.style[key].valor;}catch(e){console.log(e);}
				}
			});

		}
		// ahora vamos a aplicar los cambios en los atributos del objeto
		if(objeto.properties.attributes){
			// Iteramos por todos los atributos de objeto.properties.attributes
			Object.keys(objeto.properties.attributes).forEach(key => {
				// si el valor del atributo es diferente al valor del atributo del item, lo cambiamos
				if(objeto.properties.attributes[key].valor != item.getAttribute(key)){
					item.setAttribute(key, objeto.properties.attributes[key].valor);
				}
			});
		}
		// ahora vamos a aplicar los cambios en el contenido html del objeto
		if(objeto.properties.innerHTML){
			// si el valor del atributo es diferente al valor del atributo del item, lo cambiamos
			if(objeto.properties.innerHTML.valor != item.innerHTML){
				item.innerHTML = objeto.properties.innerHTML.valor.replaceAll("\n","<br/>");
			}
		}
		self.$refs.popupEdicion.close();
	},
	showOptions: function(property, event) {
		const opciones = property.opciones;
		const self = this;
		self.objetoActual.event = event;
		if(opciones && opciones.length) {
			// Crearemos un select en el aire y el valor seleccionado lo pegaremos en el innerHtml del elemento actual.
			self.opciones = opciones;
			self.property = property;
			self.$refs.popover.open(event.target);
		}
	},
	setOptionSelected: function(event) {
		const self = this;
		let valor = event.target.options[event.target.options.selectedIndex].value;
		// seleccionamos de self.opciones la que tiene el valor seleccionado
		let opcion = self.opciones.find(op => op.content == valor);
		if (self.property.tipo == 'innerHTML') {
			let sel = window.getSelection()
			var newDocument = new DOMParser().parseFromString(self.property.valor, 'text/html');
			var range = sel.getRangeAt(0);
			var element = newDocument.createTextNode(`[${valor}${opcion.tipo == 'html' ? ':html' : ''}]`);
			// element.innerHTML = `[${valor}]`;
			range.insertNode(element);

            // Move the caret immediately after the inserted span
            range.setStartAfter(element);
    
            // Ahora añadimos un espacio al final de range para que no se quede pegado al elemento
            const auxElem = newDocument.createTextNode('\u00A0');
            range.insertNode(auxElem);
            // Move the caret immediately after the inserted span
            range.setStartAfter(auxElem);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range)
			// self.property.valor = window.getSelection().baseNode.textContent.substr(0, window.getSelection().focusOffset) + '[' + valor + ']' + window.getSelection().baseNode.textContent.substr(window.getSelection().focusOffset);
		} else {
			self.property.valor = valor;
		}
		self.opciones = false;
		self.$refs.popover.close(self.objetoActual.event.target);
	},

	getCurrentSelection: function() {
        var html = ''
        var sel

        if (typeof window.getSelection != 'undefined') {
        sel = window.getSelection()
        if (sel.rangeCount) {
            var container = document.createElement('div')
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents())
            }
            html = container.innerHTML
        }
        } else if (typeof document.selection != 'undefined') {
            if (document.selection.type == 'Text') {
                html = document.selection.createRange().htmlText
            }
        }
        return html
    },

	getContent() {
		const self = this;
		var contenidoRaw = self.$refs.mediumeditor.editor.getContent(),
			watermarked = false,
			provisional = false,
			top = 0,
			bottom = 0;
		if(self.tipo == "templates/EXPEDIENTES/"){
			watermarked = self.$refs.mediumeditor.editor.options.extensions.watermark.isWatermarked();
			provisional = self.$refs.mediumeditor.editor.options.extensions.provisional.isProvisional();
			top = self.$refs.mediumeditor.editor.options.extensions.cabecera.cabeceraSize();
			bottom = self.$refs.mediumeditor.editor.options.extensions.pie.pieSize();
		} else if(self.tipo == "templates/OFERTAS/"){
			// watermarked = self.$refs.mediumeditor.editor.options.extensions.watermark.isWatermarked();
			// provisional = self.$refs.mediumeditor.editor.options.extensions.provisional.isProvisional();
			top = self.$refs.mediumeditor.editor.options.extensions.cabecera.cabeceraSize();
			bottom = self.$refs.mediumeditor.editor.options.extensions.pie.pieSize();
		}
		var doc = new DOMParser().parseFromString(contenidoRaw, 'text/html'),
			xhtmlDoc =  new XMLSerializer().serializeToString(doc); // Se convierte en xhtml
		xhtmlDoc = this._manageBodyClasses(xhtmlDoc, watermarked, provisional);
		xhtmlDoc = this._manageMargins(xhtmlDoc, top, bottom);
		return xhtmlDoc;
	},
	loadContent(newContent) {
		const self = this;
		var newDocument = new DOMParser().parseFromString(newContent, 'text/html');
		var pfs = Array.from(newDocument.getElementsByClassName("pf"));
		if(self.tipo == "templates/EXPEDIENTES/"){
			// Header content
			var pageHeader = newDocument.getElementById("pageHeader");
			if(pageHeader){
				this.header = pageHeader.innerHTML;
			}
			// Footer content
			var pageFooter = newDocument.getElementById("pageFooter");
			if(pageFooter){
				this.footer = pageFooter.innerHTML;
			}
			// Set everything into one pf
			self.actualContent = pfs.reduce((result, current) => current.innerHTML.trim(),"");

			self.$refs.mediumeditor.editor.setContent(self.processedContent, 0);
			self.$refs.mediumeditor.editor.options.extensions.watermark.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.provisional.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.pie.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.cabecera.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.cabecera.refresh();
			self.$refs.mediumeditor.editor.options.extensions.pie.refresh();
		} else if(self.tipo == "templates/OFERTAS/"){
			// Header content
			var pageHeader = newDocument.getElementById("pageHeader");
			if(pageHeader){
				this.header = pageHeader.innerHTML;
			}
			// Footer content
			var pageFooter = newDocument.getElementById("pageFooter");
			if(pageFooter){
				this.footer = pageFooter.innerHTML;
			}
			// Set everything into one pf
			self.actualContent = pfs.reduce((result, current) => current.innerHTML.trim(),"");

			self.$refs.mediumeditor.editor.setContent(self.processedContent, 0);
			// self.$refs.mediumeditor.editor.options.extensions.watermark.setInitialContent(newDocument);
			// self.$refs.mediumeditor.editor.options.extensions.provisional.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.pie.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.cabecera.setInitialContent(newDocument);
			self.$refs.mediumeditor.editor.options.extensions.cabecera.refresh();
			self.$refs.mediumeditor.editor.options.extensions.pie.refresh();
		} else if(self.tipo == "templates/mail/"){
			if(pfs){
				self.actualContent = pfs.reduce((result, current) => current.innerHTML.trim(),"");
			} else {
				self.actualContent = newContent;
			}
			self.$refs.mediumeditor.editor.setContent(self.processedContent, 0);
		} else {
			if(pfs){
				self.actualContent = pfs.reduce((result, current) => current.innerHTML.trim(),"");
			} else {
				self.actualContent = newContent;
			}
			self.$refs.mediumeditor.editor.setContent(self.processedContent, 0);
		}
		self.$refs.mediumeditor.editor.options.extensions.mediumEditorEnhacedTable.refresh();
	},
	setName(name){
		var self = this;
		self.name = name;
	},
	_prepareDefinition(property, parent){
		var self = this;
		var preparedProp = {};
		var propertyData = {};
		if(parent){
			propertyData = Object.assign({},property);
			propertyData.property = (parent.property) ? `${parent.property}.${propertyData.property}` : propertyData.property;
			propertyData.name = (parent.name) ? `${parent.name} - ${propertyData.name}` : propertyData.name;
		}
		if(property.children && property.children.length){
			property.children.forEach(child => {
				preparedProp[child.property] = self._prepareDefinition(child, propertyData);
			});
		} else {
			preparedProp[propertyData.property] = propertyData.name;
		}
		return preparedProp;
	},
	_subscribePaginas(){
		var self = this;
		self.$refs.mediumeditor.editor.subscribe("editableInput", self._gestionaPaginas);
	},
	_unsubscribePaginas(){
		var self = this;
		self.$refs.mediumeditor.editor.unsubscribe("editableInput", self._gestionaPaginas);
	},
	_gestionaPaginas(){
		return; // Se quita la gestión de páginas de momento, porque está dando problemas
		setTimeout(()=>{
			var self = this;
			var nuevasPaginas = self._calcularPaginas();
			self.nPaginas = nuevasPaginas;
			var pageContainer = document.getElementById("page-container");
			var pages = Array.from(pageContainer.childNodes).filter(node => node.nodeName != "#text");
			var page = (pages.length) ? pages[0] : null;
			if(!page) {
				return;
			}
			page.style.height = (23.7 * self.nPaginas) + 'cm';
		},0);
	},
	_calcularPaginas(){
		var page = null;
		var pages = Array.from(document.getElementById("page-container").childNodes).filter(node => node.nodeName != "#text");
		var page = pages[0];
		var content = Array.from(page.childNodes).filter(node => node.nodeName != "#text");
		var ultimo = content.pop();
		var alturaFinal = ultimo.offsetHeight + ultimo.offsetTop;
		const pageHeight = 880;
		var paginas = Math.floor(alturaFinal/pageHeight) + 1;
		return paginas;
	},
	_manageBodyClasses: function(doc, watermarked, provisional){
		var bodyClasses = [];
		if(watermarked){
			bodyClasses.push("watermarked");
		}
		if(provisional){
			bodyClasses.push("provisional");
		}
		var bodyClassesString = bodyClasses.join(" ");
		return bodyClassesString ? doc.replace("<body>", `<body class="${bodyClassesString}">`) : doc;
	},
	_manageMargins: function(doc, top, bottom){
		top = top || 1.5;
		bottom = bottom || 1.5;
		var newDoc = doc.replace(/\/\*PAGE-MARGIN-START\*\/.*\/\*PAGE-MARGIN-END\*\//, `/*PAGE-MARGIN-START*/margin: ${top}cm 1.5cm ${bottom}cm 1.5cm;/*PAGE-MARGIN-END*/`);
		return newDoc;
	},
    tratarKeyUp: function(event) { 
		const self = this;
        //Si hemos presionado enter y el nodo tiene el atributo "data-disable-return", "disable-return" o "data-disable-return" a true
        let returnType = window.getSelection().baseNode.parentNode.getAttribute('data-return-type');
		if (returnType && returnType == "no-wrap" && (event.keyCode == 13 || event.which == 13)) {
            event.preventDefault();
            let node = MediumEditor.selection.getSelectionStart(self.$refs.mediumeditor.editor.options.ownerDocument);
			let p = self.$refs.mediumeditor.editor.options.ownerDocument.createElement('p');
			p.innerHTML = '<br>';
			node.parentElement.insertBefore(p, node.nextSibling);
			// move the cursor into the new paragraph
			MediumEditor.selection.moveCursor(self.$refs.mediumeditor.editor.options.ownerDocument, p);
			self.$refs.mediumeditor.editor.options.ownerDocument.execCommand('formatBlock', false, 'p');
        } else if (returnType && (event.keyCode == 13 || event.which == 13)) {
            event.preventDefault();
            let selOnKey = window.getSelection();
            let rangeSel = selOnKey.getRangeAt(0);
            var br = document.createElement(returnType);
            rangeSel.insertNode(br);
            rangeSel.setStartAfter(br);
            rangeSel.collapse(true);
            selOnKey.removeAllRanges();
            selOnKey.addRange(rangeSel);
        } else {


			if (event.keyCode == 13 || event.which == 13) {
				let node = MediumEditor.selection.getSelectionStart(self.$refs.mediumeditor.editor.options.ownerDocument);
				let brText = MediumEditor.selection.getCaretOffsets(node).right === 0;
				// let p = self.$refs.mediumeditor.editor.options.ownerDocument.createElement('p');
				// p.innerHTML = '<br>';
				// node.parentElement.insertBefore(p, node.nextSibling);
				// // move the cursor into the new paragraph
				// MediumEditor.selection.moveCursor(self.$refs.mediumeditor.editor.options.ownerDocument, p);
				let selOnKey = window.getSelection();
				let rangeSel = selOnKey.getRangeAt(0);
				var p = document.createElement('p');
				rangeSel.insertNode(p);
				rangeSel.setStartAfter(p);
				let auxElem = null;
				if (brText) {
					// Ahora añadimos un espacio al final de range para que no se quede pegado al elemento
					auxElem = document.createTextNode('\u00A0');
					rangeSel.insertNode(auxElem);
					// Move the caret immediately after the inserted span
					rangeSel.setStartAfter(auxElem);
				}
				rangeSel.collapse(true);
				selOnKey.removeAllRanges();
				selOnKey.addRange(rangeSel);
				self.$refs.mediumeditor.editor.options.ownerDocument.execCommand('formatBlock', false, 'p');
				if (auxElem) {
					document.removeChild(auxElem)
				}
			}
			// self.$refs.mediumeditor.editor.events.triggerCustomEvent('editableKeydownEnter', event, self.$refs.mediumeditor.editor);
			// self.handleKeyup(event);
		}
    },
	handleKeyup: function(event) {
		const self = this;
		const medium = MediumEditor;
        var node = medium.selection.getSelectionStart(self.$refs.mediumeditor.editor.options.ownerDocument),
            tagName;

        if (!node) {
            return;
        }

        // https://github.com/yabwe/medium-editor/issues/994
        // Firefox thrown an error when calling `formatBlock` on an empty editable blockContainer that's not a <div>
        if (medium.util.isMediumEditorElement(node) && node.children.length === 0 && !medium.util.isBlockContainer(node)) {
            self.$refs.mediumeditor.editor.options.ownerDocument.execCommand('formatBlock', false, 'p');
        }

        // https://github.com/yabwe/medium-editor/issues/834
        // https://github.com/yabwe/medium-editor/pull/382
        // Don't call format block if this is a block element (ie h1, figCaption, etc.)
        if (medium.util.isKey(event, medium.util.keyCode.ENTER) &&
            !medium.util.isListItem(node) &&
            !medium.util.isBlockContainer(node)) {

            tagName = node.nodeName.toLowerCase();
            // For anchor tags, unlink
            if (tagName === 'a') {
                self.$refs.mediumeditor.editor.options.ownerDocument.execCommand('unlink', false, null);
            } else if (!event.shiftKey && !event.ctrlKey) {
                self.$refs.mediumeditor.editor.options.ownerDocument.execCommand('formatBlock', false, 'p');
            }
        } 
    }
  },
  created: function() {
	const self = this;
	let options = {
		'checkbox': new Checkbox(),
		'fontsize2': new FontSize2(),
		'colorpicker': new ColorPicker(),
		'mediumEditorEnhacedTable': new MediumEditorEnhacedTable()
	};
	if(self.tipo == "templates/EXPEDIENTES/"){
		// para los expedientes pondremos que fontsize2 se de modo class
		options.fontsize2.mode = "class";
		options = Object.assign({
			'caracteristica': new Caracteristica(),
			'caracteristicaListado': new CaracteristicaListado(),
			'watermark': new Watermark(),
			'provisional': new Provisional(),
			'cabecera': new Cabecera(),
			'pie': new Pie(),
			'valor': new ValorInspeccion(),
			'saltoPagina': new SaltoPagina(),
			'bloque1': new Bloque({name:'bloque1'}),
			'bloque2': new Bloque({name:'bloque2'})
		}, options);	
	} else if(self.tipo == "templates/OFERTAS/"){
		// para los expedientes pondremos que fontsize2 se de modo class
		options.fontsize2.mode = "class";
		options = Object.assign({
			// 'caracteristica': new Caracteristica(),
			// 'caracteristicaListado': new CaracteristicaListado(),
			// 'watermark': new Watermark(),
			// 'provisional': new Provisional(),
			'cabecera': new Cabecera(),
			'pie': new Pie(),
			'valor': new ValorInspeccion(),
			'saltoPagina': new SaltoPagina(),
			'bloque1': new Bloque({name:'bloque1'}),
			// 'bloque2': new Bloque({name:'bloque2'})
		}, options);	
	} else if(self.tipo == "templates/mail/"){
		// para los mails pondremos que fontsize2 se de modo style
		options.fontsize2.mode = "style";
		options = Object.assign({
			'caracteristica': new Caracteristica({ tipo: self.tipo }),
			'watermark': new Watermark(),
			'provisional': new Provisional(),
			'valor': new ValorEntidad(),
			'saltoPagina': new SaltoPagina(),
			'bloque1': new Bloque({name:'bloque1'})
		}, options);
	}
	self.options.extensions = options;
  },
  mounted: function() {
    const self = this;
	if(self.tipo == "templates/EXPEDIENTES/"){
		Combos.getCombo({filter:{nombre: "bloques1"}}).then(res=>{
			var self = this;
			var bloque1 = self.$refs.mediumeditor.editor.options.extensions.bloque1;
			if (bloque1) {
				bloque1.changeOptions(res.list);
			}
		});
		Combos.getCombo({filter:{nombre: "cabeceras"}}).then(res=>{
			var self = this;
			var cabecera = self.$refs.mediumeditor.editor.options.extensions.cabecera;
			if (cabecera) {
				cabecera.changeOptions(res.list);
			}
		});
		Combos.getCombo({filter:{nombre: "pies"}}).then(res=>{
			var self = this;
			var pie = self.$refs.mediumeditor.editor.options.extensions.pie;
			if (pie) {
				pie.changeOptions(res.list);
			}
		});
		Inspeccion.getDefineCombo({name: "Inspección", property: "inspeccion"}).then(opciones=>{
			var self = this;
			var valor = self.$refs.mediumeditor.editor.options.extensions.valor;
			if (valor) {
				valor.loadValoresInspeccion(opciones);
			}
		});
	} else if(self.tipo == "templates/OFERTAS/"){
		Oferta.getDefineCombo2({name: "Oferta", property: "oferta"}).then(opciones=>{
			var self = this;
			var valor = self.$refs.mediumeditor.editor.options.extensions.valor;
			self.datosCampos = self._prepareDefinition(opciones);
			if (valor) {
				valor.loadValoresEntidad(opciones);
			}
		});
		Combos.getCombo({filter:{nombre: "cabeceras_oferta"}}).then(res=>{
			var self = this;
			var cabecera = self.$refs.mediumeditor.editor.options.extensions.cabecera;
			if (cabecera) {
				cabecera.changeOptions(res.list);
			}
		});
		Combos.getCombo({filter:{nombre: "pies_oferta"}}).then(res=>{
			var self = this;
			var pie = self.$refs.mediumeditor.editor.options.extensions.pie;
			if (pie) {
				pie.changeOptions(res.list);
			}
		});
		Combos.getCombo({filter:{nombre: "bloques_oferta"}}).then(res=>{
			var self = this;
			var bloque1 = self.$refs.mediumeditor.editor.options.extensions.bloque1;
			if (bloque1) {
				bloque1.changeOptions(res.list);
			}
		});
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "click", (c) => {
			var tableExt = self.$refs.mediumeditor.editor.options.extensions.mediumEditorEnhacedTable;
			tableExt.repositionCursor(c.target);
		});
		
		self._subscribePaginas();
		
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "keyup", (c) => {
			console.log("keyup");
			self.tratarKeyUp(c);
		});
			
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "contextmenu", (c) => {
			console.log("contextmenu");
			// Esto lo hago para ignorar la captura del evento contextmenu si se está pulsando la tecla shift
			if (c.shiftKey||c.ctrlKey) {return;}
			self.handleClick(c);
		});
	}else if(self.tipo == "templates/mail/"){
		// si el nombre termina en _oportunidad, usamos el modelo de oportunidades
		let modelo = LineaPedido;
		if(self.data.name.endsWith("_oportunidad")){
			modelo = Oportunidad;
		} else if(self.data.name.endsWith("_oferta")){
			modelo = Oferta;
		} else if(self.data.name.endsWith("_tarea")){
			modelo = Tarea;
		}
		modelo.getDefineCombo2({name: "Línea de pedido", property: "lineaPedido"}).then(opciones=>{
			var self = this;
			var valor = self.$refs.mediumeditor.editor.options.extensions.valor;
			self.datosCampos = self._prepareDefinition(opciones);
			if (valor) {
				valor.loadValoresEntidad(opciones);
			}
		});
		Combos.getCombo({filter:{nombre: "bloques_mail"}}).then(res=>{
			var self = this;
			var bloque1 = self.$refs.mediumeditor.editor.options.extensions.bloque1;
			if (bloque1) {
				bloque1.changeOptions(res.list);
			}
		});
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "click", (c) => {
			var tableExt = self.$refs.mediumeditor.editor.options.extensions.mediumEditorEnhacedTable;
			tableExt.repositionCursor(c.target);
		});
		
		self._subscribePaginas();
		
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "keyup", (c) => {
			console.log("keyup");
			self.tratarKeyUp(c);
		});
			
		self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "contextmenu", (c) => {
			console.log("contextmenu");
			// Esto lo hago para ignorar la captura del evento contextmenu si se está pulsando la tecla shift
			if (c.shiftKey||c.ctrlKey) {return;}
			self.handleClick(c);
		});
	}
	self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "click", (c) => {
		var tableExt = self.$refs.mediumeditor.editor.options.extensions.mediumEditorEnhacedTable;
		tableExt.repositionCursor(c.target);
	});
	
	self._subscribePaginas();
	
	self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "keyup", (c) => {
		console.log("keyup");
		self.tratarKeyUp(c);
	});
        
	self.$refs.mediumeditor.editor.on(self.$refs.mediumeditor.$el, "contextmenu", (c) => {
		console.log("contextmenu");
		// Esto lo hago para ignorar la captura del evento contextmenu si se está pulsando la tecla shift
		if (c.shiftKey) {return;}
		self.handleClick(c);
	});
  }
};
</script>