<template>
<r-form 
  ref="rformFiltroInspecciones"
  @submit="submitFilter(rlist, $event, rpanel)" 
  class="page inspecciones-offline"
>
  <template v-slot="{rform}">
    <r-list
      ref="rlist"
      :dbAdapter="Inspeccion"
      sort="fechaInsp"
      sortDir="DESC"
      :tableMode.sync="mostrarListado"
      :virtualListParams="{height: anteriores ? 262 : 242}"
      :rowsPerPage.sync="rowsPerPageInspecciones"
      :infiniteScroll.sync="infiniteScrollInspecciones"
      :searchOnInit="false"
      class="page"
      :shownFields="['codigo', 'cliente.razonSocial', 'calificacion', 'codPedido', 
        'numeroLineaPedido', 'formato.codigo', 'formato.nombre', 'direccionInspeccion', 'fechaInsp', 'hc1', 'id', 
        'sincronizado', 'estado', 'guardadoLocal', 'idExpedienteRelacionado', 'idPedido', 'item.nombre', 'servicio.nombre']"
      classContent="page-content data-table"
      :classList="['media-list','no-margin']"
      :filter=" anteriores ? {'estado.estado': {'$nin':['GENERADO', 'PLANIFICADO']}} : {'estado.estado': {'$in':['GENERADO', 'PLANIFICADO']}}"
    >
      <template v-slot:massiveActions="{selecteds}">
        <f7-button
          fill
          icon-f7="trash"
          text="Eliminar selección"
          class="display-flex"
          color="red"
          @click="eliminarSeleccionados(selecteds)"
        ></f7-button>
      </template>
      <!-- Top Navbar -->
      <template v-slot:before="{}">
        <f7-navbar
          :back-link="$t('atras')"
        >
          <!-- <f7-link slot="left" class="margin-left" href="/albaranes-offline/">
            <f7-icon class="margin-right-half" f7="doc"></f7-icon>
            Albaranes
          </f7-link> -->
          <f7-link slot="left" class="margin-left padding-left" @click="irAHoy">{{$t('common.today')}}</f7-link>
          <f7-link slot="left" class="margin-left" @click="diaAnterior">
            <f7-icon f7="chevron_left"></f7-icon>
          </f7-link>
          <f7-list slot="left">
            <ul class="contenedor-fecha-inspeccion">
              <r-input
                :class="{'fechaGris': !fechaActivo}"
                class="filtro-fecha-inspeccion"
                :form="rform"
                type="datepicker"
                :name="'fechaInspecciones'"
                @change="updateFechaFiltro"
                :calendar-params="{
                  closeOnSelect: true,
                  routableModals: false,
                  dateFormat: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },
                }"
              />
            </ul>
          </f7-list>
          <f7-link slot="left" class="margin-right" @click="diaSiguiente">
            <f7-icon f7="chevron_right"></f7-icon>
          </f7-link>
          <f7-link slot="right" class="margin-right" @click="verTodasInspecciones()">
            <f7-icon f7="calendar_badge_minus"></f7-icon>
                &nbsp;Todos
          </f7-link>
        </f7-navbar>
        <f7-subnavbar
          :title="anteriores ? $t('inspeccionesOffline.inspecciones_anteriores') : $t('inspeccionesOffline.inspecciones_pendientes')"
        >
          <f7-link class="margin-right" @click="clickMostrarListado">
            {{mostrarListado ? 'Mostrar como tarjeta' : 'Mostrar como tabla'}}
          </f7-link>
          <f7-link v-if="!anteriores"  popup-open=".popupnew" @click="inspeccionPlanificada = null">
            {{$t('inspecciones.inspecciones.continuar_sin_pedido')}}
            <f7-icon f7="chevron_right"></f7-icon>
          </f7-link>
        </f7-subnavbar>
        <f7-popup ref="popupnew" class="popupnew" @popup:opened="popupNewOpen($refs.rform.formData)">
          <f7-page>
            <f7-navbar>
              <f7-nav-left>
                <f7-link popup-close>Cerrar</f7-link>
              </f7-nav-left>
              <f7-nav-title>
                {{inspeccionPlanificada ? 'Expediente Planificado' : 'Nuevo expediente'}}
              </f7-nav-title>
              <f7-nav-right>
                <f7-link
                  v-if="$refs.rform && $refs.rform.formData.formatoSeleccionado && (inspeccionPlanificada != null || $refs.rform.formData.provinciaSeleccionada)"
                  popup-close
                  @click="continuarCreacion($refs.rform.formData)"
                >Continuar</f7-link>
              </f7-nav-right>
            </f7-navbar>
            <r-form ref="rform" :dbAdapter="Inspeccion" classContent="full-height">
              <template v-slot:default="{rform}">
                <f7-block-title v-if="!inspeccionPlanificada">{{$t('inspecciones.inspeccion_form.provincia')}}</f7-block-title>
                <div v-if="!inspeccionPlanificada" class="list" style="z-index: 2;">
                  <ul>
                    <r-autocomplete
                      ref="provincia"
                      :dbAdapter="Provincia"
                      :form="rform"
                      name="provinciaSeleccionada"
                      labelProp="nombre"
                      :shownFields="['id', 'codigo']"
                      @loaded="if($event.length==1) $refs.provincia.setValue($event[0]);"
                    ></r-autocomplete>
                  </ul>
                </div>
                <f7-block-title style="display: flex;">
                  Formato 
                  <f7-input
                    style="margin-left: 5px;"
                    outline
                    label="Buscar formato"
                    type="text"
                    clear-button
                    @input="searchFormato = $event.target.value"
                    :disabled="!!inspeccionPlanificada && !!inspeccionPlanificada.idExpedienteRelacionado && isCodFormatoInList(inspeccionPlanificada.formato.codigo)"
                  ></f7-input>
                </f7-block-title>
                <f7-list v-if="inspeccionPlanificada || rform.formData.provinciaSeleccionada">
                  <f7-list-item
                    v-for="formato in getFormatosProvincia(inspeccionPlanificada ? {codigo: inspeccionPlanificada.direccionInspeccion.provincia} : rform.formData.provinciaSeleccionada)"
                    :key="'formato.' + formato.id"
                    radio
                    :value="formato.codigo"
                    name="formato"
                    :checked="inspeccionPlanificada && inspeccionPlanificada.formato && inspeccionPlanificada.formato.codigo == formato.codigo"
                    :title="formato.codigo + ' - ' + formato.nombre"
                    @change="$set(rform.formData, 'formatoSeleccionado', formato.codigo)"
                    :disabled="!!inspeccionPlanificada && !!inspeccionPlanificada.idExpedienteRelacionado && isCodFormatoInList(inspeccionPlanificada.formato.codigo)"
                  ></f7-list-item>
                </f7-list>
              </template>
            </r-form>
          </f7-page>
        </f7-popup>
      </template>

      <!-- Page content-->
      <template v-slot:header="{rlist}" v-if="mostrarListado">
        <thead class="thead-sticky">
          <tr>
            <th v-if="anteriores" style="min-width:1em;">
              <input ref="checkGeneral" type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
            </th>
            <th 
              style="min-width:1em;"
              @click="rlist.setOrder('codigo')"
              :class="rlist.sortableClasses('codigo')"
            >{{$t('inspecciones.inspeccion_form.codigo')}}</th>
            <th
              style="min-width:1em;"
              @click="rlist.setOrder('cliente.razonSocial')"
              :class="rlist.sortableClasses('cliente.razonSocial')"
            >{{$t('inspecciones.inspeccion_form.cliente')}}</th>
            <!-- <th
              style="min-width:1em;"
              @click="rlist.setOrder('codigoAlbaran')"
              :class="rlist.sortableClasses('codigoAlbaran')"
            >{{$t('inspecciones.inspeccion_form.albaran')}}</th> -->
            <th
              style="min-width:1em;"
              @click="rlist.setOrder('calificacion')"
              :class="rlist.sortableClasses('calificacion')"
            >{{$t('inspecciones.inspeccion_form.calificacion')}}</th>
            <th
              style="min-width:1em;"
            >{{$t('inspecciones.inspeccion_form.servicio')}}</th>
            <th
              style="min-width:1em;"
            >{{$t('inspecciones.inspeccion_form.item')}}</th>
            <th
              style="min-width:1em;"
              @click="rlist.setOrder('numeroPedido')"
              :class="rlist.sortableClasses('numeroPedido')"
            >{{$t('inspecciones.inspeccion_form.pedido')}}</th>
            <th
              style="min-width:1em;"
            >{{$t('inspecciones.inspeccion_form.linea')}}</th>
            <th 
              style="min-width:1em;"
            >{{$t('inspecciones.inspeccion_form.formato')}}</th>
            <th
              style="min-width:1em;"
            >{{$t('inspecciones.inspeccion_form.direccion')}}</th>
            <th>{{$t('inspecciones.inspeccion_form.provincia')}}</th>
            <th
              style="min-width:1em;"
              @click="rlist.setOrder('fechaInsp')"
              :class="rlist.sortableClasses('fechaInsp')"
            >{{$t('inspecciones.inspeccion_form.fecha_inspeccion')}}</th>
            <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
              <f7-icon f7="gear_alt_fill"></f7-icon>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:default="{vlData,rlist}" v-if="mostrarListado">
        <tr v-for="(item) in vlData.items" :key="'inspeccion-' + item.id">
          <td v-if="anteriores"> 
            <input
              type="checkbox"
              :checked="rlist.getPrimary(item) in rlist.selected"
              @change="rlist.toggleSelect(item, $event.target.checked)"
            />
          </td>
          <td>{{item.codigo ? item.codigo : ''}}</td>
          <td>{{item.cliente ? item.cliente.razonSocial : 'Sin Cliente'}}</td>
          <!-- <td>{{item.codigoAlbaran ? item.codigoAlbaran : 'No'}}</td> -->
          <td>
            <r-badge v-if="getValorCalificacion(item.calificacion)" :color="getColorCalificacion(item.calificacion)" :text="getValorCalificacion(item.calificacion) || ''"></r-badge>
          </td>
          <td>{{item.servicio && item.servicio.nombre ? item.servicio.nombre : ''}}</td>
          <td>{{item.item && item.item.nombre ? item.item.nombre : ''}}</td>
          <td>{{item.numeroPedido ? item.numeroPedido : (item.codPedido ? item.codPedido : 'Sin Pedido')}}</td>
          <td>{{typeof item.numeroLineaPedido != 'undefined' && item.numeroLineaPedido != null ? item.numeroLineaPedido : 'Sin Línea'}}</td>
          <td>{{item.formato ? item.formato.codigo : ''}}</td>
          <td>{{item.direccionInspeccion ? item.direccionInspeccion.direccion : ''}}</td>
          <td>{{item.direccionInspeccion ? item.direccionInspeccion.provincia : ''}}</td>
          <td>{{item.fechaInsp | dateParse | dateFormat('DD/MM/YYYY')}}</td>

          <td
            class="display-flex right-column-sticky align-items-center padding-half text-color-gray text-align-center"
          >
            <template v-if="item.guardadoLocal">
              <!-- <f7-button
                v-tooltip="$t('inspeccionesOffline.previsualizar')"
                icon-f7="printer"
                icon-size="18"
                color="primary"
                @click="downloadPreview(item)"
              ></f7-button> -->
              <f7-button
                v-tooltip="$t('inspeccionesOffline.visualizar')"
                :href="'/inspecciones/view-offline/'+item.id+'/'"
                icon-f7="eye"
                icon-size="18"
                color="green"
              ></f7-button>
              <f7-button
                v-if="!item.sincronizado"
                v-tooltip="$t('inspeccionesOffline.editar')"
                :href="'/inspecciones/edit-offline/'+item.id+'/'"
                fill
                icon-f7="pencil"
                icon-size="18"
                color="blue"
                class="margin-right-half"
              ></f7-button>
              <f7-button
                v-tooltip="$t('inspeccionesOffline.eliminar')"
                fill
                icon-f7="trash"
                icon-size="18"
                color="red"
                class="margin-right-half"
                @click.stop="borrarExpediente(item.id)"
              ></f7-button>
              <f7-button
                v-tooltip="$t('inspecciones.inspecciones.malo')"
                icon-f7="exclamationmark_triangle_fill"
                icon-size="18"
                color="orange"
                fill
                class="margin-right-half"
                @click.stop="expedienteMalo(item)"
              ></f7-button>
              <f7-button
                v-if="item.idPedido"
                v-tooltip="$t('inspeccionesOffline.documentacion')"
                @click.stop="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                fill
                icon-f7="doc_plaintext_fill"
                icon-size="18"
                class="margin-right-half"
              ></f7-button>

              <f7-button
                fill
                icon-f7="cloud_upload"
                color="green"
                @click="syncUp([item])"
              >&nbsp;SINCRONIZAR</f7-button>
            </template>
            <template v-else-if="item.estado && item.estado.estado=='PLANIFICADO'">
              <!-- <f7-button
                v-tooltip="$t('inspeccionesOffline.imprimir')"
                icon-f7="printer"
                icon-size="18"
                color="primary"
                @click="downloadInforme(item)"
              ></f7-button> -->
              <f7-button
                v-tooltip="$t('inspeccionesOffline.inspeccionar')"
                popup-open=".popupnew"
                @click="inspeccionPlanificada = item"
                fill
                icon-f7="arrow_right_circle"
                icon-size="18"
                color="green"
                class="margin-right-half"
              ></f7-button>
              <f7-button
                v-if="item.idPedido"
                v-tooltip="$t('inspeccionesOffline.documentacion')"
                @click="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                fill
                icon-f7="doc_plaintext"
                icon-size="18"
                class="margin-right-half"
              ></f7-button>
            </template>
            <template v-else>
              <!-- <f7-button
                v-tooltip="$t('inspeccionesOffline.imprimir')"
                icon-f7="printer"
                icon-size="18"
                color="primary"
                @click="downloadInforme(item)"
              ></f7-button> -->
              <f7-button
                v-tooltip="$t('inspeccionesOffline.visualizar')"
                :href="'/inspecciones/view-offline/'+item.id+'/'"
                icon-f7="eye"
                icon-size="18"
                color="green"
              ></f7-button>
              <f7-button
                v-tooltip="$t('inspeccionesOffline.eliminar')"
                icon-f7="trash"
                icon-size="18"
                color="red"
                @click.stop="borrarExpediente(item.id)"
              ></f7-button>
              <f7-button
                v-tooltip="$t('inspecciones.inspecciones.malo')"
                icon-f7="exclamationmark_triangle_fill"
                icon-size="18"
                color="orange"
                @click.stop="expedienteMalo(item)"
              ></f7-button>
              <f7-button
                v-if="item.idPedido"
                v-tooltip="$t('inspeccionesOffline.documentacion')"
                @click.stop="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                icon-f7="doc_plaintext"
                icon-size="18"
              ></f7-button>
              <span>SINCRONIZADO</span>
            </template>
          </td>
        </tr>
      </template>

      <template v-slot:default="{vlData}" v-else>
        <li v-for="(item) in vlData.items" :key="'inspeccion-'+item.id">
          <a :href="getItemURL(item)" class="item-link item-content" @click="clickItemURL(item)">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <template v-if="item.codigo">{{item.codigo}}</template>
                  <template v-else>{{item.cliente && item.cliente.razonSocial ? item.cliente.razonSocial : 'Sin Cliente'}}</template>
                </div>
                <div class="item-after">
                  <div
                    v-if="item.fechaInsp"
                    class="badge padding-vertical padding-left text-color-white"
                    style="padding-right:0;"
                  >
                    <span>{{item.fechaInsp | dateParse | dateFormat('DD/MM/YYYY')}}</span>
                    <span
                      class="badge color-blue padding margin-left-half"
                      style="border-radius:0 var(--f7-badge-size) var(--f7-badge-size) 0;"
                    >{{(item.hc1 || item.fechaInsp) | dateParse | dateFormat('HH:mm')}}</span>
                  </div>
                </div>
              </div>
              <div class="item-title-data">
                <div class="item-data">
                  <div class="item-subtitle" v-if="item.codigo">{{item.cliente ? item.cliente.razonSocial : 'Sin Cliente'}}</div>
                  <div class="item-subtitle" v-else>Pedido: {{item.numeroPedido ? item.numeroPedido : (item.codPedido ? item.codPedido : 'Sin Pedido')}} - {{typeof item.numeroLineaPedido != 'undefined' && item.numeroLineaPedido != null ? item.numeroLineaPedido : 'Sin Línea'}}</div>
                  <div
                    class="item-text"
                  >
                    {{item.direccionInspeccion ? item.direccionInspeccion.direccion : ''}} - {{item.direccionInspeccion ? item.direccionInspeccion.cp : ''}}
                  </div>
                  <div
                    class="item-text"
                  >
                    {{item.direccionInspeccion ? item.direccionInspeccion.poblacion : ''}} ({{item.direccionInspeccion ? item.direccionInspeccion.provinciaStr : ''}})
                  </div>
                  <div
                    v-if="item.codigo"
                    class="item-text"
                  >
                    Pedido: {{item.numeroPedido ? item.numeroPedido : (item.codPedido ? item.codPedido : 'Sin Pedido')}} - {{typeof item.numeroLineaPedido != 'undefined' && item.numeroLineaPedido != null ? item.numeroLineaPedido : 'Sin Línea'}}
                  </div>
                  <!-- <div
                    class="item-text"
                  >
                    Albarán: {{item.codigoAlbaran || 'Sin Albarán'}}
                  </div> -->
                  <div
                    class="item-text"
                  >
                    Servicio: {{item.servicio && item.servicio.nombre ? item.servicio.nombre : 'Sin Servicio'}}
                  </div>
                  <div
                    class="item-text"
                  >
                    Item: {{item.item && item.item.nombre ? item.item.nombre : 'Sin Item'}}
                  </div>
                  <div
                    class="item-text"
                  >
                    Formato: {{item.formato ? (item.formato.codigo + ' - ' + item.formato.nombre) : 'Sin Formato'}}
                  </div>
                  <r-badge :color="getColorCalificacion(item.calificacion)" :text="getValorCalificacion(item.calificacion) || 'Sin Calificación'"></r-badge>
                </div>
                <div class="item-after">
                  <template v-if="item.guardadoLocal">
                    <f7-button
                      v-tooltip="$t('inspeccionesOffline.eliminar')"
                      fill
                      icon-f7="trash"
                      color="red"
                      class="margin-right-half btn-inspeccion"
                      @click.stop="borrarExpediente(item.id)"
                    ></f7-button>
                    <f7-button
                      v-tooltip="$t('inspecciones.inspecciones.malo')"
                      icon-f7="exclamationmark_triangle_fill"
                      color="orange"
                      fill
                      class="margin-right-half  btn-inspeccion"
                      @click.stop="expedienteMalo(item)"
                    ></f7-button>
                    <f7-button
                      v-if="item.idPedido"
                      v-tooltip="$t('inspeccionesOffline.documentacion')"
                      @click.stop="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                      fill
                      icon-f7="doc_plaintext_fill"
                      class="margin-right-half  btn-inspeccion"
                    ></f7-button>
                    <f7-button
                      fill
                      icon-f7="cloud_upload"
                      color="green"
                      class="margin-right-half  btn-inspeccion"
                      @click.stop="syncUp([item])"
                    ></f7-button>
                  </template>
                  <template v-else-if="item.estado && item.estado.estado=='PLANIFICADO'">
                    <f7-button
                      v-if="item.idPedido"
                      v-tooltip="$t('inspeccionesOffline.documentacion')"
                      @click="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                      fill
                      icon-f7="doc_plaintext"
                      class="margin-right-half btn-inspeccion"
                    ></f7-button>
                  </template>
                  <template v-else>
                    <f7-button
                      v-tooltip="$t('inspeccionesOffline.eliminar')"
                      icon-f7="trash"
                      color="red"
                      class="btn-inspeccion"
                      @click.stop="borrarExpediente(item.id)"
                    ></f7-button>
                    <f7-button
                      v-tooltip="$t('inspecciones.inspecciones.malo')"
                      icon-f7="exclamationmark_triangle_fill"
                      color="orange"
                      class="btn-inspeccion"
                      @click.stop="expedienteMalo(item)"
                    ></f7-button>
                    <f7-button
                      v-if="item.idPedido"
                      v-tooltip="$t('inspeccionesOffline.documentacion')"
                      @click.stop="navigate('/pedidos/documentos/' + item.idPedido + '/')"
                      icon-f7="doc_plaintext"
                      class="btn-inspeccion"
                    ></f7-button>
                    <div class="break"></div>
                    <span class="btn-inspeccion">SINCRONIZADO</span>
                  </template>
                </div>
              </div>
            </div>
          </a>
        </li>
      </template>
      <template v-slot:empty>
        <f7-block v-if="anteriores">{{$t('inspecciones.inspecciones.sin_inspecciones_anteriores')}}</f7-block>
        <f7-block v-else>{{$t('inspecciones.inspecciones.sin_inspecciones_pendientes')}}</f7-block>
      </template>
    </r-list>
  </template>
</r-form>
</template>
<style>

  .inspecciones-offline .filtro-fecha-inspeccion .list .item-content {
    padding-left: 0;
    padding-right: 0;
  }

  .inspecciones-offline .filtro-fecha-inspeccion input {
    min-width: 110px;
  }


  .inspecciones-offline .list .item-inner {
    padding-bottom: 13px;
    padding-top: 13px;
  }

  .inspecciones-offline .list .item-title-data .item-after {
    align-self: center;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .inspecciones-offline .list .item-title-data {
    position: relative;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-right: calc(var(--f7-list-chevron-icon-area));
  } 

  .inspecciones-offline .btn-inspeccion i {
    width: 28px;
    height: 28px;
    font-size: 28x;
  }
  .inspecciones-offline .break {
    flex-basis: 100%;
    height: 0;
  }
  .inspecciones-offline .btn-inspeccion {
    margin-right: 16px;
    display: flex;
  }
  .inspecciones-offline span.btn-inspeccion {
    margin-top: 15px;
  }

  .inspecciones-offline .list .item-data {
    min-width: 0;
    flex-shrink: 1;
    white-space: var(--f7-list-item-title-white-space);
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .inspecciones-offline .rlist .card-header {
    padding-top: calc(var(--f7-navbar-height) + var(--f7-subnavbar-height) + 10px);
  }
  .inspecciones-offline .rlist .page-content {
    --f7-page-subnavbar-offset: 0;
  }
  .inspecciones-offline .rlist .data-table-header-selected {
    align-items: flex-end;
    flex: auto;
  }
  .inspecciones-offline .rlist .data-table-title-selected {
    flex: auto;
  }
  .contenedor-fecha-inspeccion {
    background: none !important;
  }
  .filtro-fecha-inspeccion input{
    color: white !important;
    font-weight: bold !important;
    width: 77px !important;
  }
  .fechaGris.filtro-fecha-inspeccion input {
    color: gray !important;
  }
  .filtro-fecha-inspeccion .input-clear-button{
    display: none;
  }
  .inspecciones-offline .subnavbar .subnavbar-title {
    font-size: 26px;
  }
</style>
<script>
import rBadge from "./../../components/rBadge.vue";
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Inspeccion from "./../../js/db/InspeccionOffline.js";
import Formato from "./../../js/db/FormatoOffline.js";
import Provincia from "./../../js/db/ProvinciaOffline.js";
import DatabaseModelOffline from "../../js/db/DatabaseModelOffline";
import Configuracion from '../../js/db/ConfiguracionOffline';
import Utils from '../../js/Utils';
import moment from 'moment';
import FileUtils from '../../js/FileUtils';

export default {
  components: {
    rList,
    rBadge,
    rForm,
    rInput,
    rAutocomplete,
    moment,
  },
  data() {
    return {
      Inspeccion,
      Formato,
      Provincia,
      Configuracion,
      DatabaseModelOffline,
      rPanelOpenedByBreakpoint: false,
      formatos: [],
      inspeccionPlanificada: null,
      fechaActivo: true,
      rowsPerPageInspecciones: 60,
      infiniteScrollInspecciones: true,
      mostrarListado: false,
      FileUtils,
      searchFormato: ''
    };
  },
  props: {
    anteriores: Boolean,
    id: String
  },
  computed: {},
  methods: {
    clickMostrarListado() {
      const self = this;
      self.mostrarListado = !self.mostrarListado;
      localStorage.setItem('mostrarListado', '' + self.mostrarListado);
    },
    clickItemURL(item) {
      const self = this;
      if (item.estado && item.estado.estado=='PLANIFICADO' && !item.guardadoLocal) {
        self.inspeccionPlanificada = item;
        self.$refs.popupnew.open();
      }
    },
    getItemURL(item) {
      const self = this;
      if (item.guardadoLocal){
        if (!item.sincronizado) {
          return `/inspecciones/edit-offline/${item.id}/`;
        }
        return `/inspecciones/view-offline/${item.id}/`;
      } else if (item.estado && item.estado.estado=='PLANIFICADO') {
        return '';
      } else {
        return `/inspecciones/view-offline/${item.id}/`;
      }
    },
    eliminarSeleccionados(selecteds) {
      var self = this;
      var app = self.$f7;

      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registros"),
        "Eliminar",
        function() {
          const total = Object.values(selecteds).length;
          var borrados = 0;
          Object.values(selecteds).forEach(selected => {
            self.borrarExpediente(selected.id, true, r => {
              borrados++;
              if (borrados >= total) {
                self.$refs.rlist.selected = {};
                self.$refs.checkGeneral.checked = false;
                self.$refs.rlist.reload();
              }
            });
          });
        }
      );
    
    },
    listaDocumentosExpediente (exp) {
      var listaDocumentos = [];

      var iterateComprobacion = function(comprobaciones) {
        for (let x = 0; x < comprobaciones.length; x++) {
          var comprobacion = comprobaciones[x];
          if (comprobacion.defectosSeleccionados && comprobacion.defectosSeleccionados.length) {
            for (var z = 0; z < comprobacion.defectosSeleccionados.length; z++) {
              var defecto = comprobacion.defectosSeleccionados[z];
              if (defecto.documentos && defecto.documentos.length) {
                for (var y = 0; y < defecto.documentos.length; y++) {
                  let doc = defecto.documentos[y];
                  listaDocumentos.push(doc);
                }
              }
            }
          }
          if (comprobacion.hijas && comprobacion.hijas.length) {
            iterateComprobacion(comprobacion.hijas);
          }
        }
      };

      if (exp.comprobaciones) {
        iterateComprobacion(exp.comprobaciones);
      }

      if (exp.documentos) {
        for (var j = 0; j < exp.documentos.length; j++) {
          let doc = exp.documentos[j];
          listaDocumentos.push(doc);
        }
      }

      if (exp.tabs) {
        for (var k = 0; k < exp.tabs.length; k++) {
          var tab = exp.tabs[k];
          if (tab.items) {
            for (var l = 0; l < tab.items.length; l++) {
              var elemento = tab.items[l];
              if (elemento) {
                if (elemento.comprobaciones) {
                  iterateComprobacion(elemento.comprobaciones);
                }
                if (elemento.documentos) {
                  for (var m = 0; m < elemento.documentos.length; m++) {
                    let doc = elemento.documentos[m];
                    listaDocumentos.push(doc);
                  }
                }
              }
            }
          }
        }
      }
      return listaDocumentos;
    },
    
    borrarExpediente(idExpediente, silencioso, callbackOk, callbackError) {
      var self = this;

      if(silencioso) {
        self._borrarExpediente (idExpediente, callbackOk, callbackError);
      } else {
        self.$f7.dialog.confirm(
          self.$t("rlist.pregunta_eliminar_registro"),
          "Eliminar", function () {
            self._borrarExpediente(idExpediente, callbackOk, callbackError);
          }
        );
      }
    },

    _borrarExpediente (idExpediente, callbackOk, callbackError) {
      const self = this;
      const app = self.$f7;
      Inspeccion.get({itemId: idExpediente})
      .then(function(expediente) {
        Inspeccion.delete({itemId: idExpediente})
          .then(function (res) {
            console.log("Resultado delete", res)
            Utils.moveInspeccionToBorrar(expediente, idExpediente);
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: self.$t("rlist.registro_eliminado"),
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
            self.$refs.rlist.removeItem(idExpediente);
            if (callbackOk) {
              callbackOk();
            }
          })
          .catch(function(e) {
            console.log("El catch de Inspeccion.delete");
            console.log(e);
            if (callbackError) {
              callbackError(e);
            }
          });
      })
      .catch(function(error) {
        if (callbackError) {
          callbackError(error);
        }
      });
    },
    navigate(url){
      var self = this;
      var app = self.$f7;
      app.views.current.router.navigate(url);
    },
    getFormatosProvincia(provincia) {
      const self = this;
      let formatos = !provincia || !provincia.codigo ? self.formatos : self.formatos.filter(f => !f.provincias || !f.provincias.length || f.provincias.filter(p => p.codigo == provincia.codigo).length);
      return !self.searchFormato ? formatos : formatos.filter(formato => {
        let search = (formato.codigo + ' - ' + formato.nombre).toUpperCase();
        return search.includes(self.searchFormato.toUpperCase());
      });
    },
    isCodFormatoInList(codFormato) {
      const self = this;
      var codsFormatos = self.formatos.map(f => f.codigo);
      if (codsFormatos.includes(codFormato)) {
        return true;
      } else {
        return false;
      }
    },
    verTodasInspecciones: function () {
      const self = this;
      self.rowsPerPageInspecciones = 10;
      self.infiniteScrollInspecciones = false;
      self.$refs.rlist.conditions = self.anteriores ? {'codigo': {'$exists': true}} : {'estado.estado': {'$in':['GENERADO', 'PLANIFICADO']}};
      self.$nextTick(_=> self.$refs.rlist.refresh());
      self.fechaActivo = false;

    },
    shiftDias: function(dias){
      var self = this,
        formData = self.$refs.rformFiltroInspecciones.formData,
        nuevaFecha = moment(formData.fechaInspecciones).add(dias,"day").format();
      formData.fechaInspecciones = nuevaFecha;
      self.updateFechaFiltro();      
    },
    diaAnterior: function(){
      var self = this;
      self.shiftDias(-1);
    },
    diaSiguiente: function(){
      var self = this;
      self.shiftDias(1);
    },
    irAHoy:function(){
      var self = this;
      self.$refs.rformFiltroInspecciones.formData.fechaInspecciones = new Date();
      self.updateFechaFiltro();
    },
    getValorCalificacion: function(calificacion) {
      if (!calificacion) {
        return null;
      }
      switch (calificacion) {
        case "FAVORABLE":
          return "Favorable";
        case "FAVORABLE_CON_DEFECTOS":
          return "Favorable con defectos";
        case "FAVORABLE_CON_REPARO":
          return "Favorable con reparo"; 
        case "DESFAVORABLE":
          return "Condicionada";
        case "NEGATIVO":
          return "Negativo";
        default:
          break;
      }
    },
    getColorCalificacion: function(calificacion) {
      if (!calificacion) {
        return null;
      }
      switch (calificacion) {
        case "FAVORABLE":
          return "green";
        case "FAVORABLE_CON_DEFECTOS":
          return "lime";
        case "DESFAVORABLE":
          return "orange";
        case "NEGATIVO":
          return "red";
        default:
          return "gray";
      }
    },
    popupNewOpen: function(form){
      var self = this;
      if(!form.formatoSeleccionado){
        self.$set(form,'formatoSeleccionado', self.inspeccionPlanificada && self.inspeccionPlanificada.formato ? self.inspeccionPlanificada.formato.codigo : null);
      }
    },
    continuarCreacion: function(formData) {
      var self = this;
      var app = self.$f7;
      var url = '#';
      if (self.inspeccionPlanificada && self.inspeccionPlanificada.idExpedienteRelacionado) {
        url = '/inspecciones/edit-offline/' + self.inspeccionPlanificada.id + '/';
      } else if (self.inspeccionPlanificada) {
        url = '/inspecciones/edit-offline/' + self.inspeccionPlanificada.id + '/' + formData.formatoSeleccionado + '/';
      } else {
        url = '/inspecciones/new-offline/' + formData.formatoSeleccionado + '/' + formData.provinciaSeleccionada.codigo + '/';
      }
      
      app.views.current.router.navigate(url);

    },
    downloadInforme: function(inspeccion) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.downloadInforme(inspeccion)
        .catch(function(e) {
          console.log(e);
        })
        .then(_ => app.preloader.hide());
    },
    downloadPreview: function(inspeccion) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Inspeccion.downloadPreview(inspeccion)
        .catch(function(e) {
          console.log(e);
        })
        .then(_ => app.preloader.hide());
    },
    syncUp: function(inspecciones) {
      var self = this;
      var app = self.$f7;
      if (!inspecciones || !inspecciones.length) {
        app.dialog.alert("No se ha seleccionado ninguna inspección para sincronizar.");
        return;
      }
      if (inspecciones.filter(i => !i.calificacion).length) {
        app.dialog.alert("Para sincronizar una inspección debe tener una calificación.");
        return;
      }

      app.dialog.confirm(
        "¿Seguro que quieres sincronizar la inspección seleccionada?",
        "Sincronizar inspección",
        function() {
          app.preloader.show();
          DatabaseModelOffline.syncUp({ data: inspecciones })
            .then(function() {
              app.preloader.hide();
              self.$refs.rlist.reload();
              app.toast
                .create({
                  text: "Inspeccion sincronizada",
                  position: "center",
                  closeTimeout: 2000,
                  icon: '<i class="f7-icons">checkmark_alt</i>'
                })
                .open();
            })
            .catch(function(e) {
              app.preloader.hide();
              if (e &&  e.error && e.error.code == 'ERROR_AUDITORIA') {
                var message = e.error.message + " :<ul>";
                e.error.errors.forEach( err => {message+= "<li>"+err+"</li>";})
                message+="</ul>";
                app.dialog.confirm(
                  message + " <br> ¿Desea abrir el expediente para corregirlo?",
                  function() {
                    let insp = inspecciones[e.error.aux[0]];
                    app.views.main.router.navigate("/inspecciones/edit-offline/" + (insp.id || insp._id) +"/");
                  }
                );
                console.log("error syncUp", e.error.message);
                return;
              }
              if (e && e.idExp) {
                app.dialog.confirm(
                  e.errores[0] + ", ¿Desea abrir el expediente para corregirlo?",
                  "Ha habido algún error de validación",
                  function() {
                    app.views.main.router.navigate("/inspecciones/edit-offline/" + e.idExp+"/");
                  }
                );
              } else {
                var message = "";
                if (typeof e == 'string') {
                  message = e;
                } else if (e.error && typeof e.error == 'string'){
                  message = e.error;
                } else {
                  message = e.error && e.error.message ? e.error.message : 'Error al sincronizar';
                }
                app.dialog.alert(message);
                console.log("error syncUp", e);

              }
            });
      });
    },
    updateFechaFiltro: function(){
      var self = this,
        filtro = self.$refs.rlist.filter,
        fechaInspecciones = self.$refs.rformFiltroInspecciones.formData.fechaInspecciones;
      filtro["fechaInsp"] = self._filtroFecha(fechaInspecciones);
      self.infiniteScrollInspecciones = true;
      self.rowsPerPageInspecciones = 60;
      self.$nextTick(_=> self.$refs.rlist.setFilter(filtro));
      self.fechaActivo = true;
    },
    _filtroFecha:function (fecha){
      var fechaInspeccionDesde = moment.utc(moment(fecha).startOf("day")).format('yyyy-MM-DDTHH:mm:ss.sssZZ');
      var fechaInspeccionHasta = moment.utc(moment(fecha).endOf("day")).format('yyyy-MM-DDTHH:mm:ss.sssZZ');
      var filtro = {$gte:fechaInspeccionDesde, $lte:fechaInspeccionHasta};
      return filtro;
    },
    expedienteMalo: function(expediente) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "Se va a reportar que este expediente está dando problemas de sincronización. ¿Desea continuar?",
        "Reportar expediente",
        function() {
          app.preloader.show();
          Inspeccion
          .get({ itemId: expediente.id })
          .then(function(res) {
            DatabaseModelOffline.expMalo(res)
              .then(function(res) {
                app.preloader.hide();
                app.dialog.alert("Se ha reportado el error de sincronización correctamente. Una vez analizado se intentará solucionar lo antes posible. Muchas gracias. ");
              })
              .catch(function(error) {
                app.preloader.hide();
                app.dialog.alert(error.message);
              });
          })
          .catch(function(e) {
            app.preloader.hide();
            console.log(e);
            app.dialog.alert(e.message);
          })
        });
    }
    
  },
  mounted: async function() {
    var self = this;
    var app = self.$f7;

    if(self.target == "cordova"){
      DatabaseModelOffline.createIndex('inspecciones', 'fechaInsp');
    }
    self.mostrarListado = localStorage.getItem('mostrarListado') && localStorage.getItem('mostrarListado') == 'true';
    /*self.Inspeccion.save({
      data: {
        codigo: "AAA",
        formato: { codigo: "FFF" },
        provincia: { nombre: "Alava" },
        fechaInsp: "2020-01-08"
      }
    });*/
    self.Formato.getList({projection:['id', 'codigo', 'nombre', 'provincias']}).then(function(res) {
      self.formatos = res.list;
    });
    self.irAHoy();

    if (self.id){
      // app.dialog.alert(self.id);

      // Buscar en BBDD el item que es.
      let item = await Inspeccion.get({ itemId: self.id });
      console.log(item);

      if (item == false){
        app.dialog.alert("Expediente no encontrado en la tablet, por favor realice una sincronización");
        return;
      }
      // El item que nos devuelve lo metemos en self.inspeccionPlanificada
      self.inspeccionPlanificada = item;

      // Abrimos el popupnew
      self.$refs.popupnew.open();

    }

    Configuracion.get({itemId: "CONFIG"})
      .then(result => {
        if (!Utils.isSync(result)) {
          app.views.main.router.navigate("/");
        }
      }).catch(_ => self.config = null);
  }
};
</script>
