<template>
<f7-page :page-content="true">
  <r-form
    ref="rform"
    class="page"
    classContent="page-content full-height"
    :itemId="null"
    :readonly="readonly"
    :dbAdapter="Planificacion"
    :defaultData="{inspectores:[], lineas:[], fechaVisita: getFechaVisita()}"
  >
    <!-- Top Navbar -->
    <template v-slot:before="{}">
      <f7-navbar :back-link="$t('atras')">
        <f7-nav-title sliding>Planificación de Líneas {{ esTpi  ? 'TPI' : ''}}</f7-nav-title>
        <f7-nav-right>
          <f7-button
            v-if="!esTpi"
            fill
            icon-f7="car_fill"
            text="Desplanificar"
            color="red"
            class="display-flex"
            @click="desplanificar($refs.rform.formData)"
          ></f7-button>
          <f7-button
            v-if="!esTpi"
            fill
            icon-f7="floppy_disk"
            text="Pdte Confirmar"
            class="display-flex"
            @click="planificar($refs.rform.formData, true)"
          ></f7-button>
          <f7-button
            fill
            icon-f7="floppy_disk"
            text="Planificar"
            class="display-flex"
            @click="planificar($refs.rform.formData, false)"
          ></f7-button>
        </f7-nav-right>
      </f7-navbar>
    </template>
    <template v-slot:default="{rform}">
    <f7-page>
      <f7-block-title>
        Seleccione las líneas a planificar
      </f7-block-title>
      <div class="item-content item-input item-input-with-value margin">
        <div class="item-inner">
          <div class="item-title item-label item-floating-label">Seleccionar / Deseleccionar todas:</div>
          <div class="item-input-wrap">
            <f7-toggle 
              class="item-input-wrap"
              @change="selectAllPopup($event.target.checked, rform.formData.lineas)"
            ></f7-toggle>
          </div>
        </div>
      </div>
      <Container 
        @drop="onDrop(rform.formData.lineas, $event)" 
        lock-axis="y"
        drag-handle-selector=".column-drag-handle"
      >
        <Draggable
          v-for="(item,index) in rform.formData.lineas"
          :key="'linea-'+index"
        >
          <f7-card class="elevation-3 padding-vertical-half padding-right">  
            <template v-if="esTpi">
              <div v-if="item.idPlanif && item.tipoPlani == 'TAREA' && item.tipoTarea && item.tipoTarea.codigo == 'T0005'" class="border-3 border-color-lime text-align-center margin-horizontal width-90">
                <div  id="block_container"> <div id="block1"> Fecha TPI: {{ getFechaPlanif(index) }} </div> <div id="block2"> TPI ya planificado para los inspectores:</div> {{ item.inspectores.map(i => i.username).join(',') }} </div>
              </div>
              <div v-else-if="getFechaPlanif(index)" class="text-align-center margin-horizontal width-90">
                <div  id="block_container"> <div id="block1"> Fecha TPI: {{ getFechaPlanif(index) }}  </div> <div id="block2"></div></div>
              </div>
            </template>
            <template v-else>
              <div v-if="item.inspectorUsername && item.inspectores && item.inspectores.length" class="border-3 border-color-lime text-align-center margin-horizontal width-90">
                <div  id="block_container"> <div id="block1"> Fecha Inspección: {{getFechaPlanif(index)}} </div> <div id="block2"> Inspección ya planificada para los inspectores:</div> {{item.inspectores.map(i => i.username).join(',')}} </div>
              </div>
              <div v-else-if="item.lineas && item.lineas.inspectores && item.lineas.inspectores.length" class="border-3 border-color-lime text-align-center margin-horizontal width-90">
                <div  id="block_container"> <div id="block1"> Fecha Inspección: {{getFechaPlanif(index)}}  </div> <div id="block2"> Inspección ya planificada para los inspectores:</div> {{item.lineas.inspectores.map(i => i.username).join(',')}}</div>
              </div>
              <div v-else-if="getFechaPlanif(index)" class="text-align-center margin-horizontal width-90">
                <div  id="block_container"> <div id="block1"> Fecha Inspección: {{getFechaPlanif(index)}}  </div> <div id="block2"></div></div>
              </div>
              <div v-if="item.tiempoLibre && item.tiempoLibre.value < 0" class="border-3 border-color-red text-align-center margin-horizontal width-90">
                <b>No llega a tiempo:</b> {{item.tiempoLibre.text}}
              </div>

              <div v-if="item.desplazamiento" class="border-3 border-color-gray text-align-center margin-horizontal width-90">
                <b>Desplazamiento <span v-if="index == 0">desde el inicio</span>:</b> Tiempo: {{item.desplazamiento.duration.text}},  Distancia: {{item.desplazamiento.distance.text}}<br v-if="index == 0">
                <span v-if="index == 0"> {{rform.formData.direccionInicio}}</span>
              </div>
              <div v-if="item.tiempoLibre && item.tiempoLibre.value > 0" class="border-3 border-color-orange text-align-center margin-horizontal width-90">
                <b>Tiempo Libre:</b> {{item.tiempoLibre.text}}
              </div>
            </template>
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row no-gap>
                    <span 
                      class="column-drag-handle margin-left cursor-move col-100"
                      v-tooltip="$t('ofertas.titulo')"
                    >&uarr;&darr;</span>
                    <f7-list-input
                      class="col-100 medium-5"
                      floating-label
                      :label="$t('pedidos.posicion')"
                      :value="(index + 1) + (item.tipo == 'QUEDADA' ? '⋆' : '')"
                      v-tooltip="$t('pedidos.posicion') + ':<br>' + (index + 1) + (item.tipo == 'QUEDADA' ? '⋆' : '')"
                      autocomplete="off"
                      :readonly="true"
                    >
                    </f7-list-input>
                    <r-input
                      class="col-100 medium-10"
                      :label="$t('pedidos.seleccionar')"
                      :form="rform"
                      type="check"
                      :readonly="item.idPlanif && item.tipoPlani == 'TAREA' && item.tipoTarea && item.tipoTarea.codigo == 'T0005' ? true : false"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.seleccionada'"
                      @change="setMapPosition(); initRoutesMap();"
                    ></r-input>
                    <f7-list-input
                      v-if="item.tipo == 'QUEDADA'"
                      class="col-100 medium-40"
                      floating-label
                      :label="$t('pedidos.direccion')"
                      :value="getDireccionStr(item.lineas.direccionInspeccion)"
                      v-tooltip="$t('pedidos.direccion') + ':<br>' + getDireccionStr(item.lineas.direccionInspeccion)"
                      autocomplete="off"
                      :readonly="true"
                    >
                    </f7-list-input>
                    <r-input
                      v-if="item.tipo == 'QUEDADA'"
                      class="col-100 medium-25"
                      floating-label
                      :label="$t('pedidos.comentarios')"
                      :form="rform"
                      :readonly="false"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.comentariosPlanificacion'"
                      v-tooltip="$t('pedidos.comentarios') + ':<br>' + item.comentariosPlanificacion"
                    ></r-input>
                    <f7-list-input
                      outline
                      v-if="item.tipo == 'LINEA' && !esTpi"
                      class="col-100 medium-20"
                      floating-label
                      :label="$t('pedidos.direccion')"
                      :value="getDireccionStr(item.lineas.direccionInspeccion)"
                      v-tooltip="$t('pedidos.direccion') + ':<br>' + getDireccionStr(item.lineas.direccionInspeccion)"
                      autocomplete="off"
                      @change="onChangeDireccion($event.target.value, index)"
                      :readonly="false"
                    >
                    </f7-list-input>
                    <f7-list-input
                      v-if="item.tipo == 'LINEA'"
                      class="col-100 medium-15"
                      floating-label
                      :label="$t('pedidos.cliente')"
                      :value="item.cliente ? item.cliente.razonSocial : ''"
                      v-tooltip="$t('pedidos.cliente') + ':<br>' + (item.cliente ? item.cliente.razonSocial : '')"
                      autocomplete="off"
                      :readonly="true"
                    >
                    </f7-list-input>
                    <f7-list-input
                      v-if="item.tipo == 'LINEA'"
                      class="col-100 medium-10"
                      floating-label
                      :label="$t('pedidos.pedido')"
                      :value="item.numero || item.codigo"
                      v-tooltip="$t('pedidos.pedido') + ':<br>' + (item.numero || item.codigo)"
                      autocomplete="off"
                      :readonly="true"
                    >
                    </f7-list-input>
                    <f7-list-input
                      v-if="item.tipo == 'LINEA'"
                      class="col-100 medium-5"
                      floating-label
                      :label="$t('pedidos.linea')"
                      :value="item.lineas.numero"
                      v-tooltip="$t('pedidos.linea') + ':<br>' + item.lineas.numero"
                      autocomplete="off" 
                      :readonly="true"
                    >
                    </f7-list-input>
                    <f7-list-input
                      v-if="item.tipo == 'LINEA'"
                      class="col-100 medium-15"
                      floating-label
                      :label="$t('pedidos.servicio')"
                      :value="(item.lineas.servicio ? item.lineas.servicio.nombre : '')"
                      v-tooltip="$t('pedidos.servicio') + ':<br>' + (item.lineas.servicio ? item.lineas.servicio.nombre : '')"
                      autocomplete="off"
                      :readonly="true"
                    >
                    </f7-list-input>
                    <r-input
                      v-if="!esTpi"
                      class="col-100 medium-10"
                      floating-label
                      :label="$t('pedidos.tiempoEstimadoCampo')"
                      :form="rform"
                      type="number"
                      step="1"
                      :readonly="false"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.lineas.tiempoEstimadoCampo'"
                      @change="calcularHoras()"
                    ></r-input>
                    <r-input
                      v-if="esTpi"
                      class="col-100 medium-10"
                      floating-label
                      :label="'Tiempo estimado TPI'"
                      :form="rform"
                      type="number"
                      step="1"
                      :readonly="false"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.lineas.tiempoEstimadoOficina'"
                      @change="calcularHoras()"
                    ></r-input>
                    <r-timepicker
                      ref="tpHoraInicio"
                      floating-label
                      class="col-100 medium-10"
                      :label="$t('pedidos.hora_inicio')"
                      :form="rform"
                      :readonly="false"
                      :fechaBase="getFechaVisita()"
                      :overrideReadonly="true"
                      :name="'lineas.' + index + '.horaInicio'"
                      v-tooltip="$t('pedidos.hora_inicio') + ':<br>' + getHourFormat(item.horaInicio)"
                      @change="changeHour(item, $event)"
                      @input:clear="clearHoraInicio(index);"
                      :clearButton="true"
                      :scrollPicker="false"
                    ></r-timepicker>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-link
              v-tooltip="'Borrar'"
              v-if="item.tipo == 'QUEDADA' && !!!item.inspectorUsername"
              @click="$delete(rform.formData.lineas,index);"
              class="margin-half"
              style="position:absolute;right:0;top:0;"
              icon-f7="trash"
              key="trashicon"
            ></f7-link>
            <f7-link
              external
              v-tooltip="'Ver Pedido'"
              v-else-if="item.tipo == 'LINEA'"
              class="margin-half"
              icon-f7="envelope_open_fill"
              style="position:absolute;right:0;top:0;"
              color="green"
              :href="'/#!/pedidos/view/' + (item.idPedido || item.id) + '/'"
              key="verpedidoicon"
            ></f7-link>
            <f7-link
              external
              v-tooltip="'Ver Tarea'"
              v-if="item.tipoPlani == 'TAREA' && item.idTarea"
              class="margin-half"
              icon-f7="envelope_open_fill"
              style="position:absolute;right:0;top:0;"
              color="green"
              :href="'/#!/tarea/view/' + item.idTarea + '/'"
              key="vertareaicon"
            ></f7-link>
            <f7-link
              external
              v-tooltip="'Línea de segundas'"
              v-if="item.lineas.idExpedienteRelacionado"
              class="margin-half"
              icon-material="looks_two"
              style="position:absolute;right:25px;top:0;"
              key="lineasegundasicon"
            ></f7-link>
            <f7-link
              external
              v-tooltip="'Línea de Nueva Primera'"
              v-if="item.lineas.idPedidoAnteriorNegativa"
              class="margin-half"
              icon-material="looks_one"
              style="position:absolute;right:25px;top:0;color: red;"
              key="lineanuevaprimeraicon"
            ></f7-link>
            <f7-link
              external
              v-tooltip="'Ver Expediente'"
              v-if="item.idExpediente || item.lineas.idExpediente"
              class="margin-half"
              style="position:absolute;right:25px;top:0;"
              icon-f7="checkmark_seal"
              color="green"
              :href="'/#!/inspecciones/view/' + (item.idExpediente || item.lineas.idExpediente) + '/'"
              key="verexpedienteicon"
            ></f7-link>
            <f7-link
              v-tooltip="'Borrar inspector asignado'"
              v-if="((item.inspectores && item.inspectores.length) || (item.lineas.inspectores && item.lineas.inspectores.length)) &&  ((item.idPedido) || (item.lineas.idPedido))"
              @click="
                lineaSeleccionada = item;
                popupBorrarInspectorOpened = true;
                $nextTick(function() {
                  $refs.popupBorrarInspector.open();
                });"
              class="margin-half"
              style="position:absolute;right:50px;top:0;"
              icon-f7="car_fill"
              color="red"
              key="borrarInspectoricon"
            ></f7-link>
            <div v-if="item.desplazamientoFin" class="border-3 border-color-gray text-align-center margin-horizontal width-90">
              <b>Desplazamiento hasta el fin:</b> Tiempo: {{item.desplazamientoFin.duration.text}},  Distancia: {{item.desplazamientoFin.distance.text}}<br>
              <span> {{rform.formData.direccionFin}}</span>
            </div>
          </f7-card>
        </Draggable>
      </Container>
      <f7-button
        v-if="!esTpi"
        fill
        icon-f7="route"
        text="Calcular Ruta"
        class="col-100 display-flex margin-half"
        color="orange"
        @click="calcRoute(rform.formData.lineas)"
      ></f7-button>
      <f7-block-title>
        Datos de planificación
      </f7-block-title>
      <f7-card class="elevation-3 padding-vertical-half padding-right"
        :style="'z-index:'+(rform.formData.lineas.length * 3)"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="60" v-if="!esTpi">
                  <f7-row no-gap >
                    <f7-block-title class="col-100">
                      Mapa
                    </f7-block-title>
                    <f7-list-input
                      :readonly="false"
                      floating-label
                      :label="$t('pedidos.direccion')"
                      type="text"
                      class="col-100 medium-40"
                      :value="direccionBuscar"
                      @input="direccionBuscar = $event.target.value"
                      v-tooltip="$t('pedidos.direccion')"
                    ></f7-list-input>
                    <f7-list-input
                      :readonly="false"
                      floating-label
                      :label="$t('pedidos.comentarios')"
                      type="text"
                      class="col-100 medium-40"
                      :value="comentariosBuscar"
                      @input="comentariosBuscar = $event.target.value"
                      v-tooltip="$t('pedidos.comentarios')"
                    ></f7-list-input>
                    <f7-list-input
                      :readonly="false"
                      floating-label
                      :label="$t('pedidos.tiempo')"
                      type="text"
                      class="col-100 medium-20"
                      :value="tiempoBuscar"
                      @input="tiempoBuscar = $event.target.value"
                      v-tooltip="$t('pedidos.tiempo')"
                    ></f7-list-input>
                    <f7-button
                      fill
                      icon-f7="search"
                      text="Añadir"
                      class="col-100 medium-70 display-flex margin-half"
                      color="blue"
                      @click="buscarDireccion(direccionBuscar, comentariosBuscar, tiempoBuscar)"
                    ></f7-button>
                    <f7-button
                      fill
                      icon-f7="dot_square"
                      text="Centrar"
                      class="col-100 medium-20 display-flex margin-half"
                      color="gray"
                      @click="setMapPosition"
                    ></f7-button>
                    <gmap-map ref="gmap" :center="mapCenter" :zoom="mapZoom" class="col-100 margin-half" style="height: 400px"
                      :options="mapOptions"  
                    >
                      <gmap-marker v-if="direccionInicioInformada && rform.formData.direccionInicio"
                        :position="rform.formData.longitudInicio && rform.formData.latitudInicio ? 
                          {lng: rform.formData.longitudInicio, lat: rform.formData.latitudInicio} : 
                          (rform.formData.callingGeocodeInicio ? null : getGeocode(null, null, true))" 
                        :options="{
                          clickable: true, 
                          draggable: true,
                          label: 'I',
                        }"
                        @click="$set(rform.formData, 'inicioOpened', !rform.formData.inicioOpened)"
                        @dragend="ondragend($event, null, true)"
                      >

                        <gmap-info-window :opened="rform.formData.inicioOpened || false" @closeclick="rform.formData.inicioOpened=false">
                          <div v-html="'<b>Direccion inicio</b>' + 
                            '<p>' + rform.formData.direccionInicio + '</p>'
                          "></div>
                        </gmap-info-window>
                      </gmap-marker>
                      <div
                        v-for="(linea, index) in rform.formData.lineas"
                        :key="'linea-marker'+index"
                      >
                        <gmap-marker v-if="linea.seleccionada && (linea.tipo == 'LINEA' || linea.tipo == 'QUEDADA')"
                          :position="linea.lineas.direccionInspeccion && linea.lineas.direccionInspeccion.longitud && linea.lineas.direccionInspeccion.latitud ? 
                            {lng: linea.lineas.direccionInspeccion.longitud, lat: linea.lineas.direccionInspeccion.latitud} : 
                            (linea.lineas.callingGeocode ? null : getGeocode(linea, index))" 
                          :options="{
                            clickable: true, 
                            draggable: true,
                            label: linea.tipo == 'LINEA' ? (index + 1).toString() : (index + 1) + '⋆',
                          }"
                          @click="$set(rform.formData.lineas[index], 'opened', !linea.opened)"
                          @dragend="ondragend($event, index)"
                        >

                          <gmap-info-window :opened="linea.opened || false" @closeclick="linea.opened=false">
                            <div v-if="linea.tipo == 'LINEA'" v-html="'<b>Número de Pedido: ' + linea.numero + '</b><br>' + 
                              '<span> Número de Línea: ' + linea.lineas.numero + '</span><br>' +
                              '<span> Cliente: ' + linea.cliente.razonSocial + '</span><br>' +
                              '<span> Servicio: ' + (linea.lineas.servicio ? linea.lineas.servicio.nombre : '') + '</span><br>' +
                              '<span> Item: ' + (linea.lineas.item ? linea.lineas.item.nombre : '') + '</span><br>' +
                              '<span> Tiempo en campo: ' + linea.lineas.tiempoEstimadoCampo + ' min.</span>' +
                              '<p>' + getDireccionStr(linea.lineas.direccionInspeccion) + '</p>'
                            "></div>
                            <div v-else-if="linea.tipo == 'QUEDADA'" v-html="'<b>Direccion donde quedar con el cliente</b><br>' + 
                              '<span>' + linea.comentariosPlanificacion + '</span>' +
                              '<p>' + getDireccionStr(linea.lineas.direccionInspeccion) + '</p>'
                            "></div>
                          </gmap-info-window>
                        </gmap-marker>
                      </div>
                      <gmap-marker v-if="direccionFinInformada && rform.formData.direccionFin"
                        :position="rform.formData.longitudFin && rform.formData.latitudFin ? 
                          {lng: rform.formData.longitudFin, lat: rform.formData.latitudFin} : 
                          (rform.formData.callingGeocodeFin ? null : getGeocode(null, null, false))" 
                        :options="{
                          clickable: true, 
                          draggable: true,
                          label: 'F',
                        }"
                        @click="$set(rform.formData, 'finOpened', !rform.formData.finOpened)"
                        @dragend="ondragend($event, null, false)"
                      >

                        <gmap-info-window :opened="rform.formData.finOpened || false" @closeclick="rform.formData.finOpened=false">
                          <div v-html="'<b>Direccion fin</b>' + 
                            '<p>' + rform.formData.direccionFin + '</p>'
                          "></div>
                        </gmap-info-window>
                      </gmap-marker>
                    </gmap-map>
                    <f7-button
                      fill
                      icon-f7="route"
                      text="Calcular Ruta"
                      class="col-100 display-flex margin-half"
                      color="orange"
                      @click="calcRoute(rform.formData.lineas)"
                    ></f7-button>
                  </f7-row>
                </f7-col>
                <f7-col width="100" medium="40">
                  <f7-row no-gap>
                    <!-- Inspectores -->
                    <f7-block-title class="col-100" v-if="rform && rform.formData" style="z-index: 30">
                      {{$t('pedidos.inspectores')}}
                      <f7-link
                        v-if="!esTpi || !rform.formData.inspectores.length"
                        @click="rform.formData.inspectores.push({rol: 'INSPECTOR'})"
                      >{{$t('pedidos.add')}}</f7-link>
                    </f7-block-title>

                    <div v-if="rform && rform.formData" style="z-index: 30" class="col-100">
                      <f7-card
                        class="elevation-3 padding-vertical-half padding-right"
                        v-for="(item,index) in rform.formData.inspectores"
                        :key="'inspectores-'+index"
                        :style="'z-index:'+(rform.formData.inspectores.length - index)"
                      >
                        <f7-card-content>
                          <f7-list class="list-form">
                            <ul class="no-margin no-padding">
                              <f7-row no-gap>
                                <f7-link 
                                  v-if="item.username" 
                                  v-tooltip="inspectoresAdded.indexOf(item.username) > -1 ? 'Dejar de ver Planificación Inspector' : 'Ver Planificación Inspector'" 
                                  class="col-100 medium-5 margin-top" 
                                  :color="inspectoresAdded.indexOf(item.username) > -1 ? 'green' : ''"
                                  @click="anadirInspector(item)"
                                >
                                  <f7-icon f7="car_fill"></f7-icon>
                                </f7-link>
                                <r-autocomplete
                                  :dbAdapter="Inspector"
                                  class="col-100 medium-45"
                                  floating-label
                                  :label="$t('pedidos.inspector')"
                                  :form="rform"
                                  :name="'inspectores.' + index + '.username'"
                                  labelProp="nombre"
                                  :readonly="false"
                                  :overrideReadonly="true"
                                  idProp="username"
                                  prefixProp="username"
                                  idFieldDetail="id"
                                  :shownFields="['direccionInicio', 'horaInicio']"
                                  @change="onChangeInspector($event, index)"
                                ></r-autocomplete>
                                <r-input
                                  class="col-100 medium-50"
                                  floating-label
                                  :label="$t('pedidos.rol')"
                                  :form="rform"
                                  type="select"
                                  :readonly="false"
                                  :overrideReadonly="true"
                                  :name="'inspectores.' + index + '.rol'"
                                >
                                  <option value="INSPECTOR">{{$t('pedidos.inspector')}}</option>
                                  <option value="SUPERVISADO">{{$t('pedidos.inspectorSupervisado')}}</option>
                                  <option value="OBSERVADOR">{{$t('pedidos.observador')}}</option>
                                  <option value="SUPERVISOR">{{$t('pedidos.supervisor')}}</option>
                                  <option value="SUPERVISADO_FORMACION">{{$t('pedidos.supervisadoFormacion')}}</option>
                                </r-input>
                              </f7-row>
                            </ul>
                          </f7-list>
                        </f7-card-content>
                        <f7-link
                          v-if="rform.formData"
                          @click="$delete(rform.formData.inspectores, index);"
                          class="margin-half"
                          style="position:absolute;right:0;top:0;"
                          icon-f7="trash"
                        ></f7-link>
                      </f7-card>
                    </div>
                    <!-- Inspectores -->
                    <f7-block-title class="col-100" v-if="rform && rform.formData">
                      {{$t('pedidos.inspectores')}}
                      <f7-link
                        v-if="!esTpi || !rform.formData.inspectores.length"
                        @click="rform.formData.inspectores.push({rol: 'INSPECTOR'})"
                      >{{$t('pedidos.add')}}</f7-link>
                    </f7-block-title>
                    <r-input
                      floating-label
                      class="col-100 medium-50"
                      :label="$t('pedidos.fecha_visita')"
                      :form="rform"
                      type="datepicker"
                      name="fechaVisita"
                      :readonly="false"
                      :overrideReadonly="true"
                      :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                      ref="fechaVisita"
                      @change="changeFechaVisita()"
                    ></r-input>
                    <r-timepicker
                      floating-label
                      class="col-100 medium-50"
                      :label="$t('pedidos.hora_primera_visita')"
                      :form="rform"
                      :readonly="!$refs.fechaVisita"
                      :overrideReadonly="true"
                      name="fechaVisita"
                      @change="calcularHoras()"
                      :clearButton="true"
                    ></r-timepicker>
                    <r-input
                      v-if="rform.formData.inspectores && rform.formData.inspectores.length && !esTpi"
                      class="col-100 medium-50"
                      floating-label
                      :label="'Dirección Inicio'"
                      :form="rform"
                      :readonly="false"
                      :overrideReadonly="true"
                      name="direccionInicio"
                      @blur="direccionInicioInformada = true; rform.formData.longitudInicio = null; rform.formData.latitudInicio = null; "
                      @keyup.enter="direccionInicioInformada = true; rform.formData.longitudInicio = null; rform.formData.latitudInicio = null; "
                    ></r-input>
                    <r-input
                      v-if="rform.formData.inspectores && rform.formData.inspectores.length && !esTpi"
                      class="col-100 medium-50"
                      floating-label
                      :label="'Dirección Fin'"
                      :form="rform"
                      :readonly="false"
                      :overrideReadonly="true"
                      name="direccionFin"
                      @blur="direccionFinInformada = true; rform.formData.longitudFin = null; rform.formData.latitudFin = null; "
                      @keyup.enter="direccionFinInformada = true; rform.formData.longitudFin = null; rform.formData.latitudFin = null; "
                    ></r-input>
                    <r-input
                      class="col-100"
                      floating-label
                      :label="$t('pedidos.observaciones')"
                      :form="rform"
                      :readonly="false"
                      :overrideReadonly="true"
                      type="textarea"
                      name="observacionesPlanificar"
                    ></r-input>
                  </f7-row>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <!-- <f7-row>
        <f7-col width="100" medium="100">
          <f7-button
            fill
            icon-f7="floppy_disk"
            text="Planificar"
            class="display-flex margin-half"
            color="green"
            @click="planificar(rform.formData)"
          ></f7-button>
        </f7-col>
      </f7-row> -->
      <!-- </div> -->
    </f7-page>
    </template>
  </r-form>
  <r-form
    ref="rformBorrarInspector"
    v-slot="{rform}"
    @save="onSaveBorrarInspector"
    :dbAdapter="null"
  >
    <f7-popup ref="popupBorrarInspector" 
      @popup:closed="popupBorrarInspectorOpened = false"
      v-if="popupBorrarInspectorOpened" 
      swipe-to-close
    >
      <f7-page>
        <f7-navbar title="¿Qué inspector desea borrar la asignación?">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-autocomplete
                      outline
                      floating-label
                      :dbAdapter="null"
                      label="Inspector"
                      class="col-100"
                      :form="rform"
                      :name="'inspector'"
                      labelProp="username"
                      idProp="username"
                      :dbItems="getItemsInspectoresBorrar(lineaSeleccionada)"
                    >
                    </r-autocomplete>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                  fill
                  icon-f7="disk"
                  text="Borrar"
                  class="display-flex margin-vertical margin-left"
                  color="red"
                  @click="rform.saveItem()"
                ></f7-button>
            </f7-col>
          </f7-row>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </r-form>
</f7-page>
</template>
<style>

</style>
<style scoped>

.width-90 {
  width: 90%;
}
ul.no-padding ul {
  padding: 0 !important;
}
.border-3 {
  border-radius: 20px;
  border: 3px solid;
}
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.label-group-inspeccion {
  margin-top: 30px;
}
label.btn {
  padding: 1px;
  cursor: pointer;
  border: 1px solid;
}
.first-col {
  padding-left: 20px;
}
.first-col:before {
    font-family: 'framework7-core-icons';
    position: absolute;
    top: 50%;
    font-size: 20px;
    color: var(--f7-list-chevron-icon-color);
    pointer-events: none;
    content: var(--f7-accordion-chevron-icon-down);
    margin-top: -4px;
    line-height: 8px;
    transform: translateX(-50%);
    left: calc((var(--f7-list-chevron-icon-area) + var(--f7-list-item-padding-horizontal)) / 2 + var(--f7-safe-area-left));
}
#block_container
{
    display: flex;
}
#block1
{
    margin-left: 20px;
    font-weight: bold
}
#block2
{
    margin-left: 300px;
    margin-right: 10px;
    font-weight: bold;

}
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rCuenta from "./../../components/rCuenta.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Pedido from "./../../js/db/Pedido.js";
import Planificacion from "./../../js/db/Planificacion.js";
import Empleado from "./../../js/db/Empleado.js";
import Inspector from "./../../js/db/Inspector.js";
import DateUtils from "./../../js/DateUtils.js";
// Google Maps
import * as GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: 'places', // This is required if you use the Autocomplete plugin
        region: 'ES',
        language: 'es',
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    autobindAllEvents: true,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});
import {gmapApi} from 'gmap-vue';
import { Container, Draggable } from "vue-smooth-dnd";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  //return result;
};

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rDireccion,
    rCuenta,
    Container,
    Draggable,
  },
  data: function() {
    var self = this;
    return {
      Pedido,
      Planificacion,
      Empleado,
      Inspector,
      readonly: !this.editable,
      itemsInstalacion: [],
      isTitularOpen: false,
      openedInstalacion: {},
      idData: null,
      direccionBuscar: '',
      comentariosBuscar: '',
      tiempoBuscar: 0,
      DateUtils,
      lineaSeleccionada: null,
      popupBorrarInspectorOpened: false,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true
      },
      mapCenter: { lat: 40.416775,lng: -3.703790},
      mapZoom: 5,
      jsonData: undefined,
      directionsService: null,
      directionsRenderer: null,
      polylines: [],
      polylineOptions: {
        strokeColor: '#23355b',
        strokeOpacity: 0.4,
        strokeWeight: 6
      },
      map: undefined,
      infowindowRoute: undefined,
      inspectoresAdded: [],
      lastCallGeocode: 0,
      direccionInicioInformada: false,
      direccionFinInformada: false,
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    esTpi: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalesLineas: function() {
      var self = this;
      if(!self.$refs.rform || !self.$refs.rform.formData){
        return [];
      }
      return self.$refs.rform.formData.lineas.map(linea => {
        var totales = self.getTotalesLinea(linea);
        return totales;
      });
    },
    google: gmapApi
  },
  beforeDestroy() {},
  mounted() {
    var self = this;
    var app = self.$f7;
    if(self.$f7route.query.jsonData) {
      var jsonData = JSON.parse(self.$f7route.query.jsonData);
      jsonData.forEach(j => j.esTpi = self.esTpi);
      app.preloader.show();
      Pedido.getPlanificar({ data: jsonData })
        .then(function(res) {
          if (res) {
            var lineas = res;
            if (!self.esTpi) {
              lineas.forEach(l => l.tipo = "LINEA");
            }
            self.$set(self.$refs.rform.formData, 'lineas', lineas);
          }
        })
        .catch(function(error) {
          app.dialog.alert(error.message);
        }).finally(function() {
          app.preloader.hide();
        });
    }
  },
  methods: {
    onChangeInspector(inspector, index) {
      const self = this;
      self.getDiario();
    },
    getDiario() {
      const self = this;
      self.direccionInicioInformada = false;
      self.direccionFinInformada = false;
      let inspe = self.$refs.rform.formData.inspectores;
      let fVisita = self.$refs.rform.formData.fechaVisita;
      if (inspe && inspe.length && inspe[0].username && fVisita) {
        Planificacion
          .getDiario({
            data: {
              dia: fVisita,
              inspector: inspe[0].username
            }
          })
          .then(function(res) {
            if (res) {
              self.$set(self.$refs.rform.formData, 'direccionInicio', res.origen);
              self.$set(self.$refs.rform.formData, 'direccionFin', res.destino);
              self.direccionInicioInformada = !!res.origen;
              self.direccionFinInformada = !!res.destino;
              // let ini = new Date(res.horaInicio);
              // fVisita = self.$refs.rform.formData.fechaVisita;
              // fVisita.setHours(ini.getHours());
              // fVisita.setMinutes(ini.getMinutes());
              // fVisita.setSeconds(0);
              // self.$set(self.$refs.rform.formData, 'fechaVisita', fVisita);
            }
          })
          .catch(function(e) {
            app.preloader.hide();
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
            self.getPlanificaciones();
          });
      }
    },  
    onChangeDireccion(direccion, index) {
      const self = this;
      self.$set(self.$refs.rform.formData.lineas[index].lineas, 'callingGeocode', false);
      self.$set(self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion, 'direccionStr', direccion);
      self.$set(self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion, 'longitud', null);
      self.$set(self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion, 'latitud', null);
      self.$set(self.$refs.rform.formData.lineas[index].lineas, 'seleccionada', false);
      self.$nextTick(_ => self.$set(self.$refs.rform.formData.lineas[index].lineas, 'seleccionada', true));
    },
    onSaveBorrarInspector: function(res) {
      const self = this;
      const app = self.$f7;
      if (!res.inspector) {
        app.dialog.alert("Seleccione un inspector a borrar.");
        return;
      }

      self.$f7.dialog.confirm(
        "¿Está seguro que desea borrar el inspector seleccionado para la linea planificada?",
        "Advertencia",
        function() {
          var idPlani = null;
          var idPedido = null;
          var numLinea = null;
          var esQuedada = false;
          if (self.lineaSeleccionada.tipo == "QUEDADA") {
            idPlani = self.lineaSeleccionada.id;
            esQuedada = true;
          } else {
            idPedido = self.lineaSeleccionada.idPedido;
            numLinea = self.lineaSeleccionada.lineas.numero;
            idPlani = self.lineaSeleccionada.idPlanif || self.lineaSeleccionada.id;
          }

          app.preloader.show();
          Planificacion.borrarInspector({data: {inspector: res.inspector, idPlani: idPlani, idPedido: idPedido, numeroLinea: numLinea, esQuedada: esQuedada}}).then(_ => {

            var inspectores = self.lineaSeleccionada.inspectores || self.lineaSeleccionada.lineas.inspectores
            if (!inspectores || inspectores.length <= 1) {
              self.lineaSeleccionada.idExpediente = null;
              self.lineaSeleccionada.lineas.idExpediente = null;
              if (self.lineaSeleccionada.tipo == "QUEDADA") {
                var idxBorrar = self.$refs.rform.formData.lineas.indexOf(self.lineaSeleccionada);
                self.$refs.rform.formData.lineas.splice(idxBorrar, 1);
              }
              self.lineaSeleccionada.idPlanif = null;
              self.lineaSeleccionada.idTarea = null;
              self.lineaSeleccionada.tipoTarea = null;
              self.lineaSeleccionada.tipoPlani = null;
            } 
            if (inspectores) {
              var insp = inspectores.filter(i => i.username == res.inspector)[0];
              var idxBorrar = inspectores.indexOf(insp);
              inspectores.splice(idxBorrar, 1);
            }
            self.$refs.popupBorrarInspector.close();
            self.$refs.rformBorrarInspector.clear();
            self.popupBorrarInspectorOpened = false;
            self.lineaSeleccionada = null;
            app.dialog.alert("Inspector borrado correctamente.");

          }).catch(e => {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          }).then(_ => app.preloader.hide());

          
        }
      );

      // self.cambiarEstado('ENVIADA', res.formaEnvio);
    },
    getItemsInspectoresBorrar(item) {
      if (!item) {
        return [];
      } else {
        return item.inspectores || item.lineas.inspectores;
      }
    },
    changeFechaVisita() {
      const self = this;
      // self.borrarLineasInspectores();
      let lineas = self.$refs.rform.formData.lineas;
      let fechaVisita = self.$refs.rform.formData.fechaVisita || self.getFechaVisita();
      // var lineasSeleccionadas = lineas.filter(l => l.seleccionada);
      if (self.$refs.tpHoraInicio) {
        if (Array.isArray(self.$refs.tpHoraInicio)) {
          self.$refs.tpHoraInicio.forEach(tp => {
            tp.setFechaBase(fechaVisita);
          });
        } else {
          self.$refs.tpHoraInicio.setFechaBase(fechaVisita);
        }
      }
      lineas.filter(linea => !!linea.horaInicio).forEach(linea => {
        if (typeof linea.horaInicio == 'string') {
          linea.horaInicio = new Date(linea.horaInicio);
        }
        var dateInicio = new Date(fechaVisita.getTime());
        if (typeof linea.horaInicio == 'string') {
          linea.horaInicio = new Date(linea.horaInicio);
        }
        dateInicio.setHours(linea.horaInicio.getHours());
        dateInicio.setMinutes(linea.horaInicio.getMinutes());
        dateInicio.setSeconds(linea.horaInicio.getSeconds());
        dateInicio.setMilliseconds(linea.horaInicio.getMilliseconds());
        self.$set(linea, "horaInicio", dateInicio);
      })
      self.getDiario();
    },
    borrarLineasInspectores() {
      const self = this;
      var lineasToRemove = [];
      self.$refs.rform.formData.lineas.forEach(l => {
        if (!!l.inspectorUsername) {
          lineasToRemove.push(l);
        }
      });
      lineasToRemove.forEach(l => {
        self.$refs.rform.formData.lineas.splice(self.$refs.rform.formData.lineas.indexOf(l), 1);
      });
      self.inspectoresAdded.splice(0,self.inspectoresAdded.length);
    },
    anadirInspector(inspector) {
      const self = this;
      const app = self.$f7;
      if (self.inspectoresAdded.indexOf(inspector.username) > -1) {
        self.removeInspector(inspector.username);
      } else {
        app.preloader.show();
        self.Planificacion
          .getList({
            filter: {
              inspector: inspector.username,
              fecha: self.$refs.rform.formData.fechaVisita,
              tipo: ['LINEA', 'QUEDADA']
            }
          })
          .then(function(res) {
            if (res && res.list && res.list.length) {
              self.inspectoresAdded.push(inspector.username);
              res.list.forEach(l => {
                if (!!!self.$refs.rform.formData.lineas.filter(l2 => !!l2.id && l2.id == l.id).length) {
                  l.inspectorUsername = inspector.username;
                  l.manual = true;
                  l.lineas = JSON.parse(JSON.stringify(l));
                  l.codigo = l.codigoPedido;
                  l.numero = l.numeroPedido;
                  l.lineas.numero = l.numLinea;
                  self.$refs.rform.formData.lineas.push(l);
                }
              });
            } else {
              app.dialog.alert("No se han encontrado planificaciones para el inspector " + inspector.username + " para el día " + DateUtils.dateFormat(self.$refs.rform.formData.fechaVisita, "DD/MM/YYYY"));
            }
          })
          .catch(function(e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function() {
            app.preloader.hide();
          });
      }
    },
    removeInspector(username) {
      const self = this;
      var lineas = self.$refs.rform.formData.lineas;
      var lineasToRemove = [];
      lineas.forEach(lin => {
        if (lin.inspectorUsername == username) {
          lineasToRemove.push(lin);
        }
      })
      lineasToRemove.forEach(l => {
        lineas.splice(lineas.indexOf(l), 1);
      })

      self.inspectoresAdded.splice(self.inspectoresAdded.indexOf(username), 1);
      
    },
    changeHour(linea, newVal) {
      var self = this;
      linea.manual = !!newVal;
      if (linea.manual) {
        self.calcularHoras();
      }
      //self.calcularHoras();
    },
    getHourFormat(d) {
      var self = this;
      if (!d) {
        return '';
      }
      if (typeof d == "string") {
        d = new Date(d);
      }
      return self.padZeros(d.getHours(), 2) + ':' + self.padZeros(d.getMinutes(), 2);
    },
    padZeros(input, maxLength) {
      if (maxLength === void 0) { maxLength = 0; }
      return ("0000" + input).slice(-maxLength);
    },
    getFechaVisita() {
      const self = this;
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.fechaVisita) {
        return self.$refs.rform.formData.fechaVisita;
      }
      var d = new Date();
      d.setDate(d.getDate() + 1);
      d.setMinutes(0);
      d.setHours(9);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return d;
    },
    initRoutesMap() {
      var self = this;
      if (!self.google) {
        return;
      }
      if (self.directionsRenderer) {
        self.directionsRenderer.setMap(null);
      }
      for (var i=0; i < self.polylines.length; i++) {
        self.polylines[i].setMap(null);
      }
      if (!self.directionsService) {
        self.directionsService = new self.google.maps.DirectionsService();
      }
      if (!self.infowindowRoute) {
        self.infowindowRoute = new self.google.maps.InfoWindow();
      }
      self.directionsRenderer = new self.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        suppressPolylines: true,
        infoWindow: self.infowindowRoute,
      });
      self.$refs.gmap.$mapPromise.then((map) => {
        self.map = map;
        self.directionsRenderer.setMap(self.map);
      })
      var lineas = self.$refs.rform.formData.lineas;
      if (lineas) {
        lineas.forEach((linea, idx) => {
          if (!linea.manual) {
            self.$set(linea, "horaInicio", null);
          }
          self.$set(linea, "desplazamiento", null);
          self.$set(linea, "tiempoLibre", null);
        });
      }
    },
    renderDirectionsPolylines(response) {
      var self = this;
      var legs = response.routes[0].legs;
      for (var i = 0; i < legs.length; i++) {
        var leg = legs[i];
        var steps = legs[i].steps;
        for (var j = 0; j < steps.length; j++) {
          var nextSegment = steps[j].path;
          var stepPolyline = new google.maps.Polyline(self.polylineOptions);
          for (var k = 0; k < nextSegment.length; k++) {
            stepPolyline.getPath().push(nextSegment[k]);
          }
          stepPolyline.leg = leg;
          self.polylines.push(stepPolyline);
          stepPolyline.setMap(self.map);
          // route click listeners, different one on each step
          self.google.maps.event.addListener(stepPolyline, 'click', function(evt) {
            var leg = this.leg;
            self.infowindowRoute.setContent("Distancia: <b>" + leg.distance.text + ".</b><br>Tiempo: <b>" + leg.duration.text + ".</b>");
            self.infowindowRoute.setPosition(evt.latLng);
            self.infowindowRoute.open(self.map);
          })
        }
      }
    },
    calcRoute(lineas) {
      var self = this;
      if (!self.$refs.rform.formData.inspectores || self.$refs.rform.formData.inspectores.filter(i => i.username).length < 1) {
        app.dialog.alert("Debe informar al menos a un inspector");
        return;
      }
      if (!self.$refs.rform.formData.direccionInicio || !self.$refs.rform.formData.direccionFin ||
        !self.$refs.rform.formData.latitudInicio || !self.$refs.rform.formData.longitudInicio ||
        !self.$refs.rform.formData.latitudFin || !self.$refs.rform.formData.longitudFin) {
          app.dialog.alert("Debe informar una dirección de inicio y una de fin válidas");
          return;
      }
      self.initRoutesMap();
      var routes = lineas.filter(l => l.seleccionada);
      if (!routes.length) {
        app.dialog.alert("Debe seleccionar al menos una línea");
        return;
      }
      var start = 
        {
          longitud: self.$refs.rform.formData.longitudInicio,
          latitud: self.$refs.rform.formData.latitudInicio,
          direccionStr: self.$refs.rform.formData.direccionInicio
        };
      var end = 
        {
          longitud: self.$refs.rform.formData.longitudFin,
          latitud: self.$refs.rform.formData.latitudFin,
          direccionStr: self.$refs.rform.formData.direccionFin
        };
      var waypoints = routes.map(l => {
        return {
          location: {
            lng: l.lineas.direccionInspeccion.longitud,
            lat: l.lineas.direccionInspeccion.latitud
          }
        }
      });
      if (start && end) {
        var request = {
          origin: {lng: start.longitud, lat: start.latitud},
          destination: {lng: end.longitud, lat: end.latitud},
          waypoints: waypoints,
          travelMode: 'DRIVING'
        };
        self.directionsService.route(request, function(result, status) {
          if (status == 'OK') {
            self.directionsRenderer.setDirections(result);
            self.renderDirectionsPolylines(result);
            self.calcularHoras();
          }
        });
      }
    },
    calcularHoras() {
      var self = this;
      var app = self.$f7;
      var lineas = self.$refs.rform.formData.lineas;
      var fechaPrimera = self.$refs.rform.formData.fechaVisita;
      if (!fechaPrimera && !self.esTpi) {
        self.setMapPosition(); 
        self.initRoutesMap();
        return;
      }
      var timeMillis = fechaPrimera.getTime();
      var lineasSeleccionadas = lineas.filter(l => l.seleccionada);

      if (!self.directionsRenderer && !self.esTpi) {
        return;
      }
      if (self.esTpi) {
        lineas.forEach((linea) => {
          if (linea.seleccionada) {
            var timeMillisTiempo = linea.lineas.tiempoEstimadoOficina * 60 * 1000;
            if (!linea.manual && (!linea.horaInicio || linea.horaInicio.getTime() != timeMillis)) {
              linea.manual = false;
              self.$set(linea, "horaInicio", new Date(timeMillis));

            } else {
              var dateInicio = new Date(fechaPrimera.getTime());
              if (typeof linea.horaInicio == 'string') {
                linea.horaInicio = new Date(linea.horaInicio);
              }
              dateInicio.setHours(linea.horaInicio.getHours());
              dateInicio.setMinutes(linea.horaInicio.getMinutes());
              dateInicio.setSeconds(linea.horaInicio.getSeconds());
              dateInicio.setMilliseconds(linea.horaInicio.getMilliseconds());
              timeMillis = dateInicio.getTime();
            }
            timeMillis = timeMillis + timeMillisTiempo;
          }
        });
      } else {

        var directions = self.directionsRenderer.getDirections();
        if (!directions) {
          if (lineasSeleccionadas.length){
            var linea = lineasSeleccionadas[0];
            // Si se llega aquí es porque se ha llamado a la función con una sola linea seleccionada
            if (!linea.manual) {
              self.$set(linea, "horaInicio", new Date(timeMillis));
            } else {
              var dateInicio = new Date(timeMillis);
              if (typeof linea.horaInicio == 'string') {
                linea.horaInicio = new Date(linea.horaInicio);
              }
              dateInicio.setHours(linea.horaInicio.getHours());
              dateInicio.setMinutes(linea.horaInicio.getMinutes());
              dateInicio.setSeconds(linea.horaInicio.getSeconds());
              dateInicio.setMilliseconds(linea.horaInicio.getMilliseconds());
              self.$set(linea, "horaInicio", dateInicio);
            }
          }
          return;
        }
        directions = directions.routes.flatMap(r=>r.legs);
        if (lineasSeleccionadas > 1 && lineasSeleccionadas.length - directions.length != 1) {
          app.dialog.alert("No se corresponden las lineas con las rutas, no se pueden calcular las horas de inicio");
          return;
        }
        var idxSeleccionada = 0;
        let ultimaLinea = null;
        lineas.forEach((linea) => {
          if (linea.seleccionada) {
            ultimaLinea = linea;
            var timeMillisTiempo = linea.lineas.tiempoEstimadoCampo * 60 * 1000;
            var timeMillisDistancia = 0;
            var timeMillisFinAnterior = timeMillis;
            var direction = undefined;
            // if (idxSeleccionada > 0) {
            direction = directions[idxSeleccionada];
            if (direction && idxSeleccionada > 0) {
              timeMillisDistancia = direction.duration.value * 1000;
            }
            // }
            timeMillis = timeMillis + timeMillisDistancia;
            if (!linea.manual && (!linea.horaInicio || linea.horaInicio.getTime() != timeMillis)) {
              linea.manual = false;
              self.$set(linea, "horaInicio", new Date(timeMillis));
  
            } else {
              var dateInicio = new Date(fechaPrimera.getTime());
              if (typeof linea.horaInicio == 'string') {
                linea.horaInicio = new Date(linea.horaInicio);
              }
              dateInicio.setHours(linea.horaInicio.getHours());
              dateInicio.setMinutes(linea.horaInicio.getMinutes());
              dateInicio.setSeconds(linea.horaInicio.getSeconds());
              dateInicio.setMilliseconds(linea.horaInicio.getMilliseconds());
              timeMillis = dateInicio.getTime(); 
            }
            var tiempoLibre = timeMillis - timeMillisFinAnterior - timeMillisDistancia;
            if ((tiempoLibre < 0 && (tiempoLibre * -1) > (1 * 60 * 1000)) || tiempoLibre > (10 * 60 * 1000)) {
              self.$set(linea, "tiempoLibre", {text: self.getHoursMinutesFormat(tiempoLibre < 0 ? (tiempoLibre * -1) : tiempoLibre), value: tiempoLibre});
            } else {
              self.$set(linea, "tiempoLibre", null);
            }
            if (direction) {
              self.$set(linea, "desplazamiento", direction);
            }
            timeMillis = timeMillis + timeMillisTiempo;
            idxSeleccionada++;
          }
        });
        if (ultimaLinea) {
          let direction = directions[directions.length - 1];
          if (direction) {
            self.$set(ultimaLinea, "desplazamientoFin", direction);
          }
  
        }
        self.setMapPosition();
      }
    },
    getHoursMinutesFormat(millis) {
      if (isNaN(millis)) {
        return millis;
      }
      var value = millis / 1000;
      let hours =  parseInt(Math.floor(value / 3600)); 
      let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60)); 
      let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60); 

      let dHours = hours;
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      let dSecs = (seconds > 9 ? seconds : '0' + seconds);

      return (dHours > 0 ? dHours + "h " : "") + dMins + " min";
    },
    setMapPosition() {
      var self = this;
      var markers = 
        ((self.$refs.rform.formData.longitudInicio && self.$refs.rform.formData.latitudInicio) ? [{longitud: self.$refs.rform.formData.longitudInicio, latitud: self.$refs.rform.formData.latitudInicio}] : []).concat(
        (!self.$refs.rform.formData.lineas ? [] : self.$refs.rform.formData.lineas.filter(l=>l.seleccionada && l.lineas.direccionInspeccion && l.lineas.direccionInspeccion.longitud && l.lineas.direccionInspeccion.latitud).map(l => { return {longitud: l.lineas.direccionInspeccion.longitud, latitud: l.lineas.direccionInspeccion.latitud};}))).concat(
        ((self.$refs.rform.formData.longitudFin && self.$refs.rform.formData.latitudFin) ? [{longitud: self.$refs.rform.formData.longitudFin, latitud: self.$refs.rform.formData.latitudFin}] : []));

      if (markers == null || markers.length == 0) {
        if (this.$refs.gmap.$mapObject) {
          // Si no hay marcas a mostrar Centramos en madrid
          this.$refs.gmap.$mapObject.setCenter(self.mapCenter);
          this.$refs.gmap.$mapObject.setZoom(self.mapZoom);
        }
      } else {
        const bounds = new google.maps.LatLngBounds()
        for (let m of markers) {
          if (m.longitud && m.latitud) {
            bounds.extend({lng: m.longitud, lat: m.latitud});
          }
        }
        self.$refs.gmap.$mapObject.fitBounds(bounds, 50);
        if (markers.length == 1) {
          this.$refs.gmap.$mapObject.setZoom(14);
        }
      }
    },
    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult);
      self.initRoutesMap();
      self.$forceUpdate();
    },
    setGeocoderError: function(status) {
      var self = this;
      var app = self.$f7;
      var alertMessage = 'No ha sido posible obtener la informacion de Geocode de Google, razón: ' + status;
      if (status == 'ZERO_RESULTS') {
        alertMessage = "No se han encontrado resultados";
      }
      app.dialog.alert(alertMessage);
    },
    ondragend: function(marker, index, inicio) {
      var self = this;
      var geocoder = new self.google.maps.Geocoder();
      geocoder.geocode({'location': marker.latLng}, function(results, status) {
        if (status === 'OK') {
          var address = results[0].formatted_address;
          if (index != null) {
            self.$set(
              self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion,
              "direccion",
              address
            );
          } else {
            if (inicio) {
              self.$set(self.$refs.rform.formData, 'direccionInicio', address);
            } else {
              self.$set(self.$refs.rform.formData, 'direccionFin', address);
            }
          }
        } else {
          self.setGeocoderError(status);
        }
      });
      if (index != null) {
        self.$set(
          self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion,
          "longitud",
          marker.latLng.lng()
        );
        self.$set(
          self.$refs.rform.formData.lineas[index].lineas.direccionInspeccion,
          "latitud",
          marker.latLng.lat()
        );
      } else {
        if (inicio) {
          self.$set(self.$refs.rform.formData, 'longitudInicio', marker.latLng.lng());
          self.$set(self.$refs.rform.formData, 'latitudInicio', marker.latLng.lat());
        } else {
          self.$set(self.$refs.rform.formData, 'longitudFin', marker.latLng.lng());
          self.$set(self.$refs.rform.formData, 'latitudFin', marker.latLng.lat());
        }
      }
      self.initRoutesMap();
      // self.setMapPosition();
    },
    buscarDireccion: function(direccion, comentarios, tiempo) {
      var self = this;
      var app = self.$f7;
      if (!self.google) {
        return null;
      }
      if (!direccion || direccion == '') {
        app.dialog.alert("Debe introducir una direccion a añadir");
        return;
      }
      var geocoder = new self.google.maps.Geocoder();
      geocoder.geocode({'address': direccion}, function(results, status) {
        if (status === 'OK') {
          var posicion = results[0].geometry.location.toJSON();
          var address = results[0].formatted_address;
          var linea = {
            seleccionada : true,
            tipo: "QUEDADA",
            comentariosPlanificacion: comentarios,
            lineas: {
              direccionInspeccion: {
                direccion: address,
                longitud: posicion.lng,
                latitud: posicion.lat
              },
              tiempoEstimadoCampo: tiempo
            }
          }
          self.$refs.rform.formData.lineas.push(linea);
          self.setMapPosition();
          self.initRoutesMap();
          self.direccionBuscar = '';
          self.comentariosBuscar = '';
          self.tiempoBuscar = 0;
        } else {
          self.setGeocoderError(status);
        }
      });
    },
    getGeocode: function(linea, index, inicio) {
      var self = this;
      let formData = self.$refs.rform.formData;
      if (!self.google || (linea && linea.lineas.callingGeocode) || (!linea && inicio && formData.callingGeocodeInicio) || (!linea && !inicio && formData.callingGeocodeFin)) {
        return;
      }
      let timemillis = new Date().getTime();
      let direccionStr = !linea ? (inicio ? formData.direccionInicio : formData.direccionFin) : self.getDireccionStr(linea.lineas.direccionInspeccion);
      if (self.lastCallGeocode == 0 || timemillis - self.lastCallGeocode >= 1000) {
        if (linea) {
          linea.lineas.callingGeocode = true;
        } else {
          if (inicio) {
            self.$set(formData, "callingGeocodeInicio", true);
          } else {
            self.$set(formData, "callingGeocodeFin", true);
          }
        }
        self.callGeocode(direccionStr, index, inicio);
      } else {
        setTimeout(function() {
          self.getGeocode(linea, index, inicio);
        }, 1000);
      }
    },
    getDireccionFromGeoGoogle(obj) {
      const self = this;
      let direccion = {
        direccionStr: obj.formatted_address,
        longitud: obj.geometry.location.lng(),
        latitud: obj.geometry.location.lat(),
        direccion: self.getAdressComponent(obj, "route") + " " + self.getAdressComponent(obj, "street_number"),
        cp: self.getAdressComponent(obj, "postal_code"),
        poblacion: self.getAdressComponent(obj, "locality"),
        provinciaStr: self.getAdressComponent(obj, "administrative_area_level_2"),
        comunidad: self.getAdressComponent(obj, "administrative_area_level_1"),
        pais: self.getAdressComponent(obj, "country", true)
      }
      return direccion;
    },
    getAdressComponent(obj, type, shortName) {
      let component = obj.address_components.find(c => c.types.includes(type));
      return component ? (shortName ? component.short_name : component.long_name) : '';
    },
    callGeocode(direccionStr, index, inicio) {
      const self = this;
      self.lastCallGeocode = new Date().getTime();
      var geocoder = new self.google.maps.Geocoder();
      geocoder.geocode({'address': direccionStr}, function(results, status) {
        if (status === 'OK') {
          let dir = self.getDireccionFromGeoGoogle(results[0]);
          console.log(dir);
          if (index != null) {
            self.$set(self.$refs.rform.formData.lineas[index].lineas, 'direccionInspeccion', dir);
            self.$set(self.$refs.rform.formData.lineas[index].lineas, 'callingGeocode', false);
          } else {
            if (inicio) {
              self.$set(self.$refs.rform.formData, 'direccionInicio', dir.direccionStr);
              self.$set(self.$refs.rform.formData, 'latitudInicio', dir.latitud);
              self.$set(self.$refs.rform.formData, 'longitudInicio', dir.longitud);
              self.$set(self.$refs.rform.formData, 'callingGeocodeInicio', false);
            } else {
              self.$set(self.$refs.rform.formData, 'direccionFin', dir.direccionStr);
              self.$set(self.$refs.rform.formData, 'latitudFin', dir.latitud);
              self.$set(self.$refs.rform.formData, 'longitudFin', dir.longitud);
              self.$set(self.$refs.rform.formData, 'callingGeocodeFin', false);
            }
          }
          self.setMapPosition();
        } else {
          if (index != null) {
            self.$set(self.$refs.rform.formData.lineas[index].lineas, 'callingGeocode', false);
          } else {
            if (inicio) {
              self.$set(self.$refs.rform.formData, 'callingGeocodeInicio', false);
            } else {
              self.$set(self.$refs.rform.formData, 'callingGeocodeFin', false);
            }
          }
          self.setGeocoderError(status);
        }
      });
    },
    getDireccionStr(direccion) {
      if (direccion) {
        if (direccion.direccionStr) {
          return direccion.direccionStr;
        }
        return (direccion.direccion || '') + ' ' + (direccion.cp || '') + ' ' + (direccion.poblacion || '') + ' ' + (direccion.pais || '');
      } else {
        return '';
      }
    },
    planificar: function(createPlani, pdteConfirmar = false) {
      var self = this;
      var app = self.$f7;

      var data = Object.assign({}, createPlani);
      var lineasSeleccionadas = data.lineas.filter(l=>l.seleccionada);
      var lineasNoSeleccionadas = Object.assign([], data.lineas.filter(l=>!l.seleccionada));
      if (lineasSeleccionadas.length < 1) {
        app.dialog.alert("Debe seleccionar al menos una línea para planificar");
        return;
      }
      if (lineasSeleccionadas.filter(l => !l.horaInicio).length > 0) {
        app.dialog.alert("Todas las líneas deben tener una hora inicio informada");
        return;
      }
      if (!data.inspectores || data.inspectores.filter(i => i.username).length < 1) {
        app.dialog.alert("Debe informar al menos a un inspector");
        return;
      }
      if (!self.esTpi && (!data.direccionInicio || !data.direccionFin)) {
        app.dialog.alert("Debe informar una dirección de inicio y fin válidas");
        return;
      }
      if (!data.fechaVisita) {
        app.dialog.alert("Debe informar una fecha Visita Válida");
        return;
      }

      var dataLineas = lineasSeleccionadas.map(linea => {

        var dataLinea = Object.assign({}, linea);
        delete dataLinea.lineas;
        if (!dataLinea.inspectorUsername) {
          dataLinea.id = dataLinea.idPlanif ? dataLinea.idPlanif : null;
          dataLinea.idPedido = dataLinea.idPedido ? dataLinea.idPedido: linea.id;
          dataLinea.codigoPedido = dataLinea.codigoPedido ? dataLinea.codigoPedido : linea.codigo;
          dataLinea.numLinea = typeof linea.lineas.numero != "undefined" ? linea.lineas.numero: null;
          dataLinea.direccionInspeccion = linea.lineas.direccionInspeccion;
          dataLinea.servicio = linea.lineas.servicio;
          dataLinea.item = linea.lineas.item;
          dataLinea.estado = null;
          dataLinea.pendienteConfirmar = pdteConfirmar;
        }
        dataLinea.tiempoEstimadoCampo = self.esTpi ? linea.lineas.tiempoEstimadoOficina : linea.lineas.tiempoEstimadoCampo;

        return dataLinea;

      });

      data.lineas = dataLineas;
      data.esTpi = self.esTpi;
      
      app.dialog.confirm(
        "¿Seguro que desea planificar las líneas seleccionadas?",
        "Planificación de pedido",
        function() {
          app.preloader.show();
          Planificacion.save({
            data: data,
            itemId: null
          })
            .then(function(res) {
              app.dialog.alert("Líneas planificadas correctamente");
              createPlani.inspectores = [];
              createPlani.lineas = lineasNoSeleccionadas;
              self.$set(self.$refs.rform, 'formData', createPlani);
              self.borrarLineasInspectores();
              self.initRoutesMap();
            })
            .catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
              self.errors = e.errors || {};
            })
            .finally(function() {
              app.preloader.hide();
            });
        }
      );
    },
    desplanificar: function(createPlani) {
      var self = this;
      var app = self.$f7;

      var data = Object.assign({}, createPlani);
      var lineasSeleccionadas = data.lineas.filter(l=>l.seleccionada);
      if (lineasSeleccionadas.length < 1) {
        app.dialog.alert("Debe seleccionar al menos una línea para desplanificar");
        return;
      }
      var dataLineas = lineasSeleccionadas.map(linea => {

        return {
          id: linea.idPlanif ? linea.idPlanif : null,
          idPedido: linea.idPedido ? linea.idPedido : linea.id,
          numLinea: linea.lineas.numero != "undefined" ? linea.lineas.numero : null
        }
      });

      app.dialog.confirm(
        "¿Seguro que desea desplanificar las líneas seleccionadas?",
        "Desplanificación de líneas",
        function() {
          app.preloader.show();
          Planificacion.desplanificar({
            data: dataLineas
          })
            .then(function(res) {
              app.dialog.alert("Líneas desplanificadas correctamente");
              window.location.reload();
            })
            .catch(function(e) {
              if (typeof e == "string") {
                e = {
                  message: e
                };
              }
              app.dialog.alert(e.message);
            })
            .finally(function() {
              app.preloader.hide();
            });
        }
      );
    },
    selectAllPopup: function(checked, lineas) {
      var self = this;
      for (let index = 0; index < lineas.length; index++) {
        var lin = lineas[index];
        if (!lin.idPlanif || lin.tipoPlani != 'TAREA' || !lin.tipoTarea || lin.tipoTarea.codigo != 'T0005') {
          self.$set(
            self.$refs.rform.formData.lineas[index],
            "seleccionada",
            checked
          );
        }
      }
    },
    getTotalesLinea: function(linea) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
      });
      var cantidad = linea.lineas.cantidad;
      var dtoUnitario = linea.lineas.precioUnitario / 100 * linea.lineas.porcDescuento;
      var descuento = dtoUnitario * cantidad;
      var totalSinDto = linea.lineas.precioUnitario * cantidad;
      var totalTasas = linea.lineas.tasas * cantidad;
      var totalSinTasas = totalSinDto - descuento;
      var subtotal = totalTasas + totalSinTasas;
      var iva = totalSinTasas / 100 * linea.lineas.porcIVA;
      var total = subtotal + iva;
      
      // Proveedor
      var proveedor = linea.lineas.proveedor || {precio: 0, porcIVA: 21};
      var subtotalProveedor = proveedor.precio * cantidad;
      var ivaProveedor = subtotalProveedor / 100 * proveedor.porcIVA;
      var totalProveedor = subtotalProveedor + ivaProveedor;

      var totales = {
        descuento: formatter.format(descuento),
        totalSinDto: formatter.format(totalSinDto),
        subtotal: formatter.format(subtotal),
        iva: formatter.format(iva),
        totalTasas: formatter.format(totalTasas),
        total: formatter.format(total),
        subtotalProveedor: formatter.format(subtotalProveedor),
        ivaProveedor: formatter.format(ivaProveedor),
        totalProveedor: formatter.format(totalProveedor),
        subtotalConProveedor: formatter.format(subtotal + subtotalProveedor),
        ivaConProveedor: formatter.format(iva + ivaProveedor),
        totalConProveedor: formatter.format(total + totalProveedor)
      };
      
      return totales;
    },
    clearHoraInicio: function(index){
      var self = this;
      self.$set(self.$refs.rform.formData.lineas[index], 'manual', false);
    },
    setFechaBase: function(){
      var self = this;
      self.$set(self.$refs.rform.formData, 'fechaBase', fechaVisita);
    },
    getFechaPlanif: function (index) {
      var self = this;
      var fechaPlanif = self.$refs.rform.formData.lineas[index].horaInicio;
      if (typeof fechaPlanif == 'undefined') {
        fechaPlanif = self.$refs.rform.formData.lineas[index].lineas.horaInicio;
      }
    

      return DateUtils.dateFormat(fechaPlanif, "DD/MM/YYYY") ;
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.idData = self.id;
  }
};
</script>