import Inventarios from './inventarios.vue';
import InventarioForm from './inventario-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/inventario/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inventario', ['ADMIN', 'INSPECTOR'], Inventarios),
        keepAlive: true
    },
    {
        path: '/inventario/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inventario/new', ['ADMIN', 'INSPECTOR'], InventarioForm)
    },
    {
        path: '/inventario/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inventario/edit', ['ADMIN', 'INSPECTOR'], InventarioForm)
    },
    {
        path: '/inventario/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inventario/view', ['ADMIN', 'INSPECTOR'], InventarioForm, { editable: false })
    },
];
export default routes;