import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Oferta = Object.create(DatabaseModel);

Oferta.model = 'oferta';
Oferta.modelFront = 'ofertas';

Oferta.downloadDocumento = function(item) {
    var self = this;
    return self.download(
        self.server_movilidad + self.prefix + self.model + '/' + item.id + '/documento/ORDINARIA',
        '' + (item.numero || item.codigo.replaceAll('/', '_')) + ' - ' + item.cliente.razonSocial + '.pdf');
};

Oferta.cambioEstado = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/cambioestado',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Oferta.refreshEnvio = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/refreshEnvio/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Oferta.aprobacion = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/aprobacion',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Oferta.aceptacion = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/aceptacion',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Oferta.extension = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/extension',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Oferta.clonar = function(params){
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/copiar/' + params.itemId,
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

Oferta.probarPlantilla = function(data) {
    var self = this;
    var name = `prueba.pdf`;
    return new Promise((resolve, reject) => {
        self.request({
            url: self.server_movilidad + self.prefix + self.model + '/probarPlantilla/',
            method: 'POST',
            contentType: 'application/json',
            crossDomain: true,
            xhrFields: { 'responseType': 'arraybuffer' },
            data: data,
            success: function(data) {
                var blob = new Blob([data]);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                } else {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name;
                    link.target = '_blank';
                    link.click();
                    URL.revokeObjectURL(link.href);
                }
                resolve();
            },
            error: function(error) {
                reject(error);
            }
        });
    });
};

Oferta.enviarEmail = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/enviarEmail/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};
Oferta.probarPlantillaCorreo = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/probarPlantillaCorreo/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default Oferta;