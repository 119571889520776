<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('comunidades.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('comunidades.comunidad')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('comunidades.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">Editar</f7-button>
          <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Comunidad"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-input
                  floating-label
                  outline
                  :label="$t('comunidades.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-input
                  floating-label
                  outline
                  :label="$t('comunidades.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-autocomplete-m2m
                  :dbAdapter="Provincia"
                  floating-label
                  outline
                  :label="$t('comunidades.provincias')"
                  :form="rform"
                  name="provincias"
                  labelProp="nombre"
                  idProp="codigo"
                  idFieldDetail="id"
                  type="textarea"
                  :lineBreak="true"
                ></r-autocomplete-m2m>
              </ul>
            </f7-col>
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-autocomplete
                  class="col-100 medium-25"
                  :dbAdapter="Pais"
                  floating-label
                  outline
                  :label="$t('componentes.direccion.pais')"
                  :form="rform"
                  name="codPais"
                  labelProp="nombre"
                  idProp="codigo"
                  idFieldDetail="id"
                ></r-autocomplete>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-autocomplete
                  :dbAdapter="Cliente"
                  class="col-100 medium-25"
                  floating-label
                  outline
                  :label="$t('comunidades.proveedor')"
                  :form="rform"
                  name="codProveedor"
                  labelProp="codigoNombre"
                  idProp="codigo"
                  :shownFields="['codigo', 'razonSocial']"
                  idFieldDetail="id"
                  :minLength="3"
                  :async="true"
                ></r-autocomplete>
              </ul>
            </f7-col>
            <f7-col width="100" medium="25" small="100">
              <ul>
                <r-input
                  floating-label
                  outline
                  :label="$t('comunidades.codCuentaMayorTasas')"
                  :form="rform"
                  type="text"
                  name="codCuentaMayorTasas"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="30" small="100">
              <r-input
                ref="calificacionesExcluir"
                floating-label
                outline
                :form="rform"
                :label="$t('comunidades.calificacionesExcluir')"
                type="checks"
                :name="'calificacionesExcluir'"
                :dbItems="calificacionesInspeccion"
              />
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>






      <f7-block-title>
        ** {{rform.formData.nombre}} - Definición de plazos
        <f7-link @click="nuevoPlazo">Nuevo plazo</f7-link>
        <f7-card 
          class="padding-vertical-half padding-right"
          style="z-index: 3000"
          v-for="(plazo,indexPZ) in rform.formData.plazos" :key="'plazos-'+indexPZ"
        >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="10" small="100">
                    <r-input
                      ref="plazo"
                      floating-label
                      outline
                      :label="$t('comunidades.plazos.plazo')"
                      :form="rform"
                      type="number"
                      :name="'plazos.' + indexPZ + '.plazo'"
                    ></r-input>
                  </f7-col>
                  <f7-col width="100" medium="15" small="100">
                    <r-input
                      ref="tiposPlazo"
                      floating-label
                      outline
                      :form="rform"
                      :label="$t('comunidades.plazos.tipo_plazo')"
                      type="checks"
                      :name="'plazos.' + indexPZ + '.tiposPlazo'"
                      :dbItems="tiposPlazo"
                    />
                  </f7-col>
                  <f7-col width="100" medium="15" small="100">
                    <r-input
                      ref="tiposInspeccion"
                      floating-label
                      outline
                      :form="rform"
                      :label="$t('comunidades.plazos.tipo_inspeccion')"
                      type="checks"
                      :name="'plazos.' + indexPZ + '.tiposInspeccion'"
                      :dbItems="tiposInspeccion"
                    />
                      <!-- :dbItems="[{name: 'Primera', value: 'PRIMERA'}, {name: 'Segunda', value: 'SEGUNDA'}, {name: 'Nueva Primera', value: 'NUEVA_PRIMERA'}]" -->
                  </f7-col>
                  <f7-col width="100" medium="20" small="100">
                    <r-input
                      ref="calificacionesInspeccion"
                      floating-label
                      outline
                      :form="rform"
                      :label="$t('comunidades.plazos.calificaciones_inspeccion')"
                      type="checks"
                      :name="'plazos.' + indexPZ + '.calificacionesInspeccion'"
                      :dbItems="calificacionesInspeccion"
                    />
                  </f7-col>
                  <r-autocomplete-m2m
                    ref="materias"
                    class="col-15"
                    :dbAdapter="Materia"
                    floating-label
                    outline
                    :form="rform"
                    :label="$t('comunidades.plazos.materias')"
                    :name="'plazos.' + indexPZ + '.materias'"
                    labelProp="nombre"
                    idProp="codigo"
                    prefixProp="codigo"
                    idFieldDetail="id"
                    type="textarea"
                    :lineBreak="true"
                  ></r-autocomplete-m2m>
                  <r-autocomplete-m2m
                    class="col-15"
                    :dbAdapter="Servicio"
                    floating-label
                    outline
                    :form="rform"
                    :label="$t('comunidades.plazos.serviciosExcluir')"
                    :name="'plazos.' + indexPZ + '.serviciosExcluir'"
                    labelProp="nombre"
                    idProp="codigo"
                    prefixProp="codigo"
                    idFieldDetail="id"
                    type="textarea"
                    :lineBreak="true"
                  ></r-autocomplete-m2m>
                  <!-- <r-autocomplete-m2m
                    ref="materias"
                    class="col-25"
                    floating-label
                    outline
                    :form="rform"
                    :label="$t('comunidades.plazos.materias')"
                    :name="'plazos.' + indexPZ + '.materias'"
                    labelProp="nombre"
                    idProp="valor"
                    :dbItems="[]"
                    type="textarea"
                    :viewTooltip="viewTooltip"
                  ></r-autocomplete-m2m> -->
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-link
            @click="$delete(rform.formData.plazos,indexPZ);"
            class="margin-half"
            style="position:absolute;right:0;top:0;"
            icon-f7="trash"
          ></f7-link>
        </f7-card>
      </f7-block-title>









      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Comunidad from "./../../js/db/Comunidad.js";
import Pais from "./../../js/db/Pais.js";
import Provincia from "./../../js/db/Provincia.js";
import Cliente from "./../../js/db/Cliente.js";
import Combos from '../../js/db/Combos'
import Materia from "./../../js/db/Materia.js";
import Servicio from "./../../js/db/Servicio.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m
  },
  data: function() {
    var self = this;
    return {
      Comunidad,
      Provincia,
      Pais,
      Cliente,
      Combos,
      readonly: !this.editable,
      tiposPlazo: [],
      tiposInspeccion: [],
      Materia,
      Servicio,
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    filtro: {
      required: true,
      type: Object
    },
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Comunidad.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("comunidades.comunidad_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    nuevoPlazo: function(){
      var self = this;
      if(!self.$refs.rform.formData.plazos) {self.$set(self.$refs.rform.formData, 'plazos', []);} 
      self.$refs.rform.formData.plazos.push({});
    },
    getValue: function(field) {
      const self = this;
      var value = self.filtro[field];
      return value;
    },
    getValueOpcion: function(opcion) {
      const self = this;
      var value = self.filtro.opciones && self.filtro.opciones[opcion] ? self.filtro.opciones[opcion] : undefined;
      return value;
    },
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;

    Combos.getComboById("tiposPlazo").then(res => {
      self.tiposPlazo = res.list.map(item => {
        return {
          name: item.nombre,
          value: item.valor
        };
      });
    });
    Combos.getComboById("tiposInspeccion").then(res => {
      self.tiposInspeccion = res.list.map(item => {
        return {
          name: item.nombre,
          value: item.valor
        };
      });
    });
    Combos.getComboById("calificacionesInspeccion").then(res => {
      self.calificacionesInspeccion = res.list.map(item => {
        return {
          name: item.nombre,
          value: item.valor
        };
      });
    });
  }
};
</script>