import Servicios from './servicios.vue';
import ServicioForm from './servicios-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/servicios/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('servicios', ['ADMIN', 'INSPECTOR'], Servicios),
        keepAlive: true
    },
    {
        path: '/servicios/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('servicios/new', ['ADMIN', 'INSPECTOR'], ServicioForm)
    },
    {
        path: '/servicios/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('servicios/edit', ['ADMIN', 'INSPECTOR'], ServicioForm)
    },
    {
        path: '/servicios/copiar/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('servicios/copiar', ['ADMIN', 'INSPECTOR'], ServicioForm)
    },
    {
        path: '/servicios/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('servicios/view', ['ADMIN', 'INSPECTOR'], ServicioForm, { editable: false })
    },
];
export default routes;