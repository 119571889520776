import MediumEditor from 'medium-editor';

var FontSize2 = MediumEditor.extensions.form.extend({

    name: 'fontsize2',
    action: 'fontSize2',
    aria: 'increase/decrease font size',
    contentDefault: '&#xB1;', // ±
    contentFA: '<i class="fa fa-text-height"></i>',
    modo: "class", // class o style en pt

    init: function () {
        MediumEditor.extensions.form.prototype.init.apply(this, arguments);
    },

    // Called when the button the toolbar is clicked
    // Overrides ButtonExtension.handleClick
    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();

        this._showForm();

        return false;
    },

    _showForm(fontSize){
        if (!this.isDisplayed()) {
            // Get fontsize of current selection (convert to string since IE returns this as number)
            // var fontSize = this.document.queryCommandValue('fontSize') + '';
            this.showForm(fontSize);
        }
    },

    // Called by medium-editor to append form to the toolbar
    getForm: function () {
        if (!this.form) {
            this.form = this.createForm();
        }
        return this.form;
    },

    // Used by medium-editor when the default toolbar is to be displayed
    isDisplayed: function () {
        return this.getForm().style.display === 'block';
    },

    hideForm: function () {
        this.getForm().style.display = 'none';
        this.getInput().value = '';
    },

    showForm: function (fontSize) {
        var input = this.getInput();

        this.base.saveSelection();
        this.hideToolbarDefaultActions();
        this.getForm().style.display = 'block';
        this.setToolbarPosition();
        
        input.value = fontSize || '';
        input.focus();
    },

    // Called by core when tearing down medium-editor (destroy)
    destroy: function () {
        if (!this.form) {
            return false;
        }

        if (this.form.parentNode) {
            this.form.parentNode.removeChild(this.form);
        }

        delete this.form;
    },

    // core methods
    doFormSave: function () {
        this.base.restoreSelection();
        this.base.checkSelection();
    },

    // form creation and event handling
    createForm: function () {
        var doc = this.document,
            form = doc.createElement('div'),
            input = doc.createElement('input'),
            save = doc.createElement('a');

        // Font Size Form (div)
        form.className = 'medium-editor-toolbar-form';
        form.id = 'medium-editor-toolbar-form-fontsize-' + this.getEditorId();

        // Handle clicks on the form itself
        this.on(form, 'click', this.handleFormClick.bind(this));

        // Add font size slider
        input.setAttribute('type', 'range');
        input.setAttribute('min', '1');
        input.setAttribute('max', '7');
        input.className = 'medium-editor-toolbar-input';
        form.appendChild(input);

        // Handle typing in the textbox
        this.on(input, 'click', this.handleSliderChange.bind(this));

        // Add save buton
        save.setAttribute('href', '#');
        save.className = 'medium-editor-toobar-save';
        save.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-check"></i>' :
                            '&#10003;';
        form.appendChild(save);

        // Handle save button clicks (capture)
        this.on(save, 'click', this.handleSaveClick.bind(this), true);

        return form;
    },

    getInput: function () {
        return this.getForm().querySelector('input.medium-editor-toolbar-input');
    },

    clearFontSize: function () {
        MediumEditor.selection.getSelectedElements(this.document).forEach(function (el) {
            if (el.nodeName.toLowerCase() === 'font' && el.hasAttribute('size')) {
                el.removeAttribute('size');
            }
        });
    },

    handleSliderChange: function () {
        var size = this.getInput().value;
        this.selectedSize = size;
        this.base.saveSelection();
        this.execAction('fontSize', { value: size });
        // En este punto, el navegador debería haber gestionado el cambio de letra incluyendo elementos <font> donde sea necesario.
        // Hay que reemplazar estos elementos por elementos con nuestras clases personalizadas
        this._cleanFonts();
        this.base.restoreSelection();
        this._showForm(this.selectedSize);
        console.log("handleSliderChange", this.selectedSize);
    },

    handleFormClick: function (event) {
        // make sure not to hide form when clicking inside the form
        event.stopPropagation();
    },

    handleSaveClick: function (event) {
        // Clicking Save -> create the font size
        event.preventDefault();
        this.doFormSave();
    },

    _cleanFonts: function() {
        var fontsArr = Array.from(this.document.getElementsByTagName("font"));
        fontsArr.filter(font => font.getAttribute("size")).forEach(font => this._cleanFont(font));
    },
    _cleanFont: function(font){
        var parent = font.parentNode,
            newElement = this.document.createElement("span"),
            newClass = "fs-";
        switch(font.size){
            case "1":
                newClass = `${newClass}xxs`;
                break;
            case "2":
                newClass = `${newClass}xs`;
                break;
            case "3":
                newClass = `${newClass}s`;
                break;
            case "4":
                newClass = `${newClass}n`;
                break;
            case "5":
                newClass = `${newClass}l`;
                break;
            case "6":
                newClass = `${newClass}xl`;
                break;
            case "7":
                newClass = `${newClass}xxl`;
                break;
        }
        if(this.modo === "class"){
            // si modo es class, añadimos la clase al nuevo elemento
            newElement.classList.add(newClass);
            newElement.classList.add("custom-fs");
            newElement.classList.add(newClass);
        } else {
            // si modo es style, añadimos el estilo font-size al nuevo elemento
            // para los correos vamos a poner el tamaño en pt con el estilo font-size
            newElement.style.fontSize = `${(8 + (parseInt(font.size) * 2))}pt`;
        }
        while (font.firstChild) {
            newElement.appendChild(font.firstChild); // *Moves* the child
        }
        
        parent.replaceChild(newElement, font);
    },
});

export default FontSize2;