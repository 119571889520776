import Inspecciones from './inspecciones.vue';
import InspeccionesOffline from './inspecciones-offline.vue';
import InspeccionForm from './inspeccion-form.vue';
import InspeccionManualForm from './inspeccion-manual-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/inspecciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMIN', 'INSPECTOR', 'TRAMITACION'], Inspecciones),
        keepAlive: true
    },
    {
        path: '/inspecciones/filter',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMIN', 'INSPECTOR', 'TRAMITACION'], Inspecciones, { searchOnInit: true }),
        keepAlive: false
    },
    {
        path: '/inspecciones-offline/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMIN', 'INSPECTOR'], InspeccionesOffline),
        keepAlive: false
    },
    {
        path: '/inspecciones-offline/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMIN', 'INSPECTOR'], InspeccionesOffline),
        keepAlive: false
    },
    {
        path: '/inspecciones-offline-anteriores/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMIN', 'INSPECTOR'], InspeccionesOffline, { anteriores: true }),
        //keepAlive: true
    },
    {
        path: '/inspecciones/new-offline/:formato/:provincia/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/new', ['ADMIN', 'INSPECTOR'], InspeccionForm, { offline: true, nuevo: true })
    },
    {
        path: '/inspecciones/edit-offline/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/edit', ['ADMIN', 'INSPECTOR'], InspeccionForm, { offline: true })
    },
    {
        path: '/inspecciones/edit-offline/:id/:formato/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/edit', ['ADMIN', 'INSPECTOR'], InspeccionForm, { offline: true })
    },
    {
        path: '/inspecciones/view-offline/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/view', ['ADMIN', 'INSPECTOR'], InspeccionForm, { editable: false, offline: true })
    },
    {
        path: '/inspecciones/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/new', ['ADMIN', 'INSPECTOR'], InspeccionManualForm)
    },
    {
        path: '/inspecciones/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/edit', ['ADMIN', 'INSPECTOR'], InspeccionForm)
    },
    {
        path: '/inspecciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/view', ['ADMIN', 'INSPECTOR', 'TRAMITACION'], InspeccionForm, { editable: false })
    }
];
export default routes;