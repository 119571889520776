<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link v-tooltip="'Eliminar'" icon-f7="trash" @click="eliminar" v-if="!rform.readonly"></f7-link>
        <f7-link v-tooltip="'Duplicar'" icon-f7="plus_square_fill_on_square_fill" @click="duplicar" v-if="!rform.readonly"></f7-link>
      </f7-nav-left>
      <f7-nav-title>
        <f7-link @click="!rform.readonly ? editar() : _ => {return}">{{tab.nombre}}: {{elemento.nombre}}</f7-link>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="!rform.readonly" popup-close>Aceptar</f7-link>
        <f7-link v-else popup-close>Cerrar</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar position="top">
      <f7-link tab-link="#elemento-general" tab-link-active>General</f7-link>
      <f7-link tab-link="#elemento-comprobaciones">Comprobaciones</f7-link>
      <f7-link tab-link="#elemento-defectos">Defectos</f7-link>
      <f7-link tab-link="#elemento-caracteristicas">Características</f7-link>
      <f7-link tab-link="#elemento-medidas" v-if="tab.tipo=='CUADROS'">Medidas</f7-link>
      <f7-link tab-link="#elemento-reproduccion" v-if="tab.tipo=='CUADROS'">Reproducción</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="elemento-general" class="page-content" tab-active>
        <f7-block-title>Datos generales</f7-block-title>
        <div class="list">
          <f7-row no-gap class="">
              <r-input
                :form="rform"
                placeholder="Observaciones"
                label="Observaciones"
                type="text"
                :name="'tabs.'+indexTab+'.items.'+index+'.observaciones'"
                class="col-50 no-hairline textoAzul"
                :allMayus="true"
              ></r-input>
              <div class="item-row padding width-100">
                <r-files
                  :class="{ 'width-100': target != 'cordova' || rform.readonly, 'width-70': target == 'cordova' && !rform.readonly }"
                  class="overflow-auto"
                  :form="rform"
                  :name="'tabs.'+indexTab+'.items.'+ index + '.documentos'"
                  :documentoAdapter="target == 'cordova' ? DocumentoOffline : Documento"
                  :mostrarCheck="isImage"
                  :offlinePath="Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Generales`, rform.formData)"
                ></r-files>
                <f7-list v-if="!rform.readonly">
                  <f7-list-item
                    v-if="target == 'cordova'"
                    title="Capturar foto"
                    @click="Utils.capturarFoto(rform, 'tabs.' + indexTab + '.items.' + index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Generales`, rform.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="add_a_photo"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                  <f7-list-item
                    v-if="target == 'cordova'"
                    title="Grabar vídeo"
                    @click="Utils.grabarVideo(rform, 'tabs.' + indexTab + '.items.' + index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Generales`, rform.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="video_call"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                  <f7-list-item
                    v-if="target == 'cordova'"
                    title="Grabar audio"
                    @click="Utils.grabarAudio(rform, 'tabs.' + indexTab + '.items.' + index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Generales`, rform.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="mic"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                </f7-list>
              </div>
          </f7-row>
        </div>
        <f7-block-title>Comprobaciones</f7-block-title>
        <f7-list
          class="searchbar-found searchbar-found-defectos fichas-defectos no-hairlines"
        >
          <ul>
            <li
              v-for="(comprobacion, idx) in getComprobacionesConDatos() "
              :key="'datos-comprobacion-elemento-' + idx"
              class="card margin elevation-4"
            >
              <div class="card-header row align-items-center">
                <div class="col-80">
                  <span class="display-inline-block">{{ comprobacion.codigo }} - {{ comprobacion.nombre }}</span>
                </div>
              </div>
              <div class="card-content item-row">
                <div class="item-cell">
                  <div class="item-row">
                    <r-input
                      :form="rform"
                      outline
                      placeholder="Observaciones"
                      type="textarea"
                      :name="comprobacion.ruta + '.observaciones'"
                      class="width-100 textoAzul"
                      :allMayus="true"
                    ></r-input>
                  </div>
                  <div class="item-row">
                    <r-files
                      :class="{ 'width-100': target != 'cordova' || rform.readonly, 'width-70': target == 'cordova' && !rform.readonly }"
                      class="overflow-auto"
                      :prefixKey="comprobacion.ruta"
                      :form="rform"
                      :name="comprobacion.ruta + '.documentos'"
                      :documentoAdapter="target == 'cordova' ? DocumentoOffline : Documento"
                      :mostrarCheck="isImage"
                      :offlinePath="Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Comprobaciones/${comprobacion.codigo}`, rform.formData) "
                    ></r-files>
                    <f7-list v-if="!rform.readonly">
                      <f7-list-item
                        v-if="target == 'cordova'"
                        title="Capturar foto"
                        @click="Utils.capturarFoto(rform, comprobacion.ruta + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Comprobaciones/${comprobacion.codigo}`, rform.formData), $set)"
                        link="#"
                      >
                        <f7-icon
                          slot="media"
                          material="add_a_photo"
                          color="primary"
                        ></f7-icon>
                      </f7-list-item>
                      <f7-list-item
                        v-if="target == 'cordova'"
                        title="Grabar vídeo"
                        @click="Utils.grabarVideo(rform, comprobacion.ruta + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Comprobaciones/${comprobacion.codigo}`, rform.formData), $set)"
                        link="#"
                      >
                        <f7-icon
                          slot="media"
                          material="video_call"
                          color="primary"
                        ></f7-icon>
                      </f7-list-item>
                      <f7-list-item
                        v-if="target == 'cordova'"
                        title="Grabar audio"
                        @click="Utils.grabarAudio(rform, comprobacion.ruta + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Comprobaciones/${comprobacion.codigo}`, rform.formData), $set)"
                        link="#"
                      >
                        <f7-icon
                          slot="media"
                          material="mic"
                          color="primary"
                        ></f7-icon>
                      </f7-list-item>
                    </f7-list>
                  </div>
                </div>
                <div class="item-after align-items-center padding" v-if="!rform.readonly">
                  <a
                    class="link eliminar-observacion"
                    @click="eliminarObservacion(comprobacion)"
                    v-tooltip="'Eliminar Observación y documentos de la comprobación ' + comprobacion.codigo"
                  >
                    <i class="f7-icons">trash</i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </f7-list>
      </f7-tab>
      <f7-tab id="elemento-comprobaciones" class="page-content">
        <f7-list accordion-list>
          <f7-list-item
            accordion-item
            :title="comprobacion.nombre"
            v-for="(comprobacion,ic) in elemento.comprobaciones"
            :key="'elemento-comprobacion-'+comprobacion.codigo"
          >
            <div slot="media" class="display-flex align-self-center">{{comprobacion.codigo}}</div>
            <f7-accordion-content>
              <comprobacion
                v-for="(hija,ih) in comprobacion.hijas"
                :key="'elemento-comprobaciones.'+comprobacion.codigo+'.hijas.'+hija.codigo"
                :comprobacion="hija"
                :name="'tabs.'+indexTab+'.items.'+index+'.comprobaciones.'+ic+'.hijas.'+ih"
                :form="rform"
                :resultadoDefecto="hija.valorInicial"
                :tabletFormat="target == 'cordova'"
                :pathPadre="`Documentos/${tab.nombre}/${elemento.nombre}`"
              ></comprobacion>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </f7-tab>
      <f7-tab id="elemento-defectos" class="page-content">
        <div class="full-height">
          <f7-searchbar
            disable-button-text="Cancelar"
            placeholder="Buscar defecto por código"
            :clear-button="true"
            search-container=".searchbar-found-defectos-elemento"
            search-in=".card-header"
            style="overflow-x: hidden;"
            found-el=".searchbar-found-defectos-elemento"
            not-found-el=".searchbar-not-found-defectos-elemento"
          ></f7-searchbar>
          <div class="page-content no-padding">
            <f7-list class="searchbar-not-found searchbar-not-found-defectos-elemento">
              <f7-list-item title="No se han encontrado defectos"></f7-list-item>
            </f7-list>
            <f7-list class="searchbar-found searchbar-found-defectos-elemento no-hairlines">
              <ul>
                <li
                  v-for="(item, index) in getAllDefectosElemento()"
                  :key="'all-defectos-'+index"
                  class="card margin elevation-4"
                >
                  <div class="card-header row align-items-center">
                    <div class="col-50">
                      <span class="display-inline-block">{{ item.codigo }} {{ item.codigoVisual ? (' - ' + item.codigoVisual) : '' }} - {{ item.nombre }}</span>
                      <span class="display-inline-block padding-left">{{item.cuadro}}</span>
                    </div>
                    <div class="col-50">
                      <span class="display-inline-block">{{item.categoria}}</span>
                      <span class="display-inline-block padding-left">{{item.prop}}</span>
                    </div>
                  </div>
                  <div class="card-content item-row">
                    <div class="item-cell">
                      <div
                        class="item-row padding-horizontal padding-top-half align-items-center"
                        >
                        {{ item.descripcion }}
                        <i
                          class="material-icons"
                          v-tooltip="item.textoAyuda"
                          >info</i
                      >
                      </div>
                      <div class="item-row padding">
                        <r-input
                          :form="rform"
                          placeholder="Observaciones"
                          type="text"
                          :name="index+'.observaciones'"
                          class="width-100 textoAzul"
                          :allMayus="true"
                        ></r-input>
                      </div>
                      <div class="item-row padding">
                        <r-files
                          :class="{ 'width-100': target != 'cordova' || rform.readonly, 'width-70': target == 'cordova' && !rform.readonly }"
                          class="overflow-auto"
                          :form="rform"
                          :name="index + '.documentos'"
                          :documentoAdapter="target == 'cordova' ? DocumentoOffline : Documento"
                          :mostrarCheck="isImage"
                          :offlinePath="Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Defectos/${item.codigo}`, rform.formData) "
                        ></r-files>
                        <f7-list v-if="!rform.readonly">
                        <f7-list-item
                          v-if="target == 'cordova'"
                          title="Capturar foto"
                          @click="Utils.capturarFoto(rform, index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Defectos/${item.codigo}`, rform.formData), $set)"
                          link="#"
                        >
                          <f7-icon
                            slot="media"
                            material="add_a_photo"
                            color="primary"
                          ></f7-icon>
                        </f7-list-item>
                        <f7-list-item
                          v-if="target == 'cordova'"
                          title="Grabar vídeo"
                          @click="Utils.grabarVideo(rform, index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Defectos/${item.codigo}`, rform.formData), $set)"
                          link="#"
                        >
                          <f7-icon
                            slot="media"
                            material="video_call"
                            color="primary"
                          ></f7-icon>
                        </f7-list-item>
                        <f7-list-item
                          v-if="target == 'cordova'"
                          title="Grabar audio"
                          @click="Utils.grabarAudio(rform, index + '.documentos', Utils.getInspeccionPath(`Documentos/${tab.nombre}/${elemento.nombre}/Defectos/${item.codigo}`, rform.formData), $set)"
                          link="#"
                        >
                          <f7-icon
                            slot="media"
                            material="mic"
                            color="primary"
                          ></f7-icon>
                        </f7-list-item>
                      </f7-list>
                      </div>
                    </div>
                    <div class="item-after align-items-center padding" v-if="!rform.readonly">
                      <a
                        class="link eliminar-defecto"
                        @click="eliminarDefecto(index)"
                        v-tooltip="'Eliminar defecto'"
                      >
                        <i class="f7-icons">trash</i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </f7-list>
          </div>
        </div>
      </f7-tab>
      <f7-tab id="elemento-caracteristicas" class="page-content">
        <div
          v-for="(caracteristica, ic) in elemento.caracteristicas"
          :key="'caracteristica-' + caracteristica.nombre + '-' + ic"
          class="textoAzul"
        >
          <template 
            v-if="caracteristica.tipo == 'GROUP' && cumpleCaracteristicaCondicion(caracteristica, elemento.caracteristicas)"
          >
            <f7-block-title>{{ caracteristica.label }}</f7-block-title>
            <f7-list>
              <ul>
                <div 
                  v-for="(chija, ich) in caracteristica.hijas"
                  :key="'chija-' + chija.nombre + '-' + ich"
                >
                  <div
                    v-if="chija.tipo == 'GROUP'  && cumpleCaracteristicaCondicion(chija, elemento.caracteristicas)"
                  >
                    <f7-block-title>{{ chija.label }}</f7-block-title>
                    <f7-list>
                      <ul>
                        <div
                          v-for="(chija2, ich2) in chija.hijas"
                          :key="'chija2-' + chija2.nombre + '-' + ich + '-' + ich2"
                        >
                          <caracteristica
                            :key="'caract-chija2-' + chija2.nombre + '-' + ich + '-' + ich2"
                            v-if="cumpleCaracteristicaCondicion(chija2, elemento.caracteristicas)"
                            :tipo="chija2.tipo"
                            :caracteristica="chija2"
                            :form="rform"
                            :label="chija2.label"
                            :name="'tabs.'+indexTab+'.items.'+index+'.caracteristicas.' + ic + '.hijas.' + ich + '.hijas.' + ich2"
                          ></caracteristica>
                        </div>
                      </ul>
                    </f7-list>
                  </div>
                  <caracteristica
                    :key="'caract-chija-' + chija.nombre + '-' + ich"
                    v-else-if="cumpleCaracteristicaCondicion(chija, elemento.caracteristicas)"
                    :tipo="chija.tipo"
                    :caracteristica="chija"
                    :form="rform"
                    :label="chija.label"
                    :name="'tabs.'+indexTab+'.items.'+index+'.caracteristicas.' + ic + '.hijas.' + ich"
                  ></caracteristica>
                </div>
              </ul>
            </f7-list>
          </template>
        </div>
      </f7-tab>
      <f7-tab id="elemento-medidas" class="page-content" v-if="tab.tipo=='CUADROS'">
        <div class="list">
          <ul class="row inline-labels padding-vertical">
            <r-input
              outline
              :form="rform"
              type="number"
              :label="tab.version && tab.version >= 2 ? 'Rbucle (Ω)' : 'P. a tierra'"
              :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.puestaATierra'"
              class="col-50 no-hairline bordeAzul"
              tabindex= 101
            ></r-input>
            <r-input
              outline
              :form="rform"
              type="text"
              :label="tab.version && tab.version >= 2 ? 'Riso (MΩ)' : 'Aislamiento'"
              :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.aislamiento'"
              class="col-50 no-hairline bordeAzul"
              tabindex= 102
            ></r-input>
          </ul>
        </div>
        <div class="data-table card list">
          <div class="card-header">
            <div class="data-table-title">Medidas asociadas al cuadro</div>
            <div class="data-table-actions">
              <a class="link icon-only" @click="eliminarMedida" v-tooltip="'Eliminar medida'" v-if="!rform.readonly">
                <i class="icon f7-icons if-not-md">minus</i>
                <i class="icon material-icons md-only">minus</i>
              </a>
              <r-input
                :form="rform"
                type="integer"
                name= cantidadMasMenos
                class="cell-li-input textoAzul"
                tabindex= 103
              ></r-input>
              <a class="link icon-only" @click="anadirMedida" v-tooltip="'Añadir medida'" v-if="!rform.readonly">
                <i class="icon f7-icons if-not-md">plus</i>
                <i class="icon material-icons md-only">add</i>
              </a>
            </div>
          </div>
          <!-- Card Content -->
          <ul class="card-content">
            <table>
              <thead>
                <th>Identificación*</th>
                <th>Módulo</th>
                <th>Fila</th>
                <th>Posición</th>
                <th>V.I. disp (mA)</th>
                <th>V.T. disp (mS)</th>
                <th v-if="tab.version && tab.version >= 2">Vc (V)</th>
              </thead>
              <tbody>
                <tr v-for="(medida, im) in elemento.cuadro.medidas" :key="'cuadro-medida-'+im">
                  <td>
                    <r-input
                      :form="rform"
                      type="text"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.codigo'"
                      class="cell-li-input textoAzul"
                      :tabindex="1000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td>
                    <r-input
                      :form="rform"
                      type="integer"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.modulo'"
                      class="cell-li-input textoAzul"
                      :tabindex="2000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td>
                    <r-input
                      :form="rform"
                      type="integer"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.fila'"
                      class="cell-li-input textoAzul"
                      :tabindex="3000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td>
                    <r-input
                      :form="rform"
                      type="integer"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.posicion'"
                      class="cell-li-input textoAzul"
                      :tabindex="4000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td>
                    <r-input
                      :form="rform"
                      type="number"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.vi'"
                      class="cell-li-input textoAzul"
                      :tabindex="5000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td>
                    <r-input
                      :form="rform"
                      type="number"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.vt'"
                      class="cell-li-input textoAzul"
                      :tabindex="6000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td v-if="tab.version && tab.version >= 2">
                    <r-input
                      :form="rform"
                      type="number"
                      :name="'tabs.'+indexTab+'.items.'+index+'.cuadro.medidas.'+im+'.vc'"
                      class="cell-li-input textoAzul"
                      :tabindex="7000+im"
                      :clear-button="false"
                    ></r-input>
                  </td>
                  <td v-if="!rform.readonly">
                    <a class="link icon-only" @click="elemento.cuadro.medidas.splice(im,1)">
                      <i class="icon f7-icons if-not-md">trash</i>
                      <i class="icon material-icons md-only">delete</i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ul>
        </div>
      </f7-tab>
      <reproduccion
        v-if="tab.tipo=='CUADROS' "
        id="elemento-reproduccion"
        class="page-content"
        :rform="rform"
        :cuadroIdx="index"
        :indexTab="indexTab"
        :cuadro="elemento.cuadro"
      ></reproduccion>
    </f7-tabs>
  </f7-page>
</template>
<script>
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import Comprobacion from "./Comprobacion.vue";
import Caracteristica from "./Caracteristica.vue";
import Reproduccion from "./reproduccion.vue";
import Documento from "./../../js/db/Documento";
import DocumentoOffline from "./../../js/db/DocumentoOffline";
import Utils from "./../../js/Utils";
export default {
  data() {
    return {
      target: process.env.TARGET,
      Documento,
      DocumentoOffline,
      Utils
    };
  },
  components: {
    rInput,
    rFiles,
    Reproduccion,
    Comprobacion,
    Caracteristica
  },
  props: {
    index: { required: true },
    indexTab: { required: true },
    rform: { type: Object, required: true },
    elemento: { type: Object, required: true },
    tab: { type: Object, required: true }
  },
  methods: {
    isImage: function (item) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      return imageExtensions.includes(item.extension.toLowerCase());
    },
    cumpleCaracteristicaCondicion (caracteristica, caracteristicas) {
      const self = this;
      if (caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre) {
        const allCaracteristicas = self.getCaracteristicas(caracteristicas);
        return self.cumpleCondicion(caracteristica.mostrarCuando, allCaracteristicas);
      }
      return true;
    },
    getCaracteristicas (caracteristicas) {
      const self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap(c => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    cumpleCondicion (condicion, caracteristicas) {
      const self = this;
      const caracteristicasCoincidentes = caracteristicas.filter(c => c.nombre == condicion.nombre);
      if(!caracteristicasCoincidentes.length){
        return false;
      }
      const caracteristicaCoincidente = caracteristicasCoincidentes[0];
      var valorA = caracteristicaCoincidente.valor;
      var valorB = condicion.valor;
      if (caracteristicaCoincidente.tipo == "NUMBER") {
        valorA = parseInt(valorA);
        valorB = parseInt(valorB);
      }
      if (condicion.operacion == "IGUAL") {
        return valorA == valorB;
      } else if (condicion.operacion == "DIFERENTE") {
        return valorA != valorB;
      } else if (condicion.operacion == "MAYOR") {
        return valorA > valorB;
      } else if (condicion.operacion == "MENOR") {
        return valorA < valorB;
      }
    },
    eliminarDefecto: function(key) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm("¿Está seguro?", "Eliminar defecto", function() {
        var namespaces = key.split(".");
        var context = self.rform.formData;
        for (var i = 0; i < namespaces.length; i++) {
          if (i == namespaces.length - 1) {
            context.splice(parseInt(namespaces[i]), 1);
            //self.$forceUpdate();
          }
          context = context[namespaces[i]];
        }
        context = self.rform.formData;
        for (var i = 0; i < namespaces.length; i++) {
          if (i == namespaces.length - 2) {
            console.log(context[namespaces[i]]);
            if (!context.defectosSeleccionados || !context.defectosSeleccionados.length) {
              context.resultado = "F";
            }
            //self.$forceUpdate();
          }
          context = context[namespaces[i]];
        }
      });
    },
    editar() {
      var self = this;
      var app = self.$f7;
      app.dialog
        .create({
          title: "Editar elemento",
          content:
            `
				<div class="list no-hairlines">
				  <ul>
					<li class="item-content item-input">
					  <div class="item-inner">
						<div class="item-title item-floating-label">Nombre</div>
						<div class="item-input-wrap">
						  <input type="text" name="nombre" value="` +
            self.elemento.nombre +
            `" required>
						</div>
					  </div>
					</li>
					<li class="item-content item-input">
					  <div class="item-inner">
						<div class="item-title item-floating-label">Observaciones</div>
						<div class="item-input-wrap">
						  <textarea placeholder="" name="observaciones">` +
            self.elemento.observaciones +
            `</textarea>
						</div>
					  </div>
					</li>
				  </ul>
				</div>`,
          buttons: [
            {
              text: "Cancelar"
            },
            {
              text: "Ok",
              bold: true,
              close: false,
              onClick: function(dialog, e) {
                var $nombre = dialog.$el.find('input[name="nombre"]');
                app.input.validate($nombre);
                if ($nombre.hasClass("input-invalid")) return;
                var $observaciones = dialog.$el.find(
                  'textarea[name="observaciones"]'
                );
                self.elemento.nombre = $nombre.val();
                self.elemento.observaciones = $observaciones.val();
                dialog.close();
              }
            }
          ],
          on: {
            open: function(e) {
              e.$el.find("input,textarea").change();
            }
          }
        })
        .open();
    },
    duplicar() {
      var self = this;
      var app = this.$f7;
      app.dialog.prompt(
        "Nombre del nuevo elemento",
        "Duplicar elemento",
        function(v) {
          if (!v) {
            app.toast
              .create({
                text: "Inserte un nombre válido",
                position: "center",
                closeTimeout: 2000
              })
              .open();
            return;
          }
          let nuevoElemento = JSON.parse(JSON.stringify(self.elemento));
          if (self.tab.tipo == "CUADROS") {
            nuevoElemento.cuadro.puestaATierra = null;
            nuevoElemento.cuadro.aislamiento = null;
            nuevoElemento.cuadro.medidas = [];
          }
          nuevoElemento.comprobaciones = JSON.parse(JSON.stringify(self.tab.formato.comprobaciones));
          console.log(nuevoElemento);
          //TODO? Eliminar ID
          nuevoElemento.nombre = v;
          self.tab.items.push(nuevoElemento);
          app.toast
            .create({
              text: "Elemento " + v + " agregado con éxito",
              position: "center",
              closeTimeout: 2000
            })
            .open();
          app.popup.close();
          self.$emit("abrirPopup", self.tab.items.length - 1);
          /*setTimeout(function() {
          app.views.main.router.navigate(
            "/inspecciones/cuadro/" +
              (self.rform.formData.cuadros.length - 1) +
              "/",
            { props: { rform: self.rform } }
          );
        }, 500);*/
        }
      );
    },
    eliminar() {
      var self = this;
      var app = this.$f7;
      app.dialog.confirm("¿Está seguro?", "Eliminar elemento", function() {
        app.popup.close();
        setTimeout(function() {
          self.tab.items.splice(self.index, 1);
        }, 500);
      });
    },
    anadirMedida() {
      var self = this;
      if (!self.elemento.cuadro.medidas) {
        self.elemento.cuadro.medidas = [];
      }
      if (self.rform.formData.cantidadMasMenos == null || !Number.isInteger(self.rform.formData.cantidadMasMenos)) {
        self.rform.formData.cantidadMasMenos = 1;
      }
      for (var i=0; i< self.rform.formData.cantidadMasMenos; i++) {
        self.elemento.cuadro.medidas.push({
          manual: true,
          codigo:"",
          modulo: "",
          fila:"",
          posicion:"",
          vi:"",
          vt:"",
          vc:""
          
        });
      }
      self.rform.formData.cantidadMasMenos = null;
    },
    eliminarMedida() {
      var self = this;
      if (!self.elemento.cuadro.medidas || !self.elemento.cuadro.medidas.length > 0) {
        return;
      }
      var cantidadMasMenos = self.rform.formData.cantidadMasMenos;
      if (cantidadMasMenos == null  || !Number.isInteger(cantidadMasMenos)) {
        cantidadMasMenos = 1;
      }
      var medidas = self.elemento.cuadro.medidas;
      var lengthMedidas = medidas.length;
      if (cantidadMasMenos > lengthMedidas) {
        cantidadMasMenos = lengthMedidas;
      }
      var todosVacios = true;
      for(var i=1; i<= cantidadMasMenos; i++) {
        var medida = self.elemento.cuadro.medidas[lengthMedidas-i];
        if (medida.codigo || medida.modulo || medida.fila || medida.posicion || medida.vi || medida.vt || medida.vc) {
          todosVacios = false;
        }
      }
      if (todosVacios) {
        for(var i=1; i<= cantidadMasMenos; i++) {
          medidas.splice(lengthMedidas-i,1);
        }
        self.rform.formData.cantidadMasMenos = null;
      } else {
         app.dialog.confirm(
            "Se van a eliminar medidas que contienen datos, ¿Desea continuar?",
            "Eliminar medidas",
            function() {
              for(var i=1; i<= cantidadMasMenos; i++) {
                medidas.splice(lengthMedidas-i,1);
              }
              self.rform.formData.cantidadMasMenos = null;
            }
          );
      }
    },
    eliminarObservacion(comprobacion) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm("¿Está seguro?", "Eliminar observación y documentos de la comprobación", function () {
        comprobacion.observaciones = "";
        comprobacion.documentos = [];
      });
    },
    getComprobacionesConDatos: function () {
      const self = this;
      if (self.rform && self.rform.formData) {
        // vamos a buscar todas las comprobaciones que tengan datos (observaciones o documentos)
        var formData = self.rform.formData;
        let tab = formData.tabs[self.indexTab];
        let item = tab.items[self.index];
        var name = "tabs." + self.indexTab + ".items." + self.index + ".comprobaciones"
        var comprobaciones = (!item || !item.comprobaciones) ? [] : item.comprobaciones
        var comprobacionesConDatos = self.getAllComprobaciones(comprobaciones, name).filter(c => c.observaciones || (c.documentos && c.documentos.length));
        return comprobacionesConDatos;
      }
      return [];
    },
    // vamos a convertir el árbol de comprobaciones en un array de comprobaciones
    getAllComprobaciones: function (comprobaciones, rutaPadre) {
      const self = this;
      // let orden = 0;
      let res = [];
      comprobaciones.forEach(function (c, i) {
        c.ruta = rutaPadre + "." + i;
        res.push(c);
        if (c.hijas && c.hijas.length) {
          // si tiene hijas, las añadimos al array
          res = res.concat(self.getAllComprobaciones(c.hijas, c.ruta + ".hijas"));
        }
      });
      return res;
    },
    getAllDefectosElemento() {
      var self = this;
      var app = this.$f7;
      var formData = self.rform.formData;
      var res = {};

      let tab = formData.tabs[self.indexTab];
      let item = tab.items[self.index];
      for (let ci in item.comprobaciones) {
        var c = item.comprobaciones[ci];
        var name = "tabs." + self.indexTab + ".items." + self.index + ".comprobaciones." + ci;
        self.recorrerHijas(c, name, res);
        
      }
      return res;
    },

    recorrerHijas (padre, nombrePadre, res) {
      var self = this;
      if (padre.defectosSeleccionados && padre.defectosSeleccionados.length) {
        for (let dsi in padre.defectosSeleccionados) {
          let ds = padre.defectosSeleccionados[dsi];
          ds.defectos = padre.defectos;
          res[
              nombrePadre +
              ".defectosSeleccionados." +
              dsi
          ] = ds;
        }
      }
      if (padre.hijas && padre.hijas.length) {
        for (let hi in padre.hijas) {
          var h = padre.hijas[hi];
          var name = nombrePadre + ".hijas." + hi;
          self.recorrerHijas(h, name, res);

        }
      }
    },
  }
};
</script>
<style>
.cell-li-input {
  border-right: 1px solid #ccc;
}
#elemento-general .list .textoAzul input[type="text"] {
  color: blue;
  text-transform: uppercase;
}
#elemento-defectos .list .textoAzul input[type="text"] {
  color: blue;
  text-transform: uppercase;
}
#elemento-medidas .list .bordeAzul input[type="number"],
#elemento-medidas .list .bordeAzul input[type="text"] {
  color: blue;
  text-transform: uppercase;
}
#elemento-medidas .bordeAzul .item-input-outline .item-input-wrap:after {
  border-color: lightblue;
}
#elemento-medidas .textoAzul input[type="text"],
#elemento-medidas .textoAzul input[type="number"],
#elemento-medidas .textoAzul input[type="integer"] {
  color: blue;
  text-transform: uppercase;
}
#elemento-caracteristicas .textoAzul  input[type="text"],
#elemento-caracteristicas .textoAzul  textarea,
#elemento-caracteristicas .textoAzul  input[type="number"],
#elemento-caracteristicas .textoAzul  select
{
  color: blue;
  border: solid 1px  lightblue;
  text-transform: uppercase;
}
</style>
<style scoped>
.list div.row {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    background: var(--f7-list-bg-color);
}
</style>