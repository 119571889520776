import Utils from '../Utils.js';
import Sugerencia from './Sugerencia.js';
import moment from 'moment';
import DocumentoOnline from './Documento.js';
import DocumentoOffline from './DocumentoOffline.js';
import FileUtils from '../FileUtils.js';

var DatabaseModel = {
    database: "simecaldb",
    getList: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            const collection = self.model;
            var order = {};
            if (params.order) {
                order[params.order] = params.orderDir == 'ASC' ? 1 : -1;
            }
            var projections = "";
            if (params.projection) {
                if (Array.isArray(params.projection) && params.projection.length) {
                    projections = params.projection.join(',');
                } else if (typeof params.projection == 'string') {
                    projections = params.projection;
                } 
            }
            window.db.find(self.database, collection, params.filter || {}, order, params.offset, params.limit, projections)
            .then((result) => {
                window.db.count(self.database, collection, params.filter || {})
                .then(count => {
                    resolve({ list: result, count });
                }).catch((error) => {
                    console.error(error);
                    reject({error: error});
                });
            })
            .catch((error) => {
                console.error(error);
                reject({error: error});
            });
        });
    },
    get: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            const collection = self.model;
            if (!params.itemId) reject({ error: 'itemId not defined' });
            window.db.findOne(self.database, collection, { id: params.itemId })
            .then((result) => {
                resolve( result );
            })
            .catch((error) => {
                console.error(error);
                reject({ error: 'No se encuentra el registro' });
            });
        });
    },
    put: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            const collection = self.model;
            if (!params.itemId) reject({ error: 'itemId not defined' });
            delete params.data._id;
            window.db.replaceOne(self.database, collection, { id: params.itemId }, params.data)
            .then((result) => {
                if (result) {
                    resolve(params.data);
                } else {
                    reject({ error: 'No se ha actualizado.' });
                }
            })
            .catch((error) => {
                console.error(error);
                reject({ error: error });
            });
        });
    },
    putAll: function(items) {
        var self = this;
        items = items || [];
        return new Promise(function(resolve, reject) {
            var results = [];
            var p = Promise.resolve();
            const collection = self.model;
            items.forEach(item => {
                p = p.then(_ => new Promise(function(resolve, reject) {
                    delete item._id;
                    window.db.replaceOne(self.database, collection, {id: item.id}, item)
                    .then((result) => {
                        results.push(result);
                        resolve();
                    }).catch((error) => reject(error));
                }));
            });
            p.then((_) => resolve(results)).catch(e => reject(e));
        });
    },
    post: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            const collection = self.model;
            window.db.replaceOne(self.database, collection, {id: params.data.id}, params.data)
                .then((result) => {
                    resolve(result);
                }).catch((error) => reject(error));
        });
    },
    delete: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            const collection = self.model;
            if (!params.itemId) reject({ error: 'itemId not defined' });
            window.db.deleteOne(self.database, collection, {id: params.itemId})
            .then((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject({ error: "No se ha podido realizar el borrado" });
                }
            })
            .catch((error) => {
                console.error(error);
                reject({error: error});
            });
        });
    },
    syncUp: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.data) {
                reject({ error: 'Datos no definidos' });
            }
            // var albaran = JSON.parse(JSON.stringify(params.data));
            // albaran.albaranFirmado = params.data.albaranFirmado;

            // if (!params.sugerencia) {
            //     if (albaran.id.startsWith('local')) {
            //         delete albaran.id;
            //     } 
            //     delete albaran._id;
            //     delete albaran.$loki;
            //     delete albaran.meta;
            // }

            self.model = "inspecciones";
            let inspeccionesIds = params.data.map(i => i.id || i._id);
            return self.getList({filter: {id: {"$in": inspeccionesIds}}}).then(function(res) {
                if (res.error) {
                    reject(data);
                    return;
                }
                let inspecciones = res.list;
                var error = self.comprobarExpedientes(inspecciones);
                if (error && error.idExp) {
                    reject(error);
                    return;
                }


                // if (params.sugerencia) {
                //     albaran.expedientes = inspecciones;
                //     return Sugerencia.post({
                //         data: { sugerencia: JSON.stringify(albaran), albaranMalo: true}
                //       }).then(data => resolve(data)).catch( e => reject(e));

                // }
                for (let i = 0; i < inspecciones.length; i++) {
                    var inspeccion = inspecciones[i];
                    if (inspeccion.id.startsWith('local')) {
                        delete inspecciones[i].id;
                    } 
                    delete inspecciones[i].$loki;
                    delete inspecciones[i]._id;
                    delete inspecciones[i].meta;
                    delete inspecciones[i].idAlbaran;
                    delete inspecciones[i].codigoAlbaran;
                }
                var inspeccionesString = JSON.stringify(inspecciones);
                while (inspeccionesString.match(/\{\"\$numberLong\":\"(.*)\"\}/)) {
                    inspeccionesString = inspeccionesString.replace(/\{\"\$numberLong\":\"([0-9]+)\"\}/,'$1');
                }
                inspecciones = JSON.parse(inspeccionesString);
                // albaran.expedientes = inspecciones;
                return self.uploadDocuments(inspecciones).then(function(data) {
                    return self.request.promise({
                        url: self.server_movilidad + self.prefix + 'expediente/sync/',
                        method: 'POST',
                        contentType: 'application/json',
                        dataType: 'json',
                        crossDomain: true,
                        data: data
                    }).then(function({ data, xhr, status }) {
                        if (data.error) {
                            reject(data);
                        } else {
                            var p = Promise.resolve();
                            data.list.forEach(function(item, idx) {
                                item.sincronizado = true;
                                delete item._id;
                                Utils.moveInspeccionToBorrar(item, inspeccionesIds[idx]);
                                var today = moment().format("YYYYMMDD"),
                                    provincia = item.direccionInspeccion && item.direccionInspeccion.provincia ? item.direccionInspeccion.provincia : "00",
                                    formato = item.formato.codigo.replaceAll(/\s/g, ""),
                                    newName = item.codigo ? item.codigo.replaceAll("/", "_") : '',
                                    folder = `Documents/SIMECAL/${formato}/${provincia}/${inspeccionesIds[idx]}`,
                                    newFolder = `Documents/SIMECAL/Borrar/${today}/${newName}`;
                                self._getAllDocuments(item).forEach(doc => {
                                    doc.offlinePath = doc.offlinePath ? doc.offlinePath.replace(folder, newFolder) : '';
                                })
                                p = p.then(_ => new Promise(function(resolve, reject) {
                                    window.db.replaceOne(self.database, 'inspecciones', {id: inspeccionesIds[idx]}, item)
                                    .then((_) => {
                                        resolve();
                                    }).catch((error) => reject(error));
                                }));
                            });
                            p.then((_) => {
                                // data.list = data.dto.expedientes.map(e => e.id);
                                self.getList({filter: {id: {"$in": inspeccionesIds}}}).then(function(result) {
                                    resolve(result.list);
                                }).catch((error) => reject(error));
                            }).catch(e => reject(e));
                        }
                    }).catch( function(e) {
                        self._catchError(e, reject);
                    });
                }).catch(function(e) {
                    self._catchError(e, reject);
                });
            }).catch( function(e) {
                self._catchError(e, reject);
            });
        });
    },
    syncUpAlbaran: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.data) {
                reject({ error: 'Datos no definidos' });
            }
            var albaran = JSON.parse(JSON.stringify(params.data));
            albaran.albaranFirmado = params.data.albaranFirmado;

            if (!params.sugerencia) {
                if (albaran.id.startsWith('local')) {
                    delete albaran.id;
                } 
                delete albaran._id;
                delete albaran.$loki;
                delete albaran.meta;
            }

            self.model = "inspecciones";
            return self.getList({filter: {id: {"$in": albaran.expedientes}}}).then(function(res) {
                if (res.error) {
                    reject(data);
                    return;
                }
                var inspecciones = res.list;
                var error = self.comprobarExpedientes(inspecciones);
                if (error && error.idExp) {
                    reject(error);
                    return;
                }


                if (params.sugerencia) {
                    albaran.expedientes = inspecciones;
                    return Sugerencia.post({
                        data: { sugerencia: JSON.stringify(albaran), albaranMalo: true}
                      }).then(data => resolve(data)).catch( e => reject(e));

                }
                for (let i = 0; i < inspecciones.length; i++) {
                    var inspeccion = inspecciones[i];
                    if (inspeccion.id.startsWith('local')) {
                        delete inspecciones[i].id;
                    } 
                    delete inspecciones[i].$loki;
                    delete inspecciones[i]._id;
                    delete inspecciones[i].meta;
                    delete inspecciones[i].idAlbaran;
                    delete inspecciones[i].codigoAlbaran;
                }
                var inspeccionesString = JSON.stringify(inspecciones);
                while (inspeccionesString.match(/\{\"\$numberLong\":\"(.*)\"\}/)) {
                    inspeccionesString = inspeccionesString.replace(/\{\"\$numberLong\":\"([0-9]+)\"\}/,'$1');
                    
                }
                inspecciones = JSON.parse(inspeccionesString);
                albaran.expedientes = inspecciones;
                return self.uploadDocuments(albaran).then(function(data) {
                    return self.request.promise({
                        url: self.server_movilidad + self.prefix + 'albaran/sync/',
                        method: 'POST',
                        contentType: 'application/json',
                        dataType: 'json',
                        crossDomain: true,
                        data: data
                    }).then(function({ data, xhr, status }) {
                        if (data.error) {
                            reject(data);
                        } else {
                            var p = Promise.resolve();
                            data.dto.expedientes.forEach(function(item, idx) {
                                item.sincronizado = true;
                                delete item._id;
                                p = p.then(_ => new Promise(function(resolve, reject) {
                                    window.db.replaceOne(self.database, 'inspecciones', {id: params.data.expedientes[idx]}, item)
                                    .then((_) => {
                                        resolve();
                                    }).catch((error) => reject(error));
                                }));
                            });
                            p.then((_) => {
                                data.dto.expedientes = data.dto.expedientes.map(e => e.id);
                                window.db.replaceOne(self.database, 'albaranes', {id: params.data.id}, data.dto)
                                .then((result) => {
                                    resolve(result);
                                }).catch((error) => reject(error));
                            }).catch(e => reject(e));
                        }
                    }).catch( function(e) {
                        self._catchError(e, reject);
                    });
                }).catch(function(e) {
                    self._catchError(e, reject);
                });
            }).catch( function(e) {
                self._catchError(e, reject);
            });
        });
    },
    expMalo: function (expediente) {
        return Sugerencia.post({
            data: { sugerencia: JSON.stringify(expediente), expedienteMalo: true}
          });
        
    },
    cleanData: function() {
        const self = this;
        var p = Promise.resolve();
        ['configuraciones', 'componentesProteccion', 'equipos', 'clientes', 'formatos', 'inspectores', 'municipios', 'provincias', 'paises', 'comunidades', 'evaluadoresjs'].forEach(function(el) {
            p = p.then(_ => new Promise(function(resolve, reject) {
            window.db.deleteAll(self.database, el)
                .then(_ => resolve())
                .catch((error) => reject(error));
            }));
        });
        p = p.then(_ => new Promise(function(resolve, reject) {
            window.db.deleteMany(self.database, 'inspecciones', { codigo: { $exists: false }, guardadoLocal: { $exists: false } })
            .then(_ => resolve())
            .catch(_ => resolve());
        }));
        return p;
    },
    sync: function(params) {
        const self = this;
        params = params || {};
        params.data = params.data || {};
        var p = Promise.resolve();
        p = p.then(_ => new Promise(function(resolve, _) {
            window.db.findOne(self.database, 'configuraciones', {id: 'CONFIG'})
            .then(result => resolve(result))
            .catch(_ => resolve(null));
        }));
        p = p.then(configuracion => new Promise(function(resolve, _) {
            if (configuracion) {
                params.data.syncDate = configuracion.syncDate;
                params.data.dispositivo = configuracion.dispositivo;
            }
            if (!params.data.dispositivo) {
                if (typeof device != "undefined") {
                    params.data.dispositivo = device;
                } else {
                    params.data.dispositivo = {};
                    params.data.dispositivo.uuid = "WEB_" + window.app.data.user.username;
                }
            }
            resolve(configuracion);
        }));

        p = p.then(configuracion => new Promise(function(resolve, reject) {
            self.request.promise({
                url: self.server_movilidad + self.prefix + 'sincronizacion/',
                method: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                crossDomain: true,
                data: params.data
            }).then(function({ data, xhr, status }) {
                if (data.error) {
                    reject(data);
                } else {

                    if (data.dto.properties) {
                        localStorage.setItem('properties', JSON.stringify(data.dto.properties));
                    } else {
                        localStorage.setItem('properties', JSON.stringify([]));
                    }

                    var p = Promise.resolve();
                    ['componentesProteccion', 'equipos', 'formatos', 'inspectores', 'municipios', 'provincias', 'paises', 'comunidades', 'evaluadoresjs', 'clientes'].forEach(function(el) {
                        p = p.then(_ => new Promise(function(resolve, reject) {
                            // Se comenta ya que ahora los municipios no tiene gfecha modificacion y siempre devuelven todos, 
                            // Cuando esto se arregle, debería descomentarse esta linea
                            if (el == 'clientes' || el == 'municipios') {
                            //if (el == 'clientes') {
                                self._replaceInCollection(el, data.dto[el], resolve, reject, params.data.syncDate);
                            } else {
                                self._reloadCollection(el, data.dto[el], resolve, reject);
                            }
                        }));
                    });
                    
                    p = p.then(_ => new Promise(function(resolve, reject) {
                        window.db.deleteMany(self.database, 'inspecciones', { codigo: { $exists: false }, guardadoLocal: { $exists: false } })
                        .then(_ => resolve())
                        .catch(_ => resolve());
                    }));

                    // p = p.then(_ => new Promise(function(resolve, reject) {
                    //     window.db.deleteMany(self.database, 'inspecciones', { 'cliente.razonSocial': { $exists: false } })
                    //     .then(_ => resolve())
                    //     .catch(_ => resolve());
                    // }));

                    p = p.then(_ => new Promise(function(resolve, reject) {
                        window.db.deleteMany(self.database, 'inspecciones', { $and: [{ codigo: {$exists: true}, fechaInsp: {$lte: moment(new Date()).add(-7,"day").format()} }] })
                        .then(_ => resolve())
                        .catch(_ => resolve());
                    }));

                    p = p.then(_ => new Promise(function(resolve, reject) {
                        self._insertNotExistsInCollection('inspecciones', data.dto['expedientes'], resolve, reject);
                    }));
                    

                    p = p.then(_ => new Promise(function(resolve, reject) {
                        var dispositivo = data.dto['dispositivo'];
                        var syncDate = new Date();

                        if (!configuracion) {
                            configuracion = {id: "CONFIG"};
                        }
                        configuracion.syncDate = syncDate;
                        var dispositivoActual = configuracion.dispositivo;
                        if (dispositivoActual && dispositivo.anyo == dispositivoActual.anyo && dispositivo.secAlbaran > dispositivoActual.secAlbaran) {
                            dispositivoActual.secAlbaran = dispositivo.secAlbaran;
                        } else if (!dispositivoActual) {
                            configuracion.dispositivo = dispositivo;
                        } else {
                            configuracion.dispositivo = dispositivoActual;
                        }
                        window.db.replaceOne(self.database, "configuraciones", {id: 'CONFIG'}, configuracion)
                        .then((result) => {
                            resolve();
                        }).catch(e => self._catchError(e, reject));
                    }));
                    
                    p.then(_ => resolve());
                    p.catch(e => self._catchError(e, reject));
                    return p;
                }
            }).catch(function(e) {
                self._catchError(e, reject);
            });
        }));
        p = p.then(_ => new Promise(function(resolve, _) {
            Utils.removeBackups();
            resolve();
        }));
        return p;
    },
    _catchError(e, reject) {
        if (typeof e == 'string') e = { error: e };
        if (typeof e == 'undefined') e = { error: 'Error desconocido' };
        if (e.xhr) {
            var { xhr, status, message } = e;
            if (status == 0) {
                // Offline
                reject({ error: 'Se necesita conexión a internet para sincronizar las inspecciones' });
            } else if (status == 401) {
                // Offline
                reject({ error: 'invalid_token'});
            } else {
                try {
                    reject(JSON.parse(xhr.response));
                } catch (e) {
                    reject(xhr.response);
                }
            }
        } else {
            reject(e);
        }
    },
    _reloadCollection(collection, list, resolve, reject) {
        const self = this;
        console.log("_reloadCollection: " + collection);
        window.db.deleteAll(self.database, collection)
            .then((result) => {
                console.log("deleteAll OK: " + collection);
            self._insertMany(collection, list, resolve, reject);
        }).catch((error) => reject(error));
    },
    _insertMany(collection, list, resolve, reject) {
        const self = this;
        if (list && list.length) {
            console.log("_insertMany: " + collection + " -> " + list.length);
            var p2 = Promise.resolve();
            if (list.length > 1000 || (collection == 'inspecciones' && list.length > 5) || (collection == 'formatos' && list.length > 10)) {
                while(list.length) {
                    const dataToSend = collection == 'inspecciones' ? list.splice(0, 5) : (collection == 'formatos' ? list.splice(0, 10) : list.splice(0, 1000));
                    p2 = p2.then(_ => new Promise(function(resolve, reject) {
                        window.db.insertMany(self.database, collection, dataToSend)
                            .then((result) => {
                                console.log("insertMany OK: " + collection);
                            resolve();
                        }).catch((error) => reject(error));
                    }));
                }
            } else {
                p2 = p2.then(_ => new Promise(function(resolve, reject) {
                    window.db.insertMany(self.database, collection, list)
                        .then((result) => {
                            console.log("insertMany OK: " + collection);
                        resolve();
                    }).catch((error) => reject(error));
                }));
            }
            p2.then((_) => resolve()).catch(e => reject(e));
        } else {
            resolve();
        }
    },
    _replaceInCollection(collection, list, resolve, reject, syncDate) {
        const self = this;
        console.log("_replaceInCollection: " + collection);
        if (!list || !list.length) {
            resolve();
        } else {
            if (!!!syncDate) {
                self._reloadCollection(collection, list, resolve, reject);
            } else {
                if (list.length > 500) {
                    const ids = list.map(l => l.id);
                    window.db.deleteMany(self.database, collection, { id: { $in: ids } })
                        .then(res => {
                            console.log("deleteMany OK: " + collection);
                            self._insertMany(collection, list, resolve, reject);
                        })
                        .catch(e => reject(e));
                } else {
                    window.db.count(self.database, collection, {})
                    .then((result) => {
                        if (result && result > 0) {
                            var p2 = Promise.resolve();
                            list.forEach(l => {
                                Utils.cleanObj(l);
                                p2 = p2.then(_ => new Promise(function(resolve, reject) {
                                    window.db.replaceOne(self.database, collection, {id: l.id}, l)
                                        .then((result) => {
                                            console.log("replaceOne OK: " + collection);
                                        resolve();
                                    }).catch((error) => reject(error));
                                }));
                            });
                            p2.then((_) => resolve()).catch(e => reject(e));
                        } else {
                            self._reloadCollection(collection, list, resolve, reject);
                        }
                    }).catch((error) => reject(error));
                }

            }
        }
    },
    _insertNotExistsInCollection(collection, list, resolve, reject) {
        const self = this;
        if (!list || !list.length) {
            resolve();
        } else {
            window.db.count(self.database, collection, {})
            .then(async (result) => {
                if (result && result > 0) {
                    var p2 = Promise.resolve();
                    list.forEach(l => {
                        Utils.cleanObj(l);
                        p2 = p2.then(_ => new Promise(function(resolve, reject) {

                            window.db.findOne(self.database, collection, {id: l.id})
                            .then(async (result) => {
                                if (!result) {
                                    if (collection == 'inspecciones' && l && l.estado && l.estado.estado && l.estado.estado == 'PLANIFICADO') {
                                        self._downloadDocuments(l).then(() => {
                                            window.db.insertOne(self.database, collection, l)
                                                .then(() => {
                                                    resolve();
                                                }).catch((error) => reject(error));
                                        }).catch((error) => reject(error));
                                        // try {
                                        //     await self._downloadDocuments(l);
                                        // } catch(e) {
                                        //     console.log("ERROR EN LA DESCARGA DE DOCUMENTOS", e);
                                        //     reject(e);
                                        // }
                                    } else {
                                        window.db.insertOne(self.database, collection, l)
                                            .then(() => {
                                                resolve();
                                            }).catch((error) => reject(error));
                                    }
                                } else {
                                    resolve();
                                }
                            }).catch((error) => reject(error));
                        }));
                    });
                    p2.then((_) => resolve()).catch(e => reject(e));
                } else {
                    let promises = [];
                    if (collection == 'inspecciones') {
                        // let promises = [];
                        for (let i = 0; i < list.length; i++) {
                            let l = list[i];
                            if (l && l.estado && l.estado.estado && l.estado.estado == 'PLANIFICADO') {
                                // try {
                                    promises.push(self._downloadDocuments(l));
                                    // await self._downloadDocuments(l);
                                // } catch(e) {
                                //     console.log("ERROR EN LA DESCARGA DE DOCUMENTOS", e);
                                //     reject(e);
                                // }
                            }
                        }
                        // try {
                        //     await Promise.all(promises)
                        // } catch(e) {
                        //     console.log("ERROR DESCARGARDO DOCUMENTOS", e);
                        // }
                    }
                    if (promises.length) {
                        Promise.all(promises).then(() => {
                            self._reloadCollection(collection, list, resolve, reject);
                        }).catch((e) => {
                            console.log("ERROR DESCARGARDO DOCUMENTOS", e);
                            self._reloadCollection(collection, list, resolve, reject);
                        })
                    } else {
                        self._reloadCollection(collection, list, resolve, reject);
                    }
                }
            }).catch((error) => reject(error));
        }
    },
    _getAllDocuments(insp) {
        const self = this;
        let documentos = [];
        if (insp.documentos && insp.documentos.length) {
            documentos = [...documentos, ...insp.documentos];
        }
        let docCompro = self._getDocumentosComprobaciones(insp.comprobaciones);
        if (docCompro && docCompro.length) {
            documentos = [...documentos, ...docCompro];
        }
        let docTabs = self._getDocumentosTabs(insp.tabs);
        if (docTabs && docTabs.length) {
            documentos = [...documentos, ...docTabs];
        }
        return documentos;
    },
    _downloadDocuments(insp) {
        const self = this;
        let documentos = self._getAllDocuments(insp);
        return new Promise(function(resolve, reject) {
            console.log("Comenzando descarga de documentos de la inspección " + (insp.id || insp._id));
            // var p = Promise.resolve();
            let promises = [];
            if (documentos && documentos.length) {
                for (let i = 0; i < documentos.length; i++) {
                    let doc = documentos[i];
                    try {
                        promises.push(new Promise(function(resolve, reject) {
                            console.log("get Documento DOC " + (insp.id || insp._id), doc);
                            DocumentoOnline.getDocumentoScaled(doc, 'blob').then((blobData) => {
                                console.log("OBTENIDO Documento " + (insp.id || insp._id), doc);
                                if (blobData.size > 10000000) {
                                    doc.alreadySaved = true;
                                    doc.descargadoInicial = true;
                                    console.log("FIN DOC SIN GUARDAR" + (insp.id || insp._id), doc);
                                    resolve()
                                } else {
                                    DocumentoOffline.saveBlobInspeccion(blobData, insp, "Documentos/Descargados", doc.nombre + '.' + doc.extension)
                                        .then(({ filePath, localURL }) => {
                                            doc.alreadySaved = true;
                                            doc.descargadoInicial = true;
                                            doc._localURL = localURL;
                                            doc.offlinePath = filePath;
                                            console.log("FIN DOC " + (insp.id || insp._id), doc);
                                            resolve();
                                        }).catch((e) => {
                                            console.log("ERROR GUARDANDO BLOB DOCUMENTO: ", e);
                                            resolve();
                                        });
                                    // FileUtils.saveBlobFile(blobData, doc.nombre, doc.extension, '/documentos/', (resSave) => {
                                    //     console.log(resSave);
                                    //     doc.alreadySaved = true;
                                    //     doc.descargadoInicial = true;
                                    //     console.log("FIN DOC " + (insp.id || insp._id), doc);
                                    //     resolve();
                                    // }, reject, true);
                                }
                            }).catch((e) => {
                                console.log("ERROR DESCARGANDO DOCUMENTO: ", e);
                                resolve();
                            });
                        }));
                    } catch (e) {
                        console.log("ERROR DESCARGANDO DOCUMENTO: ", e);
                    }
                }
            }

            Promise.all(promises).then(() => {
                console.log("Todos los documentos descargados de la inspección " + (insp.id || insp._id));
                resolve();
            }).catch((e) => {
                console.log("Error en la descarga de documentos de la inspección " + (insp.id || insp._id), e);
                resolve();
            });
        });
    },
    _getDocumentosTabs(tabs) {
        const self = this;
        let documentos = [];
        if (tabs && tabs.length) {
            tabs.forEach(t => {
                if (t.items && t.items.length) {
                    t.items.forEach(t => {
                        let docCompro = self._getDocumentosComprobaciones(t.comprobaciones);
                        documentos = [...documentos, ...docCompro];
                        if (t.documentos && t.documentos.length) {
                            documentos = [...documentos, ...t.documentos];
                        }
                    });
                }
            });
        } 
        return documentos
    },
    _getDocumentosComprobaciones(comprobaciones) {
        const self = this;
        let documentos = [];
        if (comprobaciones && comprobaciones.length) {
            comprobaciones.forEach(c => {
                if (c.defectosSeleccionados && c.defectosSeleccionados.length) {
                    c.defectosSeleccionados.forEach(d => {
                        if (d.documentos && d.documentos.length) {
                            documentos = [...documentos, ...d.documentos];
                        }
                    });
                }
                if (c.documentos && c.documentos.length) {
                    documentos = [...documentos, ...c.documentos];
                }
                if (c.hijas && c.hijas.length) {
                    let docHijas = self._getDocumentosComprobaciones(c.hijas);
                    if (docHijas && docHijas.length) {
                        documentos = [...documentos, ...docHijas];
                    }
                }
            });
        } 
        return documentos
    },
    createIndex (collection, indexProperty) {
        const self = this;
        return new Promise(function(resolve, reject) {
            window.db.createIndex(self.database, collection, indexProperty)
                .then((result) => {
                    resolve(result);
                }).catch((error) => reject(error));
        });
    }
}

export default DatabaseModel;