import MediumEditor from 'medium-editor';

var Provisional = MediumEditor.Extension.extend({
    name: 'provisional',
    title: 'Provisional',
    provisional: true,

    init: function () {
        this.button = this.document.createElement('button');
        this.button.classList.add('medium-editor-action');
        this.button.classList.add('provisional-button');
        var image = this.document.createElement('i');
        image.classList.add("icon");
        image.classList.add("f7-icons");
        image.innerText = "hammer_fill";

        this.button.append(image);
        this.button.title = this.title;
        this.on(this.button, 'click', this.handleClick.bind(this));
    },

    getButton: function () {
        return this.button;
    },

    handleClick: function (event) {
        if(!this.isProvisional()){
            this.activate();
        } else {
            this.deactivate();
        }
    },

    isActive: function () {
        return this.button.classList.contains('medium-editor-button-active');
    },

    deactivate: function () {
        this.provisional = false;
        this.button.classList.remove('medium-editor-button-active');
        var pf = this._mainPf();
        if(!pf){
            return;
        }
        pf.classList.remove("provisional");
    },
    
    activate: function () {
        this.provisional = true;
        this.button.classList.add('medium-editor-button-active');
        var pf = this._mainPf();
        if(!pf){
            return;
        }
        pf.classList.add("provisional");
    },

    _mainPf: function(){
        var pfs = this.document.getElementsByClassName("pf"),
            pf = pfs.length ? pfs[0] : null;
        return pf;
    },

    setInitialContent: function (content) {
        var body = (content.getElementsByTagName("body").length) ? content.getElementsByTagName("body")[0] : null;
        if(Array.from(body.classList).indexOf("provisional") >= 0){
            this.activate();
        } else {
            this.deactivate();
        }
    },

    isProvisional: function(){
        return this.provisional;
    }

})

export default Provisional;