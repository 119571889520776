import MediumEditor from 'medium-editor';

var Bloque = MediumEditor.extensions.form.extend({
    
    name: 'bloque',
    action: 'blocks',
    aria: 'add block',
    title: 'Añadir bloque',
    contentDefault: '&#xB1;', // ±
    f7Icon: 'rectangle_badge_checkmark',

    bloques: {},

    init: function () {
        var self = this;
        this.button = this.document.createElement('button');
        this.button.classList.add('medium-editor-action');
        this.button.innerHTML = '<i class="icons f7-icons">' + this.f7Icon + '</i>';
        this.button.title = this.title;
        this.on(this.button, 'click', this.handleClick.bind(this));
        this.bloques[self.name] = [];
        this.bloques[self.name].unshift({   name: '', content: ''   });
    },

    // Overrides ButtonExtension.handleClick
    handleClick: function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.showForm();

        return false;
    },

    // Called by medium-editor to append form to the toolbar
    getForm: function () {
        if (!this.form) {
            this.form = this.createForm();
        }
        return this.form;
    },

    // Used by medium-editor when the default toolbar is to be displayed
    isDisplayed: function () {
        return this.getForm().style.display === 'block';
    },

    hideForm: function () {
        this.getForm().style.display = 'none';
        this.getSelect().value = '';
    },

    showForm: function () {
        var select = this.getSelect();

        this.base.saveSelection();
        this.hideToolbarDefaultActions();
        this.getForm().style.display = 'block';
        this.setToolbarPosition();

        select.focus();
    },

    // Called by core when tearing down medium-editor (destroy)
    destroy: function () {
        if (!this.form) {
            return false;
        }

        if (this.form.parentNode) {
            this.form.parentNode.removeChild(this.form);
        }

        delete this.form;
    },

    // core methods

    doFormSave: function () {
        this.base.restoreSelection();
        this.base.checkSelection();
    },

    doFormCancel: function () {
        this.base.restoreSelection();
        this.clearFontName();
        this.base.checkSelection();
    },

    // form creation and event handling
    createForm: function () {
        var self = this,
            doc = this.document,
            form = doc.createElement('div'),
            select = doc.createElement('select'),
            close = doc.createElement('a'),
            save = doc.createElement('a'),
            option;

        // Font Name Form (div)
        form.className = 'medium-editor-toolbar-form';
        form.id = 'medium-editor-toolbar-form-fontname-' + this.getEditorId();

        // Handle clicks on the form itself
        this.on(form, 'click', this.handleFormClick.bind(this));

        // Add font names
        for (var i = 0; i<this.bloques[self.name].length; i++) {
            option = doc.createElement('option');
            option.innerHTML = this.bloques[self.name][i].name;
            option.value = this.bloques[self.name][i].content;
            select.appendChild(option);
        }

        select.className = 'medium-editor-toolbar-select';
        form.appendChild(select);

        // Handle typing in the textbox
        this.on(select, 'change', this.handleBlockChange.bind(this));

        // Add save buton
        save.setAttribute('href', '#');
        save.className = 'medium-editor-toobar-save';
        save.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-check"></i>' :
                            '&#10003;';
        form.appendChild(save);

        // Handle save button clicks (capture)
        this.on(save, 'click', this.handleSaveClick.bind(this), true);

        // Add close button
        close.setAttribute('href', '#');
        close.className = 'medium-editor-toobar-close';
        close.innerHTML = this.getEditorOption('buttonLabels') === 'fontawesome' ?
                            '<i class="fa fa-times"></i>' :
                            '&times;';
        form.appendChild(close);

        // Handle close button clicks
        this.on(close, 'click', this.handleCloseClick.bind(this));

        return form;
    },

    getSelect: function () {
        return this.getForm().querySelector('select.medium-editor-toolbar-select');
    },

    clearFontName: function () {
        MediumEditor.selection.getSelectedElements(this.document).forEach(function (el) {
            if (el.nodeName.toLowerCase() === 'font' && el.hasAttribute('face')) {
                el.removeAttribute('face');
            }
        });
    },

    handleBlockChange: function () {
        var newBlock = this.getSelect();

        var html = newBlock.value;

        MediumEditor.util.insertHTMLCommand(this.document, html);

    },

    handleFormClick: function (event) {
        // make sure not to hide form when clicking inside the form
        event.stopPropagation();
    },

    handleSaveClick: function (event) {
        // Clicking Save -> create the font size
        event.preventDefault();
        this.doFormSave();
    },

    handleCloseClick: function (event) {
        // Click Close -> close the form
        event.preventDefault();
        this.doFormCancel();
    },

    getCurrentSelection: function() {
        var html = ''
        var sel

        if (typeof window.getSelection != 'undefined') {
        sel = window.getSelection()
        if (sel.rangeCount) {
            var container = document.createElement('div')
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents())
            }
            html = container.innerHTML
        }
        } else if (typeof document.selection != 'undefined') {
            if (document.selection.type == 'Text') {
                html = document.selection.createRange().htmlText
            }
        }
        return html
    },

    changeOptions: function(bloques){
        var self = this;
        var doc = self.document;
        bloques.unshift({   name: '', content: ''   });
        self.bloques[self.name] = [{name:"", content:""}];
        var form = this.getForm();
        var select = form.getElementsByClassName("medium-editor-toolbar-select")[0];
        Array.from(select.childNodes).forEach(node => {node.remove();});
		bloques.forEach(bloque => {
		if(!bloque.valores) return;
			bloque.valores.forEach(optionRes => {
                self.bloques[self.name].push({name:optionRes.valor, content:optionRes.nombre});
            })
        });
        self.bloques[self.name].forEach(optionRes => {
            var option = doc.createElement('option');
            option.innerHTML = optionRes.name;
            option.value = optionRes.content;
            select.appendChild(option);
        });
    }
});
export default Bloque;