import DatabaseModel from './DatabaseModel.js';

var Factura = Object.create(DatabaseModel);

Factura.model = 'factura';
Factura.modelFront = 'facturas';

Factura.crearFacturas = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/lineas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

// La cancelación de la factura la marcará como cancelada y creará un abono
Factura.cancelar = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/cancelar',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        // CacheService.clearCache(self.model);
        return res;
    });
};

export default Factura;