<template>
  <r-form
    ref="rform"
    :itemId="id"
    @save="onSave"
    :beforeSave="beforeSave"
    class="page"
    classContent="full-height"
    :readonly="readonly"
    :dbAdapter="Item"
    @loadItem="loadItem"
    :defaultData="{esEquipo:esEquipo}"
  >

  <template v-slot:before="{rform}">
    <f7-navbar
        :title="esEquipo ? $t('items.equipo') : $t('items.item') + ' ' + (rform.formData.codigo? rform.formData.codigo : '')"
        :back-link="$t('atras')"
      >
      <f7-button
        fill
        v-if="!readonly"
        slot="right"
        color="green"
        @click="rform.saveItem()"
      >Guardar</f7-button>
      <template slot="right" v-else>
        <f7-button color="blue" @click="readonly=false">Editar</f7-button>
        <f7-button color="red" @click="deleteItem">Eliminar</f7-button>
      </template>
      <f7-subnavbar>
        <f7-segmented raised class="elevation-6">
          <f7-button fill tab-link="#tab-item" tab-link-active>{{esEquipo ? $t('items.equipo') : $t('items.item')}}</f7-button>
          <f7-button fill tab-link="#tab-caracteristicas">Características</f7-button>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
  </template>


  <template v-slot:default="{rform}">
      <div class="full-height">
        <f7-tabs animated>
          <f7-tab id="tab-item" class="page-content" tab-active>
            <f7-block-title medium>{{esEquipo ? $t('items.equipo') : $t('items.item')}}</f7-block-title>
            <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
              <f7-card-content>
                <f7-row no-gap class="list list-form no-hairlines">
                  <f7-col medium="50" width="100">
                    <ul>
                      <r-input
                        floating-label
                        :label="$t('items.codigo')"
                        :form="rform"
                        type="text"
                        name="codigo"
                      ></r-input>
                      <r-input
                        floating-label
                        :label="$t('items.nombre')"
                        :form="rform"
                        type="text"
                        name="nombre"
                      ></r-input>
                      <r-autocomplete
                        ref="cliente"
                        :dbAdapter="Cliente"
                        floating-label
                        :label="$t('items.cliente')"
                        :form="rform"
                        name="cliente"
                        labelProp="codigoNombre"
                        :idProp="null"
                        :minLength="esEquipo ? 0 : 3"
                        @change="changeCliente"
                        :filter="esEquipo ? {filter: {codigo:'SIMECAL'}} : {}"
                        @loaded="if($event.length==1) $refs.cliente.setValue($event[0]);"
                        :shownFields="['codigo','razonSocial']"
                        :async="true"
                      ></r-autocomplete>
                      <r-input
                        floating-label
                        :label="$t('items.instalacion')"
                        :form="rform"
                        type="select"
                        name="codigoInstalacion"
                      >
                        <option selected disabled></option>
                        <option v-for="(item,index) in instalacionesCombo"
                            :key="'instalacion' + '-' + index" :value="item.codigo">{{item.codigo}} - {{item.nombre}}</option>
                      </r-input>
                      <r-autocomplete
                        :dbAdapter="Formato"
                        floating-label
                        :label="$t('items.formato')"
                        :form="rform"
                        name="formato"
                        labelProp="nombre"
                        prefixProp="codigo"
                        :shownFields="['caracteristicas']"
                        @change="changeFormato"
                      ></r-autocomplete>
                      <r-autocomplete-m2m
                        v-if="esEquipo"
                        :dbAdapter="Formato"
                        floating-label
                        :label="$t('items.formatosAplica')"
                        :form="rform"
                        name="datosEquipo.formatos"
                        idProp="id"
                        labelProp="nombre"
                        prefixProp="codigo"
                        type="textarea"
                        :lineBreak="true"
                      ></r-autocomplete-m2m>
                      <div class="label-group col-100">Dirección Inspección</div>
                      <r-direccion
                        :form="rform"
                        name="direccion"
                        :guardarCodigos="true">
                      </r-direccion>
                    </ul>
                  </f7-col>
                  <!-- <f7-col v-if="!esEquipo" medium="50" width="100">
                    <ul>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.icAdministrador')"
                        :form="rform"
                        name="icAdministrador"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.icInstalador')"
                        :form="rform"
                        name="icInstalador"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.icMantenedor')"
                        :form="rform"
                        name="icMantenedor"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.icAsociacion')"
                        :form="rform"
                        name="icAsociacion"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                    </ul>
                  </f7-col> -->
                  <f7-col v-if="!esEquipo" medium="50" width="100">
                    <ul>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.administrador')"
                        :form="rform"
                        name="administrador"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.instalador')"
                        :form="rform"
                        name="instalador"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.mantenedor')"
                        :form="rform"
                        name="mantenedor"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                      <r-autocomplete
                        :dbAdapter="Cliente"
                        class="col-25"
                        floating-label
                        :label="$t('items.asociacion')"
                        :form="rform"
                        name="asociacion"
                        labelProp="codigoNombre"
                        idProp="codigo"
                        idFieldDetail="id"
                        :shownFields="['codigo', 'razonSocial']"
                        :minLength="3"
                        :async="true"
                      ></r-autocomplete>
                    </ul>
                  </f7-col>
                  <f7-col v-if="esEquipo" medium="50" width="100">
                    <ul>
                      <r-autocomplete
                        :dbAdapter="Delegacion"
                        floating-label
                        :label="$t('items.delegacion')"
                        :form="rform"
                        name="datosEquipo.delegacion"
                        labelProp="nombre"
                        :shownFields="['codigo', 'descripcion', 'idEmpresa', 'idResponsableEquipos', 'responsableEquipos', 'diasPreavisoCalibrado', 'diasPreavisoVerificacion' ]"
                      ></r-autocomplete>
                      
                      <r-autocomplete-m2m
                        :dbAdapter="Empleado"
                        floating-label
                        :label="$t('items.empleadosAsignados')"
                        :form="rform"
                        name="datosEquipo.empleadosAsignados"
                        labelProp="nombre"
                        prefixProp="username"
                        type="textarea"
                        :lineBreak="true"
                        :shownFields="[	'idSap', 'username', 'passwordSAP', 'email', 'telefono', 'datos', 'acreedor', 'rol', 'createdUser', 'securityUserId', 'fechaAlta', 'fechaBaja', 'activo', 'firma']"
                      ></r-autocomplete-m2m>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.fechaAlta')"
                        :form="rform"
                        type="datepicker"
                        name="datosEquipo.fechaAlta"
                        :clearButton="true"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="'Fecha Ult. Calibración'"
                        :form="rform"
                        type="datepicker"
                        :clearButton="true"
                        name="datosEquipo.fechaUltimaCalibracion"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.fechaProximaCalibracion')"
                        :form="rform"
                        type="datepicker"
                        :clearButton="true"
                        name="datosEquipo.fechaProximaCalibracion"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.fechaProximaVerificacion')"
                        :form="rform"
                        type="datepicker"
                        :clearButton="true"
                        name="datosEquipo.fechaProximaVerificacion"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.fechaBaja')"
                        :form="rform"
                        type="datepicker"
                        :clearButton="true"
                        name="datosEquipo.fechaBaja"
                        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit'} }"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.unidad_movil')"
                        :form="rform"
                        name="datosEquipo.unidadMovil"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.mostrarSiCaducado')"
                        :form="rform"
                        type="check"
                        name="datosEquipo.mostrarSiCaducado"
                      ></r-input>
                      <r-input
                        class="col-100 medium-25"
                        floating-label
                        :label="$t('items.diasAvisoEmail')"
                        :form="rform"
                        type="number"
                        step="1"
                        name="datosEquipo.diasAvisoEmail"
                      ></r-input>
                      <r-autocomplete-m2m
                        class="col-100 medium-25"
                        :dbAdapter="TipoItem"
                        floating-label
                        :label="$t('items.tipoEquipo')"
                        :form="rform"
                        name="datosEquipo.tipos"
                        labelProp="nombre"
                        type="textarea"
                        :lineBreak="true"
                        :filter="{esEquipo: true}"
                      ></r-autocomplete-m2m>
                    </ul>
                  </f7-col>
                </f7-row>
              </f7-card-content>
            </f7-card>
          </f7-tab>
          <f7-tab id="tab-caracteristicas" class="page-content">
            <f7-block-title medium>Características</f7-block-title>
            <!-- Características -->
              <div
                class="textoAzul w-99"
                v-for="(caracteristica, ic) in rform.formData.caracteristicas"
                :key="'caracteristica-' + caracteristica.nombre + '-' + ic"
              >
                <caracteristica
                  :key="'caract-' + caracteristica.nombre + '-' + ic"
                  :tipo="caracteristica.tipo"
                  :caracteristica="caracteristica"
                  :form="rform"
                  :label="caracteristica.label"
                  :name="'caracteristicas.' + ic"
                  :allCaracteristicas="rform.formData.caracteristicas"
                  :offline="false"
                ></caracteristica>
              </div>  
          </f7-tab>
      </f7-tabs>
    </div>
    <pre style="display:none;">{{rform.formData}}</pre>
  </template>
  </r-form>
</template>

<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
.tab-link-active .item-media,
.accordion-item-opened .accordion-item-toggle .item-media {
  color: var(--f7-theme-color) !important;
}

.label-radio {
  width: var(--f7-radio-size);
  text-align: center;
}
label.radio.disabled,
label.item-checkbox.disabled {
  opacity: 1 !important;
}
.radio input[type="radio"]:not(:checked) ~ * {
  opacity: 0.2;
}

.page-content::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.list ul {
  background: rgba(255, 255, 255, 0.7);
}

.tab-link-active {
  background-color: var(--f7-theme-color-shade) !important;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}

</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Item from "./../../js/db/Item.js";
import Formato from "./../../js/db/Formato.js";
import Delegacion from "./../../js/db/Delegacion.js";
import Empleado from "./../../js/db/Empleado.js";
import Instalacion from "./../../js/db/Instalacion.js";
import Materia from "./../../js/db/Materia.js";
import Cliente from "./../../js/db/Cliente.js";
import TipoItem from "./../../js/db/TipoItem.js";
import Caracteristica from "./../inspecciones/Caracteristica.vue";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m,
    rDireccion,
    Caracteristica
  },
  data: function() {
    var self = this;
    return {
      Cliente,
      Item,
      Formato,
      Delegacion,
      Empleado,
      Materia,
      TipoItem,
      readonly: !this.editable,
      instalacionesCombo: [],
      isTitularOpen: false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    },
    esEquipo: {
      type: Boolean
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Item.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    changeCliente: function(e) {
      var self = this;
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.cliente && self.$refs.rform.formData.cliente.id) {
        self.loadInstalaciones(self.$refs.rform.formData.cliente.id);
      }
    },
    changeFormato: function(e) {
      var self = this;
      if (self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.formato && self.$refs.rform.formData.formato.id) {
        let caracteristicasPrevias = self.getCaracteristicas(self.$refs.rform.formData.caracteristicas).filter(car => !!car.valor);
        // Reemplazamos las características iniciales por las propias del formato seleccionado
        self.$set(self.$refs.rform.formData, "caracteristicas", self.$refs.rform.formData.formato.caracteristicas);
        // Metemos los valores que podamos de las características previas en el nuevo conjunto de características del expediente.
        self.getCaracteristicas(self.$refs.rform.formData.caracteristicas).forEach(car => {
          let old = caracteristicasPrevias.find(c => c.nombre == car.nombre);
          car.valor = old ? old.valor : car.valor;
        });
        // self.$set(self.$refs.rform.formData, "caracteristicas", self.$refs.rform.formData.formato.caracteristicas);
      }
    },
    getCaracteristicas(caracteristicas) {
      var self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap(c => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    loadItem: function(res) {
      var self = this;
      self.loadInstalaciones(res.cliente.id);
    },
    loadInstalaciones: function(idCliente) {
      var self = this;
      var app = self.$f7;
      Instalacion.getList({clienteId: idCliente}).then(function(res) {
            if (res) {
              self.instalacionesCombo = res.list;
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
    }, 
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.clienteId = o.cliente ? o.cliente.id : null;
      o.formatoId = o.formato ? o.formato.id : null;
      delete o.cliente;
      delete o.formato;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.esEquipo ? self.$t("items.equipo_guardado") : self.$t("items.item_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>