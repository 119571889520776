<template>
  <div>
    <div v-if="operacion.tipo == 'OPERACION'" :class="{'padding-half': withPadding}" style="cursor: pointer;"> {{operacion.visual}} </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'TEXTO'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-input 
        ref="resizeInput"
        placeholder="Texto"
        @mounted="createdInput"
        class="red-placeholder input-drag"
        :name="name + '.valor'"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(value, obj.type, obj.$el)}}"
        :form="rform"
      />
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && (operacion.codigo == 'TRUE' || operacion.codigo == 'FALSE')"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      {{operacion.valor}}
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'NUMERO'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-input 
        ref="resizeInput"
        placeholder="Nº"
        @mounted="createdInput"
        class="red-placeholder input-drag"
        :name="name + '.valor'"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(value, obj.type, obj.$el)}}"
        :form="rform"
        type="number"
        step="1" 
      />
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'FECHA'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;">
      <r-input 
        ref="resizeInput"
        placeholder="Fecha"
        class="red-placeholder input-drag"
        @mounted="createdInput"
        :name="name + '.valor'"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(value, obj.type, obj.$el)}}"
        :form="rform"
        type="datepicker"
        :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
      />
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'CARACTERISTICA'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-autocomplete 
        ref="resizeInput"
        placeholder="Característica"
        class="red-placeholder autocomplete-drag"
        :form="rform"  
        :name="name + '.valorAux'"
        labelProp="nombre"
        idProp="nombre"
        @mounted="createdInput"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(obj.getValue(), obj.type, obj.$el)}}"
        :dbItems="getCaracteristicas(rform.formData.caracteristicas)"
      >
      </r-autocomplete>
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'COMPROBACION'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-autocomplete 
        ref="resizeInput"
        placeholder="Comprobación"
        class="red-placeholder autocomplete-drag"
        :form="rform"  
        :name="name + '.valorAux'"
        labelProp="codigoNombre"
        idProp="codigo"
        @mounted="createdInput"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(obj.getValue(), obj.type, obj.$el)}}"
        :dbItems="getComprobaciones(rform.formData.comprobaciones)"
      >
      </r-autocomplete>
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'RESULTADO'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-autocomplete 
        ref="resizeInput"
        placeholder="Resultado"
        class="red-placeholder autocomplete-drag"
        :form="rform"  
        :name="name + '.valor'"
        labelProp="nombre"
        idProp="codigo"
        @mounted="createdInput"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(obj.getValue(), obj.type, obj.$el)}}"
        :dbItems="resultados"
      >
      </r-autocomplete>
    </div>
    <div 
      v-if="operacion.tipo == 'VALOR' && operacion.codigo == 'CAMPO'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer;"> 
      <r-autocomplete 
        ref="resizeInput"
        placeholder="Campo"
        class="red-placeholder autocomplete-drag"
        :form="rform"  
        :name="name + '.valor'"
        labelProp="nombre"
        idProp="codigo"
        @mounted="createdInput"
        @change="function(value, obj) {if (!obj) {return;} else {resizeInput(obj.getValue(), obj.type, obj.$el)}}"
        :dbItems="camposExpediente"
      >
      </r-autocomplete>
    </div>
    <div 
      v-if="operacion.tipo == 'FUNCION' && operacion.codigo == 'VALOR'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer; display: flex;"> 
      <div style="cursor: pointer;"> Tiene valor<b class="text-color-blue">(</b> </div>
        <Container
          group-name="group-count"
          orientation="horizontal"
          class="container-horizontal"
          :animation-duration="1000"
          @drop="onDrop(operacion.operaciones, $event)"
          :get-child-payload="getPayload(operacion.operaciones)"
          :should-accept-drop="(src, payload) => getShouldAcceptDropCount(operacion.operaciones, src, payload)"
        >
          <Draggable v-for="(operacion2, index2) in operacion.operaciones" :key="'btn-operacion-'+ operacion.codigo + '-operaciones-'+index2">
            <operacion-auditoria :withPadding="false" :onDrop="onDrop" :operacion="operacion2" :rform="rform" :name="name + '.operaciones.' + index2"></operacion-auditoria>
          </Draggable>
        </Container>
      <div style="cursor: pointer;"> <b class="text-color-blue">)</b> </div>
    </div>
    <div 
      v-if="operacion.tipo == 'FUNCION' && operacion.codigo == 'NO_VALOR'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer; display: flex;"> 
      <div style="cursor: pointer;"> No tiene valor<b class="text-color-blue">(</b> </div>
        <Container
          group-name="group-count"
          orientation="horizontal"
          class="container-horizontal"
          :animation-duration="1000"
          @drop="onDrop(operacion.operaciones, $event)"
          :get-child-payload="getPayload(operacion.operaciones)"
          :should-accept-drop="(src, payload) => getShouldAcceptDropCount(operacion.operaciones, src, payload)"
        >
          <Draggable v-for="(operacion2, index2) in operacion.operaciones" :key="'btn-operacion-'+ operacion.codigo + '-operaciones-'+index2">
            <operacion-auditoria :withPadding="false" :onDrop="onDrop" :operacion="operacion2" :rform="rform" :name="name + '.operaciones.' + index2"></operacion-auditoria>
          </Draggable>
        </Container>
      <div style="cursor: pointer;"> <b class="text-color-blue">)</b> </div>
    </div>
    <div 
      v-if="operacion.tipo == 'FUNCION' && operacion.codigo == 'COUNT'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer; display: flex;"> 
      <div style="cursor: pointer;"> COUNT<b class="text-color-blue">(</b> </div>
        <Container
          group-name="group-count"
          orientation="horizontal"
          class="container-horizontal"
          :animation-duration="1000"
          @drop="onDrop(operacion.operaciones, $event)"
          :get-child-payload="getPayload(operacion.operaciones)"
          :should-accept-drop="(src, payload) => getShouldAcceptDropCount(operacion.operaciones, src, payload)"
        >
          <Draggable v-for="(operacion2, index2) in operacion.operaciones" :key="'btn-operacion-'+ operacion.codigo + '-operaciones-'+index2">
            <operacion-auditoria :withPadding="false" :onDrop="onDrop" :operacion="operacion2" :rform="rform" :name="name + '.operaciones.' + index2"></operacion-auditoria>
          </Draggable>
        </Container>
      <div style="cursor: pointer;"> <b class="text-color-blue">)</b> </div>
    </div>
    <div 
      v-if="operacion.tipo == 'FUNCION' && operacion.codigo == 'HIJAS'"  
      :class="{'padding-half': withPadding}" style="cursor: pointer; display: flex;"> 
      <div style="cursor: pointer;"> HIJAS<b class="text-color-orange">(</b> </div>
        <Container
          group-name="group-count"
          orientation="horizontal"
          class="container-horizontal"
          :animation-duration="1000"
          @drop="onDrop(operacion.operaciones, $event)"
          :get-child-payload="getPayload(operacion.operaciones)"
          :should-accept-drop="(src, payload) => getShouldAcceptDropHijas(operacion.operaciones, src, payload)"
        >
          <Draggable v-for="(operacion2, index2) in operacion.operaciones" :key="'btn-operacion-'+ operacion.codigo + '-operaciones-'+index2">
            <operacion-auditoria 
              :withPadding="false" 
              :onDrop="onDrop" 
              :operacion="operacion2" 
              :rform="rform" 
              :name="name + '.operaciones.' + index2">
            </operacion-auditoria>
          </Draggable>
        </Container>
         <div style="cursor: pointer;"> , </div>
         <div v-if="operacion.operaciones[0] && operacion.operaciones[0].codigo == 'CARACTERISTICA'" style="cursor: pointer;" class="padding-horizontal-half"> cuando su Valor es: </div>
         <div v-if="operacion.operaciones[0] && operacion.operaciones[0].codigo == 'COMPROBACION'" style="cursor: pointer;" class="padding-horizontal-half"> cuando su Resultado es: </div>
        <r-input 
          v-if="operacion.operaciones[0] && operacion.operaciones[0].codigo == 'CARACTERISTICA'"
          ref="resizeInput"
          placeholder="Valor"
          @mounted="createdInput"
          class="red-placeholder input-drag"
          :name="name + '.operaciones.1.valor'"
          @change="function(value, obj) {if (!obj) {return;} else {resizeInput(value, obj.type, obj.$el)}}"
          :form="rform"
        />
        <r-autocomplete 
          v-if="operacion.operaciones[0] && operacion.operaciones[0].codigo == 'COMPROBACION'"
          ref="resizeInput"
          placeholder="Resultado"
          class="red-placeholder autocomplete-drag"
          :form="rform"  
          :name="name + '.operaciones.1.valor'"
          labelProp="nombre"
          idProp="codigo"
          @mounted="createdInput"
          @change="function(value, obj) {if (!obj) {return;} else {resizeInput(obj.getValue(), obj.type, obj.$el)}}"
          :dbItems="resultados"
        >
        </r-autocomplete>

      <div style="cursor: pointer;"> <b class="text-color-orange">)</b> </div>
    </div>
  </div>
</template>
<style scoped>
.input-drag {
  background: transparent;
  border: none;
  min-width: 40px;
}
.autocomplete-drag {
  background: transparent;
  border: none;
  min-width: 90px;
}
</style>
<script>
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import operacionAuditoria from "./operacion-auditoria";

export default {
  name: 'operacion-auditoria',
  components: {
    rAutocomplete,
    rInput,
    Container,
    Draggable,
    operacionAuditoria
  },
  data() {
    return {
      self: this,
      resultados: [
        {codigo: "F", nombre: "F - Favorable"},
        {codigo: "NA", nombre: "N/A - No Aplica"},
        {codigo: "D", nombre: "D - Defecto"}
      ],
      camposExpediente: [
        {codigo: "hc1", nombre: "Hora Inicio"},
        {codigo: "hf1", nombre: "Hora Fin"},
        {codigo: "fechaInsp", nombre: "Fecha Inspección"}
      ],
    };
  },
  updated() {
    var self = this;
    if (self.$refs.resizeInput && Array.isArray(self.$refs.resizeInput)) {
      self.$refs.resizeInput.forEach(i => self.resizeInput(i.getValue(), i.type, i.$el));
    } else if (self.$refs.resizeInput) {
      self.resizeInput(self.$refs.resizeInput.getValue(), self.$refs.resizeInput.type, self.$refs.resizeInput.$el);
    }
  },
  methods: {
    getPayload(arr) { 
      var self = this;
      return index => Object.assign({}, arr[index]);
    },
    getShouldAcceptDropCount (arr, src, payload) {
      var self = this;
      // return src.groupName == 'group-count' || src.groupName == 'group-panel' || src.groupName == 'group-cuando' || src.groupName == 'group-entonces';
      return true;
    },
    getShouldAcceptDropHijas (arr, src, payload) {
      var self = this;
      if (arr && arr.filter(a => a.codigo == 'CARACTERISTICA' || a.codigo == 'COMPROBACION').length >= 1) {
        return false;
      }
      return payload.codigo == 'CARACTERISTICA' || payload.codigo == 'COMPROBACION';
    },
    createdInput (rinput) {
      var self = this;
      self.resizeInput(rinput.getValue(), rinput.type, rinput.$el);
    },
    getCaracteristicas (caracteristicas) {
      var self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap(c => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    getComprobaciones (comprobaciones) {
      var self = this;
      if (!comprobaciones || !comprobaciones.length) {
        return [];
      }
      var result = Object.assign([], comprobaciones);
      var hijas = comprobaciones.flatMap(c => {
        c.codigoNombre = c.codigo + ' - ' + c.nombre;
        return self.getComprobaciones(c.hijas);
      });
      return result.concat(hijas);
    },
    resizeInput (value, type, target) {
      var self = this;
      var offset = 7;
      type = type || 'otro';
      if (type == 'number') {
        offset = 10;
      } 
      value = value || "";
      var width = (((value + '').length + 1) * offset);
      target.style.width = width > 150 ? '150px' : (width + 'px');
    },
  },
  props: {
    operacion: {
      required: true,
      type: Object
    },
    rform: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    onDrop: {
      type: Function
    },
    withPadding: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>