import Facturas from './facturas.vue';
import FacturaForm from './factura-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/facturas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas', ['ADMIN', 'INSPECTOR'], Facturas),
        keepAlive: true
    },
    {
        path: '/facturas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/new', ['ADMIN', 'INSPECTOR'], FacturaForm)
    },
    {
        path: '/facturas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/edit', ['ADMIN', 'INSPECTOR'], FacturaForm)
    },
    {
        path: '/facturas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/view', ['ADMIN', 'INSPECTOR'], FacturaForm, { editable: false })
    },
];
export default routes;