import DatabaseModel from './DatabaseModelOffline.js';
import * as DeepDiff from "deep-diff";
import DeepChangesObject from "../DeepChangesObject.js";

var Inspeccion = Object.create(DatabaseModel);

Inspeccion.model = 'inspecciones';

Inspeccion.save = async function (params) {
    var self = this;
    params = params || {};
    let deepChanges = new DeepChangesObject();
    if (params.previousData) {
        DeepDiff.diff(params.previousData, params.data, null, deepChanges);
        if (!params.data.historicoOffline) {
            params.data.historicoOffline = [];
        }
        let changes = await deepChanges.toDatabaseFormat();
        console.log(changes);
        if (changes) {
            params.data.historicoOffline.push(changes);
        }
    }
    if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
};

export default Inspeccion;