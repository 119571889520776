<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Personal cualificado" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="PersonalCualificado"
        :tableCode="'PERSONALCUALIFICADO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :controlsWidth="100"
        :funcShowCheck="_ => false"
      >
      <template v-slot:tableLinks>
          <a v-roles="['ADMIN', 'DTYCAL', 'ENAC', 'PRODUCCION']" class="link" href="/personalcualificado/new/">{{$t('common.nuevo')}}</a>
      </template>
      <template v-slot:optionsColumn="{item}">
          <f7-link v-roles="['ADMIN', 'DTYCAL', 'ENAC', 'PRODUCCION']" v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/personalcualificado/view/${item.id}/`"></f7-link>
          <f7-link v-roles="['ADMIN', 'DTYCAL', 'ENAC', 'PRODUCCION']" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/personalcualificado/edit/${item.id}/`"></f7-link>
          <f7-link v-roles="['ADMIN', 'DTYCAL', 'ENAC', 'PRODUCCION']" v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
      </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import PersonalCualificado from "./../../js/db/PersonalCualificado.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      PersonalCualificado
    };
  },
  computed: {},
  methods: {

   },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>