'use strict';

var dateParse = function (input) {
    if (isNaN(Date.parse(input))) {
        return input;
    } else {
        return new Date(input);
    }
};

var main = {
    install: function (Vue) {
        Vue.filter('dateParse', dateParse);
    }
};

export default main; 
