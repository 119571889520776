import Departamentos from './departamentos.vue';
import DepartamentoForm from './departamento-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/departamentos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('departamentos', ['ADMIN', 'INSPECTOR'], Departamentos),
        keepAlive: true
    },
    {
        path: '/departamentos/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('departamentos/new', ['ADMIN', 'INSPECTOR'], DepartamentoForm)
    },
    {
        path: '/departamentos/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('departamentos/edit', ['ADMIN', 'INSPECTOR'], DepartamentoForm)
    },
    {
        path: '/departamentos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('departamentos/view', ['ADMIN', 'INSPECTOR'], DepartamentoForm, { editable: false })
    },
];
export default routes;