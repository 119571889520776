import CondicionesPago from './condicionespago.vue';
import CondicionPagoForm from './condicionespago-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/condicionespago/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('condicionespago', ['ADMIN', 'INSPECTOR'], CondicionesPago),
        keepAlive: true
    },
    {
        path: '/condicionespago/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('condicionespago/new', ['ADMIN', 'INSPECTOR'], CondicionPagoForm)
    },
    {
        path: '/condicionespago/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('condicionespago/edit', ['ADMIN', 'INSPECTOR'], CondicionPagoForm)
    },
    {
        path: '/condicionespago/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('condicionespago/view', ['ADMIN', 'INSPECTOR'], CondicionPagoForm, { editable: false })
    },
];
export default routes;