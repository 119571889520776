import Clientes from './clientes.vue';
import ClienteForm from './cliente-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/clientes/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes', ['ADMIN', 'INSPECTOR'], Clientes),
        keepAlive: true
    },
    {
        path: '/clientes/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/new', ['ADMIN', 'INSPECTOR'], ClienteForm)
    },
    {
        path: '/clientes/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/edit', ['ADMIN', 'INSPECTOR'], ClienteForm)
    },
    {
        path: '/clientes/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/view', ['ADMIN', 'INSPECTOR'], ClienteForm, { editable: false })
    },
];
export default routes;