import Comunidades from './comunidades.vue';
import ComunidadForm from './comunidad-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/comunidades/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias', ['ADMIN', 'INSPECTOR'], Comunidades),
        keepAlive: true
    },
    {
        path: '/comunidades/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/new', ['ADMIN', 'INSPECTOR'], ComunidadForm)
    },
    {
        path: '/comunidades/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/edit', ['ADMIN', 'INSPECTOR'], ComunidadForm)
    },
    {
        path: '/comunidades/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('provincias/view', ['ADMIN', 'INSPECTOR'], ComunidadForm, { editable: false })
    },
];
export default routes;