import DatabaseModel from './DatabaseModel.js';
import Cliente from './Cliente.js';

var ContactoCliente = Object.create(DatabaseModel);

ContactoCliente.model = 'contactos';

ContactoCliente.getList = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server_movilidad + self.prefix + Cliente.model + '/' + params.clienteId + '/' + self.model + '/',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

ContactoCliente.post = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + Cliente.model + '/' + params.data.clienteId + '/' + self.model + '/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default ContactoCliente;