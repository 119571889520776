import GrupoProveedor from './gruposproveedor.vue';
import GrupoProveedorForm from './gruposproveedor-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/gruposproveedor/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposproveedor', ['ADMIN', 'INSPECTOR'], GrupoProveedor),
        //keepAlive: true
    },
    {
        path: '/gruposproveedor/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposproveedor/new', ['ADMIN', 'INSPECTOR'], GrupoProveedorForm)
    },
    {
        path: '/gruposproveedor/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposproveedor/edit', ['ADMIN', 'INSPECTOR'], GrupoProveedorForm)
    },
    {
        path: '/gruposproveedor/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('gruposproveedor/view', ['ADMIN', 'INSPECTOR'], GrupoProveedorForm, { editable: false })
    },
];
export default routes;