<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('empleados.empleados')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-list
        ref="rlist"
        slot="default"
        :dbAdapter="Empleado"
        sort="creationDate"
        sortDir="DESC"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        filtersName="empleados"
      >
        <template v-slot:before="{rlist}">
          <f7-card-header>
            <div class="data-table-header" v-if="Object.keys(rlist.selected).length==0">
              <div class="data-table-links">
                <a class="link" href="/empleados/new/">Nuevo</a>
              </div>
              <div class="data-table-actions">
                <f7-link small @click="rlist.reload()" icon-f7="arrow_counterclockwise"></f7-link>
                <f7-link small @click="rlist.exportExcel('Empleados.xlsx')" icon-f7="doc_text_fill"  v-tooltip="'Exportar a Excel'" v-noroles="['ENAC', 'PRODUCCION']"></f7-link>
                <f7-link
                  small
                  @click="$refs.rpanel.toggle()"
                  icon-f7="line_horizontal_3_decrease"
                  v-show="!rPanelOpenedByBreakpoint"
                ></f7-link>
              </div>
            </div>
            <div class="data-table-header-selected display-flex" v-else>
              <!-- Selected table title -->
              <div class="data-table-title-selected">
                <span
                  class="data-table-selected-count"
                >{{$tc('rlist.n_registros_seleccionados', Object.keys(rlist.selected).length, {count:Object.keys(rlist.selected).length}) }}</span>
              </div>
              <!-- Selected table actions -->
              <div class="data-table-actions">
                <f7-link icon-f7="trash"></f7-link>
              </div>
            </div>
          </f7-card-header>
        </template>

        <template v-slot:header="{rlist}">
          <thead class="thead-sticky">
            <tr>
              <th class="checkbox-cell" style="width: 2rem;">
                <label class="checkbox">
                  <input type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)" />
                  <i class="icon-checkbox"></i>
                </label>
              </th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('username')"
                :class="rlist.sortableClasses('username')"
              >{{$t('empleados.username')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('nombre')"
                :class="rlist.sortableClasses('nombre')"
              >{{$t('empleados.nombre')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('email')"
                :class="rlist.sortableClasses('email')"
              >{{$t('empleados.email')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('telefono')"
                :class="rlist.sortableClasses('telefono')"
              >{{$t('empleados.telefono')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('rol')"
                :class="rlist.sortableClasses('rol')"
              >{{$t('empleados.rol')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('createdUser')"
                :class="rlist.sortableClasses('createdUser')"
              >{{$t('empleados.createdUser')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('datos.codEmpresa')"
                :class="rlist.sortableClasses('datos.codEmpresa')"
              >{{$t('empleados.codEmpresa')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('datos.codLineaNegocio')"
                :class="rlist.sortableClasses('datos.codLineaNegocio')"
              >{{$t('empleados.codLineaNegocio')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('datos.codDelegacion')"
                :class="rlist.sortableClasses('datos.codDelegacion')"
              >{{$t('empleados.codDelegacion')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('datos.codCentroTrabajo')"
                :class="rlist.sortableClasses('datos.codCentroTrabajo')"
              >{{$t('empleados.codCentroTrabajo')}}</th>
              <th
                style="min-width:1em;"
                @click="rlist.setOrder('datos.codDepartamento')"
                :class="rlist.sortableClasses('datos.codDepartamento')"
              >{{$t('empleados.codDepartamento')}}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:default="{vlData,rlist}">
          <tr v-for="(item) in vlData.items" :key="'empleado-'+item.id">
            <th class="checkbox-cell">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :checked="item.id in rlist.selected"
                  @change="rlist.toggleSelect(item, $event.target.checked)"
                />
                <i class="icon-checkbox"></i>
              </label>
            </th>
            <td>{{item.username}}</td>
            <td>{{item.nombre}}</td>
            <td>{{item.email}}</td>
            <td>{{item.telefono}}</td>
            <td>{{item.rol == 'ENAC' ? 'DIRECTOR_TECNICO' : item.rol}}</td>
            <td v-html="getSiNoBadge(item.createdUser)"></td>
            <td>{{item.datos ? item.datos.codEmpresa : '-'}}</td>
            <td>{{item.datos ? item.datos.codLineaNegocio : '-'}}</td>
            <td>{{item.datos ? item.datos.codDelegacion : '-'}}</td>
            <td>{{item.datos ? item.datos.codCentroTrabajo : '-'}}</td>
            <td>{{item.datos ? item.datos.codDepartamento : '-'}}</td>
            <td class="text-align-right right-column-sticky">
              <f7-link icon-f7="eye" color="green" :href="`/empleados/view/${item.id}/`" v-tooltip="'Ver'"></f7-link>
              <f7-link icon-f7="square_pencil" color="blue" :href="`/empleados/edit/${item.id}/`" v-tooltip="'Editar'"></f7-link>
              <f7-link v-if="item.securityUserId" icon-f7="lock" color="orange" @click="resetPass(item.securityUserId)" v-tooltip="'Resetear contraseña'"></f7-link>
              <f7-link icon-f7="trash" color="red" @click="rlist.deleteItem(item.id)" v-tooltip="'Borrar'"></f7-link>
            </td>
          </tr>
        </template>
        <template v-slot:afterInner="{rlist}">
          <rPanel
            ref="rpanel"
            side="right"
            @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
            @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
            v-slot="{rpanel}"
          >
            <r-form 
              @submit="rlist.setFilter($event); rpanel.close();"
              filtersName="empleados"  
            >
              <template v-slot:before="{rform}">
                <f7-toolbar>
                  <div>Filtro</div>
                  <f7-link icon-f7="bin_xmark" @click="rform.clear();"></f7-link>
                </f7-toolbar>
              </template>
              <template v-slot="{rform}">
                <f7-list no-hairlines-md>
                  <template v-slot:list>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('empleados.username')"
                      :form="rform"
                      type="text"
                      name="username"
                    ></r-input>
                    <r-input
                      maxlength="256"
                      floating-label
                      :label="$t('empleados.nombre')"
                      :form="rform"
                      type="text"
                      name="nombre"
                    ></r-input>
                    <r-autocomplete
                      :dbAdapter="Rol"
                      floating-label
                      :label="$t('empleados.rol')"
                      :form="rform"
                      name="rol"
                      idProp="valor"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="Empresa"
                      floating-label
                      :label="$t('empleados.codEmpresa')"
                      :form="rform"
                      name="codEmpresa"
                      idProp="codigo"
                      idFieldDetail="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="LineaNegocio"
                      floating-label
                      :label="$t('empleados.codLineaNegocio')"
                      :form="rform"
                      name="codLineaNegocio"
                      idProp="codigo"
                      idFieldDetail="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="Delegacion"
                      floating-label
                      :label="$t('empleados.codDelegacion')"
                      :form="rform"
                      name="codDelegacion"
                      idProp="codigo"
                      idFieldDetail="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="CentroTrabajo"
                      floating-label
                      :label="$t('empleados.codCentroTrabajo')"
                      :form="rform"
                      name="codCentroTrabajo"
                      idProp="codigo"
                      idFieldDetail="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <r-autocomplete
                      :dbAdapter="Departamento"
                      floating-label
                      :label="$t('empleados.codDepartamento')"
                      :form="rform"
                      name="codDepartamento"
                      idProp="codigo"
                      idFieldDetail="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                    <f7-button type="submit" class="margin-vertical" icon-f7="search">Buscar</f7-button>
                  </template>
                </f7-list>
              </template>
            </r-form>
          </rPanel>
        </template>
        <template v-slot:after="{rlist}">
          <f7-card-footer>
            <span>
              <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
            </span>
          </f7-card-footer>
        </template>
      </r-list>
    </f7-page-content>
    <f7-popup ref="popupAsociar" class="popup-asociar" swipe-to-close>
        <f7-page>
          <f7-navbar title="Asociar Delegación">
            <f7-nav-right>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
        <r-form
          ref="rformAsociar"
          v-slot="{rform}"
          :itemId="itemSelected"
          :dbAdapter="Empleado"
          @save="onSaveAsociar"
        >
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-autocomplete
                      :dbAdapter="Delegacion"
                      floating-label
                      :label="$t('empleados.delegacion')"
                      :form="rform"
                      name="idDelegacion"
                      idProp="id"
                      labelProp="nombre"
                    ></r-autocomplete>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-button
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="rform.saveItem();"
          ></f7-button>
        </r-form>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import rList from "./../../components/rList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Empleado from "./../../js/db/Empleado.js";
import Empresa from "./../../js/db/Empresa.js";
import LineaNegocio from "./../../js/db/LineaNegocio.js";
import Delegacion from "./../../js/db/Delegacion.js";
import CentroTrabajo from "./../../js/db/CentroTrabajo.js";
import Departamento from "./../../js/db/Departamento.js";
import Usuario from "./../../js/db/Usuario.js";
import Rol from "./../../js/db/Rol.js";
export default {
  components: {
    rList,
    rPanel,
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Empleado,
      Empresa,
      LineaNegocio,
      Delegacion,
      CentroTrabajo,
      Departamento,
      Rol,
      Usuario,
      itemSelected: null,
      rPanelOpenedByBreakpoint: false
    };
  },
  computed: {},
  methods: {
    onSaveAsociar: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.popupAsociar.close();
      self.$refs.rlist.reload();
      self.$refs.rformAsociar.clear();
    },
    getSiNoBadge: function(b) {
      if (b) {
        var texto = "Si";
        var color = "green";
      } else {
        var texto = "No";
        var color = "red";
      }
      return `<span class="width-100 badge color-` + color + `">` + texto + `</span>`;
    },
    resetPass: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea resetear la contraseña del usuario seleccionado?",
        "Resetear Contraseña",
        function() {
          app.preloader.show();
          Usuario.resetPass({itemId: itemId})
            .then(function(res) {
              app.preloader.hide();
              app.toast
                .create({
                  text: 'Contraseña Reseteada',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>
<style scoped>
 .right-column-sticky {
    right: 0;
    position: -webkit-sticky !important;
    position: sticky !important;
    opacity: 1;
    z-index: 17 !important;
  }
</style>