import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Pedido = Object.create(DatabaseModel);

Pedido.model = 'pedido';
Pedido.modelFront = 'pedidos';

Pedido.getPlanificar = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/getlineasplanificar/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Pedido.getLineaCreateExpManual = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/getLineaCreateExpManual/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                var obj = JSON.parse(xhr.response);
                reject(obj.message ? obj.message : obj.error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Pedido.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_inspecciones + self.prefix + self.model + '/' + params.itemId + '/cancel',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Pedido.crearSegundas = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/crearsegundas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.desvincularNuevaPrimera = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/desvincularnuevaprimera',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.vincularNuevaPrimera = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/vincularnuevaprimera',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.crearNuevaPrimera = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/crearnuevaprimera',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.desplanificar = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/desplanificar',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.borrarLineas = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/borrarlineas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.borrarSegundas = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/borrarsegundas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Pedido.borrarNuevaPrimera = function(params) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/borrarNuevaPrimera',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

export default Pedido;