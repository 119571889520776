<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('inventario.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('inventario.inventario')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('inventario.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">{{$t('common.editar')}}</f7-button>
          <f7-button color="red" @click="deleteItem">{{$t('common.eliminar')}}</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Inventario"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('tarea.codigo')"
                  :form="rform"
                  type="text"
                  name="codigo"
                  :validation="{isValid: str => {return !validator.isEmpty(str, {ignore_whitespace:true})}, errorMessage: '* El código es obligatorio'}"
                ></r-input>
                <r-autocomplete
                  :dbAdapter="LineaNegocio"
                  class="col-100 medium-33"
                  floating-label
                  :label="$t('inventario.lineanegocio')"
                  :form="rform"
                  name="lineaNegocio"
                  labelProp="nombre"
                  :idProp="null"
                  :shownFields="['nombre', 'codigo', 'id']"
                  prefixProp="codigo"
                  idFieldDetail="id"
                ></r-autocomplete>
                <r-input
                  floating-label
                  :label="$t('inventario.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('inventario.marca')"
                  :form="rform"
                  type="text"
                  name="marca"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('inventario.modelo')"
                  :form="rform"
                  type="text"
                  name="modelo"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('inventario.descripcion')"
                  :form="rform"
                  type="text"
                  name="descripcion"
                ></r-input>
                <r-autocomplete-m2m
                  :dbAdapter="Empleado"
                  floating-label
                  outline
                  :label="$t('common.empleados')"
                  :form="rform"
                  name="usernames"
                  idProp="username"
                  labelProp="nombre"
                  prefixProp="username"
                  type="textarea"
                  :lineBreak="true"
                ></r-autocomplete-m2m>
                <r-input
                    floating-label
                    class="col-100 medium-50"
                    :label="$t('inventario.fechaalta')"
                    :form="rform"
                    type="datepicker"
                    name="fechaAlta"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                    floating-label
                    class="col-100 medium-50"
                    :label="$t('inventario.fechabaja')"
                    :form="rform"
                    type="datepicker"
                    name="fechaBaja"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }

</style>
<script>
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rAutocompleteM2m from "./../../components/rAutocompleteM2m.vue";
import Inventario from "./../../js/db/Inventario.js";
import Empleado from "./../../js/db/Empleado.js";
import LineaNegocio from "./../../js/db/LineaNegocio.js";
import validator from "validator";

export default {
  components: {
    rForm,
    rInput,
    rFiles,
    rAutocomplete,
    rAutocompleteM2m
  },
  data: function() {
    return {
      Inventario,
      Empleado,
      LineaNegocio,
      validator,
      readonly: !this.editable
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  computed: {},
  beforeDestroy() {},
  methods: {
    deleteItem: function() {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          Tarea.delete({itemId: self.id}).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;


      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("inventario.guardado_ok"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }

  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>