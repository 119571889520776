<template>
  <f7-list-item
    checkbox
    :checked="getValue()"
    :title="label"
    @change="setValue($event.target.checked?true:false)"
    v-bind="{...$attrs,...$props}"
    v-on="$listeners"
    :footer="form.errors[name]"
    :readonly="readonly || form.readonly"
    :disabled="readonly || form.readonly"
    :class="{readonly:readonly || form.readonly}"
  >
    <slot></slot>
    <slot name="media" slot="media"></slot>
  </f7-list-item>
</template>
<style>
</style>
<script>
export default {
  data() {
    return {
      self: this
    };
  },
  methods: {
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context;
    },
    setValue: function(val) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          self.$set(context, namespaces[i], val);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    }
  },
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>