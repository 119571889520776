import CentrosTrabajo from './centrostrabajo.vue';
import CentroTrabajoForm from './centrostrabajo-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/centrostrabajo/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('centrostrabajo', ['ADMIN', 'INSPECTOR'], CentrosTrabajo),
        keepAlive: true
    },
    {
        path: '/centrostrabajo/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('centrostrabajo/new', ['ADMIN', 'INSPECTOR'], CentroTrabajoForm)
    },
    {
        path: '/centrostrabajo/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('centrostrabajo/edit', ['ADMIN', 'INSPECTOR'], CentroTrabajoForm)
    },
    {
        path: '/centrostrabajo/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('centrostrabajo/view', ['ADMIN', 'INSPECTOR'], CentroTrabajoForm, { editable: false })
    },
];
export default routes;