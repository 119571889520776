<template>
  <f7-page :page-content="false">
    <f7-popup tablet-fullscreen v-if="dialog" class="popup-dialog" ref="popupDialog" :opened="true" swipe-to-close @popup:closed="dialog = false; currentPage = 1;">
      <f7-page>
        <f7-navbar :title="'Ver Documento ' + activeElement.name">
          <f7-nav-right>
            <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
          </f7-nav-right>
        </f7-navbar>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content class="" >
              <v-app class="full-height">
                <div class="d-flex sticky-top">
                  <v-btn
                    :disabled="currentPage <= 1"
                    fill
                    color="primary"
                    @click="currentPage--"
                    style="width: 40%;"
                  >Anterior</v-btn>
                  <div class="text-align-center" style="width: 20%; margin-top: 10px;">
                    Pág: {{currentPage}} / {{pageCount}}
                  </div>
                  <v-btn
                    style="width: 40%;"
                    :disabled="currentPage >= pageCount"
                    fill
                    color="primary"
                    @click="currentPage++"
                  >Siguiente</v-btn>
                </div>
                <pdf 
                  v-show="loadedRatio && loadedRatio >= 1"
                  ref="pdfViewer"
                  class="pdf-viewer"
                  :src="Documentacion.getDownloadUrl(activeElement)"
                  @num-pages="pageCount = $event"
                  @page-loaded="currentPage = $event"
                  @progress="loadedRatio = $event"
                  :page="currentPage"
                  @loaded="changeHeightCanvas"
                ></pdf>
                <div v-if="!loadedRatio || loadedRatio < 1" class="text-align-center color-primary">
                  <div class="preloader color-primary">
                    <span class="preloader-inner">
                      <span class="preloader-inner-circle"></span>
                    </span>
                  </div>
                  <span>Cargando...</span>
                </div>
                <div class="d-flex">
                  <v-btn
                    :disabled="currentPage <= 1"
                    fill
                    color="primary"
                    @click="currentPage--"
                    style="width: 40%;"
                  >Anterior</v-btn>
                  <div style="width: 20%; margin-top: 10px;" class="text-align-center">
                    Pág: {{currentPage}} / {{pageCount}}
                  </div>
                  <v-btn
                    :disabled="currentPage >= pageCount"
                    style="width: 40%;"
                    fill
                    color="primary"
                    @click="currentPage++"
                  >Siguiente</v-btn>
                </div>
              </v-app>
            </f7-card-content>
          </f7-card>
      </f7-page>
    </f7-popup>
    <v-app class="full-height">
      <v-sheet 
        height="64"
        class="d-flex"
      >
        <v-toolbar flat>
          <v-toolbar-title>
              Documentación
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-row class="full-height doc-content" justify="space-between">
        <v-col :cols="activeElement ? 6 : 12" class="full-height">
          <v-sheet class="full-height">
            <div class="iconos-superiores">
              <v-icon v-roles="['ADMIN', 'DTYCAL']" color="green" class="icono-superior" @click="createFolder({path: ''})">
                {{ 'mdi-folder-plus' }}
              </v-icon>
            </div>
            <v-treeview
              v-model="tree"
              :items="items"
              activatable
              hoverable
              :multiple-active="false"
              :return-object="true"
              item-key="path"
              @update:active="onActiveItem"
              :open-on-click="true"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="!item.extension">
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
                <v-icon v-else>
                  {{ extensions[item.extension] || 'mdi-file-document' }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                <div v-if ="item.isDirectory" class="item-label" @drop.prevent="e => onDrop(e, item)" :dropZoneActive="activeDrag" :data-active="activeDrag" @dragenter.prevent="activeDrag = true" @dragover.prevent="activeDrag = true" @dragleave.prevent="activeDrag = false">
                  {{ item.name }}<span class="file-time"> - {{ moment(item.fileTime).format('DD/MM/YYYY HH:mm') }}</span>
                </div>
                <div v-else class="item-label" style="cursor: pointer;">
                  {{ item.name }}<span class="file-time"> - {{ moment(item.fileTime).format('DD/MM/YYYY HH:mm') }}</span>
                </div>
              </template>
              <template v-slot:append="{ item }">
                <div class="item-icons">
                  <v-icon color="green" v-roles="['ADMIN', 'DTYCAL']" small class="item-icon" v-if="item.isDirectory" @click.stop="createFolder(item)" v-tooltip="'Crear carpeta'">
                    {{ 'mdi-folder-plus' }}
                  </v-icon>
                  <v-icon color="red" v-roles="['ADMIN', 'DTYCAL']" small class="item-icon" @click.stop="deleteFolder(item)" v-tooltip="item.isDirectory ? 'Borrar carpeta' : 'Borrar documento'">
                    {{ item.isDirectory ? 'mdi-folder-remove' : 'mdi-file-remove' }}
                  </v-icon>
                  <v-icon color="blue" v-roles="['ADMIN', 'DTYCAL']" small class="item-icon" @click.stop="rename(item)" v-tooltip="item.isDirectory ? 'Renombrar carpeta' : 'Renombrar documento'">
                    {{ 'mdi-form-textbox' }}
                  </v-icon>
                  <v-file-input @change="loadFile($event, item)" v-roles="['ADMIN', 'DTYCAL']" small class="item-icon" v-if="item.isDirectory" prepend-icon="mdi-file-document-plus" multiple hide-input v-tooltip="'Cargar documentos'" @click.stop=""></v-file-input>
                  <v-spacer width="100"></v-spacer>
                  <div class="item-end-label">{{item.children.length + ' ' + (item.children.length == 1 ? 'elemento' : 'elementos')}}</div>
                </div>
              </template>
            </v-treeview>
          </v-sheet>
        </v-col>
        <v-divider vertical></v-divider>

        <v-col
          v-if="activeElement"
          class="d-flex text-center"
        >
          <v-scroll-y-transition mode="out-in">
            <div v-if="activeElement.extension == 'pdf'" style="width: 100%;">
              <div class="d-flex">

                <v-icon color="error" style="width: 10%;" @click="activeElement = false">
                  {{ 'mdi-close' }}
                </v-icon>
                <v-icon color="primary" style="width: 10%;" @click="dialog = true; currentPage = 1;">
                  {{ 'mdi-application-brackets-outline' }}
                </v-icon>
                <v-btn
                  :disabled="currentPage <= 1"
                  fill
                  color="primary"
                  @click="currentPage--"
                  style="width: 35%;"
                >Anterior</v-btn>
                <div class="text-align-center" style="width: 20%; margin-top: 10px;">
                  Pág: {{currentPage}} / {{pageCount}}
                </div>
                <v-btn
                  style="width: 35%;"
                  :disabled="currentPage >= pageCount"
                  fill
                  color="primary"
                  @click="currentPage++"
                >Siguiente</v-btn>
              </div>
              <!-- <pdf :src="Documentacion.getDownloadUrl(activeElement)" :page="1">
                <template slot="loading">
                  Cargando...
                </template>
              </pdf> -->
              <pdf 
                v-show="loadedRatio && loadedRatio >= 1"
                ref="pdfViewer"
                class="pdf-viewer"
                :src="Documentacion.getDownloadUrl(activeElement)"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                @progress="loadedRatio = $event"
                :page="currentPage"
                @loaded="changeHeightCanvas"
              ></pdf>
              <div v-if="!loadedRatio || loadedRatio < 1" class="text-align-center color-primary">
                <div class="preloader color-primary">
                  <span class="preloader-inner">
                    <span class="preloader-inner-circle"></span>
                  </span>
                </div>
                <span>Cargando...</span>
              </div>
            </div>
            <div v-else style="width: 100%;">
              Tipo de documento aún no disponible para visualizado...
            </div>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-app>
  </f7-page>
</template>
<script>
import rInput from "./../../components/rInput.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rBadge from './../../components/rBadge';
import Utils from "./../../js/Utils.js";
import rForm from "./../../components/rForm.vue";
import DateUtils from '../../js/DateUtils';
import Documentacion from '../../js/db/Documentacion'
import moment from 'moment';
import pdf from 'vue-pdf';
// import pdf from 'pdfvuer'

// import 'vuetify/dist/vuetify.min.css';


  export default {
    components: {
      rInput,
      rForm,
      rBadge,
      rAutocomplete,
      rDireccion,
      rTimepicker,
      pdf
    },
    data: () => ({
      moment,
      Documentacion,
      tree: [],
      activeElement: false,
      extensions: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-code-json',
        md: 'mdi-language-markdown',
        pdf: 'mdi-file-pdf-box',
        png: 'mdi-file-image',
        jpg: 'mdi-file-image',
        jpeg: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
      },
      items: [],
      activeDrag: true,
      pageCount: 0,
      currentPage: 1,
      loadedRatio: 0,
      errorLoading: undefined,
      dialog: false,
    }),
    mounted: function () {
      const self = this;
      Documentacion.getList().then(res => {
        self.items = res.list;
      }).catch(self.catchError)
    },
    created: function() {
      const self = this;
    },
    props: {

  },
    watch: {
      // loadedRatio: function(val) {
      //   if (val >= 1) {
      //     const self = this;
      //     self.changeHeightCanvas();
      //   }
      // },
      currentPage: function (val) {
        const self = this;
        self.changeHeightCanvas();
      }
    }, 
    methods: {
      changeHeightCanvas() {
        let canvas = document.getElementsByTagName("canvas");
        const self = this;
        if (canvas && canvas.length) {
          setTimeout(_ => {
            let ratio = window.innerHeight / window.innerWidth;
            // let h = canvas[0].parentElement.parentElement.style.height;
            // let w = canvas[0].parentElement.parentElement.style.width;
            if (self.dialog) {
              let card = document.getElementsByClassName("popup-dialog");
              canvas = card[0].getElementsByTagName("canvas");
              let h = window.innerHeight;
              let w = h / ratio;
              if (canvas.length) {
                // canvas[0].style.width = '100%';
                // canvas[0].style.height = '100%';
                canvas[0].style.height = (h * 2) + 'px';
                // canvas[0].style.width = w + 'px';
                card[0].getElementsByClassName("page-content")[0].scrollTo(0, 0);
              }
            } else {
              let h = window.innerHeight - 180;
              let w = h / ratio / 2.8;
              if (canvas[0]) {
                canvas[0].style.height = h + 'px';
                canvas[0].style.width = w + 'px';
              }
            }
            // console.log("height: " + canvas[0].style.height);
            // console.log("width: " + canvas[0].style.width);
          }, 200);
        }
      },
      getPdfHeight: function () {
        return window.innerHeight - 180;
      },
      onDrop: function(e, item) {
        const self = this;
        console.log("DROPPPPP");
        console.log(e);
        console.log(item);
        if (!Utils.getUser() || !Utils.getUser().roles || (Utils.getUser().roles.indexOf('ADMIN') < 0 && Utils.getUser().roles.indexOf('DTYCAL') < 0)) {
          return;
        }
        self.loadFile(e, item);
      },
      loadFile: function(event, item) {
        var self = this;
        var app = self.$f7;
        let files = [];
        if (event instanceof FileList || Array.isArray(event)) {
          files = event;
        } else {
          files = event.target && event.target.files ? event.target.files : (event.dataTransfer && event.dataTransfer.files ? event.dataTransfer.files : [])
        }
        files.forEach(f => {
          Documentacion.upload(f, item).then(res => {
            item.children = res.list;
          }).catch(self.catchError);
        });
      },
      catchError: function (e) {
        const self = this;
        const app = self.$f7;
        if (typeof e == "string") {
          e = {
            message: e
          };
        }
        app.dialog.alert(e.message);
      },
      onActiveItem: function (e) {
        const self = this;
        self.currentPage = 1;
        if (e && e.length && !e[0].isDirectory) {
          self.activeElement = e[0];
        } else {
          self.activeElement = false;
        }
        console.log(e);
      },
      deleteFolder: function (item) {
        const self = this;
        const app = self.$f7;
        app.dialog.confirm(
          "¿Esta seguro que quieres eliminar " + (item.isDirectory ? "la carpeta " : "el documento ") + item.name + " y todo su contenido?",
          "Eliminar " + (item.isDirectory ? "carpeta " : "documento ") + item.name,
          function () {
            Documentacion.delete({ data: item }).then((res) => {
              let parentItem = self.getParentItem(item, self.items);
              if (!parentItem) {
                self.items = res.list;
              } else {
                parentItem.children = res.list;
              }
            }).catch(self.catchError);
          }
        );
      },
      createFolder: function (item) {
        const self = this;
        const app = self.$f7;
        app.dialog.prompt(
          "Introduzca el nombre de la carpeta",
          "Creación de carpeta",
          function (nombre) {
            let sendItem = {
              name: nombre,
              path: item.path,
              isDirectory: true
            }
            Documentacion.post({ data: sendItem }).then((res) => {
              if (!item.path) {
                self.items = res.list;
              } else {
                item.children = res.list;
              }
            }).catch(self.catchError);
          }
        );
      },
      rename: function (item) {
        const self = this;
        const app = self.$f7;
        app.dialog.prompt(
          "Introduzca el nuevo nombre",
          "Renombrado",
          function (nombre) {
            let sendItem = {
              name: nombre + '.' + item.extension,
              path: item.path
            }
            Documentacion.put({ data: sendItem }).then((res) => {
              let parentItem = self.getParentItem(item, self.items);
              if (!parentItem) {
                self.items = res.list;
              } else {
                parentItem.children = res.list;
              }
            }).catch(self.catchError);
          }
        );
      },
      getParentItem(item, items, parent) {
        const self = this;
        for (let index = 0; index < items.length; index++) {
          const element = items[index];
          if (element.path == item.path) {
            return parent;
          } else if (element.children && element.children.length) {
            let p = self.getParentItem(item, element.children, element);
            if (p) {
              return p;
            }
          }
        }
        return false;
      }
    }
  }
</script>
<style>
.item-icon.v-file-input {
  margin-left: 5px;
  max-width: 20px;
  margin-bottom: 15px;
}
.item-icon.v-file-input button.v-icon.mdi-file-document-plus {
  font-size: 16px;
  color: #4CAF50;
}
.v-application {
  max-height:95% !important;
}
.v-application--wrap {
  min-height:0 !important;
}
.v-application .v-menu__content {
  position: fixed !important;
}
.v-application .v-treeview-node__label {
  flex: none;
}
.v-treeview-node__root .item-icons {
  /* margin-left: 10px; */
  display: none;
}
.v-treeview-node__root:hover .item-icons {
  /* margin-left: 10px; */
  display: flex;
}

.v-application--is-ltr .v-treeview-node__append {
  width: 100%;
}
.v-dialog__content.v-dialog__content--active {
  z-index: 6000 !important;
}
</style>
<style scoped>
@import '../../css/vuetify.css';

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 19;
  background-color: white;
}

.doc-content {
  overflow-y: auto;
  overflow-x: hidden;
}

button.item-icon {
  margin-left: 5px;
  max-width: 20px;
}

.item-end-label {
  font-size: 11px;
  color: #80808094;
}
.iconos-superiores {
  margin-left: 15px;
}
.icono-superior {
  justify-content: start !important;
}

.file-time {
  font-size: 11px;
  color: #80808094;
}

.pdf-viewer {
  /* width: 80%; */
  margin: auto;
  /* min-width: 0; */
  /* max-height: 100%; */
}

</style>

