import DatabaseModel from './DatabaseModel.js';

var Combos = Object.create(DatabaseModel);

Combos.model = 'combos';

Combos.getComboById = function(comboId){
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/search/' + comboId + '/',
        method: 'GET',
        data: {}
    };
    return self.sendRequest(params);
}

export default Combos;