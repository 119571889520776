import Utils from '../Utils.js';
import Sugerencia from './Sugerencia.js';

var DatabaseModel = {
    getList: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            var registros = window.db.getCollection(self.model);
            if (registros === null) registros = window.db.addCollection(self.model);
            var result = registros.chain().find(params.filter || {}).simplesort(params.order || '', params.orderDir == 'ASC').offset(params.offset).limit(params.limit).data();
            var count = registros.count(params.filter || null);
            var result_cloned = JSON.parse(JSON.stringify(result));
            resolve({ list: result_cloned, count });
        });
    },
    get: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.itemId) reject({ error: 'itemId not defined' });
            var registros = window.db.getCollection(self.model);
            if (registros === null) registros = window.db.addCollection(self.model);
            var result = registros.find({ id: params.itemId });
            var result_cloned = JSON.parse(JSON.stringify(result));
            if (result.length) {
                resolve(result_cloned[0]);
            } else {
                reject({ error: 'No se encuentra el registro' });
            }
        });
    },
    put: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.itemId) reject({ error: 'itemId not defined' });
            var registros = window.db.getCollection(self.model);
            if (registros === null) registros = window.db.addCollection(self.model);
            var result = registros.find({ id: params.itemId });
            if (!result.length) reject({ error: 'Not found reg to update' });
            result[0] = params.data;
            var result = registros.update(result[0]);
            window.db.saveDatabase();
            if (result) {
                resolve(params.data);
            } else {
                reject({ error: 'Error' });
            }
        });
    },
    putAll: function(items) {
        var self = this;
        items = items || [];
        return new Promise(function(resolve, reject) {
            var results = [];
            items.forEach(item => {
                if (!item.id) reject({ error: 'id not defined' });
                var registros = window.db.getCollection(self.model);
                if (registros === null) registros = window.db.addCollection(self.model);
                var result = registros.find({ id: item.id });
                if (!result.length) reject({ error: 'Not found reg to update' });
                result[0] = item;
                var result = registros.update(result[0]);
                if (!result) {
                    reject({ error: 'Error actualizando' });
                } else {
                    results.push(result);
                }
            });
            window.db.saveDatabase();
            resolve(results);
        });
    },
    post: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            var registros = window.db.getCollection(self.model);
            if (registros === null) registros = window.db.addCollection(self.model);
            if (params.data.$loki) {
                var result = registros.update(params.data);
            } else {
                var result = registros.insert(params.data);
            }
            window.db.saveDatabase();
            if (result) {
                resolve(params.data);
            } else {
                reject({ error: 'Error' });
            }
        });
    },
    delete: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.itemId) reject({ error: 'itemId not defined' });
            var registros = window.db.getCollection(self.model);
            if (registros === null) registros = window.db.addCollection(self.model);
            var result = registros.find({ id: params.itemId })[0];
            registros.remove(result);
            window.db.saveDatabase(function(err) {
                if (err) {
                    console.log("error : " + err);
                    reject({ error: err });
                } else {
                    resolve(result);
                }
            });
        });
    },
    syncUp: function(params) {
        var self = this;
        params = params || {};
        return new Promise(function(resolve, reject) {
            if (!params.data) {
                reject({ error: 'Datos no definidos' });
            }
            var albaran = JSON.parse(JSON.stringify(params.data));
            albaran.albaranFirmado = params.data.albaranFirmado;
            
            if (!params.sugerencia) {
                if (albaran.id.startsWith('local')) {
                    delete albaran.id;
                } 
                delete albaran.$loki;
                delete albaran.meta;
            }
            
            self.model = "inspecciones";
            return self.getList({filter: {id: {"$in": albaran.expedientes}}}).then(function(res) {
                if (res.error) {
                    reject(data);
                    return;
                }
                var inspecciones = res.list;

                var error = self.comprobarExpedientes(inspecciones);
                if (error && error.idExp) {
                    reject(error);
                    return;
                }

                if (params.sugerencia) {
                    albaran.expedientes = inspecciones;
                    return Sugerencia.post({
                        data: { sugerencia: JSON.stringify(albaran), albaranMalo: true}
                      }).then(data => resolve(data)).catch( e => reject(e));

                }

                for (let i = 0; i < inspecciones.length; i++) {
                    var inspeccion = inspecciones[i];
                    if (inspeccion.id.startsWith('local')) {
                        delete inspecciones[i].id;
                    } 
                    // else {
                    //     delete inspecciones[i].clienteId;
                    // }
                    // delete inspecciones[i].cliente;
                    // delete inspecciones[i].formato;
                    // delete inspecciones[i].estado;
                    // delete inspecciones[i].lineaPedido;
                    delete inspecciones[i].$loki;
                    delete inspecciones[i].meta;
                    delete inspecciones[i].idAlbaran;
                    delete inspecciones[i].codigoAlbaran;
                }
                var inspeccionesString = JSON.stringify(inspecciones);
                while (inspeccionesString.match(/\{\"\$numberLong\":\"(.*)\"\}/)) {
                    inspeccionesString = inspeccionesString.replace(/\{\"\$numberLong\":\"([0-9]+)\"\}/,'$1');
                    
                }
                inspecciones = JSON.parse(inspeccionesString);
                albaran.expedientes = inspecciones;

                return self.uploadDocuments(albaran).then(function(data) {
                    return self.request.promise({
                        url: self.server_movilidad + self.prefix + 'albaran/sync/',
                        method: 'POST',
                        contentType: 'application/json',
                        dataType: 'json',
                        crossDomain: true,
                        data: data
                    }).then(function({ data, xhr, status }) {
                        if (data.error) {
                            reject(data);
                        } else {
                            var collection = window.db.getCollection('inspecciones');
                            if (collection === null) collection = window.db.addCollection('inspecciones');
                            data.dto.expedientes.forEach(function(item, idx) {
                                collection.findAndRemove({ id: params.data.expedientes[idx] });
                                collection.insert(item);
                            });
                            var collection = window.db.getCollection('albaranes');
                            if (collection === null) collection = window.db.addCollection('albaranes');
                            collection.findAndRemove({ id: params.data.id });
                            data.dto.expedientes = data.dto.expedientes.map(e => e.id);
                            collection.insert(data.dto);
                            window.db.saveDatabase(function(err) {
                                if (err) {
                                    console.log("error : " + err);
                                    reject({ error: err });
                                } else {
                                    resolve(data);
                                }
                            });
                        }
                    }).catch( function(e) {
                        if (typeof e == 'string') e = { error: e };
                        if (typeof e == 'undefined') e = { error: 'Error desconocido' };
                        if (e.xhr) {
                            var { xhr, status, message } = e;
                            if (status == 0) {
                                // Offline
                                reject({ error: 'Se necesita conexión a internet para sincronizar las inspecciones' });
                            } else {
                                try {
                                    reject(JSON.parse(xhr.response));
                                } catch (e) {
                                    reject(xhr.response);
                                }
                            }
                        } else {
                            reject(e);
                        }
                    });
                }).catch(function(e) {
                    if (typeof e == 'string') e = { error: e };
                    if (typeof e == 'undefined') e = { error: 'Error en la subida de documentos' };
                    if (xhr in e) {
                        var { xhr, status, message } = e;
                        if (status == 0) {
                            // Offline
                            reject({ error: 'Se necesita conexión a internet para sincronizar las inspecciones' });
                        } else {
                            try {
                                reject(JSON.parse(xhr.response));
                            } catch (e) {
                                reject(xhr.response);
                            }
                        }
                    } else {
                        reject(e);
                    }
                });
            }).catch( function(e) {
                if (typeof e == 'string') e = { error: e };
                if (typeof e == 'undefined') e = { error: 'Error desconocido' };
                if (e.xhr) {
                    var { xhr, status, message } = e;
                    if (status == 0) {
                        // Offline
                        reject({ error: 'Se necesita conexión a internet para sincronizar las inspecciones' });
                    } else {
                        try {
                            reject(JSON.parse(xhr.response));
                        } catch (e) {
                            reject(xhr.response);
                        }
                    }
                } else {
                    reject(e);
                }
            });
        });
    },
    expMalo: function (expediente) {
        return Sugerencia.post({
            data: { sugerencia: JSON.stringify(expediente), expedienteMalo: true}
          });
        
    },
    cleanData: function() {
        const self = this;
        return new Promise(function(resolve, reject) {
            ['configuraciones', 'componentesProteccion', 'equipos', 'clientes', 'formatos', 'inspectores', 'municipios', 'provincias', 'paises', 'comunidades', 'evaluadoresjs'].forEach(function(el) {
                var collection = window.db.getCollection(el);
                if (collection === null) collection = window.db.addCollection(el);
                collection.clear();
            });
            // inspecciones
            var collection = window.db.getCollection('inspecciones');
            if (collection === null) collection = window.db.addCollection('inspecciones');
            collection.removeWhere(function(el) {
                return !el.codigo && !el.guardadoLocal;
            });
            window.db.saveDatabase(function(err) {
                if (err) {
                    console.log("error : " + err);
                    reject({ error: err });
                } else {
                    resolve();
                }
            });
        });
    },
    sync: function(params) {
        const self = this;
        params = params || {};
        params.data = params.data || {};
        var configuraciones = window.db.getCollection('configuraciones');
        if (configuraciones != null) {
            var result = configuraciones.find({ id: 'CONFIG' });
            if (result.length) {
                params.data.syncDate = result[0].syncDate;
                params.data.dispositivo = result[0].dispositivo;
            }
        }

        if (!params.data.dispositivo) {
            if (typeof device != "undefined") {
                params.data.dispositivo = device;
            } else {
                params.data.dispositivo = {};
                params.data.dispositivo.uuid = "WEB_" + window.app.data.user.username;
            }
        }

        return new Promise(function(resolve, reject) {
            self.request.promise({
                url: self.server_movilidad + self.prefix + 'sincronizacion/',
                method: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                crossDomain: true,
                data: params.data
            }).then(function({ data, xhr, status }) {
                console.log(data);
                if (data.error) {
                    reject(data);
                } else {
                    // Guardo en local las imágenes
                    var p = Promise.resolve();
                    /*for (let i = 0; i < data.dto.modelos.length; i++) {

                        p = p.then(_ => new Promise(function(resolve, reject) {
                            var img = data.dto.modelos[i].imagen;
                            if (!img || !img.path) {
                                resolve();
                                return;
                            };
                            self.request.promise({
                                url: self.server_inspecciones + self.prefix + 'documento/download/',
                                method: 'POST',
                                contentType: 'application/json',
                                crossDomain: true,
                                xhrFields: { 'responseType': 'arraybuffer' },
                                data: {
                                    nombre: img.nombre,
                                    extension: img.extension,
                                    path: img.path
                                }
                            }).then(function({ data, xhr, status }) {
                                if (typeof cordova == 'undefined') {
                                    resolve();
                                    return;
                                }
                                // Guardar imagen
                                try {
                                    var baseDir = eapp.getPath('userData');
                                    let dir = baseDir + '/modelos/';
                                    if (!fs.existsSync(dir)) fs.mkdirSync(dir);
                                } catch (err) {
                                    reject({ error: 'Error almacenando imágenes: No se pudo crear el directorio local' });
                                    console.log(err);
                                    return;
                                }
                                data = new Buffer(data);
                                fs.writeFile(baseDir + '/modelos/' + img.nombre + '.' + img.extension, data, 'binary', function(err) {
                                    if (err) reject({ error: 'Error almacenando imágenes' });
                                    else resolve();
                                });
                            }).catch(function(e) {
                                if (typeof e == 'string') e = { error: e };
                                if (typeof e == 'undefined') e = { error: 'Error desconocido' };
                                if ('xhr' in e) {
                                    let { xhr, status, message } = e;
                                    try {
                                        reject(JSON.parse(xhr.response));
                                    } catch (e) {
                                        reject(xhr.response);
                                    }
                                } else {
                                    reject(e);
                                }
                            });
                        }));
                    }*/
                    p.then(function() {
                        // Almacenar todo en local
                        // Borrar todos e insertar de nuevo
                        ['componentesProteccion', 'equipos', 'formatos', 'inspectores', 'municipios', 'provincias', 'paises', 'comunidades', 'evaluadoresjs'].forEach(function(el) {
                            var collection = window.db.getCollection(el);
                            if (collection === null) collection = window.db.addCollection(el);
                            collection.clear();
                            if (process.env.TARGET == 'web' && el == 'formatos' && data.dto[el] && data.dto[el].length > 3) data.dto[el] = [data.dto[el][0], data.dto[el][1], data.dto[el][2]];
                            if (process.env.TARGET == 'web' && el == 'municipios' && data.dto[el] && data.dto[el].length > 20) {
                                data.dto[el].splice(20);  
                            } 
                            if (data.dto[el]) {
                                collection.insert(data.dto[el]);
                            }
                        });
                        // Actualizar los nuevos
                        ['clientes'].forEach(function(el) {
                            var collection = window.db.getCollection(el);
                            if (collection === null) collection = window.db.addCollection(el);
                            if (process.env.TARGET == 'web' && el == 'clientes' && data.dto[el] && data.dto[el].length > 3) data.dto[el] = [data.dto[el][0], data.dto[el][1], data.dto[el][2]];
                            // collection.clear();
                            var list = data.dto[el];
                            if (collection.data && collection.data.length) {
                                list.forEach(l => {
                                    var result = collection.find({id: l.id});
                                    if (result.length) {
                                        result[0] = Object.assign(result[0], l);
                                        collection.update(result[0]);
                                    } else {
                                        collection.insert(l);
                                    }
                                });
                            } else if (data.dto[el]) {
                                collection.insert(data.dto[el]);
                            }
                        });
                        // Inspecciones
                        var collection = window.db.getCollection('inspecciones');
                        if (collection === null) collection = window.db.addCollection('inspecciones');
                        collection.removeWhere(function(el) {
                            return !el.codigo && !el.guardadoLocal;
                        });

                        data.dto['expedientes'].forEach(expediente => {
                            var saved = collection.chain().find({id: expediente.id}).data()[0];
                            
                            if (saved) {
                                saved = Object.assign(saved, Utils.cleanObj (expediente));
                                collection.update(saved);
                            } else {
                                collection.insert(expediente);
                            }
                        })

                        var dispositivo = data.dto['dispositivo'];

                        // Configuraciones
                        var syncDate = new Date();
                        var configuraciones = window.db.getCollection('configuraciones');
                        if (configuraciones == null) configuraciones = window.db.addCollection('configuraciones');
                        var result = configuraciones.find({ id: 'CONFIG' });
                        if (result.length) { // Ya existe configuracion en local
                            result[0].syncDate = syncDate;
                            var dispositivoActual = result[0].dispositivo;
                            if (dispositivoActual && dispositivo.anyo == dispositivoActual.anyo && dispositivo.secAlbaran > dispositivoActual.secAlbaran) {
                                dispositivoActual.secAlbaran = dispositivo.secAlbaran;
                            } else if (!dispositivoActual) {
                                result[0].dispositivo = dispositivo;
                            } else {
                                result[0].dispositivo = dispositivoActual;
                            }
                            configuraciones.update(result[0]);
                        } else { // No existe configuracion en local
                            configuraciones.insert({id: 'CONFIG', syncDate: syncDate, dispositivo: dispositivo});
                        }

                        window.db.saveDatabase(function(err) {
                            if (err) {
                                console.log("error : " + err);
                                reject({ error: err });
                            } else {
                                resolve();
                            }
                        });
                    });
                    return p;
                }
            }).catch(function(e) {
                self._catchError(e, reject);
            });
        });
        
    },
    _catchError(e, reject) {
        if (typeof e == 'string') e = { error: e };
        if (typeof e == 'undefined') e = { error: 'Error desconocido' };
        if (e.xhr) {
            var { xhr, status, message } = e;
            if (status == 0) {
                // Offline
                reject({ error: 'Se necesita conexión a internet para sincronizar las inspecciones' });
            } else {
                try {
                    reject(JSON.parse(xhr.response));
                } catch (e) {
                    reject(xhr.response);
                }
            }
        } else {
            reject(e);
        }
    }
}

export default DatabaseModel;