import DatabaseModel from './DatabaseModel.js';

var Notificacion = Object.create(DatabaseModel);

Notificacion.model = 'notificaciones';

Notificacion.registrarToken = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/registrarToken',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

Notificacion.enviar = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/sendNotificacion',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default Notificacion;