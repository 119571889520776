import DatabaseModel from './DatabaseModel.js';

var Tabla = Object.create(DatabaseModel);

Tabla.model = 'core/tabla';

Tabla.getTabla = function(codigo) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/getTabla/' + codigo + '/',
        method: 'GET',
        data: {}
    };
    return self.sendRequest(params, false);
}

Tabla.consulta = function(codigo, filtros) {
    const self = this;
    return new Promise(function(resolve, reject) {
        //var params_ser = '';
        self.request.promise({
            url: self.server_movilidad + self.prefix + self.model + '/consulta/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {codigo, filtros}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
} 

export default Tabla;