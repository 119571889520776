<template>
  <r-list
    ref="rlist"
    :dbAdapter="Albaran"
    sort="fecha"
    sortDir="DESC"
    :tableMode="true"
    :virtualListParams="{height:120.8}"
    class="page albaranes-offline"
    classContent="page-content data-table"
    :classList="['media-list','no-margin']"
    :infiniteScroll="false"
    :rowsPerPage="20"
    :funcShowCheck="showCheck"
  >
    <template v-slot:massiveActions="{selecteds}">
        <f7-button
          fill
          icon-f7="trash"
          text="Eliminar selección"
          class="display-flex"
          color="red"
          @click="eliminarSeleccionados(selecteds)"
        ></f7-button>
      </template>
    <!-- Top Navbar -->
    <template v-slot:before="{}">
      <f7-navbar
        :title="$t('albaranes.albaranes')"
        :back-link="$t('atras')"
      >
        <f7-link  slot="right" popup-open=".popupnew">
          {{$t('albaranes.new')}}
          <f7-icon f7="chevron_right"></f7-icon>
        </f7-link>
      </f7-navbar>
      <f7-popup ref="popupnew" class="popupnew" tablet-fullscreen @popup:close="$refs.rform.clear(); step = 1;" @popup:open="setCodigo()">
        <f7-page>
          <f7-navbar>
            <f7-nav-left>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-left>
            <f7-nav-title>
              {{$t('albaranes.new')}} - {{step}}/{{maxStep}}
            </f7-nav-title>
          </f7-navbar>
          <r-form 
            ref="rform" 
            :dbAdapter="Albaran" 
            classContent="full-height"
            :beforeSave="beforeSave"
            @save="onSave"
            @loadItem="setCodigo"
            :defaultData="{firma:{}, fecha: new Date()}"
            :uploadDocuments="uploadDocumentsTablet"
            :documentoAdapter="target == 'cordova' ? DocumentoOffline : Documento"
          >
            <template v-slot:default="{rform}">
              <f7-block-title v-if="step == 1">{{$t('albaranes.cliente')}}</f7-block-title>
              <div v-if="step == 1" class="list" style="z-index: 2;">
                <ul>
                  <r-autocomplete
                    ref="cliente"
                    :dbItems="clientes"
                    :form="rform"
                    :idProp="null"
                    name="cliente"
                    labelProp="razonSocial"
                  ></r-autocomplete>
                </ul>
              </div>
              <f7-block-title v-if="step == 2">{{$t('albaranes.expedientes_incluir')}} - {{rform.formData.cliente.razonSocial}}</f7-block-title>
              <f7-list v-if="step == 2">
                <template
                  v-for="inspeccion in inspecciones"
                >
                  <f7-list-item
                    v-if="inspeccion.cliente && inspeccion.cliente.razonSocial == rform.formData.cliente.razonSocial"
                    :key="'inspeccion.'+inspeccion.id"
                    checkbox
                    :value="inspeccion.id"
                    name="expedientes"
                    :title=" DateUtils.dateFormat(inspeccion.fechaInsp, 'DD/MM/YYYY') + ' ' + DateUtils.dateFormat(inspeccion.hc1, 'HH:mm:ss') + ' - ' + (inspeccion.formato ? (inspeccion.formato.codigo + ' - ' + inspeccion.formato.nombre) : 'SIN FORMATO')"
                    @change="changeCheck"
                    :checked="rform.formData.expedientes && rform.formData.expedientes.indexOf(inspeccion.id) >= 0"
                  ></f7-list-item>
                </template>
              </f7-list>
              <f7-block-title v-if="step == 3">{{$t('albaranes.resumen')}}</f7-block-title>
              <div id="resumenAlbaran" ref="resumen" v-if="step == 3">
                <header class="page-header" v-if="firmado">
                  <img class="logo" alt="" src="static/icons/128x128.png" />
                  <p class="texto-cabecera">
                    SEGURIDAD INDUSTRIAL, MEDIOAMBIENTE Y CALIDAD, S.L.<br />
                    C/ Manuel Azaña, 39, bajo<br />
                    47014 VALLADOLID<br />
                    Tlf: 983 36 28 27<br />
                    Fax: 983 36 29 01<br />
                    C.I.F.: B 47474259
                  </p>
                </header>
                <table class="datos-albaran">
                  <tr>
                    <th>{{$t('albaranes.numero_albaran')}}</th>
                    <td>{{rform.formData.codigo}}</td>
                  </tr>
                  <tr>
                    <th>{{$t('albaranes.fecha_albaran')}}</th>
                    <td>{{rform.formData.fecha | dateParse | dateFormat('DD/MM/YYYY') }}</td>
                  </tr>
                </table>
                <div class="datos-cliente">
                  <span>{{$t('albaranes.cliente')}}: {{rform.formData.cliente.razonSocial}}</span><br/>
                  <span>{{$t('albaranes.domicilio')}}: {{rform.formData.cliente.direccion}}</span><br/>
                  <span class="nif">{{$t('albaranes.nif')}}: {{rform.formData.cliente.nif}}</span>
                </div>
                <table class="datos-servicio">
                  <tr>
                    <th>{{$t('albaranes.servicio')}}</th>
                    <th>{{$t('albaranes.cantidad')}}</th>
                  </tr>
                  <tr
                    v-for="formato in getResumenFormatos(rform.formData.expedientes)"
                    :key="'formatos.' + formato.id">
                    <td>{{formato.nombre}}</td>
                    <td>{{formato.cantidad}}</td>
                  </tr>
                </table>
                <div class="fields">
                  <f7-row no-gap class="list list-form no-hairlines" v-if="!firmado">
                    <f7-col width="100" medium="100">
                      <f7-block-title>{{$t('albaranes.firmante')}}</f7-block-title>
                    </f7-col>
                    <f7-col width="100" medium="66">
                      <ul>
                        <r-input
                          floating-label
                          :label="$t('albaranes.nombre')"
                          :form="rform"
                          type="text"
                          :required="firmaIncompleta"
                          name="firma.nombre"
                        ></r-input>
                      </ul>
                    </f7-col>
                    <f7-col width="100" medium="33">
                      <ul> 
                        <r-input
                          floating-label
                          :label="$t('albaranes.nif')"
                          :form="rform"
                          type="text"
                          :required="firmaIncompleta"
                          name="firma.nif"
                        ></r-input>
                      </ul>
                    </f7-col>
                    <f7-col width="100" medium="100">
                      <ul>
                        <r-sign
                          :form="rform"
                          name="firma.firma"
                          ref="firma"
                        ></r-sign>
                      </ul>
                    </f7-col>
                  </f7-row>
                  <div class="sign-view" v-if="firmado">
                    <img class="sign-image" :src="signImage" />
                    <p class="sign-name">{{$t('albaranes.nif')}}: {{$refs.rform.formData.firma.nif}}</p>
                    <p>{{$t('albaranes.firmado')}}: {{$refs.rform.formData.firma.nombre}}</p>
                  </div>
                </div>
              </div>
              <vue-html2pdf
                v-if="step == 3"
                :show-layout="false"
                :enable-download="target == 'web' ? true : false"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="albaran"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                ref="html2Pdf"
                @hasGenerated="hasGenerated($event)"
              >
                <section class="pdf-content" slot="pdf-content" v-html="rform.formData.resumen">
                </section>
              </vue-html2pdf>
              <div class="display-inline-block text-align-center">
                <f7-button
                  v-if="verBotonAnterior()"
                  fill
                  color="red"
                  class="display-inline-block margin-horizontal"
                  @click="step--"
                >{{$t('albaranes.anterior')}}</f7-button>
                <f7-button
                  v-if="verBotonSiguiente()"
                  fill
                  color="primary"
                  class="display-inline-block margin-horizontal"
                  @click="step++"
                >{{$t('albaranes.siguiente')}}</f7-button>
                <f7-button
                  v-if="verBotonGuardar()"
                  fill
                  color="primary"
                  class="display-inline-block margin-horizontal"
                  @click="generateReport()"
                >{{$t('albaranes.guardar')}}</f7-button>
              </div>
            </template>
          </r-form>
        </f7-page>
      </f7-popup>
    </template>

    <!-- Page content-->
    <template v-slot:header="{rlist}">
      <thead class="thead-sticky">
        <tr>
          <th style="min-width:1em;">
            <input ref="checkGeneral" type="checkbox" @change="rlist.toggleSelectAll($event.target.checked)"
             />
          </th>
          <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
            <f7-icon f7="gear_alt_fill"></f7-icon>
          </th>
          <th
            style="min-width:1em;"
            @click="rlist.setOrder('codigo')"
            :class="rlist.sortableClasses('codigo')"
          >{{$t('albaranes.codigo')}}</th>
          <th
            style="min-width:1em;"
            @click="rlist.setOrder('cliente.razonSocial')"
            :class="rlist.sortableClasses('cliente.razonSocial')"
          >{{$t('albaranes.cliente')}}</th>
          <th>{{$t('albaranes.expedientes')}}</th>
          <th
            style="min-width:1em;"
            @click="rlist.setOrder('fecha')"
            :class="rlist.sortableClasses('fecha')"
          >{{$t('albaranes.fecha')}}</th>
          <th
            style="min-width:1em;"
          >{{$t('albaranes.firmado')}}</th>
          <th style="width:7em;" class="label-cell text-align-center right-column-sticky">
            <f7-icon f7="gear_alt_fill"></f7-icon>
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:default="{vlData, rlist}">
      <tr v-for="(item) in vlData.items" :key="'albaran-' + item.id">
        <td> 
          <input  
            v-if="showCheck(item)" 
            type="checkbox"
            :checked="rlist.getPrimary(item) in rlist.selected"
            @change="rlist.toggleSelect(item, $event.target.checked)"
          />
        </td>
        <td
          class="display-flex right-column-sticky align-items-center padding-half text-color-gray text-align-center"
        >
        <f7-button
          v-if="item.guardadoLocal"
          fill
          icon-f7="cloud_upload"
          color="green"
          @click="syncUp(item)"
        ></f7-button>
        </td>
        <td>{{item.codigo ? item.codigo : ''}}</td>
        <td>{{item.cliente ? item.cliente.razonSocial : ''}}</td>
        <td>{{item.expedientes ? item.expedientes.length : 0}}</td>
        <td>{{item.fecha | dateParse | dateFormat('DD/MM/YYYY')}}</td>
        <td><r-badge 
          :text="$t((item.firma && item.firma.firma) ? 'common.yes' : 'common.no')"
          :color="(item.firma && item.firma.firma) ? 'green' : 'red'"></r-badge></td>
        <td
          class="display-flex right-column-sticky align-items-center padding-half text-color-gray text-align-center"
        >
          <f7-button
            v-tooltip="$t('albaranes.malo')"
            icon-f7="exclamationmark_triangle_fill"
            icon-size="18"
            color="orange"
            @click="albaranMalo(item)"
          ></f7-button>  
          <f7-button
            v-tooltip="$t('albaranes.resumen')"
            icon-f7="info_circle"
            icon-size="18"
            color="green"
            @click="albaranSeleccionado=item"
            popup-open=".popupresumen"
          ></f7-button>
          <template v-if="item.guardadoLocal">
            <f7-button
              v-tooltip="$t('albaranes.eliminar')"
              fill
              icon-f7="trash"
              icon-size="18"
              color="red"
              class="margin-right-half"
              @click="deleteAlbaran(item)"
            ></f7-button>
            <f7-button
              fill
              icon-f7="cloud_upload"
              color="green"
              @click="syncUp(item)"
            >&nbsp;{{$t('albaranes.sincronizar')}}</f7-button>
          </template>
          <template v-else>
            <f7-button
              v-tooltip="$t('albaranes.eliminar')"
              icon-f7="trash"
              icon-size="18"
              color="red"
              class="margin-right-half"
              @click="rlist.deleteItem(item.id)"
            ></f7-button>
            <span>{{$t('albaranes.sincronizado')}}</span>
          </template>
        </td>
      </tr>
    </template>
    <template v-slot:empty>
      <f7-block>{{$t('albaranes.sin_albaranes')}}</f7-block>
    </template>
    <template v-slot:after="{rlist}">
      <f7-card-footer>
        <span>
          <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
        </span>
      </f7-card-footer>
      <f7-popup class="popupresumen" @popup:close="albaranSeleccionado = null" swipeToClose>
        <f7-page v-if="albaranSeleccionado" >
          <f7-navbar>
            <f7-nav-left>
              <f7-link popup-close>Cerrar</f7-link>
            </f7-nav-left>
            <f7-nav-title>
              {{$t('albaranes.resumen')}}
            </f7-nav-title>
            <f7-nav-right>
              
            </f7-nav-right>
          </f7-navbar>
          <f7-block-title>{{$t('albaranes.resumen')}}</f7-block-title>
          <div v-html="albaranSeleccionado.resumen">
          </div>
        </f7-page>
      </f7-popup>
    </template>
  </r-list>
</template>
<style>
.albaranes-offline  .card-header {
    padding-top: calc(var(--f7-navbar-height) + var(--f7-subnavbar-height) + 10px);
  }
.albaranes-offline  .page-content {
    --f7-page-subnavbar-offset: 0;
  }
.albaranes-offline  .data-table-header-selected {
    align-items: flex-end;
    flex: auto;
  }
.albaranes-offline .data-table-title-selected {
    flex: auto;
  }
.btn-separate-right .icon {
  margin-right: 10px;
}
.btn-separate-left .icon {
  margin-left: 10px;
}
.sign-image {
  width:100%;
  min-height: 225px;
}
#resumenAlbaran {
  position: relative;
  padding: 8px;
  width: calc(100% - 16px);
}
.pdf-content,
.pdf-content > div {
  width: 100%;
}

.pdf-content{
  padding: 4em;
  box-sizing: border-box;
}
#resumenAlbaran .page-header {
  margin: 3px 0 10px;
}
#resumenAlbaran .page-header .logo {
  width: 105px;
  position:absolute;
}
#resumenAlbaran .page-header .texto-cabecera {
  padding-left: 135px;
  margin: 0;
  font-size: 0.9em;
}
#resumenAlbaran .datos-albaran,
#resumenAlbaran .datos-cliente {
  position: absolute;
  width: calc(50% - 20px);
  display: block;
}
#resumenAlbaran .datos-albaran {
  border-collapse: collapse;
  display: table;
}
#resumenAlbaran th,
#resumenAlbaran td {
  padding: 1px 7px;
}
#resumenAlbaran .datos-albaran th,
#resumenAlbaran .datos-albaran td {
  border: 1px solid black;
  font-weight: normal;
  text-align: left;
}
#resumenAlbaran .datos-cliente {
  border: 1px solid black;
  right: 9px;
  height: 105px;
  padding: 1px 5px;
}
#resumenAlbaran .datos-cliente .nif {
  position: absolute;
  bottom: 0;
}
#resumenAlbaran .datos-servicio {
  margin-top: 130px;
  width: 100%;
  border: 2px solid black;
  border-collapse: collapse;
}
#resumenAlbaran .datos-servicio thead tr{
  border: 2px solid black;
}
#resumenAlbaran .datos-servicio th,
#resumenAlbaran .datos-servicio td {
  border: 1px solid black
}
#resumenAlbaran .fields, #resumenAlbaran .fields .list {
  margin: 0;
}
.sign-view .sign-name {
  margin-top: -54px;
}
.sign-view p {
  margin: 0 0 0 10px;
}
</style>
<script>
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rBadge from "./../../components/rBadge.vue";
import rSign from "./../../components/rSign.vue";
import Albaran from "./../../js/db/AlbaranOffline.js";
import Inspeccion from "./../../js/db/InspeccionOffline.js";
import VueHtml2pdf from 'vue-html2pdf'
import Configuracion from '../../js/db/ConfiguracionOffline';
import DatabaseModel from '../../js/db/DatabaseModelOffline';
import FileUtils from '../../js/FileUtils';
import Utils from '../../js/Utils';
import DateUtils from '../../js/DateUtils';
import Documento from "./../../js/db/Documento";
import DocumentoOffline from "./../../js/db/DocumentoOffline";

export default {
  components: {
    rList,
    rForm,
    rInput,
    rAutocomplete,
    rBadge,
    rSign,
    VueHtml2pdf
  },
  data() {
    return {
      Albaran,
      Inspeccion,
      Documento,
      DocumentoOffline,
      DateUtils,
      step: 1,
      maxStep: 3,
      inspecciones: [],
      clientes: [],
      albaranSeleccionado: null,
      target: process.env.TARGET,
      firmado: false,
      montado: false
    }
  },
  props: {},
  computed: {
    firmaIncompleta: function(){
      var self = this;
      if (!self.montado) {
        return false;
      }
      var rform = self.$refs.rform.formData;
      var firma = rform.firma;
      if(!firma) {
        return false;
      }
      return (!firma.firma || !firma.nombre || !firma.nif) && !(firma.firma && firma.nombre && firma.nif);
    },
    signImage: function(){
      var self = this;
      if (!self.montado) {
        return false;
      }
      var rform = self.$refs.rform.formData;
      var firma = rform.firma;
      if(firma && firma.firma) {
        return firma.firma;
      }
    }
  },
  methods: {
    uploadDocumentsTablet (data, documentoAdapter) {
      return data;

    },
    showCheck(item) {
      return !item.guardadoLocal;
    },
    eliminarSeleccionados(selecteds) {
      var self = this;
      var app = self.$f7;
      
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registros"),
        "Eliminar",
        function() {
          const total = Object.values(selecteds).length;
          var borrados = 0;
          Object.values(selecteds).forEach(selected => {
            self.$refs.rlist.deleteItem(selected.id, true)
            .then(r => {
              borrados++;
              if (borrados >= total) {
                self.$refs.rlist.selected = {};
                self.$refs.checkGeneral.checked = false;
                self.$refs.rlist.reload();
              }
            });
          });
        }
      );
      
    
    },
    setCodigo () {
      const self = this;
      if (self.$refs.rform && self.$refs.rform.formData) {
        Albaran.getCodigo()
          .then(codigo => {
            self.$set(self.$refs.rform.formData, "codigo", codigo);
          })
      }
    },
    deleteAlbaran (albaran) {
      const self = this;
      const app = self.$f7;
      self.$f7.dialog.confirm(
          self.$t("rlist.pregunta_eliminar_registro"),
          "Eliminar",
            function () {
              app.preloader.show();
              Inspeccion.getList({filter: {id: {"$in": albaran.expedientes}}}).then(function(res){
              var inspecciones = res.list;
              inspecciones.forEach(i => {delete i.idAlbaran; delete i.codigoAlbaran});
              Inspeccion.putAll(inspecciones)
                .then(function(res) {
                  self.reloadInspecciones();
                  self.$refs.rlist.deleteItem(albaran.id, true);
                }).catch(function(e){
                  app.dialog.alert("Ha ocurrido un error al borrar la asociación de expedientes a albarán.");
                  console.log(e);
                }).then(function(e) {
                  app.preloader.hide();
                });
            }).catch(function(e){
              app.dialog.alert("Ha ocurrido un error al borrar la asociación de expedientes a albarán.");
              console.log(e);
            }).then(function(e) {
              app.preloader.hide();
            });
          }
        );
      
      
    },
    hasGenerated (blobPdf) {
      const self = this;
      const app = self.$f7;
      var formData = self.$refs.rform.formData;
      app.preloader.show();
      if (self.target == 'cordova') {
        FileUtils.saveBlobFile(blobPdf, formData.codigo, "pdf", null, 
          function(res) {
            formData.albaranFirmado = res;
            app.preloader.hide();
            self.guardarAlbaran();
          },
          function(e) {
            app.dialog.alert(e.message);
            app.preloader.hide();
          });
      } else {
        app.preloader.hide();
        self.guardarAlbaran();
      }
      console.log(blobPdf)
    },
    generateReport () {
      const self = this;
      self.$refs.firma.updateValue();
      app.preloader.show();
      self.firmado = true;
      setTimeout(function(){
        self.$set(self.$refs.rform.formData, 'resumen', self.$refs.resumen.outerHTML);
        self.$refs.html2Pdf.generatePdf();
      }, 200);
    },
    onSave: function(res) {
      const self = this;
      const app = self.$f7;

      self.$refs.rlist.reload();
      
      // var inspeccionesCompletas = self.inspecciones.filter(i => res.expedientes.indexOf(i.id) >= 0) || [];
      Inspeccion.getList({filter: {id: {"$in": res.expedientes}}}).then(function(resInsp) {
        var inspeccionesCompletas = resInsp.list;
        inspeccionesCompletas.forEach(i => {
          i.idAlbaran = res.id;
          i.codigoAlbaran= res.codigo;
        });
        app.preloader.show();
        Albaran.aumentarSecAlbaran();
        Inspeccion.putAll(inspeccionesCompletas)
        .then(function(res) {
          self.reloadInspecciones();
          app.toast
            .create({
              text: "Albarán guardado",
              position: "center",
              closeTimeout: 2000,
              icon: '<i class="f7-icons">checkmark_alt</i>'
            })
            .open();
        }).catch(function(e){
          app.dialog.alert("Ha ocurrido un error al guardar los expedientes");
          console.log(e);
        }).then(function(e) {
          app.preloader.hide();
        });
      }).catch(function(e){
        app.preloader.hide();
        app.dialog.alert("Ha ocurrido un error al buscar los expedientes del albarán.");
        console.log(e);
      });
    },
    beforeSave: function(data, resolve, reject) {
      const self = this;
      const d = new Date();
      // id

      if (!data.id) {
        data.id = "local-" + d.getTime();
      }
      data.guardadoLocal = d;
      data.fecha = d;
      Configuracion.get({itemId: "CONFIG"})
        .then(config => {
          data.idDispositivo = config.dispositivo.id;
          data.anyo = config.dispositivo.anyo;
          data.secuencia = config.dispositivo.secAlbaran;
          resolve(data);
        })
        .catch(_ => {
          resolve(data);
        })
    },
    guardarAlbaran: function() {
      const self = this;
      self.$refs.rform.saveItem();
      if (self.$refs.popupnew) {
        self.$refs.popupnew.close();
      }
      self.$refs.rform.clear();
      self.firmado = false;
    }, 
    getResumenFormatos: function(ids) {
      const self = this;
      var formatos = [];
      self.inspecciones.filter(i => ids.indexOf(i.id) >= 0).forEach(i => {
        var formato = formatos.filter(f => f.id == i.formato.id)[0];
        if (formato) {
          formato.cantidad = (formato.cantidad || 0) + 1;
        } else {
          formato = {
            id: i.formato.id,
            cantidad: 1,
            nombre: i.formato.nombre,
            codigo: i.formato.codigo
          };
          formatos.push(formato);
        }
      });
      return formatos;
    },
    getResumenDirecciones: function(ids) {
      const self = this;
      var direcciones = [];
      self.inspecciones.filter(i => ids.indexOf(i.id) >= 0).forEach(i => {
        var direccion = direcciones.filter(d => d.id == JSON.stringify(i.direccionInspeccion))[0];
        if (!direccion) {
          direccion = JSON.parse(JSON.stringify(i.direccionInspeccion));
          direccion.id = JSON.stringify(i.direccionInspeccion);
          direcciones.push(direccion);
        }
      });
      return direcciones;
    },
    verBotonSiguiente: function() {
      const self = this;

      if (self.step >= self.maxStep || !self.$refs.rform || !self.$refs.rform.formData) {
        return false;
      }
      if (self.step == 1) {
        return self.$refs.rform.formData.cliente && self.$refs.rform.formData.cliente.razonSocial;
      }
      if (self.step == 2) {
        return self.$refs.rform.formData.expedientes && self.$refs.rform.formData.expedientes.length;
      }

      return false;
    },
    verBotonAnterior: function() {
      const self = this;

      if (self.step <= 1 || !self.$refs.rform || !self.$refs.rform.formData) {
        return false;
      }
      if (self.step == 3) {
        return !self.$refs.rform.formData.firma;
      }

      return true;
    },
    verBotonGuardar: function() {
      const self = this;

      return self.step == 3 && self.$refs.rform && self.$refs.rform.formData && self.$refs.rform.formData.firma;
    },
    changeCheck: function(event) {
      const self = this;
      const value = event.target.value;
      var arr = self.$refs.rform.formData.expedientes || [];
      if (event.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      self.$set(self.$refs.rform.formData, "expedientes", arr)
    },
    syncUp: function(albaran) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      DatabaseModel.syncUpAlbaran({ data: albaran })
        .then(function() {
          self.reloadInspecciones();
          app.preloader.hide();
          self.$refs.rlist.reload();
          app.toast
            .create({
              text: "Albaran y expedientes sincronizados",
              position: "center",
              closeTimeout: 2000,
              icon: '<i class="f7-icons">checkmark_alt</i>'
            })
            .open();
        })
        .catch(function(e) {
          app.preloader.hide();
          if (e &&  e.error && e.error.code == 'ERROR_AUDITORIA') {
            var message = e.error.message + " :<ul>";
            e.error.errors.forEach( err => {message+= "<li>"+err+"</li>";})
            message+="</ul>";
            app.dialog.confirm(
              message + " <br> ¿Desea abrir el expediente para corregirlo?",
              function() {
                app.views.main.router.navigate("/inspecciones/edit-offline/" + albaran.expedientes[e.error.aux[0]]+"/");

              }
            
            );
            console.log("error syncUp", e.error.message);
            return;
            
          }
          if (e && e.idExp) {
            app.dialog.confirm(
              e.errores[0] + ", ¿Desea abrir el expediente para corregirlo?",
              "Ha habido algún error de validación",
              function() {
                app.views.main.router.navigate("/inspecciones/edit-offline/" + e.idExp+"/");
              }
            );
          } else {
            var message = "";
            if (typeof e == 'string') {
              message = e;
            } else if (e.error && typeof e.error == 'string'){
              message = e.error;
            } else {
              message = e.error && e.error.message ? e.error.message : 'Error al sincronizar';
            }
            app.dialog.alert(message);
            console.log("error syncUp", e);

          }
        });
    },
    reloadInspecciones: function() {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      self.inspecciones = [];
      self.clientes = [];
      self.Inspeccion.getList(
        {
          filter: {
            'estado.estado': {'$in':['GENERADO', 'PLANIFICADO']}, 
            'cliente.razonSocial': {$exists: true},
            'guardadoLocal': { $exists: true }
          }
          ,projection: ['cliente.id', 'cliente.nif', 'cliente.razonSocial', 'cliente.direccionDefectoFacturasDTO', 'id', 'formato.id', 'formato.codigo', 'formato.nombre', 'cliente.razonSocial', 'direccionInspecion', 'hc1', 'fechaInsp']
          })
      .then(function(res) {
        self.inspecciones = res.list;
        self.clientes = self.inspecciones.filter(i => i.cliente && i.cliente.razonSocial).map(i => {
          return {id: i.cliente.id, razonSocial: i.cliente.razonSocial, nif: i.cliente.nif, direccion: Utils.getDireccionStr(i.cliente.direccionDefectoFacturasDTO)}
        }).filter((value, index, arr) => arr.map(a => a.razonSocial).indexOf(value.razonSocial) == index);
        self.$refs.cliente.reload(self.clientes);
        app.preloader.hide();
      }).catch(function(e){
        app.dialog.alert("Ha ocurrido un error al cargar las inspecciones.");
        app.preloader.hide();
      });
    },
    albaranMalo: function(albaran) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "Se va a reportar que este albarán está dando problemas de sincronización. ¿Desea continuar?",
        "Reportar albarán",
        function() {
          app.preloader.show();
          DatabaseModel.syncUpAlbaran({ data: albaran, sugerencia: true })
            .then(function(res) {
              app.preloader.hide();
              app.dialog.alert("Se ha reportado el error de sincronización correctamente. Una vez analizado se intentará solucionar lo antes posible. Muchas gracias. ");
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.message);
            });
        });
    }

    
  },
  mounted: function() {
    const self = this;
    var app = self.$f7;
    self.reloadInspecciones();
    self.montado = true;
    Configuracion.get({itemId: "CONFIG"})
      .then(result => {
        if (!Utils.isSync(result)) {
          app.views.main.router.navigate("/");
        }
      }).catch(_ => self.config = null);
  }
};
</script>
