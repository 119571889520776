import Error403 from './../pages/403.vue';
import Flash from './FlashUtils';
import Util from './Utils';

var RoutesUtils = {
    checkAuthPage: function(routeTo, routeFrom, resolve, reject) {
        var self = this;
        let user = RoutesUtils.getUser();
        if (!user || user == null) {
            reject();
            var loggedOut = Flash.get("logged-out");
            if(!loggedOut){
                // A este estado se llega al intentar entrar a un link interno de la aplicación antes de haber hecho login
                var directURL = window.location.href;
                Flash.set("directURL", directURL);
            }
            self.app.views.main.router.navigate("/login/");
        } else {
            resolve();
        }
    },
    checkAuth: function() {
        let user = RoutesUtils.getUser();
        if (!user || user == null) {
            return false;
        } else {
            return true;
        }
    },
    checkPermission: function(page, roles, Comp, props) {
        return function(routeTo, routeFrom, resolve, reject) {
            var menu = Util.getMenu();
            // while(menu == null || menu == undefined) {
            //     menu = Util.getMenu();
            // }
            var links = [];
            
            if (menu) {
                menu.items.forEach(i => RoutesUtils.getLinksMenuItem(i, links));
                if (routeTo.path == "/" || routeTo.path == "/perfil/"  || routeTo.path == "/index-offline/" || links.filter(link => link && link != "/" && routeTo.path.startsWith(link)).length > 0) {
                    resolve({
                        component: Comp
                    }, {
                        props: props
                    });
                } else {
                    resolve({ component: Error403 });
                }
            } else if (RoutesUtils.checkRolesPermission(roles)) {
                resolve({
                    component: Comp
                }, {
                    props: props
                });
            } else {
                resolve({ component: Error403 });
            }
        }
    },
    public: function(Comp, props) {
        return function(routeTo, routeFrom, resolve, reject) {
            resolve({
                component: Comp
            }, {
                props: props
            });
        }
    },
    checkRolesPermission: function(roles) {
        var self = this;
        let user = RoutesUtils.getUser();
        if (!user || user == null || roles.filter(r => user.roles.indexOf(r) > -1).length <= 0 ) {
            return false;
        } 
        else {
            return true;
        }
    },
    getUser: function() {
        var user = localStorage.getItem("user");
        if (typeof user != "undefined" && user) {
            var userObj = JSON.parse(user);
            return userObj;
        }
        return null;
    },
    getLinksMenuItem: function(menuItem, hijos) {
        const self = this;
        if (!menuItem) {
            return [];
        }
        if (!hijos) {
            hijos = [];
        }
        hijos.push(menuItem.link)
        if (menuItem.hijos && menuItem.hijos.length) {
            menuItem.hijos.forEach(i => self.getLinksMenuItem(i, hijos));
        }
        return hijos;
    }
}

export default RoutesUtils;