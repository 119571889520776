import Utils from "./Utils";
import * as nominatim from 'nominatim-client';

function DeepChangesObject() {
    this.diffs = [];
    this.push = function (diff) {
        let valL = diff.lhs;
        let valR = diff.rhs;
        let pathJoin = diff.path.join("");
        if (valL instanceof Date) {
            valL = valL.toISOString();
        }
        if (valR instanceof Date) {
            valR = valR.toISOString();
        }
        if (valL != valR && pathJoin != 'guardadoLocal' && pathJoin != 'guardadoSilencioso' && pathJoin != 'historicoOffline' &&
            pathJoin != 'tiempoRealAutomatico' && pathJoin != '_id' && diff.path[0] != 'sesiones' && !diff.path.includes("_id")) {
            this.diffs.push({
                anterior: valL,
                nuevo: valR,
                path: diff.path
            });
        }
    };
    this.toDatabaseFormat = async function () {
        if (!this.diffs || !this.diffs.length) {
            return null;
        }
        let res = {
            anterior: {},
            nuevo: {},
            date: new Date(),
            punto: await Utils.executeTask(5000, this.getLocation(), null)
        };

        this.diffs.forEach(diff => {
            this.setValue(res, diff.anterior, ['anterior', ...diff.path])
            this.setValue(res, diff.nuevo, ['nuevo', ...diff.path])
        });

        return res;

    };
    this.getLocation = async function() {
        const self = this;
        let location = null;
        try {
            if (navigator && navigator.geolocation) {
                const pos = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                if (pos) {
                    location = {
                        punto: { coordinates: [pos.coords.longitude, pos.coords.latitude], type: "Point", x: pos.coords.longitude, y: pos.coords.latitude },
                        latitud: pos.coords.latitude,
                        longitud: pos.coords.longitude
                    };

                    if (nominatim) {
                        try {
                            let nomClient = nominatim.default.createClient({ useragent: "SIMECAL-MOVILIDAD" });
                            const res = await nomClient.reverse({ lat: pos.coords.latitude, lon: pos.coords.longitude });
                            location.direccion = res.display_name;
                        } catch (e) {
                            console.log("Error obteniendo direccion de nominatim: ", e);
                        }
                    }
                }
            }
        } catch (e) {
            console.log("Error obteniendo direccion de nominatim: ", e);
        }
        return location;
    };
    this.setValue = function (obj, val, namespaces) {
        val = this.sanitizeJson(val);
        for (var i = 0; i < namespaces.length; i++) {
            if (i == namespaces.length - 1) {
                obj[namespaces[i]] = val;
            } else if (obj[namespaces[i]] == null || typeof obj[namespaces[i]] == "undefined") {
                obj[namespaces[i]] = {};
            }
            obj = obj[namespaces[i]];
        }
    };
    this.sanitizeJson = function (obj) {
        const self = this;
        // Object is an array? Then recursively sanitize each element
        if (Array.isArray(obj)) {
            return obj.map(self.sanitizeJson);
        }
        // Object is a non-null object
        else if (typeof obj === 'object' && obj !== null) {
            // Both '$id' and '$values' properties exist? Sanitize the '$values' property
            if (obj.hasOwnProperty('_id')) {
                delete obj._id;
            }
            Object.keys(obj).forEach(k => {
                if (self) {
                    obj[k] = self.sanitizeJson(obj[k]);
                }
            });
        }
        return obj;
    }

}

export default DeepChangesObject;