<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Tarea" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Tarea"
        :tableCode="'TAREA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="140"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/tarea/new/">{{$t('tarea.nuevo')}}</a>
      </template>
      <template v-slot:optionsColumn="{item}">
          <f7-link v-tooltip="$t('common.ver') + ' Tarea'" icon-f7="eye" color="green" :href="`/tarea/view/${item.id}/`"></f7-link>
          <f7-link icon-f7="square_pencil" color="blue" :href="`/tarea/edit/${item.id}/`"></f7-link>
          <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link>
          <!-- <f7-link 
            icon-f7="envelope" 
            color="brue" 
            :href="'/registroemail/filter/?filter={\'onlyAggregations\':false,\'withoutAggregations\':true,\'activo\':true,\'datos##codigoTarea\':\''+item.codigo+'\'}'"
          ></f7-link> -->
          <f7-link
            v-tooltip="'Ver emails'"
            class="margin-half link-inline-row-3 link-inline-1"
            icon-f7="envelope"
            @click="codigoTarea=item.codigo;verListadoMails = true;"
          ></f7-link>
      </template>
        <template v-slot:massiveActions="{ }">
          <f7-button
            fill
            icon-f7="envelope"
            text="Enviar email"
            class="display-flex"
            color="purple"
            @click="enviarEmail = true"
          ></f7-button>
        </template>
      </r-dynamic-list>
    </f7-page-content>

    <listado-mails
      v-if="verListadoMails"
      filterName="datos##codigoTarea"
      :filterValue="codigoTarea"
      @listado-cerrado="verListadoMails = false"
    ></listado-mails>
    
    <envio-mail
      v-if="enviarEmail"
      ref="enviarEmail"
      :selected="filasSeleccionadas()"
      @email-enviado="enviarEmail = false"
    ></envio-mail>
    
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<style>
  .aurora .f7-icons, .aurora .material-icons {
    font-size: 16px;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import EnvioMail from "./components/envio-mail.vue";
import ListadoMails from "./../../components/listado-mails.vue";
import Tarea from "./../../js/db/Tarea.js";
import Utils from "./../../js/Utils.js";
import RegistroEmail from "./../../js/db/RegistroEmail.js";

export default {
  components: {
    rDynamicList,
    EnvioMail,
    ListadoMails
  },
  data() {
    return {
      Tarea,
      enviarEmail: false,
      verListadoMails: false,
      popupVerEmailsOpened: false,
      codigoTarea: null,
      Utils,
      RegistroEmail
    };
  },
  computed: {},
  methods: {
    filasSeleccionadas: function() {
      let filas = [...Object.values(this.$refs.rlist.selected)].map(selected => {
        return {id: selected.id};
      });
      return filas;
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>