import Documentacion from './documentacion.vue';
import RoutesUtils from './../../js/RoutesUtils.js';


var routes = [

    {
        path: '/documentacion/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('documentacion', [], Documentacion),
        keepAlive: false
    }

];
export default routes;
