import Tareas from './tareas.vue';
import TareasForm from './tarea-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/tarea/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarea', ['ADMIN', 'INSPECTOR'], Tareas),
        keepAlive: true
    },
    {
        path: '/tarea/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarea/new', ['ADMIN', 'INSPECTOR'], TareasForm)
    },
    {
        path: '/tarea/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarea/edit', ['ADMIN', 'INSPECTOR'], TareasForm)
    },
    {
        path: '/tarea/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tarea/view', ['ADMIN', 'INSPECTOR'], TareasForm, { editable: false })
    },
];
export default routes;