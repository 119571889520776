import Albaranes from './albaranes.vue';
import AlbaranesOffline from './albaranes-offline.vue';
// import AlbaranForm from './albaran-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [
    {
        path: '/albaranes/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('albaranes', ['ADMIN', 'INSPECTOR'], Albaranes),
        keepAlive: true
    },
    {
        path: '/albaranes-offline/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('albaranes', ['ADMIN', 'INSPECTOR'], AlbaranesOffline),
        keepAlive: false
    }
];
export default routes;