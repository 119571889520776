import Fichajes from './fichajes.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/fichaje/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('fichaje', ['ADMIN', 'INSPECTOR'], Fichajes),
        keepAlive: true
    },
];
export default routes;