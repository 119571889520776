import ListasPrecios from './listasprecios.vue';
import ListasPreciosForm from './listasprecios-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/listasprecios/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listasprecios', ['ADMIN', 'INSPECTOR'], ListasPrecios),
        keepAlive: true
    },
    {
        path: '/listasprecios/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listasprecios/new', ['ADMIN', 'INSPECTOR'], ListasPreciosForm)
    },
    {
        path: '/listasprecios/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listasprecios/edit', ['ADMIN', 'INSPECTOR'], ListasPreciosForm)
    },
    {
        path: '/listasprecios/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('listasprecios/view', ['ADMIN', 'INSPECTOR'], ListasPreciosForm, { editable: false })
    },
];
export default routes;