import Paises from './paises.vue';
import PaisForm from './pais-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/paises/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises', ['ADMIN', 'INSPECTOR'], Paises),
        keepAlive: true
    },
    {
        path: '/paises/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/new', ['ADMIN', 'INSPECTOR'], PaisForm)
    },
    {
        path: '/paises/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/edit', ['ADMIN', 'INSPECTOR'], PaisForm)
    },
    {
        path: '/paises/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('paises/view', ['ADMIN', 'INSPECTOR'], PaisForm, { editable: false })
    },
];
export default routes;