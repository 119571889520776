<template>
  <f7-popup ref="popup" v-if="popupAbierto" :opened="true" class="popup-asignar-formato" @popup:closed="popupAbierto = false; " swipe-to-close>
    <f7-page>
      <f7-navbar>
        <f7-nav-title>
          Indique el código del formato
        </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>{{$t('common.cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form 
        ref="rform" 
        classContent="full-height"
        :itemId="inspeccion?inspeccion.id:null"
        :readonly="false"
        :initialData="inspeccion ? inspeccion : null"
        :defaultData="inspeccion ? inspeccion : null"
        :beforeSave="beforeSave"
        @save="onSave"
      >
        <template v-slot:default="{ rform }">
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <f7-col width="100" medium="100">
                      <!-- <r-input
                        floating-label
                        :label="'Código Formato'"
                        :form="rform"
                        type="text"
                        :name="'formato'"
                      /> -->
                      <r-autocomplete
                        :dbAdapter="Formato"
                        class="col-100"
                        floating-label
                        :label="'Código Formato'"
                        :form="rform"
                        name="formato"
                        :idProp="'codigo'"
                        prefixProp="codigo"
                        labelProp="nombre"
                        :shownFields="['codigo', 'nombre']"
                      ></r-autocomplete>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-row>
            <f7-col width="100" medium="100">
              <f7-button
                  fill
                  icon-f7="disk"
                  text="Asignar"
                  class="display-flex margin-vertical margin-left margin-right"
                  color="green"
                  @click="asignarFormato(rform.formData.formato, true)"
                ></f7-button>
            </f7-col>
          </f7-row>
        </template>
      </r-form>
    </f7-page>
  </f7-popup>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rForm from "../../../components/rForm.vue";
import rAutocomplete from "../../../components/rAutocomplete.vue";
import rInput from "../../../components/rInput.vue";
import Formato from "../../../js/db/Formato.js";
import Inspeccion from "../../../js/db/Inspeccion.js";
import Documento from "../../../js/db/Documento.js";
import Utils from "../../../js/Utils.js";

export default {
  name: "asignar-formato",
  components: {
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Formato,
      Inspeccion,
      Documento,
      Utils,
      popupAbierto: false
    };
  },
  props:{
    inspeccion:{
      type: Object
    },
    guardar: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    openPopup: function() {
      const self = this;
      self.popupAbierto = true;
    },
    beforeSave: function(data, resolve, reject) {
      resolve(data);
    },
    onSave: function(data) {
      var self = this;
      var app = self.$f7;
      // Vamos a asignar el formato a la inspección
      console.log("Guardando inspección", self.inspeccion);
      let formato = data.formato;
      self.asignarFormato(formato);
    },
    initFechaProxInspeccion(inspeccion, sobreescribir = false) {
      const self = this;
      if(inspeccion.formato && inspeccion.formato.periodicidadMeses && (sobreescribir || !inspeccion.proxInsp)) {
        var fechaBase = moment();
        if(inspeccion.fechaInsp){
          fechaBase = inspeccion.fechaInsp;
        }
        self.$set(inspeccion, "proxInsp", moment(fechaBase).add(inspeccion.formato.periodicidadMeses, 'M').format());
        // inspeccion.proxInsp = moment(fechaBase).add(inspeccion.formato.periodicidadMeses, 'M').format();
      }
    },
    _initCaracteristicas(element){
      const self = this;
      if(element.caracteristicas){
        return element.caracteristicas.forEach(self._initCaracteristicas);
      }
      var caracteristica = element;
      caracteristica.modificada = !!caracteristica.valor;
      if(caracteristica.hijas){
        caracteristica.hijas.forEach(self._initCaracteristicas);
      }
    },
    rellenarCaracteristicasDefecto(caracteristica){
      const self = this,
        form = self.$refs.rform,
        inspeccion = form.formData;
      if(!caracteristica || caracteristica.target){
        if(inspeccion.caracteristicas){
          inspeccion.caracteristicas.forEach(self.rellenarCaracteristicasDefecto);
        }
        return;
      }
      if(caracteristica.vinculado && form.getValue(caracteristica.vinculado)){
        var val = form.getValue(caracteristica.vinculado).toUpperCase();
        if(!caracteristica.valor && !caracteristica.modificada && !!val){
          self.$set(caracteristica,"valor", val);
        }
      }
      if(caracteristica.hijas){
        caracteristica.hijas.forEach(self.rellenarCaracteristicasDefecto);
      }
      // if((!caracteristica.hijas || !caracteristica.hijas.length)  && typeof  caracteristica.valor == "undefined") {
      //   self.$set(caracteristica,"valor", "");
      // }
    },
    getCaracteristicas (caracteristicas) {
      var self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap(c => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    getComprobaciones (comprobaciones) {
      var self = this;
      if (!comprobaciones || !comprobaciones.length) {
        return [];
      }
      var result = Object.assign([], comprobaciones);
      var hijas = comprobaciones.flatMap(c => {
        return self.getComprobaciones(c.hijas);
      });
      return result.concat(hijas);
    },
    asignarFormato(formato, asignadoManual = false) {
      const self = this;
      var dbAdapter = self.offline ? FormatoOffline : Formato;
      app.preloader.show();
      let codigoFormato = formato.codigo?formato.codigo:formato;
      dbAdapter.getList({ filter: { codigo: codigoFormato } }).then(function(res) {
        if (!res.list.length) {
          app.dialog.alert("No se puede cargar el formato").open();
        } else {
          var formato = res.list[0];
          let inspeccion = self.inspeccion;
          // Si la inspección trae comprobaciones y formato y el formato tiene como código el codigoFormato que nos llega por parámetro, no hacemos nada
          if (!inspeccion.comprobaciones || !inspeccion.formato || inspeccion.formato.codigo !== codigoFormato) {
            self.$set(self.inspeccion,"comprobaciones",formato.comprobaciones);
          }
          // revisaremos las comprobaciones para ver si alguna tiene uno de los códigos de defectosExpedienteAnteriorNegativo
          // si es así, le pondremos el campo conDefectoAnterior a true
          if (inspeccion.comprobaciones && inspeccion.defectosExpedienteAnteriorNegativo && inspeccion.defectosExpedienteAnteriorNegativo.length) {
            let comprobaciones = self.getComprobaciones(inspeccion.comprobaciones);
            comprobaciones.forEach(comprobacion => {
              // Si comprobacion.codigo está en defectosExpedienteAnteriorNegativo, ponemos conDefectoAnterior a true
              if (inspeccion.defectosExpedienteAnteriorNegativo.includes(comprobacion.codigo)) {
                self.$set(comprobacion, "conDefectoAnterior", true);
              }
            });
          }

          // Preservamos las características que nos llegan informadas del servidor ------
          let caracteristicasPrevias = self.getCaracteristicas(self.inspeccion.caracteristicas).filter(car => !!car.valor);
          // Reemplazamos las características iniciales por las propias del formato seleccionado
          self.$set(self.inspeccion,"caracteristicas",formato.caracteristicas);
          // Metemos los valores que podamos de las características previas en el nuevo conjunto de características del expediente.
          self.getCaracteristicas(self.inspeccion.caracteristicas).forEach(car => {
            let old = caracteristicasPrevias.find(c => c.nombre == car.nombre);
            car.valor = old?old.valor:car.valor;
          });
          // -----------------------------------------------------------------------------

          self.$set(self.inspeccion,"tabs",formato.tabs);
          if (!asignadoManual) {
            self.$set(
             self.inspeccion,
             "hc1",
             new Date()
            )
          }
          let formato_copy = JSON.parse(JSON.stringify(res.list[0]));
          self.$set(self.inspeccion, "formato", formato_copy);
          if (!asignadoManual) {
            self.initFechaProxInspeccion(self.inspeccion);
          }
          self._initCaracteristicas(self.inspeccion);
        }
        // si guardar es true, vamos a guardar la inspección
        // if (self.guardar) {
          
          Inspeccion.save({
            data: self.inspeccion,
            itemId: self.inspeccion.id
          }).then(function() {
            app.toast
              .create({
                text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
                position: "center",
                closeTimeout: 2000,
                icon: '<i class="f7-icons">checkmark_alt</i>'
              })
              .open();
            self.$emit("finished");
            app.preloader.hide();
          });
        // } else {
        //   app.toast
        //     .create({
        //       // text: self.$t("inspecciones.inspeccion_form.inspeccion_guardada"),
        //       text: self.$t("inspecciones.inspeccion_form.formato_asignado"),
        //       position: "center",
        //       closeTimeout: 2000,
        //       icon: '<i class="f7-icons">checkmark_alt</i>'
        //     })
        //     .open();
        //   self.$emit("finished");
        // }
      });
    }
  }
};
</script>