import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import CacheService from './Cache.js';
import Flash from '../FlashUtils';

const DatabaseModel = {
    server: process.env.VUE_APP_SERVER,
    server_movilidad: process.env.VUE_APP_SERVER_MOVILIDAD,
    prefix: 'movilidad/',
    updateMany: 'updatemany',
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    cache_expiration_time: 60000,
    request: Framework7.request,
    getList: function(params, forceReload) {
        var self = this;
        params = params || {}
        var paramsDefault = {
            currentPage: (params.page || 1) - 1,
            perPage: params.limit,
            sortBy: params.order,
            sortDesc: params.orderDir == 'DESC' ? true : false,
            complete: params.complete,
            projection: params.projection,
            operation: params.operation
        };
        var params_ser = Framework7.utils.serializeObject(paramsDefault);
        var paramsRequest = {
            url: self.server_movilidad + self.prefix + self.model + '/search/?' + params_ser,
            method: 'POST',
            data: params.filter || {}
        };
        return self.sendRequest(paramsRequest, !forceReload);
    },
    sendToSAP: function(params, forceReload) {
        var self = this;
        params = params || {}
        var paramsDefault = {
            currentPage: (params.page || 1) - 1,
            perPage: params.limit,
            sortBy: params.order,
            sortDesc: params.orderDir == 'DESC' ? true : false,
            complete: params.complete,
            projection: params.projection,
            operation: params.operation
        };
        var params_ser = Framework7.utils.serializeObject(paramsDefault);
        var paramsRequest = {
            url: self.server_movilidad + self.prefix + self.model + '/sendToSap/?' + params_ser,
            method: 'POST',
            data: params.filter || {}
        };
        return self.sendRequest(paramsRequest, !forceReload);
    },
    getCombo: function(params, shownFields = []){
        var self = this;
        var comboParams = Object.assign({}, params);
        if(shownFields.length){
            comboParams.projection = shownFields.join(",");
        }
        return self.getList(comboParams);
    },
    get: function(params, forceReload) {
        var self = this;
        var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/',
            method: 'GET',
            data: {}
        };
        return self.sendRequest(params, !forceReload);
    },
    getHistory: function(params, forceReload) {
        var self = this;
        var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
        params = params || {};
        if (!params.itemId) return;
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/history',
            method: 'GET',
            data: {}
        };
        return self.sendRequest(params, !forceReload);
    },
    save: function(params) {
        var self = this;
        if (params.itemId) {
            return self.put(params);
        } else {
            return self.post(params);
        }
    },
    put: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId,
            method: 'PUT',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    putAll: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemIds) reject({ error: 'itemIds not defined' });
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/' + self.updateMany,
            method: 'PUT',
            data: {
                ids: params.itemIds,
                dto: params.data,
                vaciarCampos: params.vaciarCampos
            }
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    post: function(params) {
        var self = this;
        params = params || {};
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/',
            method: 'POST',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    delete: function(params) {
        var self = this;
        params = params || {};
        if (!params.itemId) reject({ error: 'itemId not defined' });
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId,
            method: 'DELETE',
            data: params.data
        };
        return self.sendRequest(params, false).then(function(res) {
            CacheService.clearCache(self.model);
            return res;
        });
    },
    define: function(params, forceReload){
        var self = this;
        var forceReload = typeof forceReload == "undefined" || forceReload ? true : false;
        params = params || {};
        var params = {
            url: self.server_movilidad + self.prefix + self.model + '/define',
            method: 'GET',
            data: {}
        };
        return self.sendRequest(params, forceReload);
    },




    getDefineCombo2: function(params, forceReload){
        var self = this;
        return self.getDefinePrepared2(params, forceReload).then(res => {
            return res;
        });
    },
    getDefinePrepared2: function(params, forceReload){
        var self = this;
		return self.define(params, forceReload).then(res => {
            let valores = self._prepareDefinition2(res);
            // Ordenamos los valores por el nombre y lo devolvemos
            return valores.sort((a, b) => a.name.localeCompare(b.name));
        });
    },
    // procesamos cada property (árbol que ramifica por el campo children) para montar una lista de objetos con los campos name, value, tipo y children (este solo si el tipo es list)
	_prepareDefinition2(hijo, padre){
		var self = this;
        var preparedProp = [];
        // si tenemos parent, lo concatenamos nombre y valor del padre al del hijo
        // - padre: {name: "nombre del padre", content: "valor del padre", tipo: "tipo del padre", children: [hijos del padre]}
        // - hijo: {name: "nombre del hijo", property: "valor del hijo", tipoCampo: "tipo del hijo", children: [hijos del hijo]}
        var nuevoDescriptor = {};
        var descriptorLista = false;
        if(padre){
            nuevoDescriptor = {
                content: (padre.content?padre.content+'.':''  )+hijo.property, 
                name:  (padre.name ?padre.name +' - ':'')+hijo.name, 
                tipo:hijo.tipoCampo,
                children: []
            };
        } else {
            nuevoDescriptor = { content: '$root', name: '', children: [] };
        }
        
        if(hijo.children && hijo.children.length){
            if(hijo.tipoCampo == "list") {
                descriptorLista = {...nuevoDescriptor}
                descriptorLista.content = "$actual";
            }
            hijo.children.forEach(nh => {
                nuevoDescriptor.children.push(...self._prepareDefinition2(nh, hijo.tipoCampo == "list"?descriptorLista:nuevoDescriptor));
            });
            if(hijo.tipoCampo !== "list") {
                preparedProp.push(...nuevoDescriptor.children);
            }
        } 
        // metemos en la lista de objetos a devolver, las descripciones de los nodos hoja y los de los nodos lista
        if(hijo.tipoCampo == "list" || !hijo.children || hijo.children.length == 0) {
            preparedProp.push(nuevoDescriptor);
        } 

        return preparedProp;
	},


    getDefineCombo: function(params, forceReload){
        var self = this;
        return self.getDefinePrepared(params, forceReload).then(valores => {
            return self._tratarObjeto(valores).map(valor => {
                return {name:valor.value, content:valor.name, tipo: valor.tipo};
            });
        });
    },
    getDefinePrepared: function(params, forceReload){
        var self = this;
		return self.define(params, forceReload).then(res => {
            return self._prepareDefinition(res);
        });
    },
	_prepareDefinition(property, parent){
		var self = this;
		var preparedProp = {};
		var propertyData = {};
		if(parent){
			propertyData = Object.assign({},property);
			propertyData.property = (parent.property) ? `${parent.property}.${propertyData.property}` : propertyData.property;
			propertyData.name = (parent.name) ? `${parent.name} - ${propertyData.name}` : propertyData.name;
		}
		if(property.children && property.children.length && property.tipoCampo == "list"){
            const proper = propertyData.tipoCampo ? `${propertyData.property}:${propertyData.tipoCampo}` : propertyData.property;
			preparedProp[proper] = propertyData.name;
			property.children.forEach(child => {
				preparedProp[child.property] = self._prepareDefinition(child, propertyData);
			});
		} else if(property.children && property.children.length){
			property.children.forEach(child => {
				preparedProp[child.property] = self._prepareDefinition(child, propertyData);
			});
		} else {
            const proper = propertyData.tipoCampo ? `${propertyData.property}:${propertyData.tipoCampo}` : propertyData.property;
			preparedProp[proper] = propertyData.name;
		}
		return preparedProp;
	},
    _tratarObjeto: function(objeto, pwd){
        var self = this;
        var valores = [];
        for(const i in objeto){
            const prop = objeto[i];
            if(typeof prop == "object"){
                const nuevos = self._tratarObjeto(prop);
                valores = valores.concat(self._tratarObjeto(prop));
            } else {
                let position = pwd ? `${pwd}.${i}` : i;
                let tipo = undefined;
                if (position.indexOf(":") > -1) {
                    [position, tipo] = position.split(":");
                }
                if(tipo == "list") {
                }
                const nuevo = {name:position, value:prop, tipo:tipo};
                valores.push(nuevo);
            }
        }
        return valores;
    },
    download: function(url, name) {
        var self = this;
        params = params || {};
        var params = {
            url: url,
            method: 'GET',
            xhrFields: {'responseType': 'arraybuffer'}
        };
        return new Promise((resolve, reject) => {
            self.request({
                url: url,
                method: 'GET',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    getBase64: function(url) {
        var self = this;
        params = params || {};
        var params = {
            url: url,
            method: 'GET',
            xhrFields: {'responseType': 'arraybuffer'}
        };
        return new Promise((resolve, reject) => {
            self.request({
                url: url,
                method: 'GET',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                success: function(arrayBuffer) {
                    let array = new Uint8Array(arrayBuffer);
                    let base64 = window.btoa(
                        array.reduce((data, byte) => data + String.fromCharCode(byte), '')
                      );
            
                    // let b642 = arrayBuffer.toString('base64');
                    resolve({base64, array});
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    exportExcel: function(params) {
        var self = this;
        params = params || {};
        var name = params.name || model + 's.xlsx';
        return new Promise((resolve, reject) => {
            self.request({
                url: self.server_movilidad + self.prefix + self.model + '/excel',
                method: 'POST',
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                data: params.filter || {},
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    exportDynamicExcel: function(params) {
        var self = this;
        params = params || {};
        var name = params.name || model + 's.xlsx';
        var pagination = {
            sortBy: params.order,
            sortDesc: params.orderDir == 'DESC' ? true : false,
            projection: params.projection,
            operation: params.operation
        };
        var data = {
            filter: params.filter || {},
            columns: params.columns || {},
            pagination: pagination
        }
        return new Promise((resolve, reject) => {
            self.request({
                url: self.server_movilidad + self.prefix + self.model + '/dynamicexcel',
                method: 'POST',
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { 'responseType': 'arraybuffer' },
                data: data,
                success: function(data) {
                    var blob = new Blob([data]);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    } else {
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name;
                        link.target = '_blank';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                    resolve();
                },
                error: function(error) {
                    reject(error);
                }
            });
        });
    },
    sendRequest: function(params, cached, callback, errorCallback, completeCallback){
        var self = this;
        var callback = callback;
        var errorCallback = errorCallback;
        var params = Object.assign({
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            expiresIn: self.cache_expiration_time
        }, params);
        var cached = cached != undefined ? cached : true;
        var completeCallback = completeCallback || function() {};
        if (cached) {
            var cachedRequest = CacheService.getCachedRequest(self.model, params);
            if (cachedRequest) {
                return cachedRequest;
            }
        }
        var promise = new Promise(function(resolve, reject) {
            var callback = callback || function(res) {
                if (res.data.error) {
                    reject(res.data);
                } else if (res.data.dto){
                    resolve(res.data.dto);
                } else {
                    resolve(res.data);
                }
            };
            var errorCallback = errorCallback || function(e) {
                var app = window.app;
                if (typeof e == 'string') e = { error: e };
                if (typeof e == 'undefined') e = { error: 'Error desconocido' };
                if(e.status == 404){
                    if(e.xhr && e.xhr.requestUrl) {console.log('URL: '+e.xhr.requestUrl);}
                    reject({status: e.status, xhr: e.xhr, message: e.message});
                    return;
                }
                if (e.xhr) {
                    var { xhr, status, message } = e;
                    try {
                        let errorCode = JSON.parse(xhr.response).error;
                        if (status == 401 || errorCode == "invalid_token") {
                            app.methods.removeSession();
                            app.preloader.hide();
                            var currentURL = app.views.main.router.currentRoute.path;
                            if (currentURL != "/login/") {
                                Flash.set("redirectTo", currentURL);
                                app.views.main.router.navigate("/login/");
                            }
                        } else {
                            errorCode = {
                                status: e.status,
                                xhr: e.xhr,
                                message: errorCode.message || errorCode
                            }
                            reject(errorCode);
                        }
                    } catch (e) {
                        reject({status: e.status, xhr: e.xhr, message: e.message});
                    }
                } else {
                    reject(e);
                }
            }
            self.request.promise(params).then(callback).catch(errorCallback).then(completeCallback);
        });
        CacheService.addCachedRequest(self.model, params, promise);
        return promise;
    },
    resetCache: function(){
        var self = this;
        CacheService.clearCache(self.model);
    }
}

export default DatabaseModel;