import PersonalCualificado from './personalcualificado.vue';
import PersonalCualificadoForm from './personalcualificado-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/personalcualificado/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('personalcualificado', [], PersonalCualificado),
        keepAlive: true
    },
    {
        path: '/personalcualificado/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('personalcualificado/new', [], PersonalCualificadoForm)
    },
    {
        path: '/personalcualificado/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('personalcualificado/edit', [], PersonalCualificadoForm)
    },
    {
        path: '/personalcualificado/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('personalcualificado/view', [], PersonalCualificadoForm, { editable: false })
    },
];
export default routes;