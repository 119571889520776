<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Facturas" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Factura"
        :tableCode="'FACTURA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="false"
        :controlsWidth="50"
        :shownFields="['lineasFactura']"
      >
        <template v-slot:optionsColumn="{item}">
          <f7-link v-if="Utils.getUser() && Utils.getUser().username == 'SIMECAL'" v-tooltip="$t('common.ver')" icon-f7="eye" color="green" :href="`/facturas/view/${item.id}/`"></f7-link>
          <!-- <f7-link v-if="item.docStatus && item.docStatus == 'O'" v-tooltip="$t('common.editar')" icon-f7="square_pencil" color="blue" :href="`/facturas/edit/${item.id}/`"></f7-link> -->
        </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Factura from "./../../js/db/Factura.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Factura,
      Utils
    };
  },
  computed: {},
  methods: {
    
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>