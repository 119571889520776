import { render, staticRenderFns } from "./oferta-public.vue?vue&type=template&id=160f76ce&scoped=true"
import script from "./oferta-public.vue?vue&type=script&lang=js"
export * from "./oferta-public.vue?vue&type=script&lang=js"
import style0 from "./oferta-public.vue?vue&type=style&index=0&id=160f76ce&prod&lang=css"
import style1 from "./oferta-public.vue?vue&type=style&index=1&id=160f76ce&prod&scoped=true&lang=css"
import style2 from "./oferta-public.vue?vue&type=style&index=2&id=160f76ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160f76ce",
  null
  
)

export default component.exports