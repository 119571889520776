import TiposItem from './tipositem.vue';
import TiposItemForm from './tipositem-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/tipositem/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipositem', ['ADMIN', 'INSPECTOR'], TiposItem, { esEquipo: false }),
        //keepAlive: true
    },
    {
        path: '/tipositem/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipositem/new', ['ADMIN', 'INSPECTOR'], TiposItemForm, { esEquipo: false })
    },
    {
        path: '/tipositem/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipositem/edit', ['ADMIN', 'INSPECTOR'], TiposItemForm, { esEquipo: false })
    },
    {
        path: '/tipositem/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('tipositem/view', ['ADMIN', 'INSPECTOR'], TiposItemForm, { editable: false, esEquipo: false })
    },
];
export default routes;