import DatabaseModel from './DatabaseModel.js';

var LineaPedido = Object.create(DatabaseModel);

LineaPedido.model = 'pedido/lineas';

LineaPedido.enviarEmail = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/enviarEmail/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};
LineaPedido.probarPlantilla = function(data) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/probarPlantillaCorreo/',
        method: 'POST',
        data: data
    };
    return self.sendRequest(params, false).then(function(res) {
        return res;
    });
};

export default LineaPedido;