<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="path && !readonly" sliding>{{$t('documentos.editar')}}</f7-nav-title>
      <f7-nav-title v-else-if="path" sliding>{{$t('documentos.documento')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('documentos.nuevo')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="guardarDocumento"
        ></f7-button>
        <template slot="right" v-else>
          <f7-button color="blue" @click="readonly=false">{{$t('common.editar')}}</f7-button>
          <f7-button color="red" @click="deleteItem(documentoInicial.archivo[0])">{{$t('common.eliminar')}}</f7-button>
        </template>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="path"
      :beforeSave="beforeSave"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :defaultData="{isTemplate:false, camposPDF: []}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul class="row">
                <r-autocomplete
                  ref="comboTiposDocumento"
                  class="col-25"
                  floating-label
                  :label="$t('documentos.tipoDocumento')"
                  :form="rform"
                  :dbItems="tiposDocumento"
                  :readonly="isPDFTemplate"
                  name="tipoDocumento"
                  labelProp="valor"
                  idProp="nombre"
                  @change="isHTMLTemplate = !isHTMLTemplate; isHTMLTemplate = !isHTMLTemplate;"
                ></r-autocomplete>
                <r-input
                  floating-label
                  class="col-50"
                  :label="$t('documentos.path')"
                  :form="rform"
                  :readonly="isPDFTemplate"
                  type="text"
                  name="path"
                ></r-input>
                <r-input
                  floating-label
                  class="col-25"
                  :label="$t('documentos.nombre')"
                  :form="rform"
                  :readonly="isPDFTemplate"
                  @change="actualizarNombre"
                  type="text"
                  name="name"
                ></r-input>
                <br/>
              </ul>
              <ul class="row">
                <r-input
                  v-if="!readonly"
                  floating-label
                  class="col-25"
                  :label="$t('documentos.plantilla')"
                  :form="rform"
                  type="check"
                  @change="refreshIsTemplate"
                  name="isTemplate"
                ></r-input>
                <r-files v-if="isTemplate && isHTMLTemplate"
                  class="col-25 margin-top-half"
                  :form="rform"
                  name="archivoTemplate"
                  @loadFile="importarPlantilla"
                  :maxFiles="1"
                  :label="$t('documentos.importarPlantilla')"
                  :mostrarComentario="false"
                ></r-files>
                <f7-button v-if="isTemplate && isPDFTemplate"
                  class="col-25 margin-top-half display-flex"
                  @click="descargarReferencias"
                >{{$t('documentos.descargarPDFReferencias')}}</f7-button>
                <r-files v-if="!isTemplate"
                  class="col-25"
                  :form="rform"
                  name="archivo"
                  :documentoAdapter="Documento"
                  :maxFiles="1"
                  :mostrarComentario="false"
                ></r-files>
                <div class="col-50">
                  <div v-if="isTemplate">
                    <r-input
                      floating-label
                      class="col-50"
                      :label="placeholderPrueba"
                      :form="rform"
                      name="expedientePrueba"
                    ></r-input>
                    <f7-button
                      class="col-50"
                      @click="probarPlantilla(rform.formData.expedientePrueba, rform.formData.archivo[0])"
                    >{{$t('documentos.probarPlantilla')}}</f7-button>
                  </div>
                </div>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <r-medium-editor ref="mediumEditor" class="margin" v-if="isHTMLTemplate" :tipo="tipoDocumento.nombre||tipoDocumento" :data="rform.formData"/>
      <f7-card v-if="isPDFTemplate">
        <f7-row style="padding:1em">
          <div class="campos col-100">
            <div v-for="(item,index) in $refs.rform.formData.camposPDF" :key="'campos'+index"
                :style="'position: relative; z-index:'+($refs.rform.formData.camposPDF.length - index + 100)">
              <f7-row class="list list-form no-hairlines">
                <f7-col  width="25">
                  <r-input
                    floating-label
                    :label="$t('documentos.camposPdf.nombre')"
                    :form="rform"
                    type="text"
                    :readonly="true"
                    :name="'camposPDF.' + index + '.nombre'"
                  ></r-input>
                </f7-col>
                <f7-col  width="25">
                  <r-input
                    floating-label
                    :label="$t('documentos.camposPdf.tipo')"
                    :form="rform"
                    type="select"
                    :name="'camposPDF.' + index + '.tipo'"
                    >
                    <option value=""></option>
                    <option value="STRING">Texto</option>
                    <option value="CARACTERISTICA">Caracteristica</option>
                    <option value="VALOR_INSPECCION">Valor de inspección</option>
                    <option value="EXPRESION" style="color:blue;font-style: oblique;" tooltip="Experimental">Expresión</option>
                  </r-input>
                </f7-col>
                <f7-col
                  v-if="esValorInspeccion($refs.rform.formData.camposPDF[index])"
                  width="50"
                >
                  <r-autocomplete
                    :dbAdapter="null"
                    :label="$t('documentos.camposPdf.valor')"
                    :form="rform"
                    :name="'camposPDF.' + index + '.valor'"
                    labelProp="name"
                    idProp="content"
                    :dbItems="valoresInspeccion"
                    :viewTooltip="false"
                  ></r-autocomplete>
                </f7-col>
                <f7-col v-else width="50" :class="$refs.rform.formData.camposPDF[index].tipo == 'CARACTERISTICA'?'display-flex':''">
                  <r-input
                    floating-label
                    :label="$t('documentos.camposPdf.valor')"
                    :form="rform"
                    type="text"
                    :name="'camposPDF.' + index + '.valor'"
                  ></r-input>
                  <r-input
                    v-if="$refs.rform.formData.camposPDF[index].tipo == 'CARACTERISTICA'"
                    floating-label
                    :label="$t('documentos.camposPdf.valoresEspeciales')"
                    :form="rform"
                    type="textarea"
                    :name="'camposPDF.' + index + '.valoresEspecialesJSON'"
                    @change="rform.formData.camposPDF[index].valoresEspeciales = JSON.parse(rform.formData.camposPDF[index].valoresEspecialesJSON)"
                  ></r-input>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </f7-row>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  .label-group-inspeccion {
    margin-top: 30px;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
</style>
<style>
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rMediumEditor from "./../../components/medium-editor/rMediumEditor"
import rTimepicker from "./../../components/rTimepicker.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Combos from "./../../js/db/Combos.js";
import Documento from "./../../js/db/Documento.js";
import Inspeccion from "./../../js/db/Inspeccion.js";
import Oportunidad from "./../../js/db/Oportunidad.js";
import Tarea from "./../../js/db/Tarea.js";
import Oferta from "./../../js/db/Oferta.js";
import LineaPedido from "./../../js/db/LineaPedido.js";
import Usuario from "./../../js/db/Usuario.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete,
    rMediumEditor,
    Container,
    Draggable
  },
  data: function() {
    var self = this;
    return {
      Combos,
      Documento,
      readonly: !this.editable,
      documentoInicial: null,
      tiposDocumento: [],
      isTemplate: false,
      isHTMLTemplate: false,
      isPDFTemplate: false,
      valoresInspeccion: [],
      mailUsuario: ""
    };
  },
  props: {
    path: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    tipoDocumento: function(){
      const self = this;
      return self.$refs.rform.formData.tipoDocumento;
    },
    placeholderPrueba: function(){
      const self = this;
      var form = self.$refs.rform.formData;
      var name = form.name;
      switch(self.tipoDocumento){
        case 'templates/EXPEDIENTES/':
          return self.$t('documentos.expedientePrueba');
        case 'templates/OFERTAS/':
          return self.$t('documentos.ofertaPrueba');
        case 'templates/mail/':
          // Si name termina por _linea, se trata de una plantilla de correo de línea de pedido
          if(name.endsWith("_linea")){
            return self.$t('documentos.lineaPrueba');
          } else 
          // si name termina por _oferta, se trata de una plantilla de correo de oferta
          if(name.endsWith("_oferta")){
            return self.$t('documentos.pruebaOfertaCorreo');
          } else 
          // si name termina por _oportunidad, se trata de una plantilla de correo de oportunidad
          if(name.endsWith("_oportunidad")){
            return self.$t('documentos.pruebaOportunidadCorreo');
          }
          // si el name termina por _tarea, se trata de una plantilla de correo de tarea
          if(name.endsWith("_tarea")){
            return self.$t('documentos.pruebaTareaCorreo');
          }
      }
    }
  },
  beforeDestroy() {},
  methods: {
    esValorInspeccion: function(campoPdf){
      return campoPdf && campoPdf.tipo && campoPdf.tipo == "VALOR_INSPECCION";
    },
    create: function(documentoAnterior, force) {
      var self = this;
      var form = self.$refs.rform.formData;
      var path = form.path;
      var name = form.name;
      var archivo = form.archivo[0];
      return Documento.uploadTo(archivo, path, name, null, !!force)
        .then(nuevos => {
          if(documentoAnterior){
            Documento.delete(documentoAnterior)
              .then(res => {
                self.guardadoCorrecto(nuevos.list);
              });
          } else {
            self.guardadoCorrecto(nuevos.list);
          }
          return nuevos.list;
        })
        .catch(err => {
          if(err.code == "FILE_EXISTS"){
            self.$f7.dialog.confirm(
              self.$t("documentos.fichero_existe_confirmacion"),
              self.$t("common.sobreescribir"),
              function() {
                self.create(documentoAnterior, true);
              }
            );
          } else {
            console.error(err);
          }
        });
    },
    move: function(force){
      var self = this;
      var form = self.$refs.rform.formData;
      var path = form.path;
      var name = form.name;
      var archivo = form.archivo[0];
      return Documento.moveTo(archivo, path, name, force)
        .then(res => {
          self.guardadoCorrecto(res.dto);
          return res;
        })
        .catch(err => {
          if(err.code == "FILE_EXISTS"){
            self.$f7.dialog.confirm(
              self.$t("documentos.fichero_existe_confirmacion"),
              self.$t("common.sobreescribir"),
              function() {
                self.move(true);
              }
            );
          } else {
            console.error(err);
          }
        });
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      if(!data.isTemplate){
        if(!data.archivo.length){
          app.dialog.alert(self.$t(''));
          reject();
        }
        resolve(data);
      } else if(self.isHTMLTemplate) {
        // Guardar plantilla
        data.contenidoPlantilla = self.$refs.mediumEditor.getContent();
        if(self.tipoDocumento == "templates/mail/"){
          data.contenidoPlantilla = data.contenidoPlantilla.replace(/<style(.|\n)*<\/style>/mi, "");
        }
        resolve(data);
      } else if(self.isPDFTemplate) {
        resolve(data);
      }
    },
    tratarDocumento: function(documento) {
      var self = this;
      var pathArray = documento.path.split("/");
      pathArray.splice(- 1, 1);
      var path = pathArray.join("/") + "/".replace("//", "/");
      // Buscamos el tipo de documento con nombre igual al start del path
      var tipoDocumento = self.tiposDocumento.find(tipo => path.startsWith(tipo.nombre));
      // eliminamos el tipo de documento del inicio del path
      if((tipoDocumento||tipoDocumento.nombre) && path.startsWith(tipoDocumento.nombre||tipoDocumento)){
        path = path.replace(((tipoDocumento && tipoDocumento.nombre)?tipoDocumento.nombre:tipoDocumento), '[PLANTILLAS]',1);
      }
      return {
        tipoDocumento: tipoDocumento,
        archivo: [Object.assign({}, documento)],
        path: path,
        name: documento.nombre
      }
    },
    actualizarNombre: function(val){
      var self = this;
      if(self.$refs.mediumEditor){
        self.$refs.mediumEditor.setName(self.$refs.rform.formData.name);
      }
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      var nuevoDocumento = self.$refs.rform.formData;
      var documentoInicial = self.documentoInicial;
      var savePromise = null;

      if(self.isHTMLTemplate){
        var content = res.contenidoPlantilla;
        // Eliminar <o:p> y su contenido
        content = content.replace(/<\w+:\w+\s*[^>]*>/gi, '');
        content = content.replace(/<\/\w+:\w+>/gi, '');
        var blob = new Blob([content], { type: "application/xml"});
        blob.extension = 'xhtml';
        var archivo = [blob];
        nuevoDocumento.archivo = archivo;
        savePromise = self.guardarFichero(nuevoDocumento, documentoInicial);
      } else if (self.isPDFTemplate){
        if(!nuevoDocumento.archivo || !nuevoDocumento.archivo.length){
          // TODO error
          return;
        }
        var camposPDF = self.$refs.rform.formData.camposPDF;
        savePromise = Documento.guardarCamposPDF(nuevoDocumento.archivo[0], camposPDF);
      } else {
        savePromise = self.guardarFichero(nuevoDocumento, documentoInicial);
      }
      
      if(savePromise){
        savePromise.then(res => {
          self.isTemplate = false;
          self.isHTMLTemplate = false;
          self.isPDFTemplate = false;
          self.$set(self.$refs.rform.formData, "isTemplate", false);
        });
      }
      return savePromise;
    },
    guardarFichero (nuevoDocumento, documentoInicial, forzar){
      var self = this;
      var archivo = nuevoDocumento.archivo[0];
      var rutaModificada = (!documentoInicial || (nuevoDocumento.path ? nuevoDocumento.path : '') != documentoInicial.path || nuevoDocumento.name != documentoInicial.name);
      var documentoExistia = documentoInicial && !!documentoInicial.archivo.length;
      var ficheroModificado = !!archivo._base64 || nuevoDocumento.isTemplate;

      if(!ficheroModificado && !rutaModificada) {
        // Nada modificado, no se hace nada
        return;
      }
      if(!documentoExistia){
        if(ficheroModificado && rutaModificada) {
          // Crear fichero
          nuevoDocumento.path = (nuevoDocumento.tipoDocumento && nuevoDocumento.tipoDocumento.nombre?nuevoDocumento.tipoDocumento.nombre:nuevoDocumento.tipoDocumento) + (nuevoDocumento.path ? nuevoDocumento.path.replaceAll('[PLANTILLAS]','') : '');
          return self.create();
        } else {
          // ERROR: Faltan datos
          return;
        }
      }
      nuevoDocumento.path = (nuevoDocumento.tipoDocumento && nuevoDocumento.tipoDocumento.nombre?nuevoDocumento.tipoDocumento.nombre:nuevoDocumento.tipoDocumento) + (nuevoDocumento.path ? nuevoDocumento.path.replaceAll('[PLANTILLAS]','') : '');
      if(ficheroModificado && rutaModificada) {
        // Actualizar fichero y eliminar anterior
        return self.create(documentoInicial.archivo[0]);
      } else if(ficheroModificado) {
        // Actualizar fichero
        return self.create();
      } else {
        // Mover fichero
        return self.move();
      }
    },
    guardadoCorrecto (nuevoDocumento){
      var self = this;
      if(nuevoDocumento.length){
        return self.guardadoCorrecto(nuevoDocumento[0]);
      }
      self.inicializarDocumento(nuevoDocumento);

      app.toast
        .create({
          text: self.$t("documentos.documento_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();    
    },
    deleteItem: function(documento) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        self.$t("common.eliminar"),
        function() {
          Documento.delete(documento).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t("rlist.registro_eliminado"),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
              self.$f7router.back()
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          });
        }
      );
    },
    inicializarDocumento(documento) {
      var self = this;
      self.documentoInicial = self.tratarDocumento(documento);
      self.$refs.rform.formData = self.tratarDocumento(documento);
      self.tiposDocumento.forEach(tipo => {
        if(self.$refs.rform.formData.path && self.$refs.rform.formData.path.includes(tipo.nombre)){
          self.$refs.rform.formData.tipoDocumento = tipo.nombre;
          // Si el path empieza por el nombre del tipo de documento, se reemplaza por [PLANTILLAS] para tenerlo en cuenta al guardar
          if(self.$refs.rform.formData.path.startsWith(tipo.nombre)){
            self.$refs.rform.formData.path = self.$refs.rform.formData.path.replace(tipo.nombre, '[PLANTILLAS]',1);
          }
        }
      })
    },
    refreshIsTemplate(e) {
      if(e && e.target){ // Se ignora el evento por defecto del navegador, y se escucha sólo al evento que se manda a través de rinput
        return;
      }
      var self = this;
      var form = self.$refs.rform.formData;
      self.isTemplate = form.isTemplate;
      if(!self.isTemplate){
        self.$set(form, "isHTMLTemplate", false);
        self.isHTMLTemplate = false;
        self.$set(form, "isPDFTemplate", false);
        self.isPDFTemplate = false;
        return;
      }

      if(form.archivo && form.archivo.length) {
        let camposPDF = (form.archivo[0].camposPDF) ? form.archivo[0].camposPDF : {};
        self.loadContentFromDocumento();
        return;
      }

      self.isHTMLTemplate = true;
    },
    loadContentFromDocumento: function() {
      var self = this,
        form = self.$refs.rform.formData;
      if(!form.archivo || !form.archivo.length) {
        return;
      }
      console.log("documento");
      if(form.archivo.length && form.archivo[0] && form.archivo[0].extension == "pdf"){
        var documentoActual = form;
        Documento.updatePDF(documentoActual, self.documentoInicial).then(res => {
          /*
           * UpdatePDF debería:
           *  1) Comprobar si existe documento inicial:
           *    a) Si existe:
           *      · Si tiene diferente path:
           *        - Obtener los datos guardados de ese path
           *        - Eliminar los datos de ese path
           *        - Guardar los nuevos datos junto con los datos guardados del path del documento inicial
           *      · Si tiene el mismo path:
           *        - Obtener los datos guardados de ese path
           *  2) Tratar documento para obtener campos (obtener campos del documento, ordenados alfabéticamente)
           *  3) Si se han obtenidos datos guardados:
           *    - Informar los campos obtenidos con los datos guardados
           *  4) Devolver campos
           */
          let camposPDF = (res.camposPDF) ? res.camposPDF : {};
          let arrayCamposPDF = Object.values(camposPDF);
          if(arrayCamposPDF.length){
            // Si form tiene el campo camposPDF, vamos a asignar a todos los camposPDF el valor de valoresEspecialesJSON si tiene valoresEspeciales
            arrayCamposPDF.forEach(campo => {
              if(campo.valoresEspeciales){
                self.$set(campo, "valoresEspecialesJSON", JSON.stringify(campo.valoresEspeciales));
              }
            });
          }
          if(res.nombre){
            self.$set(form, "camposPDF", Object.values(camposPDF));
            self.isPDFTemplate = true;
          }
        });
      } else {
        self.isHTMLTemplate = true;
        Documento.getDocumento(form.archivo[0]).then(data => {
          const dec = new TextDecoder("utf-8");
          self.loadContent(dec.decode(data));
        });
      }
    },
    descargarReferencias(){
      var self = this,
        form = self.$refs.rform.formData;
      if(!form.archivo || !form.archivo.length){
        // TODO Error
        return;
      }
      Documento.downloadPDFReferenciasByPath(form.archivo[0]);
    },
    importarPlantilla(){
      const self = this;
      var datosPlantilla = Documento.getDocumentoData(self.$refs.rform.formData.archivoTemplate[0]._base64);
      datosPlantilla = decodeURIComponent(escape(datosPlantilla)); // Corrección de charset
      self.$refs.rform.formData.archivoTemplate = [];
      self.loadContent(datosPlantilla);
    },
    probarPlantilla(identificador, documento){
      const self = this;
      var app = self.$f7;
      if(!identificador){
        // Error: no hay expediente
          console.error("No se ha introducido un expediente para probar la plantilla.");
          return;
      }
      identificador = identificador.trim();
      let prueba = null;
      let nombreTipoDocumento = (self.tipoDocumento && self.tipoDocumento.nombre)?self.tipoDocumento.nombre:self.tipoDocumento;
      if(nombreTipoDocumento == "templates/EXPEDIENTES/") {
        prueba = Inspeccion.probarInforme(identificador,documento);
      } else if(nombreTipoDocumento == "templates/OFERTAS/") {
        console.log("Probar oferta");
        let data = {
          codigo: identificador,
          rutaPlantilla: documento.path
        };
        prueba = Oferta.probarPlantilla(data);
      } else if(nombreTipoDocumento == "templates/mail/") {
        let pedidoArr = identificador.split("-"),
          pedido = pedidoArr[0],
          linea = pedidoArr.length > 1 ? pedidoArr[1] : 1;
        let mailData= [{
          idPedido: pedido,
          numeroLinea: linea,
          fromName: "Prueba",
          from: self.mailUsuario,
          to: [self.mailUsuario],
          cc: [self.mailUsuario],
          cco: [self.mailUsuario, 'pruebas@correo.com'],
          asunto: "Prueba de plantilla de correo",
          plantillaEmail: documento.path
        }];
        // Si la plantilla termina en _oferta, se trata de una plantilla de oferta así que llamaremos a las pruebas de correo de la oferta
        if(documento.nombre.endsWith("_oferta")){
          mailData[0].codigo = identificador;
          mailData[0].idOferta = undefined;
          prueba = Oferta.probarPlantillaCorreo(mailData);
        } else if(documento.nombre.endsWith("_oportunidad")){
          mailData[0].codigo = identificador;
          mailData[0].idOportunidad = undefined;
          prueba = Oportunidad.probarPlantillaCorreo(mailData);
        } else if(documento.nombre.endsWith("_tarea")){
          mailData[0].codigo = identificador;
          mailData[0].idTarea = undefined;
          prueba = Tarea.probarPlantillaCorreo(mailData);
        } else {
          prueba = LineaPedido.probarPlantilla(mailData);
        }
      }
      if(prueba){
        app.preloader.show();
        prueba.then(res=> {
          if(self.tipoDocumento == "templates/mail/"){
            app.toast
              .create({
                icon: '<i class="f7-icons">checkmark</i>',
                text: `${self.$t("documentos.correoPruebaEnviado")} ${self.mailUsuario}`,
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
          }
          return res;
        }).catch(error => {
          var encoder = new TextDecoder("utf-8");
          error = JSON.parse(encoder.decode(error.response));
          if(error.error.code == "MODEL_NOT_FOUND"){
            app.toast
              .create({
                icon: '<i class="f7-icons">exclamationmark_circle_fill</i>',
                text: `No se ha encontrado el expediente con código ${error.error.message}`,
                position: "center",
                destroyOnClose: true,
                closeTimeout: 2000
              })
              .open();
          }
          console.error(error);
          return error;
        }).finally(_=>{
          app.preloader.hide();
        });
      }
    },
    loadContent: function(content){
      var self = this;
      self.contenidoPlantilla = content;
      self.$refs.mediumEditor.loadContent(content);
      self.$refs.mediumEditor.setName(self.$refs.rform.formData.name);
    },
    loadItem(res) {
      var self = this;
      this.refreshIsTemplate();
    },
    guardarDocumento: function(){
      var self = this;
      return self.$refs.rform.saveItem();
    }
  },
  created: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;

    if(self.path){
      Documento.getDocumentoByPath(self.path)
        .then(res => {
          self.inicializarDocumento(res);
        })
        .catch(err => {
          console.error(err);
        });
    }
    Combos.getComboById("tiposPlantilla").then(res => {
      self.tiposDocumento = res.list;
      self.$refs.comboTiposDocumento.reload(self.tiposDocumento);
      if(self.documentoInicial && self.documentoInicial.archivo.length){
        self.inicializarDocumento(self.documentoInicial.archivo[0]);
      }
    });
    Inspeccion.getDefineCombo({name: "Inspección", property: "inspeccion"}).then(opciones=>{
      var self = this;
      self.valoresInspeccion = opciones;
    });
    Usuario.me().then(res => {
      const self = this;
      self.mailUsuario = res.email;
    })
  }
};
</script>