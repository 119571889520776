import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var Formato = Object.create(DatabaseModel);

Formato.model = 'formato';
Formato.modelFront = 'formatos';

Formato.save= function(params) {
    var self = this;
    // Si hay documento es que tenemos que ir al importarComprobaciones
    if (params.data && params.data.documento) {
        return self.importarComprobaciones(params.data.documento);
    } else if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
};

Formato.auditoria = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/auditoria',
        method: 'PUT',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};
Formato.predecir = function(id, datos) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + id + '/predecir',
        method: 'POST',
        data: datos
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
};

Formato.clonar = function(params){
    var self = this;
    params = params || {};
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/' + params.newCode + '/duplica',
        method: 'GET',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

Formato.exportarComprobaciones = function(formato){
    var self = this;
    return self.download(
        self.server_movilidad + self.prefix + self.model + '/' + formato.id + '/exportacomprobaciones', 
        formato.codigo + '.xlsx');
}
Formato.regenerarCondicionesGPT = function(idFormato){
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + idFormato + '/regenerarCondicionesGPT', 
        method: 'PUT'
    };
    return self.sendRequest(params, false);
}

Formato.importarComprobaciones = function(documento) {
    var self = this;
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/importacomprobaciones',
        method: 'POST',
        data: documento
    };
    return self.sendRequest(params, false);
}

Formato.getVersion = function(params, forceReload) {
    var self = this;
    params = params || {};
    if (!params.itemId) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_movilidad + self.prefix + self.model + '/' + params.itemId + '/version',
        method: 'GET',
        data: {}
    };
    return self.sendRequest(params, !forceReload);
};

export default Formato;