import Empleados from './empleados.vue';
import EmpleadoForm from './empleados-form.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/empleados/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empleados', ['ADMIN', 'INSPECTOR'], Empleados),
        keepAlive: true
    },
    {
        path: '/empleados/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empleados/new', ['ADMIN', 'INSPECTOR'], EmpleadoForm)
    },
    {
        path: '/empleados/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empleados/edit', ['ADMIN', 'INSPECTOR'], EmpleadoForm)
    },
    {
        path: '/empleados/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('empleados/view', ['ADMIN', 'INSPECTOR'], EmpleadoForm, { editable: false })
    },
];
export default routes;